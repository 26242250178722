import {
  CodeSnippet,
  Column,
  ErrorBoundary,
  Grid,
  SkeletonPlaceholder,
  Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getGenAIRunBookCodeRecommendations } from "../Services/ServerApi";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";

const RunbookRecommendation = () => {
  const { keycloak } = useKeycloak();

  const alertSelected = JSON.parse(sessionStorage.getItem("platformAlert"));

  const { name, recommendation, type, metrics, event_id } = alertSelected;

  const {
    data: res,
    isLoading,
    isError,
    isFetching,
  } = useQuery([`GET_RUNBOOK_CODE_RECOMMENDATIONS`], () =>
    getGenAIRunBookCodeRecommendations(
      keycloak.token,
      name,
      recommendation,
      type,
      metrics.name,
      event_id,
    ),
  );

  const getData = () => {
    if (isLoading || isFetching) {
      return (
        <Column lg={16} md={8} sm={4}>
          <div className="skeletonPlaceholder--it-health">
            <SkeletonPlaceholder />
          </div>
        </Column>
      );
    }

    if (isError) {
      return (
        <Column lg={16} md={8} sm={4}>
          <ErrorWhileFetching />
        </Column>
      );
    }
    const [titleDescription, code_snippet, footerDescription] =
      res.data.data.runbook.split("```");
    return (
      <>
        <Column lg={16} md={8} sm={4}>
          <p style={{ marginBottom: "0.5rem" }}>
            Reference Ansible playbook that can handles the error and implements
            the recommendation:
          </p>
        </Column>
        <Column lg={10} md={5} sm={4}>
          <CodeSnippet
            CodeSnippet
            type="multi"
            feedback="Copied to clipboard"
            style={{ backgroundColor: "black", color: "white" }}
          >
            {code_snippet}
          </CodeSnippet>
        </Column>
        <Column lg={6} md={3} sm={4}>
          <Tile className="mt-0">
            <h6>Code Description</h6>
            <p>{footerDescription}</p>
          </Tile>
        </Column>
      </>
    );
  };

  return (
    <ErrorBoundary fallback={<ErrorWhileFetching />}>
      <Grid>{getData()}</Grid>
    </ErrorBoundary>
  );
};

export default RunbookRecommendation;
