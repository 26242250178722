import { Modal } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import DynamicForm from "Components/Common/DynamicForm";
// import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import React, { useEffect, useState } from "react";
// import { checkOrgDupilicates, postOrgDetails } from "services/serverapi";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import { getOrganizationAPI, postOrgDetails } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
// import addOrg from "./addOrg.json";

const CreateOrganization = (props) => {
  const { keycloak } = useKeycloak();
  let timer = null;
  const duplicateMessage = "Organization Already Exists";
  const [isFormValid, setFormValididty] = useState(false);
  const [formData, setFormData] = useState({});
  const [dupilicate, setDupilicate] = useState(false);
  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedTenant = sessionStorage.getItem("selectedTenant");

  const handleFormValidity = (isValid) => {   
    setFormValididty(isValid);
  };

  useEffect(() => {
  }, [formData]);

  const handleFormChange = (label, value, id) => {
    if (label === "name") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(() => {
        if (Boolean(value)) checkDupilicate(value);
      }, 500);
    }
    const targetId = label;
    const targetValue = value;
    const data = formData;
    data[targetId] = targetValue;
    setFormData(data);
  };

  const checkDupilicate = async (value) => {
    setChecking(true);
    const res = await getOrganizationAPI(keycloak, uuidv4(), selectedTenant, value);
    // if (res?.duplicate === false) {
    //   setDupilicate(false);
    // } else {
    //   setDupilicate(true);
    // }
    if (res?.status === 204) {
      setDupilicate(false);
    } else {
      setDupilicate(true);
    }
    setChecking(false);
  };

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const onCreateOrg = async () => {

    const data = { ...formData };
    // data.okrTemplate = [];
    // data.startDate = createUTCDateFromInput(formData.startDate, "start");
    // data.endDate = createUTCDateFromInput(formData.endDate, "end");
    // data.tenant = sessionStorage.getItem("tenant");
    setIsLoading(true);
    const res = await postOrgDetails(keycloak, uuidv4(), selectedTenant, data);
    try {
      if (!Boolean(res?.data)) {
        throw new Error("msg");
      } else {
        setErrorMessage("Organization created successfully");
        setReloadRequired(true);
      }
    } catch (error) {
      setErrorMessage("Failed to create organization.");
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div>
      {errorMessage || isLoading ? (
        <PassiveModal
          canCloseDetailsPopup={() => {
            closeErrorPopup();
            props.closeCreateOrg(reload_required);
          }}
        >
          <h4>{errorMessage}</h4>
          {isLoading && (
            <InlineLoader
              status="active"
              description="Creating Organization"
            />
          )}
        </PassiveModal>
      ) : (
        <Modal
          open
          modalHeading="Create Organization"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestClose={() => props.closeCreateOrg(reload_required)}
          onRequestSubmit={() => onCreateOrg()}
          primaryButtonDisabled={!isFormValid || dupilicate || checking}
        >
          <div>
            <DynamicForm
              formData={JSON.parse(JSON.stringify(props.addOrg))}
              dupilicate={{ id: "name", exists: dupilicate }}
              duplicateMessage={duplicateMessage}
              getFormValidity={handleFormValidity}
              handleFormChange={handleFormChange}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateOrganization;
