import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row
} from "@carbon/react";
import CarbonTabs from "Carbon-Components/Tabs";
import React from "react";
import AppTable from "./ApplicationBased/AppTable";
import EAApps from "./ApplicationBased/EAApps";
import ITOps from "./ApplicationBased/ITOps";
import Insights from "./ApplicationBased/Insights";
import Overview from "./ApplicationBased/Overview";
import "./css/dashboard.scss";
import LanguageShowdown from "./ApplicationBased/Consulting/LanguageShowdown";

const Dashboard = () => {
  const tenant = sessionStorage.getItem("tenant");
  const EA_Tenant = tenant === "Energy_Australia";
  // const Ibm_consulting = tenant === process.env.REACT_DEFAULT_TENANT;
  const Ibm_consulting = true;

  return (
    <div
      className="table-container green-theme"
      style={{ position: "relative" }}
    >
      <div>
        <FlexGrid>
          <Row>
            <Column lg={12} md={8} sm={4}>
              <Breadcrumb noTrailingSlash>
                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                {/* <BreadcrumbItem href="#/sustainability">
                  Sustainability
                </BreadcrumbItem> */}
                <BreadcrumbItem isCurrentPage>Overview</BreadcrumbItem>
              </Breadcrumb>
            </Column>
          </Row>
          <Row>
            <Column lg={16} md={8} sm={4}>
              {
                EA_Tenant
                  ? <CarbonTabs
                    tabIndex={0}
                    selectionMode="automatic"
                    type="default"
                    Tablist={[
                      "Overview",
                      "Applications",
                      "IT Operations",
                      "Delivered Benefits",
                    ]}
                    TabPanelsList={[
                      <Overview />,
                      <AppTable />,
                      <ITOps />,
                      <EAApps />,
                    ]}
                  />
                  :Ibm_consulting
                    ? <CarbonTabs
                      tabIndex={0}
                      selectionMode="automatic"
                      type="default"
                      Tablist={[
                        "Overview",
                        "Applications",
                        "IT Operations",
                        "Delivered Benefits",
                        // "Sample SRE Dashboard",
                        "Language Showdown"
                      ]}
                      TabPanelsList={[
                        <Overview />,
                        <AppTable />,
                        <ITOps />,
                        <EAApps />,
                        // <Insights />,
                        <LanguageShowdown />
                      ]}
                    />
                    : <CarbonTabs
                      tabIndex={0}
                      selectionMode="automatic"
                      type="default"
                      Tablist={[
                        "Overview",
                        "Applications",
                        "IT Operations",
                        "Sample SRE Dashboard"
                      ]}
                      TabPanelsList={[
                        <Overview />,
                        <AppTable />,
                        <ITOps />,
                        <Insights />,
                      ]}
                    />
              }
            </Column>
          </Row>
        </FlexGrid>
      </div>
    </div>
  );
};

export default Dashboard;
