import {
    ClickableTile,
    Column,
    Modal,
    Row,
    Tag
} from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    ModelBuilder,
    IbmWatsonDiscovery,
  } from "@carbon/react/icons";
import "./nerve.scss";
import TopologyGraph from "./TopologyGraph";

const ContainerDetails = (props) => {
    
    const history = useHistory();
    const [appDetails, setAppDetails] = useState([]);
    const [selectedApp, setSelectedApp] = useState("");
    const [showApps, setShowApps] = useState({});
    const [showTopology, setShowTopology] = useState(false);
    const [showAnalyze, setShowAnalyze] = useState(false);

    useEffect(() => {
        setAppDetails(props.appDetails)
        setSelectedApp(props.selectedApp)
    }, [props?.appDetails, props?.selectedApp])

    const getTilesDetails = () => {
        return showApps.data.map((data) => {
            return <li>{data.name}</li>
        })
    }

    // const handleSetShowApps = (data) => {
    //     setShowApps(data);
    // }

    const getFirstRow = () => {
        return appDetails.length > 0 && appDetails[0]["Header Tiles"].map((tile) => {
            return <ClickableTile onClick={() => showTileDetails(tile)} className="tile-row">
                <h5>{tile.name}</h5>
                <span>{tile.value}</span>
            </ClickableTile>
        })
    }

    const showTileDetails = (tile) => {
        if (tile.data !== undefined) {
            setShowApps(tile)
        }
    }

    const handleOnClick = (data) => {
        history.push({ pathname: `nerveTileInfoDetails`, state: { chartData: data } })
    }

    const getCharts = () => {
        return appDetails.length > 0 &&
            appDetails[0].Charts.map(
                (chart, index) =>
                    // index == 0 ?
                    //     <Column lg={8} md={4} sm={4} >
                    //         <span style={{ "cursor": "pointer", "background": "lightgray" }}
                    //             onClick={() => { handleOnClick(chart) }} >
                    //             <ChartContainer data={chart} />
                    //         </span>
                    //     </Column >
                    //     :
                        <Column lg={8} md={4} sm={4} >
                            <ChartContainer data={chart} />
                        </Column>
            )
    }

    const redirectToIne = (page, node) => {
        // history.push({
        //     pathname: "/nerveDetails",
        //     state: { data: node, tileData: propsState.tileData, workflow: propsState.workflow, flow: propsState.flow },
        // });
    };

    return (
        <>
            <Row className="head-sec">
                <Column lg={6} md={4} sm={2} >
                    <ul className="icon-actions">
                        <li  
                        title="Analyze"
                        onClick={() => setShowAnalyze(true)}
                       >
                        <Tag><IbmWatsonDiscovery /></Tag>
                        </li>
                        <li 
                        title="Topology"
                        onClick={() => setShowTopology(true)}
                        >
                        <Tag><ModelBuilder /></Tag>
                        </li>
                    </ul>
                    {/* <Tag className="lft-tag" type="purple">
                        Name: {selectedApp}
                    </Tag> */}
                </Column>
                <Column className="date-sec" lg={10} md={6} sm={4} >
                    <Tag className="some-class" type="high-contrast">
                        {'1H'}
                    </Tag>

                    <Tag className="some-class" type="outline">
                        {'8H'}
                    </Tag>
                    <Tag className="some-class" type="outline">
                        {'16H'}
                    </Tag>
                    <Tag className="some-class" type="outline">
                        {'24H'}
                    </Tag>
                </Column>
            </Row>
            <Row>
                <Column className="first-row" lg={16} md={8} sm={4} >
                    {getFirstRow()}
                </Column>
            </Row>
            <Row>
                {getCharts()}
            </Row>
            {
                Object.entries(showApps).length > 0 ?
                    <Modal
                        open
                        size="lg"
                        passiveModal
                        isFullWidth
                        onRequestClose={() => setShowApps(false)}
                        modalHeading={showApps.name}
                        modalLabel=""
                        className="fss-topology"
                    >
                        <ul className="tiles-details">{getTilesDetails()}</ul>
                    </Modal> : null
            }
            {showTopology && (
                        <Modal
                            open
                            size="lg"
                            passiveModal
                            isFullWidth
                            onRequestClose={() => setShowTopology(false)}
                            modalHeading="Topology View"
                            modalLabel="Application Topology View"
                            className="topology-view"
                        >
                            <TopologyGraph redirectToIne={redirectToIne} appDetails={appDetails} />
                        </Modal>
             )}
             {
                showAnalyze && handleOnClick( appDetails[0].Charts[0])
             }

        </>
    )
}

export default ContainerDetails;