import React, { useState, useEffect } from "react";
import ELK from "elkjs/lib/elk.bundled";
import { path as d3Path } from "d3-path";
import { CardNodeTitle, CardNodeColumn } from '@carbon/charts-react';
import { ShapeNode, CardNode, Edge, TeeMarker, ArrowRightMarker } from "@carbon/charts-react";
import { DataBase, Wikis, Send, Queued } from "@carbon/react/icons/index";
import "./fins.scss";
import { v4 as uuidv4 } from "uuid";

const Link = ({ link }) => {
  const sections = link.sections[0];
  const path = d3Path();

  path.moveTo(sections.startPoint.x, sections.startPoint.y);

  if (sections.bendPoints) {
    sections.bendPoints.forEach((b) => {
      path.lineTo(b.x, b.y);
    });
  }

  path.lineTo(sections.endPoint.x, sections.endPoint.y);

  return (
    <Edge
      path={path.toString()}
      markerStart="tee"
      markerEnd="marker"
      variant="dash-sm"
      className="draw-edge"
    />
  );
};

function getColor(color) {
  if (color === "done") {
    return "#24a148";
  } else if (color === "start") {
    return "#ff832b";
  } else if (color === "failed") {
    return "#da1e28";
  } else {
    return "#c6c6c6";
  }
}

const getIcons = (data) => {
  switch (data.types[0]) {
    case "jdbc":
      return <DataBase size={20} />;
    case "HTTP":
      return <Wikis size={20} />;
    case "MESSAGING":
      return <Send size={20} />;
    default:
      return <Queued size={20} />;
  }
};

const Node = ({ x, y, height, width, data, onClickCard, flow }) => {
  return (
    <foreignObject
      transform={`translate(${x},${y})`}
      height={height}
      width={width}
      style={{ overflow: "visible", position: "relative" }}
    >
      {data.label !== "Integration Svc Layer" ? (
        <>
          {data.label === "MDMS" ? (
            <div className="err-circle-elk"></div>
          ) : null}
          <div className={data.label === "MDMS" ? "shapenode-mod" : ""}>
            <ShapeNode
              renderIcon={getIcons(data)}
              size={60}
              onClick={() => onClickCard(data)}
              title={data.label}
            />
          </div>
          <h5
            style={{
              position: "absolute",
              bottom: "-30px",
              fontSize: "0.9rem",
              width: "235px",
              fontWeight: "600",
            }}
          >
            {/* {data.label} */}
          </h5>
        </>
      ) : (
        <CardNode onClick={() => onClickCard(data)}>
          {/* <CardNodeColumn>{getIcons(data)}</CardNodeColumn> */}
          <CardNodeColumn>
            <CardNodeTitle>{data.label}</CardNodeTitle>
          </CardNodeColumn>
        </CardNode>
      )}
    </foreignObject>
  );
};

const Elk = (props) => {
  const elk = new ELK();
  const [positions, setPositions] = useState(null);

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": props.layout,
      "elk.padding": "[left=20, top=20, right=20, bottom=20]",
      separateConnectedComponents: false,
      "spacing.nodeNode": 100,
      "spacing.nodeNodeBetweenLayers": 100,
      // "spacing.nodeNode": 50,
      interactiveLayout: true,
      "elk.direction": "RIGHT",
      // "elk.interactive": "Layer & Order Preserving",
      "elk.alignment": "CENTER",
      "elk.interactive": "Layer Preserving",
      "elk.contentAlignment": "V_CENTER",
      "crossingMinimization.semiInteractive": true,
      "nodePlacement.strategy": "NETWORK_SIMPLEX",
    },
    children: props.nodes,
    edges: props.links,
  };

  useEffect(() => {
    elk
      .layout(graph)
      .then((g) => setPositions(g))
      .catch(console.error);
  }, []);

  if (!positions) return null;

  const buildNodes = () => {
    const { children } = positions;

    return children.map((node) => {
      return (
        <Node
          key={`node_${uuidv4()}`}
          x={node.x}
          y={node.y}
          height={node.height}
          width={node.width}
          data={node}
          flow={props.flow}
          onClickCard={props.cardClick}
        />
      );
    });
  };

  const buildLinks = () => {
    const { edges } = positions;

    return edges.map((edge) => {
      return <Link key={`link_${uuidv4()}`} link={edge} />;
    });
  };

  const nodeElements = buildNodes();
  const linkElements = buildLinks();
  return (
    <div
      className={`force polaris`}
      style={{
        position: `relative`,
        height: Number(props.height),
        width: props.width,
        float: "left",
        backgroundColor: "white",
      }}
    >
      <svg viewBox={props.viewBox} width={props.width} height={props.height}>
        <defs>
          <TeeMarker id="tee" position="start" />
          <ArrowRightMarker id="marker" position="end" />
          {/* <CircleMarker id="circle" position="start" /> */}
        </defs>
        {nodeElements.length > 1 ? linkElements : null}
        {nodeElements}
      </svg>
    </div>
  );
};

export default Elk;
