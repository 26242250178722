/** @format */

import { Breadcrumb, BreadcrumbItem, Column, Row } from "@carbon/react";
import DynamicForm from "Components/Common/DynamicForm";
import { default as React, useEffect, useState } from "react";
import UserList from "./orguser/UserList";

function OrganizationDetails(props) {
  const [organizationDetails, setOrganizationDetails] = useState({});

  useEffect(() => {
    setOrganizationDetails(props.organizationRow);
  }, []);

  return (
    <>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/adminPanel" onClick={() => {
              props.closeOrganizationDetails();
            }}>Organization List</BreadcrumbItem>
            <BreadcrumbItem href="#" isCurrentPage={true}>
              Organization Details
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={6} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            <DynamicForm
              formData={JSON.parse(JSON.stringify(props.addOrganization))}
              dupilicate={{ id: "name", exists: false }}
            // duplicateMessage={duplicateMessage}
            // getFormValidity={handleFormValidity}
            // handleFormChange={handleFormChange}
            />
          </div>
        </Column>
        <Column lg={10} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            <UserList tenant={props.tenant} organization={props.organizationRow.name} />
          </div>
        </Column>
      </Row>
    </>
  );
}

export default OrganizationDetails;
