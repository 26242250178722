import { Modal } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import React, { useEffect, useState } from "react";
import { getTenantRolesByTenantNameAPI, getTenantUserByUsername, postUsertoSelectedTenantDetails } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
import addUser from "./addUser.json";

const CreateUser = (props) => {
  const { keycloak } = useKeycloak();
  let timer = null;
  const duplicateMessage = "User Already Exists";
  const [isFormValid, setFormValididty] = useState(false);
  const [dupilicate, setDupilicate] = useState(false);
  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formTemplate, setFormTemplate] = useState(JSON.parse(JSON.stringify(addUser)));
  const [updateCounter, setUpdateCounter] = useState(0);
  const [roles, setRoles] = useState([]);
  const selectedTenant = sessionStorage.getItem("selectedTenant");

  // Fetch roles when component mounts
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData = await getTenantRolesByTenantNameAPI(keycloak, selectedTenant, uuidv4());
        if (Array.isArray(rolesData)) {
          // Transform the roles data into the format expected by the dropdown
          const formattedRoles = rolesData.map(role => ({
            value: role.name || role,  // Adjust based on actual API response structure
            name: role.id || role  // Adjust based on actual API response structure
          }));

          setRoles(formattedRoles);

          // Update the form template with the new roles
          let tempFormTemplate = JSON.parse(JSON.stringify(formTemplate));
          const updatedTemplate = tempFormTemplate.map(field => {
            if (field.id === 'role') {
              return {
                ...field,
                options: formattedRoles
              };
            }
            return field;
          });

          setFormTemplate(updatedTemplate);
          setUpdateCounter(prev => prev + 1); // Force re-render of form
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        setTimeout(fetchRoles, 2000);
      }
    };

    fetchRoles();
  }, [selectedTenant, keycloak]);

  const handleFormValidity = (isValid) => {
    setFormValididty(isValid);
  };

  const handleFormChange = (label, value, id) => {
    let tempFormTemplate = JSON.parse(JSON.stringify(formTemplate));
    let tenantUserRes;
    let updatedTemplate = [];
    let isProductUser = "none";
    let userRoles = [];
    let initialSelectedItems = [];
    let initialSelectedObj = {};
    let formIsValid = true;

    if (label === "username") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(async () => {
        if (Boolean(value)) {

          setChecking(true);
          tenantUserRes = await getTenantUserByUsername(keycloak, selectedTenant, value);

          if (tenantUserRes?.status === 200) {
            if (tenantUserRes?.err === false) {
              //user exists in product - populate
              isProductUser = "true";
              updatedTemplate = tempFormTemplate.map(field => {
                if (tenantUserRes.data.hasOwnProperty("roles")) {
                  if (field.id === "role") {
                    tenantUserRes.data["roles"]?.forEach((userRoleObj) => {
                      userRoles.push(userRoleObj.name);
                      roles.forEach((roleObj) => {
                        if (roleObj.value === userRoleObj.name) {
                          initialSelectedObj = {};
                          initialSelectedObj.id = roleObj.name;
                          initialSelectedObj.text = roleObj.value;
                          initialSelectedItems.push(initialSelectedObj);
                        }
                      })
                    })
                    field.inputValue = userRoles;
                    field.initialSelectedItems = initialSelectedItems;

                    // field.isValid = true;
                    field.visited = Boolean(userRoles);
                    if (userRoles.length > 0)
                      field.isValid = true;
                  } else {
                    field.inputValue = tenantUserRes.data[field.id];
                    // field.isValid = true;
                    field.visited = Boolean(tenantUserRes.data[field.id]);
                    const re = new RegExp(field.inputPattern.slice(1, -1));
                    field.isValid =
                      tenantUserRes.data[field.id] === "" ? false : re.test(tenantUserRes.data[field.id]);
                    if (field?.minLength && field?.isValid)
                      if (Number(field.minLength) > tenantUserRes.data[field.id]?.length)
                        field.isValid = false;
                  }
                }
                return field;
              });
              formIsValid = true;
              updatedTemplate.forEach((formfield) => {
                if (formfield.isValid === false || formfield.isValid === true) {
                  if (formfield.isRequired || formfield.inputValue) {
                    formIsValid = formIsValid && formfield.isValid;
                  }
                }
              });
              handleFormValidity(formIsValid);

              setUpdateCounter(prev => prev + 1); // Force re-render of form
              setFormTemplate(updatedTemplate);

              setDupilicate(false);
            }
          }
          else {
            isProductUser = "false";
            if (tenantUserRes?.status === 204) {
              //New Username
              setDupilicate(false);
            } else {
              if (tenantUserRes?.err) {
                //user already exists in tenant - error
                setDupilicate(true);
                setFormValididty(false);
              } else {
                setDupilicate(false);
              }
            }
          }
          setChecking(false);
        }
      }, 500);
    }

    if (isProductUser === "false") {
      updatedTemplate = tempFormTemplate.map((formTemplateObj) => {
        if (formTemplateObj.id === label) {
          formTemplateObj.inputValue = value;
        }
        return formTemplateObj;
      })
      setFormTemplate(updatedTemplate);
    }
  };

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const onCreateUser = async () => {
    let formDataObj = {};

    formTemplate.forEach((formTemplateObj) => {
      if (formTemplateObj.id === "role") {
        formDataObj[formTemplateObj.id] = [];
        formTemplateObj.inputValue.forEach((roleName) => {
          roles.forEach((roleObj) => {
            if (roleObj.value === roleName) {
              formDataObj[formTemplateObj.id].push(roleObj);
            }
          })
        })
      } else {
        formDataObj[formTemplateObj.id] = formTemplateObj.inputValue;
      }
    })

    setIsLoading(true);
    const res = await postUsertoSelectedTenantDetails(keycloak, uuidv4(), formDataObj, selectedTenant);
    try {
      if (!Boolean(res?.data)) {
        throw new Error("msg");
      } else {
        setErrorMessage("User created successfully");
        setReloadRequired(true);
      }
    } catch (error) {
      setErrorMessage("Failed to create user");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {errorMessage || isLoading ? (
        <PassiveModal
          canCloseDetailsPopup={() => {
            closeErrorPopup();
            props.closeCreateUser(reload_required);
          }}
        >
          <h4>{errorMessage}</h4>
          {isLoading && (
            <InlineLoading
              status="active"
              description={dupilicate ? "Adding User to Tenant" : "Creating Tenant User"}
            />
          )}
        </PassiveModal>
      ) : (
        <Modal
          open
          modalHeading="Create/Edit user in Tenant"
          primaryButtonText="Create/Edit"
          secondaryButtonText="Cancel"
          onRequestClose={() => props.closeCreateUser(reload_required)}
          onRequestSubmit={() => onCreateUser()}
          primaryButtonDisabled={!isFormValid || checking}
        >
          <div>
            <DynamicForm
              key={`form-${updateCounter}`}
              formData={formTemplate}
              dupilicate={{ id: "username", exists: dupilicate }}
              duplicateMessage={duplicateMessage}
              getFormValidity={handleFormValidity}
              handleFormChange={handleFormChange}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateUser;
