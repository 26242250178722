import { LineChart } from "@carbon/charts-react";
import { Dropdown } from "@carbon/react";
import React, { useState } from "react";

const LineChartWithGroupDropdown = ({ data, options }) => {
  const groupArray = Array.from(new Set(data.map((ticket) => ticket.group)));
  const [group, setGroup] = useState(groupArray[0]);
  const data1 = data.filter((a) => a.group === group);

  return (
    <div className="chart-dropdown-wrapper">
      {groupArray.length > 1 ? (
        <div className="dropdown-wrapper">
          <Dropdown
            id="select-target-year"
            label="Dropdown menu options"
            onChange={(e) => setGroup(e.selectedItem)}
            selectedItem={group}
            size="sm"
            items={groupArray}
            itemToString={(item) => (item ? item : "")}
          />
        </div>
      ) : (
        <p>year: {groupArray[0]}</p>
      )}
      <LineChart data={data1} options={options}></LineChart>
    </div>
  );
};

export default LineChartWithGroupDropdown;
