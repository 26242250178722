import {
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    StructuredListWrapper,
} from "@carbon/react";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const StructuredListChart = ({ data }) => {
    const { heading, header, rows, footer } = data;

    const [sortOrder, setSortOrder] = useState({
        columnIndex: 0,
        decending: true,
    });

    // const sortRows = (columnIndex) => {
    //     setSortOrder((prevSortOrder) => ({
    //         columnIndex,
    //         decending: !prevSortOrder.decending,
    //     }));
    // };

    const sortedRows = [...rows].sort((a, b) => {
        const aValue = a[sortOrder.columnIndex];
        const bValue = b[sortOrder.columnIndex];

        if (typeof aValue === "string" && typeof bValue === "string") {
            return sortOrder.decending
                ? bValue.localeCompare(aValue)
                : aValue.localeCompare(bValue);
        } else {
            return sortOrder.decending ? bValue - aValue : aValue - bValue;
        }
    });

    // let backgroundColorObj = (rowIndex) => {
    //     if (rowIndex % 2 === 0) {
    //         return "#fff";
    //     } else {
    //         return "#efefef";
    //     }
    // };

    return (
        <div className="chart-containet-table">
            <div className="dynamic-table-heading">{heading}</div>
            <StructuredListWrapper className="dynamic-tables">
                <StructuredListHead>
                    <StructuredListRow head>
                        {header.map((item, headerIndex) => (
                            <StructuredListCell
                                head
                                key={uuidv4()}
                                // onClick={() => sortRows(headerIndex)}
                            >
                                {item}
                                {/* {sortOrder.decending ? <CaretDown /> : <CaretUp />} */}
                            </StructuredListCell>
                        ))}
                    </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                    {sortedRows.map((row, rowIndex) => {
                        //const length = row.length;
                        return (
                            <StructuredListRow key={uuidv4()}>
                                {row.map((cell, cellIndex) => (
                                    <StructuredListCell
                                        key={uuidv4()}
                                        // style={
                                        //     cellIndex === length - 2
                                        //         ? {
                                        //             backgroundColor:
                                        //                 row[length - 2] !== null
                                        //                     ? row[length - 1]
                                        //                     : backgroundColorObj(rowIndex),
                                        //             color: "#fff",
                                        //         }
                                        //         : {}
                                        // }
                                    >
                                        {cell}
                                    </StructuredListCell>
                                ))}
                            </StructuredListRow>
                        );
                    })}
                    <StructuredListRow head>
                        {footer.map((item) => (
                            <StructuredListCell head key={uuidv4()}>
                                {item}
                            </StructuredListCell>
                        ))}
                    </StructuredListRow>
                </StructuredListBody>
            </StructuredListWrapper>
        </div>
    );
};

export default StructuredListChart;
