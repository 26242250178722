import { Tile } from "@carbon/react";
import {
  ApplicationMobile,
  Car,
  ChevronLeft,
  ChevronRight,
  GasStation
} from "@carbon/react/icons";
import React, { useEffect, useState } from "react";

const inferences1Mt = [
  {
    value: 98.2,
    description: "gallons of diesel consumed",
    icon: <GasStation size={50} />,
  },
  {
    value: 2_564,
    description:
      "miles driven by an average gasoline-powered passenger vehicle",
    icon: <Car size={50} />,
  },
  {
    value: 121_643,
    description: "number of smartphones charged",
    icon: <ApplicationMobile size={50} />,
  }
];

const Inferences = ({ value, displayUnit }) => {
  const inferenceLength = inferences1Mt.length;
  const [index, setIndex] = useState(0);

  const demo_value = value * inferences1Mt[index]["value"];

  const description = `${Math.ceil(demo_value)} ${
    inferences1Mt[index]["description"]
  }`;

  useEffect(() => {
    let timer = setInterval(() => nextInf(), 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const nextInf = () => {
    setIndex((prev) => {
      if (prev === inferenceLength - 1) return 0;
      return prev + 1;
    });
  };

  const prevInf = () => {
    setIndex((prev) => {
      if (prev === 0) return inferenceLength - 1;
      return prev - 1;
    });
  };
  return (
    <Tile className="inference-tile">
      <div className="inference-heading">
        Emission of <span>{value.toFixed(3)} {displayUnit}</span> is eqivalent to
      </div>
      <div className="inference">
        {inferences1Mt[index]["icon"]}
        <p className="inference-description">{description}</p>
        <ChevronLeft
          className="left-arrow"
          size={20}
          onClick={() => prevInf()}
        />
        <ChevronRight
          className="right-arrow"
          size={20}
          onClick={() => nextInf()}
        />
      </div>
    </Tile>
  );
};

export default Inferences;
