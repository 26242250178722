import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@carbon/react";
import { CheckmarkFilled, ErrorFilled, InProgress, UndefinedFilled } from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";
import ContainerDetails from "./ContainerDetails";
import "./nerve.scss";


const Servers = (props) => {
    const [appDetails, setAppDetails] = useState([]);
    const [selectedApp, setSelectedApp] = useState("");

    useEffect(() => {
        setAppDetails(props.appDetails)
        setSelectedApp(props.selectedApp)
    }, [props?.appDetails, props?.selectedApp])

    const getStatus = (stat) => {
        switch (stat) {
            case 0:
                return <CheckmarkFilled fill="#198038" style={{ marginBottom: "-2px" }} />;
            case 1:
                return <ErrorFilled fill="#da1e28" style={{ marginBottom: "-2px" }} />;
            case "inProgress":
                return <InProgress fill="#d2a106" style={{ marginBottom: "-2px" }} />;
            default:
                return <UndefinedFilled />;
        }
    }

    return (
        props.appDetails[0].Servers && props.appDetails[0].Servers.length > 0 ?
            // <Grid condensed>
            //     <Column lg={16} md={8} sm={4}>
            <Tabs>
                <TabList activation="manual" aria-label="List of tabs" contained>
                    {
                        props.appDetails[0].Servers.map(
                            (appDetail, i) =>
                                <Tab
                                // renderIcon={getStatus(appDetail.status)}
                                >{appDetail["Display Name"]} {getStatus(appDetail.status)}</Tab>
                        )
                    }
                </TabList>
                <TabPanels>
                    {
                        props.appDetails[0].Servers.map(
                            (appDetail, i) =>
                                <TabPanel style={{ background: "none" }}>
                                    <ContainerDetails
                                        appDetails={[appDetail]}
                                        selectedApp={appDetail["Display Name"]}
                                    />
                                </TabPanel>
                        )
                    }
                </TabPanels>
            </Tabs>
            //     </Column>
            // </Grid>
            :
            <ContainerDetails
                appDetails={appDetails}
                selectedApp={selectedApp}
            />
    )
}

export default Servers;