/** @format */

import { SkeletonPlaceholder } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { getRunBookRecommendations } from "../../Services/ServerApi";
import AddUserActivity from "../../utilities/AddUserActivity";
import ErrorWhileFetching from "../../utilities/ErrorWhileFetching";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import RunBookAutomation from "./RunbookAutomation";
import RunBookAutomationGenAI from "./RunbookAutomationGenAi";

const RunBookAutomationCommon = (props) => {
  const context = useContext(Context);
  useEffect(() => {
    getAutomationRecommendations(props.servicetype, props.rootcause);
  }, []);

  const { keycloak } = useKeycloak();
  const [APIData, setAPIData] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [callGenAI, setCallGenAI] = useState(false);


  const isValidHttpUrl = (string) => {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  const getAutomationRecommendations = async (servicetype, rootcause) => {
    const response = await getRunBookRecommendations(
      keycloak.token,
      servicetype,
      rootcause
    );
    AddUserActivity(keycloak, context, "FEATURE", "RunBook Recommendation", response);
    setAPIData(response.data.data)
    setIsAPIDataLoading(false);

    if (!response.data) {
      setErrorMessage("Error while fetching data");
    }
    if (response.status >= 400 || response === "Error") {
      setErrorMessage("Error while fetching data");
    } else {
      if (response.data.data) {
        response.data.data.forEach(obj => {
          if (obj['Automation link'] && isValidHttpUrl(obj['Automation link'])) {
          } else {
            setCallGenAI(true);
          }
        });
      }
    }
  };

  const getLoading = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    isAPIDataLoading || errMessage ? (
      getLoading()
    ) : (
      callGenAI
        ?
        <RunBookAutomationGenAI
          servicetype={props.servicetype}
          title={props.title}
          rootcause={props.rootcause}
        />
        :
        <RunBookAutomation
          data={APIData}
        />
    )
  );
};

export default RunBookAutomationCommon;