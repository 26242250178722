import { ComboChart } from "@carbon/charts-react";
import { Dropdown } from "@carbon/react";
import React, { useState } from "react";

const ComboWithdropdown = ({ data, options }) => {
  const years = Array.from(new Set(data.map((ticket) => ticket.yearKey)));
  const [year, setYear] = useState(Math.max(...years));

  const data1 = data.filter((a) => a.yearKey === year);

  return (
    <div className="chart-dropdown-wrapper">
      {years.length > 1 ? (
        <div className="dropdown-wrapper">
          <Dropdown
            id="select-target-year"
            label="Dropdown menu options"
            onChange={(e) => setYear(e.selectedItem)}
            selectedItem={year}
            size="sm"
            items={years}
            itemToString={(item) => (item ? item : "")}
          />
        </div>
      ) : (
        <p>year: {years[0]}</p>
      )}
      <ComboChart data={data1} options={options}></ComboChart>
    </div>
  );
};

export default ComboWithdropdown;
