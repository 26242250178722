import { Toggletip, ToggletipButton, ToggletipContent } from "@carbon/react";
import { Information } from "@carbon/react/icons";
import ChartContainer from "Carbon-Charts/ChartContainer";
import React from "react";

const ServiceNow = ({
  app_specific,
  year_specific,
  application,
  assumptions,
}) => {
  return (
    <div className="heading-parent">
      <Toggletip className="manual-heading-info" align="left">
        <ToggletipButton label="Show information">
          <Information className="info-icon" size={20} />
        </ToggletipButton>
        <ToggletipContent>
          <div>
            <h6>Assumptions</h6>
            <ul className="assumption">
              {assumptions.map((ass) => (
                <li>{ass}</li>
              ))}
            </ul>
          </div>
        </ToggletipContent>
      </Toggletip>
      <ChartContainer
        data={
          application
            ? app_specific("Service Now")
            : year_specific("Service Now")
        }
      />
    </div>
  );
};

export default ServiceNow;
