/** @format */
import {
    Column,
    Grid,
    Header,
    HeaderContainer,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenu,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    Link,
    Modal,
    RadioTile,
    SideNavItems,
    SideNavLink,
    SkipToContent,
    TileGroup
} from "@carbon/react";
import { Notification, Switcher, UserRole } from "@carbon/react/icons";
import { CustomerService, Tour, UserProfile, Wikis } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import Logo from "Assets/logo.png";
import Logout from "Common-Modules/Logout";
import OverlayRoute from "Components/HomePage/HomeDashboard/Overlay";
import Context from "Context/Context";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import { getAlertCounts, getDOExternalApi } from "Services/ServerApi";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import "./css/appHeader.scss";

const sign = require('jwt-encode');
const secret = 'secret';

const AppHeaderNew = ({ roles }) => {

    axios.interceptors.response.use(undefined, (error) => {
        if (keycloak.isTokenExpired()) {
            history.push("/logout")
        }
        else if (error?.response?.data?.error?.status !== "UNAUTHENTICATED" && (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 500)) {
            history.push("/401");
        }
    });

    const { t, i18n } = useTranslation();
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [showProfile, setShowProfile] = useState(false);
    const [showCollaborate, setShowCollaborate] = useState(false);
    const [showLanguage, setShowLanguage] = useState(false);
    const [activeClass, setActiveClass] = useState();
    const setWrapperRef = useRef(null);
    const location = useLocation();
    const context = useContext(Context);
    const [alertCount, setAlertCount] = useState(1);
    const [activeMenu, setActiveMenu] = useState("");
    //const tenants = useSelector((state) => state.GetTenants);
    const [logoutPopup, showLogoutPopup] = useState(false);
    const [DOExternal, setDOExternal] = useState({});
    const [alertCunt, setAlertCunt] = useState([]);
    const [overlayPop, setOverlayPop] = useState(false);
    const [showNotify, setShowNotify] = useState(false);
    //const msTeamsEmails = ["ragvenk2@in.ibm.com", "shrinira@in.ibm.com", "anil.chaudhary@ibm.com", "mbalagur@in.ibm.com",
    //"aparna.venkatraman@in.ibm.com", "smeena@in.ibm.com", "mrdivakar@in.ibm.com", "hpramod@in.ibm.com", "divyesh.dp@ibm.com", "navpadiy@in.ibm.com"];
    const [showAccountSettings, setShowAccountSettings] = useState(false);

    const [showTenantSwitch, setShowTenantSwitch] = useState(true);
    const [showOrgSwitch, setShowOrgSwitch] = useState(false);
    const [showAppSwitch, setShowAppSwitch] = useState(false);

    sessionStorage.setItem("selectedApp", "petsite");

    useEffect(() => {
        getOrgDisplayName();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getDOExternal()
        alertCountApi()
    }, [])

    useEffect(() => {
    }, [i18n.language]);

    const alertCountApi = async () => {
        const getAlert = await getAlertCounts(keycloak);

        if (getAlert !== undefined && getAlert.data !== undefined) {
            setAlertCunt(getAlert.data)
        }
    }

    const getDOExternal = async () => {
        //const org = sessionStorage.getItem("organizationName");
        const DOExt = await getDOExternalApi(keycloak, "DevSecOps-INT", getUserRoles());
        if (DOExt !== undefined) {
            setDOExternal(DOExt.data)
        }
    }

    const reLoadApp = (context) => {
        sessionStorage.setItem("setLogin", "false");
        context.updateLogin(false);

        window.history.replaceState(
            null,
            "",
            `${window.location.protocol}//${window.location.host}/`,
        );
        sessionStorage.setItem("setLogin", "true");
        context.updateLogin(true);
    };

    const handleClickOutside = (event) => {
        if (
            setWrapperRef.current !== null &&
            !setWrapperRef.current.contains(event.target)
        ) {
            setShowCollaborate(false);
            setShowLanguage(false);
            setShowProfile(false);
            setShowNotify(false);
        }
    };

    const onclickOfHome = () => {
        //  dispatch(step3Option({ step: 1, option: "home" }));
        sessionStorage.removeItem("region");
        sessionStorage.removeItem("statesData");
        sessionStorage.removeItem("networkTab");
    };

    const getDisplayname = () => {
        // const selectedTenant = tenants.tenantsList.filter(
        //     (data) => data.name === keycloak.realm,
        // );
        let tenantsList = JSON.parse(sessionStorage.getItem("tenants"));
        let tenantDisplayName = "";

        tenantsList.forEach((tenantObj) => {
            if (tenantObj.name === sessionStorage.getItem("tenant")) {
                tenantDisplayName = tenantObj.displayName;
            }
        })
        // return tenantsList[0];
        // return tenants.tenantsList[0];
        return tenantDisplayName;
    };

    const getOrgDisplayName = () => {
        // if (Boolean(UserInfo.userCurrentOrg?.name)) {
        //   return UserInfo.userCurrentOrg;
        // }
        // const telcoOrg = organizations.orgList.filter(
        //   (data) => data.name === "Telco",
        // );
        // let selectedOrg;
        // selectedOrg = telcoOrg.length > 0 ? telcoOrg[0] : organizations.orgList[0];
        // if (Boolean(selectedOrg?.name)) {
        //   dispatch(userCurrentOrg(selectedOrg));
        //   sessionStorage.setItem("currentOrg", JSON.stringify(selectedOrg));
        // }

        const selectedOrg = sessionStorage.getItem("organizationName");
        // const selectedOrg = organizations.orgList.filter(
        //   (data) => data.id === sessionStorage.getItem("organization"),
        // );
        // return selectedTenant[0];
        return selectedOrg;
    };

    const getUserDetails = () => {
        return keycloak;
    }

    // const getRoles = () => {
    //   let roles = [];
    //   keycloak.realmAccess.roles.forEach((role) => {
    //     if (
    //       role !== "uma_authorization" &&
    //       role !== "offline_access" &&
    //       role !== "Guest"
    //     ) {
    //       roles.push(role);
    //     }
    //   });
    //   return roles.length > 0 ? nameMapper(roles[0]) : "Guest";
    // };


    // const getApplicationDisplayName = () => {
    //     let applicationDisplayName = "";
    //     const applicationsList = JSON.parse(sessionStorage.getItem("applications"));
    //     if (applicationsList && applicationsList.length > 0) {
    //         applicationsList.forEach(
    //             (data) => {
    //                 if (data.id === sessionStorage.getItem("application") && data.displayName) {
    //                     applicationDisplayName = data.displayName;
    //                 }
    //             }
    //         );
    //     }
    //     return applicationDisplayName;
    // };

    const changeTenant = (tenant, context) => {
        sessionStorage.removeItem("tenant");
        sessionStorage.removeItem("organization");
        sessionStorage.removeItem("organizationName");
        sessionStorage.removeItem("currentOrg");
        sessionStorage.removeItem("application");
        sessionStorage.removeItem("applicationName");

        sessionStorage.setItem("tenant", tenant);
        reLoadApp(context);
    };

    // const getApplicationRegion = (id) => {
    //     const applicationsList = JSON.parse(sessionStorage.getItem("applications"));
    //     if (applicationsList && applicationsList.length > 0) {
    //         return applicationsList.map(
    //             (data) => data.id === id && data.deploymentRegion
    //         );
    //     }
    // };

    // const changeApplication = (applicationId, context) => {
    //     sessionStorage.removeItem("application");
    //     sessionStorage.setItem("application", applicationId);

    //     const applicationObj = JSON.parse(sessionStorage.getItem("applications")).filter(application => application.id === applicationId);
    //     sessionStorage.setItem("applicationName", applicationObj[0].displayName);
    //     sessionStorage.setItem("monitoringTool", applicationObj[0].monitoringTool);

    //     sessionStorage.removeItem("region");
    //     sessionStorage.setItem("region", getApplicationRegion(applicationId));
    //     reLoadApp(context);
    // };

    const changeOrganization = (organizationId, context) => {
        sessionStorage.removeItem("organization");
        sessionStorage.removeItem("currentOrg");
        sessionStorage.removeItem("organizationName");
        sessionStorage.removeItem("application");
        sessionStorage.removeItem("applicationName");

        sessionStorage.setItem("organization", organizationId);
        sessionStorage.setItem("organizationName", organizationId);
        // sessionStorage.setItem("applicationName", applicationName);

        //const organizationObj = JSON.parse(sessionStorage.getItem("orgs")).filter(organization => organization.id === organizationId);
        sessionStorage.setItem("currentOrg", organizationId);
        // sessionStorage.setItem("organizationName", organizationId);
        reLoadApp(context);
    };

    const getTenantSwitch = (context) => {

        let newArr = null;
        Object.entries(context.UserInfo).forEach(([key, value]) => {
            if (key === "tenants") {
                newArr = value
            }
        })
        const tenantTiles = newArr !== null && newArr.map((tenant) => {
            return (
                <RadioTile
                    checked={sessionStorage.getItem("tenant") === tenant.name ? true : false}
                    value={tenant.name}
                    style={{ marginBottom: '.5rem' }}>
                    {tenant.displayName}
                </RadioTile>
            );
        });

        return <TileGroup
            defaultSelected={sessionStorage.getItem("tenant")}
            legend={t('common.headers.switchTenant')}
            name="Tenants"
            onChange={(e) => { changeTenant(e, context) }}
        >
            {tenantTiles}
        </TileGroup>
    }

    const getOrgSwitch = (context) => {
        let newArr = [];
        Object.entries(context.UserInfo).forEach(([key, value]) => {
            if (key === "groups") {
                value.forEach((val) => {
                    newArr.push(val)
                })

            }
        })


        const orgFilt = newArr.length > 0 && newArr.filter((arr) => {
            return arr.name === sessionStorage.getItem("tenant")
        })

        const orgTiles = orgFilt.length > 0 && orgFilt.map((organization) => {
            return organization.orgs.map((org) => {
                return (
                    <RadioTile
                        checked={sessionStorage.getItem("organizationName") === org ? true : false}
                        value={org.name}
                        style={{ marginBottom: '.5rem' }}>
                        {org.name}
                    </RadioTile>
                );
            })
        });

        // const orgTiles = orgFilt.length > 0 && orgFilt.map((organization) => {
        //     // Only use the first element (string) from orgs array
        //     // const orgName = organization.orgs[0];
        //     const orgNames = organization.orgs.filter(org => typeof org === 'string');
        //     // return (
        //     //     <RadioTile
        //     //         key={orgName}
        //     //         checked={sessionStorage.getItem("organizationName") === orgName}
        //     //         value={orgName}
        //     //         style={{ marginBottom: '.5rem' }}>
        //     //         {orgName}
        //     //     </RadioTile>
        //     // );
        //     return orgNames.map(orgName => (
        //             <RadioTile
        //                 key={orgName}
        //                 checked={sessionStorage.getItem("organizationName") === orgName}
        //                 value={orgName}
        //                 style={{ marginBottom: '.5rem' }}>
        //                 {orgName}
        //             </RadioTile>
        //         ));
        // });

        let applicationName = sessionStorage.getItem("applicationName");
        return <TileGroup
            defaultSelected={sessionStorage.getItem("organizationName")}
            legend={t('common.headers.switchOrganization')} onChange={(e) => { changeOrganization(e, context) }}
        >
            {orgTiles}
        </TileGroup>
    }

    // const getAppSwitch = (context) => {
    //     const appTiles = JSON.parse(sessionStorage.getItem("applications")).map((application) => {
    //         return (
    //             <RadioTile
    //                 value={application.id}
    //                 style={{ marginBottom: '.5rem' }}>
    //                 {application.displayName}
    //             </RadioTile>
    //         );
    //     });

    //     return <TileGroup
    //         defaultSelected={sessionStorage.getItem("application")}
    //         legend="Switch Application"
    //         name="Applications"
    //         onChange={(e) => { changeApplication(e, context) }}
    //     >
    //         {appTiles}
    //     </TileGroup>
    // }

    // const hideApplicationsMenu = () => {
    //     if (location.pathname === "/portfolio" || location.pathname === "/platformInsightsPortfolio" || location.pathname === "/performanceAvailabilityPortfolio") {
    //         return true;
    //     }
    //     return false;
    // }

    const hideTenantOrgHeaderInAdminPanel = () => {
        if (location.pathname === "/adminPanel") {
            return true;
        }
        return false;
    }

    const getDisplayRole = (userRoles) => {
        const sequence = [
            "productadmin",
            "productowner",
            "InfraAdmin",
            "OPSEngineer",
            "SRE",
            "Developer",
            "agent_assist_superadmin",
            "agent_assist_admin",
            "agent_assist_sme",
            "agent_assist_agent",
            "agent_assist_user",
            "voice_assistant_service_analyst",
            "voice_assistant_service_manager",
            "voice_assistant_user",
            "Guest"];

        // Create a map of sequence positions
        const sequenceMap = sequence.reduce((acc, item, index) => {
            acc[item] = index;
            return acc;
        }, {});

        // Sort the array according to the sequence map
        const sortedArray = userRoles.sort((a, b) => sequenceMap[a] - sequenceMap[b]);
        return sortedArray[0];
    }

    const getUserRoles = () => {
        // let roleA = "Guest";
        // Object.entries(keycloak.resourceAccess).forEach(([key, value]) => {
        //     if (keycloak.clientId === key) {
        //         value.roles.forEach((es) => {
        //             if(es !== "Guest") {
        //                 roleA = es
        //             }
        //         })
        //     }
        // })
        // return roleA;

        let roleA = "";

        let selectedTenant = sessionStorage.getItem("tenant");
        let tenants = JSON.parse(sessionStorage.getItem("tenants"));
        let userRoles = [];

        tenants.forEach((tenantObj) => {
            if (tenantObj.name === selectedTenant) {
                userRoles = tenantObj.roles;
            }
        })
        roleA = getDisplayRole(userRoles);
        return roleA;
    }

    const onClickMenuItem = (name) => {

        let operationsHub = ["susOpsDashboard", "itGovernance", "itOperations", "securityOps"];
        if (operationsHub.includes(name)) {
            sessionStorage.setItem("HubFlow", "operations");
        }

        history.push(`/${name}`)
        setActiveMenu(name)
    }

    // const blueBuddyRedirect = () => {
    //     window.open(
    //         'https://roksuat.ibmautomationplatform.com/uat/dw/bluebuddy/apps/bluebuddy/launch',
    //         '_blank'
    //     )
    // }

    const onClickService = () => {

        const json = {
            filters: {
                tenant: ['CAI'],
                org: [""],
                time: { start_datetime: "", end_datetime: "" }
            },
            Tab: "Services Management"
        }

        const jwt = sign(json, secret);

        const doUrl = sessionStorage.getItem("doUrl");

        window.open(
            `${doUrl}/?userinfo=${jwt}`,
            '_self'
        )

        // history.push("./DO")
    }

    const onClickIntegrate = () => {
        // history.push("./itOperations")
        setShowNotify(true)
    }

    const setOVerlaySetting = (bool, context) => {
        // context.updateOverlay(bool);
        // sessionStorage.setItem("overlay", "true")
        setOverlayPop(true)
    }


    const overlayDone = (bool) => {
        setOverlayPop(bool)
        //    context.updateOverlay(bool)
    }

    const setHelp = () => {
        window.open(`https://ibm.enterprise.slack.com/archives/C02UKQFGCM6`, '_blank')
    }

    const getAlertCount = () => {
        let count = 0;
        DOExternal !== undefined && Object.entries(DOExternal).forEach(([key, arr]) => {
            Object.entries(arr).forEach(([key, value]) => {
                count += value
            })
        });

        alertCunt.length > 0 && alertCunt.forEach((cunt) => {
            count = count + parseInt(cunt.alert_count)
        })

        return 7;
    }

    const showNotifyAlerts = () => {
        let newArr = [], count = 0;
        DOExternal !== undefined && Object.entries(DOExternal).forEach(([key, arr]) => {
            count = count + Object.values(arr)[0]
        });
        newArr.push({ tickets: count })
        alertCunt.length > 0 && alertCunt.forEach((cunt) => {
            newArr.push(cunt)
        })
        newArr.push({ job: 2 })
        newArr.push({ bp: 1 })

        return <ul
            id="profileId"
            className="header-profile header-notify"
            ref={setWrapperRef}
        >
            {newArr.map((arr) => {
                const newEr = Object.entries(arr).map(([key, value]) => ({ key, value }));
                return <>
                    {/* {newEr[0].key === "sd_incidents_count" && <li onClick={() => onClickIncidnt("SD")}><h5>SD Incidents</h5> <span>{Object.values(arr)}</span></li>}
                        {newEr[0].key === "cc_incidents_count" && <li onClick={() => onClickIncidnt("CC")}><h5>CC Incidents</h5> <span>{Object.values(arr)}</span></li>}
                        {newEr[0].key === "infra_incidents_count" && <li onClick={() => onClickIncidnt("Infra")}><h5>Infra Incidents</h5> <span>{Object.values(arr)}</span></li>} */}
                    {newEr[0].key === "tickets" && <li style={
                            GetPermissions("service_management::read")
                                ? {}
                                : { opacity: "0.5", pointerEvents: "none" }
                        } onClick={() => onClickIncidnt("SD")}><h5>Tickets</h5> <span>1</span></li>}
                    {newEr[0].key === "alert_count" && <li style={
                            GetPermissions("integrated_operations::read")
                                ? {}
                                : { opacity: "0.5", pointerEvents: "none" }
                        }onClick={() => clickNotify("itOperations")}><h5>IT Alerts</h5> <span>{Object.values(arr)}</span></li>}
                    {newEr[0].key === "job" && <li style={
                            GetPermissions("integrated_operations::read")
                                ? {}
                                : { opacity: "0.5", pointerEvents: "none" }
                        }onClick={() => onClickJob()}><h5>Job Alerts</h5> <span>{Object.values(arr)}</span></li>}
                    {newEr[0].key === "bp" && <li style={
                            GetPermissions("it_value_stream_management::read")
                                ? {}
                                : { opacity: "0.5", pointerEvents: "none" }
                        }onClick={() => clickBp("bp")}><h5>Process Alerts</h5> <span>1</span></li>}
                </>
            })}
        </ul>
    }

    const onClickJob = () => {
        sessionStorage.setItem("selectedOpsTab", "2")
        history.push(`/itOperations`)
        setShowNotify(false);
    }

    const clickBp = () => {
        context.valueStreamFlow = "Consumer Packaged Goods";
        context.valueStreamTemplate = 5;
        history.push(`/valueStream`)
        setShowNotify(false);
    }

    const onClickIncidnt = (rout) => {
        const json = {
            filters: {
                tenant: ['CAI'],
                org: ["DevSecOps-INT"],
                time: { start_datetime: "", end_datetime: "" }
            },
            notification: {
                assigned_to: keycloak.tokenParsed.preferred_username,
                route: rout,
                role: getUserRoles()
            },
            Tab: "Services Management"
        }

        const jwt = sign(json, secret);
        const doUrl = sessionStorage.getItem("doUrl");
        window.open(
            `${doUrl}/?userinfo=${jwt}`,
            '_self'
        )

        // history.push("./DO")
    }

    const clickNotify = (name) => {
        if (name === "itOperations") {
            sessionStorage.setItem("selectedOpsTab", "0")
            sessionStorage.setItem("HubFlow", "operations")
        }
        history.push(`/${name}`)
        setShowNotify(false);
    }

    const languageTrans = () => {
        setShowLanguage(true)
    }

    console.log("getUserRoles", getUserRoles())

    return (
        !overlayPop ? <Context.Consumer>
            {(context) => (
                <>
                    <HeaderContainer
                        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                            <>
                                <Header
                                    aria-label="IBM Platform Name"
                                    className={`itvsdp-header`}
                                >
                                    <SkipToContent />
                                    <img src={Logo} alt="Aiops" width={30} />
                                    <HeaderName
                                        href="#"
                                        onClick={() => {
                                            onclickOfHome();
                                            setActiveClass("");
                                            setActiveMenu("");
                                        }}
                                        prefix={t('common.ibmConsulting')}
                                    ></HeaderName>
                                    <HeaderNavigation aria-label={t('common.ibmConsulting')}>
                                        {/* <HeaderMenuItem className="tenant-switch">
                    <ComboBox
                      id="tenant-switch"
                      label="Tenant"
                      titleText="Tenant"
                      items={tenants.tenantsList.sort((a, b) => {
                        let fa = a.displayName.toLowerCase(),
                          fb = b.displayName.toLowerCase();
                        if (fa < fb) {
                          return -1;
                        }
                        if (fa > fb) {
                          return 1;
                        }
                        return 0;
                      })}
                      itemToString={(item) => (item ? item.displayName : "")}
                      onChange={(e) => {
                        if (e.selectedItem) {
                          sessionStorage.removeItem("orgs");
                          sessionStorage.removeItem("currentOrg");
                          sessionStorage.setItem("tenantId", e.selectedItem.id);
                          sessionStorage.setItem("tenant", e.selectedItem.name);
                          changeTenantOld(e.selectedItem.name, context);
                        }
                      }}
                      size="sm"
                      selectedItem={getDisplayname()}
                    />
                  </HeaderMenuItem>
                  <HeaderMenuItem className="tenant-switch">
                    <ComboBox
                      id="org-swtch"
                      label="Organization"
                      titleText="Organization"
                      items={organizations.orgList.sort((a, b) => {
                        let fa = a.name.toLowerCase(),
                          fb = b.name.toLowerCase();
                        if (fa < fb) {
                          return -1;
                        }
                        if (fa > fb) {
                          return 1;
                        }
                        return 0;
                      })}
                      itemToString={(item) => (item ? item.name : "")}
                      onChange={(e) => {
                        sessionStorage.setItem(
                          "currentOrg",
                          JSON.stringify(e.selectedItem),
                        );
                        dispatch(userCurrentOrg(e.selectedItem));
                        history.push("/");
                      }}
                      selectedItem={getOrgDisplayName()}
                      size="sm"
                    />
                  </HeaderMenuItem> */}
                                        <HeaderMenu className={"insightsHub" === activeMenu ? "activeMenu" : ""} aria-label="Insights" menuLinkName={t('common.headers.insights')} isActive={"insightsHub" === activeMenu}>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("it_value_stream_management::read")}`} onClick={GetPermissions("it_value_stream_management::read") === false ? () => { } : () => onClickMenuItem("valueStream")} isActive={"insightsHub" === activeMenu}>{t('common.landingPage.valueStreamManagement')}</HeaderMenuItem>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("sustainable_it_operations::read")}`} onClick={GetPermissions("sustainable_it_operations::read") === false ? () => { } : () => onClickMenuItem("susOpsDashboard")} isActive={"susOpsDashboard" === activeMenu}>
                                                {t('common.landingPage.sustainableITOperations')}
                                            </HeaderMenuItem>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("it_governance_reporting::read")}`} onClick={GetPermissions("it_governance_reporting::read") === false ? () => { } : () => onClickMenuItem("itGovernance")} isActive={"itGovernance" === activeMenu}>{t('common.landingPage.itGovernanceReporting')}</HeaderMenuItem>
                                        </HeaderMenu>

                                        <HeaderMenu className={"OperationsHub" === activeMenu ? "activeMenu" : ""} aria-label="Operations" menuLinkName={t('common.headers.operations')} isActive={"OperationsHub" === activeMenu}>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("service_management::read")}`} onClick={GetPermissions("service_management::read") === false ? () => { } : () => onClickService()} isActive={"service" === activeMenu}>{t('common.landingPage.serviceManagement')}</HeaderMenuItem>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("integrated_operations::read")}`} onClick={GetPermissions("integrated_operations::read") === false ? () => { } : () => onClickMenuItem("itOperations")} isActive={"itOperations" === activeMenu}>
                                                {t('common.landingPage.integratedOperations')}
                                            </HeaderMenuItem>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("security_operations::read")}`} onClick={GetPermissions("security_operations::read") === false ? () => { } : () => onClickMenuItem("securityOps")} isActive={"securityOps" === activeMenu}>{t('common.landingPage.securityOperations')}</HeaderMenuItem>
                                            <HeaderMenuItem className={`submenu ${GetPermissions("product_engineering::read")}`} onClick={GetPermissions("product_engineering::read") === false ? () => { } : () => onClickMenuItem("iALM")} isActive={"iALM" === activeMenu}>{t('common.landingPage.productEngineering')}</HeaderMenuItem>
                                        </HeaderMenu>
                                        <HeaderMenuItem className={"InstrumentationHub" === activeMenu ? `activeMenu` : `menuItem ${GetPermissions("aiops_instrumentation::read")} pointer`} onClick={GetPermissions("aiops_instrumentation::read") === false ? () => { } : () => onClickMenuItem("InstrumentationHub")} isActive={"InstrumentationHub" === activeMenu}>
                                            <span>{t('common.headers.instrumentation')}</span>
                                        </HeaderMenuItem>
                                        {hideTenantOrgHeaderInAdminPanel() ? "" : <HeaderMenuItem><span>{t('common.headers.tenant')}: <strong>{getDisplayname() !== undefined ? getDisplayname() : ""}</strong></span></HeaderMenuItem>}

                                        {hideTenantOrgHeaderInAdminPanel() ? "" : <HeaderMenuItem><span>{t('common.headers.org')}: <strong>{getOrgDisplayName() !== undefined ? getOrgDisplayName() : ""}</strong></span></HeaderMenuItem>}

                                        {/* <HeaderMenuItem>
                                            {hideApplicationsMenu() ? "" : <span>App: <strong>{getApplicationDisplayName()}</strong></span>}
                                        </HeaderMenuItem> */}
                                        <HeaderMenuItem
                                            onClick={() => {
                                                setShowAccountSettings(true);
                                            }}
                                        >
                                            <Switcher size={24} />

                                        </HeaderMenuItem>
                                    </HeaderNavigation>
                                    <HeaderGlobalBar>
                                        {
                                            showLanguage && (
                                                <ul
                                                    id="languageId"
                                                    className="header-profile"
                                                    ref={setWrapperRef}
                                                >
                                                    <li>
                                                        <Link
                                                            onClick={() => {
                                                                i18n.changeLanguage('en');
                                                                sessionStorage.setItem("localeLanguage", "en");
                                                            }}
                                                        >
                                                            {t('English')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="about-button"
                                                            onClick={() => {
                                                                i18n.changeLanguage('jp');
                                                                sessionStorage.setItem("localeLanguage", "jp");
                                                            }}
                                                        >
                                                            {t('Japanese')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="about-button"
                                                            onClick={() => {
                                                                i18n.changeLanguage('es');
                                                                sessionStorage.setItem("localeLanguage", "es");
                                                            }}
                                                        >
                                                            {t('Spanish')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            )
                                        }
                                        <HeaderGlobalAction aria-label={t('common.headers.tour')}
                                            onClick={() => {
                                                setOVerlaySetting(true, context);
                                            }} tooltipAlignment="end">
                                            <Tour size={20} className="carbon--icons-20 icon--white" />
                                        </HeaderGlobalAction>
                                        <HeaderGlobalAction aria-label={t('common.headers.help')}
                                            onClick={() => {
                                                setHelp(true, context);
                                            }} tooltipAlignment="end" >
                                            <CustomerService className="carbon--icons-20 icon--white" />
                                        </HeaderGlobalAction>
                                        <HeaderGlobalAction aria-label={t('common.headers.language')}
                                            onClick={() => {
                                                languageTrans(true, context);
                                            }} tooltipAlignment="end"
                                        >
                                            <Wikis className="carbon--icons-20 icon--white" />
                                        </HeaderGlobalAction>

                                        {/* {getUserRoles() !== "Guest" ? <HeaderGlobalAction
                                            aria-label=""
                                            tooltipAlignment="end"
                                            onClick={() => onClickIntegrate()}
                                        >
                                            <Notification
                                                className="carbon--icons-20 icon--white"
                                                size="20"
                                            />
                                            {alertCount > 0 ? (
                                                <div className="alert-count">{getAlertCount()}</div>
                                            ) : null}
                                        </HeaderGlobalAction> : null} */}
                                        <HeaderGlobalAction
                                            aria-label=""
                                            tooltipAlignment="end"
                                            onClick={() => onClickIntegrate()}
                                        >
                                            <Notification
                                                className="carbon--icons-20 icon--white"
                                                size="20"
                                            />
                                            {alertCount > 0 ? (
                                                <div className="alert-count">{getAlertCount()}</div>
                                            ) : null}
                                        </HeaderGlobalAction>
                                        <HeaderGlobalAction
                                            aria-label={t('common.headers.profile')}
                                            tooltipAlignment="end"
                                            onClick={() => {
                                                setShowProfile(true);
                                                setActiveClass("profile");
                                            }}
                                            className={activeClass === "profile" ? "active" : ""}
                                        >

                                            {/* <img
                                                src={user}
                                                alt="user"
                                                className="carbon--icons-20 icon--white"
                                            /> */}
                                            <UserProfile className="carbon--icons-20 icon--white"
                                                size="20" />
                                        </HeaderGlobalAction>
                                        {showNotify && showNotifyAlerts()}
                                        {showProfile && (
                                            <ul
                                                id="profileId"
                                                className="header-profile"
                                                ref={setWrapperRef}
                                            >
                                                <li className="name-icon">
                                                    <div>
                                                        <p className="user-name">
                                                            {getUserDetails().tokenParsed.preferred_username}
                                                        </p>
                                                        {/* <p className="role-name">
                                                            <Email className="carbon--icons-20 role-icon" size="20" />
                                                            {getUserDetails().tokenParsed.email}
                                                        </p> */}
                                                        <p className="role-name">
                                                            <UserRole className="carbon--icons-20 role-icon" size="20" />
                                                            {getUserRoles()}</p>
                                                    </div>
                                                    <div className="icon-container">
                                                        {/* <img
                                                            src={user}
                                                            alt="user"
                                                            width={20}
                                                            height={20}
                                                            className="icon--white"
                                                        /> */}
                                                        <UserProfile />
                                                    </div>
                                                </li>
                                                <li>
                                                    <Link href="#/adminPanel" className="about-button" disabled={!GetPermissions("admin_panel::read")}>
                                                        Admin Panel
                                                    </Link>
                                                </li>
                                                {/* <li
                                                    onClick={() => {
                                                        setHelp(true, context);
                                                    }}
                                                    className="support"
                                                >
                                                    <Help />
                                                    Support
                                                </li> */}
                                                {/* <li>
                                                    <Link
                                                        href="#/adminPanel"
                                                        className="about-button"
                                                    >
                                                        Admin Panel
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        href="#/collectionConfig"
                                                        className="about-button"
                                                    >
                                                        All Collection Configuration
                                                    </Link>
                                                </li> */}
                                                {/* <li>
                                                    <div className="user--information">
                                                        <Button
                                                            kind="ghost"
                                                            size="sm"
                                                            onClick={() => {
                                                                setActiveClass("themes");
                                                            }}
                                                        >
                                                            Change Theme
                                                        </Button>
                                                    </div>
                                                </li> */}
                                                <div
                                                    className="logout-container"
                                                    onClick={() => showLogoutPopup(true)}
                                                >
                                                    {t('common.headers.logout')} &rarr;
                                                </div>
                                            </ul>
                                        )}
                                        {logoutPopup ? (
                                            <span ref={setWrapperRef}>
                                                <Logout
                                                    cancelModal={() => showLogoutPopup(false)}
                                                    isModal={true}
                                                />
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </HeaderGlobalBar>
                                </Header>
                            </>
                        )}
                    />
                    {
                        showAccountSettings ?
                            <Modal
                                open
                                size="lg"
                                passiveModal
                                onRequestClose={() => setShowAccountSettings(false)}
                                modalHeading={t('common.headers.accountSettings')}
                            >
                                <Grid style={{ paddingLeft: "0" }}>
                                    <Column lg={4} style={{ marginLeft: "0" }}>
                                        <SideNavItems>
                                            <SideNavLink
                                                className={showTenantSwitch ? "activelink" : ""}
                                                renderIcon={CustomerService}
                                                onClick={() => { setShowTenantSwitch(true); setShowOrgSwitch(false); setShowAppSwitch(false); }}
                                                large>
                                                {t('common.headers.tenant')}
                                            </SideNavLink>
                                            <SideNavLink
                                                className={showOrgSwitch ? "activelink" : ""}
                                                renderIcon={CustomerService}
                                                onClick={() => { setShowTenantSwitch(false); setShowOrgSwitch(true); setShowAppSwitch(false); }}
                                                large>
                                                {t('common.headers.organization')}
                                            </SideNavLink>
                                            {/* <SideNavLink
                                                renderIcon={CustomerService}
                                                onClick={() => { setShowTenantSwitch(false); setShowOrgSwitch(false); setShowAppSwitch(true); }}
                                                large>
                                                Application
                                            </SideNavLink> */}
                                        </SideNavItems>
                                    </Column>
                                    <Column lg={12}>
                                        <div style={{ height: "40vh", overflowY: "scroll" }}>
                                            {
                                                showTenantSwitch ? getTenantSwitch(context) : ""
                                            }
                                            {
                                                showOrgSwitch ? getOrgSwitch(context) : ""
                                            }
                                            {/* {
                                                showAppSwitch ? getAppSwitch(context) : ""
                                            } */}
                                        </div>
                                    </Column>
                                </Grid>
                            </Modal> : ""
                    }
                </>
            )}
        </Context.Consumer> : <OverlayRoute overLaydone={overlayDone} />
    );
};

export default AppHeaderNew;
