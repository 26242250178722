import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import React, { useEffect, useState } from "react";
import DashboardDetails from "./KenvueWorkflowView";
import "./kenvue.scss";

const DashboardDetailsIndex = (props) => {
    // const [attFlow, setAttFlow] = useState("");
    // const { t } = useTranslation();
    // useEffect(() => {
    //     if (props?.location && props?.location?.state) {
    //         setAttFlow(props.location.state)
    //     }
    // }, [props.location])
    const [workflowDetails, setWorflowDetails] = useState([]);


   // const workflowDetails = useSelector((state) => state.ATTReducer);
   // const [details, setDetails] = useState([]);

    // useEffect(() => {
    //     if (Object.entries(workflowDetails).length > 0) {
    //         setDetails(workflowDetails.Details)
    //     }
    // }, [workflowDetails])

    useEffect(() => {
        if (props?.location && props?.location?.state) {
            setWorflowDetails(props.location.state.data)
        }
    }, [props.location])

    return (
        Object.entries(workflowDetails).length > 0 ?
            <FlexGrid className="att-dash">
                <Row>
                    <Column lg={12} md={8} sm={4}>
                        {props.location.state.flow !== "value stream" ?
                            <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                                <BreadcrumbItem href="#/operations">
                                    Operations
                                </BreadcrumbItem>
                                <BreadcrumbItem href="#/Kenvue">
                                    Job Dashboard
                                </BreadcrumbItem>
                                <BreadcrumbItem isCurrentPage>
                                    {workflowDetails.dataProduct}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            : <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                                <BreadcrumbItem href="#/VsworkflowView/OTC">
                                    Value Stream
                                </BreadcrumbItem>
                                <BreadcrumbItem isCurrentPage>
                                    {workflowDetails.dataProduct}
                                </BreadcrumbItem>
                            </Breadcrumb>}
                    </Column>
                </Row>
                <DashboardDetails location={props.location} />
            </FlexGrid>
            : null
    )
}

export default DashboardDetailsIndex