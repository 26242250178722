/** @format */

import { SimpleBarChart } from "@carbon/charts-react";
import {
  Button,
  Column,
  DatePicker,
  DatePickerInput,
  ErrorBoundary,
  Grid,
  InlineLoading,
  Layer,
  SkeletonPlaceholder,
  Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getVersionControlReportData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { formatDate, updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";

let repositoryNameBarChartFilter = null;
let branchNameBarChartFilter = null;

const VersionControlReport = () => {
  const repositoryNameBarChartRef = useRef(null);
  const branchNameBarChartRef = useRef(null);
  const context = useContext(Context);

  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  const [startDate, setStartDate] = useState("01-01-2022 00:00");
  const [endDate, setEndDate] = useState("09-15-2022 00:00");
  const [apiLoading, setApiLoading] = useState(false);
  const [APIDataCall, setAPIDataCall] = useState(false);

  const [repositoryNameBarChartLoading, setRepositoryNameBarChartLoading] =
    useState(false);
  const [branchNameBarChartLoading, setBranchNameBarChartLoading] =
    useState(false);

  const { keycloak } = useKeycloak();

  useEffect(() => {
    getAPIData(startDate, endDate, null);
  }, []);

  useEffect(() => {
    setEventListerners();
  }, [repositoryNameBarChartRef, branchNameBarChartRef]);

  const setEventListerners = () => {
    if (repositoryNameBarChartRef.current) {
      repositoryNameBarChartRef.current.chart.services.events.addEventListener(
        "bar-click",
        repositoryNameBarChartOnClick
      );
    }
    if (branchNameBarChartRef.current) {
      branchNameBarChartRef.current.chart.services.events.addEventListener(
        "bar-click",
        branchNameBarChartOnClick
      );
    }
  };

  const repositoryNameBarChartOnClick = ({ detail }) => {
    if (
      repositoryNameBarChartFilter &&
      repositoryNameBarChartFilter === detail.datum.group
    ) {
      setRepositoryNameBarChartLoading(true);
      getAPIData(startDate, endDate, null);
    } else {
      repositoryNameBarChartFilter = detail.datum.group;
      setRepositoryNameBarChartLoading(true);
      getAPIData(startDate, endDate, detail.datum.group, "RepoName");
    }
  };

  const branchNameBarChartOnClick = ({ detail }) => {
    if (
      branchNameBarChartFilter &&
      branchNameBarChartFilter === detail.datum.group
    ) {
      setBranchNameBarChartLoading(true);
      getAPIData(startDate, endDate, null);
    } else {
      branchNameBarChartFilter = detail.datum.group;
      setBranchNameBarChartLoading(true);
      getAPIData(startDate, endDate, detail.datum.group, "BranchName");
    }
  };

  const getRowCellData = (id, data, row) => {
    return data;
  };
  const getTableHeader = (header) => {
    return header;
  };

  const getChartLoader = () => {
    return (
      <Layer className="chart-loader__layer">
        <InlineLoading
          status="active"
          iconDescription="Loading"
          description="Loading data..."
        />
      </Layer>
    );
  };

  const getAPIData = async (sDate, eDate, filter, filterName) => {
    if (filter === null) {
      repositoryNameBarChartFilter = null;
      branchNameBarChartFilter = null;
    }
    const response = await getVersionControlReportData(
      keycloak.token,
      sDate,
      eDate,
      filter,
      filterName
    );
    AddUserActivity(keycloak, context, "FEATURE", "Version Control", response);

    setIsAPIDataLoading(false);
    setApiLoading(false);

    setRepositoryNameBarChartLoading(false);
    setBranchNameBarChartLoading(false);

    setErrorMessage(updateErrorMessage(response));
    setAPIData(updateAPIData(response));
    if (APIDataCall === false) {
      setEventListerners();
      setAPIDataCall(true);
    }
  };

  const onStartDateChange = (e) => {
    setStartDate(formatDate(new Date(e[0]).toDateString()));
    if (e.length > 1) {
      setEndDate(formatDate(new Date(e[1]).toDateString()));
    }
  };

  const updateAPI = async () => {
    setApiLoading(true);
    await getAPIData(startDate, endDate, null);
  };

  const getHeadingAndDateFilter = () => {
    return (
      <>
        <Column lg={8}>
          <h3>{APIData.data.label}</h3>
        </Column>
        <Column lg={8} className="flex-display flex-end override-form-flex">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <DatePicker
              dateFormat="Y-m-d"
              datePickerType="range"
              onChange={(e) => onStartDateChange(e)}
              className="date-picker--margin"
            >
              <DatePickerInput
                id="date-picker-range-start"
                placeholder="yyyy-mm-dd"
                labelText="Start Date"
                defaultValue="2022-01-01"
                type="text"
              />
              <DatePickerInput
                id="date-picker-range-end"
                placeholder="yyyy-mm-dd"
                labelText="End Date"
                defaultValue="2022-09-15"
                type="text"
              />
            </DatePicker>
          </ErrorBoundary>
          <span>
            {apiLoading ? (
              <InlineLoading
                status="active"
                iconDescription="Loading"
                description="Loading data..."
                className="report-update__button"
              />
            ) : (
              <Button
                kind="primary"
                onClick={updateAPI}
                size="md"
                iconDescription="Icon Description"
                className="report-update__button"
              >
                Update
              </Button>
            )}
          </span>
        </Column>
      </>
    );
  };

  const createChartExplicit = () => {
    return (
      <>
        {getHeadingAndDateFilter()}
        {(APIData.data.data[1].data.chartData &&
          APIData.data.data[1].data.chartData.length > 0) ||
          (APIData.data.data[2].data.chartData &&
            APIData.data.data[2].data.chartData.length > 0) ? (
          <>
            <Column lg={8} md={8} sm={4}>
              <Tile className="chart-container">
                <ErrorBoundary fallback={"Data is unavailable"}>
                  {APIData.data.data[1].data.chartData &&
                    APIData.data.data[1].data.chartData.length > 0 ? (
                    <>
                      {repositoryNameBarChartLoading ? getChartLoader() : ""}
                      <SimpleBarChart
                        ref={repositoryNameBarChartRef}
                        data={APIData.data.data[1].data.chartData}
                        options={APIData.data.data[1].data.chartOptions}
                      ></SimpleBarChart>
                    </>
                  ) : (
                    "Data is unavailable"
                  )}
                </ErrorBoundary>
              </Tile>
            </Column>

            <Column lg={8} md={8} sm={4}>
              <Tile className="chart-container">
                <ErrorBoundary fallback={"Data is unavailable"}>
                  {APIData.data.data[2].data.chartData &&
                    APIData.data.data[2].data.chartData.length > 0 ? (
                    <>
                      {branchNameBarChartLoading ? getChartLoader() : ""}
                      <SimpleBarChart
                        ref={branchNameBarChartRef}
                        data={APIData.data.data[2].data.chartData}
                        options={APIData.data.data[2].data.chartOptions}
                      ></SimpleBarChart>
                    </>
                  ) : (
                    "Data is unavailable"
                  )}
                </ErrorBoundary>
              </Tile>
            </Column>
            <Column lg={16} md={8} sm={4}>
              <ErrorBoundary fallback={<ErrorWhileFetching />}>
                <div className="scrollable-table">
                  <CarbonDataTable
                    rowData={APIData.data.data[0].data.rowData}
                    headerData={APIData.data.data[0].data.headerData}
                    title={APIData.data.data[0].label}
                    getRowCellData={getRowCellData}
                    getTableHeader={getTableHeader}
                  />
                </div>
              </ErrorBoundary>
            </Column>
          </>
        ) : (
          <Column lg={16} md={8} sm={4}>
            <DataUnAvailable />
          </Column>
        )}
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {APIData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
    </div>
  );
};

export default VersionControlReport;
