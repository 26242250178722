import React from "react";

import { GroupedBarChart } from "@carbon/charts-react";

class BarChartSkeleton extends React.Component {
	state = {
		data: [],
		options: {
			title: "",
			axes: {
				left: {},
				bottom: {
					scaleType: "labels",
				},
			},
			data: {
				loading: true,
			},
			height: "400px",
		},
	};

	render = () => (
		<GroupedBarChart
			data={this.state.data}
			options={this.state.options}
		></GroupedBarChart>
	);
}

export default BarChartSkeleton;
