/** @format */
import { combineReducers } from "redux";
import LoginSuccessful from "Components/landingPage/store/reducer/LoginPageReducer";
import GetTenants from "Tenants/store/reducer";
import LandingPageReducer from "Components/landingPage/store/reducer/LandingPageReducer";

const rootReducer = combineReducers({
    LoginSuccessful,
    GetTenants,
    LandingPageReducer
});
export default rootReducer;
