import React, { useState } from "react";
import { ArrowRight } from "@carbon/react/icons";
import { ClickableTile, Column, InlineLoading, SkeletonPlaceholder, Tag } from "@carbon/react";

const VSClickableTile = ({ Json, handleOnClick, colmn, flow, valueStreamFlow }) => {
    const [active, setActive] = useState("")

    const tileClick = (data) => {
        setActive(data.name)
        handleOnClick(data)
    }

    return (
        <>
            {Json !== undefined ? Json.map((data, index) => (
                <Column
                    lg={4}
                    md={4}
                    sm={2}
                    className={`clickable-tile-portfolio-att pnc-tiles ${valueStreamFlow}`}
                    key={data.name}
                >
                    <ClickableTile
                        id={index}
                        onClick={() => tileClick(data)}
                        aria-label={`clickableTile-${index}`}
                        className={active === data.name ? "active" : ""}
                        disabled={data.value === ""}
                    >
                        <div className="title">
                            <h4 className="flow-title">{data.name} </h4>
                            <Tag className="some-class" type={data.value === "100%" ? "green" : "red"}>
                                {data.value === "100%" ? "Success" : "Error"}
                            </Tag>
                        </div>
                        <div className="sec-col">
                            {data.value !== "" ? <span className={data.value === "100%" ? "green" : data.value === "99%" ? "orange" : "red"}>{data.value}</span> : <InlineLoading status="active" iconDescription="Loading" description="" />}
                            <h6>Availability</h6>
                        </div>
                        {/* {flow === "service" ?
                            <ul className="serv-info">
                                <li><span className={data.responseTime.includes("min") ? "red" : ""}>{data.errors}</span><h5>Errors</h5></li>
                                <li><span className={data.responseTime.includes("min") ? "red" : ""}>{data.responseTime}</span><h5>Response Time</h5></li>
                            </ul> : <div className="sec-col">
                                {data.value !== "" ? <span className={data.value === "100%" ? "green" : data.value === "99%" ? "orange" : "red"}>{data.value}</span> : <InlineLoading status="active" iconDescription="Loading" description="" />}
                                {["mnemonic", "customer"].includes(flow) ? <h5>Availability</h5> : null}
                            </div>} */}
                        {/* <div className={`anamoly green`}>
                                <h3> {data.percent}%</h3>
                                ({data.success} / {data.total})
                            </div> */}
                        <div>
                            <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                        </div>
                    </ClickableTile>
                </Column>
            )) : [1, 2, 3, 4].map((ske) =>
                <Column
                    lg={4}
                    md={4}
                    sm={2}
                    className="clickable-tile-portfolio-att margin-lft"
                    key={ske}
                >
                    <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
                </Column>
            )}
        </>
    )
}

export default VSClickableTile;