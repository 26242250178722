
/** @format */
import axios from "axios";
{/* TODO: Will need to enable the below later */ }
// import ApplicationsJSON from "Components/Admin/adminPanel/Applications/applications.json";
// import OrganizationsJSON from "Components/Admin/adminPanel/Organizations/organizations.json";
// import TenantsJSON from "Components/Admin/adminPanel/Tenants/tenants.json";


{/* TODO: Will need to enable the below later */ }

// import Organizations from "./organizations.json";

//AFTER REMOVING STATIC

const BFF_URL = sessionStorage.getItem("bff");
const SVC_URL = sessionStorage.getItem("svc");

export const DEFAULT_TENANT = process.env.REACT_DEFAULT_TENANT;
export const DEFAULT_MONITORING_TOOL = "AWS";
export const MONITORING_TOOL_INSTANA = "Instana";

export const getDefaultRealm = () => {
  // return process.env.REACT_DEFAULT_TENANT;
  return sessionStorage.getItem("tenant")
    ? sessionStorage.getItem("tenant")
    : DEFAULT_TENANT;
  // : "pmi";
};

export const getCurrentOrganization = () => {
  return sessionStorage.getItem("organizationName")
    ? sessionStorage.getItem("organizationName")
    : "62f4ea8ed804a7125ae45e9e";
};

export const getCurrentApplication = () => {
  return sessionStorage.getItem("applicationName")
    ? sessionStorage.getItem("applicationName")
    : "6396d8196e9bbbaf07fc9f13";
};

export const getTenantDetails = async (token) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/reports/tenants/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getTenantAssociatedToUser = async (token) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/usertenantmapping/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

// export const getOrganizationsAssociatedToUser = async (token) => {
//   let realm = getDefaultRealm();

//   return axios
//     .get(
//       `${SVC_URL}/icpsa/rbac/user-organization-mapping/tenant/${realm}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "X-Tenant": realm,
//       }
//     }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       return "Error";
//     });
// };

export const getApplicationsAssociatedToUser = async (token) => {
  let realm = getDefaultRealm();
  let organizationId = sessionStorage.getItem("organization");

  return axios
    .get(
      `${SVC_URL}/icpsa/rbac/user-application-mapping/tenant/${realm}/organization/${organizationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

{/* TODO: Will need to enable the below later */ }

// export const getTenantOrganizations = async (token) => {
//   let realm = getDefaultRealm();
//   // const config = {
//   //   headers: {
//   //     Authorization: `Bearer ${token}`,
//   //   },
//   // };
//   // return axios
//   //   .get(`${BFF_URL}/reports/${realm}/tenantOrganizations/data`, config)
//   //   .then((response) => {
//   //     return response;
//   //   })
//   //   .catch((err) => {
//   //     console.log(err);
//   //     return "Error";
//   //   });
//   return Organizations;
// };

export const getTopologyDetailsData = async (token, componentARN, startTime, endTime) => {
  let realm = getDefaultRealm();
  let partyName = getCurrentOrganization();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/observability/topology?component_arn=${componentARN}&startDate=${startTime}&endDate=${endTime}&accTypeName=${realm}&partyName=${partyName}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getServicesData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let partyName = getCurrentOrganization();
  let applicationName = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/services?startDate=${startDate}&endDate=${endDate}&accTypeName=${realm}&partyName=${partyName}&applicationName=${applicationName}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getApplicationComponentsOrgData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let orgId = getCurrentOrganization();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/applicationsOrg?startDate=${startDate}&endDate=${endDate}&orgId=${orgId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getApplicationComponentsData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let partyName = getCurrentOrganization();
  let applicationName = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/applications?startDate=${startDate}&endDate=${endDate}&accTypeName=${realm}&partyName=${partyName}&applicationName=${applicationName}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getRCAInsightsReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/rCAInsights?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/rCAInsights?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getRCAInsightsTableData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/rCAInsights/table?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};


export const getSLAManagementData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/sLAManagement/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/sLAManagement/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getSLAManagementTableData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/sLAManagement/table?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getIssueCategorizationData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/issueCategorization/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/issueCategorization/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getOutageDurationReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/outageDuration/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/outageDuration/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getSimilarTicketsTrendReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/similarTicketsTrend/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/similarTicketsTrend/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPipelineUsageReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/pipelineUsage/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/pipelineUsage/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getVersionControlReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = filter ? `${BFF_URL}/reports/${realm}/versionControl/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/versionControl/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getEventBridgeReportData = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/eventBridge/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getAppGitHubOrgInsightsDetails = async (token, applicationId, startDate, endDate) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/gitHubOrg/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGitHubOrgInsightsDetails = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/gitHubOrg/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGitHubReportData = async (token, startDate, endDate, orgId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/gitHub/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&orgId=${orgId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGitHubRepoDetailsReportData = async (token, startDate, endDate, repoId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/gitHub/repo-details?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&repoId=${repoId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGitHubCommitsReportData = async (token, startDate, endDate, repoId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/gitHub/commits?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&repoId=${repoId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getProbableRCA = async (token, anomaly, metrics) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/probableRCA/data?anomaly=${anomaly}&metrics=${metrics}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
  // return {
  //   data: {
  //     "message": "Data fetched successfully",
  //     "err": false,
  //     "apiResponseStatus": 200,
  //     "isDataAvailable": true,
  //     "data": {
  //       "Confidence_score": 1,
  //       "Probable root cause": "json: cannot unmarshal object into Go value of type []petlistadoptions.pet"
  //     }
  //   }
  // }
};

export const getGenAIRunBookCodeRecommendations = async (token, description, recommendation, type, metrics, eventId) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/runbook/codeRecommendation?description=${description}&recommendation=${recommendation}&type=${type}&metrics=${metrics}&event_id=${eventId}`, config)
};

export const getAutomateGuestToken = async (token) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/automatex/token`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGenAISummaryAPI = async (token, anomaly, metrics, log, type) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/genAISummary/data?anomaly=${anomaly}&metrics=${metrics}&log=${log}&type=${type}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
  // return {
  //   data: {
  //     "message": "Data fetched successfully",
  //     "err": false,
  //     "apiResponseStatus": 200,
  //     "isDataAvailable": true,
  //     "data": {
  //       "reason": "\nThe given CloudWatch Logs indicate that there are multiple occurrences of \"Error parsing HTTP request header\" errors being logged. This could be due to an issue with the way the Lambda function is handling HTTP requests, such as a problem with the request headers or payload. Additionally, it's possible that there are issues with the Elastic Load Balancing (ELB) configuration or the target service that the Lambda function is invoking.\nTo troubleshoot this issue, you can review the Lambda function code and check for any errors that may be occurring during the processing of HTTP requests. You can also check the ELB configuration to ensure",
  //       "remediation": "\nThe given log indicates that there was an error in parsing the HTTP request header. This could be due to various reasons such as an incorrect header format, missing or invalid parameters, or issues with the server-side configuration. Further occurrences of HTTP request parsing errors will be logged at the DEBUG level.\nTo troubleshoot this issue, you can check the Lambda function code and ensure that the HTTP request headers are being correctly parsed. You can also check the CloudWatch logs for any additional information or error messages related to the parsing error. Additionally, you can check the server-side configuration to ensure that it is set up correctly and that",
  //       "code": {
  //         "inputTextTokenCount": "430",
  //         "results": [
  //           {
  //             "tokenCount": "193",
  //             "outputText": "To resolve the error, add the below code to the application code:\r\n```Python\r\nimport logging\r\nfrom botocore.exceptions import ClientError\r\n# Create a logger\r\nlogger = logging.getLogger(__name__)\r\ndef handle_request(event, context):\r\n    logger.info(\"Received event: %s\", event)\r\n    # Add your code here to handle the request\r\n    # Example:\r\n    # \tif event['httpMethod'] == 'PUT':\r\n    # \t\t# Perform a PUT operation\r\n    # \t\treturn {\r\n    # \t\t\t'statusCode': 200,\r\n    # \t\t\t'headers': {'Content-Type': 'text/plain'},\r\n    # \t\t\t'body': 'PUT request successful'\r\n    # \t\t}\r\n    # \telse:\r\n    # \t\t# Return a 400 Bad Request response\r\n    # \t\treturn {\r\n    # \t\t\t'statusCode': 400,\r\n    # \t\t\t'headers': {'Content-Type': 'text/plain'},\r\n    # \t\t\t'body': 'Invalid HTTP method'\r\n    # \t\t}\r\n```\r\nThe provided code is a serverless function in Python that uses the AWS Lambda service. It includes a logger named \"logger\" to log information about the received event. The \"handle_request\" function takes two parameters: \"event\" and \"context\". It logs the event and then proceeds to add custom logic to handle the request. In the example code, it checks if the \"httpMethod\" of the event is \"PUT\" and performs a PUT operation. If the \"httpMethod\" is not \"PUT\", it returns a 400 Bad Request response. This code snippet can be used as a starting point to build a serverless application that handles requests and responds with appropriate HTTP status codes and content.",
  //             "completionReason": "FINISH"
  //           }
  //         ]
  //       }
  //     }
  //   }
  // }
};

export const getGenAISummarySimilarAPI = async (token, ticketTitle, ticketDescription) => {

  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/genAISummary/similar/data?ticketTitle=${ticketTitle}&ticketDescription=${ticketDescription}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
  // return {
  //   data: {
  //     "message": "Data fetched successfully",
  //     "err": false,
  //     "apiResponseStatus": 200,
  //     "isDataAvailable": true,
  //     "data": [
  //       {
  //         "id": "INC112229",
  //         "distance": "0.30",
  //         "document": "Ticket Title: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link., Ticket Description: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link.",
  //         "ASSIGNEE_NAME": "Deeksha Tendulkar",
  //         "ISSUE_CATEGORY": "state issue",
  //         "OWNER_GROUP_NAME": "DevSecOps",
  //         "PRIORITY": "P3 - Medium",
  //         "SEVERITY": "Medium",
  //         "TICKET_TYPE": "AWS/DynamoDB"
  //       },
  //       {
  //         "id": "INC290097",
  //         "distance": "0.30",
  //         "document": "Ticket Title: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link., Ticket Description: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link.",
  //         "ASSIGNEE_NAME": "Deeksha Tendulkar",
  //         "ISSUE_CATEGORY": "state issue",
  //         "OWNER_GROUP_NAME": "DevSecOps",
  //         "PRIORITY": "P3 - Medium",
  //         "SEVERITY": "Medium",
  //         "TICKET_TYPE": "AWS/DynamoDB"
  //       },
  //       {
  //         "id": "INC828945",
  //         "distance": "0.30",
  //         "document": "Ticket Title: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link., Ticket Description: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link.",
  //         "ASSIGNEE_NAME": "Deeksha Tendulkar",
  //         "ISSUE_CATEGORY": "state issue",
  //         "OWNER_GROUP_NAME": "DevSecOps",
  //         "PRIORITY": "P3 - Medium",
  //         "SEVERITY": "Medium",
  //         "TICKET_TYPE": "AWS/DynamoDB"
  //       },
  //       {
  //         "id": "INC866533",
  //         "distance": "0.30",
  //         "document": "Ticket Title: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link., Ticket Description: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link.",
  //         "ASSIGNEE_NAME": "Deeksha Tendulkar",
  //         "ISSUE_CATEGORY": "state issue",
  //         "OWNER_GROUP_NAME": "DevSecOps",
  //         "PRIORITY": "P3 - Medium",
  //         "SEVERITY": "Medium",
  //         "TICKET_TYPE": "AWS/DynamoDB"
  //       },
  //       {
  //         "id": "INC165595",
  //         "distance": "0.30",
  //         "document": "Ticket Title: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link., Ticket Description: DynamoDB WriteThrottleEvents Anomalous In Application Services Read operations, write operations, or both on your DynamoDB table are being throttled. To learn how to fix throttle events, see this link.",
  //         "ASSIGNEE_NAME": "Deeksha Tendulkar",
  //         "ISSUE_CATEGORY": "state issue",
  //         "OWNER_GROUP_NAME": "DevSecOps",
  //         "PRIORITY": "P3 - Medium",
  //         "SEVERITY": "Medium",
  //         "TICKET_TYPE": "AWS/DynamoDB"
  //       }
  //     ]
  //   }
  // }
};

export const getGenAIRunBookRecommendations = async (token, ticketTitle, ticketDescription) => {

  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/genAISummary/runbookRecommendations/data?ticketTitle=${ticketTitle}&ticketDescription=${ticketDescription}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPowerBiAccessToken = async (token) => {
  let realm = getDefaultRealm();

  return fetch(`${SVC_URL}/powerbi/powerbi-embed-token/tenant/${realm}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    }
  })
    .then((response) => response.text())

    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err);
    });
}

export const getRunBookRecommendations = async (token, serviceType, rootCause) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  // return axios
  //   .get(`${BFF_URL}/reports/${realm}/runbook/data?servicetype=${serviceType}&rootcause=${rootCause}&applicationId=${applicationId}`, config)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     return "Error";
  //   });
  return {
    data: {
      "message": "Data fetched successfully",
      "err": false,
      "apiResponseStatus": 200,
      "isDataAvailable": true,
      "data": [
        {
          "Automation link": "https://eu-central-1.console.aws.amazon.com/systems-manager/documents/AWS-CreateJiraIssue/description?region=eu-central-1",
          "Automation recommended": "AWS-CreateJiraIssue"
        },
        {
          "Automation link": "https://eu-central-1.console.aws.amazon.com/systems-manager/documents/AWS-CreateServiceNowIncident/description?region=eu-central-1",
          "Automation recommended": "AWS-CreateServiceNowIncident"
        }
      ]
    }
  }
};

export const getSimilarProbableRCA = async (token, probableRCA) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/probableRCA/similar/data?probableRCA=${probableRCA}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const postSimilarProbableRCA = async (token, probableRCA, namespace) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/probableRCA/similar/data?probableRCA=${probableRCA}&namespace=${namespace}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getAutoPatcherEvents = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };

  let url = filter ? `${BFF_URL}/reports/${realm}/autoPatcher/data?startDate=${startDate}&endDate=${endDate}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/autoPatcher/data?startDate=${startDate}&endDate=${endDate}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getDeploymentInsightsReportData = async (token, startDate, endDate, filter, filterName) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };

  let url = filter ? `${BFF_URL}/reports/${realm}/deploymentInsights/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}&filter=${filter}&filterName=${filterName}` : `${BFF_URL}/reports/${realm}/deploymentInsights/data?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`;

  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const resolveAPI = async (token) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/patchmanagement/podupdate/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getTenant = async (token, tenantName) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/tenants/${tenantName}/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

{/* TODO: Will need to enable the below later */ }

// export const getAllTenantsTable = async (token) => {
//   let response = await getAllTenants(token);
//   let updatedResponse = TenantsJSON;
//   updatedResponse.data.rowData = response.data;
//   return updatedResponse;
// };

export const getAllTenants = async (token) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/tenants/all/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const addTenant = async (token, payload) => {
  let realm = getDefaultRealm();

  return axios
    .post(
      `${SVC_URL}/rbac/tenants/add/tenant/${realm}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getOrganization = async (token, organizationName) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/organizations/${organizationName}/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

{/* TODO: Will need to enable the below later */ }

// export const getAllOrganizationsTable = async (token) => {
//   let response = await getAllOrganizations(token);
//   let updatedResponse = OrganizationsJSON;
//   updatedResponse.data.rowData = response.data;
//   return updatedResponse;
// };

export const getAllOrganizations = async (token) => {
  let realm = getDefaultRealm();

  let tenant = sessionStorage.getItem("tenantDetails") ? JSON.parse(sessionStorage.getItem("tenantDetails")).name : getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/organizations/all/tenant/${realm}?realm=${tenant}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const addOrganization = async (token, payload) => {
  let realm = getDefaultRealm();
  let tenant = JSON.parse(sessionStorage.getItem("tenantDetails")).name;

  return axios
    .post(
      `${SVC_URL}/rbac/organizations/add/tenant/${realm}?realm=${tenant}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getApplication = async (token, applicationName) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/applications/${applicationName}/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getAllApplications = async (token) => {
  let realm = getDefaultRealm();

  let tenant = JSON.parse(sessionStorage.getItem("tenantDetails")).name;
  return axios
    .get(
      `${SVC_URL}/rbac/applications/all/tenant/${realm}?realm=${tenant}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

{/* TODO: Will need to enable the below later */ }

// export const getAllApplicationsTable = async (token) => {
//   let response = await getAllApplications(token);
//   let updatedResponse = ApplicationsJSON;
//   updatedResponse.data.rowData = response.data;
//   return updatedResponse;
// };

export const addApplication = async (token, payload) => {
  let realm = getDefaultRealm();
  let organization = getCurrentOrganization();
  // let tenant = JSON.parse(sessionStorage.getItem("tenantDetails")).name;
  let tenant = sessionStorage.getItem("tenant");
  return axios
    .post(
      `${SVC_URL}/icpsa/rbac/applications/add/organization/${organization}/tenant/${realm}?realm=${tenant}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getTenantWizard = async (token) => {
  let realm = getDefaultRealm();
  return axios
    .get(
      `${SVC_URL}/itvsdp/wizard/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

// POST http://localhost:8080/auth/realms/<my_realm>/protocol/openid-connect/logout
// Authorization: Bearer <access_token>
// Content-Type: application/x-www-form-urlencoded

// client_id=<my_client_id>&refresh_token=<refresh_token></refresh_token>

// export const logout = async (token) => {
//   let realm = getDefaultRealm();

//   return axios
//     .post(
//       `${KEYCLOAK_URL}/realms/${realm}/protocol/openid-connect/logout`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/x-www-form-urlencoded"
//       }
//     }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       return "Error";
//     });
// };

export const logout = async (token) => {
  let realm = getDefaultRealm();

  return axios
    .get(
      `${SVC_URL}/rbac/logout/tenant/${realm}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": realm,
      }
    }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "Error";
    });
};

export const getPlatformInsightsOrgDetails = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let orgId = getCurrentOrganization();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformInsightsOrg?startDate=${startDate}&endDate=${endDate}&orgId=${orgId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getAppPlatformInsightsDetails = async (token, applicationId, startDate, endDate) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformInsights?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPlatformInsightsDetails = async (token, startDate, endDate) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformInsights?startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPlatformAlertsDetails = async (token, startDate, endDate, hostIds) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformAlerts?hostIds=${hostIds}&startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPlatformAlertsFeedback = async (token, alertId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformAlertFeedback?alertId=${alertId}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const postPlatformAlertsFeedback = async (token, alertId, body) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .post(`${BFF_URL}/reports/${realm}/addPlatformAlertFeedback?alertId=${alertId}&applicationId=${applicationId}`, body, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPlatformMetricsDetails = async (token, startDate, endDate, alertId, hostId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformMetrics?alertId=${alertId}&hostId=${hostId}&startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getPlatformLogsDetails = async (token, startDate, endDate, alertId, hostId) => {
  let realm = getDefaultRealm();
  let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .get(`${BFF_URL}/reports/${realm}/platformLogs?alertId=${alertId}&hostId=${hostId}&startDate=${startDate}&endDate=${endDate}&applicationId=${applicationId}`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const addEvents = async (token, body) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  return axios
    .post(`${SVC_URL}/reports/userevent/add/tenant/${realm}`, body, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
  // return {}
}

export const getUserActivity = async (token, startDate, endDate, tenant) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  let url = `${BFF_URL}/reports/${realm}/userEvents/data?startDate=${startDate}&endDate=${endDate}&tenant=${tenant}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getServiceNowCharts = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/incidents/charts?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data");
    });
};

export const getServiceNowTables = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/incidents/tables?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data")
    });
};

export const getServiceRequestsCharts = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/servicerequests/charts?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data");
    });
};

export const getServiceRequestsTables = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/servicerequests/tables?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data")
    });
};

export const getChangeRequestsCharts = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/changerequests/charts?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data");
    });
};

export const getChangeRequestsTables = async (startDate, endDate, token) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/changerequests/tables?startDate=${startDate}&endDate=${endDate}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data")
    });
};

export const getServiceChangerequests = async (token) => {
  const realm = getDefaultRealm();
  // // let applicationId = getCurrentApplication();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": realm,
    },
  };
  const url = `${BFF_URL}/reports/${realm}/serviceNow/changeRequests`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data");
    });
};

export const getAlertStatusfromInstana = async (eventId) => {
  const config = {
    headers: {
      Authorization: 'apiToken T14pfCXpQRSMVH2KnTdkEw',
    },
  };
  const url = `https://aeltc-sep.instana.io/api/events/${eventId}`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw new Error("Error fetching data");
    });
};

export const checkOrgDupilicates = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${SVC_URL}/org/checkduplicate/tenant/${realm}?orgName=${data}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const postOrgDetails = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.post(
      `${SVC_URL}/org/create/tenant/${realm}`,
      data,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

export const deleteOrganization = async (keycloak, id, uuid) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.delete(
      `${SVC_URL}/org/remove/tenant/${realm}?orgIdref=${id}`,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};