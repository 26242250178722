

import {
    InlineLoading
} from "@carbon/react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";

function AutomateX() {
    const [isChartLoading, setIsChartLoading] = useState(true);

    // const baseURL = "https://superset-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud";
    const baseURL = process.env.REACT_APP_SUPERSET_ROOT;
    const chartId = process.env.REACT_APP_SUPERSET_CHART_ID;
    const guestUsername = process.env.REACT_APP_SUPERSET_GUEST_USERNAME;
    const embedID = process.env.REACT_APP_SUPERSET_EMBED_ID;

    const getAccessToken = async () => {

        const getAccessTokenURL = `${baseURL}/api/v1/security/login`;
        const getAccessTokenPayload = { "username": "guest", "password": "guest", "provider": "db", "refresh": "true" };
        const getAccessTokenConfig = {
            headers: {
                "Accept": "application/json", "Content-Type": "application/json"
            }
        };

        return axios
            .post(`${getAccessTokenURL}`, getAccessTokenPayload, getAccessTokenConfig)
            .then((response) => {
                return response.data.access_token;
            })
            .catch((err) => {
                console.log(err);
                return "Error";
            });
    };

    const getCSRFToken = async (accessToken) => {
        const getCSRFURL = `${baseURL}/api/v1/security/csrf_token/`;
        const getCSRFConfig = {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Accept": "application/json"
            }
        };

        return axios
            .get(`${getCSRFURL}`, getCSRFConfig)
            .then((response) => {
                return response.data.result;
            })
            .catch((err) => {
                console.log(err);
                return "Error";
            });
    };

    const getGuestToken = async () => {
        let accessToken = await getAccessToken();
        let CSRFToken = await getCSRFToken(accessToken);

        const getGuestTokenURL = `${baseURL}/api/v1/security/guest_token/`;
        const getGuestTokenPayload = {
            "resources": [
                {
                    "id": chartId,
                    "type": "dashboard"
                }
            ],
            "rls": [
            ],
            "user": {
                "first_name": "guest",
                "last_name": "guest",
                "username": guestUsername
            }
        };
        const getGuestTokenConfig = {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "X-CSRFToken": `${CSRFToken}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        };

        return axios
            .post(`${getGuestTokenURL}`, getGuestTokenPayload, getGuestTokenConfig)
            .then((response) => {
                setIsChartLoading(false);
                return response.data.token;
            })
            .catch((err) => {
                setIsChartLoading(false);
                console.log(err);
                return "Error";
            });
    };

    useEffect(() => {
        const embed = async () => {
            await embedDashboard({
                id: embedID, // given by the Superset embedding UI
                supersetDomain: baseURL,
                mountPoint: document.getElementById("dashboard"), // html element in which iframe render
                fetchGuestToken: () => getGuestToken(),
                dashboardUiConfig: {
                    hideTitle: true,
                    hideChartControls: true,
                    hideTab: true,
                },
            })
        }
        if (document.getElementById("dashboard")) {
            embed()
        }
    }, [])

    return (
        <>
            <div>
                {
                    isChartLoading ?
                        <InlineLoading
                            status="active"
                            iconDescription="Loading"
                            description="Loading Charts..."
                            className="report-update__button"
                        /> : ""
                }
            </div>

            <div className="App">
                < div id="dashboard" style={{ minHeight: "100vh", minHeight: "100vw" }} />
            </div>
        </>
    )
}

export default AutomateX;
