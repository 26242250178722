import { Column, Grid } from '@carbon/react'
import { WarningAltFilled } from "@carbon/react/icons/index"
import React from 'react'
import { Link } from 'react-router-dom'
import errorIcon from "../Assets/Common/error.svg"
import "../Common-Modules/css/errorPage.scss"
import Logout from './Logout'

const Page500 = () => {
	return (
		<Grid className="page-401">
			<Column lg={16} md={8} sm={4}>
				<img src={errorIcon} alt="error" className="error-svg" />
				<div className="error-desc">
					<h2>Error <WarningAltFilled size="20" fill="#da1e28" /></h2>
					<p>We apologize for the inconvenience caused</p>
					<p>Click to Go <Link to="/">Home</Link></p>
					<p>or try Logout and Login again.</p>
					<Logout />
				</div>
			</Column>
		</Grid>
	)
}

export default Page500