import React from "react";
import { Modal } from "@carbon/react";

const DefaultModal = (props) => {
	return (
		<Modal
			open
			modalHeading={props.heading}
			primaryButtonText={props.primaryText}
			secondaryButtonText={props.secondaryText}
			onRequestClose={() => props.onCloseModal(true)}
			onRequestSubmit={() => props.onModalSubmit()}
			primaryButtonDisabled={props.primaryButtonDisabled}
		>
			{props.children}
		</Modal>
	);
};

export default DefaultModal;
