import { Dropdown, ErrorBoundary, FormLabel } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
// import { authorizationValidity } from "Components/Common-Components/Utilities";
// import ErrorFallback from "Components/Common/ErrorFallback";
// import { findApiError } from "Components/Common/ErrorHandling";
// import { getApplicationsAssociatedToUser } from "ICPSAservices/serverapi";
import ErrorFallback from "Common-Modules/ErrorFallback";
import { findApiError } from "Common-Modules/ErrorHandling";
// import { authorizationValidity } from "Common-Modules/Utilities";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import CarbonDataTableRBAC from "Components/Common/CarbonDataTableRBAC";
import { getApplicationsAPI, getTenantOrgApplicationsAPI, getTenantOrganizationsAPI } from "Services/RBACServerApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ApplicationDetails from "./ApplicationDetails";
import CreateApplication from "./CreateApplication";
import DeleteAppModal from "./DeleteAppModal";
import EditApp from "./EditApp";
import addApp from "./addApp.json";
import { storeApps } from "./store/action/actionTriggers";

const ApplicationList = () => {
  const [createEnable, setCreateEnable] = useState(false);
  const [rowData, setRowData] = useState([]);
  const { keycloak } = useKeycloak();
  const [showError, setShowError] = useState("");
  const [canShowDeleteAppPopUp, setCanShowDeleteAppPopUp] = useState(false);
  const [deletedRow, setDeletedRow] = useState("");
  const [editBox, showEditBox] = useState(false);
  const [editApp, setEditApp] = useState(undefined);
  const dispatch = useDispatch();
  //const UserInformation = useSelector((state) => state.UserInformation);

  const [isRecordsEmpty, setIsRecordsEmpty] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [selectedOrgItem, setSelectedOrgItem] = useState({ id: '', text: '' });
  const [applicationRow, setApplicationRow] = useState({});
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [addAppJSONUpdated, setAddAppJSONUpdated] = useState([]);

  const headerData = [
    {
      key: "name",
      header: "Application Name",
    },
    {
      key: "description",
      header: "Description",
    },
    {
      key: "aws_region_city",
      header: "Region",
    },
    {
      key: "resource_grp_name",
      header: "Resource Group name",
    },
    {
      key: "deployment_region",
      header: "Deployment Region",
    },
    {
      key: "startDate",
      header: "Starts on",
    },
    {
      key: "endDate",
      header: "Ends on",
    }
  ];

  const setSelectedTenantinAddAppJSON = (tenant) => {
    addApp.forEach((formField) => {
      if (formField.id === "tenant") {
        formField.inputValue = tenant;
      }
    })
    console.log("addApp: ", addApp);
  }

  const setSelectedOrginAddAppJSON = (organization) => {
    addApp.forEach((formField) => {
      if (formField.id === "organization") {
        formField.inputValue = organization;
      }
    })
    console.log("addApp: ", addApp);
  }

  useEffect(() => {
    setTenants(JSON.parse(sessionStorage.getItem("alltenants")));
    getTenantOrganisations(sessionStorage.getItem("tenant"))
    // setOrgs(JSON.parse(sessionStorage.getItem("selectedtenantorgs")));
    setSelectedTenant(sessionStorage.getItem("tenant"));
    setSelectedTenantinAddAppJSON(sessionStorage.getItem("tenant"));

    setSelectedOrg(sessionStorage.getItem("organizationName"));
    setSelectedOrginAddAppJSON(sessionStorage.getItem("organizationName"));
    // getApplicationTableData();
  }, []);

  useEffect(() => {
    if (selectedTenant && selectedOrg) {
      getTenantOrgApplicationTableData(selectedTenant, selectedOrg);
    }
  }, [selectedOrg]);

  const getTenantOrganisations = async (tenant) => {
    setShowError("");
    const res = await getTenantOrganizationsAPI(keycloak, uuidv4(), tenant);
    try {
      if (Boolean(res.error)) throw new Error(res.message);

      const errCheck = findApiError(res);
      if (errCheck.error) {
        throw new Error(errCheck.message);
      }
      if (res.data.length === 0) {
        setIsRecordsEmpty(true);
      } else {
        setIsRecordsEmpty(false);
        setOrgs(res.data);
        setSelectedOrg(res.data[0].name);
        setSelectedOrgItem({ id: res.data[0].id, text: res.data[0].name });
        setSelectedOrginAddAppJSON(res.data[0].name);
        // getTenantOrgApplicationTableData(selectedTenant ? selectedTenant : sessionStorage.getItem("tenant"), res.data[0].name);
        sessionStorage.setItem("selectedtenantorgs", JSON.stringify(res.data));
        // dispatch(storeOrgs(res.data));
        // getTenantOrgApplicationTableData(tenant, res.data[0].name);
      }
    } catch (error) {
      setShowError("Something went wrong!. Please, Try again after sometime");
    }
  };

  const getTenantOrgApplicationTableData = async (tenant, org) => {
    setShowError("");
    const res = await getTenantOrgApplicationsAPI(keycloak, uuidv4(), tenant, org);

    try {
      if (Boolean(res.error) && !Array.isArray(res?.data)) throw new Error(res.message);

      const errCheck = findApiError(res);
      if (errCheck.error && !Array.isArray(res?.data)) {
        throw new Error(errCheck.message);
      }
      // if (res.data.length === 0) {
      //   setIsRecordsEmpty(true);
      // } else {
      // setIsRecordsEmpty(false);
      setRowData(res.data);
      dispatch(storeApps(res.data));
      // }
    } catch (error) {
      console.log("INISDE CATCH getTenantOrgApplicationTableData")
      setShowError("Something went wrong!. Please, Try again after sometime");
    }
  };

  const getApplicationTableData = async () => {
    setShowError("");
    const res = await getApplicationsAPI(keycloak, uuidv4());

    try {
      if (Boolean(res.error) && !Array.isArray(res?.data)) throw new Error(res.message);

      const errCheck = findApiError(res);
      if (errCheck.error) {
        throw new Error(errCheck.message);
      }
      // if (res.data.length === 0) {
      //   setIsRecordsEmpty(true);
      // } else {
      //   setIsRecordsEmpty(false);
      let appData = res.data;
      let apps = [];
      appData.forEach((app) => {
        if (app.name !== "dso" && app.name !== "itvsdp") {
          let appObj = {};
          appObj.id = app.id;
          appObj.name = app.name;
          appObj.description = app.attributes?.description?.toString();
          appObj.aws_region_city = app.attributes?.aws_region_city?.toString();
          appObj.resource_grp_name = app.attributes?.resource_grp_name?.toString();
          appObj.deployment_region = app.attributes?.deployment_region?.toString();
          appObj.startDate = app.attributes?.startDate?.toString();
          appObj.endDate = app.attributes?.endDate?.toString();
          apps.push(appObj);
        }
      });
      setRowData(apps);
      dispatch(storeApps(apps));
      // }
    } catch (error) {
      console.log("INISDE CATCH getApplicationTableData")

      setShowError("Something went wrong!. Please, Try again after sometime");
    }
  };

  const createAppFunc = () => {
    setCreateEnable(true);
  };

  const onRefresh = () => {
    setRowData([]);
    // getApplicationTableData();
    getTenantOrgApplicationTableData(selectedTenant, selectedOrg);
  };

  const handleClose = (reload) => {
    setDeletedRow("");
    setCanShowDeleteAppPopUp(false);
    setCreateEnable(false);
    if (reload) {
      onRefresh();
    }
  };

  const getTableHeader = (header) => {
    if (!["createdBy", "updatedBy"].includes(header)) {
      return header;
    }
  };

  const getApplicationRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getApplicationRow(rowData, id);
    setApplicationRow(row);
    setSelectedApplicationinJSON(row);
    setShowApplicationDetails(true);
  };
  sessionStorage.setItem("selectedApp", applicationRow.name);

  const getRowCellData = (id, data, row) => {
    if (id.includes(":name")) {
      return (
        <a
          className="event-status"
          onClick={() => {
            onClickNode(row.id);
          }}
        >
          {data}
        </a>
      );
    }
    return <>{data}</>;
  };

  const onEdit = (selected) => {
    let data = "";
    rowData.forEach((row) => {
      if (row.id === selected.id) data = row;
    });
    setEditApp(data);
    showEditBox(true);
  };

  // const getActions = (row, rows) => {
  //   return (
  //     <div className="flex-display">
  //       <li className="actions-icon ">
  //         <Tooltip align="bottom" description="Edit">
  //           <button type="button" className="tooltip-button">
  //             <Edit size={16} fill="#525252" onClick={() => onEdit(row)} />
  //           </button>
  //         </Tooltip>
  //       </li>
  //       <li className="actions-icon ">
  //         <Tooltip align="bottom" description="Delete">
  //           <button type="button" className="tooltip-button">
  //             <TrashCan
  //               size={16}
  //               fill="#525252"
  //               onClick={() => deleteApp(row, rows)}
  //             />
  //           </button>
  //         </Tooltip>
  //       </li>
  //     </div>
  //   );
  // };

  const deleteApp = (row, rows) => {
    let selectedRow;
    rowData.forEach((data) => {
      if (row.id === data.id) {
        selectedRow = data;
      }
    });
    setDeletedRow(selectedRow);
    setCanShowDeleteAppPopUp(true);
  };

  const onTenantDropdownChange = (event) => {
    setSelectedTenant(event.selectedItem.text);
    setSelectedTenantinAddAppJSON(event.selectedItem.text);
    getTenantOrganisations(event.selectedItem.text)
    // getTenantOrgApplicationTableData(event.selectedItem.text, selectedOrg);
  }
  sessionStorage.setItem("selectedTenant", selectedTenant);

  const getTenantDropdownList = (data) => {
    return data.map((dataObject) => ({
      id: dataObject.id,
      text: dataObject.name,
    }));
  }

  const onOrgDropdownChange = (event) => {
    setSelectedOrg(event.selectedItem.text);
    setSelectedOrgItem(event.selectedItem);
    setSelectedOrginAddAppJSON(event.selectedItem.text);
    // getTenantOrgApplicationTableData(selectedTenant, event.selectedItem.text);
  }
  sessionStorage.setItem("selectedOrg", selectedOrg);

  const getOrgDropdownList = (data) => {
    return data.map((dataObject) => ({
      id: dataObject.id,
      text: dataObject.name,
    }));
  }

  const setSelectedApplicationinJSON = (tenant) => {
    let addAppJSONUpdatedTemp = JSON.parse(JSON.stringify(addApp));
    addAppJSONUpdatedTemp.forEach((formField) => {
      if (formField.id === "name") {
        formField.inputValue = tenant.name;
        formField.readOnly = true;
      }
      if (formField.id === "description") {
        formField.inputValue = tenant.description;
        formField.readOnly = true;
      }
      if (formField.id === "aws_region_city") {
        formField.inputValue = tenant.aws_region_city;
        formField.readOnly = true;
      }
      if (formField.id === "engagement_name") {
        formField.inputValue = tenant.engagement_name;
        formField.readOnly = true;
      }
      if (formField.id === "resource_grp_name") {
        formField.inputValue = tenant.resource_grp_name;
        formField.readOnly = true;
      }
      if (formField.id === "deployment_region") {
        formField.inputValue = tenant.deployment_region;
        formField.readOnly = true;
      }
      if (formField.id === "startDate") {
        formField.inputValue = tenant.startDate;
        formField.readOnly = true;
      }
      if (formField.id === "endDate") {
        formField.inputValue = tenant.endDate;
        formField.readOnly = true;
      }
    })
    setAddAppJSONUpdated(addAppJSONUpdatedTemp);
  }

  const onCloseApplicationDetails = () => {
    setShowApplicationDetails(false)
  };

  return (
    showApplicationDetails ? <ApplicationDetails tenant={selectedTenant} organization={selectedOrg} addApplication={addAppJSONUpdated} applicationRow={applicationRow} closeApplicationDetails={onCloseApplicationDetails} /> :
      <>
        <Dropdown
          size="md"
          // label="Select Tenant"
          label={selectedTenant}
          itemToString={(item) => (item ? item.text : "")}
          items={getTenantDropdownList(tenants)}
          onChange={(event) => {
            onTenantDropdownChange(event);
          }}
          titleText="Tenants"
        />
        <Dropdown
          size="md"
          // label="Select Org"
          // label={selectedOrg}
          label={selectedOrgItem.text || selectedOrg}
          itemToString={(item) => (item ? item.text : "")}
          items={getOrgDropdownList(orgs)}
          onChange={(event) => {
            onOrgDropdownChange(event);
          }}
          titleText="Orgs"
          selectedItem={selectedOrgItem}
        />
        {!showError ? (
          <>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <FormLabel className="form-lable--default">Apps</FormLabel>
              <CarbonDataTableRBAC
                rowData={rowData}
                headerData={headerData}
                title="Applications"
                createTitle="Create Application"
                getRowCellData={getRowCellData}
                getTableHeader={getTableHeader}
                // disableCreate={
                //   // !authorizationValidity(
                //   //   "application::create",
                //   //   UserInformation.userCurrentPermissions,
                //   // )
                //   !keycloak.realmAccess.roles.includes("Dashboard_Admin")
                // }
                deleteRow={deleteApp}
                createFunction={createAppFunc}
                onRefresh={onRefresh}
                actionsNeeded={false}
              // table_particular_actions={true}
              // getActions={getActions}
              />
            </ErrorBoundary>
          </>
        ) : (
          // <ErrorFallback />
          <DataUnAvailable />
        )}
        {createEnable && <CreateApplication closeCreateApp={handleClose} addApp={addApp} />}
        {canShowDeleteAppPopUp ? (
          <DeleteAppModal app={deletedRow} cancel={handleClose} />
        ) : null}
        {editBox ? (
          <EditApp
            app={editApp}
            cancel={(refresh) => {
              setEditApp("");
              showEditBox(false);
              if (refresh) onRefresh();
            }}
          />
        ) : null}
      </>
  );
};

export default ApplicationList;
