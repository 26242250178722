/** @format */

import React from "react";
import Context from "./Context";

class Provider extends React.Component {
    // state = {
    //     LoginSuccessful: false,
    //     UserPermissions: [],
    //     valueStreamFlow:"Banking",
    //     valueStreamData: {},
    //     nerveCenter: {},
    //     UserInfo: {},
    //     valueStreamTemplate:1,
    //     overlay: true,
    // };
    constructor(props) {
        super(props);

        const tenant = sessionStorage.getItem("tenant");

        this.state = tenant === "ibmconsulting" 
            ? {
                LoginSuccessful: false,
                UserPermissions: [],
                valueStreamFlow: "Banking",
                valueStreamData: {},
                nerveCenter: {},
                UserInfo: {},
                valueStreamTemplate: 1,
                overlay: true,
            }
            : {
                LoginSuccessful: false,
                UserPermissions: [],
                valueStreamFlow: "Telco with IBM Process Mining",
                valueStreamData: {},
                nerveCenter: {},
                UserInfo: {},
                valueStreamTemplate: 6,
                overlay: true,
            };
    }

    render() {
        // console.log("state", this.state)
        return (
            <Context.Provider
                value={{
                    LoginSuccessful: this.state.LoginSuccessful,
                    updateLogin: (LoginSuccessful) =>
                        this.setState({ LoginSuccessful }),
                    UserPermissions: this.state.UserPermissions,
                    UserInfo: this.state.UserInfo,
                    valueStreamFlow:this.state.valueStreamFlow,
                    valueStreamData:this.state.valueStreamData,
                    nerveCenter: this.state.nerveCenter,
                    valueStreamTemplate:this.state.valueStreamTemplate,
                    overlay: this.state.overlay,
                    updateOverlay: (overlay) =>
                        this.setState({ overlay }),
                }}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}

export default Provider;

