/** @format */

import {
    Button,
    Tag
} from "@carbon/react";
import { Renew } from '@carbon/react/icons/index';
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { resolveAPI } from "../Services/ServerApi";
// import "./../css/_chart.scss";

const AutoPatcherDetails = (props) => {
    const { keycloak } = useKeycloak();
    const [patchedDetails, setPatchedDetails] = useState([]);

    useEffect(() => {
        setPatchedDetails(props.details.patched_instance_details);
    }, []);

    const getTextBackgroundColorClassName = (text) => {
        switch (text) {
            case "InProgress": return "priority-background-color__orange";
            case "Online": return "priority-background-color__green";
            case "Success": return "priority-background-color__green";
            case "Offline": return "priority-background-color__gray";
            case "ConnectionLost": return "priority-background-color__gray";
            case "Failed": return "priority-background-color__red";
            case "Passed": return "priority-background-color__green";
            case "Not_available": return "priority-background-color__gray";
            default: return "priority-background-color__gray"
        }
    }

    const resolve = async (index) => {
        const resolveAPIResponse = await resolveAPI(keycloak.token);
        let updatedPatchedDetails = [];

        updatedPatchedDetails = patchedDetails.map(
            (data, clickIndex) => {
                if (index === clickIndex) {
                    if (resolveAPIResponse.status === 200) {
                        data.resolveResponse = true;
                    } else {
                        data.resolveResponse = false;
                    }
                }
                return data;
            }
        );

        setPatchedDetails(updatedPatchedDetails);

    }

    const getTiles = () => {
        return (
            patchedDetails.map((data, index) => (
                <>
                    {/* <Column lg={8} md={8} sm={4} className="clickable--tile-wizard"> */}
                    {/* <Tile
                        id={index}
                    > */}
                    {
                        data.instance_patching_status === "Failed" ?
                            <Button renderIcon={Renew} iconDescription="Retry" size="sm" className="button__icon"
                                onClick={() => resolve(index)}>Retry
                            </Button>
                            : ""
                    }
                    <h5 className="report-title">{data.instance_name} </h5>
                    <Tag
                        type="green"
                        size="sm"
                        title={data.distance}
                        className="text-on-tile__secondary_tag"
                    >
                        Instance Id: {data.instance_id}
                    </Tag>

                    <p className="common-top-margin__modal  text-on-tile__small ">Ping Status:
                        <span className={`table--col--highlight text-on-tile__small ${getTextBackgroundColorClassName(data.instance_ping_status)}`}>{data.instance_ping_status}</span>
                    </p>

                    <p className="common-top-margin__modal  text-on-tile__small ">Patching Status:
                        {
                            data.resolveResponse === true
                                ? <span className={`table--col--highlight text-on-tile__small ${getTextBackgroundColorClassName("InProgress")}`}>In Progress</span>
                                : <span className={`table--col--highlight text-on-tile__small ${getTextBackgroundColorClassName(data.instance_patching_status)}`}>{data.instance_patching_status}</span>
                        }
                    </p>
                    {/* </Tile> */}
                    {/* </Column> */}
                </>
            ))

        );
    }

    return (
        <>
            {getTiles()}
        </>
    );
};

export default AutoPatcherDetails;
