import React from "react";
import PlatformInsightsReport from "./PlatformInsightsReport";

const PlatformInsights = () => {
  return (
    <>
      {/* <Grid>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Platform Insights</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Grid> */}
      <div className="content-div">
        <PlatformInsightsReport />
      </div>
    </>
  );
};

export default PlatformInsights;
