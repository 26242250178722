import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import CarbonTabs from "Carbon-Components/Tabs";
import React from "react";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import AppSecurity from "./AppSecurity";
import ContainerSecurity from "./ContainerSecurity";
import DAST from "./DAST/index";
import SAST from "./SAST";

const SecurityOps = () => {


    const hubs = sessionStorage.getItem("HubFlow");

    const getTabList = () => {
        if (hubs === "insights") {
            return [
                "DAST",
                "SAST"
            ];
        }
        else if (hubs === "operations") {
            return [
                "Container Security",

            ];
        }
        else {
            return [
                "Container Security",
                "Application Security"
            ];
        }
    }

    const getTabPanelsList = () => {
        if (hubs === "insights") {
            let panelList = [];

            if (GetPermissions("dast::read")) {
                panelList.push(<DAST />);
            }
            if (GetPermissions("sast::read")) {
                panelList.push(<SAST />);
            }
            return panelList;
            // return [
            //     <DAST />,
            //     <SAST />
            // ];
        }
        else if (hubs === "operations") {
            let panelList = [];

            if (GetPermissions("container_security::read")) {
                panelList.push(<ContainerSecurity />);
            }
            return panelList;
            // return [
            //     <ContainerSecurity />
            // ];
        }
        else {
            let panelList = [];

            if (GetPermissions("container_security::read")) {
                panelList.push(<ContainerSecurity />);
            }
            if (GetPermissions("application_security::read")) {
                panelList.push(<AppSecurity />);
            }
            return panelList;
            // return [
            //     <ContainerSecurity />,
            //     <AppSecurity />
            // ];
        }
    }

    const getDisabledList = () => {

        let disabledList = [];

        if (!GetPermissions("dast::read")) {
            disabledList.push("DAST");
        }
        if (!GetPermissions("sast::read")) {
            disabledList.push("SAST");
        }
        if (!GetPermissions("container_security::read")) {
            disabledList.push("Container Security");
        }
        if (!GetPermissions("application_security::read")) {
            disabledList.push("Application Security");
        }
        console.log("disabledList: ", disabledList);
        return disabledList;
    }

    return (
        <FlexGrid className="securityOps">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
                        <BreadcrumbItem isCurrentPage>
                            Security Operations
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <CarbonTabs
                        tabIndex={0}
                        selectionMode="automatic"
                        type="default"
                        Tablist={getTabList()}
                        disabledList={getDisabledList()}
                        TabPanelsList={getTabPanelsList()}
                    />
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default SecurityOps;