/** @format */

import {
  Column,
  ComboBox,
  ErrorBoundary,
  Grid,
  SkeletonPlaceholder,
  Tile
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { getGitHubReportData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
// import "./../css/_chart.scss";
import { DonutChart, TreemapChart } from "@carbon/charts-react";
import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import { useHistory, useLocation } from "react-router-dom";

// let repositoryNameBarChartFilter = null;
// let branchNameBarChartFilter = null;

const GitHubReposReport = (props) => {
  // const repositoryNameBarChartRef = useRef(null);
  // const branchNameBarChartRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);

  const [apiLoading, setApiLoading] = useState(false);
  // const [showPopUP, setShowPopUP] = useState(false);
  const [eventDetailsRow, setEventDetailsRow] = useState();
  const [selectedOrganizationName, setSelectedOrganizationName] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();

  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  // const [repositoryNameBarChartLoading, setRepositoryNameBarChartLoading] =
  //   useState(false);
  // const [branchNameBarChartLoading, setBranchNameBarChartLoading] =
  //   useState(false);

  useEffect(() => {

    let startDateDefault = "2022-09-13";
    let endDateDefault = new Date().toISOString().split("T")[0];

    setDefaultStartDate(startDateDefault);
    setDefaultEndDate(new Date().toISOString().split("T")[0]);

    startDateDefault += " 00:00";
    endDateDefault += " 00:00";

    setStartDate(startDateDefault);
    setEndDate(endDateDefault);

    setSelectedOrganizationName(props.organizationNames[props.organizationNames.length - 1]);
    setSelectedOrganization(props.organizations[props.organizationNames.length - 1]);
    getAPIData(startDateDefault, endDateDefault, props.organizations[props.organizationNames.length - 1].id);
  }, []);

  // useEffect(() => {
  //   setEventListerners();
  // }, [repositoryNameBarChartRef, branchNameBarChartRef]);

  // const setEventListerners = () => {
  //   if (repositoryNameBarChartRef.current) {
  //     repositoryNameBarChartRef.current.chart.services.events.addEventListener(
  //       "bar-click",
  //       repositoryNameBarChartOnClick
  //     );
  //   }
  //   if (branchNameBarChartRef.current) {
  //     branchNameBarChartRef.current.chart.services.events.addEventListener(
  //       "bar-click",
  //       branchNameBarChartOnClick
  //     );
  //   }
  // };

  const getDetailsRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const handleOnClick = (route, eventDetailsRow, APIData) => {
    if (route) {
      sessionStorage.setItem("gitHubRepo", JSON.stringify(eventDetailsRow));
      sessionStorage.setItem("gitHubRepoData", JSON.stringify(APIData.data.charts[1]));
      // history.push({ pathname: route, state: { title: title } });
      history.push({ pathname: route });
    }
  };

  const onClickNode = (id) => {
    let row = getDetailsRow(APIData.data.charts[0].data.rowData, id);
    setEventDetailsRow(row);
    // setShowPopUP(true);

    handleOnClick(
      location.pathname.includes("icpsa/platformInsights") ?
        "/gitHubRepoDetails"
        :
        "gitHubRepoDetails",
      row,
      APIData
    )
  };

  // const getPopupData = () => {
  //   return <GitHubDetails details={eventDetailsRow} data={APIData.data.charts[1]} />;
  // };

  // const repositoryNameBarChartOnClick = ({ detail }) => {
  //   if (
  //     repositoryNameBarChartFilter &&
  //     repositoryNameBarChartFilter === detail.datum.group
  //   ) {
  //     setRepositoryNameBarChartLoading(true);
  //     getAPIData(startDate, endDate, null);
  //   } else {
  //     repositoryNameBarChartFilter = detail.datum.group;
  //     setRepositoryNameBarChartLoading(true);
  //     getAPIData(startDate, endDate, detail.datum.group, "RepoName");
  //   }
  // };

  // const branchNameBarChartOnClick = ({ detail }) => {
  //   if (
  //     branchNameBarChartFilter &&
  //     branchNameBarChartFilter === detail.datum.group
  //   ) {
  //     setBranchNameBarChartLoading(true);
  //     getAPIData(startDate, endDate, null);
  //   } else {
  //     branchNameBarChartFilter = detail.datum.group;
  //     setBranchNameBarChartLoading(true);
  //     getAPIData(startDate, endDate, detail.datum.group, "BranchName");
  //   }
  // };

  const getRowCellData = (id, data, row) => {
    if (id.includes("id")) {
      return (
        <a
          className="event-status"
          onClick={() => {
            onClickNode(row.id);
          }}
        >
          {data}
        </a>
      );
    }
    if (id.includes("eventExecutionTime")) {
      return String(new Date(data).toLocaleString());
    }
    return data;
  };
  const getTableHeader = (header) => {
    return header;
  };

  // const getChartLoader = () => {
  //   return (
  //     <Layer className="chart-loader__layer">
  //       <InlineLoading
  //         status="active"
  //         iconDescription="Loading"
  //         description="Loading data..."
  //       />
  //     </Layer>
  //   );
  // };

  const getAPIData = async (sDate, eDate, orgId) => {
    const response = await getGitHubReportData(
      keycloak.token,
      sDate,
      eDate,
      orgId
    );
    AddUserActivity(keycloak, context, "FEATURE", "Event bridge Insights", response);

    setIsAPIDataLoading(false);
    setApiLoading(false);
    setErrorMessage(updateErrorMessage(response));

    //let APIData = updateAPIData(response);
    setAPIData(updateAPIData(response));
  };

  // const formatDate = (date) => {
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-") + " 00:00";
  // };

  // const onStartDateChange = (e) => {
  //   setStartDate(formatDate(new Date(e[0]).toDateString()));
  //   if (e.length > 1) {
  //     setEndDate(formatDate(new Date(e[1]).toDateString()));
  //   }
  // };

  const updateAPI = async (orgId) => {
    setApiLoading(true);
    await getAPIData(startDate, endDate, orgId);
  };

  // const getHeadingAndDateFilter = () => {
  //   return (
  //     <>
  //       <Column lg={16}>
  //         <h3>{APIData.data.label}</h3>
  //       </Column>
  //       {/* <Column lg={8} className="flex-display flex-end override-form-flex">
  //         <ErrorBoundary fallback={"Data is unavailable"}>
  //           <DatePicker
  //             dateFormat="Y-m-d"
  //             datePickerType="range"
  //             onChange={(e) => onStartDateChange(e)}
  //             className="date-picker--margin"
  //           >
  //             <DatePickerInput
  //               id="date-picker-range-start"
  //               placeholder="yyyy-mm-dd"
  //               labelText="Start Date"
  //               defaultValue={defaultStartDate}
  //               type="text"
  //             />
  //             <DatePickerInput
  //               id="date-picker-range-end"
  //               placeholder="yyyy-mm-dd"
  //               labelText="End Date"
  //               defaultValue={defaultEndDate}
  //               type="text"
  //             />
  //           </DatePicker>
  //         </ErrorBoundary>
  //         <span>
  //           {apiLoading ? (
  //             <InlineLoading
  //               status="active"
  //               iconDescription="Loading"
  //               description="Loading data..."
  //               className="report-update__button"
  //             />
  //           ) : (
  //             <Button
  //               kind="primary"
  //               onClick={updateAPI}
  //               size="md"
  //               iconDescription="Icon Description"
  //               className="report-update__button"
  //             >
  //               Update
  //             </Button>
  //           )}
  //         </span>
  //       </Column> */}
  //     </>
  //   );
  // };

  // const filterItems = menu => {
  //   return menu?.item?.toLowerCase().includes(menu?.inputValue?.toLowerCase());
  // };

  const createChartExplicit = () => {
    return (
      <>
        {/* {getHeadingAndDateFilter()} */}
        <Column lg={8}>
          <div style={{ width: 300 }}>
            {/* <ComboBox allowCustomValue shouldFilterItem={filterItems} onChange={(item) => {
              setSelectedOrganizationName(item.selectedItem);
              props.organizations.forEach((organization) => {
                if (organization.host_name === item.selectedItem) { setSelectedOrganization(organization); }
              })

            }} id="carbon-combobox" items={props.organizationNames} titleText="Organizations" helperText="Select Organization" /> */}

            <ComboBox onChange={(item) => {
              setSelectedOrganizationName(item.selectedItem);
              props.organizations.forEach((organization) => {
                if (organization.version_control_org_name === item.selectedItem) {
                  setSelectedOrganization(organization);
                  updateAPI(organization.id);
                }
              })
            }} id="carbon-combobox" items={props.organizationNames}
              // itemToString={item => item ? item.text : ''} 
              titleText="Organizations" helperText="Select Organization"
              initialSelectedItem={selectedOrganizationName} />
          </div>
        </Column>
        <Column lg={8}><Tile>{getOrganizationDetails()}</Tile></Column>
        {APIData.data.charts[0].data.rowData &&
          APIData.data.charts[0].data.rowData.length > 0 ? (
          <>
            <Column lg={11} md={8} sm={4}>
              <Tile className="chart-container">
                <ErrorBoundary fallback={"Data is unavailable"}>
                  {APIData.data.charts[1].data.chartData &&
                    APIData.data.charts[1].data.chartData.length > 0 ? (
                    <>
                      {/* <SimpleBarChart
                        data={APIData.data.charts[1].data.chartData}
                        options={APIData.data.charts[1].data.chartOptions}
                      ></SimpleBarChart> */}
                      <TreemapChart
                        data={APIData.data.charts[1].data.chartData}
                        options={APIData.data.charts[1].data.chartOptions}
                      ></TreemapChart>
                    </>
                  ) : (
                    "Data is unavailable"
                  )}
                </ErrorBoundary>
              </Tile>
            </Column>

            <Column lg={5} md={8} sm={4}>
              <Tile className="chart-container">
                <ErrorBoundary fallback={"Data is unavailable"}>
                  {APIData.data.charts[2].data.chartData &&
                    APIData.data.charts[2].data.chartData.length > 0 ? (
                    <>
                      {/* <SimpleBarChart
                        data={APIData.data.charts[2].data.chartData}
                        options={APIData.data.charts[2].data.chartOptions}
                      ></SimpleBarChart> */}
                      <DonutChart
                        data={APIData.data.charts[2].data.chartData}
                        options={APIData.data.charts[2].data.chartOptions}
                      ></DonutChart>
                    </>
                  ) : (
                    "Data is unavailable"
                  )}
                </ErrorBoundary>
              </Tile>
            </Column>
            <Column lg={16} className="common-top-margin">
              {APIData.data.charts[0].data.rowData &&
                APIData.data.charts[0].data.rowData.length > 0 ? (
                <ErrorBoundary fallback={<ErrorWhileFetching />}>
                  <CarbonDataTable
                    rowData={APIData.data.charts[0].data.rowData}
                    headerData={APIData.data.charts[0].data.headerData}
                    title={APIData.data.charts[0].label}
                    getRowCellData={getRowCellData}
                    getTableHeader={getTableHeader}
                    actionsNeeded={false}
                  />
                </ErrorBoundary>
              ) : (
                "Data is unavailable!"
              )}
            </Column>
          </>
        ) : (
          <Column lg={16} md={8} sm={4}>
            <DataUnAvailable />
          </Column>
        )}
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  // const getReadableDate = (date) => {
  //   return String(new Date(date).toDateString());
  // }

  const getOrganizationDetails = () => {

    //   {
    //     "_id" : ObjectId("66a9d9b5912360b18c6ade19"),
    //     "tenant_id" : ObjectId("633fc00f45544e06599d36f3"),
    //     "organization_id" : ObjectId("62f4ea8ed804a7125ae45e9e"),
    //     "application_id" : ObjectId("6396d8196e9bbbaf07fc9f13"),
    //     "version_control_org_id" : NumberInt(72),
    //     "version_control_org_name" : "serviceengage",
    //     "description" : "",
    //     "version_control_org_repos" : [
    //     ],
    //     "version_control_org_members" : [
    //     ],
    //     "source" : "GitHub"
    // }

    return <>
      <h4 className="text-tile">{selectedOrganization.version_control_org_name}</h4>
      <p>{selectedOrganization.description}</p>
      <strong style={{ position: "absolute", top: "15px", right: "15px" }}>{selectedOrganization.version_control_org_id}</strong>
      <div style={{ paddingTop: "1rem", fontSize: "12px" }}>
        <span>Repositories: {selectedOrganization.version_control_org_repos.length}</span><br />
        <span>Members: {selectedOrganization.version_control_org_members.length}</span>
      </div>
    </>
  }

  return (
    <div className="container-multichart">
      {APIData ?
        <>
          <Grid>{createChartExplicit()}</Grid>
        </>
        : loadSkeleton()
      }
    </div>
  );
};

export default GitHubReposReport;
