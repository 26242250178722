import {
  ErrorBoundary,
  Row
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache } from "@tanstack/react-query";
import ErrorFallback from "Common-Modules/ErrorFallback";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import valueStream from "./ValueStream.json";
import VSClickableTile from "./valueStreamClickable";

const VSDashboard = ({ valueStreamFlow }) => {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  //const queryCache = new QueryCache();
  const [valueStreamData, setValueStream] = useState();

  // const {
  //   data: valueStream,
  //   isLoading: loading1,
  //   isError: err1,
  //   isFetching: fetching1,
  //   refetch,
  // } = useQuery(
  //   [`valueStream`],
  //   async () =>
  //     await getAnyCollection(keycloak, uuidv4(), "valueStreamDetails", "soc"),
  //   {
  //     cache: queryCache,
  //     cacheTime: 60 * 60 * 1000,
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);
  

  useEffect(() => {
    if (valueStream !== undefined && valueStream.data !== undefined) {
      const response = valueStream.data.valueStreamDetails.filter(
        (fil) => fil.title === valueStreamFlow
      );
      if(response.length > 0) {
        setValueStream(response[0].data);
      }
    }
  }, [valueStream ,valueStreamFlow]);

  const handleOnClick = (data) => {
    sessionStorage.setItem("portfolioTitle", data.name);
    sessionStorage.setItem("portfolioDescription", data.description);
    history.push({ pathname: `VsworkflowView/${data.name}`, state : {flowName : valueStreamFlow} });
  };

  return (
    <Row>
      <ErrorBoundary fallbakc={<ErrorFallback />}>
        <VSClickableTile valueStreamFlow={valueStreamFlow} Json={valueStreamData} handleOnClick={handleOnClick} />
      </ErrorBoundary>
    </Row>
  );
};

export default VSDashboard;
