import React from "react";
import InstrumentationComponent from "./InstrumentationMain";

const InstrumentationIndex = () => {
    return (
        <>
            <InstrumentationComponent />
        </>
    )
}

export default InstrumentationIndex;