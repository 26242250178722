/** @format */

import React from "react";
import { ErrorBoundary, Column, Grid } from "@carbon/react";
import ChartContainer from "./ChartContainer";

const DefectsMultiChartContainer = (props) => {

  const createChart = (data) => {
    return data.data.map((type) => {
      return !["line", "meter", "donut"].includes(type.dataType) ? (
        <Column lg={5} md={8} sm={4}>
          {chartContainer(type)}
        </Column>
      ) : null;
    });
  };

  const createLineChart = (data) => {
    return data.data.map((type) => {
      return type.dataType === "line" ? (
        <Column lg={8} md={8} sm={4}>
          {chartContainer(type)}
        </Column>
      ) : null;
    });
  };

  const createMeterChart = (data) => {
    return data.data.map((type) => {
      return ["meter", "donut"].includes(type.dataType)
        ? chartContainer(type)
        : null;
    });
  };

  const chartContainer = (type) => {
    return (
      <ErrorBoundary fallback={"Error while fetching data"}>
        <ChartContainer data={type} />
      </ErrorBoundary>
    );
  };

  return (
    <div className="container-multichart">
      <Grid>
        {createChart(props.data)}
        <Column lg={5} md={8} sm={4}>
          {createMeterChart(props.data)}
        </Column>
      </Grid>
      <Grid>{createLineChart(props.data)}</Grid>
    </div>
  );
};

export default DefectsMultiChartContainer;
