// react
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./../../../Css/tiles.scss";
import "./../../../ICPSAcss/_common.scss";

// carbon
import {
  ClickableTile,
  Column,
  ErrorBoundary,
  Grid,
  Layer,
  Pagination,
  Search,
  Tag,
  Tile,
} from "@carbon/react";
import { DotMark } from "@carbon/react/icons";
import Context from "Context/Context";

const PlatformInsightsTilesComponent = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [searchText, setSearchText] = useState("")
  let context = useContext(Context);

  const handleOnClick = (route, data, title, hostIds) => {
    if (route) {
      sessionStorage.setItem("platformHost", JSON.stringify(data));
      sessionStorage.setItem("host-title", title);
      sessionStorage.setItem("hostIds", JSON.stringify(hostIds));
      history.push({ pathname: route, state: { title: title } });
    }
  };

  const getMetricTitle = (name) => {
    switch (name) {
      case "cpu":
        return "CPU";
      case "iowait":
        return "IO Wait";
      case "load":
        return "System Load";
      default:
        return "";
    }
  };

  const getMetrics = (data) => {
    return (
      <div>
        <span className="text-on-tile__secondary_title">
          {getMetricTitle(data.data.group)}
        </span>
        <h4 className="text-on-tile__secondary_content">{data.data.value.toFixed(2)}%</h4>
      </div>
    );
  };

  const onErrorHandle = (data) => {
    context.events = {
      eventType: "FEATURE",
      event: data.title,
      bffResponseStatus: null,
      apiResponseStatus: null,
      uiResponseStatus: false,
      isDataAvailable: false,
    };
    return "Error processing Component!";
  };

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page);
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize);
    }
  };

  const getTileContent = (data) => {
    return <>
      <Grid>
        <Column lg={1}>
          <img
            src={
              require(`../../../Assets/carbonIcons/ibm-cloud--dedicated-host.svg`)
                .default
            }
            alt="Not Available"
            className="platform-tile-icon"
          />
        </Column>
        <Column lg={4} className="col-text-on-tile">
          {" "}
          <div
          // style={{ height: "56px" }}
          >
            <h4
              className="text-on-tile__primary_title"
              title={data.host_name}
            >
              {data.host_name}
            </h4>
            <DotMark
              size={32}

              className={`text-on-tile__secondary_status-icon ${data.open_alert_count > 0 ? "unhealthy" : "healthy"}`}
            ></DotMark>
          </div>
          <h6 className="ip-address">
            <Tag
              type="cyan"
              size="md"
              title={data.ip_address}
              className="text-on-tile__secondary_tag"            >
              IP: {data.ip_address}
            </Tag>
          </h6>
        </Column>
      </Grid>
      <Grid>
        <Column lg={1}></Column>
        <Column lg={3} style={{ marginLeft: 0 }}>
          <p
            className="text-on-tile__secondary_content_columns_title"
          >Resource Availability</p>
          <div className="tile--metrics">
            {getMetrics(data.cpuPercentage)}
            {getMetrics(data.iowaitPercentage)}
            {getMetrics(data.loadPercentage)}
          </div>
        </Column>
        <Column lg={1} style={{ marginLeft: 0 }} className="alert--wrapper">
          <h6>Alerts</h6>
          <p>Open: {data.open_alert_count || 0}</p>
          <p>Closed: {data.closed_alert_count || 0}</p>
        </Column>
      </Grid>
      {/* <Grid>
        <Column lg={1}></Column>
        <Column lg={1} style={{ marginLeft: "0" }}>
        </Column>
        <Column lg={1} style={{ marginLeft: "0" }}>
        </Column>
        <Column lg={2} style={{ marginLeft: "0" }}>
        </Column>
      </Grid> */}
      {/* <div className="alert--count">
        <h4>{data.alert_count}</h4>
        <p>{data.alert_count === 1 ? "Alert" : "Alerts"}</p>
      </div> */}
    </>;
  }

  const startIndex = (page - 1) * pageSize;
  const endIndex = page * pageSize;
  const showTiles = props.tileData.filter((fil) => fil.host_name.startsWith(searchText)).slice(startIndex, endIndex)

  return (
    <Column lg={16}>
      <Layer className="layer__tiles">
        <Grid>
          <Column lg={10}>
            <h4>Hosts</h4>
          </Column>
          <Column lg={5}>
            <Search
              size="lg"
              labelText="Search"
              placeholder="Search Host..."
              closeButtonLabelText="Clear search input"
              id="search-1"
              onChange={(e) => setSearchText(e.target.value)}
              onClear={() => setSearchText("")}
            />
          </Column>
        </Grid>
        <Grid>
          {showTiles.map((data, index) => (
            <Column
              lg={5}
              md={5}
              sm={4}
              className="clickable--tile"
              key={index}
            >
              <ErrorBoundary fallback={onErrorHandle(data)}>
                {
                  ((data.closed_alert_count >= 0 && data.closed_alert_count + data.open_alert_count > 0)) ?
                    <ClickableTile
                      id={index}
                      disabled={data.status === "stopped"}
                      onClick={() =>
                        handleOnClick(
                          window.location.href.includes("Portfolio") ?
                            "platformAlertsPortfolioApp"
                            :
                            location.pathname.includes("/operations") ?
                              "operations-platformAlerts"
                              :
                              location.pathname.includes("icpsa/platformInsights") ?
                                "/icpsa/platformAlerts"
                                :
                                "icpsa/platformAlerts",
                          data,
                          data.host_name,
                          data.hostIds
                        )
                      }
                      aria-label={`clickableTile-${index}`}
                      className={`clickable--tile--icpsa ${data.status === "stopped" ? "disable-tile" : ""}`}
                    >
                      {getTileContent(data)}
                    </ClickableTile>
                    :
                    <Tile
                      id={index}
                      disabled={data.status === "stopped"}
                      aria-label={`tile-${index}`}
                      className={`tile--unclickable ${data.status === "stopped" ? "disable-tile" : ""}`}
                    >
                      {getTileContent(data)}
                    </Tile>
                }
              </ErrorBoundary>
            </Column>
          ))}
        </Grid>
        <Grid>
          <Column lg={16}>
            <Pagination
              className="tile-pagination"
              onChange={changePaginationState}
              page={page}
              pageSize={pageSize}
              pageSizes={[9, 18, 27, 36, 45, 90]}
              totalItems={props.tileData.length}
            ></Pagination>
          </Column>
        </Grid>
      </Layer>
    </Column>
  );
};

export default PlatformInsightsTilesComponent;
