export const findApiError = (res) => {
    try{
        if(!Boolean(res) || (Array.isArray(res) && res.length === 0) || (typeof res === "object" && Object.keys(res).length === 0)){
            throw new Error("Request Failed with empty response")
        }
        if(!Boolean(res.data) || ((typeof res.data === "object" && !Array.isArray(res.data)) && Object.keys(res.data).length === 0)){
            throw new Error(`Request Failed with status ${res.status} but empty response`)
        }
        if(res?.data?.itvsdpStatusCode && res?.data?.itvsdpStatusCode !== 200){
            throw new Error(res?.data?.itvsdpResponseMessage || "Something has Went Wrong")
        }
        if(res?.data?.error){
            throw new Error(res.data.error)
        }
        if(res.status !== 200){
            throw new Error(`Request Failed with status code: ${res.status}`)
        }
        return ({
            error: false
        })
    }
    catch (err){
        console.log(err);
        return ({
            error: true,
            message: err.message,
        })
    }
}