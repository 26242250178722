import { Column, Row } from "@carbon/react";
import TooltipCarbon from "Carbon-Components/Tooltip";
import { convertDate, convertUTCDate } from "Common-Modules/ConvertUTCDate";
import React from "react";
import { useTranslation } from 'react-i18next';
import DataTable from "Carbon-Components/DataTable";

const DataTableForCharts = ({ data }) => {
  const { t } = useTranslation();
  const getHeaderData = () => {
    try {
      if(data.headerData !== undefined && data.headerData.length > 0){
        return data.headerData.map(data => ({
          key: data.key,
          header: data.header
        }))
      }
    } catch (err) {
      console.log(err);
    }
    return data.fields.map(data => ({
      key: data,
      header: data
    }))
  }

  const HEADER_DATA = getHeaderData();

  const getTableHeader = (header) => {
    return header;
  };

  const getRowCellData = (id, data, row) => {
    //const temp = new Date(data)
    // if (!isNaN(temp)) {
    //   return data ? (
    //     <>{convertDate("dd-mm-yy", convertUTCDate(data), "opened_at")}</>
    //   ) : (
    //     "-"
    //   );
    // }

    try {
      if (data.length <= 35) {
        return data;
      } else {
        let temp = data.substring(0, 35) + "...";
        return (
          <div className="font-change">
            <TooltipCarbon description={data}>{temp}</TooltipCarbon>
          </div>
        );
      }
    } catch (err) {
      console.log(err);
    }
    
    return data;
  };

  if (data.chartData.length > 0) {
    return (
      <Row>
        <Column lg={16} md={8} sm={4}>
          <DataTable
            title={data.title}
            rowData={data.chartData}
            headerData={HEADER_DATA}
            getRowCellData={getRowCellData}
            getTableHeader={getTableHeader}
            actionsNeeded={false}
          ></DataTable>
        </Column>
      </Row>
    );
  } else {
    return (
      <h4 className="empty--message">
        {t(`common.NoDataInDateRange`)}
      </h4>
    );
  }
};

export default DataTableForCharts;
