/** @format */

import { Breadcrumb, BreadcrumbItem, Button, Column, ProgressIndicator, ProgressStep, Row } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import { getTenantAPI, postTenantDetails } from "Services/RBACServerApi";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FeatureSelection from "./FeatureSelection";
import addTenantStep1JSON from "./addTenantStep1.json";
import addTenantStep2JSON from "./addTenantStep2.json";
// import apaAdapterJSON from "./apaAdapter.json";

function CreateTenant(props) {
  const { keycloak } = useKeycloak();
  let timer = null;
  const [dupilicate, setDupilicate] = useState(false);
  const duplicateMessage = "Tenant Already Exists";

  const [isFormValidStep1, setFormValididtyStep1] = useState(false);
  const [formDataStep1, setFormDataStep1] = useState({});

  const [isFormValidStep2, setFormValididtyStep2] = useState(false);
  const [formDataStep2, setFormDataStep2] = useState({});

  const [isFormValidStep3, setFormValididtyStep3] = useState(false);
  const [formDataStep3, setFormDataStep3] = useState({});

  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPublic, setPublic] = useState(true);

  const [step1CompleteStatus, setStep1CompleteStatus] = useState(false);
  const [step2CompleteStatus, setStep2CompleteStatus] = useState(false);
  const [selectedNodesArray, setSelectedNodesArray] = useState([]);
  const [selectedParentsArray, setSelectedParentsArray] = useState([]);
  // const [accordionIndex, setAccordionIndex] = useState(0);

  const handleFormValidityStep1 = (isValid) => {
    setFormValididtyStep1(isValid);
  };
  const handleFormValidityStep2 = (isValid) => {
    setFormValididtyStep2(isValid);
  };
  const handleFormValidityStep3 = (isValid) => {
    setFormValididtyStep3(isValid);
  };

  const handleFormChangeStep1 = (label, value, id) => {
    if (label === "tenant") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(() => {
        if (Boolean(value)) checkDupilicate(value);
      }, 500);
    }
    const targetId = label;
    const targetValue = value;
    const data = formDataStep1;
    data[targetId] = targetValue;
    setFormDataStep1(data);
  };

  const handleFormChangeStep2 = (label, value, id) => {
    const targetId = label;
    const targetValue = value;
    const data = formDataStep2;
    data[targetId] = targetValue;
    setFormDataStep2(data);
  };


  const handleFormChangeStep3 = (label, value, id) => {
    const targetId = label;
    const targetValue = value;
    const data = formDataStep3;
    data[targetId] = targetValue;
    setFormDataStep3(data);
  };

  const handleSelectedNodesArray = (array) => {
    // let updatedNodesArray = [...array];
    // console.log("selectedParentsArray in handleSelectedNodesArray***", selectedParentsArray);


    // // Check if any IT Governance children exist in selectedParentsArray
    // let itGovernanceChildren = ["development", "security", "operations", "it_value_stream_management"];
    // const itGovernanceChildrenSet = new Set(itGovernanceChildren);
    // const commonItems = selectedParentsArray.filter(item => itGovernanceChildrenSet.has(item));
    // console.log("commonItems:", commonItems);

    // if (commonItems.length === 0) {
    //   // If no children are in selectedParentsArray, remove children from nodesArray
    //   updatedNodesArray = updatedNodesArray.filter(item => !itGovernanceChildrenSet.has(item));
    //   // Also remove the parent if it exists
    //   updatedNodesArray = updatedNodesArray.filter(item => item !== "it_governance_reporting");
    // } else if (!updatedNodesArray.includes("it_governance_reporting")) {
    //   // If children exist but parent doesn't, add parent
    //   updatedNodesArray.push("it_governance_reporting");
    // }

    // setSelectedNodesArray(updatedNodesArray);
    setSelectedNodesArray(array);
  }

  const handleSelectedParentsArray = (array) => {
    let updatedArray = [...array];

    let itGovernanceChildren = ["development", "security", "operations", "it_value_stream_management"];
    const itGovernanceChildrenSet = new Set(itGovernanceChildren);
    const commonItems = updatedArray.filter(item => itGovernanceChildrenSet.has(item));

    if (commonItems.length > 0 && !updatedArray.includes("it_governance_reporting")) {
      updatedArray.push("it_governance_reporting");
    }

    setSelectedParentsArray(updatedArray);
  };


  const checkDupilicate = async (value) => {

    setChecking(true);
    const res = await getTenantAPI(
      keycloak,
      uuidv4(),
      value,
    );
    if (res?.status === 204) {
      setDupilicate(false);
    } else {
      setDupilicate(true);
    }
    setChecking(false);
  };

  const removeChildTags = (features) => {
    let updatedFeatures = [];
    features.forEach((feature) => {
      if (feature.includes("_child")) {
        updatedFeatures.push(feature.substring(0, feature.length - 6));
      } else {
        updatedFeatures.push(feature);
      }
    })
    return updatedFeatures;
  }

  const onCreateTenant = async () => {
    if (step1CompleteStatus === false) {
      setStep1CompleteStatus(true);
      return;
    } else if (step2CompleteStatus === false) {
      setStep2CompleteStatus(true);
      return;
    }

    // handleFormChangeStep3("features", selectedNodesArray);
    const combinedFeatures = [...new Set([...selectedNodesArray, ...selectedParentsArray])];

    handleFormChangeStep3("features", removeChildTags(combinedFeatures));

    const data = { ...formDataStep1, ...formDataStep2, ...formDataStep3 };
    setIsLoading(true);
    const response = await postTenantDetails(keycloak, uuidv4(), data);

    try {
      if (!Boolean(response?.data)) {
        throw new Error("msg");
      } else {
        setReloadRequired(true);
        setErrorMessage("Tenant creation successful");
      }
    } catch (error) {
      setReloadRequired(false);
      setErrorMessage("Failed to create Tenant");
    } finally {
      setIsLoading(false);
    }
  };

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const getPrimaryButtonText = () => {
    if (step2CompleteStatus === false) { return "Next" }
    else {
      return "Create Tenant"
    }
  }

  const getPrimaryButtonDisabled = () => {

    if (dupilicate || checking) {
      return true;
    } else if (!step1CompleteStatus && !isFormValidStep1) {
      return true;
    } else
      if (step1CompleteStatus && !isFormValidStep2) {
        return true;
      } else
        if (step1CompleteStatus && step2CompleteStatus && !isFormValidStep3) {
          return true;
        } else return false;
  }

  return (
    <>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/adminPanel" onClick={() => {
              props.createdTenant();
            }}>Tenant List</BreadcrumbItem>
            <BreadcrumbItem href="#" isCurrentPage={true}>
              Create Tenant
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            {errorMessage || isLoading ? (
              <PassiveModal
                canCloseDetailsPopup={() => {
                  closeErrorPopup();
                  props.createdTenant(reload_required);
                }}
              >
                <h4>{errorMessage}</h4>
                {isLoading && (
                  <InlineLoading status="active" description="Creating Tenant" />
                )}
              </PassiveModal>
            ) : (
              // <Modal
              //   open
              //   modalHeading="Create Tenant"
              //   primaryButtonText={getPrimaryButtonText()}
              //   secondaryButtonText="Cancel"
              //   onRequestClose={() => props.createdTenant(reload_required)}
              //   onRequestSubmit={() => onCreateTenant()}
              //   primaryButtonDisabled={getPrimaryButtonDisabled()}
              // >
              <>
                <ProgressIndicator
                  spaceEqually
                // currentIndex={1} onChange={(index) => changeProgressStep(index)}
                >
                  <ProgressStep current={!step1CompleteStatus} complete={step1CompleteStatus} label="Tenant Information" description="Step 1: Tenant Info" secondaryLabel="Information related to tenant" />
                  <ProgressStep current={step1CompleteStatus && !step2CompleteStatus} complete={step2CompleteStatus} label="Org, App, User Information" description="Step 2: Feature Selection" secondaryLabel="Add information related to Default Org, App and User" />
                  <ProgressStep current={step2CompleteStatus} label="Feature Selection" description="Step 3: Feature Selection" secondaryLabel="Select features to be enabled at UI" />
                </ProgressIndicator>

                <div style={{ marginTop: "2rem" }}>
                  {
                    step1CompleteStatus === false ?
                      <DynamicForm
                        formData={JSON.parse(JSON.stringify(addTenantStep1JSON))}
                        dupilicate={{ id: "tenant", exists: dupilicate }}
                        duplicateMessage={duplicateMessage}
                        getFormValidity={handleFormValidityStep1}
                        handleFormChange={handleFormChangeStep1}
                      /> : ""
                  }
                  {
                    step1CompleteStatus === true && step2CompleteStatus === false ?
                      <DynamicForm
                        formData={JSON.parse(JSON.stringify(addTenantStep2JSON))}
                        dupilicate={{ id: "name", exists: dupilicate }}
                        duplicateMessage={duplicateMessage}
                        getFormValidity={handleFormValidityStep2}
                        handleFormChange={handleFormChangeStep2}
                      /> : ""
                  }
                  {
                    step1CompleteStatus === true && step2CompleteStatus === true ?
                      <FeatureSelection
                        getFormValidity={handleFormValidityStep3}
                        setSelectedNodesArray={handleSelectedNodesArray}
                        setSelectedParentsArray={handleSelectedParentsArray}
                      />
                      : ""
                  }
                </div>
                <div style={{ marginTop: "1rem" }}>

                  <Button id="primary" size="lg" onClick={() => { onCreateTenant() }} disabled={getPrimaryButtonDisabled()}>{getPrimaryButtonText()}</Button>
                  <Button id="secondary" size="lg" kind="secondary" onClick={() => { props.createdTenant(reload_required) }}>Close</Button>
                </div>
              </>
              // </Modal>
            )}
          </div>
        </Column>
      </Row>
    </>
  );
}

export default CreateTenant;
