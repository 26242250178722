/** @format */

import React from "react";

const TextBox = (props) => {

  return (
    <>
        <h5 className="text-title report-title">{props.data.title}</h5>
        <h3 className="text-description">{props.data.value}</h3>
    </>
  );
};

export default TextBox;
