import React from "react";
import AutomateX from "./AutomateX";

function IncidentAnalytics() {
  return (
    <>
      <AutomateX />
    </>
  );
}

export default IncidentAnalytics;
