import React from 'react'
import DataTable from "Carbon-Components/DataTable";
import { Column, Row, Tile } from '@carbon/react'
import { SUSOPS_LANGUAGE_SHOWDOWN } from 'Constant';
import { groupedChart, rowData } from './programming';
import ChartContainer from 'Carbon-Charts/ChartContainer';

const LanguageShowdown = () => {
  return (
    <Row className="graphs-row">
        <Column lg={8} md={4} sm={4}>
            <Tile
                className="tiles title"
                style={{ minHeight: "109px", padding: "0" }}
              >
                <div className="inference-heading">Usecase: Issue Classification</div>
                <div className="summary">
                  <div className="flex-space-between flex-wrap">
                    <div className="keyValue">
                      <h5>Model:</h5>
                      <h4>LSTM</h4>
                    </div>
                    <div className="keyValue">
                      <h5>Framework:</h5>
                      <h4>Tensorflow</h4>
                    </div>
                  </div>
                  <div className="flex-space-between flex-wrap">
                    <div className="keyValue">
                      <h5>Training Data Size:</h5>
                      <h4>8 MB</h4>
                    </div>
                    <div className="keyValue">
                      <h5>Testing Data Size:</h5>
                      <h4>2 MB</h4>
                    </div>
                  </div>
                </div>
            </Tile>
            <DataTable
                rowData={rowData}
                headerData={SUSOPS_LANGUAGE_SHOWDOWN}
                getRowCellData={(id, data, row) => data}
                // title="Language Showdown"
                // description="Use Case: Utilizing the Tensorflow Framework for Classifying 10MB of Data"
                actionsNeeded={false}
                getTableHeader={(header) => header}
                toolbarNeeded={false}
            />
            {/* <Table aria-label="ml-model-comparision">
                <TableHead>
                    <TableRow>
                        <TableHeader>Parameter</TableHeader>
                        <TableHeader>Python</TableHeader>
                        <TableHeader>Javascript</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Use Case</TableCell>
                        <TableCell>Classification</TableCell>
                        <TableCell>Classification</TableCell>
                    </TableRow>
                </TableBody>
            </Table> */}
        </Column>    
        <Column lg={8} md={4} sm={4}>
            <ChartContainer data={groupedChart} />
        </Column>
        {/* <Column lg={4} md={4} sm={4}>
            <ChartContainer data={memoryChart} />
        </Column> */}
    </Row>
  )
}

export default LanguageShowdown