import { Column, ErrorBoundary, FlexGrid, Row, StructuredListSkeleton } from "@carbon/react";
import { Edit } from "@carbon/react/icons/index";
import { useQuery } from '@tanstack/react-query';
import TooltipCarbon from 'Carbon-Components/Tooltip';
import DataUnAvailable from 'Common-Modules/DataUnavailable';
import ErrorWhileFetching from 'Components/ICPSA/utilities/ErrorWhileFetching';
import React, { useEffect, useState } from 'react';
import DataTable from "../../../../Carbon-Components/DataTable";
import "../JobObserv.scss";
import { FAILED_JOBS_HEADER_DATA } from '../utilities';
import CreateSnowTicket from './CreateSnowTicket';

const FailedJobs = () => {

  const [isCalculating, setIsCalculating] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isError, setIsError] = useState(false)
  const [rowData, setRowData] = useState([])
  const [selectedrow, setSelectedRow] = useState(undefined)

  const { data: directvJobs } = useQuery(["directvJobs1"])
  const { data: starCricJobs } = useQuery(["directvstarCricJobs1"])

  useEffect(() => {
    getFailedJobs()
  }, [])

  const getJobId = (jobName) => {
    const job = starCricJobs.data.dtv_job_details_new.find(obj => obj.jobname === jobName)
    if(job){
      return job.id
    }
    return "-"
  }

  const getRowCellData = (id, data, row) => {
    if (id.includes(":observedtime")) {
      try {
        return data.substring(0, 10) + ' ' + data.substring(11, 19)
      } catch (error) {
       return data || "-" 
      }
    }
    return data || "-";
  };

  const getFailedJobs = () => {
    try {
      const jobMapper = new Map()
      for(const temp of directvJobs.data){
  
        if(Array.isArray(temp.job_details)){
  
          for(const job of temp.job_details){
  
            const jobName = job.jobname
  
            if(jobMapper.has(jobName)){
              const existingJob = jobMapper.get(jobName)
  
              const existingDate = new Date(existingJob.observedtime);
              const newDate = new Date(job.observedtime);
  
              if(newDate >= existingDate){
                jobMapper.set(jobName, job)
              }
            } 
            else{
              jobMapper.set(jobName, job)
            }
  
          }
        }
      }
  
      const valuesArray = Array.from(jobMapper.values()).filter(job => job.job_status === "Error").map(job => ({
        ...job,
        id: job.jobname,
        jobid: getJobId(job.jobname)
      }))
      
      if(valuesArray.length === 0){
        setIsEmpty(true)
      }else{
        setRowData(valuesArray)
      }
    } catch (error) {
      setIsError(true)
    } finally{
      setIsCalculating(false)
    }
  }

  const onEdit = (selected) => {
    let data = "";
    rowData.forEach((row) => {
      if (row.id === selected.id) data = row;
    });
    setSelectedRow(data)
  };

  const getActions = (row, rows) => {
    return (
      <div className="flex-display">
        <li className="actions-icon">
          <TooltipCarbon description="Create Incident in Service Now" align="left">
              <Edit size={16} className="pointer" fill="#525252" onClick={() => onEdit(row)} />
          </TooltipCarbon>
        </li>
      </div>
    );
  };

  const getData = () => {
    if(isCalculating){
      return <StructuredListSkeleton />;
    }
  
    if(isEmpty){
      return <DataUnAvailable />
    }
  
    if(isError){
      return <ErrorWhileFetching />
    }

    return <>
      <DataTable
        rowData={rowData}
        headerData={FAILED_JOBS_HEADER_DATA}
        title="Failed Jobs"
        getRowCellData={getRowCellData}
        getTableHeader={header => header}
        placeholder="Search ..."
        actionsNeeded={false}
        table_particular_actions={true}
        getActions={getActions}
      />
      {
        selectedrow
        ? <CreateSnowTicket close={() => setSelectedRow(undefined)} selected={selectedrow} />
        : null
      }
    </>
  }

  return (
    <ErrorBoundary fallback={<ErrorWhileFetching />}>
      <FlexGrid className='pl-0 pr-0'>
        <Row>
          <Column lg={16} md={8} sm={4} className='disable--button'>
            {getData()}
          </Column>
        </Row>
      </FlexGrid>
    </ErrorBoundary>
  )
}

export default FailedJobs