import ChartContainer from "Carbon-Charts/ChartContainer";
import React from "react";

export const getSecChartData = (props) => {
    if (props !== undefined && props.chartData !== undefined && props.chartData.length > 0) {
        return props.chartData.map((chartRes) => {
            let data = null;

            let colorObj = {}
            chartRes.values.forEach((val, index) => {
                ["#002d9c","#0043ce","#0f62fe", "#4589ff", "#78a9ff"].forEach((res, ind) => {
                    if(ind === index) {
                        colorObj = {
                            ...colorObj,
                            [val.group]: res
                        }
                    }
                })
            })

            if (chartRes.title === "Issues by Severity") {
                data = {
                    "dataType": "pie",
                    "data": {
                        "chartData": chartRes.values,
                        "chartOptions": {
                            "title": chartRes.title,
                            "canvasZoom": {
                                "enabled": true
                            },
                            "pie": {
                                "alignment": "center"
                            },
                            "toolbar": {
                                "enabled": false
                            },
                            "color": {
                                "scale": {
                                    "High": "#0043ce",
                                    "Medium": "#0f62fe",
                                    "Low": "#4589ff",
                                    "Informational": "#78a9ff",

                                }
                            },
                            "legend": {
                                "enabled": false
                            },
                            "height": "130px"
                        }
                    }
                }
            } else if (chartRes.title === "Top 5 Issues") {
                data = {
                    "dataType": "pie",
                    "data": {
                        "chartData": chartRes.values,
                        "chartOptions": {
                            "title": "Top 5 Issue Types",
                            "pie": {
                                "alignment": "center"
                            },
                            "axes": {
                                "left": {
                                    "mapsTo": "value"
                                },
                                "bottom": {
                                    "mapsTo": "group",
                                    "scaleType": "labels"
                                }
                            },
                            "toolbar": {
                                "enabled": false
                            },
                            "color": {
                                "scale": colorObj
                            },
                            "legend": {
                                "enabled": false
                            },
                            "height": "130px"
                        }
                    }
                }
            }
            return (
                data !== null ? <ChartContainer data={data} /> : null 
            );
        });
    }
}

export const getSecBasicInfo = (props) => {
    if (props !== undefined && props.tileData !== undefined && props.tileData.length > 0) {
        return <ul>{props.tileData.map((res) => <li><h5>{res.title}</h5><span>{res.value}</span></li>)}</ul>
    }
}