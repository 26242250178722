import { ProgressIndicator, ProgressStep, Tag } from "@carbon/react";
import { CheckmarkOutline, WarningHex } from "@carbon/react/icons/index";
import React, { useState } from "react";

const BizopsProgress = (props) => {
  const [progressLine] = useState([
    {
      day: [],
      progress: "complete",
      title: {
        title: "Start",
        value: "",
      },
      label: [],
    },
    {
      day: ["06"],
      progress: "error",
      title: {
        title: "Plan and manage inbound product flow",
        value: "97.78%",
      },
      label: [
        {
          title: "Manage inbound product flow",
          value: 98.23,
        },
        {
          title: "Monitor inbound delivery performance",
          value: 96.16,
        },
        {
          title: "Manage flow of returned products",
          value: 98.87,
        },
      ],
    },
    {
      day: [],
      progress: "complete",
      title: {
        title: "End",
        value: "",
      },
      label: [],
    },
  ]);

  const DrawProgress = () => {
    return progressLine.map((flow) => {
      return (
        <ProgressStep
          label={
            <p
              title={flow.title.title}
              onClick={(e) =>
                flow.title.title === "Start" || flow.title.title === "End"
                  ? undefined
                  : secondaryLabel(e, flow.title.title)
              }
            >
              {flow.title.title}{" "}
              <span>
                {flow.title.value}
                <span className="pt"></span>
              </span>
            </p>
          }
          secondaryLabel={flow.label.map((label) => (
            <p
              onClick={(e) => secondaryLabel(e, label)}
              className="sec-label"
              title={flow.title.title}
            >
              {label.value > 98 ? (
                <CheckmarkOutline fill="#42be65" />
              ) : (
                <WarningHex fill="#ff8389" />
              )}
              {label.title}
              <span>
                : {label.value}
                <span className="pt">%</span>{" "}
                <Tag
                  className="notify-tag"
                  type={label.value > 98 ? "green" : "red"}
                >
                  {label.value > 98 ? "Healthy" : "Error"}
                </Tag>
              </span>
            </p>
          ))}
          complete={flow.progress === "complete"}
          invalid={flow.progress === "error"}
          current={flow.progress === "current"}
        />
      );
    });
  };

  const secondaryLabel = (e, label) => {
    e.stopPropagation();
    props.secondaryLabel(label);
    // console.log("secondaryLabel ==========", label);
  };

  const onChangeProgress = (e) => {
    e.stopPropagation();
    props.enableTopology(e);
  };

  return (
    <>
      <div className="vs-progressBar">
        <ProgressIndicator
          vertical
          spaceEqually
          onChange={(e) => onChangeProgress(e)}
        >
          {DrawProgress()}
        </ProgressIndicator>
      </div>
    </>
  );
};

export default BizopsProgress;
