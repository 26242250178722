/** @format */

import {
  Button,
  Column,
  DatePicker,
  DatePickerInput,
  ErrorBoundary,
  Grid,
  InlineLoading,
  SkeletonPlaceholder,
  Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import Context from "Context/Context";
import { getAnyCollection } from "Services/ServerApi";
import React, { useContext, useEffect, useState } from "react";
import { getApplicationComponentsData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import TopologyGraphAligned from "./CarbonTopologyGraph";

const TopologyChart = (props) => {
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [hideDateFilter, setHideDateFilter] = useState(false);

  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  const { data: AtoTopologyData, refetch: atoTopoRefetch } = useQuery(['ato_topology'], async () => await getAnyCollection(keycloak, "ato_alert_topology"), { retry: 1, enabled: false });

  useEffect(() => {

    const atoFlow = sessionStorage.getItem("atoFlow");
    if(atoFlow === "ato") {
      atoTopoRefetch()
    }else {
      let startDateDefault = props.serviceObj.StartTime;
      let endDateDefault = props.serviceObj.EndTime;
  
      setStartDate(startDateDefault);
      setEndDate(endDateDefault);
  
      getAPIData(startDateDefault, endDateDefault);
    }

  }, []);

  useEffect(() => {
    if(AtoTopologyData !== undefined){
      setAPIData(AtoTopologyData.data.ato_alert_topology[0]);
    }
  }, [AtoTopologyData])

  const getAPIData = async (sDate, eDate) => {
    const response = await getApplicationComponentsData(
      keycloak.token,
      sDate,
      eDate
    );
    AddUserActivity(keycloak, context, "FEATURE", "Application Topology", response);

    setIsAPIDataLoading(false);
    setApiLoading(false);
    setErrorMessage(updateErrorMessage(response));
    setAPIData(updateAPIData(response));
  };

  // const formatDate = (date, type) => {
  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   if (type === "start") {
  //     return [year, month, day].join("-") + " 00:10";
  //   } else {
  //     return [year, month, day].join("-") + " 23:50";
  //   }
  // };

  // const onStartDateChange = (e) => {
  //   setStartDate(formatDate(new Date(e[0]).toDateString(), "start"));
  //   if (e.length > 1) {
  //     setEndDate(formatDate(new Date(e[1]).toDateString(), "end"));
  //   }
  // };

  // const updateAPI = async () => {
  //   setAPIData("");
  //   setApiLoading(true);
  //   await getAPIData(startDate, endDate);
  // };

  // const getHeadingAndDateFilter = () => {
  //   return (
  //     <>
  //       <Column lg={10}>
  //         <h3>{props.title}</h3>
  //       </Column>
  //       {hideDateFilter ? (
  //         ""
  //       ) : (
  //         <>
  //           <Column lg={4}>
  //             <ErrorBoundary fallback={"Data is unavailable"}>
  //               <DatePicker
  //                 dateFormat="Y-m-d"
  //                 datePickerType="range"
  //                 onChange={(e) => onStartDateChange(e)}
  //                 className="date-picker--margin"
  //               >
  //                 <DatePickerInput
  //                   id="date-picker-range-start"
  //                   placeholder="yyyy-mm-dd"
  //                   labelText="Start Date"
  //                   defaultValue={props.serviceObj.StartTime.substring(
  //                     0,
  //                     props.serviceObj.StartTime.indexOf("T")
  //                   )}
  //                   type="text"
  //                 />
  //                 <DatePickerInput
  //                   id="date-picker-range-end"
  //                   placeholder="yyyy-mm-dd"
  //                   labelText="End Date"
  //                   defaultValue={props.serviceObj.EndTime.substring(
  //                     0,
  //                     props.serviceObj.EndTime.indexOf("T")
  //                   )}
  //                   type="text"
  //                 />
  //               </DatePicker>
  //             </ErrorBoundary>
  //           </Column>
  //           <Column lg={2}>
  //             <span>
  //               {apiLoading ? (
  //                 <InlineLoading
  //                   status="active"
  //                   iconDescription="Loading"
  //                   description="Loading data..."
  //                   className="report-update__button"
  //                 />
  //               ) : (
  //                 <Button
  //                   kind="primary"
  //                   onClick={updateAPI}
  //                   size="md"
  //                   iconDescription="Icon Description"
  //                   className="report-update__button"
  //                 >
  //                   Update
  //                 </Button>
  //               )}
  //             </span>
  //           </Column>
  //         </>
  //       )}
  //     </>
  //   );
  // };

  const onShowTraceList = (show) => {
    setHideDateFilter(show);
  };

  const createApplicationsChart = () => {
    return (
      <>
        {/* <Column lg={10}>
          <div>
            <h3>Application Topology View</h3>
          </div>
        </Column> */}
        <Column lg={16} md={8} sm={4}>
          {APIData ? (
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              <Tile className="chart-container">
                <ErrorBoundary fallback={"Data is unavailable"}>
                  <TopologyGraphAligned
                    data={APIData}
                    hideDateFilter={onShowTraceList}
                  />
                </ErrorBoundary>
              </Tile>
            </ErrorBoundary>
          ) : (
            loadSkeleton()
          )}
        </Column>
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div
        className="skeleton__placeholder--default  common-top-margin"
      >
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {<Grid>{createApplicationsChart()}</Grid>}
    </div>
  );
};

export default TopologyChart;
