import React from "react";

const Section = ({ header, data }) => {
  let firstHeader = {
    paddingLeft: "20px",
  };
  return (
    <div className="analyzed-data-details">
      <h6 style={data[0] ? firstHeader : {}}>{header}</h6>
      {data.length === 1 && <p>{data[0]}</p>}
      {data.length >= 2 && (
        <ul>
          {(data.length === 3 || data.length === 4) && <p>{data[0]}</p>}
          {data.length === 2 &&
            data.map((item) => {
              return Object.entries(item).map(([key, value]) => {
                return (
                  <div className="analyzed-data">
                    <h5>{key}:</h5>
                    <h6>{value}</h6>
                  </div>
                );
              });
            })}
          {(data.length === 3 || data.length === 4) &&
            data.slice(1, 3).map((item) => {
              return Object.entries(item).map(([key, value]) => {
                return (
                  <div className="analyzed-data">
                    <h5>{key}:</h5>
                    <h6>{value}</h6>
                  </div>
                );
              });
            })}
          {data.length === 4 && <p>{data[3]}</p>}
        </ul>
      )}
      <hr style={{ border: "none" }} />
    </div>
  );
};

export default Section;
