/** @format */

import React from "react";
import { Column, ErrorBoundary, Grid } from "@carbon/react";
import ChartContainer from "./ChartContainer";

const CodeQualityMultiChartContainer = (props) => {

  const createChart = (data) => {
    return data.data.map((type) => {
      return (
        <Column
          lg={type.dataType === "text" ? 2 : 5}
          md={8}
          sm={4}
          className={type.dataType === "text"}
        >
          <ErrorBoundary fallback={"Error while fetching data"}>
            <ChartContainer data={type} />
          </ErrorBoundary>
        </Column>
      );
    });
  };

  return (
    <div className="container-multichart">
      <Grid>{createChart(props.data)}</Grid>
    </div>
  );
};

export default CodeQualityMultiChartContainer;
