/** @format */

import {
    Button,
    ErrorBoundary,
    SkeletonPlaceholder,
    Tag
  } from "@carbon/react";
  import { useKeycloak } from "@react-keycloak/web";
  import CarbonDataTable from "Carbon-Components/DataTable";
  import { getRunBookRecommendations } from "../../Services/ServerApi";
  import AddUserActivity from "../../utilities/AddUserActivity";
  import ErrorWhileFetching from "../../utilities/ErrorWhileFetching";
  import Context from "Context/Context";
  
  import { CheckmarkFilled, ErrorFilled, InProgress, Run, UndefinedFilled } from '@carbon/react/icons/index';
  
  import React, { useContext, useEffect, useState } from "react";
  
  const tableHeaderData = [
    {
      key: "id",
      header: "Runbook Name",
    },
    {
      key: "status",
      header: "Status",
    },
    {
      key: "action",
      header: "Action",
    },
  ];
  
  const RunBookAutomation = (props) => {
    //const context = useContext(Context);
    useEffect(() => {
      setIsAPIDataLoading(false);
      updateTableRowData(props.data);
      // setAutomationRecommandations(props.data);
    }, []);
  
    const { keycloak } = useKeycloak();
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [errMessage, setErrorMessage] = useState("");
    const [tableRowData, setTableRowData] = useState();
  
    // const getAutomationRecommendations = async (servicetype, rootcause) => {
    //   const response = await getRunBookRecommendations(
    //     keycloak.token,
    //     servicetype,
    //     rootcause
    //   );
    //   AddUserActivity(keycloak, context, "FEATURE", "RunBook Recommendation", response);
    //   setIsAPIDataLoading(false);
  
    //   if (!response.data) {
    //     setErrorMessage("Error while fetching data");
    //   }
    //   if (response.status >= 400 || response === "Error") {
    //     setErrorMessage("Error while fetching data");
    //   } else {
    //     updateTableRowData(response.data.data);
    //   }
    // };
  
    const updateTableRowData = (data) => {
      let tableRowDataArray = [];
      tableRowDataArray = data.map((obj, index) => {
        let tableRow = {};
        tableRow.id = obj["Automation recommended"];
        tableRow.status = "Not Executed";
        tableRow.action = obj["Automation link"];
        return tableRow;
      });
      setTableRowData(tableRowDataArray);
    };
  
    const getLoading = () => {
      return isAPIDataLoading ? (
        <div className="skeletonPlaceholder--it-health">
          <SkeletonPlaceholder />
        </div>
      ) : (
        <ErrorWhileFetching errMessage={errMessage} />
      );
    };
  
    const handleOnClick = (link) => window.open(link, "_blank");
  
    const getStatusIcon = (status) => {
      switch (status) {
        case "NOT_EXECUTED":
          return <UndefinedFilled className="icon__color--not-executed" />;
        case "IN_PROGRESS":
          return <ErrorFilled  className="icon__color--in-progress" />;
        case "SUCCESS":
          return <InProgress  className="icon__color--success" />;
        case "FAILURE":
          return <CheckmarkFilled  className="icon__color--failure" />;
        default:
          return <UndefinedFilled  className="icon__color--not-executed" />;
      }
    };
  
    const getRowCellData = (id, data, row) => {
      if (id.includes(":status")) {
        return (
          <p>
            <span>{getStatusIcon("NOT_EXECUTED")}Not Executed</span>
          </p>
        );
      }
      if (id.includes(":action")) {
        return (
            <Button renderIcon={Run} iconDescription="Execute" size="sm" onClick={() => handleOnClick(data)}>
              Execute
            </Button>
        );
      }
  
      return data;
    };
  
    const getTableHeader = (header) => {
      return header;
    };
  
    const getTable = () => {
      return isAPIDataLoading || errMessage ? (
        getLoading()
      ) : (
        <div className="common-top-margin">
          <ErrorBoundary fallback={<ErrorWhileFetching />}>
            <CarbonDataTable
              rowData={tableRowData}
              headerData={tableHeaderData}
              getRowCellData={getRowCellData}
              getTableHeader={getTableHeader}
            />
          </ErrorBoundary>
        </div>
      );
    };
  
    return (
      <>
        <p>
          <strong>Root Cause:</strong> {props.rootcause}
        </p>
        <Tag
          type="green"
          size="sm"
          title={props.servicetype}
          className="text-on-tile__secondary_tag"
        >
          Service Type: {props.servicetype}
        </Tag>
        {getTable()}
      </>
    );
  };
  
  export default RunBookAutomation;
  