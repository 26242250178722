export const getIconSrc = (icon) => {
  if (icon) {
    if (icon.includes("client"))
      return require(`Assets/aws/bp/laptop.svg`).default;
    if (icon.includes("user")) return require(`Assets/aws/bp/user.svg`).default;

    if (icon.includes("ApplicationMobile"))
      return require(`Assets/aws/bp/application--mobile.svg`).default;
    if (icon.includes("ApplicationWeb"))
      return require(`Assets/aws/bp/application--web.svg`).default;

    if (icon.includes("Plan")) return require(`Assets/aws/bp/plan.svg`).default;
    if (icon.includes("Catalog"))
      return require(`Assets/aws/bp/catalog.svg`).default;
    if (icon.includes("Currency"))
      return require(`Assets/aws/bp/currency.svg`).default;
    if (icon.includes("Concept"))
      return require(`Assets/aws/bp/concept.svg`).default;
    if (icon.includes("MediaLibrary"))
      return require(`Assets/aws/bp/media--library.svg`).default;

    if (icon.includes("Product"))
      return require(`Assets/aws/bp/product.svg`).default;
    if (icon.includes("Stop")) return require(`Assets/aws/bp/stop.svg`).default;
    if (icon.includes("WindPower"))
      return require(`Assets/aws/bp/wind-power.svg`).default;
    if (icon.includes("AddComment"))
      return require(`Assets/aws/bp/add-comment.svg`).default;
    if (icon.includes("ChooseItem"))
      return require(`Assets/aws/bp/choose-item.svg`).default;

    if (icon.includes("SelectWindow"))
      return require(`Assets/aws/bp/select--window.svg`).default;
    if (icon.includes("ShoppingCartPlus"))
      return require(`Assets/aws/bp/shopping--cart--plus.svg`).default;
    if (icon.includes("ShoppingCartMinus"))
      return require(`Assets/aws/bp/shopping--cart--minus.svg`).default;
    if (icon.includes("Purchase"))
      return require(`Assets/aws/bp/purchase.svg`).default;
    if (icon.includes("InventoryManagement"))
      return require(`Assets/aws/bp/inventory-management.svg`).default;

    if (icon.includes("PaymentGateway"))
      return require(`Assets/aws/bp/PaymentGateway.svg`).default;
    if (icon.includes("UPI")) return require(`Assets/aws/bp/UPI.svg`).default;
    if (icon.includes("Merchant"))
      return require(`Assets/aws/bp/Merchant.svg`).default;
    if (icon.includes("IbmBluepay"))
      return require(`Assets/aws/bp/ibm--bluepay.svg`).default;
    if (icon.includes("Finance"))
      return require(`Assets/aws/bp/finance.svg`).default;

    if (icon.includes("Upload"))
      return require(`Assets/aws/bp/upload.svg`).default;
    if (icon.includes("InProgress"))
      return require(`Assets/aws/bp/in-progress.svg`).default;
    if (icon.includes("Assembly"))
      return require(`Assets/aws/bp/assembly.svg`).default;
    if (icon.includes("Delivery"))
      return require(`Assets/aws/bp/delivery.svg`).default;

    if (icon.includes("Db2Database"))
      return require(`Assets/aws/bp/db2--database.svg`).default;
  } else {
    return require(`Assets/aws/bp/circle--outline.svg`).default;
  }
};

export const getErrorFaultyClass = (node) => {
  return node.error || node.fault ? "node_orange" : "";
};

export const getColorClass = (node) => {
  return node.hasAnomaly ? "node_red" : getErrorFaultyClass(node);
};

const nodeHeight = 64;
const nodeWidth = 64;

export const getEdgeStart = (nodeData, nodeId) => {
  const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
  if (node) {
    return {
      x: `${node.x + nodeWidth / 2}`,
      y: `${node.y + nodeHeight / 2}`,
    };
  }
};

export const getEdgeEnd = (nodeData, nodeId) => {
  const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
  if (node) {
    return {
      x: `${node.x}`,
      y: `${node.y + nodeHeight / 2}`,
    };
  }
};
