import React from 'react';
import { Tag } from '@carbon/react';
import { Close } from '@carbon/react/icons/index';
import { useTranslation } from 'react-i18next';

export default function FilterLables(props) {
    const { t } = useTranslation();

    const handleFilterLabel = (type) => {
        switch (type) {
            case "Franchise":
                props.updateState('alertFilterSelections', {
                    ...props?.filterSelections, "franchise": [], "client": []
                });
                break;
            case "Client":
                props.updateState('alertFilterSelections', {
                    ...props?.filterSelections, "franchise": [], "client": []
                });
                break;
            case "IsIncident":
                props.updateState('alertFilterSelections', {
                    ...props?.filterSelections, "isIncident": []
                });
                break;
            default: break;
        }
    }
    return (
        <div className='filter-tag-container' >
            <div className="filters">
                {props?.filterSelections?.franchise?.length > 0 || props?.filterSelections?.client?.length > 0 ? <div className="label">{t('DigitalOperations.appliedFilters')}</div> : null}
                {props?.filterSelections?.franchise?.length > 0 && <div className="filter-tag"><Tag type="blue">{props?.filterSelections?.franchise?.length} {t('DigitalOperations.franchise')} {<Close onClick={() => handleFilterLabel('Franchise')} />}</Tag></div>}
                {props?.filterSelections?.client?.length > 0 && <div className="filter-tag"><Tag type="blue">{props?.filterSelections?.client?.length} {t('DigitalOperations.client')} {<Close onClick={() => handleFilterLabel('Client')} />}</Tag></div>}
                {props?.filterSelections?.isIncident?.length > 0  ? <div className="filter-tag"><Tag type="blue">{t('DigitalOperations.alertType')} {<Close onClick={() => handleFilterLabel('IsIncident')} />}</Tag></div> : null}
            </div>
        </div>
    )
}