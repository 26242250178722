import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./JobObserv.scss";
import WorkflowDetails from "./JobWorkflowDetails";

const WorkflowDetailsIndex = (props) => {
    const [details] = useState(props.location.state.details);
    const history = useHistory();

    // const [attFlow, setAttFlow] = useState("");
    // const { t } = useTranslation();
    // useEffect(() => {
    //     if (props?.location && props?.location?.state) {
    //         setAttFlow(props.location.state)
    //     }
    // }, [props.location])


    const redirectHRef = () => {
        history.push({ pathname: "/WorkflowIndex2", state: { data: props.location.state.details, directvData: props.location.state.directvData, mergeData: props.location.state.mergeData, starCric: props.location.state.starCric, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange, flow: props.location.state.flow } })
    }


    console.log("details ==========>>>", details)

    return (
        Object.entries(details).length > 0 ?
            <FlexGrid className="att-dash">
                <Row>
                    <Column lg={12} md={8} sm={4}>
                            <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                                <BreadcrumbItem href="#/itOperations">
                                    Operations
                                </BreadcrumbItem>
                                <BreadcrumbItem onClick={() => redirectHRef()}>
                                    {details.category_name}
                                </BreadcrumbItem>
                                <BreadcrumbItem isCurrentPage>
                                    Workflow Details
                                </BreadcrumbItem>
                            </Breadcrumb>
                    </Column>
                </Row>
                <WorkflowDetails location={props.location} />
            </FlexGrid>
            : null
    )
}

export default WorkflowDetailsIndex