import React, { useEffect, useState } from "react";
import ServiceSummary from "./ServiceSummary";

function PerformanceAvailabilityComponent(props) {

    const [goBack, setGoBack] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        if (sessionStorage.getItem("startDate") && sessionStorage.getItem("endDate")) {
            setStartDate(sessionStorage.getItem("startDate"));
            setEndDate(sessionStorage.getItem("endDate"));
        } else {
            let startDateDefault = new Date().toISOString().split('T')[0] + " 00:10";
            let endDateDefault = new Date().toISOString().split('T')[0] + " " + new Date().toLocaleTimeString().substring(0, new Date().toLocaleTimeString().indexOf(':') + 3);

            // const startDate = new Date('2024-01-24T00:10:00Z');
            // const endDate = new Date('2024-01-24T23:50:00Z');
            // const startDateDefault = startDate.toISOString().split('T')[0] + " 00:10";
            // const endDateDefault = endDate.toISOString().split('T')[0] + " 23:50";

            setStartDate(startDateDefault);
            setEndDate(endDateDefault)
            sessionStorage.setItem("startDate", startDateDefault)
            sessionStorage.setItem("endDate", endDateDefault)
        }
    }, [])

    const handleDateChange = (updatedStartDate, updatedEndDate) => {
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
        sessionStorage.setItem("startDate", updatedStartDate)
        sessionStorage.setItem("endDate", updatedEndDate)
    }

    const handleTraceClick = (row) => {
        sessionStorage.setItem("serviceRow", JSON.stringify(row))
        sessionStorage.setItem("atoFlow", props.flow);
        window.location.href = "#/topologyGraph"
    }

    return (
        <>
            {/* <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
                        {
                            <BreadcrumbItem isCurrentPage>App Performance Availability</BreadcrumbItem>
                        }
                    </Breadcrumb>
                </Column>
            </Grid> */}
            <div className="content-div">
                {
                    startDate && endDate ?
                        <ServiceSummary
                            title="Service Map Timeline View"
                            serviceClick={handleTraceClick}
                            goBack={goBack}
                            dateChange={handleDateChange}
                            startDateDefault={startDate}
                            endDateDefault={endDate}
                            flow={props.flow}
                        />
                        : ""
                }
            </div>
        </>
    );
}

export default PerformanceAvailabilityComponent
