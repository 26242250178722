import { Breadcrumb, BreadcrumbItem, Column, ComboBox, ErrorBoundary, FlexGrid, Row, Tile } from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import CarbonTabs from "Carbon-Components/Tabs";
import DeploymentInsights from "Components/ICPSA/DeploymentInsights/DeploymentInsights";
import GitHub from "Components/ICPSA/GitHub/GitHub";
import PipelineUsage from "Components/ICPSA/PipelineUsage/PipelineUsage";
import ReleaseInsights from "Components/ICTE/IntegratedOperations/ReleaseInsights";
import React, { useEffect, useState } from "react";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import PipelineActivity from "../PipelineActivity/PipelineActivity";
import "../scss/ci_cd.scss";
import chartDataJSON from "./chartData.json";

const Overview = (props) => {
  const [tabSelect, setTabSelection] = useState(sessionStorage.getItem("selectedTabALM") !== null ? parseInt(sessionStorage.getItem("selectedTabALM")) : 1)

  const [selectedApp, setSelectedApp] = useState("");
  const [applicationName, setApplicationName] = useState("All");

  useEffect(() => {
    if (props.location) {
      setSelectedApp(props.location.state)
    }
  }, [])

  const getChartData = () => {
    let filteredData = chartDataJSON.chartData.filter(
      (fil) => fil.applicationName === applicationName
    );
    if (filteredData.length > 0) {
      return filteredData[0].data.map((chartRes, index) => {
        let data = {
          dataType: "donut",
          data: {
            chartData: chartRes.values,
            chartOptions: {
              title: chartRes.title,
              resizable: true,
              donut: {
                alignment: "center",
                center: {
                  label: "",
                },
              },
              color: {
                scale: {
                  Success: "#24a148",
                  Failure: "#fa4d56",
                },
              },
              toolbar: {
                enabled: false,
              },
              height: "250px",
            },
          },
        };

        return (
          <Column lg={index === 2 ? 6 : 5} md={3} className="padding-lft">
            <ChartContainer data={data} />
          </Column>
        );
      });
    }
  };

  const getTileInfo = () => {
    let filteredData = chartDataJSON.chartData.filter(
      (fil) => fil.applicationName === applicationName
    );
    if (filteredData.length > 0) {
      return (
        <>
          <Tile className="tiles3">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[0].title}</h5>
              <h3 className="text-description">{filteredData[0].tileData[0].value}</h3>
            </ErrorBoundary>
          </Tile>
          <Tile className="tiles3 vulner">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[1].title}</h5>
              <h3 className="text-description">{filteredData[0].tileData[1].value}</h3>
            </ErrorBoundary>
          </Tile>
          <Tile className="tiles3 last-child">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[2].title}</h5>
              <h3 className="text-description">{filteredData[0].tileData[2].value}</h3>
            </ErrorBoundary>
          </Tile>
        </>
      );
    }
  };

  const onApplicationNameChange = (e) => {
    if (e["selectedItem"] !== null) {
      setApplicationName(e.selectedItem);
    } else {
      setApplicationName("All");
    }
  };


  const hubs = sessionStorage.getItem("HubFlow");

  const getTabList = () => {
    return [
      "Version Control",
      "Pipeline Usage",
      "Deployment Insights",
      "Release Insights"
    ];
  }

  const getTabPanelsList = () => {
    let panelList = [];

    if (GetPermissions("version_control::read")) {
      panelList.push(<GitHub />);
    }
    if (GetPermissions("pipeline_usage::read")) {
      panelList.push(<PipelineUsage />);
    }
    if (GetPermissions("deployment_insights::read")) {
      panelList.push(<DeploymentInsights />);
    }
    if (GetPermissions("release_insights::read")) {
      panelList.push(<ReleaseInsights />);
    }
    return panelList;
    // return [
    //   <GitHub />,
    //   <PipelineUsage />,
    //   <DeploymentInsights />,
    //   <ReleaseInsights />
    // ];
  }

  const tabSelection = (e) => {
    sessionStorage.setItem("selectedTabALM", e)
    setTabSelection(e);
  }

  const getDisabledList = () => {
    let disabledList = [];
    disabledList.push("Version Control");
    // if (!GetPermissions("version_control::read")) {
    //   disabledList.push("Version Control");
    // }
    if (!GetPermissions("pipeline_usage::read")) {
      disabledList.push("Pipeline Usage");
    }
    if (!GetPermissions("deployment_insights::read")) {
      disabledList.push("Deployment Insights");
    }
    if (!GetPermissions("release_insights::read")) {
      disabledList.push("Release Insights");
    }
    console.log("disabledList:", disabledList)
    return disabledList;
  }

  return (
    hubs === "insights" ?
      <FlexGrid className="integratedOps">
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
              <BreadcrumbItem isCurrentPage>Product Engineering</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <CarbonTabs
              tabIndex={tabSelect}
              selectionMode="automatic"
              type="default"
              Tablist={getTabList()}
              tabSelection={true}
              tabSelected={tabSelection}
              disabledList={getDisabledList()}
              TabPanelsList={getTabPanelsList()}
            />
          </Column>
        </Row>
      </FlexGrid>
      :
      <FlexGrid className="cicd" fullWidth={true}>
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
              <BreadcrumbItem isCurrentPage>Product Engineering</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <>
          <Row>
            <Column lg={4} md={2} sm={2}>
              <ComboBox
                ariaLabel="ComboBox"
                id="ComboBox1"
                items={["All", "Service", "UI"]}
                itemToString={(item) => (item ? item : "")}
                placeholder="All"
                titleText="Pipelines"
                size="sm"
                onChange={(e) => onApplicationNameChange(e)}
              />
            </Column>
            <Column lg={8} md={4} sm={4}></Column>
            <Column lg={4} md={2} sm={2}>
              <h5 className="h5-title">Date: Last 30 Days</h5>
            </Column>
          </Row>
          <Row>
            <Column lg={6} md={4} sm={2}>
              {getTileInfo()}
              <Tile className="top5">
                <h5>
                  Top 5 Build Failure Category
                </h5>
                <ul>
                  <li>Code Issue</li>
                  <li>Infrastructure</li>
                  <li>Configuration</li>
                  <li>Quality Gate Failure</li>
                </ul>
              </Tile>
            </Column>
            <Column lg={10} md={8} sm={2}>
              <Row>
                {getChartData()}
              </Row>
            </Column>
          </Row>
          <Row className="pipeline-activity--row">
            <PipelineActivity />
          </Row>
        </>
      </FlexGrid>
  )
};

export default Overview;
