import { AreaChart } from "@carbon/charts-react";
import { Dropdown } from "@carbon/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import jsonFore from "../ChartsWithFilters/ForecastPredict.json";

const SparkLinewithFilter = ({ data, options }) => {
  const { t } = useTranslation();
  const ticketChartData = [...data, ...jsonFore];
  const yearArray = Array.from(
    new Set(ticketChartData.map((ticket) => ticket.yearKey))
  );
  const years = yearArray.sort((a, b) => Number(b) - Number(a));
  const [year, setYear] = useState(years[0]);

  const monthNames = [
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.january`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.february`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.march`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.april`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.may`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.june`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.july`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.august`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.mmonths.september`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.october`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.november`),
    t(`IncidentAnalyticsReports.chartScreens.forecasting.months.december`)

  ];

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [month, setMonth] = useState(1);

  const ticketData = ticketChartData.filter(
    (a) => a.yearKey === year && a.monthKey === month - 1
  );

  return (
    <div className="chart-dropdown-wrapper">
      {years.length > 1 && months.length > 1 ? (
        <div className="dropdown-wrapper">
          <Dropdown
            id="select-target-year"
            label="Select Year"
            onChange={(e) => setYear(e.selectedItem)}
            selectedItem={year}
            size="sm"
            items={years}
            itemToString={(item) => (item ? item : "")}
          />
          <Dropdown
            id="select-target-month"
            label="Select Month"
            onChange={(e) => setMonth(e.selectedItem)}
            selectedItem={month}
            size="sm"
            items={months}
            itemToString={(item) => (item ? monthNames[item - 1] : "")}
          />
        </div>
      ) : (
        <p>
          Year: {years[0]} Month: {monthNames[parseInt(months[0], 10) - 1]}
        </p>
      )}
      <AreaChart data={ticketData} options={options}></AreaChart>
    </div>
  );
};
export default SparkLinewithFilter;
