import { HP_440_G7_CE } from 'Constant';
import React from 'react';
import DataTable from "Carbon-Components/DataTable";
import { automationHeader, monthNames } from './RowsHeaders';
import apps from "./newData.json";


const ContinuousView = () => {
    const transformData = (data) => {
        let inputData = [...data]

        // Create an object to store the transformed data
        const transformedData = {};
        
        for (const item of inputData) {
            const appName = item["App Name"];
            const completionDate = new Date(item["Completion (MMM-YY)"]);
            const annualBenefit = item["Monthly OT Effort (Hrs.)"];
    
            const targetDate = new Date('2023-12-31');
    
            const remainingMonths = (targetDate.getFullYear() - completionDate.getFullYear()) * 12 + (12 - completionDate.getMonth());
            const currentMonthBenifit = item["Monthly OT Effort (Hrs.)"] / 2
    
            if(item["Benefit Category"] === "OT-ES" || remainingMonths === 1){
                const key = `${appName}-${completionDate.getMonth() + 1}-${completionDate.getFullYear()}`;
    
                if (!transformedData[key]) {
                    transformedData[key] = {
                        group: appName,
                        date: new Date(completionDate.getFullYear(), completionDate.getMonth(), 1),
                        value: 0,
                    };
                }
    
                transformedData[key].value += annualBenefit;
            }
                
            else if(remainingMonths > 1){
            const monthlyBenefit = annualBenefit;
    
            const monthLimit =  12
            for(let j = completionDate.getFullYear(); j <= targetDate.getFullYear() ; j++){
              for (let i = j === completionDate.getFullYear() ? completionDate.getMonth() : 0; i < monthLimit; i++) {
                const key = `${appName}-${i + 1}-${j}`;
    
                if (!transformedData[key]) {
                    transformedData[key] = {
                        group: appName,
                        date: new Date(j, i, 1),
                        value: 0,
                    };
                }
    
                const isCurrentMonth = i === completionDate.getMonth() && j === completionDate.getFullYear()
                const benifit = isCurrentMonth ? currentMonthBenifit : monthlyBenefit 
    
                transformedData[key].value += benifit;
              }
            }
            }
            }
    
        // Convert the transformedData object to an array
        const resultArray = Object.values(transformedData);
        return [...resultArray];
    };

  const data = transformData(JSON.parse(JSON.stringify(apps)))

  const emissionData = data.map((obj, index) => {
    obj.id = index
    obj.value = obj.value.toFixed(1)
    obj.emission = (Number(obj.value) * HP_440_G7_CE).toFixed(3)
    return obj
  })

  const getRowCellData = (id, data, row) => {
    
    if(id.includes(":date")){
        const date = new Date(data);
        const month = date.getMonth();
        const year = date.getFullYear();
        const shortMonthName = monthNames[month];
        return `${shortMonthName}-${year}`;
    }

    return data;
  };

  return (
    <DataTable
        rowData={emissionData}
        headerData={automationHeader}
        getRowCellData={getRowCellData}
        title=""
        placeholder="Search for Applications"
        actionsNeeded={false}
        getTableHeader={(header) => header}
        toolbarNeeded={false}
      />
  )
}

export default ContinuousView