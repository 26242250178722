export const getIconSrc = (type) => {
    if (type) {
        if (type.includes("HTTP")) return require(`../../../Assets/aws/http.png`);
        if (type.includes("ApiGateway")) return require(`../../../Assets/aws/ApiGateway.png`);
        if (type.includes("DynamoDB")) return require(`../../../Assets/aws/DynamoDB.png`);
        if (type.includes("ECS")) return require(`../../../Assets/aws/ECS.png`);
        if (type.includes("EC2")) return require(`../../../Assets/aws/EC2.png`);
        if (type.includes("SNS")) return require(`../../../Assets/aws/SNS.png`);
        if (type.includes("Lambda")) return require(`../../../Assets/aws/Lambda.png`);
        if (type.includes("S3")) return require(`../../../Assets/aws/S3.png`);
        if (type.includes("SimpleNotificationService")) return require(`../../../Assets/aws/SimpleNotificationService.png`);
        if (type.includes("SQS")) return require(`../../../Assets/aws/SQS.png`);
        if (type.includes("StepFunctions")) return require(`../../../Assets/aws/StepFunctions.png`);
        if (type.includes("SSM") || type.includes("SimpleSystemsManagement")) return require(`../../../Assets/aws/SSM.png`);
        if (type.includes("STS")) return require(`../../../Assets/aws/STS.png`);
        if (type.includes("remote")) return require(`../../../Assets/aws/apps.png`);
        if (type.includes("Database") || type.includes("RDS")) return require(`../../../Assets/aws/RDS.png`);
        if (type.includes("client")) return require(`../../../Assets/aws/client.png`);
        if (type.includes("user")) return require(`../../../Assets/aws/user.png`);
        if (type.includes("ECS") && type.includes("Fargate")) return require(`../../../Assets/aws/Fargate.png`);

    } else {
        return require(`../../../Assets/aws/object.png`);
    }
}

export const getErrorFaultyClass = (node) => {
    return node.error ? "node_dark_orange" :  node.fault ? "node_orange" : "";
}

export const getColorClass = (node) => {
    return node.hasAnomaly ? "node_red" : getErrorFaultyClass(node);
}

const nodeHeight = 64;
const nodeWidth = 64;

export const getEdgeStart = (nodeData, nodeId) => {
    const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
    if (node) {
        return {
            x: `${node.x + nodeWidth / 2}`,
            y: `${node.y + nodeHeight / 2}`,
        };
    }
}

export const getEdgeEnd = (nodeData, nodeId) => {
    const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
    if (node) {
        return {
            x: `${node.x}`,
            y: `${node.y + nodeHeight / 2}`,
        };
    }
}
