import { Column, Row, Tile } from "@carbon/react";
import TooltipCarbon from "Carbon-Components/Tooltip";
import React from "react";

const tenant = sessionStorage.getItem("tenant");
const EA_Tenant = tenant === "Energy_Australia";

const ApplicationTypes = () => {
  return (
    <Row className="application-types" style={{justifyContent: "space-between"}}>
      <Column lg={5} md={4} sm={4} className="pr-05">
        <Tile className="application-tiles tile-1">
          <p>Platinum (3)</p>
          {EA_Tenant ? (
            <TooltipCarbon description={"MuleSoft On-Prem, Maximo, Maximo SSRS"}>
              <p style={{color: "#fff"}}>MuleSoft On-Prem ...</p>
            </TooltipCarbon>
          ) : (
            <TooltipCarbon description={"Application_1, Application_3, Application_7"}>
              <p style={{color: "#fff"}}>Application_1 ...</p>
            </TooltipCarbon>
          )}
        </Tile>
      </Column>
      <Column lg={5} md={4} sm={4} className="pl-05">
        <Tile className="application-tiles tile-2">
          <p>Gold {EA_Tenant ? "(10)" : "(5)"}</p>
          {EA_Tenant ? (
            <TooltipCarbon description={"MDMS, C1-CCnB, MuleSoft Cloud, Control-M, Energy Apps, GoldenGate, EOT, CDM, Salesforce, IM-ODI"}>
              <p style={{color: "black"}}>MDMS, C1-CCnB ...</p>
            </TooltipCarbon>
          ) : (
            <TooltipCarbon description={"Application_2, Application_4, Application_5, Application_8, Application_10"}>
              <p style={{color: "black"}}>Application_2 ...</p>
            </TooltipCarbon>
          )}
        </Tile>
      </Column>
      {EA_Tenant ? <Column lg={5} md={4} sm={4} className="pl-05">
        <Tile className="application-tiles tile-3">
          <p>Bronze (2)</p>
          <TooltipCarbon description={"JIRA & Slack, IM Reporting"}>
          <p style={{color: "black"}}>JIRA & Slack ...</p>
          </TooltipCarbon>
        </Tile>
      </Column> : 
        <Column lg={5} md={4} sm={4} className="pl-05">
        <Tile className="application-tiles tile-3">
          <p>Bronze (2)</p>
          <TooltipCarbon description={"Application_6, Application_9"}>
          <p style={{color: "black"}}>Application_6 ...</p>
          </TooltipCarbon>
        </Tile>
      </Column> 
      }
    </Row>
  );
};

export default ApplicationTypes;
