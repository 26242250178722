export const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

export const headers = [
    {
        key: "Tower",
        header: "Tower",
    },
    {
      key: "App Name",
      header: "App Name",
    },
    {
      key: "CI Category",
      header: "CI Category",
    },
    {
      key: "Completion (MMM-YY)",
      header: "Completion (MMM-YYYY)",
    },
    {
        key: "Year",
        header: "Year",
    },
    {
      key: "Monthly OT Effort (Hrs.)",
      header: "Monthly OT Effort (Hrs.)",
    },
    {
      key: "Benefit Category",
      header: "Benefit Category",
    },
    {
      key: "Accumulated Hours(hrs)",
      header: "Accumulated Hours(hrs)",
    },
    {
      key: "Total Carbon Savings(kgCO₂eq)",
      header: "Total carbon Savings(kgCO₂eq)",
    },
    {
      key: "monthlyBreakup",
      header: "Monthly Breakup",
    }
  ];

  export const automationHeader = [
    {
      key: "group",
      header: "Application Name",
    },
    {
      key: "date",
      header: "Month(MMM-YYYY)",
    },
    {
      key: "value",
      header: "Monthly Efforts Saved(Hrs)",
    },
    {
      key: "emission",
      header: "Total carbon Savings(kgCO₂eq)",
    }
  ];

  export const automationHeader2 = [
    {
      key: "group",
      header: "Application Name",
    },
    {
      key: "value",
      header: "Monthly Efforts Saved(Hrs)",
    },
    {
      key: "emission",
      header: "Total carbon Savings(kgCO₂eq)",
    }
  ];
  