export const rowData = [
    {
        id: "python",
        language: "Python",
        name: "LSTM",
        accuracy: "75%",
        cpu: "2 GB",
        memory: "4 GB"
    },
    {
        id: "js",
        language: "Javascript",
        name: "LSTM",
        accuracy: "73%",
        cpu: "1 GB",
        memory: "2 GB"
    }
]

export const groupedChart = {
  label: "",
  dataType: "bar",
  data: {
    chartData: [
      {
        group: 'CPU',
        key: 'Python',
        value: 2,
      },
      {
        group: 'Memory',
        key: 'Python',
        value: 4,
      },
      {
        group: 'CPU',
        key: 'Javascript',
        value: 1,
      },
      {
        group: 'Memory',
        key: 'Javascript',
        value: 2,
      },
    ],
    chartOptions: {
      title: `Resource Utilization`,
      axes: {
        bottom: {
          title: "Programming Language",
          mapsTo: "key",
          scaleType: "labels",
        },
        left: {
          mapsTo: "value",
          title: `Resource Consumed(GB)`,
        },
      },
      height: "260px",
      color: {
        scale: {
          CPU : "#198038",
          Memory: "#6fdc8c",
        },
      }
    },
  },
}

export const cpuChart = {
    label: "",
    dataType: "simpleBar",
    data: {
      chartData: [
        {
          group: 'Python',
          value: 2,
        },
        {
          group: 'Javascript',
          value: 1,
        },
      ],
      chartOptions: {
        title: `CPU Consumed`,
        axes: {
          bottom: {
            title: "Programming Language",
            mapsTo: "group",
            scaleType: "labels",
          },
          left: {
            mapsTo: "value",
            title: `CPU Consumed(GB)`,
          },
        },
        height: "225px",
        legend: {
          enabled: false,
        },
        color: {
          scale: {
            Python: "#198038",
            Javascript: "#6fdc8c",
          },
        },
        // grid: {
        //   x: {
        //     enabled: false,
        //   },
        //   y: {
        //     enabled: false,
        //   },
        // },
      },
    },
};

export const memoryChart = {
    label: "",
    dataType: "simpleBar",
    data: {
      chartData: [
        {
          group: 'Python',
          value: 4,
        },
        {
          group: 'Javascript',
          value: 2,
        },
      ],
      chartOptions: {
        title: `Memory Consumed`,
        axes: {
          bottom: {
            title: "Programming Language",
            mapsTo: "group",
            scaleType: "labels",
          },
          left: {
            mapsTo: "value",
            title: `Memory Consumed(GB)`,
          },
        },
        height: "225px",
        legend: {
          enabled: false,
        },
        color: {
          scale: {
            Python: "#198038",
            Javascript: "#6fdc8c",
          },
        },
      },
    },
};