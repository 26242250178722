import React from "react";
import PipelineUsageReport from "./PipelineUsageReport";

function PipelineUsage() {
    return (
        <>
            {/* <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/intelligentALM">Intelligent ALM</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>Pipeline Usage</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid> */}
            <div className="content-div">
                <PipelineUsageReport />
            </div>
        </>
    );
}

export default PipelineUsage;
