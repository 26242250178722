import { FlexGrid } from "@carbon/react";
import React from "react";
import CpgTilesComponent from "./CpgTilesComponent";
import CpgWorkflow from "./Cpgworkflow.json";
import "./cpg.scss";

const CpgIndex = () => {

    return (
        <FlexGrid className="Cpg">
            <CpgTilesComponent dashboardData={CpgWorkflow} />
        </FlexGrid>
    )
}

export default CpgIndex;