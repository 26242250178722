/** @format */

import { ErrorBoundary } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { formatDate, formatDateWithYear } from "Common-Modules/ConvertUTCDate";
// import FormValidation from "Common-Modules/formModule/FormValidation";
// import ErrorFallback from "Components/Common/ErrorFallback";
import React, { useEffect, useState } from "react";
// import { getTenantDetails } from "services/serverapi";
// import FormValidation from "Common-Modules/Forms/FormValidation";
import ErrorFallback from "Components/Common/ErrorFallback";
// import { getTenantDetails } from "Services/ServerApi";
// import FormValidation from "Common-Modules/Forms/FormValidation";
// import { getTenantDetails } from "Components/ICPSA/Services/ServerApi";
import FormValidation from "Common-Modules/Forms/FormValidation";
import { getTenantsAPI } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
import DataTable from "../../Carbon-Components/DataTable";
import CreateTenant from "./CreateTenant";
import DeactivateTenant from "./DeactivateTenant";
import EditTenant from "./EditTenant";
import TenantDetails from "./TenantDetails";
import addTenant from "./addTenantStep1.json";

function TenantList() {
  const { keycloak } = useKeycloak();
  const [rowData, setRowData] = useState("");
  const [rowDatatemp, setRowDatatemp] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  const [canEnableCreateTenant, setCanEnableCreateTenant] = useState(false);
  const [editBox, showEditBox] = useState(false);
  const [editTenant, setEditTenant] = useState(undefined);
  const [deactivateBox, setDeactivateBox] = useState(false);
  const [deActivateTenant, setDeactivateTenant] = useState(undefined);
  const [tenantRow, setTenantRow] = useState({});
  const [showTenantDetails, setShowTenantDetails] = useState(false);
  const [addTenantJSONUpdated, setAddTenantJSONUpdated] = useState([]);
  const [isRecordsEmpty, setIsRecordsEmpty] = useState(false);

  useEffect(() => {
    getTenants();
  }, []);

  useEffect(() => {
    if (!editBox) {
      setEditTenant(undefined);
    }
  }, [editBox]);

  useEffect(() => {
    if (!deactivateBox) {
      setDeactivateTenant(undefined);
    }
  }, [deactivateBox]);

  const getTenants = async () => {
    const tenantDetails = await getTenantsAPI(keycloak, uuidv4());
    if (
      tenantDetails.err ||
      !Array.isArray(tenantDetails)
      // ||
      // tenantDetails.length === 0
    ) {
      setErrorMessage(
        "Something went wrong!. Please, Try again after sometime",
      );
    } else {
      // if (
      //   tenantDetails.length === 0
      // ) {
      //   setIsRecordsEmpty(true);
      // }
      // else {
      //   setIsRecordsEmpty(false);
      let tenantData = tenantDetails;
      let tenants = [];
      let tenantObj = {};
      tenantData.forEach((tenant) => {
        if (tenant.name !== "dso" && tenant.name !== "itvsdp") {
          tenantObj = {};
          tenantObj.id = tenant.id;
          tenantObj.name = tenant.name;

          tenantObj.displayName = tenant.attributes?.displayName?.toString();
          tenantObj.description = tenant.attributes?.description?.toString();
          tenantObj.engagement_Id = tenant.attributes?.engagement_Id?.toString();
          tenantObj.engagement_name = tenant.attributes?.engagement_name?.toString();
          tenantObj.industry = tenant.attributes?.industry?.toString();
          tenantObj.startDate = tenant.attributes?.startDate?.toString();
          tenantObj.endDate = tenant.attributes?.endDate?.toString();

          tenants.push(tenantObj);
        }
      });
      sessionStorage.setItem("alltenants", JSON.stringify(tenants));
      setRowDatatemp(tenants);
      setRowData(tenants);
      // }
    }
  };

  const getHeaderData = () => {
    return [
      {
        key: "name",
        header: "Tenant Name",
      },
      {
        key: "displayName",
        header: "Display Name",
      },
      {
        key: "engagement_Id",
        header: "Engagement ID",
      },
      {
        key: "engagement_name",
        header: "Engagement Name",
      },
      {
        key: "industry",
        header: "Industry",
      },
      {
        key: "description",
        header: "Description",
      },
      {
        key: "status",
        header: "Status",
      },
      {
        key: "startDate",
        header: "Starts on ",
      },
      {
        key: "endDate",
        header: "Ends on",
      },
    ];
  };

  const getTenantRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getTenantRow(rowData, id);
    setTenantRow(row);
    setSelectedTenantinJSON(row);
    setShowTenantDetails(true);
  };
  sessionStorage.setItem("selectedTenant", tenantRow.name);

  const getRowCellData = (id, data, row) => {
    if (id.includes(":status")) {
      return data || "-";
    }
    if (id.includes(":startDate")) {
      return FormValidation.isDateValid(data) ? <>{formatDate(data)}</> : "-";
    }
    if (id.includes(":endDate")) {
      return FormValidation.isDateValid(data) ? (
        <>{formatDateWithYear(data)}</>
      ) : (
        "-"
      );
    }
    if (id.includes(":name")) {
      return (
        <a
          className="event-status"
          onClick={() => {
            onClickNode(row.id);
          }}
        >
          {data}
        </a>
      );
    }
    return data;
  };
  const getTableHeader = (header) => {
    return header;
  };

  const enableCreateTenant = (key) => {
    setCanEnableCreateTenant(key);
  };

  const closeCreateTenant = (relead) => {
    setCanEnableCreateTenant(false);
    if (relead) {
      onRefresh();
    }
  };

  const onCloseTenantDetails = () => {
    setShowTenantDetails(false)
  };

  const onRefresh = () => {
    setRowDatatemp("");
    setRowData("");
    getTenants();
  };

  // const onEdit = (selected) => {
  //   let data = "";
  //   rowDatatemp.forEach((row) => {
  //     if (row.id === selected.id) data = row;
  //   });
  //   setEditTenant(data);
  //   showEditBox(true);
  // };

  // const checkDisable = (row) => {
  //   let data = "";
  //   rowData.forEach((obj) => {
  //     if (obj.id === row.id) data = obj;
  //   });
  //   if (
  //     sessionStorage.getItem("tenant") === row.cells[0].value ||
  //     data["publicTenant"] === "true"
  //   )
  //     return "disable--component";
  //   return "";
  // };

  // const toggleTenant = async (row) => {
  //   let data = "";
  //   rowData.forEach((obj) => {
  //     if (obj.id === row.id) data = obj;
  //   });
  //   if (
  //     sessionStorage.getItem("tenant") === row.cells[0].value ||
  //     data["publicTenant"] === "true"
  //   ) {
  //     alert("operation prohibited");
  //   } else {
  //     setDeactivateTenant(data);
  //     setDeactivateBox(true);
  //   }
  // };

  // const getActions = (row, rows) => {
  //   return (
  //     <div className="flex-display">
  //       <li className={`actions-icon ${checkDisable(row)}`}>
  //         <Tooltip align="bottom" description="Deactivate Tenant">
  //           <button type="button" className="tooltip-button">
  //             <Toggle
  //               size="sm"
  //               id={`activeInactive${row.id}`}
  //               labelB=""
  //               labelText="activate"
  //               labelA=""
  //               toggled={true}
  //               disabled={false}
  //               onToggle={(e) => {
  //                 toggleTenant(row);
  //               }}
  //               hideLabel={true}
  //             />
  //           </button>
  //         </Tooltip>
  //       </li>
  //       <li className="actions-icon ">
  //         <Tooltip align="bottom" description="Edit">
  //           <button type="button" className="tooltip-button">
  //             <Edit size={16} fill="#525252" onClick={() => onEdit(row)} />
  //           </button>
  //         </Tooltip>
  //       </li>
  //     </div>
  //   );
  // };

  const setSelectedTenantinJSON = (tenant) => {
    console.log("tenant:", tenant);
    let addTenantJSONUpdatedTemp = JSON.parse(JSON.stringify(addTenant));
    addTenantJSONUpdatedTemp.forEach((formField) => {
      if (formField.id === "tenant") {
        formField.inputValue = tenant.name;
        formField.readOnly = true;
      }
      if (formField.id === "displayName") {
        formField.inputValue = tenant.displayName;
        formField.readOnly = true;
      }
      if (formField.id === "description") {
        formField.inputValue = tenant.description;
        formField.readOnly = true;
      }
      if (formField.id === "engagement_Id") {
        formField.inputValue = tenant.engagement_Id;
        formField.readOnly = true;
      }
      if (formField.id === "engagement_name") {
        formField.inputValue = tenant.engagement_name;
        formField.readOnly = true;
      }
      if (formField.id === "industry") {
        formField.inputValue = tenant.industry;
        formField.readOnly = true;
      }
      if (formField.id === "startDate") {
        formField.inputValue = tenant.startDate;
        formField.readOnly = true;
      }
      if (formField.id === "endDate") {
        formField.inputValue = tenant.endDate;
        formField.readOnly = true;
      }
    })
    setAddTenantJSONUpdated(addTenantJSONUpdatedTemp);
    console.log("addTenantJSONUpdatedTemp: ", addTenantJSONUpdatedTemp);
  }

  return (
    showTenantDetails ? <TenantDetails addTenant={addTenantJSONUpdated} tenantRow={tenantRow} closeTenantDetails={onCloseTenantDetails} /> :
      <div>
        {
          errMessage ? (
            <ErrorFallback />
          ) : (
            <>

              {
                canEnableCreateTenant ? (
                  <CreateTenant
                    createdTenant={closeCreateTenant}
                  />
                ) : (
                  // !isRecordsEmpty ? (
                  <ErrorBoundary fallback={<ErrorFallback />}>
                    <DataTable
                      rowData={rowData}
                      headerData={getHeaderData()}
                      title="Tenants"
                      createTitle="Create Tenant"
                      getRowCellData={getRowCellData}
                      getTableHeader={getTableHeader}
                      createFunction={enableCreateTenant}
                      disableCreate={
                        !keycloak.realmAccess.roles.includes("Dashboard_Admin")
                      }
                      onRefresh={() => onRefresh()}
                      placeholder="Search by TenantID, Name, Description.."
                      actionsNeeded={false}
                    // table_particular_actions={true}
                    // getActions={getActions}
                    />
                  </ErrorBoundary>
                  // ) : (
                  //   // <ErrorFallback />
                  //   <DataUnAvailable />
                  // )
                )}
              {
                editBox ? (
                  <EditTenant
                    tenant={editTenant}
                    cancel={(refresh) => {
                      showEditBox(false);
                      if (refresh) onRefresh();
                    }}
                  />
                ) : null
              }
              {
                deactivateBox ? (
                  <DeactivateTenant
                    tenant={deActivateTenant}
                    cancel={(refresh) => {
                      setDeactivateBox(false);
                      if (refresh) onRefresh();
                    }}
                  />
                ) : null
              }
            </>
          )
        }
      </div >
  );
}

export default TenantList;
