/** @format */

import {
    Layer,
    Tile
} from "@carbon/react";
import React from "react";
import { WarningFilled } from "@carbon/react/icons/index"

const ErrorWhileFetchingShort = (props) => {
    return (
        < Tile className="chart-container" >
            <Layer className="data_unavailable--layer-short">
                <span><WarningFilled /></span>
                <span style={{ color: "gray", fontSize: "1.4rem", verticalAlign: "middle" }}> {props.errMessage ? <>{props.errMessage}</> : <>Error while fetching data</>}</span>
            </Layer>
        </Tile>
    );
}

export default ErrorWhileFetchingShort;