import PowerBi from "Components/PowerBI/PowerBI";
import React from "react";

function ReleaseInsights() {
  return (
    <>
      <PowerBi reportId="27b74507-a4f5-4985-a986-26a4d6e52e71" />
    </>
  );
}

export default ReleaseInsights;
