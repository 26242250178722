import { Column, FlexGrid, Row } from '@carbon/react';
import CarbonTabs from 'Carbon-Components/Tabs';
import ApplicationList from 'Components/Application/ApplicationList';
import OrganizationList from 'Components/Organization/OrganizationList';
import TenantList from 'Components/tenant/TenantList';
import React from 'react';

const AdminPanel = () => {
    return (
        <FlexGrid>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <CarbonTabs
                        tabIndex={0}
                        selectionMode="automatic"
                        type="default"
                        Tablist={["Tenants", "Organizations", "Applications"]}
                        TabPanelsList={[<TenantList key="tenants" />, <OrganizationList key="orgs" />, <ApplicationList key="applications" />]}
                    />
                </Column>
            </Row>
        </FlexGrid>
    );
}

export default AdminPanel