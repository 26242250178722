import {
    ErrorBoundary
} from "@carbon/react";

import React from "react";
import ErrorWhileFetching from "../../ICPSA/utilities/ErrorWhileFetching";
import TopologyChart from "./TroubleshootTopologyChart";

const serviceRow = { "StartTime": "2024-02-26 14:11", "EndTime": "2024-02-26 14:21", "ContainsOldGroupVersions": false, "application_id": { "timestamp": 1690524822, "date": "2023-07-28T06:13:42.000+00:00" }, "Session_Event": "<botocore.hooks.EventAliaser object at 0x0000027D703757F0>", "inserted_on": "2024-02-26T14:21:47.533+00:00", "inserted_by": " ", "id": "65dca8d7af57b50c5336e975", "No of service": 12, "hasIssue": "error", "hasAnomaly": "No", "hasError": "Yes", "hasFault": "No" }

function TopologyGraphComponent() {

    const atoFlow = sessionStorage.getItem("atoFlow");

    const onServiceSummaryClick = () => {
        window.location.href = "#/performanceAvailability";
    }

    return (
        <>
            <div className="content-div">
                {
                    <ErrorBoundary fallback={<ErrorWhileFetching />}>
                        {/* <TopologyChart title={JSON.parse(sessionStorage.getItem("insightsTilesData")).data[0].data[0].title} serviceObj={JSON.parse(sessionStorage.getItem("serviceRow"))} /> */}
                        <TopologyChart serviceObj={JSON.parse(sessionStorage.getItem("serviceRow"))} />
                        {/* <TopologyChart serviceObj={serviceRow} /> */}

                    </ErrorBoundary>
                }
            </div>
        </>
    );
}

export default TopologyGraphComponent
