import { Breadcrumb, BreadcrumbItem, Column, Grid } from "@carbon/react";
import React from "react";
import PlatformMetricsReport from "./PlatformMetricsReport";

const PlatformMetrics = () => {
  return (
    <>
      <Grid>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            {/* <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa/platformInsights">
              Platform Insights
            </BreadcrumbItem> */}
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/itOperations">Integrated Operations</BreadcrumbItem>
            {/* <BreadcrumbItem href="#/icpsa/platformInsights">
              Platform Observability
            </BreadcrumbItem> */}
            <BreadcrumbItem href="#/itOperations">Platform Observability</BreadcrumbItem>
            <BreadcrumbItem href="#/icpsa/platformAlerts">
              Platform Alerts
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Platform Metrics</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Grid>
      <div className="content-div">
        <PlatformMetricsReport />
      </div>
    </>
  );
};

export default PlatformMetrics;
