import {
    Breadcrumb,
    BreadcrumbItem,
    Column,
    Grid,
} from "@carbon/react";
import React, { useState } from 'react';
import TraceSummary from './TraceSummaryDetails';

const TraceComponent = () => {
    const [currentPage, setCurrentPage] = useState("Trace Summary");
    const [goBack, setGoBack] = useState(false);

    const atoFlow = sessionStorage.getItem("atoFlow");

    const handleTraceClick = () => {
        setCurrentPage("Trace Details");
        setGoBack(false);
    }

    const onTraceSummaryClick = () => {
        setCurrentPage("Trace Summary");
        setGoBack(true);
    }

    return (
        <>
            <Grid>
                <Column lg={12} md={8} sm={4}>
                {atoFlow === "ato" ? <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/valueStream">IT Value Stream Observability</BreadcrumbItem>
              <BreadcrumbItem href="#/atoDetails">Incidents</BreadcrumbItem>
              <BreadcrumbItem href="#/topologyGraph">Topology Graph</BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>Trace Summary</BreadcrumbItem>
            </Breadcrumb>
    :   
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/itOperations">Topology Graph</BreadcrumbItem>
                        {
                            currentPage === "Trace Summary" ? <BreadcrumbItem isCurrentPage>Trace Summary</BreadcrumbItem> : ""
                        }
                        {
                            currentPage === "Trace Details" ?
                                <>
                                    <BreadcrumbItem href="#/traceSummary" onClick={() => onTraceSummaryClick()} >Trace Summary</BreadcrumbItem>
                                    <BreadcrumbItem isCurrentPage>Troubleshoot View</BreadcrumbItem>
                                </> : ""
                        }
                    </Breadcrumb>}
                </Column>
            </Grid >
            <div className="content-div trace">
                <TraceSummary title="Trace Summary"
                    traceClick={handleTraceClick}
                    goBack={goBack}
                />
            </div>
        </>
    );
}

export default TraceComponent
