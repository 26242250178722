import React, { useState } from "react";
import { Tile } from "@carbon/react";
import { ChevronRight, ChevronLeft } from "@carbon/react/icons";

const AppInferences = () => {
  const inferences = [
    "The platinum application category experienced a 13% increase compared to the previous month.",
    "The gold application category experienced a 10% increase compared to the previous month.",
    ];
  const inferenceLength = inferences.length;
  const [index, setIndex] = useState(0);

  const nextInf = () => {
    setIndex((prev) => {
      if (prev === inferenceLength - 1) return 0;
      return prev + 1;
    });
  };

  const prevInf = () => {
    setIndex((prev) => {
      if (prev === 0) return inferenceLength - 1;
      return prev - 1;
    });
  };

  return (
    <Tile className="inference-tile" style={{height: "30%"}}>
      <div className="inference-heading">
        Inferences
      </div>
      <div className="inference">
        <p className={`inference-description`}>
          {inferences[index]}
        </p>
        <ChevronLeft
          className="left-arrow"
          size={20}
          onClick={() => prevInf()}
        />
        <ChevronRight
          className="right-arrow"
          size={20}
          onClick={() => nextInf()}
        />
      </div>
    </Tile>
  );
};

export default AppInferences;
