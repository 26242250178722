import { FlexGrid, Row } from "@carbon/react";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import valueStream from "../ValueStream.json";
import PortfolioTiles from "./PortfolioTiles";

const ConsumerIndex = () => {
  // const { data: valueStream } = useQuery(["valueStream"]);
  const context = useContext(Context);
  const [polarizeData, setPolarizeData] = useState({});

  useEffect(() => {
    // console.log("context =============", context, valueStream)
    if (valueStream !== undefined && valueStream.data !== undefined) {
      const response = valueStream.data.valueStreamDetails.filter(
        (fil) => fil.title === context.valueStreamFlow
      );
      context.valueStreamData = response[0];
      setPolarizeData(response[0]);
    }
  }, [context, valueStream]);
  // const [dateIndexChange, onDateIndexChange] = useState(2);
  // const [dateChange, onDateChange] = useState("1 Day");

  // const dateOnChange = (e, index) => {
  //   console.log("e", e);
  //   console.log("index", index);
  //   onDateIndexChange(index);
  //   onDateChange(e);
  // };

  // const dateSelection = () => {
  //   const dateArr = ["1 Hr", "12 Hr", "1 Day"];
  //   return dateArr.map((date, index) => (
  //     <li
  //       onClick={() => dateOnChange(date, index)}
  //       className={index === dateIndexChange && "active"}
  //     >
  //       {date}
  //     </li>
  //   ));
  // };
  // console.log("context =============", Object.keys(polarizeData))
  return (
    <FlexGrid style={{ padding: "0px" }}>
      {/* <Row>
        <Column lg={13} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              IT Value Stream Observability
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              Polaris Customer Journey
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
        <Column lg={3} md={2} sm={2}>
          <ul className="portfolio-fss">{dateSelection()}</ul>
        </Column>
      </Row> */}
      {Object.keys(polarizeData).length > 0 && (
        <Row>
          <PortfolioTiles portfolioData={polarizeData.data} />
        </Row>
      )}
    </FlexGrid>
  );
};

export default ConsumerIndex;
