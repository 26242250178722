import React, { useState } from "react";

import {
    Column,
    ContentSwitcher,
    DatePicker,
    DatePickerInput,
    Dropdown,
    Row,
    Switch
} from "@carbon/react";
import { Calendar, EventSchedule, Time } from "@carbon/react/icons/index";
import KenDashboardTiles from "./JobDashboardTiles";
import DailyJobs from "./JobDailyJobs";


const dashboardJson = [{
    "dataProduct": "Data Platform 1",
    "jobid": 1234,
    "runid": 2,
    "jobName": "vwrshp_dp_src_raw_cms_bbv_rights_intr_dy",
    "planned-start-time": "20/09/2023 11:10:00 AM",
    "start-time": "20/09/2023 11:12:00 AM",
    "end-time": "20/09/2023 11:18:00 AM",
    "anamoly": 2,
    "total": 2450,
    "success": 2330,
    "percent": 97,
    "status": [{ status: 'error', count: 0 }, { status: 'running', count: 0 }, { status: 'start', count: 0 }, { status: 'run', count: 0 }]
}, {
    "dataProduct": "Data Platform 2",
    "jobid": 1234,
    "runid": 2,
    "jobName": "vwrshp_dp_src_raw_cms_bbv_rights_intr_dy",
    "planned-start-time": "20/09/2023 11:10:00 AM",
    "start-time": "20/09/2023 11:12:00 AM",
    "end-time": "20/09/2023 11:18:00 AM",
    "anamoly": 1,
    "total": 39,
    "success": 23,
    "percent": 98,
    "status": [{ status: 'failure', count: 1 }, { status: 'running', count: 15 }, { status: 'start', count: 1 }]
}, {
    "dataProduct": "Data Platform 3",
    "jobid": 1234,
    "runid": 2,
    "jobName": "vwrshp_dp_src_raw_cms_bbv_rights_intr_dy",
    "planned-start-time": "20/09/2023 11:10:00 AM",
    "start-time": "20/09/2023 11:12:00 AM",
    "end-time": "20/09/2023 11:18:00 AM",
    "anamoly": 0,
    "total": 40,
    "success": 24,
    "percent": 99,
    "status": [{ status: 'failure', count: 1 }, { status: 'running', count: 15 }, { status: 'start', count: 1 }]
}, {
    "dataProduct": "Data Platform 4",
    "jobid": 1234,
    "runid": 2,
    "jobName": "vwrshp_dp_src_raw_cms_bbv_rights_intr_dy",
    "planned-start-time": "20/09/2023 11:10:00 AM",
    "start-time": "20/09/2023 11:12:00 AM",
    "end-time": "20/09/2023 11:18:00 AM",
    "anamoly": 3,
    "total": 41,
    "success": 25,
    "percent": 100,
    "status": [{ status: 'failure', count: 1 }, { status: 'running', count: 15 }, { status: 'start', count: 1 }]
}]

const KenDashboard = (props) => {
    const [dateIndexChange, onDateIndexChange] = useState(0);
    const [dashboard] = useState(dashboardJson);
    const [dateChange, onDateChange] = useState("All");
    const [contentSwitch, setContentSwitch] = useState("Executive Overview");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [changeItems, setOnChangeItems] = useState("");

    const dateOnChange = (e, index) => {
        onDateIndexChange(index);
        onDateChange(e);
    };

    const dateSelection = () => {
        const dateArr = ["All", "Hourly", "Daily", "Monthly"];
        return dateArr.map((date, index) => (
            <li
                onClick={() => dateOnChange(date, index)}
                className={`${index === dateIndexChange && "active"} ${date === "Monthly" ? date : ""} `}
            >
                {date}
                {date === "Hourly" ? <Time /> : date === "Daily" ? <EventSchedule /> : date === "Monthly" ? <Calendar /> : null}
            </li>
        ));
    };

    const onContentSwitch = (eve) => {
        setSelectedIndex(eve.index)
        setContentSwitch(eve.text)
    }

    const items = [{
        text: "Job Observability"
    },{
        text: "Mainframe Management"
    }]

    const onChangeItems = (e) => {
        console.log("eeee", e)
        props.SelectedItem(e.selectedItem.text)
    }

    return (
        props.flow !== "value stream" ?
            <>
                <Row className="dashboard--margin">
                    <Column lg={6} md={6} sm={4}>
                        <ContentSwitcher selectedIndex={selectedIndex} className="exe-switch" onChange={(e) => onContentSwitch(e)}>
                            <Switch index={0} name="Executive Overview" text="Executive Overview" />
                            <Switch index={1} name="Audit Report" text="Audit Report" />
                        </ContentSwitcher>
                    </Column>
                    <Column lg={7} md={6} sm={4}></Column>
                    <Column lg={3} md={2} sm={2} className="filter-date">
                        {/* {contentSwitch === "Executive Overview" ? <ul className="portfolio-fss">{dateSelection()}</ul> : null}
                        <DatePicker datePickerType="single" readOnly={true}>
                            <DatePickerInput disabled placeholder="16/10/2023" readOnly={true} labelText="Select Date" id="date-picker-single" size="sm" />
                        </DatePicker> */}
                        {/* <Dropdown id="default" titleText="" onChange={(e) => onChangeItems(e)} helperText="" initialSelectedItem={items[0]} label="" items={items} itemToString={item => item ? item.text : ''} /> */}
                    </Column>
                </Row>
                {contentSwitch === "Executive Overview" ? <KenDashboardTiles
                    dashboardData={
                        dashboard
                    }
                    date={dateChange}
                    dateIndexChange={dateIndexChange}
                /> : null}
                {contentSwitch === "Audit Report" ? <DailyJobs /> : null}
            </> : <KenDashboardTiles
                dashboardData={
                    dashboard
                }
                date={dateChange}
                dateIndexChange={dateIndexChange}
                flow={props.flow}
            />
    );
};

export default KenDashboard;