import { Button } from "@carbon/react";
import React, { useState } from "react";

const Paragraph = ({ text, size }) => {
  const [showFullText, setShowFullText] = useState(false);

  const handleClick = () => {
    setShowFullText((prev) => !prev);
  };

  const displayText = showFullText ? text : text.substring(0, size) + "...";

  return (
    <>
      {displayText}{" "}
      <span>
        <Button
          kind="ghost"
          size="sm"
          className="remove-left-padding"
          onClick={() => handleClick()}
          style={{
            padding: "0",
            verticalAlign: "initial",
          }}
        >
          See {showFullText ? "less" : "more"}
        </Button>
      </span>
    </>
  );
};

Paragraph.defaultProps = {
  text: "",
  size: 100,
};

export default Paragraph;
