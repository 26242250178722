import { Column, ErrorBoundary, Row } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { setChartOptions } from "Common-Modules/Utilities";
import ChartContainer from "Carbon-Charts/ChartContainer";
import { getQueryKeyValue } from "Carbon-Charts/Utilities/EventHandlers";
import ErrorFallback from "Common-Modules/ErrorFallback";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
import Filters from "Components/ICTE/IncidentReports/filters";
import DataTableForCharts from "Carbon-Charts/DataTableChart";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getMendLibraries } from "Services/ServerApi";
import "../securityOps.scss";
import MendIssues from "./mendIssues";
import { v4 as uuidv4 } from "uuid";

const MendOverview = ({ queryKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterChart, setFilterChart] = useState("");
  const [tabFilter, setTableFilter] = useState("");
  const { keycloak } = useKeycloak();
  const chartRefs = useRef(null);
  const { t } = useTranslation();
  const filterFields = useRef(new Map());

  const onSettled = () => {
    setIsLoading(false);
  };

  const {
    isLoading: loading,
    data: res,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    queryKey,
    () =>
      getMendLibraries(
        keycloak,
        uuidv4(),
        "libraryscan",
        filterChart
      ),
    {
      retry: 1,
      enabled: false,
      onSettled,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch, filterChart]);

  useEffect(() => {
    if (res !== undefined && chartRefs.current !== null) {
      const map = getMap();
      for (const [key, value] of map) {
        const node = value;
        const filters = getFilters();
        const fields = filters.get(key);
        const { eventHandler, dataKey, chartKey } = fields;

        const eventHandlerFunc = (e) => {
          const keyValue = getQueryKeyValue(
            e,
            eventHandler,
            dataKey,
            chartKey,
            fields
          );
          if (keyValue === filterChart) {
            setFilterChart("");
          } else {
            setFilterChart(keyValue);
          }
        };
        node.chart.services.events.addEventListener(
          eventHandler,
          eventHandlerFunc
        );
      }
    }
  }, [res, filterChart]);

  function getMap() {
    if (!chartRefs.current) {
      chartRefs.current = new Map();
    }
    return chartRefs.current;
  }

  function getFilters() {
    if (!filterFields.current) {
      filterFields.current = new Map();
    }
    return filterFields.current;
  }

  const tableFilter = (name) => {
    setTableFilter(name)
  }

  const getData = () => {
    if (isLoading || loading || (isFetching && isError)) {
      return <InlineLoader description="Please wait..." status="active" />;
    }

    if (isError) {
      return <ErrorRefresh refresh={refetch} />;
    }

    if (res.data.charts.length > 0) {
      let chartData = res.data.charts.filter(
        (fil) =>
          fil.dataType !== "treeD3" && fil.dataType !== "dataTableInsideChart"
      );

      let treeMapData = res.data.charts.filter(
        (fil) => fil.dataType === "treeD3"
      );
      let tableData = res.data.charts.filter(
        (fil) => fil.dataType === "dataTableInsideChart"
      );

      if (tabFilter !== "") {
        const table = tableData[0].data;
        tableData = [{
          ...tableData,
          data: {
            ...tableData[0].data,
            chartData: table.chartData.filter(
              (fil) => fil.project_name === tabFilter
            )
          },
        }];
      }

      return (
        <>
          {filterChart ? (
            <Filters filters={filterChart} close={() => setFilterChart("")} />
          ) : null}
          <Row className="pl-05 pr-05">
            {chartData.map((res, index) => {
              return (
                <Column
                  lg={res["col-size"]}
                  md={4}
                  sm={4}
                  key={index}
                  className="pl-05 pr-05"
                >
                  {res.data && (
                    <ErrorBoundary fallback={"Data is unavailable"}>
                      {res.data.chartData.length === 0 ? (
                        <h4 className="empty--message">{t(`common.NoDataAvailable`)}</h4>
                      ) : (
                        <>
                          <ChartContainer
                            isFetching={isFetching}
                            data={setChartOptions(res)}
                            chartRef={
                              res.refParameters
                                ? (node) => {
                                  const map = getMap();
                                  const filters = getFilters();
                                  if (node) {
                                    map.set(
                                      res.refParameters.uniqueKey,
                                      node
                                    );
                                    filters.set(
                                      res.refParameters.uniqueKey,
                                      res.refParameters
                                    );
                                  } else {
                                    map.delete(res.refParameters.uniqueKey);
                                    filters.delete(
                                      res.refParameters.uniqueKey
                                    );
                                  }
                                }
                                : null
                            }
                          />
                        </>
                      )}
                    </ErrorBoundary>
                  )}
                </Column>
              );
            })}
            <Column lg={8}>
              {treeMapData.length > 0 && (
                <MendIssues chartData={treeMapData[0]} tableData={tableData[0]} tableFilter={tableFilter} />
              )}
            </Column>
          </Row>
            <DataTableForCharts data={tableData[0].data} />
        </>
      );
    } else {
      <ErrorRefresh refresh={refetch} />;
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>{getData()}</ErrorBoundary>
  );
};

export default MendOverview;
