import React from "react";
import { Column, Grid } from "@carbon/react";
import { WarningAltFilled } from "@carbon/react/icons/index";
import Logout from "./Logout";
import errorIcon from "../Assets/Common/error.svg";
import "./css/errorPage.scss";

const ErrorPage = (props) => {
  return (
    <Grid className="page-401">
      <Column lg={16} md={8} sm={4}>
        <img src={errorIcon} alt="error" className="error-svg" />
        <div className="error-desc">
          <h2>Error <WarningAltFilled size="20" fill="#da1e28" /></h2>
          <p>We apologize for the inconvenience caused</p>
          <p>
            Please contact admin <a
                    href="https://app.slack.com/client/T5HT2F35Y/CTYC8G3L5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   #Support
                  </a>
          </p>
          <p>or try Logout and Login again.</p>
          <Logout />
        </div>
      </Column>
    </Grid>
  );
};

export default ErrorPage;
