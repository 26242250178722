import {
  Button,
  ComboBox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Form,
  InlineLoading,
  MultiSelect,
  Stack,
  TextArea,
  TextInput,
  Toggle,
  Tooltip,
} from "@carbon/react";
import { AddFilled, Information } from "@carbon/react/icons";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import {
  BOTTOM,
  FALSE,
  FIELD_CREDENTIAL,
  FIELD_KEY,
  INPUT_TYPE_DATALIST,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_DROPDOWN_CARBON,
  INPUT_TYPE_KEYVALUE,
  INPUT_TYPE_LABEL,
  INPUT_TYPE_MULTI_SELECT,
  INPUT_TYPE_PANEL,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_TILE,
  INPUT_TYPE_TOGGLE,
  INPUT_TYPE_URL,
  LOADER_STATUS_INACTIVE,
  NO,
  REQUEST_DATA_TYPE_HIDDEN,
  TEXTAREA_COLS,
  TEXTAREA_ROWS,
  TOP,
  TRUE,
  TYPE_ADD_TENANT_USER,
  YES
} from "../../../src/Constant";
import i18n from "../../locale/i18n";
import "./_dynamicform.scss";

class DynamicFormClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      byot: false,
      errors: {},
      keyvaluelen: 1,
      environmentField: [],
    };
    this.doSomething = this.doSomething.bind(this);
    this.getDropdownList = this.getDropdownList.bind(this);
    this.addKeyValue = this.addKeyValue.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onDateInputChange = this.onDateInputChange.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeMultipleOptions =
      this.handleInputChangeMultipleOptions.bind(this);
    this.getValidation = this.getValidation.bind(this);
    this.handleOnFocusOut = this.handleOnFocusOut.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.getInputClass = this.getInputClass.bind(this);
    this.getMinDate = this.getMinDate.bind(this);
    this.getDuplicateCheckValidation =
      this.getDuplicateCheckValidation.bind(this);
    this.getInvalidFieldValidation = this.getInvalidFieldValidation.bind(this);
    this.getEmptyFieldValidation = this.getEmptyFieldValidation.bind(this);
    this.getNonEmptyFieldValidation =
      this.getNonEmptyFieldValidation.bind(this);
    this.getRegExValidation = this.getRegExValidation.bind(this);
    this.getArraysValidation = this.getArraysValidation.bind(this);
    this.getMaxLengthValidation = this.getMaxLengthValidation.bind(this);
    this.getMinLengthValidation = this.getMinLengthValidation.bind(this);

    this.getInputFieldTextNoMask = this.getInputFieldTextNoMask.bind(this);
    this.getInputFieldTextYesMask = this.getInputFieldTextYesMask.bind(this);
    this.getInputFieldURLNoMask = this.getInputFieldURLNoMask.bind(this);
    this.getInputFieldURLYesMask = this.getInputFieldURLYesMask.bind(this);
    this.getInputFieldTextArea = this.getInputFieldTextArea.bind(this);
    this.getInputFieldDropdown = this.getInputFieldDropdown.bind(this);
    this.getInputFieldDropdownCarbon =
      this.getInputFieldDropdownCarbon.bind(this);
    this.getInputFieldDatalist = this.getInputFieldDatalist.bind(this);
    this.getInputFieldCalender = this.getInputFieldCalender.bind(this);
    this.getInputFieldToggle = this.getInputFieldToggle.bind(this);
    this.getInputFieldMultiSelect = this.getInputFieldMultiSelect.bind(this);
    this.getInputFieldLabel = this.getInputFieldLabel.bind(this);
    this.getInputFieldPanel = this.getInputFieldPanel.bind(this);
    this.getInputFieldTile = this.getInputFieldTile.bind(this);
    this.getInputFieldKeyValue = this.getInputFieldKeyValue.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getformData();
    if (this.props.byot !== undefined) {
      this.setState({ byot: this.props.byot });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.formData !== nextProps.formData) {
      this.setState({ formData: nextProps.formData }, () => {
        this.isFormValid();
        this.getformData();
      });
    }
  }

  onDateInputChange(id, event) {
    const pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    if (!pattern.test(event.target.value)) {
      return 0;
    }
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === id) {
        formFieldTemp.inputValue = event.target.value;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    this.isFormValid(id, event.target.value);
    return 1;
  }

  onDateChange(id, event) {
    let updatedFormData = [];

    let dateString = ";";
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === id) {
        const m = new Date(event);
        dateString = `${
          i18n.t("common.months", { returnObjects: true })[m.getMonth()]
        }/${m.getDate()}/${m.getFullYear()}`;
        formFieldTemp.inputValue = dateString;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData }, () => {
      this.props.getDateChange(id, dateString);
      this.isFormValid(id, dateString);
    });
  }

  onToggleChange(event) {
    event.stopPropagation();
  }

  onToggle(id, options, event) {
    let value;
    if (event) {
      value = options[1].value;
    } else {
      value = options[0].value;
    }
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === id) {
        formFieldTemp.inputValue = value;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    this.props.getToggleChange(id, value);
    this.isFormValid(id, value);
  }

  getformData() {
    let duplicateKeys;
    const formData = this.props.formData.map((data) =>
      data.inputType === INPUT_TYPE_KEYVALUE
        ? {
            ...data,
            field: data.field.map((res, index) =>
              res !== undefined && (index === 0 || index === 1)
                ? { ...res, chkIndex: index, no: 1 }
                : res,
            ),
          }
        : data,
    );
    this.props.formData.forEach((data) => {
      if (data.inputType === INPUT_TYPE_KEYVALUE) {
        duplicateKeys = data.field.map((res, index) =>
          res !== undefined && (index === 0 || index === 1)
            ? { ...res, no: 1 }
            : res,
        );
      }
    });
    this.setState({ formData, environmentField: duplicateKeys }, () => {
      this.isFormValid();
    });
  }

  getMinDate() {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  getDropdownList(options, placeHolder, data) {
    const dropDownList = options.map((option) => (
      <option
        className="cds--select-option"
        value={option.value}
        selected={option.value === data.inputValue ? option.value : ""}
        key={`dropdown-${option.name}`}
      >
        {option.name}
      </option>
    ));

    dropDownList.unshift(
      <option
        value="none"
        selected
        disabled
        hidden
        key={`unshift-${placeHolder}`}
      >
        {placeHolder}
      </option>,
    );
    return dropDownList;
  }

  getOptionsList(options) {
    const list = [];

    if (options && options.length) {
      options.forEach((option) => {
        const item = { id: option.value, text: option.name };
        list.push(item);
      });
    }
    list.sort((a, b) => a.text.localeCompare(b.text));
    return list;
  }

  getValidation(formfield) {
    // No validation for credential field
    if (
      formfield.dataFetchFromAPI &&
      formfield.inputType === FIELD_CREDENTIAL
    ) {
      return true;
    }
    // Validation needed?
    if (formfield.isRequired === FALSE && !formfield.inputValue) {
      return true;
    }
    // Duplicate check validation
    if (formfield.duplicate && formfield.duplicate === TRUE) {
      return this.getDuplicateCheckValidation(formfield);
    }
    // invalid field Validation
    if (formfield.invalid) {
      return this.getInvalidFieldValidation(formfield);
    }
    // Empty field validation
    if (
      formfield.isRequired === TRUE &&
      !formfield.inputValue &&
      formfield.inputType !== REQUEST_DATA_TYPE_HIDDEN
    ) {
      return this.getEmptyFieldValidation(formfield);
    }
    // Hidden fields validation
    if (
      formfield.isRequired === TRUE &&
      formfield.inputType === REQUEST_DATA_TYPE_HIDDEN
    ) {
      return true;
    }
    return this.getNonEmptyFieldValidation(formfield);
  }

  getDuplicateCheckValidation(formfield) {
    let fieldErrors = {};
    fieldErrors = this.state.errors;
    fieldErrors[formfield.label] = formfield.errorMessage
      ? formfield.errorMessage
      : i18n.t("form.errors.duplicate");
    this.setState({ errors: fieldErrors });
    return false;
  }

  getInvalidFieldValidation(formfield) {
    let fieldErrors = {};
    fieldErrors = this.state.errors;
    fieldErrors[formfield.label] = "Invalid";
    this.setState({ errors: fieldErrors });
    return false;
  }

  getEmptyFieldValidation(formfield) {
    let fieldErrors = {};
    fieldErrors = this.state.errors;
    if (formfield.inputType === INPUT_TYPE_DATE) {
      fieldErrors[formfield.label] = i18n.t("form.errors.incorrectFormat");
    } else {
      fieldErrors[formfield.label] = i18n.t("form.errors.cannotBeEmpty");
    }
    this.setState({ errors: fieldErrors });
    return false;
  }

  getNonEmptyFieldValidation(formfield) {
    if (typeof formfield.inputValue === "object") {
      return true;
    }
    // Field validation for non Empty
    if (typeof formfield.inputValue !== "undefined") {
      // Select fields validation
      if (
        formfield.inputType === INPUT_TYPE_MULTI_SELECT ||
        formfield.inputType === INPUT_TYPE_DROPDOWN ||
        formfield.inputType === INPUT_TYPE_DROPDOWN_CARBON
      ) {
        if (
          formfield.inputValue === undefined ||
          formfield.inputValue === "" ||
          formfield.inputValue.length === 0
        ) {
          return false;
        }
        return true;
      }
      // RegEx validation

      const newReg = new RegExp(formfield.regex);
      if (
        !Array.isArray(formfield.inputValue) &&
        !formfield.inputValue.match(newReg)
      ) {
        return this.getRegExValidation(formfield);
      }

      // Arrays validation
      if (Array.isArray(formfield.inputValue) && formfield.inputValue.length) {
        formfield.inputValue.forEach((data) => {
          if (data.value !== undefined && data.value.match(formfield.regex)) {
            return this.getArraysValidation(formfield, data);
          }
          return true;
        });
      }
      // Max lenght validation
      if (formfield.maxLength) {
        if (
          formfield.inputValue !== undefined &&
          formfield.inputValue.length > formfield.maxLength
        ) {
          return this.getMaxLengthValidation(formfield);
        }
      }
      // Min length validation
      if (formfield.minLength) {
        if (
          formfield.inputValue !== undefined &&
          formfield.inputValue.length < formfield.minLength
        ) {
          return this.getMinLengthValidation(formfield);
        }
      }
    }
    return true;
  }

  getRegExValidation(formfield) {
    const fieldErrors = this.state.errors;
    if (formfield.regexMessage) {
      fieldErrors[formfield.label] = formfield.regexMessage;
    } else {
      fieldErrors[formfield.label] = i18n.t("form.errors.regexNeedsToMatch");
    }
    this.setState({ errors: fieldErrors });
    return false;
  }

  getArraysValidation(formfield, data) {
    const fieldErrors = this.state.errors;
    if (formfield.regexMessage) {
      fieldErrors[data.label] = formfield.regexMessage;
    } else {
      fieldErrors[data.label] = i18n.t("form.errors.regexNeedsToMatch");
    }
    this.setState({ errors: fieldErrors });
    return false;
  }

  getMaxLengthValidation(formfield) {
    const fieldErrors = this.state.errors;
    fieldErrors[formfield.label] = `${i18n.t("common.maxLenghtError")} ${
      formfield.maxLength
    }`;
    this.setState({ errors: fieldErrors });
    return false;
  }

  getMinLengthValidation(formfield) {
    const fieldErrors = this.state.errors;
    fieldErrors[formfield.label] = `${i18n.t("common.minLengthError")} ${
      formfield.minLength
    }`;
    this.setState({ errors: fieldErrors });
    return false;
  }

  getInputClass(data) {
    const seperationSpace = " ";
    let inputClass = "input-field-bottom-margin";
    if (data.inputType === INPUT_TYPE_PANEL) {
      inputClass += `${seperationSpace}input-field-panel`;
      return inputClass;
    }
    if (data.inputType === INPUT_TYPE_LABEL) {
      return inputClass;
    }

    if (data.inputType === INPUT_TYPE_MULTI_SELECT) {
      inputClass += `${seperationSpace}multiselect-margin`;
    } else if (
      data.inputType === INPUT_TYPE_DATE ||
      data.inputType === INPUT_TYPE_MULTI_SELECT ||
      data.inputType === INPUT_TYPE_TEXTAREA
    ) {
      // no need
    } else if (data.inputType === INPUT_TYPE_DROPDOWN) {
      inputClass += `${seperationSpace}cds--select-input cds--dropdown cds--list-box`;
    }

    if (this.state.errors[data.label] !== undefined && data.visited) {
      inputClass += `${seperationSpace}form-fields-error`;
    } else {
      inputClass += `${seperationSpace}input-field-border`;
    }
    if (this.props.type === TYPE_ADD_TENANT_USER) {
      inputClass += `${seperationSpace}input-field-right-margin`;
    } else if (data.inputType !== INPUT_TYPE_TEXTAREA) {
      inputClass += `${seperationSpace}half-width`;
    }
    return inputClass;
  }

  setkeyValue(form) {
    this.setState((prevState) => ({
      formData: prevState.formData.map((data) =>
        data.inputType === INPUT_TYPE_KEYVALUE
          ? {
              ...data,
              field: [...data.field, ...form],
            }
          : data,
      ),
    }));
  }

  getKeyValue(data) {
    const formfields = data.map((res, index) => (
      <div
        className={res.label === FIELD_KEY ? "keyvalue clear-box" : "keyvalue"}
        key={`${res.label}-${res.displayName}`}
      >
        <label htmlFor={res.label} className="cds--label field-name">
          <span className="tooltip--text-alignement">{res.displayName}</span>

          {res.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={res.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <TextInput
          id={`${res.label}environment ${res.no}`}
          hideLabel={true}
          labelText=""
          placeholder={res.placeHolder}
          required={res.isRequired === TRUE}
          disabled={res.disabled}
          invalid={
            res.errorMsg !== undefined ||
            (this.state.errors[res.label] !== undefined && res.visited)
          }
          value={res.inputValue}
          onChange={(event) => {
            this.handleInputChange(event, res.chkIndex);
          }}
          onBlur={() => {
            this.handleOnFocusOut(res, index, res.no);
          }}
        />
        {/* error text */}
        {res.errorMsg !== undefined ||
        (this.state.errors[res.label] !== undefined && res.visited) ? (
          <p className="errmsg-form">
            {res.errorMsg !== undefined
              ? res.errorMsg
              : this.state.errors[res.label]}
          </p>
        ) : (
          ""
        )}
      </div>
    ));
    return <div>{formfields}</div>;
  }

  getInputFieldKeyValue(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label field-name">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>

        <>
          {Array.isArray(data.field) && data.field.length > 0
            ? this.getKeyValue(data.field)
            : ""}
          <Button
            className="addkey-btn mt-1"
            onClick={() => this.addKeyValue(data)}
            kind="tertiary"
            size="small"
            disabled={this.props.keyValueBtn}
          >
            <AddFilled className="add" />
            {i18n.t("template.manageTemplate.addKeyValue")}
          </Button>
        </>
      </div>
    );
  }

  getInputFieldLabel(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        {Array.isArray(data.inputValue) && data.inputValue.length > 0 ? (
          data.inputValue.map((res, index) => (
            <>
              {res.key !== "" && (
                <p
                  id={`${data.label}-${index}`}
                >
                  {res.key} : {res.value}
                </p>
              )}
            </>
          ))
        ) : (
          <p
            id={data.label}
          >
            {data.inputValue}
          </p>
        )}
        {data.duplicate === TRUE && (
          <p className="errmsg-form">{data.errorMessage}</p>
        )}
      </div>
    );
  }

  getInputFieldTextNoMask(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <TextInput
          id={data.label}
          hideLabel={true}
          labelText=""
          placeholder={this.props.t(data.placeHolder)}
          required={data.isRequired === TRUE}
          disabled={data.disabled}
          invalid={this.state.errors[data.label] !== undefined && data.visited}
          value={
              data.inputValue
          }
          onChange={(event) => {
            this.handleInputChange(event);
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {data.tips !== undefined ? (
          <p className="input-tips">{data.tips}</p>
        ) : null}
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldURLNoMask(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <TextInput
          id={data.label}
          hideLabel={true}
          labelText=""
          placeholder={this.props.t(data.placeHolder)}
          required={data.isRequired === TRUE}
          disabled={data.disabled}
          invalid={this.state.errors[data.label] !== undefined && data.visited}
          value={data.inputValue}
          onChange={(event) => {
            this.handleInputChange(event);
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldTextYesMask(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement">{this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <TextInput
          id={data.label}
          type="password"
          hideLabel={true}
          labelText=""
          placeholder={this.props.t(data.placeHolder)}
          required={data.isRequired === TRUE}
          disabled={data.disabled}
          invalid={this.state.errors[data.label] !== undefined && data.visited}
          value={data.inputValue}
          onChange={(event) => {
            this.handleInputChange(event);
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldURLYesMask(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <TextInput
          id={data.label}
          type="password"
          hideLabel={true}
          labelText=""
          placeholder={this.props.t(data.placeHolder)}
          required={data.isRequired === TRUE}
          disabled={data.disabled}
          invalid={this.state.errors[data.label] !== undefined && data.visited}
          value={data.inputValue}
          onChange={(event) => {
            this.handleInputChange(event);
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldTextArea(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {this.props.t(data.displayName)}</span>

          {data.helpMessage ? (
            <span className="tooltip--info">
              <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </label>
        <TextArea
          id={data.label}
          hideLabel={true}
          className={this.getInputClass(data)}
          rows={TEXTAREA_ROWS}
          cols={TEXTAREA_COLS}
          invalid={this.state.errors[data.label] !== undefined && data.visited}
          placeholder={this.props.t(data.placeHolder)}
          pattern={data.regex}
          required={data.isRequired === TRUE}
          value={data.inputValue}
          onChange={(event) => {
            this.handleInputChange(event);
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited && (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        )}
      </div>
    );
  }

  getInputFieldDropdown(data) {
    return (
      <div>
        <div className="cds--form-item">
          <label htmlFor={data.label} className="cds--label">
            <span className="tooltip--text-alignement">
              {" "}
              {this.props.t(data.displayName)}
            </span>

            {data.helpMessage ? (
              <span className="tooltip--info">
                <Tooltip align="right" description={this.props.t(data.helpMessage)}>
                  <button type="button" className="tooltip-button">
                    <Information size={12} />
                  </button>
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </label>
          {data.loading ? (
            <InlineLoading
              status={
                data.loadingStatus ? data.loadingStatus : LOADER_STATUS_INACTIVE
              }
              description={data.loadingText}
              type="left"
              className="zero-margin"
            />
          ) : (
            <div className="full-width">
              <Dropdown
                label={this.props.t(data.placeHolder)} 
                items={data.options}
                disabled={data.disabled}
                onChange={(event) => {
                  this.handleInputChange({target: {
                    id: data.label,
                    value: event.selectedItem.value
                  }}, data);
                }}
                onBlur={() => {
                  this.handleOnFocusOut(data);
                }}
                itemToString={item => item ? item.name : ''}
              />
            </div>
          )}
          {/* error text */}
          {this.state.errors[data.label] !== undefined && data.visited ? (
            <p className="errmsg-form">{this.state.errors[data.label]}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  getInputFieldDropdownCarbon(data) {
    return (
      <div>
        <label
          htmlFor={data.label}
          className={`cds--label ${data.disabled ? "input-disabled" : ""}`}
        >
          <span className="tooltip--text-alignement"> {data.displayName}</span>

          {data.helpMessage ? (
            <span className="tooltip--info">
              <Tooltip align="right" description={data.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </label>
        {data.loading ? (
          <InlineLoading
            status={
              data.loadingStatus ? data.loadingStatus : LOADER_STATUS_INACTIVE
            }
            description={data.loadingText}
            type="left"
            className="zero-margin"
          />
        ) : (
          <ComboBox
            id={data.label}
            className={`bg-white input-field-bottom-margin ${
              data.disabled ? "input-field-disabled" : ""
            } ${
              this.props.type === TYPE_ADD_TENANT_USER
                ? "input-field-right-margin"
                : ""
            }`}
            invalid={
              this.state.errors[data.label] !== undefined && data.visited
            }
            items={this.getOptionsList(data.options)}
            itemToString={(item) => (item ? item.text : "")}
            placeholder={
              data.inputValue !== "" ? data.selectedValue : data.placeHolder
            }
            onChange={(event) =>
              this.handleInputChangeSingleOptions(data.label, event, data)
            }
            onBlur={() => {
              this.handleOnFocusOut(data);
            }}
            label={data.placeHolder}
            ariaLabel={data.displayName}
            disabled={data.disabled}
            selectedItem={data.inputValue}
            initialSelectedItem={data.inputValue}
          />
        )}
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldDatalist(data) {
    return (
      <div>
        <label
          htmlFor={data.label}
          className={`cds--label ${data.disabled ? "input-disabled" : ""}`}
        >
          <span className="tooltip--text-alignement"> {data.displayName}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={data.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        {data.loading ? (
          <InlineLoading
            status={
              data.loadingStatus ? data.loadingStatus : LOADER_STATUS_INACTIVE
            }
            description={data.loadingText}
            type="left"
            className="zero-margin"
          />
        ) : (
          <>
            <input
              type="text"
              list="data"
              onChange={(event) => this.handleInputChange(event)}
              id={data.label}
              className="cds--text-input"
              invalid={
                this.state.errors[data.label] !== undefined && data.visited
              }
              placeholder={data.placeHolder}
              pattern={data.regex}
              required={data.isRequired === TRUE}
              disabled={data.disabled}
              onBlur={() => {
                this.handleOnFocusOut(data);
              }}
            />
            <datalist id="data">
              {this.getOptionsList(data.options).map((item) => (
                <option
                  key={item.id}
                  value={item.text}
                  aria-label={item.text}
                />
              ))}
            </datalist>
          </>
        )}
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldCalender(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {data.displayName}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={data.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <DatePicker
          htmlFor={data.label}
          id={data.label}
          light={false}
          minDate={this.getMinDate()}
          onChange={(event) => this.onDateChange(data.label, event)}
          datePickerType="single"
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
          maxDate={data.maxDate}
        >
          <DatePickerInput
            labelText=""
            htmlFor={data.label}
            id={data.label}
            pattern="\\d{1,2}/\\d{1,2}/\\d{4}"
            placeholder={
              data.inputValue !== "" ? data.inputValue : data.placeHolder
            }
            disabled={false}
            invalid={
              this.state.errors[data.label] !== undefined && data.visited
            }
            invalidText=""
            iconDescription="Icon description"
            onChange={(event) => this.onDateInputChange(data.label, event)}
            onBlur={() => {
              this.handleOnFocusOut(data);
            }}
            defaultValue={data.inputValue}
          />
        </DatePicker>
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldToggle(data) {
    return (
      <div>
        <label htmlFor={data.label} className="cds--label">
          <span className="tooltip--text-alignement"> {data.displayName}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={data.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <br/>
        <Toggle
          size="sm"
          aria-label=""
          hideLabel={true}
          defaultToggled={data.inputValue === "true"}
          labelB=""
          labelA=""
          disabled={data.disabled}
          onChange={(event) => this.onToggleChange(event)}
          id={data.label}
          onToggle={(event) => {
            this.handleInputChange({target: {
              id: data.label,
              value: String(event)
            }});
          }}
          onBlur={() => {
            this.handleOnFocusOut(data);
          }}
        />
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldMultiSelect(data) {
    return (
      <div>
        {data.loading ? (
          <InlineLoading
            status={
              data.loadingStatus ? data.loadingStatus : LOADER_STATUS_INACTIVE
            }
            description={data.loadingText}
            type="left"
            className="zero-margin"
          />
        ) : (
          <>
            <label htmlFor={data.label} className="cds--label">
              <span className="tooltip--text-alignement">
                {" "}
                {data.displayName}
              </span>

              {data.helpMessage && (
                <span className="tooltip--info">
                  <Tooltip align="right" description={data.helpMessage}>
                    <button type="button" className="tooltip-button">
                      <Information size={12} />
                    </button>
                  </Tooltip>
                </span>
              )}
            </label>
            <MultiSelect.Filterable
              placeholder={data.placeHolder}
              id={data.label}
              type="default"
              direction={
                data.direction && data.direction === TOP ? TOP : BOTTOM
              }
              label={data.placeHolder}
              invalid={
                this.state.errors[data.label] !== undefined && data.visited
              }
              onChange={(event) =>
                this.handleInputChangeMultipleOptions(data.label, event)
              }
              items={this.getOptionsList(data.options)}
              itemToString={(item) => (item ? item.text : "")}
              translateWithId={(id) =>
                i18n.t("common.multiSelectTranctions", {
                  returnObjects: true,
                })[id]
              }
              selectionFeedback="top-after-reopen"
              onBlur={() => {
                this.handleOnFocusOut(data);
              }}
            />
          </>
        )}
        {/* error text */}
        {this.state.errors[data.label] !== undefined && data.visited ? (
          <p className="errmsg-form">{this.state.errors[data.label]}</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  getInputFieldTile(data) {
    return (
      <div>
        <div innerHTML={this.createMarkup(data.inputValue)} />
      </div>
    );
  }

  getInputFieldPanel(data) {
    return (
      <div>
        <label
          htmlFor={data.label}
          className={`cds--label ${data.disabled ? "input-disabled" : ""}`}
        >
          <span className="tooltip--text-alignement"> {data.displayName}</span>

          {data.helpMessage && (
            <span className="tooltip--info">
              <Tooltip align="right" description={data.helpMessage}>
                <button type="button" className="tooltip-button">
                  <Information size={12} />
                </button>
              </Tooltip>
            </span>
          )}
        </label>
        <p
          id={data.label}
        >
          {data.inputValue}
        </p>
      </div>
    );
  }

  handleInputChangeMultipleOptions(id, e) {
    let updatedFormData = [];

    const selectedItemsString = [];
    e.selectedItems.forEach((selectedItem) => {
      selectedItemsString.push(selectedItem.id);
    });

    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === id) {
        formFieldTemp.inputValue = selectedItemsString;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    if (this.props.getMultiselectChange) {
      this.props.getMultiselectChange(id, selectedItemsString);
    }
    this.isFormValid(id, selectedItemsString);
  }

  handleInputChange(event, chkIndex) {
    let updatedFormData = [...this.state.formData];
    updatedFormData = updatedFormData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === event.target.id) {
        formFieldTemp.inputValue = event.target.value;
      } else if (
        formFieldTemp.field !== undefined &&
        formFieldTemp.field.length > 0
      ) {
        const keyValue = formFieldTemp.field.map((field) => {
          const environ = field;
          if (environ.chkIndex !== undefined && environ.chkIndex === chkIndex) {
            environ.inputValue = event.target.value;
          }
          return environ;
        });
        formFieldTemp.field = keyValue;
      }
      return formFieldTemp;
    });

    this.setState({
      formData: updatedFormData,
    });
    if (typeof this.props.setFormData === "function") {
      this.props.setFormData(updatedFormData, event);
    }
    this.isFormValid(event.target.id, event.target.value);
  }

  doSomething(event) {
    event.preventDefault();
  }

  addKeyValue(data) {
    const fieldlen = data.field.length + 1;
    const unique = [];
    const envLength = this.state.environmentField.length;
    this.state.environmentField.forEach((x) =>
      unique.filter((a) => a.label === x.label).length > 0
        ? null
        : unique.push(x),
    );
    const duplicate = unique.map((copy, index) =>
      copy !== undefined
        ? {
            ...copy,
            inputValue: "",
            chkIndex: fieldlen + index,
            no: envLength / 2 + this.state.keyvaluelen,
          }
        : copy,
    );
    this.setState({ keyvaluelen: this.state.keyvaluelen + 1 }, () =>
      this.setkeyValue(duplicate),
    );
  }

  createMarkup(value) {
    return { __html: `${value}` };
  }

  handleValidation(formfield) {
    const valid = this.getValidation(formfield);
    if (valid) {
      delete this.state.errors[formfield.label];
    }
    return valid;
  }

  isFormValid(label, value) {
    let formIsValid = true;
    this.state.formData.forEach((formfield) => {
      if (formfield.inputType === INPUT_TYPE_PANEL) {
        formIsValid = false;
      } else if (
        formfield.inputType === INPUT_TYPE_TOGGLE ||
        formfield.inputType === INPUT_TYPE_TILE
      ) {
        // no need for toggles
      } else if (formfield.field !== undefined && formfield.field.length > 0) {
        formfield.field.forEach((field) => {
          const validation = this.handleValidation(field);
          formIsValid = formIsValid && validation;
        });
      } else {
        const validation = this.handleValidation(formfield);
        formIsValid = formIsValid && validation;
      }
    });
    if (this.props.getFormValidity) {
      this.props.getFormValidity(formIsValid);
    }
    if (this.props.getFormValidityIndex) {
      this.props.getFormValidityIndex(formIsValid, this.props.userIndex);
    }
    if (label) {
      if (this.props.handleFormChange) {
        this.props.handleFormChange(this.props.index, label, value);
      }
    }
  }

  handleOnFocusOut(formfieldData) {
    const formfieldDataTemp = formfieldData;
    formfieldDataTemp.visited = true;
    let formfieldIndex = -1;
    this.state.formData.forEach((formfield, index) => {
      if (formfield.label === "url") {
        formfieldIndex = index;
        const getLastChar = formfield.inputValue.slice(-1);
        if (getLastChar === "/") {
          formfield.regex = "/[^\\/]$/";
          formfield.regexMessage = "Please remove the / at the end of Url";
        } else {
          formfield.regex = "[^\\s]+";
          formfield.regexMessage = "Needs to be a valid URL";
        }
      }
      if (formfield.label === formfieldDataTemp.label) {
        formfieldIndex = index;
      } else if (
        formfield.field !== undefined &&
        formfield.field.length > 0 &&
        ["key", "value"].includes(formfieldDataTemp.label)
      ) {
        formfield.field.forEach((field, indx) => {
          if (
            field.label === formfieldDataTemp.label &&
            field.no === formfieldDataTemp.no
          ) {
            formfieldIndex = indx;
          }
        });
      }
    });
    if (formfieldIndex > -1) {
      const updatedFormData = this.state.formData;
      updatedFormData.forEach((formfield) => {
        const updatedForm = formfield;
        if (
          formfield.field !== undefined &&
          formfield.field.length > 0 &&
          ["key", "value"].includes(formfieldDataTemp.label)
        ) {
          return formfield.field.forEach((field) => {
            let fill = field;
            if (
              field.label === formfieldDataTemp.label &&
              field.no === formfieldDataTemp.no
            ) {
              fill = formfieldDataTemp;
            } else {
              fill.visited = false;
            }
            return fill;
          });
        }
        updatedForm[formfieldIndex] = formfieldDataTemp;
        return updatedForm;
      });
      this.setState({ formData: updatedFormData }, () => {
        this.isFormValid();
      });
      if (this.props.gethandleFormBlur) {
        this.props.gethandleFormBlur(this.state.formData[formfieldIndex]);
      }
    }
  }

  handleInputChangeSingleOptions(id, e) {
    let updatedFormData = [];
    const selectedValue = e.selectedItem !== null ? e.selectedItem.text : "";
    const selectedItem = e.selectedItem !== null ? e.selectedItem.id : "";

    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.label === id) {
        formFieldTemp.inputValue = selectedItem;
        formFieldTemp.selectedValue = selectedValue;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    if (this.props.getSingleselectChange) {
      if (this.props.getFormValidityIndex) {
        this.props.getSingleselectChange(this.props.index, id, selectedItem);
      } else {
        this.props.getSingleselectChange(id, selectedItem);
      }
    }
    this.isFormValid(id, selectedItem);
  }

  render() {
    const formFields =
      this.state.formData &&
      this.state.formData.length &&
      this.state.formData.map((data) => (
        <div key={`${data.label}`} hidden={data.inputType === "hidden"}>
          {/* TEXT */}
          {data.inputType === INPUT_TYPE_TEXT && data.mask === NO
            ? this.getInputFieldTextNoMask(data)
            : " "}
          {data.inputType === INPUT_TYPE_TEXT && data.mask === YES
            ? this.getInputFieldTextYesMask(data)
            : " "}
          {/* URL  */}
          {data.inputType === INPUT_TYPE_URL && data.mask === NO
            ? this.getInputFieldURLNoMask(data)
            : " "}
          {data.inputType === INPUT_TYPE_URL && data.mask === YES
            ? this.getInputFieldURLYesMask(data)
            : " "}
          {/* TEXT AREA */}
          {data.inputType === INPUT_TYPE_TEXTAREA && data.mask === NO
            ? this.getInputFieldTextArea(data)
            : " "}
          {/* DROP DOWN */}
          {data.inputType === INPUT_TYPE_DROPDOWN &&
          data.mask === NO &&
          this.state.byot === false
            ? this.getInputFieldDropdown(data)
            : " "}

          {/* DROP DOWN - Carbon */}
          {data.inputType === INPUT_TYPE_DROPDOWN_CARBON &&
          data.mask === NO &&
          this.state.byot === false
            ? this.getInputFieldDropdownCarbon(data)
            : " "}

          {/* Data List */}
          {data.inputType === INPUT_TYPE_DATALIST &&
          data.mask === NO &&
          this.state.byot === false
            ? this.getInputFieldDatalist(data)
            : " "}
          {/* calendar */}
          {data.inputType === INPUT_TYPE_DATE && data.mask === NO
            ? this.getInputFieldCalender(data)
            : " "}
          {/* toggle */}
          {data.inputType === INPUT_TYPE_TOGGLE && data.mask === NO
            ? this.getInputFieldToggle(data)
            : " "}
          {/* multiselect */}
          {data.inputType === INPUT_TYPE_MULTI_SELECT && data.mask === NO
            ? this.getInputFieldMultiSelect(data)
            : " "}

          {/* LABEL */}
          {data.inputType === INPUT_TYPE_LABEL &&
          data.mask === NO &&
          this.state.byot === false
            ? this.getInputFieldLabel(data)
            : " "}

          {/* PANEL for error display */}
          {data.inputType === INPUT_TYPE_PANEL &&
          data.mask === NO &&
          this.state.byot === false
            ? this.getInputFieldPanel(data)
            : " "}

          {/* tile */}
          {data.inputType === INPUT_TYPE_TILE && data.mask === NO
            ? this.getInputFieldTile(data)
            : " "}
          {data.inputType === INPUT_TYPE_KEYVALUE && data.mask === NO
            ? this.getInputFieldKeyValue(data)
            : " "}
        </div>
      ));
    return (
      <Form
        onSubmit={(event) => this.doSomething(event)}
        className={
          this.props.type === TYPE_ADD_TENANT_USER
            ? "form-field-max-width add-user-form"
            : "form-field-max-width"
        }
      >
        <Stack gap={5}>
          <>{formFields}</>
        </Stack>
      </Form>
    );
  }
}

export default withTranslation()(DynamicFormClass);
