import {
  ClickableTile,
  Column,
  Row,
  SkeletonText,
  Tile
} from "@carbon/react";
import { CheckmarkFilled, Renew } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import ErrorNotify from "Carbon-Components/ErrorNotify";
import ErrorFallback from "Common-Modules/ErrorFallback";
import React, { useEffect, useState } from "react";
import useData from "./Hooks/useData";
import BlockedHosts from "./Tables/BlockedHosts";
import OOMS from "./Tables/OOMS";
import OpenIssues from "./Tables/OpenIssues";
import Sessions from "./Tables/Sessions";
import Tasks from "./Tables/Tasks";
import TilesLoader from "./TilesLoader";
import "./citi.scss";
import EpochTime from "./epochTime";
import { getCluserTilesData } from "./utils";

const ClusterWiseDetails = () => {
  const { keycloak } = useKeycloak();
  const asset = JSON.parse(sessionStorage.getItem("asset_class"));
  const cluster = JSON.parse(sessionStorage.getItem("cluster_name"));
  // const clusterData = JSON.parse(sessionStorage.getItem("cluster_data"));
  const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
  const [dateChange, onDateChange] = useState(() => selecteDates ? selecteDates.time : {
    currentTime: new Date(new Date().getTime() - 15 * 60 * 1000).getTime(),
    prevTime: new Date().getTime(),
  });
  const [refresh, onRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isCalculating, setIsCalculating] = useState(true);
  const [onLoading, setonLoading] = useState(0);

  const { isLoading, isErrorDate, assets, sessions, tasks, ooms, incidents, apiRefresh, sessionCount, taskCount } = useData(
    keycloak,
    dateChange,
    refresh
  );

  useEffect(() => {
    if (!apiRefresh) {
      setonLoading(loading => loading + 1)
    }
  }, [apiRefresh])

  useEffect(() => {
    if (onLoading > 1) {
      onRefresh(false);
    }
  }, [onLoading])

  useEffect(() => {
    const sess = sessions;
    const oom = ooms;
    const task = tasks;
    const incident = incidents;
    try {
      const calculatedData = getCluserTilesData(
        asset,
        sess,
        oom,
        task,
        incident,
        sessionCount,
        taskCount
      );
      setData(calculatedData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, [assets, sessions, tasks, ooms]);

  const dateOnChange = (e) => {
    onDateChange(e);
    setonLoading(1)
  };

  const onClickRefresh = () => {
    onRefresh(true)
    setonLoading(1)
  }

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    // <FlexGrid className="att-dash circle-view">
    <>
      <Row className="citi--specific--styles">
        <Column lg={16} md={8} sm={4}>
          <div className="flex-1 flex-space-between flex-wrap">
            <div></div>
            <div className="flex-space-between flex-wrap" style={{gap:"0.5rem"}}>
              <div className="refresh-icon">
                <Renew
                  aria-label="refresh"
                  className={onLoading <= 1 ? "rotateIcons" : ""}
                  onClick={() => onClickRefresh()}
                />
                Refresh
              </div>
              <EpochTime
                dateOnChange={dateOnChange}
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row className="mt-1">
        <Column lg={3} md={4} sm={4}>
          <Tile className="headings--tile" style={{ height: "94%" }}>
            {/* <h5>
              Asset Class:{" "}
              <span style={{ color: "#0062ff" }}>{asset.displayName}</span>
            </h5> */}
            <h5>
              Cluster: <span style={{ color: "#0062ff" }}>{cluster}</span>
            </h5>
          </Tile>
        </Column>
        <Column lg={13} md={4} sm={4}>
          <Row className="tiles--wrapper">
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className={`mock--radio ${data.sessionsCount[cluster] > 0 ? "red" : null}`}
                  onClick={() => setSelected("sessions")}
                >
                  <h3>{data.sessionsCount[cluster] || 0}</h3>
                  <h5>Failed Sessions</h5>
                  {selected === "sessions" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className={`mock--radio ${data.oomCount[cluster] > 0 ? "red" : null}`}
                  onClick={() => setSelected("ooms")}
                >
                  <h3>{data.oomCount[cluster] || 0}</h3>
                  <h5>OOM Errors</h5>
                  {selected === "ooms" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className={`mock--radio ${data.taskCounts[cluster] > 0 ? "red" : null}`}
                  onClick={() => setSelected("tasks")}
                >
                  <h3>{data.taskCounts[cluster] || 0}</h3>
                  <h5>Failed Tasks</h5>
                  {selected === "tasks" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className={`mock--radio ${data.blockedHostsCount[cluster] > 0 ? "red" : null}`}
                  onClick={() => setSelected("blockedHosts")}
                >
                  <h3>{data.blockedHostsCount[cluster] || 0}</h3>
                  <h5>Blocked Host</h5>
                  {selected === "blockedHosts" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className="mock--radio"
                  onClick={() => setSelected("nodeIssue")}
                >
                  <h3>{data.nodeIssuesCount[cluster] || 0}</h3>
                  <h5>Node Issues</h5>
                  {selected === "nodeIssue" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
            <div>
              {onLoading > 1 ?
                <ClickableTile
                  id="sessions"
                  className="mock--radio"
                  onClick={() => setSelected("longRunning")}
                >
                  <h3>{0}</h3>
                  <h5>Long Running</h5>
                  {selected === "longRunning" ? (
                    <CheckmarkFilled fill="#0062ff" />
                  ) : null}
                </ClickableTile> : <ClickableTile> <SkeletonText /> </ClickableTile>}
            </div>
          </Row>
        </Column>
      </Row>
      {selected === "sessions" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.sessionsCount[cluster] ? <Sessions asset={asset.toolchainName} cluster={cluster} title="Failed Sessions" /> : <ErrorNotify title="No Failed Sessions" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "ooms" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.oomCount[cluster] ? <OOMS asset={asset.toolchainName} cluster={cluster} title="OOM Errors" /> : <ErrorNotify title="No OOM Errors" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "tasks" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.taskCounts[cluster] ? <Tasks asset={asset.toolchainName} cluster={cluster} title="Failed Tasks" /> : <ErrorNotify title="No Failed Tasks" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "blockedHosts" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.blockedHostsCount[cluster] ? <BlockedHosts asset={asset.displayName} cluster={cluster} title="Blocked Host" /> : <ErrorNotify title="No Blocked Host" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "nodeIssue" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.nodeIssuesCount[cluster] ? <OpenIssues asset={asset.displayName} cluster={cluster} /> : <ErrorNotify title="No Node Issues" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "longRunning" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.longRunning[cluster] ? <OpenIssues asset={asset.displayName} cluster={cluster} /> : <ErrorNotify title="No Long Running Issues" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
      {selected === "aborted" && onLoading > 1 ? (
        <Row className="mt-1">
          <Column lg={16} md={8} sm={4}>
            {Object.entries(data).length > 0 && data.aborted[cluster] ? <OpenIssues asset={asset.displayName} cluster={cluster} /> : <ErrorNotify title="No Aborted Issues" subTitle="" kind="info-square" hideCloseButton={true} />}
          </Column>
        </Row>
      ) : null}
    </>
  );
};

export default ClusterWiseDetails;
