import { LineChart } from "@carbon/charts-react";
import { Dropdown } from "@carbon/react";
import React, { useState } from "react";

const LineChartWithDropdowns = ({ data, options }) => {
  const yearArray = Array.from(new Set(data.map((ticket) => ticket.yearKey)));
  const years = yearArray.sort((a, b) => Number(b) - Number(a));
  const [year, setYear] = useState(years[0]);
  const data1 = data.filter((a) => a.yearKey === year);

  return (
    <div className="chart-dropdown-wrapper">
      {years.length > 1 ? (
        <div className="dropdown-wrapper">
          <Dropdown
            id="select-target-year"
            label="Dropdown menu options"
            onChange={(e) => setYear(e.selectedItem)}
            selectedItem={year}
            size="sm"
            items={years}
            itemToString={(item) => (item ? item : "")}
          />
        </div>
      ) : (
        <p>year: {years[0]}</p>
      )}
      <LineChart data={data1} options={options}></LineChart>
    </div>
  );
};

export default LineChartWithDropdowns;
