/** @format */

import {
  Accordion,
  AccordionItem,
  Button,
  CodeSnippet,
  Column,
  Grid,
  InlineNotification,
  Row,
  TextArea,
  Tile
} from "@carbon/react";
import { ThumbsDown, ThumbsUp } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { postPlatformAlertsFeedback } from "./../Services/ServerApi";
//   import { updateErrorMessage } from "../Reports/CommonReportUtility";
import "./../../../ICPSAcss/_common.scss";

const GenAISummary = (props) => {
  const { keycloak } = useKeycloak();
  //const context = useContext(Context);

  const [feedbackPop, showFeedBackPop] = useState("");
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [errMessageFeedback, setErrorMessageFeedback] = useState("");
  const [score, setScore] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [showThanks, setShowThanks] = useState(false)

  // useEffect(() => {
  //   if (props.type !== "apa") {
  //     getFeedbackAPI(props.platformAlert)
  //   }
  // }, []);

  useEffect(() => {
    if (showThanks) {
      setTimeout(() => {
        setShowThanks(false)
      }, 4000)
    }
  }, [showThanks])

  // const getFeedbackAPI = async () => {
  //   const response = await getPlatformAlertsFeedback(
  //     keycloak.token,
  //     props.platformAlert.id
  //   );

  //   AddUserActivity(keycloak, context, "FEATURE", "Platform Alerts Feedback", response);

  //   setIsFeedbackLoading(false);
  //   setErrorMessageFeedback(updateErrorMessage(response));

  //   if (response.data.thumbs_up_count > 0) {
  //     let score = (response.data.thumbs_up_count / (response.data.thumbs_up_count + response.data.thumbs_down_count)) * 100;
  //     setUserCount(response.data.thumbs_up_count + response.data.thumbs_down_count);
  //     setScore(score.toFixed(2));
  //   }

  //   return response.data;
  // };

  const getGenAISummaryText = () => {
    return props.data;
  }

  const removeUnwantedFields = (feedBackObj) => {
    delete feedBackObj.hostId;
    delete feedBackObj.timeline;
    delete feedBackObj.severity;
    delete feedBackObj.type;
    delete feedBackObj.event_id;
    delete feedBackObj.host_id;
    delete feedBackObj.description;
    delete feedBackObj.overall_state_modified;
    delete feedBackObj.overall_state;
    delete feedBackObj.alert_trigger_point;
    delete feedBackObj.inserted_on;
    delete feedBackObj.inserted_by;

    return feedBackObj;
  }

  const feedbackBtn = (title) => {
    setShowThanks(true)
    showFeedBackPop(title);

    let feedBackObj = { ...props.data, ...props.platformAlert, ...props.genAIInput };
    feedBackObj.thumbsUp = title === "Accept";

    feedBackObj = removeUnwantedFields(feedBackObj);
    postPlatformAlertsFeedback(keycloak.token, props.platformAlert.id, feedBackObj)
  }

  return (
    props.flow === "DO" ? <>
    <h5 style={{ marginTop: "10px"}} className="text-tile">Augmented Assistance</h5>
    <Grid className="rca-summary">
        <Column className="rca-summary" lg={8}>
                <h6>RCA Summarization</h6>
                <p>
                  <TextArea
                    cols={150}
                    rows={20}
                    id="text-area-1"
                    defaultValue={props.data ? getGenAISummaryText().reason : ""}
                    readOnly={true}
                  />
                </p>
              </Column>
      <Column className="rca-summary" lg={8}>
        <h6>Remediation recommendation</h6>
        <p>
          <TextArea
            cols={150}
            rows={20}
            id="text-area-1"
            defaultValue={props.data ? getGenAISummaryText().remediation : ""}
            readOnly={true}
          />
        </p>
      </Column>
    </Grid> </> : 
    <Accordion className="genai--accordian">
      <AccordionItem title={<h5 className="text-tile">Augmented Assistance</h5>}>
        <Grid>
          {props.type === "apa" ?
            <>
              <Column lg={props.flow !== "DO" ? 6 : 8}>
                <h6>RCA Summarization</h6>
                <p>
                  <TextArea
                    cols={150}
                    rows={12}
                    id="text-area-1"
                    defaultValue={props.data ? getGenAISummaryText().reason : ""}
                    readOnly={true}
                  />
                </p>
              </Column>
              <Column lg={props.flow !== "DO" ? 6 : 8}>
                <h6>Remediation recommendation</h6>
                <p>
                  <TextArea
                    cols={150}
                    rows={12}
                    id="text-area-1"
                    defaultValue={props.data ? getGenAISummaryText().remediation : ""}
                    readOnly={true}
                  />
                </p>
              </Column>
              {props.flow !== "DO" && <Column lg={4}>
                <h6>Code Template</h6>
                <p>
                  <CodeSnippet type="multi" feedback="Copied to clipboard">
                    {props.data ? getGenAISummaryText().code.results[0].outputText : ""}
                  </CodeSnippet>
                </p>
              </Column> }
            </> :
            <>
              <Column lg={8}>
                <h6>RCA Summarization</h6>
                <p>
                  <TextArea
                    cols={150}
                    rows={12}
                    id="text-area-1"
                    defaultValue={props.data ? getGenAISummaryText().reason : ""}
                    readOnly={true}
                  />
                </p>
              </Column>
              <Column lg={8}>
                <h6>Remediation recommendation</h6>
                <p>
                  <TextArea
                    cols={150}
                    rows={12}
                    id="text-area-1"
                    defaultValue={props.data ? getGenAISummaryText().remediation : ""}
                    readOnly={true}
                  />
                </p>
              </Column>
              <Column lg={16}>
                <Tile className="chart-container">
                  <div className="flex-space-between">
                    <div>
                      <h5 className="text-tile">User Feedback</h5>
                      <strong>Score: {score}</strong>
                      <p>No. of feedbacks: {userCount}</p>
                    </div>
                    <div>
                      {/* // feedbackPop === ""  */}
                      <Button kind="tertiary" className="create--button" onClick={() => feedbackBtn("Accept")} disabled={Boolean(feedbackPop)} style={{ marginRight: "1rem" }}>
                        Accept Suggestion <ThumbsUp />
                      </Button>
                      <Button kind="secondary" className="create--button" onClick={() => feedbackBtn("Reject")} disabled={Boolean(feedbackPop)}>
                        Reject Suggestion <ThumbsDown />
                      </Button>
                    </div>
                  </div>
                </Tile>
              </Column>
              {
                showThanks
                  ? <div className="notification">
                    <InlineNotification
                      title="Thank You for the Feedback."
                      statusIconDescription="Thank You"
                      onClose={() => setShowThanks(false)}
                      kind="success"
                    // hideCloseButton={false}
                    />
                  </div>
                  : null
              }
            </>
          }
        </Grid>
      </AccordionItem>
    </Accordion >
  );
};

export default GenAISummary;
