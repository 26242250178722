import { ClickableTile, Column, Row } from "@carbon/react";
import { ArrowRight } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ato.scss";

const ATOIndex = () => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [atoFetchData, setAtoData] = useState({});

  const { data: AtoFetchData, refetch: atoRefetch } = useQuery(['ato_data'], async () => await getAnyCollection(keycloak, "ato"), { retry: 1 });

  useEffect(() => {
    if(AtoFetchData !== undefined) {
        setAtoData(AtoFetchData.data.ato)
    }
    }, [AtoFetchData])

    const handleOnClick = (name) => {
        history.push(`/atoDashboard/${name}`)
    }

  return (
    <>
         <Row className="ato-row"> 
            {Object.entries(atoFetchData).length > 0 && atoFetchData.map((ato, index) => (
                <Column
                    lg={4}
                    md={4}
                    sm={4}
                    className="clickable--tile insights-hb"
                    key={index}
                >
                    <ClickableTile
                        id={index}
                        onClick={() => handleOnClick(ato.data.Name)
                        }
                        className={!ato.disabled ? "" : "disable-tile"}
                        aria-label={`clickableTile-${index}`}
                    >
                        <h5 className="report-title">
                            {ato.data.Name}
                        </h5>
                        <ul className="list-ul">
                            <li>Applications: <span>{ato.data.Applications}</span></li>
                            <li>Anomaly: <span className={ato.data.Anomaly > 0 ? "red" : ""}>{ato.data.Anomaly}</span></li>
                            <li>Open Incidents: <span>{ato.data["Open Incidents"]}</span></li>
                            
                            
                            <li>Components: <span>{ato.data.Components}</span></li>
                            <li>Business Process: <span>{ato.data["Business Process"]}</span></li>
                            <li>Open Alerts: <span>{ato.data["Open Alerts"]}</span></li>

                        </ul>
                            <div className="rgt-aw">
                                <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                            </div>
                    </ClickableTile>
                </Column>
            ))}
        </Row>
    </>
  );
};

export default ATOIndex;
