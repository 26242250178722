
  import { buildElbowPathString } from "@carbon/charts";
  import { CardNodeTitle, CardNodeColumn } from '@carbon/charts-react';
  import { ShapeNode, CardNode, Edge, TeeMarker, ArrowRightMarker } from "@carbon/charts-react";
  import React from "react";
  import { v4 as uuidv4 } from "uuid";
  
  const TopologyGraph = (props) => {
    const nodeHeight = 64;
    const nodeWidth = 200;
    //const ShapeNodeSize = 64;
  
    const nodeData = [
      {
        id: "a",
        x: -50,
        y: 16,
        icon: null,
        nodeWidth,
        nodeHeight,
        color: "#24a148",
        title: "RB2B",
      },
      {
        id: "b",
        x: -150,
        y: 200,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        vertical: true,
        color: "#24a148",
        title: "ZBAPI SALESORDER CREATEB2B",
      },
      {
        id: "c",
        x: -50,
        y: 450,
        icon: null,
        nodeWidth,
        nodeHeight,
        color: "#24a148",
        title: "SAP Order Creation",
      },
      {
        id: "d",
        x: 80,
        y: 200,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        vertical: true,
        color: "#24a148",
        title: "Idoc ORDRSP Webmethods",
      },
      {
        id: "e",
        x: 250,
        y: 450,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        color:
          sessionStorage.getItem("CusJouStep") === "SAP Invoice creation"
            ? "#fa4d56"
            : "#24a148",
        title: "DHL_AU_ORD_TO_DLV1-3",
      },
      {
        id: "f",
        x: 550,
        y: 450,
        icon: null,
        nodeWidth,
        nodeHeight,
        color:
          sessionStorage.getItem("CusJouStep") === "SAP Invoice creation"
            ? "#24a148"
            : "#fa4d56",
        title: "SAP Delivery Creation",
      },
      {
        id: "g",
        x: 850,
        y: 400,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        color: "#24a148",
        title: "Idoc DESADV Via Webmethods",
      },
      {
        id: "h",
        x: 850,
        y: 500,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        color: "#24a148",
        title: "Idoc GICON Via Webmethods",
      },
      {
        id: "i",
        x: 1200,
        y: 450,
        icon: null,
        nodeWidth,
        nodeHeight,
        color: "#24a148",
        title: "Manhattan/DHL",
      },
      {
        id: "j",
        x: 550,
        y: 200,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        vertical: true,
        color:
          sessionStorage.getItem("CusJouStep") === "SAP Invoice creation"
            ? "#fa4d56"
            : "#24a148",
        title: "DHL_AU_DLV_TO_BIL1-6",
      },
      {
        id: "k",
        x: 550,
        y: 16,
        icon: null,
        nodeWidth,
        nodeHeight,
        color: "#24a148",
        title: "SAP Invoice Creation",
      },
      {
        id: "l",
        x: 250,
        y: 16,
        icon: null,
        nodeWidth,
        nodeHeight,
        type: "arrow",
        color: "#24a148",
        title: "Idoc Invoice Via Webmethods",
      },
    ];
  
    const ProgrammaticStaticNode = () => {
      const nodes = nodeData.map((node) => (
        <foreignObject
          style={{ overflow: "visible", position: "relative" }}
          key={`node_${uuidv4()}`}
          transform={`translate(${node.x}, ${node.y})`}
          height={node.nodeHeight}
          width={node.nodeWidth}
          className="amexBox"
        >
          {node.type === "arrow" ? (
            <div className={node.vertical === true ? "vertical_title" : ""}>
              <CardNode onClick={(e) => redirectTo(node)} color={node.color}>
                <CardNodeColumn>
                  <CardNodeTitle>{node.title}</CardNodeTitle>
                </CardNodeColumn>
              </CardNode>
            </div>
          ) : (
            <CardNode onClick={(e) => redirectTo(node)} color={node.color}>
              <CardNodeColumn>
                <CardNodeTitle>{node.title}</CardNodeTitle>
              </CardNodeColumn>
            </CardNode>
          )}
        </foreignObject>
      ));
  
      return nodes;
    };
  
    const ProgrammaticStaticEdge = () => {
      const edgeData = [
        {
          source: "a",
          target: "b",
          path: (source, target) => buildElbowPathString(source, target),
          variant: "arrow",
        },
        {
          source: "b",
          target: "c",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "c",
          target: "d",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "d",
          target: "a",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "c",
          target: "e",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "e",
          target: "f",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "f",
          target: "g",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "g",
          target: "i",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "i",
          target: "h",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "h",
          target: "f",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "f",
          target: "j",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "j",
          target: "k",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "k",
          target: "l",
          path: (source, target) => buildElbowPathString(source, target),
        },
        {
          source: "l",
          target: "a",
          path: (source, target) => buildElbowPathString(source, target),
        },
      ];
  
      const edgeMapped = edgeData.map((link) => {
        const sourceNode = nodeData.find((node) => node.id === link.source);
        const targetNode = nodeData.find((node) => node.id === link.target);
        return {
          ...link,
          source: {
            x: sourceNode.x + sourceNode.nodeWidth / 2,
            y: sourceNode.y + sourceNode.nodeHeight / 2,
          },
          target: {
            x: targetNode.x + targetNode.nodeWidth - 181,
            y: targetNode.y + targetNode.nodeHeight / 2,
          },
        };
      });
  
      const edges = edgeMapped.map((edge) => (
        <Edge
          key={`link_${uuidv4()}`}
          source={edge.source}
          target={edge.target}
          path={edge.path && edge.path(edge.source, edge.target)}
          variant={edge.variant}
          markerEnd={"marker"}
        />
      ));
      return edges;
    };
  
    const redirectTo = (node) => {
      props.cardClick(true, node);
    };
  
    return (
      <>
        <div>
          <svg
            height="450"
            width="1300"
            style={{ margin: "1em" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1150 600"
          >
            <defs>
              <ArrowRightMarker id={"marker"} />
            </defs>
            {ProgrammaticStaticEdge()}
            {ProgrammaticStaticNode()}
          </svg>
        </div>
      </>
    );
  };
  
  export default TopologyGraph;
  