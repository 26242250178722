import React, { useEffect, useContext } from "react";
import Context from "Context/Context";
import { useHistory } from "react-router-dom";
import ValueStreamWorkflowView from "../ValueStream/valueStreamWorkflowView";

const AppSecurity = () => {
    const context = useContext(Context);
    const history = useHistory();

    useEffect(() => {
        context.valueStreamFlow = "ViSecurity";
    }, [context])

    useEffect(() => {
        sessionStorage.setItem("portfolioTitle", "Number Activation");
        sessionStorage.setItem("portfolioDescription", "");
        history.push({ pathname: `VsworkflowView/Number Activation`, state : {flowName : "ViSecurity"} });
    }, [])

    return (
        <>
            {context.valueStreamFlow === "ViSecurity" ? <ValueStreamWorkflowView /> : null}
        </>
    )
}

export default AppSecurity;