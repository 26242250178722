import {
  Column,
  Row,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectRow,
  TabsSkeleton,
  ClickableTile,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Tag,
  FlexGrid,
  SkeletonPlaceholder,
  ComboBox,
} from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import {
  InProgress,
  ErrorFilled,
  CheckmarkFilled,
  UndefinedFilled,
  RadioButtonChecked,
} from "@carbon/react/icons/index";
import React, { useState, useContext, useEffect } from "react";
import Context from "Context/Context";
import { useHistory } from "react-router-dom";
import TopologyGraph from "../../AdaniNerve/TopologyGraph";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getAnyCollection } from "Services/ServerApi";
import "../../AdaniNerve/nerve.scss";

const NerveDashboard = (props) => {
  const context = useContext(Context);
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [showApps, setShowApps] = useState({});
  const [appDetails, setAppDetails] = useState([]);
  const [showTopology, setShowTopology] = useState(false);
  const [nerveCenterData, setNerveCenter] = useState([]);
  const [nerveCenterDuplicateData, setDuplicateNerveCenter] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [serviceData, setServiceData] = useState([]);

  const { data: nerveCenter, refetch: Refetch } = useQuery(
    ["cpg"],
    async () => await getAnyCollection(keycloak, "cpg"),
    { retry: 1 }
  );

  useEffect(() => {
    if (nerveCenterData.length > 0) {
      onClickApp(selectedApp);
    }
  }, [nerveCenterData]);

  useEffect(() => {
    if (
      props.tileData !== undefined &&
      nerveCenter !== undefined &&
      nerveCenter.data !== undefined
    ) {
      let filterKpi = [],
        serviceArr = [];
      nerveCenter.data.cpg.map((nerve) => {
        if (
          nerve.kpi.includes(props.tileData.kpi) ||
          nerve.services.includes(props.tileData.kpi)
        ) {
          filterKpi.push(nerve);
        }
      });
      if (filterKpi.length > 0) {
        filterKpi !== undefined &&
          filterKpi.forEach((serv) => {
            serv.services.forEach((sev) => {
              serviceArr.push(sev);
            });
          });

        setNerveCenter(filterKpi);
        setDuplicateNerveCenter(filterKpi);
        if (selectedApp !== "") {
          setSelectedApp(selectedApp);
        } else {
          setSelectedApp(filterKpi[0]["Display Name"]);
        }
      }

      const servDuplicate = [...new Set(serviceArr)];
      setServiceData(servDuplicate);
    }
  }, [props, nerveCenter]);

  useEffect(() => {
    if (nerveCenterDuplicateData.length > 0) {
      if (props.businessProcess !== undefined) {
        getBusinessProcessDetails(props.businessProcess);
      } else {
        setNerveCenter(nerveCenterDuplicateData);
      }
    }
  }, [props.businessProcess]);

  const getBusinessProcessDetails = (businessProcess) => {
    const comb = [
      { name: "Manage inbound product flow", sub: "DNS" },
      { name: "Monitor inbound delivery performance", sub: "SAP" },
      { name: "Manage flow of returned products", sub: "VM" },
    ];

    const filterData = nerveCenterDuplicateData.filter((fil) =>
      comb.some(
        (obj) => obj.name === businessProcess && fil.services.includes(obj.sub)
      )
    );
    setNerveCenter(filterData);
    setSelectedApp(filterData[0]["Display Name"]);
  };

  const getInference = () => {
    return (
      <ul>
        {appDetails.length > 0 &&
          appDetails[0].Inference !== undefined &&
          appDetails[0].Inference.map((infer) => {
            return <li>{infer}</li>;
          })}
      </ul>
    );
  };

  const getRowData = (cell, row) => {
    if (cell.id.includes("Platform")) {
      return (
        <div onClick={() => onClickStatus(cell)}>
          {getStatus(cell.value.Error)}
        </div>
      );
    }

    if (cell.id.includes("Network")) {
      return (
        <div onClick={() => onClickStatus(cell)}>
          {getStatus(cell.value.Error)}
        </div>
      );
    }

    if (cell.id.includes("Security")) {
      return (
        <div onClick={() => onClickStatus(cell)}>
          {getStatus(cell.value.Error)}
        </div>
      );
    }

    if (cell.id.includes("Alert")) {
      return (
        <div onClick={() => onClickStatus(cell)}>
          {getStatus(cell.value.Error)}
        </div>
      );
    }

    if (cell.id.includes("Display Name")) {
      return cell.value === selectedApp ? (
        <div className="selectedAp">
          <RadioButtonChecked /> {cell.value}
        </div>
      ) : (
        <div className="table-app" onClick={() => onClickApp(cell.value, row)}>
          {cell.value}
        </div>
      );
    }

    return cell.value;
  };

  const onClickApp = (e) => {
    let data = [];
    nerveCenterData.length > 0 &&
      nerveCenterData.forEach((app) => {
        if (app["Display Name"] === e) {
          data.push(app);
        }
      });
    if (data.length > 0) {
      setAppDetails(data);
      setSelectedApp(e);
    }
  };

  const onClickStatus = (e) => {
    let data = [];
    nerveCenterData.forEach((app) => {
      // console.log("Object.keys(app),")
      if (e.id.includes(app.id)) {
        data.push(app);
      }
    });
    context.nerveCenter = data;
    setAppDetails(data);
    setShowTopology(true);
  };

  const getStatus = (stat) => {
    switch (stat) {
      case 0:
        return <CheckmarkFilled fill="#198038" />;
      case 1:
        return <ErrorFilled fill="#da1e28" />;
      case "inProgress":
        return <InProgress fill="#d2a106" />;
      default:
        return <UndefinedFilled />;
    }
  };

  const getFirstRow = () => {
    return (
      appDetails.length > 0 &&
      appDetails[0]["Header Tiles"].map((tile) => {
        return (
          <ClickableTile
            disabled={tile.name !== "Alerts"}
            onClick={() => showTileDetails(tile)}
            className="tile-row"
          >
            <h5>{tile.name}</h5>
            <span>{tile.value}</span>
          </ClickableTile>
        );
      })
    );
  };

  const showTileDetails = (tile) => {
    if (tile.data !== undefined) {
      setShowApps(tile);
    }
  };

  const getTilesDetails = () => {
    return showApps.data.map((data) => {
      return <li>{data.name}</li>;
    });
  };

  const getCharts = () => {
    return (
      appDetails.length > 0 &&
      appDetails[0].Charts.map((chart) => (
        <Column lg={8} md={4} sm={4}>
          <ChartContainer data={chart} />
        </Column>
      ))
    );
  };

  // const getComboBox = () => {
  //   const comb = [
  //     { name: "Manage inbound product flow", sub: "DNS" },
  //     { name: "Monitor inbound delivery performance", sub: "SAP" },
  //     { name: "Manage flow of returned products", sub: "VM" },
  //   ];
  //   return (
  //     <ComboBox
  //       id="ComboBox1"
  //       items={comb.sort((a, b) => {
  //         let fa = a.name.toLowerCase(),
  //           fb = b.name.toLowerCase();
  //         if (fa < fb) {
  //           return -1;
  //         }
  //         if (fa > fb) {
  //           return 1;
  //         }
  //         return 0;
  //       })}
  //       itemToString={(item) => (item ? item.name : "")}
  //       placeholder="Select Service"
  //       titleText=""
  //       size="sm"
  //       onChange={(e) => getSelectedFlow(e)}
  //     />
  //   );
  // };

  // const getSelectedFlow = (e) => {
  //   if (e.selectedItem !== null) {
  //     const filterData = nerveCenterDuplicateData.filter((fil) =>
  //       fil.services.includes(e.selectedItem.sub)
  //     );
  //     setNerveCenter(filterData);
  //   } else {
  //     setNerveCenter(nerveCenterDuplicateData);
  //   }
  // };

  const redirectToIne = (page, node) => {
    history.push({
      pathname: "/nerveDetails",
      state: {
        data: node,
        tileData: props.tileData,
        flow: "valueStream",
        workflow: props.workflow,
      },
    });
  };

  // const breadcurmRedirect = () => {
  //   const name = sessionStorage.getItem("portfolioTitle");
  //   history.push({
  //     pathname: `/workflowViewCpg/${name}`,
  //     state: { tileData: props.tileData, workflow: props.workflow },
  //   });
  // };

  const headers = [
    { key: "Display Name", header: "Application" },
    { key: "Platform", header: "Platform" },
    { key: "Network", header: "Middleware" },
    { key: "Security", header: "Security" },
  ];

  return (
    <>
      {nerveCenterData.length > 0 && appDetails.length > 0 ? (
        <Row className="adani-nerve">
          <Column lg={6} md={4} sm={2} className="pd-l pd-r">
            <div className="inference">
              <h5>Inference</h5>
              {getInference()}
            </div>
            <div className="col-table">
              {/* {getComboBox()} */}
              {nerveCenterData.length > 0 ? (
                <DataTable rows={nerveCenterData} headers={headers}>
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getTableProps,
                    getTableContainerProps,
                  }) => (
                    <TableContainer
                      className="details"
                      {...getTableContainerProps()}
                    >
                      <Table {...getTableProps()} aria-label="sample table">
                        <TableHead>
                          <TableRow>
                            {headers.map((header, i) => (
                              <TableHeader
                                key={i}
                                {...getHeaderProps({
                                  header,
                                })}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow
                              key={i}
                              {...getRowProps({
                                row,
                              })}
                            >
                              {row.cells.map((cell) => (
                                <TableCell key={cell.id}>
                                  {getRowData(cell, row)}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DataTable>
              ) : (
                <TabsSkeleton rowCount={5} columnCount={2} />
              )}
            </div>
          </Column>
          <Column lg={10} md={8} sm={4} className="rgt-col">
            <Row className="head-sec">
              <Column lg={6} md={4} sm={2}>
                <Tag className="lft-tag" type="purple">
                  Application: {selectedApp}
                </Tag>
              </Column>
              <Column className="date-sec" lg={10} md={6} sm={4}>
                <Tag className="some-class" type="high-contrast">
                  {"1H"}
                </Tag>

                <Tag className="some-class" type="outline">
                  {"8H"}
                </Tag>
                <Tag className="some-class" type="outline">
                  {"16H"}
                </Tag>
                <Tag className="some-class" type="outline">
                  {"24H"}
                </Tag>
              </Column>
            </Row>
            <Row>
              <Column className="first-row" lg={16} md={8} sm={4}>
                {getFirstRow()}
              </Column>
            </Row>
            <Row>{getCharts()}</Row>
          </Column>

          {Object.entries(showApps).length > 0 ? (
            <Modal
              open
              size="md"
              passiveModal
              isFullWidth
              onRequestClose={() => setShowApps(false)}
              modalHeading={showApps.name}
              modalLabel=""
              className="fss-topology"
            >
              <ul className="tiles-details">{getTilesDetails()}</ul>
            </Modal>
          ) : null}

          {showTopology && (
            <Modal
              open
              size="lg"
              passiveModal
              isFullWidth
              onRequestClose={() => setShowTopology(false)}
              modalHeading="Topology View"
              modalLabel="Application Topology View"
              className="topology-view"
            >
              <TopologyGraph
                redirectToIne={redirectToIne}
                appDetails={appDetails}
              />
            </Modal>
          )}
        </Row>
      ) : (
        <Column
          lg={16}
          md={8}
          sm={2}
          className="clickable-tile-portfolio-att margin-lft"
        >
          <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
        </Column>
      )}{" "}
    </>
  );
};

export default NerveDashboard;
