import { SkeletonText, Tile } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import * as d3 from "d3";
import React, { useEffect, useState } from "react";
import { getAuditReport } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";

const DailyJobs = (props) => {
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        auditReport()
    }, [])

    const auditReport = async () => {
        let report = await getAuditReport(keycloak, uuidv4(), "kenvue_audit");
        getTreeMap(report.data.kenvue_audit)
    }

    const getTreeMap = (report) => {
        const jobsData = {
            name: "",
            children: convertJobs(report)
        }
        setLoading(false)
        if (props.flow) {
            showGraph(jobsData, "childFlow")
        } else {
            showGraph(jobsData)
        }
    }

    const convertJobs = (json) => {
        let unique_Product = json
            .map((item) => item["Data Product"])
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );

        let newAr = [];
        unique_Product.forEach((pd) => {
            let getChild = [];
            json.forEach((js) => {
                if (js["Data Product"] === pd) {
                    getChild.push(js)
                }
            })

            let newObj = {
                name: pd,
            }
            let sub_cat = getChild
                .map((item) => item["Job Name"])
                .filter(
                    (value, index, current_value) => current_value.indexOf(value) === index
                )
            let childAr = []
            sub_cat.forEach((cat) => {
                let getChild1 = [];
                getChild.forEach((chd) => {
                    if (chd["Job Name"] === cat) {
                        getChild1.push({ name: chd["Job Name"], value: parseFloat(chd["Absolute"]), dailyRow: parseFloat(chd["Daily Row Count Processed"]), dailyAvg: parseFloat(chd["Daily Average"]) })
                    }
                })

                const filterDup = getChild1.filter((obj, index) => {
                    return index === getChild1.findIndex(o => obj.name === o.name);
                })
                childAr.push({ name: cat, children: filterDup })
            })
            newObj = {
                ...newObj,
                children: childAr
            }
            newAr.push(newObj)
        })
        return newAr;
    }

    const showGraph = (data, flow) => {
        const width = 1300;
        const height = 550;
        // This custom tiling function adapts the built-in binary tiling function
        // for the appropriate aspect ratio when the treemap is zoomed-in.
        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }


        //const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

        // Compute the layout.
        const hierarchy = d3.hierarchy(data)
            .count(d => d.value)
            .sort((a, b) => b.value - a.value);
        const root = d3.treemap().tile(tile)(hierarchy);

        // Create the scales.
        const x = d3.scaleLinear().rangeRound([0, width]);
        const y = d3.scaleLinear().rangeRound([0, height]);

        // Formatting utilities.
        //const format = d3.format(",d");
        const name = d => d.ancestors().reverse().map(d => d.data.name).join(" -> ");

        // Create the SVG container.
        const svg = d3.select("#daily-grp")
            .append("svg")
            .attr("id", `treeMap${flow !== undefined ? 1 : 2}`)
            .attr("viewBox", [0, -20, width, height + 30])
            .attr("width", width)
            .attr("height", height + 30)
            .attr("style", "max-width: 100%; height: auto;")
            .style("font", "10px");

        // Display the root.
        let group = svg.append("g")
            .call(render, root);


        // if (flow !== undefined) {
        //     const lastNode = root
        //         .descendants()
        //         .find(e => e.data.name === "VWRSHP");
        //     zoomin(lastNode);
        // }

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => d === root ? zoomout(root) : zoomin(d));

            node.append("title")
                .text(d => `${name(d)}\n${d !== root ? `Processed: ${getRowCount(d).toLocaleString('en-US')}` : ""}\n${d !== root ? `Daily Average: ${getAvgCount(d).toLocaleString('en-US')} ` : ""}\n${d !== root ? `Variation: ${getVariation(d).toLocaleString('en-US')} ` : ""}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = "leaf").id)
                .attr("fill", d => d !== root ? getRectFill(d) : "#fff")
                .attr("stroke", "#fff");
            node.append("clipPath")
                .attr("id", d => (d.clipUid = "clip").id)
                .append("use")
                .attr("xlink:href", d => d.leafUid.href);

            node.append("text")
                .attr("id", "titleTxt")
                .attr("dx", "0.5em")
                .attr("dy", "1.5em")
                .attr("font-size", "14px")
                .attr("fill", "#fff")
                .attr("font-weight", "bold")
                .text(function (d) {
                    return d.data.name;
                });

            node.append("text")
                .attr("clip-path", d => d.clipUid)
                .text(d => d === root ? name(d) : "")
                .attr("dx", "0.7em")
                .attr("dy", "1.5em")
                .attr("id", "ct")
                .attr("font-weight", d => d === root ? "bold" : null)
                .selectAll("tspan")
                .data(d => ("").split(/(?=[A-Z][^A-Z])/g).concat(d !== root ? `Processed: ${getRowCount(d).toLocaleString('en-US')}` : "").concat(d !== root ? `Daily Average: ${getAvgCount(d).toLocaleString('en-US')} ` : "").concat(d !== root ? `Variation: ${getVariation(d).toLocaleString('en-US')} ` : "").concat(""))
                .join("tspan")
                .attr("x", 3)
                .attr("dx", "0.7em")
                .attr("dy", "2em")
                .attr("y", (d, i, nodes) => getParent(d, i, nodes))
                .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                .attr("font-weight", (d, i, nodes) => i === nodes.length - 1 ? "normal" : null)
                .attr("font-size", (d, i, nodes) => getFontsize(d, i, nodes))
                .attr("fill", "#fff")
                .text(d => d);
            group.call(position, root);
        }

        function getPDx(d) {
            return (x(d.x1) - x(d.x0)) - 42
        }

        function getPDy(d) {
            return (y(d.y1) - y(d.y0)) - 10
        }


        function setOpacity(d) {
            if ((x(d.x1) - x(d.x0)) < 150) {
                return 0
            }
            return 1
        }

        function getPercent(d) {
            const percent = getValue(d);
            return `${percent}%`;
        }

        function getFontsize(d, i, nodes) {
            if (i === 0) {
                return "13px"
            }
            return "11px"
        }

        function getParent(d, i, nodes) {
            return `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 1.3}em`
        }

        function getRectFill(d) {
            const val = getValue(d);
            return getColor(val);
        }

        function getVariation(d) {
            return Math.round(`${getRowCount(d) - getAvgCount(d)}`)
        }

        function getColor(val) {
            switch (true) {
                case val > 100:
                    return "#750e13";
                case val <= 100 && val >= 80:
                    return "#a2191f";
                case val < 80 && val >= 70:
                    return "#24a148";
                case val < 70 && val > 60:
                    return "#022d0d";
                case val < 60 && val >= 20:
                    return "#044317";
                default:
                    return "#0e6027";
            }
        }

        function getRowCount(d) {
            let dailyRow = 0, len = 0;
            let childPresence = d.data.children && d.data.children.some(obj => Object.keys(obj).includes("children"));
            if (childPresence) {
                d.data.children.forEach((child1) => {
                    len = len + child1.children.length;
                    child1.children.forEach((nested) => {
                        dailyRow = dailyRow + nested.dailyRow
                    })
                })
                const round = Math.round(dailyRow) / len
                return Math.round(round);
            } else if (d.data.children && d.data.children.length) {
                len = len + d.data.children.length;
                d.data.children.forEach((child1) => {
                    dailyRow = dailyRow + child1.dailyRow
                })
                const round = Math.round(dailyRow) / len
                return Math.round(round);
            } else {
                dailyRow = d.data.dailyRow;
                return dailyRow;
            }
        }

        function getAvgCount(d) {
            let dailyAvg = 0, len = 0;
            let childPresence = d.children && d.children.some(obj => Object.keys(obj).includes("children"));
            if (childPresence) {
                d.data.children.forEach((child1) => {
                    len = len + child1.children.length;
                    child1.children.forEach((nested) => {
                        dailyAvg = dailyAvg + nested.dailyAvg
                    })
                })
                const round = Math.round(dailyAvg) / len;
                return Math.round(round);
            } else if (d.data.children && d.data.children.length) {
                len = len + d.data.children.length;
                d.data.children.forEach((child1) => {
                    dailyAvg = dailyAvg + child1.dailyAvg
                })
                const round = Math.round(dailyAvg) / len
                return Math.round(round);
            } else {
                dailyAvg = d.data.dailyAvg;
                return dailyAvg;
            }
        }

        function getValue(d) {
            let value = 0, len = 0;
            let childPresence = d.children && d.children.some(obj => Object.keys(obj).includes("children"));
            if (childPresence) {
                d.data.children.forEach((child1) => {
                    len = len + child1.children.length;
                    child1.children.forEach((nested) => {
                        value = value + nested.value
                    })
                })
                const round = Math.round(value) / len
                return Math.round(round);
            } else if (d.data.children && d.data.children.length > 0) {
                len = len + d.data.children.length;
                d.data.children.forEach((child1) => {
                    value = value + child1.value
                })
                const round = Math.round(value) / len
                return Math.round(round);
            }
            return Math.round(d.data.value)
        }

        function position(group, root) {

            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0))

            group.selectAll("g")
                .select("#ct")
                .attr("opacity", d => setOpacity(d))

            group.selectAll("g")
                .select("#tx1")
                .attr("dy", d => getPDy(d))
                .attr("dx", d => getPDx(d))
                .attr("opacity", d => {
                    if ((x(d.x1) - x(d.x0)) < 80) {
                        return 0
                    } return 1
                })
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .call(position, d.parent))
                .call(t => group1.transition(t)
                    .attrTween("opacity", () => d3.interpolate(0, 1))
                    .call(position, d));
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => d3.interpolate(1, 0))
                    .call(position, d))
                .call(t => group1.transition(t)
                    .call(position, d.parent));
        }

        return svg.node();
    }


    return (
        <Tile>
            <h4>Percentage difference in records processed by jobs</h4>
            {!loading ?
                <div id="daily-grp"></div>
                : <>
                <SkeletonText></SkeletonText>
                <SkeletonText></SkeletonText>
                <SkeletonText></SkeletonText>
                <SkeletonText></SkeletonText>
                <SkeletonText></SkeletonText>
                </>}
        </Tile>
    )
}

export default DailyJobs;