import {
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React from "react";
import { susopsRec } from "./staticData/recomm";

const SusopsRec = () => {
  return (
    <StructuredListWrapper className="susops-tables">
      <StructuredListHead>
        <StructuredListRow head>
          <StructuredListCell head>Recommendations</StructuredListCell>
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {susopsRec.map((rec) => (
          <StructuredListRow key={rec}>
            <StructuredListCell>{rec}</StructuredListCell>
          </StructuredListRow>
        ))}
      </StructuredListBody>
    </StructuredListWrapper>
  );
};

export default SusopsRec;
