import axios from "axios";

const BFF_URL =
    //"http://localhost:8000/bff";
sessionStorage.getItem("bff");

/* DO External */
export const getDOClientsApi = async () => {
    // console.log("keycloak --------", keycloak, org);
    const body =
    {
        "alertType": "Infra Alert",
        "filtersArray": [
            "CAI"
        ]
    }

    return axios
        .post(`${BFF_URL}/DO/getDOClients`, body)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return "Error";
        });
};

export const getDOInfraAlertsApi = async (filters) => {
    // console.log("keycloak --------", keycloak, org);
    console.log(filters, "infra filters");
    const body =
    {
        "alertType": "Infra Alert",
        "tenenatsArray": filters.franchise || [],
        "alertStatus": filters.isIncident || 'All',
        "orgArray": filters.client || [],
        "pit": filters.pit,
        "size": 100,
        "searchAfter": filters.searchAfter || ""

    }

    return axios
        .post(`${BFF_URL}/DO/getDOInfraAlerts`, body)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return "Error";
        });
};

export const getDOFranchiseApi = async (filters) => {
    // console.log("keycloak --------", keycloak, org);
    console.log(filters, "infra filters");
    const body = {};

    return axios
        .post(`${BFF_URL}/DO/getDOFranchise`, body)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return "Error";
        });
};

export const getDOPitApi = async (index) => {
    // console.log("keycloak --------", keycloak, org);
    console.log(index, "infra filters");
    const body = {
        "index": index
    };

    return axios
        .post(`${BFF_URL}/DO/getDOPit`, body)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return "Error";
        });
};

export const getDOInfraApi = async (component) => {
    // console.log("keycloak --------", keycloak, org);
    const body = {};

    return axios
        .post(`${BFF_URL}/DO/getDoInfraTopology?component=${component}&tenant=${sessionStorage.getItem("tenant")}&org=${sessionStorage.getItem("organizationName")}`, body)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log(err);
            return "Error";
        });
};