import React from 'react'
import EmissionChart from './EmissionChart'
import { Column, Row } from '@carbon/react'
import EmissionChart1 from '../Consulting/EmissionChart1';

const EAApps = () => {
  const tenant = sessionStorage.getItem("tenant");
  const EA_Tenant = tenant === "Energy_Australia";
  return (
      <Row>
        <Column lg={16} md={8} sm={4}>
          {
            EA_Tenant
            ? <EmissionChart />
            : <EmissionChart1 />
          }
        </Column>
      </Row>
  )
}

export default EAApps