import React from "react";
import { SimpleBarChart } from "@carbon/charts-react";
import { ErrorBoundary, Tile } from "@carbon/react";

const data = [
    {
        "group": "Misc",
        "value": [40, 50]
    },
    {
        "group": "Restocking",
        "value": [35, 40]
    },
    {
        "group": "Sold",
        "value": [30, 35]
    },
    {
        "group": "More",
        "value": [10, 30]
    },
    {
        "group": "Qty",
        "value": [0, 10]
    }
];

const options = {
    "title": "Timeline (ms)",
    "axes": {
        "left": {
            "mapsTo": "group",
            "scaleType": "labels"
        },
        "bottom": {
            "mapsTo": "value"
        }
    },
    "height": "320px"
};

const FloatingBarChart = (props) => {

    const getChartData = () => {
        let length = props.trace_graph.Services.length;
        let chartData = new Array(length);
        let chartDataObj = { group: "", value: [] };
        let milliSeconds = 0;
        props.trace_graph.Services.map((serviceObj, index) => {
            chartDataObj = {};
            chartDataObj.group = serviceObj.Name;
            if (serviceObj.ResponseTimeHistogram) {
                chartDataObj.value = [milliSeconds, milliSeconds + serviceObj.ResponseTimeHistogram[0].Value];
                milliSeconds = milliSeconds + serviceObj.ResponseTimeHistogram[0].Value;
            }
            chartData[length - index - 1] = chartDataObj;
        })
        return chartData;
    }

    return (
        <Tile className="chart-container">
            <ErrorBoundary fallback={"Data is unavailable"}>
                <SimpleBarChart
                    data={getChartData()}
                    options={options}
                />
            </ErrorBoundary>
        </Tile>
    );
}

export default FloatingBarChart;
