/** @format */

import { Layer, Tile } from "@carbon/react";
import React from "react";
import { WarningFilled } from "@carbon/react/icons/index"

const DataUnAvailable = () => {
    return (
        <Tile className="chart-container">
            <Layer className="data_unavailable--layer">
                <WarningFilled />
                <h3>Data is unavailable</h3>
                <p>
                    Error due to data unavailablity for the selected filter. Please select
                    a different date.
                </p>
            </Layer>
        </Tile>
    );
};

export default DataUnAvailable;
