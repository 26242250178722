import {
  Column,
  Row
} from "@carbon/react";
import {
  ArrowDown,
  ArrowUp,
  Catalog,
  IbmWatsonDiscovery,
} from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import TooltipCarbon from "Carbon-Components/Tooltip";
import { convertDate, convertUTCDate } from "Common-Modules/ConvertUTCDate";
import { getMLSimilarIncident, getServiceNowCRData } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import incidentData from "./incident.json";
import IncidentTabs from "./incidentTabs";
import "./pnc.scss";

const PncIncidents = (props) => {
  const { keycloak } = useKeycloak();
  const queryCache = new QueryCache();
  const [refresh, refreshIcons] = useState(false);
  const [showPopUp, setShowPopup] = useState(false);
  const [snowRowTable, snowRowDataTable] = useState([]);
  const [tableDetails, setTableDetails] = useState([]);
  const [tabsChange, setTabsChange] = useState(0);
  const [snowincidentData, setSnowIncident] = useState([]);
  const [snowCRRowTable, snowCRRowDataTable] = useState([]);
  const [mlSimilarInc, setMLSimilarInc] = useState("");

  const { data: snowCRRowData, refetch: snowCRRefetch } = useQuery(
    ["servicenowCR"],
    async () => await getServiceNowCRData(keycloak, uuidv4()),
    {
      cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
    }
  );

  const {
    data: similarInc,
  } = useQuery(
    ["MLsimilar"],
    () => getMLSimilarIncident(keycloak, uuidv4(), tableDetails[0].description),
    {
      retry: 1,
      enabled: false,
    }
  );

  useEffect(() => {
    if (similarInc !== undefined && similarInc.data !== undefined) {
      setMLSimilarInc(similarInc.data);
    }
  }, [similarInc]);

  useEffect(() => {
    if (snowCRRowData !== undefined && snowCRRowData.data !== undefined) {
      snowCRRowDataTable(snowCRRowData.data.changeReq);
    }
  }, [snowCRRowData]);

  // const snowCRHeaderData = [
  //   {
  //     key: "TICKET_ID",
  //     header: "Ticket",
  //   },
  //   {
  //     key: "CHNAGE_DESCRIPTION",
  //     header: "Description",
  //   },
  //   {
  //     key: "CHANGE_STATUS",
  //     header: "Status",
  //   },
  //   {
  //     key: "CONFIGURATION_ITEM",
  //     header: "Config Item",
  //   },
  //   // {
  //   //     key: "IMPACT",
  //   //     header: "Impact",
  //   // },
  //   // {
  //   //     key: "CREATED_BY",
  //   //     header: "Created By",
  //   // },
  //   {
  //     key: "CREATED",
  //     header: "Created On",
  //   },
  // ];

  // const getTableHeader = (header) => {
  //   return header;
  // };

  // const getState = (num) => {
  //   switch (num) {
  //     case "1":
  //       return "New";
  //     case "2":
  //       return "In Progress";
  //     case "3":
  //       return "On Hold";
  //     case "6":
  //       return "Resolved";
  //     default:
  //       return "null";
  //   }
  // };

  // const getPriority = (num) => {
  //   switch (num) {
  //     case "1":
  //       return (
  //         <div>
  //           P1 - Critical{" "}
  //           <ArrowUp size={12} stroke="#da1e28" strokeWidth="3px" />
  //         </div>
  //       );
  //     case "2":
  //       return (
  //         <div>
  //           P2 - High <ArrowUp size={12} stroke="#fa4d56" strokeWidth="3px" />
  //         </div>
  //       );
  //     case "3":
  //       return (
  //         <div>
  //           P3 - Medium{" "}
  //           <ArrowDown size={12} stroke="#ff8389" strokeWidth="3px" />
  //         </div>
  //       );
  //     case "4":
  //       return (
  //         <div>
  //           P4 - Low <ArrowDown size={12} stroke="#d2a106" strokeWidth="3px" />
  //         </div>
  //       );
  //     case "5":
  //       return (
  //         <div>
  //           P5 - Very Low{" "}
  //           <ArrowDown size={12} stroke="#d2a106" strokeWidth="3px" />
  //         </div>
  //       );
  //     default:
  //       return "null";
  //   }
  // };

  // const getSnowRowCRCellData = (id, data, row) => {
  //   if (id.includes("IMPACT")) {
  //     return getPriority(data);
  //   }
  //   if (id.includes("CREATED")) {
  //     return convertDate("dd-mm-yy", convertUTCDate(data), "created");
  //   }
  //   if (id.includes(":CHNAGE_DESCRIPTION")) {
  //     try {
  //       if (data.length <= 90) {
  //         return data;
  //       } else {
  //         let temp = data.substring(0, 90) + "...";
  //         return (
  //           <div className="desc-tooltip">
  //             <TooltipCarbon description={data}>{temp}</TooltipCarbon>
  //           </div>
  //         );
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   return data;
  // };

  // const onRefreshSnow = () => {
  //   refreshIcons(true);
  //   snowRowDataTable([]);
  // };

  // const getChangeActions = (row, cell) => {
  //   return (
  //     <ul className="action-icons">
  //       <li
  //         className="enable"
  //         title="Analyze"
  //         onClick={() => onclickChangeAnalize(row)}
  //       >
  //         <IbmWatsonDiscovery />
  //       </li>
  //       <li
  //         className="enable"
  //         title="Details"
  //         // onClick={() => onClickActions("Node", row)}
  //       >
  //         <Catalog />
  //       </li>
  //     </ul>
  //   );
  // };

  // const onclickChangeAnalize = (row) => {
  //   let tableRow = [];
  //   row.cells.forEach((cell) => {
  //     snowCRRowTable.forEach((rows) => {
  //       if (cell.value === rows.TICKET_ID) {
  //         tableRow.push(rows);
  //       }
  //     });
  //   });
  //   setTableDetails(tableRow);
  //   setShowPopup(true);
  //   const ele = document.getElementById(`row6`);
  //   ele.scrollIntoView({ behavior: "smooth" });
  // };

  // const onclickAnalize = (row) => {
  //   let tableRow = [];
  //   row.cells.forEach((cell) => {
  //     snowRowTable.forEach((rows) => {
  //       if (cell.value === rows.number) {
  //         tableRow.push(rows);
  //       }
  //     });
  //   });
    // const res = snowRowTable.filter(
    //   (inc) => inc.key === tableRow[0].incidentId
    // );
  //   setSnowIncident(incidentData[0].data);
  //   setTableDetails(tableRow);
  //   setShowPopup(true);
  //   const ele = document.getElementById(`row6`);
  //   ele.scrollIntoView({ behavior: "smooth" });
  // };

  // const tabChange = (e) => {
  //   setTabsChange(e.selectedIndex);
  //   if (e.selectedIndex === 1) {
  //     snowCRRefetch();
  //   }
  //   setShowPopup(false);
  // };

  return (
    <>
      {/* <FlexGrid fullWidth={true} className="pnc"> */}
        {/* <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/operations">Operations</BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>Incident Analysis</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row> */}
        <Row className="pnc-value-stream">
          <Column lg={16} md={8} className="paddingRgt paddingLft">
            <div className="tabs">
              {/* <Tabs onChange={(e) => tabChange(e)}>
                <TabList aria-label="List of tabs">
                  <Tab>Incidents</Tab>
                  <Tab>Change Request</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel> */}
                    <IncidentTabs
                      clickedTiles="Incidents"
                      appFlow="Incident Analysis"
                      flow={props.flow}
                      servnow={props.servnow}
                      toggle={props.toggle}
                    />
                    {/* <ErrorBoundary fallback={"Data is unavailable"}>
                                            <DataTable
                                                title="ServiceNow - Event"
                                                rowData={snowRowTable}
                                                headerData={snowHeaderData}
                                                getRowCellData={getSnowRowCellData}
                                                getTableHeader={getTableHeader}
                                                actionsNeeded={false}
                                                className="snow-table"
                                                iconRotate={refresh}
                                                onRefresh={onRefreshSnow}
                                                table_particular_actions={true}
                                                getActions={getActions}
                                            />
                                        </ErrorBoundary> */}
                  {/* </TabPanel>
                  <TabPanel> */}
                    {/* <ErrorBoundary fallback={"Data is unavailable"}>
                      {snowCRRowTable.length > 0 ? (
                        <DataTable
                          title="ServiceNow - Change Request"
                          rowData={snowCRRowTable}
                          headerData={snowCRHeaderData}
                          getRowCellData={getSnowRowCRCellData}
                          getTableHeader={getTableHeader}
                          actionsNeeded={false}
                          className="snow-table"
                          iconRotate={refresh}
                          onRefresh={onRefreshSnow}
                          table_particular_actions={true}
                          getActions={getChangeActions}
                        />
                      ) : (
                        <span
                          style={{
                            textAlign: "center",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          No Change Data is Open
                        </span>
                      )}
                    </ErrorBoundary> */}
                  {/* </TabPanel>
                </TabPanels>
              </Tabs> */}
            </div>
          </Column>
        </Row>
        <Row id="row6"></Row>
      {/* </FlexGrid> */}
    </>
  );
};

export default PncIncidents;
