import ChartContainer from "Carbon-Charts/ChartContainer";
import React from "react";

export const getChartData = (props) => {
    if (props !== undefined && props.chartData !== undefined && props.chartData.length > 0) {
        return props.chartData.map((chartRes) => {
            let data = null;
            if (chartRes.title === "Risk Assessment") {
                data = {
                    "dataType": "simpleBar",
                    "data": {
                        "chartData": chartRes.values,
                        "chartOptions": {
                            "title": chartRes.title,
                            "toolbar": {
                                "enabled": false
                            },
                            "axes": {
                                "left": {
                                    "mapsTo": "value",
                                    "scaleType": "linear",
                                    "ticks": {
                                        "number": 1
                                    }
                                },
                                "bottom": {
                                    "mapsTo": "group",
                                    "scaleType": "labels"
                                }
                            },
                            "grid": {
                                "x": {
                                    "enabled": false
                                },
                                "y": {
                                    "enabled": false
                                }
                            },
                            "color": {
                                "scale": {
                                    "High": "#0f62fe",
                                    "Medium": "#4589ff",
                                    "Low": "#78a9ff"
                                }
                            },
                            "legend": {
                                "enabled": false
                            },
                            "label": {
                                "enabled": false
                            },
                            "height": "130px",
                            "width": "130px"
                        }
                    }
                }
            } else if (chartRes.title === "Build Status") {
                data = {
                    dataType: "donut",
                    data: {
                        chartData: chartRes.values,
                        chartOptions: {
                            title: chartRes.title,
                            resizable: true,
                            donut: {
                                alignment: "center",
                                center: {
                                    label: "",
                                },
                                legend: {
                                    enabled: false
                                }
                            },
                            color: {
                                scale: {
                                    Success: "#78a9ff",
                                    Failure: "#ff8389",
                                },
                            },
                            toolbar: {
                                enabled: false,
                            },
                            legend: {
                                enabled: false
                            },
                            height: "130px",
                            "width": "130px"
                        },
                    },
                };
            } else if (chartRes.title === "Velocity") {
                data = {
                    dataType: "bar",
                    data: {
                        chartData: chartRes.values,
                        "chartOptions": {
                            "title": "Velocity",
                            "color": {
                                "scale": {
                                    "Committed": "#78a9ff",
                                    "Completed": "#a6c8ff"
                                }
                            },
                            "grid": {
                                "x": {
                                    "enabled": false
                                },
                                "y": {
                                    "enabled": false
                                }
                            },
                            "axes": {
                                "left": {
                                    "mapsTo": "value"
                                },
                                "bottom": {
                                    "scaleType": "labels",
                                    "mapsTo": "key"
                                }
                            },
                            "height": "130px",
                            "toolbar": {
                                "enabled": false
                            },
                            "legend": {
                                "enabled": false
                            }
                        }
                    },
                };
            }
            return (
                data !== null ? <ChartContainer data={data} /> : null 
            );
        });
    }
}

export const getBasicInfo = (props) => {
    if (props !== undefined && props.tileData !== undefined && props.tileData.length > 0) {
        return <ul className="basic-info">{props.tileData.map((res) => <li><h5>{res.title}</h5><span>{res.value}</span></li>)}</ul>
    }
}

export const getValChartData = (props) => {
    if (props !== undefined && props.chartData !== undefined && props.chartData.length > 0) {
        return props.chartData.map((chartRes) => {
            let data = null;
            if (chartRes.title === "Journey Status") {
                data = {
                    dataType: "donut",
                    data: {
                        chartData: chartRes.values,
                        chartOptions: {
                            title: chartRes.title,
                            resizable: true,
                            donut: {
                                alignment: "center",
                                center: {
                                    label: "",
                                },
                            },
                            pie: {
                                alignment: "center"
                            },
                            color: {
                                scale: {
                                    Success: "#78a9ff",
                                    Failure: "#ff8389",
                                },
                            },
                            legend: {
                                enabled: false
                            },
                            toolbar: {
                                enabled: false,
                            },
                            height: "130px"
                        },
                    },
                };
            } else if (chartRes.title === "Interaction Volume Status") {
                data = {
                    dataType: "area",
                    data: {
                        chartData: chartRes.values,
                        chartOptions: {
                            title: "Interaction Volume",
                            axes: {
                                bottom: {
                                    mapsTo: "key",
                                    scaleType: "labels"
                                },
                                left: {
                                    mapsTo: "value",
                                    scaleType: "linear",
                                },
                            },
                            curve: "curveNatural",
                            height: "130px",
                            legend: {
                                enabled: false
                            },
                            toolbar: {
                                enabled: false,
                            },
                            color: {
                                scale: {
                                    Success: "#78a9ff",
                                    Fail: "#da1e28",
                                },
                            },
                        },
                    },
                };
            }
            return (
                data !== null ? <ChartContainer data={data} /> : null 
            );
        });
    }
}


export const getOpsChartData = (props) => {
    if (props !== undefined && props.chartData !== undefined && props.chartData.length > 0) {
        return props.chartData.map((chartRes) => {
            let data = null;
            if (chartRes.title === "Job Status") {
                data = {
                    dataType: "bubble",
                    data: {
                        chartData: chartRes.values,
                        chartOptions: {
                            title: chartRes.title,
                            resizable: true,
                            color: {
                                scale: {
                                    "Success": "#78a9ff",
                                    "Failed": "#ff8389",
                                    "Running": "#82cfff",
                                    "Yet to Start": "#d0e2ff",
                                },
                            },
                            legend: {
                                enabled: false
                            },
                            toolbar: {
                                enabled: false,
                            },
                            "height": "180px"
                        },
                    },
                }
            } else if (chartRes.title === "Sev 1 Incidents") {
                data = {
                    dataType: "donut",
                    data: {
                        chartData: chartRes.values,
                        chartOptions: {
                            title: chartRes.title,
                            resizable: true,
                            donut: {
                                alignment: "center",
                                center: {
                                    label: "",
                                },
                            },
                            color: {
                                scale: {
                                    "Sev 1": "#ffb3b8",
                                    "Others": "#bae6ff"
                                },
                            },
                            legend: {
                                enabled: false
                            },
                            toolbar: {
                                enabled: false,
                            },
                            "height": "130px",
                            "width": "130px"
                        },
                    },
                };
            } else if (chartRes.title === "Alerts by Categories") {
                data = {
                    dataType: "pie",
                    data: {
                        chartData: chartRes.values,
                       "chartOptions": {
                            "title": chartRes.title,
                            "canvasZoom": {
                                "enabled": true
                            },
                            "pie": {
                                "alignment": "center"
                            },
                            "toolbar": {
                                "enabled": false
                            },
                            "color": {
                                "scale": {
                                    "Application": "#0043ce",
                                    "Network": "#0f62fe",
                                    "Infrastructure": "#4589ff"
                                }
                            },
                            "legend": {
                                "enabled": false
                            },
                            "height": "130px",
                            "width": "130px"
                        }
                    },
                };
            }
            return (
               data !== null ? <ChartContainer data={data} /> : null 
            );
        });
    }
}


