import { Button, CodeSnippet, Column, ErrorBoundary, FlexGrid, Modal, RadioTile, Row, Tile } from "@carbon/react";
import { LogoSlack, Rotate } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { getAzureGenAIlogs, getVertexResponse, getWatsonxGenAIlogs, googletoken } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";

const Analyze = (props) => {
    const size = 400;
    const { keycloak } = useKeycloak();
    const [progress, setProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [helperText, setHelperText] = useState("");
    const [selectChange, setSelectChange] = useState(0);
    const [googlekey, setGoogleToken] = useState("");
    const [selectRadio, setSelectRadio] = useState("google vertex AI");
    const [storeSummary, setStoreSummary] = useState("");
    const [questionArr] = useState(["google vertex AI", "watsonx AI", "azure AI"]);

    useEffect(() => {
        if (progress === 0) {
            setTimeout(() => {
                const interval = setInterval(() => {
                    setProgress(currentProgress => {
                        const advancement = Math.random() * 8;
                        if (currentProgress + advancement < size) {
                            return currentProgress + advancement;
                        } else {
                            clearInterval(interval);
                            return size;
                        }
                    });
                }, 100);
            }, 100);
        }
    }, [progress])

    useEffect(() => {
        if (progress > 50) {
            setHelperText('Analyzing the issue..')
        }
        if (progress === size) {
            setHelperText('')
        }
    }, [progress])

    useEffect(() => {
        getApiResponse(props.logs)
    }, [props.logs])


    // const getGoogleToken = async () => {
    //     const token = await googletoken(keycloak, uuidv4());
    //     if (token === undefined) {
    //         setErrorMessage("Token is invalid")
    //     } else {
    //         setGoogleToken(token.data.access_token)
    //     }
    // }

    const getApiResponse = async (logs) => {
        const res = await getVertexResponse({
            "query": logs
        }, "", keycloak)
        setProgress(0)
        setStoreSummary(res.data)
    }

    const getWatsonxGen = async (logs) => {
        const res = await getWatsonxGenAIlogs(keycloak, uuidv4(), {
            "query": logs
        });
        setProgress(0)
        setStoreSummary(res.data)
    }

    const getAzureGen = async (logs) => {
        const res = await getAzureGenAIlogs(keycloak, uuidv4(), {
            "query": logs
        });
        setProgress(0)
        setStoreSummary(res.data)
    }

    const getWatsonDetails = () => {
        return storeSummary !== "" ? <ul>
            <li><h5>Title</h5>{storeSummary.application}</li>
            <li><h5>Issue Category</h5>{storeSummary["issue_category"]}</li>
            <li><h5>Error Location</h5>{storeSummary["error_location"]}</li>
            <li><h5>Resolution</h5>{storeSummary["resolution_note"]}</li>
            <li><h5>Error Description</h5>{storeSummary["description"]}</li>
        </ul> :
            <ul>
                <li><Rotate className="rotateIcons" size={16} />{helperText}</li>
            </ul>
    }

    const getAzureDetails = () => {
        return storeSummary !== "" ? <ul>
            <li><h5>Title</h5>{storeSummary.application}</li>
            <li><h5>Issue Category</h5>{storeSummary["issue_category"]}</li>
            <li><h5>Error Location</h5>{storeSummary["error_location"]}</li>
            <li><h5>Resolution</h5>{storeSummary["resolution_note"]}</li>
            <li><h5>Affected Application</h5>{storeSummary["affected_applications"]}</li>
            <li><h5>Error Description</h5>{storeSummary["description"]}</li>
        </ul> :
            <ul>
                <li><Rotate className="rotateIcons" size={16} />{helperText}</li>
            </ul>
    }

    const getSummaryDetails = () => {
        return storeSummary !== "" ? <ul>
            <li><h5>Title</h5>{storeSummary.Title}</li>
            <li><h5>Issue Category</h5>{storeSummary["issue category"]}</li>
            <li><h5>Error Location</h5>{storeSummary["error location"]}</li>
            <li><h5>Summary</h5>{storeSummary["Summary"]}</li>
            <li><h5>Resolution</h5>{storeSummary["Resolution"]}</li>
            <li><h5>Error Description</h5>{storeSummary["Error Description"]}</li>
        </ul> :
            <ul>
                <li><Rotate className="rotateIcons" size={16} />{helperText}</li>
            </ul>
    }

    const closeModal = () => {
        props.onCloseModal(false)
    }

    const collaborate = () => {
        window.open("https://ibm-test.enterprise.slack.com/archives/C081XC8KZU4", '_blank', 'noopener, noreferrer');
    }

    const onUseCaseSelect = (e, index) => {
        setSelectChange(index)
        setStoreSummary("")
        setSelectRadio(e)
        setProgress(0)

        if (e === "google vertex AI") {
            getApiResponse(props.logs)
        } else if (e === "watsonx AI") {
            getWatsonxGen(props.logs)
        } else {
            getAzureGen(props.logs)
        }
    }

    const listUseCase = () => {
        return questionArr.map((que, index) => {
            return <li> <RadioTile id={`radio-tile-${index}`} checked={index === selectChange} className={`radio-${index} common-margin__bottom`} value={que} onChange={(e) => onUseCaseSelect(e, index)} >
                {que}
            </RadioTile>
            </li>
        })
    }

    return (
        <Modal
            open
            size="lg"
            passiveModal
            isFullWidth
            className="analyze"
            onRequestClose={() => closeModal()}
            modalHeading={<div style={{ fontSize: "14px" }}>
                Analyze
                {" "}
            </div>}
        >
            <FlexGrid isFullWidth={true}>
                <Row>
                    <Column lg={8} md={4} className="padding-rgt">
                        <h5>Models</h5>
                        <ul className="model-list">
                            {listUseCase()}
                        </ul>
                        <h5>Logs</h5>
                        <CodeSnippet hideCopyButton={true} minCollapsedNumberOfRows={10} minExpandedNumberOfRows={10} maxCollapsedNumberOfRows={25} maxExpandedNumberOfRows={25} aria-label="Copy" copyButtonDescription="Copy" className="copy-clip" type="multi" feedback="Copied" copyText="Copy">
                            {props.logs}
                        </CodeSnippet>
                    </Column>
                    <Column lg={8} md={6} className="padding-0">
                        <ErrorBoundary fallback={"Server Error.."}>
                            <h5><span>Selected Model:</span> {selectRadio}</h5>
                            <Tile className="ana-res">
                                {selectChange === 1 ? getWatsonDetails() : selectChange === 0 ? getSummaryDetails() : getAzureDetails()}
                            </Tile>
                        </ErrorBoundary>
                    </Column>
                </Row>
                <Row className="mdl-collaborate">
                    <Button kind="primary" disabled={storeSummary === ""} onClick={() => collaborate()} ><LogoSlack /> Collaborate</Button>
                </Row>
            </FlexGrid>
        </Modal>
    )
}

export default Analyze;