import {
    Accordion,
    AccordionItem,
    Button,
    Column,
    Modal,
    Row,
    SkeletonText,
    Tag,
    Tile,
    Tooltip
} from "@carbon/react";
import { Information } from "@carbon/react/icons";
import { QueryCache, useQuery } from "@tanstack/react-query";
import { getSimilarIncidents } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import "./vs.scss";

const IncidentModal = (props) => {
    //const queryCache = new QueryCache();
    const { keycloak } = useKeycloak();
    const [similarIncData, setSimilarIncData] = useState([]);

    const [showML, setShowML] = useState("root_cause");
    const [showAccordian , setShowAccordian] = useState(true)
    // const handleClick = (mlType) => {
    //     setShowML(mlType);
    // };

    const {
        data: similarIncRes,
        isLoading: loading1,
        isError: err1,
        isFetching: fetching1,
    } = useQuery(
        [`similat_incidents`],
        async () => await getSimilarIncidents({
            "ticket_type": "",
            "ticket_title": "database issue",
            "ticket_description": "database issue",
            "priority": "",
            "severity": ""
        }, keycloak));

    useEffect(() => {
        if (similarIncRes !== undefined && similarIncRes.data !== undefined) {
            const simInc = similarIncRes.data.tickets;
            setSimilarIncData(simInc)
        }
    }, [similarIncRes])

    useEffect(() => {
        if (fetching1) {
            setSimilarIncData([])
        }
    }, [fetching1])

    // const similarIncidents = [
    //     {
    //         Number: "INC0237615",
    //         DESCRIPTION:
    //             "ApplicationELB HTTPCode_ELB_5XX_Count Anomalous In Application Services",
    //         RESOLVER: "Pruthvi  P S",
    //         Priority: "P4 - Low",
    //         Score: 1,
    //         Resolution:
    //             "Check the logs for the task to see if there are any errors that are occurring.",
    //     },
    //     {
    //         Number: "INC0237700",
    //         DESCRIPTION:
    //             "ApplicationELB HTTPCode_ELB_5XX_Count Anomalous In Application Services",
    //         RESOLVER: "Ganga  Revankar",
    //         Priority: "P4 - Low",
    //         Score: 1,
    //         Resolution:
    //             "Check the network connection between the task and the listener to make sure that it is working properly.",
    //     },
    // ];

    // const getHeaderData = () => {
    //     return [
    //         {
    //             key: "runbookName",
    //             header: "RunBook Name",
    //         },
    //         {
    //             key: "status",
    //             header: "Status",
    //         },
    //         {
    //             key: "action",
    //             header: "Action",
    //         },
    //     ];
    // };

    // const getRowData = () => {
    //     return [
    //         {
    //             id: 1,
    //             runbookName: "CreateJiraIssue",
    //             status: "Not Executed",
    //             action: "Excecute",
    //         },
    //     ];
    // };

    // const getTableHeader = (header) => {
    //     return header;
    // };

    // const getRowCellData = (id, data, row) => {
    //     if (id.includes(":action")) {
    //         return (
    //             <p>
    //                 <Button kind="primary" size="sm">
    //                     Execute
    //                 </Button>
    //             </p>
    //         );
    //     }
    //     return data;
    // };


    const getMLSimilarInc = () => {
        let mlArr = [];
        if (similarIncData !== undefined && similarIncData.length > 0) {
            similarIncData.forEach((ml) => {
                const { resolution_description, owner_group_name, assignee_name, ...rest } = ml;
                mlArr.push(rest)
            })
        }
        return mlArr.length > 0 ?
            mlArr.map((similar) => {
                const newSim = Object.entries(similar).map(([key, value]) => ({ key, value }))
                return <ul className="err-log">{newSim.map((erlog) => {
                    return erlog.key === "ticket_description" ? <li>
                        <h5>{erlog.key}</h5><pre>{erlog["value"].replace(/\\n/g, '\n')}</pre>
                    </li> : <li>
                        <h5>{erlog.key}</h5><span>{erlog.key === "priority" ? <Tag type="magenta">{erlog.value}</Tag> : erlog.key === "severity" ? <Tag type="purple">
                            {erlog.value}
                        </Tag> : erlog.value}</span>
                    </li>
                })}</ul>
            }) : [1, 2].map(() => {
                return <ul className="err-log">
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                </ul >
            })
    }

    const getRR = () => {
        let mlArr = [];
        if (similarIncData !== undefined && similarIncData.length > 0) {
            similarIncData.forEach((ml) => {
                // const { resolution_description, owner_group_name, assignee_name, ...rest } = ml;
                mlArr.push({ Resolution: ml.resolution_description })
            })
        }
        return mlArr.length > 0 ?
            mlArr.map((similar) => {
                const newSim = Object.entries(similar).map(([key, value]) => ({ key, value }))
                return <ul className="err-log">{newSim.map((erlog) => {
                    return <li>
                        <pre>{erlog["value"].replace(/\\n/g, '\n')}</pre>
                    </li>
                })}</ul>
            }) : [1, 2].map(() => {
                return <ul className="err-log">
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                    <li><h5><SkeletonText /></h5><span><SkeletonText /></span></li>
                </ul >
            })
    }


    return (
        <Modal
            open
            size="lg"
            passiveModal
            onRequestClose={() => props.setShowModal(false)}
            className="ml-model-popup fss-topology"
            modalHeading="Ticket Details"
        >
            <>
                <Tile
                    style={{
                        background: "#c1c1c129",
                        padding: "1rem",
                        minHeight: "1rem",
                    }}
                >
                    <Row>
                        <Column lg={6} md={4} sm={2}>
                            <strong>Incident ID</strong> :{" "}
                            <>
                                {props.ticketDetails.Incident_Id}{" "}
                                <Tooltip
                                    description={props.ticketDetails.Description}
                                    align="bottom"
                                >
                                    <button type="button" className="tooltip-button">
                                        <Information size={12} />
                                    </button>
                                </Tooltip>
                            </>
                        </Column>
                        <Column lg={4} md={4} sm={2}>
                            <strong>Status</strong> : {props.ticketDetails.Status}
                        </Column>
                        <Column lg={4} md={4} sm={2}>
                            <strong>Severity</strong> : {props.ticketDetails.Priority}
                        </Column>
                        {/* <Column lg={4} md={4} sm={2}>
              <strong>Type</strong> : {props.ticketDetails.type}
            </Column> */}
                    </Row>
                </Tile>
                {/* <Row style={{ marginTop: "1rem" }}>
                    <Column lg={16} md={8} sm={4}>
                        <Accordion>
                            <AccordionItem
                                title="Root Cause Analysis"
                                className="accordion-ml-model"
                                open={false}
                            >
                                <Tile
                                    style={{
                                        height: "100%",
                                        marginTop: "0rem",
                                        padding: "10px",
                                        background: "#c1c1c129",
                                    }}
                                >
                                    <h5>
                                        Performance Degradation caused due to Insufficient hardware
                                        resources(CPU,Memory,Disk I/O) or network Issues.
                                    </h5>
                                </Tile>
                            </AccordionItem>
                        </Accordion>
                    </Column>
                </Row>
                <Row>
                    <Column lg={16} md={8} sm={4}>
                        <Accordion>
                            <AccordionItem
                                title="Runbook Recommendation"
                                className="accordion-ml-model"
                            >
                                <CarbonDataTable
                                    rowData={getRowData()}
                                    headerData={getHeaderData()}
                                    getRowCellData={getRowCellData}
                                    getTableHeader={getTableHeader}
                                />
                            </AccordionItem>
                        </Accordion>
                    </Column>
                </Row> */}
                <Row>
                    <Column lg={16} md={8} sm={4}>
                        <Accordion>
                            {props.ticketDetails.flow === undefined ? <AccordionItem 
                                onClick={() => setShowAccordian(!showAccordian)}
                                title="Similar Incidents"
                                className="accordion-ml-model"
                            >
                                {showAccordian && getMLSimilarInc()}
                                {/* {similarIncData.map((similarIncident) => {
                                    return (
                                        <Tile style={{ background: "#c1c1c129" }}>
                                            <h5>
                                                <Tag type="purple">
                                                    Similarity Score: {similarIncident.Score}
                                                </Tag>{" "}
                                                <br />
                                                {similarIncident.DESCRIPTION}
                                                <br />
                                                <Tag type="magenta">{similarIncident.Priority}</Tag>
                                                <br />
                                                <strong>Resolver</strong> {similarIncident.RESOLVER}
                                                <br />
                                                <strong>Resolution</strong> <br />
                                                {similarIncident.Resolution}
                                            </h5>
                                        </Tile>
                                    );
                                })} */}
                            </AccordionItem> : <AccordionItem
                                onClick={() => setShowAccordian(!showAccordian)}
                                title="Recommended Resolution"
                                className="accordion-ml-model"
                            >
                                {showAccordian && getRR()}
                            </AccordionItem>}
                        </Accordion>
                    </Column>
                </Row>
            </>
        </Modal>
    );
};

export default IncidentModal;