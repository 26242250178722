/** @format */

import { Button, ErrorBoundary, Tooltip } from "@carbon/react";
import { Edit, TrashCan } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
// import { authorizationValidity } from "Components/Common-Components/Utilities";
// import CarbonDataTable from "Components/Common/CarbonDataTable";
// import ErrorFallback from "Components/Common/ErrorFallback";
import React, { useEffect, useState } from "react";
// import { getUsersFromKeycloak } from "services/serverapi";
import CarbonDataTableRBAC from "Components/Common/CarbonDataTableRBAC";
import ErrorFallback from "Components/Common/ErrorFallback";
import { getAppUsersAPI } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
import CreateUser from "./CreateUser";
import DeleteUserModal from "./DeleteUserModal";
import EditUser from "./EditUser";
import ShowRolesForOrg from "./ShowRolesForOrg";


function UserList(props) {
  const { keycloak } = useKeycloak();
  const [createEnable, setCreateEnable] = useState(false);
  const [rowData, setRowData] = useState("");
  const [showError, setShowError] = useState("");
  const [canShowDeleteUserPopUp, setCanShowDeleteUserPopUp] = useState(false);
  const [deletedRow, setDeletedRow] = useState("");
  const [editBox, showEditBox] = useState(false);
  const [editUser, setEditUser] = useState(undefined);
  const [errMessage, setErrorMessage] = useState("");
  const [rolesPopup, setRolesPopup] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  //const UserInformation = useSelector(state => state.UserInformation)
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    const res = await getAppUsersAPI(keycloak, uuidv4(), props.tenant, props.organization, props.application)
    try {
      if (!Array.isArray(res?.data)) {
        throw new Error("Something went wrong!. Please, Try again after sometime")
      }
      addOrgs(res.data)
    } catch (error) {
      setErrorMessage(error.message)
    }
  };

  const addOrgs = (data) => {
    const newData = data.map(user => {
      let temp = { ...user }
      temp.organizations = []
      return temp
    })
    setRowData(newData)
  }

  const getHeaderData = () => {
    return [
      {
        key: "email",
        header: "Email",
      },
      {
        key: "username",
        header: "User Name",
      },
      {
        key: "id",
        header: "",
      },
      {
        key: "firstName",
        header: "First Name",
      },
      {
        key: "lastName",
        header: "Last Name",
      },
      // {
      //   key: "organizations",
      //   header: "Roles",
      // }
    ];
  };

  const onClickView = (selectedUser) => {
    setCurrentUser(selectedUser)
    setRolesPopup(true)
  }

  const getRowCellData = (id, data, row) => {
    if (id.includes(":id")) {
      return null;
    }
    if (id.includes(":organizations")) {
      let selectedUser = undefined;
      rowData.forEach(user => {
        if (user.id === row.id) {
          selectedUser = { ...user }
        }
      });
      return (
        <Button
          kind="ghost"
          onClick={() => onClickView(selectedUser)}
        >
          Click to View
        </Button>
      )
    }
    return (data ?? "-");
  };

  const getTableHeader = (header) => {
    if (header === "id") return null;
    return header;
  };

  const addUserFunction = () => {
    // const tenant = sessionStorage.getItem("tenant")
    // let base_url = sessionStorage.getItem("keycloak")
    // const url = `${base_url}/auth/admin/${tenant}/console/#/${tenant}/users/add-user`
    // window.open(url, '_blank', 'noreferrer');
    setCreateEnable(true);
  };

  const onRefresh = () => {
    setRowData("")
    getUsers();
  }

  const handleClose = (reload) => {
    setDeletedRow("");
    setCanShowDeleteUserPopUp(false);
    setCreateEnable(false);
    if (reload) {
      onRefresh();
    }
  };

  const onEdit = (selected) => {
    let data = "";
    rowData.forEach((row) => {
      if (row.id === selected.id) data = row;
    });
    setEditUser(data);
    showEditBox(true);
  };

  const getActions = (row, rows) => {
    return (
      <div className="flex-display">
        <li className="actions-icon ">
          <Tooltip align="bottom" description="Edit">
            <button type="button" className="tooltip-button">
              <Edit size={16} fill="#525252" onClick={() => onEdit(row)} />
            </button>
          </Tooltip>
        </li>
        <li className="actions-icon ">
          <Tooltip align="bottom" description="Delete">
            <button type="button" className="tooltip-button">
              <TrashCan
                size={16}
                fill="#525252"
                onClick={() => deleteUser(row, rows)}
              />
            </button>
          </Tooltip>
        </li>
      </div>
    );
  };

  const deleteUser = (row, rows) => {
    let selectedRow;
    rowData.forEach((data) => {
      if (row.id === data.id) {
        selectedRow = data;
      }
    });
    setDeletedRow(selectedRow);
    setCanShowDeleteUserPopUp(true);
  };

  return (
    <div>
      {errMessage ? (
        <ErrorFallback />
      ) : (
        <>
          <ErrorBoundary fallback={<ErrorFallback />}>
            <CarbonDataTableRBAC
              rowData={rowData}
              headerData={getHeaderData()}
              title="Application Users"
              createTitle="Create User/Edit"
              getRowCellData={getRowCellData}
              getTableHeader={getTableHeader}
              createFunction={addUserFunction}
              // disableCreate={
              //   // !(authorizationValidity("tenantUser::create",UserInformation.userCurrentPermissions))
              //   !keycloak.realmAccess.roles.includes("Dashboard_Admin")
              // }
              showRefreshIcon={true}
              onRefresh={() => onRefresh()}
              placeholder="Search by UserID, Email, Description.."
              deleteRow={deleteUser}
              actionsNeeded={false}
              table_particular_actions={true}
              getActions={getActions}
            />
          </ErrorBoundary>
          {createEnable && <CreateUser closeCreateUser={handleClose} />}
          {canShowDeleteUserPopUp ? (
            <DeleteUserModal user={deletedRow} cancel={handleClose} />
          ) : null}
          {editBox ? (
            <EditUser
              user={editUser}
              cancel={(refresh) => {
                setEditUser("");
                showEditBox(false);
                if (refresh) onRefresh();
              }}
            />
          ) : null}
          {
            rolesPopup && <ShowRolesForOrg selectedUser={currentUser} onClose={() => {
              setCurrentUser(undefined)
              setRolesPopup(false)
            }} />
          }
        </>
      )}
    </div>
  );
}

export default UserList;
