/** @format */

import { Column, SkeletonPlaceholder, Tag, Tile } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { getGenAISummarySimilarAPI } from '../../Services/ServerApi';
import AddUserActivity from '../../utilities/AddUserActivity';
import ErrorWhileFetching from "../../utilities/ErrorWhileFetching";
import InfoWhileFetching from '../../utilities/InfoWhileFetching';
import Context from 'Context/Context';
import React, { useContext, useEffect, useState } from 'react';

const SimilarRCATicketsGenAI = (props) => {

    const context = useContext(Context)
    useEffect(() => {
        getSimilarProbableGenAIData();
    }, []);

    const { keycloak } = useKeycloak();
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [errMessage, setErrorMessage] = useState("");
    const [similarRCA, setSimilarRCA] = useState([]);

    // const isUnique = (obj, array) => {
    //     if (array.length > 0) {
    //         if (array.find(item => item.document === obj.document) === undefined) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     } else { return true; }
    // }

    // const getUniqueIncidents = (array) => {
    //     let incidentsArray = [];

    //     array.forEach((obj) => {
    //         if (isUnique(obj, incidentsArray)) {
    //             incidentsArray.push(obj);
    //         }
    //     });
    //     return incidentsArray;
    // }

    const getSimilarProbableGenAIData = async () => {

        const response = await getGenAISummarySimilarAPI(keycloak.token, props.title, props.probableRCA);
        AddUserActivity(keycloak, context, "FEATURE", "Similar Incidents", response);

        if (response.status >= 400 || response === "Error") {
            setErrorMessage("Error while fetching data");
        } else {
            setSimilarRCA(response.data.data);
        }
        setIsAPIDataLoading(false);
    }

    const getLoading = () => {
        return isAPIDataLoading ? (
            <div className="skeletonPlaceholder--it-health">
                <SkeletonPlaceholder />
            </div>
        ) : <ErrorWhileFetching errMessage={errMessage} />
    }

    const getPriorityColorClassName = (priorityText) => {
       // let priority = priorityText.substring(0, 2);

        switch (priorityText) {
            case "Priority 1": return "priority-background-color__red";
            case "Priority 2": return "priority-background-color__pink";
            case "Priority 3": return "priority-background-color__orange";
            case "Priority 4": return "priority-background-color__turquoise";
            case "Priority 5": return "priority-background-color__green";
            case "Priority 6": return "priority-background-color__blue";
        }
    }

    const getTiles = () => {
        return (
            isAPIDataLoading || errMessage ? getLoading() :
                <>
                    {
                        similarRCA.length > 0
                            ?
                            similarRCA.map((data, index) => (
                                <Column lg={16} md={16} sm={16} className="clickable--tile-wizard">
                                <Tile
                                    id={index}
                                    className="summary-tile"
                                >
                                    <h5 className="report-title">
                                        <p>{data.ticket_title}</p>
                                        {data.ticket_type !== "" && <span className="text-on-tile__right-top">Type: {data.ticket_type}</span>}
                                    </h5>
                                    <Tag
                                            type="green"
                                            size="sm"
                                            title={data.distance}
                                            className="text-on-tile__secondary_tag"
                                        >
                                            Similarity Score: {data.distance}
                                    </Tag>

                                    <p className="text-on-tile__small">{data.ticket_description}</p>
                                    <p className="text-on-tile__small ">Issue Category: {data.issue_category}</p>
                                    <span className={`table--col--highlight text-on-tile__small ${getPriorityColorClassName(data.priority)}`}>{data.priority}</span>
                                    <h6 className="common-top-margin">Assignee</h6>
                                    <p>{data.assignee_name}</p>
                                    <h6 className="common-top-margin">Owner Group</h6>
                                    <p>{data.owner_group_name}</p>
                                </Tile>
                            </Column>
                            ))
                            :
                            <InfoWhileFetching errMessage={similarRCA["Similar Incidents"]} />
                    }
                </>
        );
    }

    return (
        <>
            {getTiles()}
        </>
    );
}

export default SimilarRCATicketsGenAI;