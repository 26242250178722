export const getEATrend = (base) => {
  return [
    {
      group: "Platinum",
      key: "2023",
      value: base,
    },
    {
      group: "Platinum",
      key: "2030",
      value: 1.78 * base,
    },
    {
      group: "Platinum",
      key: "2040",
      value: 2.15 * base,
    },
    {
      group: "Platinum",
      key: "2050",
      value: 2.582 * base,
    },
    {
      group: "Platinum",
      key: "2060",
      value: 3.107 * base,
    },
    {
      group: "Platinum",
      key: "2070",
      value: 3.632 * base,
    },
    {
      group: "Gold",
      key: "2023",
      value: base,
    },
    {
      group: "Gold",
      key: "2030",
      value: 1.231 * base,
    },
    {
      group: "Gold",
      key: "2040",
      value: 1.436 * base,
    },
    {
      group: "Gold",
      key: "2050",
      value: 1.641 * base,
    },
    {
      group: "Gold",
      key: "2060",
      value: 1.846 * base,
    },
    {
      group: "Gold",
      key: "2070",
      value: 2.051 * base,
    },
    {
      group: "Silver/Bronze",
      key: "2023",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2030",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2040",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2050",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2060",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2070",
      value: base,
    },
    {
      group: "Optimize",
      key: "2023",
      value: base,
    },
    {
      group: "Optimize",
      key: "2030",
      value: 0.6 * base,
    },
    {
      group: "Optimize",
      key: "2040",
      value: 0.35 * base,
    },
    {
      group: "Optimize",
      key: "2050",
      value: 0.15 * base,
    },
    {
      group: "Optimize",
      key: "2060",
      value: 0.1 * base,
    },
    {
      group: "Optimize",
      key: "2070",
      value: 0.05 * base,
    },
  ];
};

const getEmissionTrend = (base) => {
  return [
    {
      group: "Platinum",
      key: "2022",
      value: base,
    },
    {
      group: "Platinum",
      key: "2030",
      value: 1.2 * base,
    },
    {
      group: "Platinum",
      key: "2040",
      value: 1.4 * base,
    },
    {
      group: "Platinum",
      key: "2050",
      value: 1.6 * base,
    },
    {
      group: "Platinum",
      key: "2060",
      value: 1.82 * base,
    },
    {
      group: "Platinum",
      key: "2070",
      value: 1.96 * base,
    },
    {
      group: "Gold",
      key: "2022",
      value: base,
    },
    {
      group: "Gold",
      key: "2030",
      value: 1.075 * base,
    },
    {
      group: "Gold",
      key: "2040",
      value: 1.15 * base,
    },
    {
      group: "Gold",
      key: "2050",
      value: 1.23 * base,
    },
    {
      group: "Gold",
      key: "2060",
      value: 1.325 * base,
    },
    {
      group: "Gold",
      key: "2070",
      value: 1.4 * base,
    },
    {
      group: "Silver/Bronze",
      key: "2022",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2030",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2040",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2050",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2060",
      value: base,
    },
    {
      group: "Silver/Bronze",
      key: "2070",
      value: base,
    },
    {
      group: "Optimize",
      key: "2022",
      value: base,
    },
    {
      group: "Optimize",
      key: "2030",
      value: 0.6 * base,
    },
    {
      group: "Optimize",
      key: "2040",
      value: 0.35 * base,
    },
    {
      group: "Optimize",
      key: "2050",
      value: 0.15 * base,
    },
    {
      group: "Optimize",
      key: "2060",
      value: 0.1 * base,
    },
    {
      group: "Optimize",
      key: "2070",
      value: 0.05 * base,
    },
  ];
};

export default getEmissionTrend;
