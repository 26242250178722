import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row
} from "@carbon/react";
import React, { useState } from "react";
import ClusterWiseDetails from "./ClusterWiseDetails";
import "./citi.scss";
import { useHistory } from "react-router-dom";
import GetRecentTime from "./GetRecentTime";

const ClusterWiseDetailsIndex = (props) => {
  const history = useHistory();
  const [details] = useState(props.location.state.details);
  const asset = JSON.parse(sessionStorage.getItem("asset_class"));
  const cluster = JSON.parse(sessionStorage.getItem("cluster_name"));

  const redirectHRef = () => {
    history.push({ pathname: "/WorkflowIndex", state: { data: props.location.state.details, directvData: props.location.state.directvData, mergeData: props.location.state.mergeData, starCric: props.location.state.starCric, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange, flow: props.location.state.flow } })
  }

  const redirectAsset = () => {
    history.push({ pathname: `/citiDetails/${asset.toolchainName}`, state: { details: props.location.state.details, directvData: props.location.state.directvData, mergeData: props.location.state.mergeData, starCric: props.location.state.starCric, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange, flow: props.location.state.flow } })
  }

  return (
    <FlexGrid className="att-dash circle-view">
      <Row>
        <Column lg={13} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/itOperations">
                Operations
              </BreadcrumbItem>
              <BreadcrumbItem onClick={() => redirectHRef()}>
                  {details.dataProduct}
              </BreadcrumbItem>
            <BreadcrumbItem onClick={() => redirectAsset()}>
              {/* {asset.displayName} */}
              Clusters
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>{cluster}</BreadcrumbItem>
          </Breadcrumb>
        </Column>
        <Column lg={3} md={3} sm={1}>
          <GetRecentTime />
        </Column>
      </Row>
      <ClusterWiseDetails />
    </FlexGrid>
  );
};

export default ClusterWiseDetailsIndex;
