import { Column, ErrorBoundary, Row } from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import ErrorFallback from "Components/Common/ErrorFallback";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Tile } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { setChartOptions } from "../../../Common-Modules/utilities/utilities";
import { getQueryKeyValue } from "Carbon-Charts/Utilities/EventHandlers";
import ErrorRefresh from "Components/Common/ErrorRefresh";
import { useTranslation } from 'react-i18next';
import DropdownFilters from "./DropdownFilters";
import Filters from "./Filters";
import { useIncidentHook } from "./Hooks/useIncidentAnalyticsReport";
import "./IncidentAnalyticscss.scss";
import ProbabilityAnalysisOverview from "./Likelihood/ProbabilityAnalysisOverview";

const Summary = ({ queryKey, tabName, forceDirectedQueryKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  // on chart click
  const [filterChart, setFilterChart] = useState("");
  // For dropdown
  const [filterArray, setFilterArray] = useState([]);
  const [dropdownFilters, setDropdownFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const { keycloak } = useKeycloak();
  const chartRefs = useRef(null);
  const filterFields = useRef(new Map());
  const { t } = useTranslation();

  const onSettled = () => {
    setIsLoading(false);
  };

  const {
    isLoading: loading,
    data: res,
    isFetching,
    isError,
    refetch,
  } = useIncidentHook(
    keycloak,
    queryKey,
    queryKey[1],
    false,
    onSettled,
    filterChart,
    dropdownFilters
  );

  useEffect(() => {
    refetch();
  }, [refetch, filterChart, dropdownFilters]);

  useEffect(() => {
    if (res !== undefined && chartRefs.current !== null) {
      const map = getMap();
      for (const [key, value] of map) {
        const node = value;
        const filters = getFilters();
        const fields = filters.get(key);
        const { eventHandler, dataKey, chartKey } = fields;

        const eventHandlerFunc = (e) => {
          const keyValue = getQueryKeyValue(
            e,
            eventHandler,
            dataKey,
            chartKey,
            fields
          );
          if (keyValue === filterChart) {
            setFilterChart("");
          } else {
            setFilterChart(keyValue);
          }
        };
        node.chart.services.events.addEventListener(
          eventHandler,
          eventHandlerFunc
        );
      }
    }
  }, [res, filterChart]);

  function getMap() {
    if (!chartRefs.current) {
      chartRefs.current = new Map();
    }
    return chartRefs.current;
  }

  function getFilters() {
    if (!filterFields.current) {
      filterFields.current = new Map();
    }
    return filterFields.current;
  }

  const getFilterDropdowns = (filters) => {
    if (filterArray.length === 0) {
      setFilterArray(filters);
    }
    return (
      <DropdownFilters
        filters={filterArray}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        dropdownFilters={dropdownFilters}
        setDropdownFilters={setDropdownFilters}
      />
    );
  };

  // const isCommomFilter = (filterChart, dropdownFilters) => {
  //   const stringsArray = filterChart.split(",");

  //   // Filter the array to get only keys
  //   const keysStrings = stringsArray.filter((_, index) => index % 2 === 0);
  //   const dropdownKeys = Object.keys(dropdownFilters);
  //   const intersection = keysStrings.filter((element) =>
  //     dropdownKeys.includes(element)
  //   );
  //   return intersection.length > 0 ? true : false;
  // };

  const getData = () => {
    if (isLoading || loading || (isFetching && isError)) {
      return <InlineLoader description="Please wait..." status="active" />;
    }
    if (isError) {
      return <ErrorRefresh refresh={refetch} />;
    }

    if (res.data.charts.length > 0) {
      const filters = res.data?.filters || [];
      return (
        <>
          {filterChart ? (
            <Filters filters={filterChart} close={() => setFilterChart("")} />
          ) : null}
          {filters.length > 0 ? getFilterDropdowns(filters) : null}
          <Row className="pl-05 pr-05">
            {res.data.tiles && res.data.tiles.length > 0 && (
              <>
                {res.data.tiles.map((tile, index) => {
                  return (
                    <>
                      {tile.data.map((dataObject, index) => {
                        return (
                          <Column
                            lg={tile["col-size"]}
                            md={2}
                            sm={2}
                            key={`${dataObject.title}-${dataObject.value}`}
                            className="pl-05 pr-05"
                          >
                            <Tile>
                              <h5 className="tile-header">
                                {t(`${dataObject.title}`)}
                              </h5>
                              <h3 className="tile-value">{dataObject.value}</h3>
                            </Tile>
                          </Column>
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
            {res.data.inference && res.data.inference.length > 0 && (
              <>
                {res.data.inference.map((inf, index) => {
                  return (
                    <>
                      {inf.data.map((dataObject, index) => {
                        return (
                          <Column
                            lg={inf["col-size"]}
                            md={4}
                            sm={4}
                            key={`${dataObject.title}-${dataObject.value}`}
                            className="pl-05 pr-05"
                          >
                            <Tile className="inference-tile-container">
                              <h5 className="inf-title">{t(`ITControlTower.${dataObject.title}`)}</h5>
                              <h3 className="text-description">
                                {dataObject.value}
                              </h3>
                            </Tile>
                          </Column>
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
          </Row>
          <Row className="pl-05 pr-05">
            {res.data.charts.map((res, index) => {
              return (
                <Column
                  lg={res["col-size"]}
                  md={4}
                  sm={4}
                  key={uuidv4()}
                  className="pl-05 pr-05"
                >
                  {res.data && (
                    <ErrorBoundary fallback={"Data is unavailable"}>
                      {res.data.chartData.length === 0 ? (
                        <h4 className="empty--message">No Data Available</h4>
                      ) : (
                        <>
                          <ChartContainer
                            isFetching={isFetching}
                            data={setChartOptions(res)}
                            chartRef={
                              res.refParameters
                                ? (node) => {
                                    const map = getMap();
                                    const filters = getFilters();
                                    if (node) {
                                      map.set(
                                        res.refParameters.uniqueKey,
                                        node
                                      );
                                      filters.set(
                                        res.refParameters.uniqueKey,
                                        res.refParameters
                                      );
                                    } else {
                                      map.delete(res.refParameters.uniqueKey);
                                      filters.delete(
                                        res.refParameters.uniqueKey
                                      );
                                    }
                                  }
                                : null
                            }
                          />
                        </>
                      )}
                    </ErrorBoundary>
                  )}
                </Column>
              );
            })}
          </Row>
        </>
      );
    } else {
      return <ErrorRefresh refresh={refetch} />;
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      {tabName === "Probability Analysis" && (
        <ProbabilityAnalysisOverview queryKey={forceDirectedQueryKey} />
      )}
      {getData()}
    </ErrorBoundary>
  );
};

export default Summary;

