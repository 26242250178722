import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React from "react";
import ClusterIssuesApi from "./IssuesAPICall";

const ClusterIssuesWrapper = () => {
  return (
    <FlexGrid>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/security">Security</BreadcrumbItem>
            <BreadcrumbItem href="#/twistlock">
              Container Security
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Issues</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <ClusterIssuesApi hirarchy=""/>
        </Column>
      </Row>
    </FlexGrid>
  );
};

export default ClusterIssuesWrapper;
