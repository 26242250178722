import React from "react";
import { InlineNotification } from "@carbon/react";

const ErrorNotify = (props) => {
  return (
    <InlineNotification
      aria-label="closes notification"
      kind={props.kind ? props.kind : "error"}
      onClose={function noRefCheck() {}}
      onCloseButtonClick={function noRefCheck() {}}
      statusIconDescription="notification"
      subtitle={props.subtitle}
      title={props.title}
      hideCloseButton={props.hideCloseButton}
    />
  );
};

export default ErrorNotify;
