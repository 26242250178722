import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CarbonDataTable from "Carbon-Components/DataTable";
import TilesLoader from "../TilesLoader";
import ErrorFallback from "Common-Modules/ErrorFallback";
import {
  convertDate,
  convertEpochToDateTime,
  convertUTCDate,
} from "Common-Modules/ConvertUTCDate";
import { Modal } from "@carbon/react";
import MailContent from "./MailContent";

const Tasks = ({ asset, cluster, title }) => {
  const [isCalculating, setIsCalculating] = useState(true);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [mailData, setMailData] = useState([]);
  const { data: taskDetails } = useQuery(["citi_task"]);

  const headers = [
    {
      key: "task_id",
      header: "Task Id",
    },
    {
      key: "session_id",
      header: "Session Id",
    },
    {
      key: "host",
      header: "Host",
    },
    {
      key: "application",
      header: "Application",
    },
    {
      key: "service",
      header: "Service",
    },
    {
      key: "timestamp",
      header: "Reported Date",
    },
    {
      key: "reason",
      header: "Action Taken",
    },
  ];

  useEffect(() => {
    try {
      const detailsCopy = JSON.parse(JSON.stringify(taskDetails.data));
      const rowData = detailsCopy
        .filter(
          (session) =>
            session.asset_class === asset && session.cluster[0] === cluster,
        )
        .map((session, index) => {
          session.id = `${session.task_id}-${index}`;
          const date = convertEpochToDateTime(session.timestamp);
          const formattedDate = convertDate(
            "dd-mm-yy",
            convertUTCDate(date),
            "created",
          );
          session.timestamp = formattedDate;
          return session;
        });
      setData(rowData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, []);

  const onselectRow = (e, row) => {
    const filteredRow = data.filter((selected) => selected["task_id"] === row["cells"][0].value);
    if (e === false) {
      setMailData(prevItems => prevItems.filter((sel) => sel["task_id"] !== row["cells"][0].value));
    } else {
      setMailData(prevItems => [...prevItems, ...filteredRow]);
    }
  }

  const sendMailfunc = () => {
    if (mailData.length === 0) {
      setMailData(data)
    }
    setSendMail(true)
  }

  const getRowCellData = (id, data, row) => {
    return data;
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    <>
      <CarbonDataTable
        rowData={data}
        headerData={headers}
        title="Failed Tasks"
        getRowCellData={getRowCellData}
        getTableHeader={(header) => header}
        actionsNeeded={false}
        mailBtn={true}
        createTitle="Send Mail"
        headerSelection={true}
        rowSelection={true}
        onselectRow={onselectRow}
        createFunction={() => sendMailfunc()}
      />
      {sendMail ?
        <Modal
          open
          passiveModal
          modalHeading="Send Mail"
          onRequestClose={() => setSendMail(false)}
          // className={props.className}
          size="lg"
        >
          <MailContent asset={asset} cluster={cluster} rowData={mailData} title={title} />
        </Modal>
        : null
      }
    </>
  );
};

export default Tasks;
