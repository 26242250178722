import { TextInput } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import DefaultModal from "Carbon-Components/Modals/CommonModal";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import { checkAppDupilicates } from "Services/ServerApi";
// import DefaultModal from "Carbon-Components/Modal/Modal";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useState } from "react";
// import { checkAppDupilicates, updateAppDetails } from "services/serverapi";
import { v4 as uuidv4 } from "uuid";

const EditUser = ({ app, cancel }) => {
  const [newName, setNewName] = useState(app.name);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { keycloak } = useKeycloak();

  const re = new RegExp("/^[a-zA-Z\\d\\-_]*$/".slice(1, -1));

  const onSubmit = async () => {
    // setRefresh(false);
    // setIsLoading(true);
    // const data = {
    //   name: newName.replace(/\s+$/, ""),
    // };
    // const res = await updateAppDetails(
    //   keycloak,
    //   app.id,
    //   data,
    //   uuidv4(),
    // );
    // if (res?.data?.name === newName.replace(/\s+$/, "")) {
    //   setResMsg("Application updated Successfully.");
    //   setRefresh(true);
    // } else {
    //   setResMsg("Failed to update Application.");
    // }
    // setIsLoading(false);
  };

  const checkDupilicate = async () => {
    setDuplicate(false);
    setIsLoading(true);
    const res = await checkAppDupilicates(
      keycloak,
      uuidv4(),
      newName.replace(/\s+$/, ""),
    );
    if (res?.duplicate === false) {
      setDuplicate(false);
      onSubmit();
    } else {
      setDuplicate(newName + " exists, try different name.");
      setIsLoading(false);
    }
  };

  if (resMsg) {
    return (
      <PassiveModal canCloseDetailsPopup={() => cancel(refresh)}>
        <h4>{resMsg}</h4>
      </PassiveModal>
    );
  }

  return (
    <DefaultModal
      heading="Edit"
      primaryText="Submit"
      primaryButtonDisabled={
        isLoading || !re.test(newName.replace(/\s+$/, "")) || newName.length < 3
      }
      onModalSubmit={() => checkDupilicate()}
      onCloseModal={() => cancel(false)}
    >
      {isLoading ? (
        <InlineLoader status="active" description="updating user..." />
      ) : (
        <TextInput
          id="appName"
          labelText="Application Name"
          placeholder="Enter Application Name"
          invalid={!re.test(newName.replace(/\s+$/, "")) || newName.length < 3}
          invalidText="Text cannot contain special characters other than - and _ and min length of 3"
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          maxLength={30}
        />
      )}
      {duplicate ? <h6 className="error">{duplicate}</h6> : null}
    </DefaultModal>
  );
};

export default EditUser;
