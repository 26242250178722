import React from "react";
import {
    Column,
    ErrorBoundary,
    Row,
} from "@carbon/react";
import ErrorFallback from "Common-Modules/ErrorFallback";
import AppScanOverview from "./appScanDashboard";

const DASTDashboards = () => {
    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <AppScanOverview queryKey={["appscan_dashboards"]} />
                </Column>
            </Row>
        </ErrorBoundary>
    );
};

export default DASTDashboards;
