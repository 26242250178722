import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  FlexGrid,
  Row,
} from "@carbon/react";
import ErrorFallback from "Components/Common/ErrorFallback";
import Overview from "./Overview";
import { useTranslation } from 'react-i18next';

const IncidentAnalyticsReports = () => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <FlexGrid>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              {/* <BreadcrumbItem href="#/">{t(`breadcrumb.home`)}</BreadcrumbItem>
              <BreadcrumbItem href="#/itControlTower">
              {t(`breadcrumb.ITControlTower`)} */}
              {/* </BreadcrumbItem> */}
              {/* <BreadcrumbItem isCurrentPage>{t(`breadcrumb.IncidentAnalyticsReports`)}</BreadcrumbItem> */}
            </Breadcrumb>
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <Overview />
          </Column>
        </Row>
      </FlexGrid>
    </ErrorBoundary>
  );
};

export default IncidentAnalyticsReports;
