import { ProgressIndicator, ProgressStep, Tag } from '@carbon/react';
import React, { useState } from "react";

const BizopsProgress = (props) => {
    const [progressLine] = useState([
        {
            day: [],
            progress: "complete",
            title: {
                title: "Awareness of Need",
                value: 100.00
            },
            label: []
        },
        {
            day: [],
            progress: "complete",
            title: {
                title: "Pre-Qualification",
                value: 98.29
            },
            label: []
        },
        {
            day: ["06"],
            progress: "error",
            title: {
                title: "Application Process",
                value: 97.78
            },
            label: [{
                "title": "Apply Online",
                "value": 98.23
            }, {
                "title": "Submit Application",
                "value": 96.16
            }]
        },
        {
            day: [],
            progress: "complete",
            title: {
                title: "Decision and Approval",
                value: 99.20
            },
            label: []
        },
        {
            day: [],
            progress: "complete",
            title: {
                title: "Onboarding",
                value: 98.99
            },
            label: []
        }]);


    const DrawProgress = () => {
        return progressLine.map((flow) => {
            return <ProgressStep
                label={
                    <p title={flow.title.title}>{flow.title.title} <span>{flow.title.value}<span className='pt'>%</span></span></p>
                }
                secondaryLabel={
                    flow.label.map((label) => <p title={flow.title.title}>{label.title}<span>{label.value}<span className='pt'>%</span> <Tag className="notify-tag" type={label.value > 98 ? "green" : "red"}>
                    {label.value > 98 ? "Healthy" : "Error"}
                    </Tag></span></p>
                )}
                complete={flow.progress === "complete"}
                invalid={flow.progress === "error"}
                current={flow.progress === "current"}
            />
        })
    }

    return (
        <>
            <div className="vs-progressBar">
                <ProgressIndicator vertical spaceEqually
                    onChange={(e) => {
                        props.enableTopology(e);
                    }}>
                    {DrawProgress()}
                </ProgressIndicator>
            </div>
        </>
    )
}

export default BizopsProgress;