/** @format */

import { DonutChart, SimpleBarChart } from "@carbon/charts-react";
import {
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    ErrorBoundary,
    Grid,
    InlineLoading,
    Layer,
    Modal,
    SkeletonPlaceholder,
    Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useRef, useState } from "react";

import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import { getAutoPatcherEvents } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import AutoPatcherDetails from "./AutoPatcherDetails";
// import "./../css/_chart.scss";

const autoPatcherHeaderData = [
    {
        key: "patching_event_name",
        header: "Name",
    },
    {
        key: "patching_event_id",
        header: "ID",
    },
    {
        key: "patching_event_start_date",
        header: "Start Date",
    },
    {
        key: "patching_event_end_date",
        header: "End Date",
    },
    {
        key: "event_patching_status",
        header: "Status",
    },
];

let eventStatusCircleChartFilter = null;
let instanceStatusCircleChartFilter = null;
let eventNameBarChartFilter = null;
let eventNameBarChartLegendFilter = null;

const AutoPatcherReport = () => {
    const eventStatusCircleChartRef = useRef(null);
    const instanceStatusCircleChartRef = useRef(null);
    const eventNameBarChartRef = useRef(null);
    const eventNameBarChartLegendRef = useRef(null);

    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [APIData, setAPIData] = useState("");
    const [errMessage, setErrorMessage] = useState("");

    const [startDate, setStartDate] = useState("2022-09-13 00:00");
    const [endDate, setEndDate] = useState(
        new Date().toISOString().split("T")[0] + " 00:00"
    );

    const [apiLoading, setApiLoading] = useState(false);
    const [showPopUP, setShowPopUP] = useState(false);
    const [eventDetailsRow, setEventDetailsRow] = useState();
    const [APIDataCall, setAPIDataCall] = useState(false);

    const [eventStatusCircleChartLoading, setEventStatusCircleChartLoading] =
        useState(false);
    const [
        instanceStatusCircleChartLoading,
        setInstanceStatusCircleChartLoading,
    ] = useState(false);
    const [eventNameBarChartLoading, setEventNameBarChartLoading] =
        useState(false);

    const { keycloak } = useKeycloak();
    const context = useContext(Context);

    useEffect(() => {
        getAPIData(startDate, endDate, null);
    }, []);

    useEffect(() => {
        setEventListerners();
    }, [
        eventStatusCircleChartRef,
        instanceStatusCircleChartRef,
        eventNameBarChartRef,
        eventNameBarChartLegendRef,
    ]);

    const setEventListerners = () => {
        if (eventStatusCircleChartRef.current) {
            eventStatusCircleChartRef.current.chart.services.events.addEventListener(
                "pie-slice-click",
                eventStatusCircleChartOnClick
            );
        }
        if (instanceStatusCircleChartRef.current) {
            instanceStatusCircleChartRef.current.chart.services.events.addEventListener(
                "pie-slice-click",
                instanceStatusCircleChartOnClick
            );
        }
        if (eventNameBarChartRef.current) {
            eventNameBarChartRef.current.chart.services.events.addEventListener(
                "bar-click",
                eventNameBarChartOnClick
            );
            eventNameBarChartRef.current.chart.services.events.addEventListener(
                "legend-item-onclick",
                eventNameBarChartLegendOnClick
            );
        }
    };

    const eventStatusCircleChartOnClick = ({ detail }) => {
        if (
            eventStatusCircleChartFilter &&
            eventStatusCircleChartFilter === detail.datum.data.group
        ) {
            setEventStatusCircleChartLoading(true);
            getAPIData(startDate, endDate, null);
        } else {
            eventStatusCircleChartFilter = detail.datum.data.group;
            setEventStatusCircleChartLoading(true);
            getAPIData(
                startDate,
                endDate,
                detail.datum.data.group,
                "EventPatchingStatus"
            );
        }
    };

    const instanceStatusCircleChartOnClick = ({ detail }) => {
        if (
            instanceStatusCircleChartFilter &&
            instanceStatusCircleChartFilter === detail.datum.data.group
        ) {
            setInstanceStatusCircleChartLoading(true);
            getAPIData(startDate, endDate, null);
        } else {
            instanceStatusCircleChartFilter = detail.datum.data.group;
            setInstanceStatusCircleChartLoading(true);
            getAPIData(
                startDate,
                endDate,
                detail.datum.data.group,
                "InstancePatchingStatus"
            );
        }
    };

    const eventNameBarChartOnClick = ({ detail }) => {
        if (
            eventNameBarChartFilter &&
            eventNameBarChartFilter === detail.datum.group
        ) {
            setEventNameBarChartLoading(true);
            getAPIData(startDate, endDate, null);
        } else {
            eventNameBarChartFilter = detail.datum.group;
            setEventNameBarChartLoading(true);
            getAPIData(startDate, endDate, detail.datum.group, "PatchingEventName");
        }
    };

    const eventNameBarChartLegendOnClick = ({ detail }) => {
    };

    const getDetailsRow = (details, detailsId) => {
        let detailRow = {};
        details.forEach((row) => {
            if (row.id === detailsId) {
                detailRow = row;
                return;
            }
        });
        return detailRow;
    };

    const onClickNode = (id) => {
        let row = getDetailsRow(APIData.data.data[3].data.rowData, id);
        setEventDetailsRow(row);
        setShowPopUP(true);
    };

    const getPopupData = () => {
        return <AutoPatcherDetails details={eventDetailsRow} />;
    };

    // const getTextBackgroundColor = (text) => {
    //     switch (text) {
    //         case "Success":
    //             return "green";
    //         case "Not Approved":
    //             return "gray";
    //         case "Failed":
    //             return "red";
    //         case "Partial Success":
    //             return "orange";
    //         case "Not Applicable":
    //             return "gray";
    //     }
    // };

    const getStatusPhrase = (status) => {
        switch (status) {
            case "DONE_ERROR":
                return "Event executed successfully, but patching failed";
            case "DONE_PARTIAL":
                return "Event executed successfully, but one instance patching failed";
            case "DONE":
                return "Event executed successfully and all patchings succeeded";
            case "NOT_APPROVED":
                return "Event not approved for patching";
        }
    };

    const getRowCellData = (id, data, row) => {
        if (id.includes("event_patching_status")) {
            return getStatusPhrase(data);
        }
        if (id.includes("patching_event_name")) {
            return (
                <a
                    className="event-status"
                    onClick={() => {
                        onClickNode(row.id);
                    }}
                >
                    {data}
                </a>
            );
        }
        if (
            id.includes("patching_event_start_date") ||
            id.includes("patching_event_end_date")
        ) {
            return String(new Date(data).toLocaleString());
        }
        return data;
    };

    const getTableHeader = (header) => {
        return header;
    };

    const getChartLoader = () => {
        return (
            <Layer className="chart-loader__layer">
                <InlineLoading
                    status="active"
                    iconDescription="Loading"
                    description="Loading data..."
                />
            </Layer>
        );
    };

    const getAPIData = async (sDate, eDate, filter, filterName) => {
        if (filter === null) {
            eventStatusCircleChartFilter = null;
            instanceStatusCircleChartFilter = null;
            eventNameBarChartFilter = null;
            eventNameBarChartLegendFilter = null;
        }
        const response = await getAutoPatcherEvents(
            keycloak.token,
            sDate,
            eDate,
            filter,
            filterName
        );
        AddUserActivity(keycloak, context, "FEATURE", "Patch Event Insights", response);

        setIsAPIDataLoading(false);
        setApiLoading(false);

        setEventStatusCircleChartLoading(false);
        setInstanceStatusCircleChartLoading(false);
        setEventNameBarChartLoading(false);

        setErrorMessage(updateErrorMessage(response));

        setAPIData();
        setAPIData(updateAPIData(response));
        if (APIDataCall === false) {
            setEventListerners();
            setAPIDataCall(true);
        }
    };

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-") + " 00:00";
    };

    const onStartDateChange = (e) => {
        setStartDate(formatDate(new Date(e[0]).toDateString()));
        if (e.length > 1) {
            setEndDate(formatDate(new Date(e[1]).toDateString()));
        }
    };

    const updateAPI = async () => {
        setApiLoading(true);
        await getAPIData(startDate, endDate, null);
    };

    const getHeadingAndDateFilter = () => {
        return (
            <>
                <Column lg={8}>
                    <h3>{APIData.data.label}</h3>
                </Column>
                <Column lg={8} className="flex-display flex-end override-form-flex">
                    <ErrorBoundary fallback={"Data is unavailable"}>
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="range"
                            onChange={(e) => onStartDateChange(e)}
                            className="date-picker--margin"
                        >
                            <DatePickerInput
                                id="date-picker-range-start"
                                placeholder="yyyy-mm-dd"
                                labelText="Start Date"
                                defaultValue="2022-09-13"
                                type="text"
                            />
                            <DatePickerInput
                                id="date-picker-range-end"
                                placeholder="yyyy-mm-dd"
                                labelText="End Date"
                                defaultValue={new Date().toISOString().split("T")[0]}
                                type="text"
                            />
                        </DatePicker>
                    </ErrorBoundary>
                    <span>
                        {apiLoading ? (
                            <InlineLoading
                                status="active"
                                iconDescription="Loading"
                                description="Loading data..."
                                className="report-update__button"
                            />
                        ) : (
                            <Button
                                kind="primary"
                                onClick={updateAPI}
                                size="md"
                                iconDescription="Icon Description"
                                className="report-update__button"
                            >
                                Update
                            </Button>
                        )}
                    </span>
                </Column>
            </>
        );
    };

    const createChartExplicit = () => {
        return (
            <>
                {getHeadingAndDateFilter()}
                {(APIData.data.data[0].data.chartData &&
                    APIData.data.data[0].data.chartData.length > 0) ||
                    (APIData.data.data[1].data.chartData &&
                        APIData.data.data[1].data.chartData.length > 0) ||
                    (APIData.data.data[2].data.chartData &&
                        APIData.data.data[2].data.chartData.length > 0) ? (
                    <>
                        <Column lg={4} md={8} sm={4}>
                            <Tile className="chart-container">
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[1].data.chartData &&
                                        APIData.data.data[1].data.chartData.length > 0 ? (
                                        <>
                                            {instanceStatusCircleChartLoading ? getChartLoader() : ""}
                                            <DonutChart
                                                ref={instanceStatusCircleChartRef}
                                                data={APIData.data.data[1].data.chartData}
                                                options={APIData.data.data[1].data.chartOptions}
                                            ></DonutChart>
                                        </>
                                    ) : (
                                        "Data is unavailable"
                                    )}
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={12} md={8} sm={4}>
                            <Tile className="chart-container">
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[2].data.chartData &&
                                        APIData.data.data[2].data.chartData.length > 0 ? (
                                        <>
                                            {eventNameBarChartLoading ? getChartLoader() : ""}
                                            <SimpleBarChart
                                                ref={eventNameBarChartRef}
                                                data={APIData.data.data[2].data.chartData}
                                                options={APIData.data.data[2].data.chartOptions}
                                            ></SimpleBarChart>
                                            <p className="chart-description">
                                                * Patch plans that have zero instances are not
                                                represented in the graph.
                                            </p>
                                        </>
                                    ) : (
                                        "Data is unavailable"
                                    )}
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={16} className="common-top-margin">
                            {APIData.data.data[3].data.rowData &&
                                APIData.data.data[3].data.rowData.length > 0 ? (
                                <ErrorBoundary fallback={<ErrorWhileFetching />}>
                                    <CarbonDataTable
                                        rowData={APIData.data.data[3].data.rowData}
                                        headerData={autoPatcherHeaderData}
                                        title={APIData.data.data[3].label}
                                        getRowCellData={getRowCellData}
                                        getTableHeader={getTableHeader}
                                        actionsNeeded={false}
                                    />
                                </ErrorBoundary>
                            ) : (
                                "Data is unavailable!"
                            )}
                        </Column>
                    </>
                ) : (
                    <Column lg={16} md={8} sm={4}>
                        <DataUnAvailable />
                    </Column>
                )}
            </>
        );
    };

    const loadSkeleton = () => {
        return isAPIDataLoading ? (
            <div className="skeletonPlaceholder--it-health">
                <SkeletonPlaceholder />
            </div>
        ) : (
            <ErrorWhileFetching errMessage={errMessage} />
        );
    };

    return (
        <div className="container-multichart">
            {APIData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
            {showPopUP ? (
                <Modal
                    open
                    size="md"
                    passiveModal
                    onRequestClose={() => setShowPopUP(false)}
                    modalHeading="Patch Instances Details"
                >
                    {getPopupData()}
                </Modal>
            ) : (
                ""
            )}
        </div>
    );
};

export default AutoPatcherReport;
