import {
    Column,
    ErrorBoundary,
    ListItem,
    Row,
    Tile,
    UnorderedList
  } from "@carbon/react";
  import { SquareFill } from "@carbon/react/icons";
  import { useKeycloak } from "@react-keycloak/web";
  import { useQuery } from "@tanstack/react-query";
  import TooltipCarbon from "Carbon-Components/Tooltip";
  import { setChartOptions } from "Common-Modules/Utilities";
  import ChartContainer from "Carbon-Charts/ChartContainer";
  import { getQueryKeyValue } from "Carbon-Charts/Utilities/EventHandlers";
  import ErrorFallback from "Common-Modules/ErrorFallback";
  import ErrorRefresh from "Common-Modules/ErrorRefresh";
  import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
  import Filters from "Components/ICTE/IncidentReports/filters";
  import React, { useEffect, useRef, useState } from "react";
  import { useTranslation } from 'react-i18next';
  import { getAppScanData } from "Services/ServerApi";
  import DataTable from "Carbon-Components/DataTable";
  import "../securityOps.scss";
  import { v4 as uuidv4 } from "uuid";
  
  
  const AppScanOverview = ({ queryKey }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [filterChart, setFilterChart] = useState("");
    const [rowData, setRowData] = useState([]);
    const { keycloak } = useKeycloak();
    const chartRefs = useRef(null);
    const filterFields = useRef(new Map());
    const { t } = useTranslation();
  
    const onSettled = () => {
      setIsLoading(false);
    };
  
    const {
      isLoading: loading,
      data: res,
      isFetching,
      isError,
      refetch,
    } = useQuery(
      queryKey,
      () => getAppScanData(keycloak, uuidv4(), "appscan", filterChart),
      {
        retry: 1,
        enabled: false,
        onSettled,
      }
    );
  
    useEffect(() => {
      refetch();
    }, [refetch, filterChart]);
  
    useEffect(() => {
      if (res !== undefined && chartRefs.current !== null) {
        const map = getMap();
        for (const [key, value] of map) {
          const node = value;
          const filters = getFilters();
          const fields = filters.get(key);
          const { eventHandler, dataKey, chartKey } = fields;
  
          const eventHandlerFunc = (e) => {
            const keyValue = getQueryKeyValue(
              e,
              eventHandler,
              dataKey,
              chartKey,
              fields
            );
            if (keyValue === filterChart) {
              setFilterChart("");
            } else {
              setFilterChart(keyValue);
            }
          };
          node.chart.services.events.addEventListener(
            eventHandler,
            eventHandlerFunc
          );
        }
      }
    }, [res, filterChart]);
  
    function getMap() {
      if (!chartRefs.current) {
        chartRefs.current = new Map();
      }
      return chartRefs.current;
    }
  
    function getFilters() {
      if (!filterFields.current) {
        filterFields.current = new Map();
      }
      return filterFields.current;
    }
  
    const getRowCellData = (id, data, row) => {
      try {
        if (data.length <= 65) {
          return data;
        } else {
          let temp = data.substring(0,65) + "...";
          return (
            <div className="font-change">
              <TooltipCarbon description={data}>{temp}</TooltipCarbon>
            </div>
          );
        }
      } catch (err) {
        console.log(err);
      }
      return data;
    };
  
    const getTableHeader = (header) => {
      return header.toUpperCase();
    };
  
    const getData = () => {
      if (isLoading || loading || (isFetching && isError)) {
        return <InlineLoader description="Please wait..." status="active" />;
      }
  
      if (isError) {
        return <ErrorRefresh refresh={refetch} />;
      }
  
      if (res.data.charts.length > 0) {
        let chartData = res.data.charts.filter(
          (fil) => fil.dataType !== "treeD3" && fil.dataType !== "dataTableInsideChart"
        );
  
        // let treeMapData = res.data.charts.filter(
        //   (fil) => fil.dataType === "treeD3"
        // );
  
        let tableData = res.data.charts.filter(
          (fil) => fil.dataType === "dataTableInsideChart"
        );
  
        const HEADER_DATA = tableData[0].data.fields.map(data => ({
          key: data,
          header: data
        }))
  
        let colorsArray = [
          "#6929c4",
          "#1192e8",
          "#005d5d",
          "#9f1853",
          "#fa4d56",
          "#570408",
          "#198038",
          "#002d9c",
          "#ee538b",
          "#b28600",
        ];
  
        chartData = chartData.map((chart) => {
          let colors = {};
          chart.data.chartData.forEach((data, index) => {
            colors[data.group] = colorsArray[index];
          });
          chart.data.chartOptions.color.scale = colors;
          return chart;
        });
  
        return (
          <>
            <Row>
              <Column lg={8}
                md={4}
                sm={4}
                className="pl-05 pr-05">
                <Tile>
                  <h4>{t(`SecurityHub.Application`)}: {res.data.projectname}</h4>
                </Tile>
              </Column>
              <Column lg={8}
                md={4}
                sm={4}
                className="pl-05 pr-05">
                {filterChart ? (
                  <Filters filters={filterChart} close={() => setFilterChart("")} />
                ) : null}
              </Column>
            </Row>
            <Row className="pl-05 pr-05">
              {chartData.map((res, index) => {
                return (
                  <Column
                    lg={res["col-size"]}
                    md={4}
                    sm={4}
                    key={index}
                    className="pl-05 pr-05"
                  >
                    {res.data && (
                      <ErrorBoundary fallback={"Data is unavailable"}>
                        {res.data.chartData.length === 0 ? (
                          <h4 className="empty--message">No Data Available</h4>
                        ) : (
                          <>
                            <ChartContainer
                              isFetching={isFetching}
                              data={setChartOptions(res)}
                              chartRef={
                                res.refParameters
                                  ? (node) => {
                                    const map = getMap();
                                    const filters = getFilters();
                                    if (node) {
                                      map.set(
                                        res.refParameters.uniqueKey,
                                        node
                                      );
                                      filters.set(
                                        res.refParameters.uniqueKey,
                                        res.refParameters
                                      );
                                    } else {
                                      map.delete(res.refParameters.uniqueKey);
                                      filters.delete(
                                        res.refParameters.uniqueKey
                                      );
                                    }
                                  }
                                  : null
                              }
                            />
                            <Tile
                              style={{ marginTop: "0" }}
                              className="appscan-manual-legends"
                            >
                              <UnorderedList>
                                {res.data.chartData.map((ticket) => {
                                  return (
                                    <ListItem style={{ textDecoration: "none" }}>
                                      <div className="flex-space-between">
                                        <div
                                          className="flex-display"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          <SquareFill
                                            className="legend-svg"
                                            fill={
                                              res.data.chartOptions.color.scale[
                                              ticket.group
                                              ]
                                            }
                                            size={16}
                                          />
                                          <h4 className="legend-name">
                                            {ticket.group}
                                          </h4>
                                          <h6 className="legend-value">
                                            {ticket.value}
                                          </h6>
                                        </div>
                                      </div>
                                    </ListItem>
                                  );
                                })}
                              </UnorderedList>
                            </Tile>
                          </>
                        )}
                      </ErrorBoundary>
                    )}
                  </Column>
                );
              })}
            </Row>
            {tableData[0].data.chartData.length > 0 ?
              <Row className="pl-05 pr-05">
                <DataTable
                  rowData={tableData[0].data.chartData}
                  headerData={HEADER_DATA}
                  getRowCellData={getRowCellData}
                  getTableHeader={getTableHeader}
                  actionsNeeded={false}
                ></DataTable>
              </Row> : null}
          </>
        );
      } else {
        <ErrorRefresh refresh={refetch} />;
      }
    };
  
    return (
      <ErrorBoundary fallback={<ErrorFallback />}>{getData()}</ErrorBoundary>
    );
  };
  
  export default AppScanOverview;
  