export const setChartOptions = (data) => {
  if (data.aggregationType !== "Percentage") {
    return data;
  }
  const axes = data.data.chartOptions.axes;
  Object.entries(axes).forEach(([key, value]) => {
    if (value.add_percenatage) {
      axes[key]["ticks"]["formatter"] = (n) => `${n}%`;
    }
  });
  return data;
};

export const capitalize = (inputString) => {
  const lowerCase = inputString.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const isValidDate = (dateString) => {
  let date = new Date(dateString);
  return !isNaN(date.getTime());
};

export const authorizationValidity = (authority, userAuthority) => {
  return userAuthority.includes(authority);
};

export const authorizationValidityTest = (authority, userAuthority) => {
  if (userAuthority) {
    const { length } = userAuthority;
    for (let i = 0; i < length; i += 1) {
      if (userAuthority[i] === authority) {
        return "";
      }
    }
    return "not-authorized";
  }
  return "not-authorized";
};

export const isValidUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
  } catch (error) {
    return false;
  }
};
