import { Column, ErrorBoundary, Grid } from "@carbon/react";
import CarbonDataTable from "Carbon-Components/DataTable";
import ErrorFallback from "Common-Modules/ErrorFallback";
// import CarbonDataTable from "Components/Common/CarbonDataTable";
// import ErrorFallback from "Components/Common/ErrorFallback";
import React from "react";

const RolesListTable = ({ rowData, name }) => {
  const getHeaderData = () => {
    return [
      {
        key: "orgName",
        header: "Organization",
      },
      {
        key: "role",
        header: "Roles",
      },
    ];
  };

  const getTableHeader = (header) => {
    return header;
  };

  const getRowCellData = (id, data, row) => {
    if(id.includes(":role")){
      if(data.length === 1){
        return data[0]
      }
      return data.join(", ")
    }
    return data;
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <CarbonDataTable
            rowData={rowData}
            headerData={getHeaderData()}
            title={`${name} roles`}
            getRowCellData={getRowCellData}
            getTableHeader={getTableHeader}
            showRefreshIcon={true}
            placeholder="Search by organization name or role ..."
          />
        </Column>
      </Grid>
    </ErrorBoundary>
  );
};

export default RolesListTable;
