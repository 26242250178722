import { ErrorBoundary, Row } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import ErrorFallback from "Common-Modules/ErrorFallback";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VSClickableTile from "./vsClickabletile";

const VSDashboard = () => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const [valueStreamData, setValueStream] = useState();

    const {
        data: valueStream,
        isLoading: loading1,
        isError: err1,
        isFetching: fetching1,
    } = useQuery(
        [`valueStream`],
        async () => await getAnyCollection(keycloak, "valueStream"),
        {
            cache: queryCache,
            cacheTime: 60 * 60 * 1000,
        },
    );

    useEffect(() => {
        if (valueStream !== undefined && valueStream.data !== undefined) {
            const response = valueStream.data.valueStream;
            setValueStream(response[0].data)
        }
    }, [valueStream])

    const handleOnClick = (data) => {
        sessionStorage.setItem("portfolioTitle", data.title);
        history.push({ pathname: `workflowView/${data.title}` })
    }

    return (
        <Row>
            <ErrorBoundary fallbakc={<ErrorFallback />}>
                <VSClickableTile Json={valueStreamData} handleOnClick={handleOnClick} />
            </ErrorBoundary>
        </Row>
    )
}

export default VSDashboard;