import { Modal } from "@carbon/react";
import { useQuery } from "@tanstack/react-query";
import CarbonDataTable from "Carbon-Components/DataTable";
import {
  convertDate,
  convertEpochToDateTime,
  convertUTCDate,
} from "Common-Modules/ConvertUTCDate";
import ErrorFallback from "Common-Modules/ErrorFallback";
import React, { useEffect, useState } from "react";
import TilesLoader from "../TilesLoader";
import MailContent from "./MailContent";
import SessionDetails from "./SessionDetails";

const Sessions = ({ asset, cluster, title }) => {
  const [isCalculating, setIsCalculating] = useState(true);
  const [dataTable, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [mailData, setMailData] = useState([]);
  const [showSessionMdl, setShowSessionModal] = useState(false);
  const [sessionDet, setSessionDetail] = useState({});
  const { data: sessionDetails } = useQuery(["citi_session"]);

  const headers = [
    {
      key: "session_id",
      header: "Session Id",
    },
    {
      key: "host",
      header: "Host",
    },
    {
      key: "application",
      header: "Job Name",
    },
    {
      key: "service",
      header: "Service",
    },
    {
      key: "timestamp",
      header: "Reported Date",
    },
    {
      key: "reason",
      header: "Action Taken",
    },
  ];

  useEffect(() => {
    try {
      const detailsCopy = JSON.parse(JSON.stringify(sessionDetails.data));
      const rowData = detailsCopy
        .filter(
          (session) =>
            session.asset_class === asset && session.cluster[0] === cluster,
        )
        .map((session, index) => {
          session.id = `${session.session_id}-${index}`;
          const date = convertEpochToDateTime(session.timestamp);
          const formattedDate = convertDate(
            "dd-mm-yy",
            convertUTCDate(date),
            "created",
          );
          session.timestamp = formattedDate;
          return session;
        });
      setData(rowData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, []);

  const onselectRow = (e, row) => {
    const filteredRow = dataTable.filter((selected) => selected["session_id"] === row["cells"][0].value);
    if (e === false) {
      setMailData(prevItems => prevItems.filter((sel) => sel["session_id"] !== row["cells"][0].value));
    } else {
      setMailData(prevItems => [...prevItems, ...filteredRow]);
    }
  }

  const sendMailfunc = () => {
    if (mailData.length === 0) {
      setMailData(dataTable)
    }
    setSendMail(true)
  }

  // const sessionIDClick = (data, row) => {
  //   let hostnm = null;
  //   dataTable.forEach((cell) => {
  //     if(cell.id === row.id){
  //       hostnm = cell.cluster
  //     }

  //   })
  //   //const splitnm = hostnm.split("-");

  //   const getFthree = hostnm[0].slice(0, 3);
  //   const getLast = hostnm[0].charAt(hostnm[0].length - 1);

  //   //CITI_SMP_DAL1

  //   const strData = `CITI_${hostnm[1]}_${getFthree}${getLast}`;

  //   setSessionDetail({
  //     sessionId: data,
  //     clustername: strData.toUpperCase()
  //   })
  //   setShowSessionModal(true)
  // }

  const getRowCellData = (id, data, row) => {

    if(id.includes("session_id")){
      // return <div className="session_link" onClick={() => sessionIDClick(data, row)}>
      //   {data}
      // </div>
      return data
    }
    return data;
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    <>
      <CarbonDataTable
        rowData={dataTable}
        headerData={headers}
        title="Failed Sessions"
        getRowCellData={getRowCellData}
        getTableHeader={(header) => header}
        actionsNeeded={false}
        mailBtn={true}
        createTitle="Send Mail"
        headerSelection={true}
        rowSelection={true}
        onselectRow={onselectRow}
        createFunction={() => sendMailfunc()}
      />
      {sendMail ?
        <Modal
          open
          passiveModal
          modalHeading="Send Mail"
          onRequestClose={() => setSendMail(false)}
          // className={props.className}
          size="lg"
        >
          <MailContent asset={asset} cluster={cluster} rowData={mailData} title={title} />
        </Modal>
        : null
      }
      {showSessionMdl ?
        <Modal
          open
          passiveModal
          modalHeading=""
          onRequestClose={() => setShowSessionModal(false)}
          // className={props.className}
          size="lg"
        >
          <SessionDetails sessionDet={sessionDet}  />
        </Modal>
        : null
      }

    </>
  );
};

export default Sessions;
