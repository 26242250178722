import { Tile } from "@carbon/react";
import * as d3 from "d3";
import React, { useEffect, useState } from "react";

const TreeMap = (props) => {
    const [lastchild, setLastChild] = useState("")

    useEffect(() => {
            showGraph(getTreeMap())
    }, [])

    const getTreeMap = () => {
        const jobsData = {
            name: "",
            children: [
                { name: "Cart", value: 4000, quality: 89},
                { name: "Catalogue", value: 3000, quality: 70},
                { name: "Dispatch", value: 2000, quality: 40},
                { name: "Payment", value: 1500, quality: 80},
                { name: "LoadGen", value: 100, quality: 30},
                { name: "Rating", value: 500, quality: 50},
                { name: "Auth", value: 800, quality: 60}
            ]
        }

        return jobsData;
    }

    const showGraph = (data, flow) => {
        const width = 600;
        const height = 300;
        // This custom tiling function adapts the built-in binary tiling function
        // for the appropriate aspect ratio when the treemap is zoomed-in.
        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
                child.x0 = x0 + child.x0 / width * (x1 - x0);
                child.x1 = x0 + child.x1 / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        //const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

        // Compute the layout.
        const hierarchy = d3.hierarchy(data)
            .count(d => d.value)
            .sort((a, b) => b.value - a.value);
        const root = d3.treemap().tile(tile)(hierarchy);

        // Create the scales.
        const x = d3.scaleLinear().rangeRound([0, width]);
        const y = d3.scaleLinear().rangeRound([0, height]);

        // Formatting utilities.
        const format = d3.format(",d");
        const name = d => d.ancestors().reverse().map(d => d.data.name).join(" -> ");

        // Create the SVG container.
        const svg = d3.select("#daily-grp")
            .append("svg")
            .attr("id", `treeMap${flow !== undefined ? 1 : 2}`)
            .attr("viewBox", [0, -20, width, height + 30])
            .attr("width", width)
            .attr("height", height + 30)
            .attr("style", "max-width: 100%; height: auto;")
            .style("font", "10px");

        // Display the root.
        let group = svg.append("g")
            .call(render, root);

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => d === root ? zoomout(root) : zoomin(d));

            node.append("title")
                .text(d => `${name(d)}\n${format(d.data.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = "leaf").id)
                .attr("fill", d => d !== root ? getRectFill(d) : "#fff")
                .attr("stroke", "#fff");
            node.append("clipPath")
                .attr("id", d => (d.clipUid = "clip").id)
                .append("use")
                .attr("xlink:href", d => d.leafUid.href);

            node.append("text")
                .attr("id", "titleTxt")
                .attr("dx", "0.5em")
                .attr("dy", "1.5em")
                .attr("font-size", "14px")
                .attr("fill", "#fff")
                .attr("font-weight", "bold")
                .text(function (d) {
                    return d.data.name;
                });

            node.append("text")
                .attr("clip-path", d => d.clipUid)
                .text(d => d === root ? name(d) : "")
                .attr("dx", "0.7em")
                .attr("dy", "1.5em")
                .attr("id", "ct")
                .attr("font-weight", d => d === root ? "bold" : null)
                .selectAll("tspan")
                .join("tspan")
                .attr("x", 3)
                .attr("dx", "0.7em")
                .attr("dy", "2em")
                .attr("y", (d, i, nodes) => getParent(d, i, nodes))
                .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                .attr("font-weight", (d, i, nodes) => i === nodes.length - 1 ? "normal" : null)
                .attr("font-size", (d, i, nodes) => getFontsize(d, i, nodes))
                .attr("fill", "#fff")
                .text(d => d);

            node
                .append("text")
                .attr("id", "tx1")
                .attr("font-size", "18px")
                .attr("fill", "#fff")
                .text(d => d === root ? "" : getPercent(d));


            group.call(position, root);
        }

        function getPDx(d) {
            return (x(d.x1) - x(d.x0)) - 42
        }

        function getPDy(d) {
            return (y(d.y1) - y(d.y0)) - 10
        }

        function setOpacity(d) {
            if ((x(d.x1) - x(d.x0)) < 150) {
                return 0
            }
            return 1
        }

        function getPercent(d) {
            return `${getValue(d)}%`;
        }

        function getFontsize(d, i, nodes) {
            if (i === 0) {
                return "13px"
            }
            return "11px"
        }

        function getParent(d, i, nodes) {
            return `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 1.3}em`
        }

        function getRectFill(d) {
            const val = getValue(d);
            return getColor(val);
        }

        function getColor(val) {
            switch (true) {
                case val > 100:
                    return "#022d0d";
                case val <= 100 && val >= 80:
                    return "#044317";
                case val < 80 && val >= 70:
                    return "#0e6027";
                case val < 70 && val > 60:
                    return "#750e13";
                case val < 60 && val >= 20:
                    return "#a2191f";
                default:
                    return "#24a148";
            }
        }


        function getValue(d) {
            return d.data.quality
        }

        function position(group, root) {

            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0))

            group.selectAll("g")
                .select("#ct")
                .attr("opacity", d => setOpacity(d))

            group.selectAll("g")
                .select("#tx1")
                .attr("dy", d => getPDy(d))
                .attr("dx", d => getPDx(d))
                .attr("opacity", d => {
                    if ((x(d.x1) - x(d.x0)) < 80) {
                        return 0
                    } return 1
                })
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .call(position, d.parent))
                .call(t => group1.transition(t)
                    .attrTween("opacity", () => d3.interpolate(0, 1))
                    .call(position, d));
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(750)
                .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => d3.interpolate(1, 0))
                    .call(position, d))
                .call(t => group1.transition(t)
                    .call(position, d.parent));
        }

        return svg.node();
    }


    return (
        <Tile style={{ marginTop: 0 }}>
            <h4>Code Quality</h4>
            <div id="daily-grp" style={{ textAlign: "center" }}></div>
        </Tile>
    )
}

export default TreeMap;