import { useKeycloak } from "@react-keycloak/web";
import DefaultModal from "Carbon-Components/Modals/CommonModal";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import { deActivateTenant } from "Services/ServerApi";
// import DefaultModal from "Carbon-Components/Modal/Modal";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useState } from "react";
// import { deActivateTenant } from "services/serverapi";
import { v4 as uuidv4 } from "uuid";

const DeactivateTenant = ({cancel, tenant}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [resMsg, setResMsg] = useState("")
  const [refresh, setRefresh] = useState(false)
  const { keycloak } = useKeycloak()

  const onSubmit = async () => {
      setIsLoading(true)
      const res = await deActivateTenant(keycloak, tenant.name, uuidv4())
      if(res?.data?.status === "InActive"){
        setRefresh(true)
        setResMsg("Tenant Deactivated Succesfully")
      }else{
        setRefresh(false)
        setResMsg("Failed to Deactivate Tenant")
      }
      setIsLoading(false)
  }

  if(resMsg){
    return <PassiveModal canCloseDetailsPopup={() => cancel(refresh)} >
      <h4>{resMsg}</h4>
    </PassiveModal>
  }

  return (
    <DefaultModal
      heading="Alert"
      primaryText="Submit"
      primaryButtonDisabled={isLoading}
      onModalSubmit={() => onSubmit()}
      onCloseModal={() => cancel(false)}
    >
      {
        isLoading
        ? <InlineLoader status="active" description="deactivating tenant..." />
        : <>
          <p>Are you sure you want to Deactivate the Tenant?</p>
          <p className="mt-1"><strong>Tenant Name: </strong> {tenant.name}</p>
        </>
      }
    </DefaultModal>
  );
}

export default DeactivateTenant