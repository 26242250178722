export const headers = [
  {
    key: "recommendation",
    header: "Potential Solution",
  },
  {
    key: "startTime",
    header: "Implementation start time",
  },
  {
    key: "timeToImplement",
    header: "Time to Implement",
  },
  {
    key: "costSaving",
    header: "Potential Cost Saving",
  },
  {
    key: "saving",
    header: "Potential Carbon Saving",
  },
];

export const decrement = {
  application: 40,
  "Augmented HyOps": 60,
  "Augmented SRE": 50,
  "End-to-End Full Stack Observability": 65,
  "ML enabled Situation Room (ChatOps)": 45,
  "Intelligent Help Desk (ChatBot)": 35,
  "Augmented Advanced Analytics & Insights": 32,
  "Application Resource & Performance Management (Turbonomic)": 42,
  "Intelligent Application Life Cycle Management": 60,
};

export const rows = [
  {
    id: "4",
    recommendation: "End-to-End Full Stack Observability",
    startTime: "Feb 2024",
    timeToImplement: "6-8 Months",
    costSaving: "TBD",
    saving: "60-70%",
  },
  {
    id: "2",
    recommendation: "Augmented HyOps",
    startTime: "Feb 2024",
    timeToImplement: "6-7 Months",
    costSaving: "TBD",
    saving: "50-70%",
  },
  {
    id: "3",
    recommendation: "Augmented SRE",
    startTime: "Feb 2024",
    timeToImplement: "2-3 Months",
    costSaving: "TBD",
    saving: "45-55%",
  },
  {
    id: "9",
    recommendation: "Augmented Advanced Analytics & Insights",
    startTime: "Feb 2024",
    timeToImplement: "2-3 Months",
    costSaving: "TBD",
    saving: "30-40%",
  },
  {
    id: "7",
    recommendation: "ML enabled Situation Room (ChatOps)",
    startTime: "Feb 2024",
    timeToImplement: "4-5 Months",
    costSaving: "TBD",
    saving: "40-50%",
  },
  {
    id: "8",
    recommendation: "Intelligent Help Desk (ChatBot)",
    startTime: "Feb 2024",
    timeToImplement: "1-2 Months",
    costSaving: "TBD",
    saving: "25-40%",
  },
  {
    id: "10",
    recommendation:
      "Application Resource & Performance Management (Turbonomic)",
    startTime: "Feb 2024",
    timeToImplement: "4-6 Months",
    costSaving: "TBD",
    saving: "35-55%",
  },
  {
    id: "6",
    recommendation: "Intelligent Application Life Cycle Management",
    startTime: "Feb 2024",
    timeToImplement: "4-6Months",
    costSaving: "TBD",
    saving: "45-60%",
  },
];

export const susopsRec = [
  "Reduce the number of tickets being generated",
  "Reduce the number of individuals involved in resolving the ticket.",
  "Reduce the overall effort involved in resolving the ticket.",
  "Limit the number of individuals notified via email during the ticket lifecycle.",
  "Limit the number of individuals involved in collaboration channels/communication tools such as MS Teams, Webex, Slack.",
  "Limit the number of video calls and transition to audio-based calls.",
  "Restrict the frequency and duration of screen sharing.",
  "Minimize the volume of messages exchanged in the collaboration channels/communication tools.",
  "Upgrade to more energy-efficient devices being used by resources involved in ticket lifecycle",
  "Implement a Shift-Left approach to end-to-end (E2E) IT operations.",
];
