import {
    Button,
    Column,
    FluidForm,
    TextArea,
    TextInput
} from "@carbon/react";
import { CaretLeft, Rotate, SendAltFilled } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { sendMail, watsonXStructured } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const MailContent = (props) => {
    const { keycloak } = useKeycloak();
    const [inputTo, setInputTo] = useState("Application-dev team");
    const [inputCc, setInputCc] = useState("Application-IM team");
    const [inputSubject, setInputSubject] = useState(`${props.IncidentId}: update notification as of ${new Date()}`);
    const [emailContent, setEmailContent] = useState("");
    const [mailSent, setMailSent] = useState(false);

    useEffect(() => {
        getMailContent()
    }, [])

    const getMailContent = async () => {
        const body = {
            work_note: "Hello world!"
        }
        const Response = await watsonXStructured(keycloak, uuidv4(), body);
        setEmailContent(Response.data.email_content)
    }

    
    const postMailContent = async () => {
        // const data = {
        //     to: "gkumar17@in.ibm.com",
        //     cc: "aparna.venkatraman@in.ibm.com",
        //     subject: inputSubject,
        //     content: emailContent
        // }
        //const Response = await sendMail(keycloak, uuidv4(), data);
        setMailSent(false)
        // console.log("Response", Response)
    }

    const onclickBack = () => {
        props.BackTo()
    }

    const onclickSend = () => {
        postMailContent()
        setMailSent(true)
    }

    const onInputTo = (e) => {
        setInputTo(e.target.value)
    }

    const onInputCc = (e) => {
        setInputCc(e.target.value)
    }

    const onInputSubject = (e) => {
        setInputSubject(e.target.value)
    }

    const onTextArea = (e) => {
        const txt = emailContent.concat(e.target.value)
        setEmailContent(txt)
    }

    return (
        <Column lg={16} className="rgt-padding lft-padding">
            <div style={{ clear: "both", display: "inline-block", width: "100%" }}>
                <Button
                    kind="ghost"
                    className="skip-btn back"
                    onClick={() => onclickBack()}
                    size="sm"
                    style={{ float: "left", color: "#161616" }}
                >
                    <CaretLeft size={16} />Back
                </Button>
                <Button
                    kind="primary"
                    className="skip-btn send-btn"
                    onClick={() => onclickSend()}
                    size="sm"
                    style={{ float: "right" }}
                >
                    {mailSent ? <Rotate className="rotateIcons"  size={16} /> : <SendAltFilled size={16} />} Send
                </Button>
            </div>
            <div className="mail-content">
                <TextInput onChange={(e) => onInputTo(e)} value={inputTo} id="text-input-1" type="text" labelText="To" helperText="" />
                <TextInput onChange={(e) => onInputCc(e)} value={inputCc} id="text-input-2" type="text" labelText="Cc" helperText="" />
                <FluidForm>
                    <TextInput onChange={(e) => onInputSubject(e)} value={inputSubject} type="text" labelText="Add a Subject" id="text-input-3" />
                </FluidForm>
                <div className="txt-area">
                    {emailContent !== "" ?
                        <TextArea onChange={(e) => onTextArea(e)} labelText="" helperText="" value={`Hi, ${emailContent}`} rows={12} id="text-area-1" />
                        : <>
                            <p className="mailcont"><Rotate className="rotateIcons" size={16} /> Loading Mail Content..</p>
                        </>}
                </div>
            </div>
        </Column>
    )
}

export default MailContent;