/** @format */

import { LineChart } from "@carbon/charts-react";
import {
  Button,
  Column,
  DatePicker,
  DatePickerInput,
  ErrorBoundary,
  Grid,
  InlineLoading,
  SkeletonPlaceholder,
  TimePicker,
  Tooltip,
} from "@carbon/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Information } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import CarbonDataTableServiceMap from "Carbon-Charts/CarbonDataTableServiceMap";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import { getAnyCollection } from "Services/ServerApi";
import { getServicesData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";

// ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$
const validTime = new RegExp("^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$");

const tableHeaderData = [
  {
    key: "id",
    header: "Service ID",
  },
  {
    key: "No of service",
    header: "Service Count",
  },
  {
    key: "StartTime",
    header: "Start Time",
  },
  {
    key: "EndTime",
    header: "End Time",
  },
  {
    key: "hasAnomaly",
    header: "Anomaly",
  },
  {
    key: "hasError",
    header: "Error",
  },
  {
    key: "hasFault",
    header: "Fault",
  },
];

const ServiceSummary = (props) => {
  const { keycloak } = useKeycloak();
  const context = useContext(Context);
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [errMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [apiLoading, setApiLoading] = useState(true);
  const [serviceSummary, setServiceSummary] = useState();
  const [serviceSummaryInitial, setServiceSummaryInitial] = useState();
  const [time, setTime] = useState(new Date().toLocaleTimeString().substring(0, new Date().toLocaleTimeString().indexOf(':') + 3));
  const [date, setDate] = useState(null);

  const [timeValid, setTimeValid] = useState(true);

  const chartRef = useRef(null);

  const { data: AtoAlertData, refetch: atoRefetch } = useQuery(['ato_alert'], async () => await getAnyCollection(keycloak, "ato_alert_response"), { retry: 1, enabled: false });

  useEffect(() => {
    if(props.flow === "ato") {
      atoRefetch()
    } else {
      setDate(props.startDateDefault);
      setStartDate(props.startDateDefault);
      setEndDate(props.endDateDefault);
      getServiceSummaryData(props.startDateDefault, props.endDateDefault);
    }
  }, [props]);

  useEffect(() => {
    if(AtoAlertData !== undefined) {
      updateAtoResponse(AtoAlertData)
    }
  }, [AtoAlertData])

  useEffect(() => {
    setEventListener();
  }, [serviceSummary, chartRef]);

  const updateAtoResponse = (response) => {
    if (response.err) {
      setErrorMessage(response.message);
    } else {
      if (response.data.ato_alert_response) {
        setServiceSummaryInitial(response.data.ato_alert_response[0].data);
        setServiceSummary(response.data.ato_alert_response[0].data);
      } else {
        setErrorMessage("Data is unavailable");
      }
    }
  };

  const setEventListener = () => {
    if (chartRef.current) {
      chartRef.current.chart.services.events.addEventListener(
        "scatter-click",
        chartOnClick
      );
    }
  };

  const chartOnClick = (detail) => {
    if (detail.detail.datum.group === "Anomaly") {
      sessionStorage.setItem("hasAnomaly", "Yes");
    }
    else {
      sessionStorage.setItem("hasAnomaly", "No");
    }
    let row = getDetailsRow(serviceSummary.tableData, detail.detail.datum.id);
    props.serviceClick(row);
  };

  const updateResponse = (response) => {
    if (response.err) {
      setErrorMessage(response.message);
    } else {
      if (response.data.data) {
        setServiceSummaryInitial(response.data.data);
        setServiceSummary(response.data.data);
      } else {
        setErrorMessage("Data is unavailable");
      }
    }
  };

  const getServiceSummaryData = async (sDate, eDate) => {
    //TODO: hardcoding the date which has the data
    // const startDate = new Date('2024-01-24T00:10:00Z');
    // const endDate = new Date('2024-01-24T23:50:00Z');
    // sDate = startDate.toISOString().split('T')[0] + " 00:10";
    // eDate = endDate.toISOString().split('T')[0] + " 23:50";

    setIsAPIDataLoading(true);

    const response = await getServicesData(keycloak.token, sDate, eDate);
    AddUserActivity(keycloak, context, "FEATURE", "Service Map Timeline Summary", response);

    setIsAPIDataLoading(false);
    setApiLoading(false);

    if (response.status >= 400 || response === "Error") {
      setErrorMessage("Error while fetching data");
    } else {
      updateResponse(response);
    }
  };

  const formatDate = (date, type, hours, minutes) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (type === "start") {
      return [year, month, day].join("-") + " 00:10";
    } else {
      return [year, month, day].join("-") + " " + hours + ":" + minutes;
    }
  };

  const updateDate = (dateEventObj, currentTime) => {
    let dateObj = new Date(dateEventObj);
    let arr = currentTime.split(":");
    let hours = arr[0];
    let minutes = arr[1];

    setStartDate(formatDate(dateObj.toDateString(), "start"));
    setEndDate(formatDate(dateObj.toDateString(), "end", hours, minutes));
    props.dateChange(startDate, endDate);
  };

  const onDateChange = (e) => {
    setDate(e[0]);
    updateDate(e[0], time);
  };

  const onTimeChange = (e) => {
    let enteredTime = e.target.value;
    let isTimeValid = validTime.test(enteredTime);

    setTimeValid(isTimeValid);
    setTime(enteredTime);

    let timeValidity = isTimeValid;
    if (timeValidity) {
      setTimeValid(timeValidity);
      updateDate(date, enteredTime);
    }
  };

  const updateAPI = async () => {
    let timeValidity = validTime.test(time);
    setTimeValid(timeValidity);
    if (timeValidity) {
      setServiceSummary("");
      setApiLoading(true);
      await getServiceSummaryData(startDate, endDate);
    }
  };

  const getHeadingAndDateFilter = () => {
    return (
      <>
        <Column lg={10}>
          <div>
            <h3>{props.title}
              {/* <img
                src={require(`../../../../ICPSAassets/${sessionStorage.getItem("monitoringTool")}.png`).default}
                alt="monitoring-tool-icon"
                className="monitoring-tile-icon"
                title={sessionStorage.getItem("monitoringTool")}
              /> */}

            </h3>
          </div>
        </Column>
        {
          <>
            <Column lg={3}>
              <ErrorBoundary fallback={"Data is unavailable"}>
                <DatePicker
                  dateFormat="Y-m-d"
                  datePickerType="single"
                  onChange={(e) => onDateChange(e)}
                  className="date-picker--margin"
                >
                  <DatePickerInput
                    className="width--100"
                    placeholder="yyyy-mm-dd"
                    labelText="Date"
                    id="date-picker-single"
                    defaultValue={props.startDateDefault.substring(0, 10)}
                    size="md"
                    type="text"
                  />
                </DatePicker>
              </ErrorBoundary>
            </Column>
            <Column
              lg={1}
            >
              <ErrorBoundary fallback={"Data is unavailable"}>
                <TimePicker
                  id="time-picker"
                  labelText="Time"
                  onChange={(e) => onTimeChange(e)}
                  invalid={!timeValid}
                  value={time}
                ></TimePicker>
              </ErrorBoundary>
            </Column>
            <Column lg={2}>
              <span>
                {apiLoading ? (
                  <InlineLoading
                    status="active"
                    iconDescription="Loading"
                    description="Loading data..."
                    className="report-update__button"
                  />
                ) : (
                  <Button
                    kind="primary"
                    onClick={updateAPI}
                    size="md"
                    iconDescription="Icon Description"
                    className="report-update__button"
                  >
                    Update
                  </Button>
                )}
              </span>
            </Column>
          </>
        }
      </>
    );
  };

  const getDetailsRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getDetailsRow(serviceSummary.tableData, id);
    props.serviceClick(row);
  };

  const getRowCellData = (id, data, row) => {
    if (id.includes("id")) {
      if (row.cells[4].value === "Yes") {
        return (
          <a
            className="event-status event-status__anomaly"
            onClick={() => {
              onClickNode(row.id);
              sessionStorage.setItem("hasAnomaly", "Yes");
            }}
          >
            {data}
          </a>
        );
      } else if (row.cells[5].value === "Yes") {
        return (
          <a
            className="event-status event-status__error"
            onClick={() => {
              onClickNode(row.id);
              sessionStorage.setItem("hasAnomaly", "No");
            }}
          >
            {data}
          </a>
        );
      } else if (row.cells[6].value === "Yes") {
        return (
          <a
            className="event-status event-status__fault"
            onClick={() => {
              onClickNode(row.id);
              sessionStorage.setItem("hasAnomaly", "No");
            }}
          >
            {data}
          </a>
        );
      } else {
        return (
          <a
            className="event-status"
            onClick={() => {
              onClickNode(row.id);
              sessionStorage.setItem("hasAnomaly", "No");
            }}
          >
            {data}
          </a>
        );
      }
    } else if (id.includes("hasError")) {
      if (row.cells[4].value === "Yes" && row.cells[5].value === "No") {
        return (
          <>
            {data}
            <Tooltip
              align="bottom"
              label="This is a proactive anamoly, error may occur!"
            >
              <button
                className="tooltip-button"
                type="button"
              >
                <Information />
              </button>
            </Tooltip>
          </>
        );
      }
    }

    if (typeof data == "boolean") {
      return String(data);
    }
    return data;
  };

  const getTableHeader = (header) => {
    return header;
  };

  const getTableDescription = () => {
    return "Service Applications";
  };

  const createChartExplicit = () => {
    return (
      <>
        {getHeadingAndDateFilter()}
        <Column lg={16} className="common-top-margin">
          {serviceSummary &&
            serviceSummary.chartData &&
            serviceSummary.chartOptions ? (
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              {serviceSummary.chartData.length > 0 ? (
                <LineChart
                  ref={chartRef}
                  data={serviceSummary.chartData}
                  options={serviceSummary.chartOptions}
                ></LineChart>
              ) : (
                <DataUnAvailable />
              )}
            </ErrorBoundary>
          ) : (
            loadSkeleton()
          )}
        </Column>
        <Column lg={16} className="common-top-margin">
          {serviceSummary &&
            serviceSummary.tableData ? (
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              {serviceSummary.tableData.length > 0 ? (
                <CarbonDataTableServiceMap
                  rowData={serviceSummary.tableData}
                  headerData={tableHeaderData}
                  title="Service Map Timeline List"
                  getRowCellData={getRowCellData}
                  getTableHeader={getTableHeader}
                  description={getTableDescription()}
                />
              ) : (
                <></>
              )}
            </ErrorBoundary>
          ) : (
            loadSkeleton()
          )}
        </Column>
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeleton__placeholder--table">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {
        <Grid>{createChartExplicit()}</Grid>
      }
    </div>
  );
};

export default ServiceSummary;
