import axios from "axios";

const BFF_URL = sessionStorage.getItem("bff");

export const getErrorResponse = (error) => {
  if (error.response) {
    return { err: true, message: error.response.status };
  }
  else {
    return { err: true, message: error.message };
  }
};

//Get Tenants
export const getTenantsAPI = async (keycloak, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/all-tenants`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Tenant
export const getTenantAPI = async (keycloak, uuid, name) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant?name=${name}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Tenant Roles
export const getTenantRolesAPI = async (keycloak, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/roles`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};
//Get Organizations
export const getOrganizationsAPI = async (keycloak, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${sessionStorage.getItem("tenant")}/orgs`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Organization
export const getOrganizationAPI = async (keycloak, uuid, tenant, org) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org?name=${org}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Tenant Organization
export const getTenantOrganizationsAPI = async (keycloak, uuid, tenant) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/orgs`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Tenant Organization
export const getTenantOrganizationAPI = async (keycloak, uuid, tenant, org) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org?name=${org}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Applications
export const getApplicationsAPI = async (keycloak, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${sessionStorage.getItem("tenant")}/org/${sessionStorage.getItem("organizationName")}/apps`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Application
export const getApplicationAPI = async (keycloak, uuid, tenant, org, app) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app?name=${app}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};


//Get Org Applications
export const getTenantOrgApplicationsAPI = async (keycloak, uuid, tenant, org) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/apps`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Org Application
export const getTenantOrgApplicationAPI = async (keycloak, uuid, tenant, org, app) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app?name={app}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Users
export const getUsersAPI = async (keycloak, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/all-users`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get tenant Users
export const getTenantUsersAPI = async (keycloak, uuid, tenant) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/users`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Org Users
export const getOrgUsersAPI = async (keycloak, uuid, tenant, org) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/users`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Applications Users
export const getAppUsersAPI = async (keycloak, uuid, tenant, org, app) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app/${app}/users`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postTenantDetails = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postOrgDetails = async (keycloak, uuid, tenant, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${tenant}/org`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postAppDetails = async (keycloak, uuid, tenant, org, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUsertoTenantDetails = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${sessionStorage.getItem("tenant")}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUsertoSelectedTenantDetails = async (keycloak, uuid, data, tenant) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${tenant}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUserToOrgDetails = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${sessionStorage.getItem("tenant")}/org/${sessionStorage.getItem("organizationName")}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUserToSelectedOrgDetails = async (keycloak, uuid, data, tenant, org) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUserToAppDetails = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${sessionStorage.getItem("tenant")}/org/${sessionStorage.getItem("organizationName")}/app/${sessionStorage.getItem("applicationName")}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const postUserToSelectedAppDetails = async (keycloak, uuid, data, tenant, org, app) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app/${app}/user`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const checkDuplicateUser = async (keycloak, uuid, username) => {
  return axios
    .get(`${BFF_URL}/rbac/users/user?username=${username}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Users
export const getUserbyUsername = async (keycloak, username, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/user?username=${username}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

// get Tenant user
export const getTenantUserByUsername = async (keycloak, tenant, username, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/user?username=${username}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

// get Org user
export const getOrgUserByUsername = async (keycloak, tenant, org, username, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/user?username=${username}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

// get Tenant user
export const getAppUserByUsername = async (keycloak, tenant, org, app, username, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/org/${org}/app/${app}/user?username=${username}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

//Get Tenant Roles
export const getTenantRolesByTenantNameAPI = async (keycloak, tenant, uuid) => {
  return axios
    .get(`${BFF_URL}/rbac/tenant/${tenant}/roles`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};