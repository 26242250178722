import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from "locale/i18n";
import { Provider } from "react-redux";
import { legacy_createStore as createStore } from "redux";
import reducers from "./Store/reducer";
import reportWebVitals from './reportWebVitals';
import ContextProvider from "./Context/Provider";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import "./Css/main.scss";

import process from 'process';
window.process = process;

const store = createStore(reducers);
i18n.changeLanguage(sessionStorage.getItem("language"));

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

root.render(
  <Provider store={store}>
    <ContextProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
