import React, { useEffect, useState } from "react";

import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@carbon/react";

const CarbonTabs = (props) => {
	const initialTabIndex = props.tabIndex ? props.tabIndex : 0;
	const [tabindex, setTabIndex] = useState(initialTabIndex);

	useEffect(() => {
		setTabIndex(props.tabIndex)
	}, [props])

	const tabsList = () => {
		return props.Tablist.map((label) => (
			<Tab
				href="#"
				id={label}
				key={label}
				role="presentation"
				disabled={
					props.disabledList
						? props.disabledList.includes(label)
							? true
							: false
						: false
				}
			>
				{label}
			</Tab>
		));
	};

	const tabPanels = () => {
		return props.TabPanelsList.map((component, index) => (
			<TabPanel className="dc-tabPanel" key={`panel-${index}`}>
				{parseInt(tabindex) === index && component}
			</TabPanel>
		));
	};

	useEffect(() => {
		if(props.tabSelection) {
			props.tabSelected(tabindex)
		}
	}, [props, tabindex])

	return (
		<Tabs
			selectedIndex={tabindex}
			onChange={(e) => {
				setTabIndex(e.selectedIndex);
				if(props.setTabIndex){
					props.setTabIndex(e.selectedIndex)
				}
			}}
			// selectionMode="automatic"
		>
			<TabList activation="manual" aria-label="tabs-list">{tabsList()}</TabList>
			<TabPanels>{tabPanels()}</TabPanels>
		</Tabs>
	);
};

export default CarbonTabs;
