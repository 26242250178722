import { Column, Row } from "@carbon/react";
import { Close } from "@carbon/react/icons";
import React, { useEffect, useState } from "react";

const Filters = ({ filters, close }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);
  const getSelectedFilter = () => {
    const values = filters.split(",");
    let result = "";
    for (let i = 0; i < values.length; i += 2) {
      if (i !== 0) {
        result += ", ";
      }
      result += `${values[i]} = ${values[i + 1]}`;
    }
    return result;
  };
  if (isLoading) {
    return null;
  }
  return (
    <Row className="pl-05 pr-05">
      <Column lg={4} md={4} sm={2}>
        <div className="filters-for-charts">
          <h6>{getSelectedFilter()}</h6>
          <Close size={20} className="inline-svg" onClick={() => close()} />
        </div>
      </Column>
    </Row>
  );
};

export default Filters;
