/** @format */

export const setLandingPage = (data) => ({
  type: "LANDING_PAGE",
  payload: data,
});

export const setLoginPage = (data) => ({
  type: "LOGIN_PAGE",
  payload: data,
});
