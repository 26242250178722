import {
    Button,
    DataTable,
    DataTableSkeleton,
    Pagination,
  } from "@carbon/react";
  import { Add, Download, MailAll, Renew, TrashCan } from "@carbon/react/icons";
  import React, { useEffect, useState } from "react";
  import TooltipCarbon from "Carbon-Components/Tooltip";
  const {
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableToolbar,
    TableSelectAll,
    TableSelectRow,
    TableToolbarContent,
    TableToolbarSearch,
  } = DataTable;
  
  const CarbonDataTable = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowData, setRowData] = useState([]);
  
    useEffect(() => {
      setRowData(props.rowData)
    }, [props])
  
    const changePaginationState = (pageInfo) => {
      if (page !== pageInfo.page) setPage(pageInfo.page);
      if (pageSize !== pageInfo.pageSize) setPageSize(pageInfo.pageSize);
    };
  
    const start = (page - 1) * pageSize;
  
    return (
      <div className="carbon--data--table" style={{width:"200%"}}>
        {rowData.length > 0 ? (
          <div>
            <DataTable size={props.size ? props.size : "md"} rows={rowData} headers={props.headerData}>
              {({
                rows,
                headers,
                getHeaderProps,
                getTableProps,
                getBatchActionProps,
                onInputChange,
                getSelectionProps
              }) => (
                <TableContainer title={props.title} className={`table-container ${props.className}`} description={props.description ?? ""}>
                  {props.toolbarNeeded ?? (
                    <TableToolbar>
                      <TableToolbarContent>
                        {props.contentTitle ? <h5>{props.contentTitle}</h5> : null}
                        {props.dropDown ? props.getDropDown() : null}
                        {props.checkBox ? props.getcheckBox() : null}
                        <TableToolbarSearch
                          tabIndex={
                            getBatchActionProps().shouldShowBatchActions ? -1 : 0
                          }
                          onChange={onInputChange}
                          placeholder={
                            props.placeholder ? props.placeholder : "Filter Table"
                          }
                        />
                        {props.onRefresh && (
                          <TooltipCarbon description="Refresh">
                            <div className="refresh-icon">
                              <Renew
                                aria-label="refresh"
                                size={24}
                                className={props.iconRotate ? "rotateIcons" : ""}
                                onClick={() => props.onRefresh()}
                              />
                            </div>
                          </TooltipCarbon>
                        )}
                        {props.getFilters &&
                          props.getFilters(getBatchActionProps())}
                        {props.importTitle ? (
                          <Button
                            tabIndex={
                              getBatchActionProps().shouldShowBatchActions
                                ? -1
                                : 0
                            }
                            onClick={() => props.importFunction()}
                            kind="primary"
                            className="button--margin-right create--button"
                          >
                            {props.importTitle}
                            <Download size={14} />
                          </Button>
                        ) : null}
                        {props.createTitle && !props.disableCreate && (
                          <Button
                            disabled={props.oncheckRow ? !props.oncheckRow : false}
                            tabIndex={
                              getBatchActionProps().shouldShowBatchActions
                                ? -1
                                : 0
                            }
                            onClick={() => props.createFunction(true)}
                            kind="primary"
                            className="create--button"
                          >
                            {props.createTitle}
                            {props.mailBtn ? <MailAll /> : <Add />}
                          </Button>
                        )}
                      </TableToolbarContent>
                    </TableToolbar>
                  )}
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
  
                        {props.headerSelection ? <TableSelectAll disabled={true} id="1" onChange={(e) => props.onselectRow(e)} {...getSelectionProps()} />
                          : null}
                        {headers.map((header) => (
                          header.header !== props.diabledCol ?
                            <TableHeader
                              {...getHeaderProps({ header, isSortable: true })}
                            >
                              {props.getTableHeader(header.header)}
                            </TableHeader> : null
                        ))}
                        {props.actionsNeeded ?? (
                          <TableHeader>Actions</TableHeader>
                        )}
                        {props.table_particular_actions && (
                          <TableHeader>Actions</TableHeader>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(start, start + pageSize).map((row) => (
                        <TableRow key={row.id}>
                          {props.rowSelection ? <TableSelectRow id={row.id} radio={false} checked={props.oncheckRow} onChange={(e) => props.onselectRow(e, row)} {...getSelectionProps({
                            row
                          })} /> : null}
                          {row.cells.map((cell, index) => (
                            !cell.id.includes(':workflow') ?
                              <TableCell key={cell.id}>
                                {props.getRowCellData(cell.id, cell.value, row)}
                              </TableCell> : null
                          ))}
                          {props.actionsNeeded ?? (
                            <TableCell>
                              <TooltipCarbon description="Delete">
                                <TrashCan
                                  fill="#525252"
                                  size={16}
                                  onClick={() => props.deleteRow(row, rows)}
                                />
                              </TooltipCarbon>
                            </TableCell>
                          )}
                          {props.table_particular_actions && (
                            <TableCell>{props.getActions(row, rows)}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
            {props.pagination === undefined ? (
              <Pagination
                onChange={changePaginationState}
                page={page}
                pageSize={pageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                totalItems={rowData.length}
                className={`${props.className}`}
              />
            ) : null}
          </div>
        ) : (
          <DataTableSkeleton
            columnCount={5}
            rowCount={4}
            headers={props.headerData}
            showToolbar={false}
          />
        )}
      </div>
    );
  };
  
  export default CarbonDataTable;
  