
const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const convertUTCDate = (dateString) => {
  if (!dateString) {
    return "-";
  }

  const splitedDate = dateString.split(/[^0-9]/);
  const date = new Date(
    splitedDate[0],
    splitedDate[1] - 1,
    splitedDate[2],
    splitedDate[3],
    splitedDate[4],
    splitedDate[5]
  );
  const seconds =
    Math.floor((new Date() - date) / 1000) +
    new Date().getTimezoneOffset() * 60;

  const convertedDate = `${date.getTime()} ${
    splitedDate[1]
  } ${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}`;

  if (seconds < 0) {
    return convertedDate;
  }

  const noOfDays = seconds / 86400;
  if (noOfDays > 1) {
    return convertedDate;
  }
  const noOfHours = seconds / 3600;
  if (noOfHours > 1) {
    return `${date.getTime()}-${Math.floor(noOfHours)} hours ago`;
  }
  const noOfMinutes = seconds / 60;
  if (noOfMinutes > 1) {
    return `${date.getTime()}-${Math.floor(noOfMinutes)} minutes ago`;
  }
  return `${date.getTime()}-${Math.floor(seconds)} seconds ago`;
};

export const convertDate = (dateFormat, date, dateModified) => {
  if (!date && dateModified.toLowerCase() === "updated") {
    return "Not Modified";
  }
  const dateArray = date.split(" ");
  const dateString = `${dateArray[1]}/${dateArray[2]}/${dateArray[4]}`;
  const dateObject = new Date(dateString);
  let currentDate;
  if (dateFormat === "dd-mm-yy" && dateObject.toString() !== "Invalid Date") {
    currentDate = `${dateObject.getDate()} ${
      monthsArray[dateObject.getMonth()]
    } ${dateObject.getFullYear()}`;
  } else {
    const invalidDate = date.split("-");
    currentDate = invalidDate[1];
  }
  return currentDate;
};

export const splitDateTime = (stamp) => {
  let [date, time] = stamp.split("T");

  let [year, month, day] = date.split("-");

  return `${day} ${monthsArray[Number(month) - 1]} ${year} ${time}`;
};

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const createUTCDateFromInput = (inp, param) => {
  const day = inp.getDate();
  let month = inp.toLocaleString("default", { month: "long" });
  let year = inp.getFullYear();
  const dateTemp = new Date();
  let dateString;
  if (param === "start") {
    if (!isToday(inp)) {
      return inp.toISOString();
    }
    dateString = `${day} ${month} ${year} ${dateTemp.getHours()}:${dateTemp.getMinutes()}:${dateTemp.getSeconds()}`;
  } else if (param === "end") {
    dateString = `${day} ${month} ${year} ${23}:${59}:${59}`;
  }
  const date = new Date(dateString);
  return date.toISOString();
};

export const formatDateWithYear = (date) => {
  return new Date(date).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const formatDate = (date) => {
  const diff = (new Date().getTime() - new Date(date).getTime()) / 1000;
  if (diff < 0) {
    return formatDateWithYear(date);
  } else if (diff < 60) {
    return `${Math.floor(diff)} seconds ago`;
  } else if (diff < 60 * 60) {
    return `${Math.floor(diff / 60)} minutes ago`;
  } else if (diff < 24 * 60 * 60) {
    return `${Math.floor(diff / (60 * 60))} hours ago`;
  } else {
    return formatDateWithYear(date);
  }
};

export function convertEpochToDateTime(epoch) {
  const dateObj = new Date(epoch);
  const year = dateObj.getUTCFullYear();
  const month = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getUTCDate()).slice(-2);
  const hours = ("0" + dateObj.getUTCHours()).slice(-2);
  const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);
  const seconds = ("0" + dateObj.getUTCSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
