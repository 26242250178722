/** @format */
import { InlineLoading } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import ErrorComponent from "Common-Modules/ErrorPage";
import Overlay from "Components/HomePage/HomeDashboard/Overlay";
import Routes from "Routes";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Context from "../../Context/Context";
import { getOrgDetails, getTenantDetails } from "../../Services/ServerApi";

const Navigation = () => {
    const { keycloak } = useKeycloak();
    //const context = useContext(Context);
    const [showRoutes, setShowRoutes] = useState(false);
    const [errMessage, setErrorMessage] = useState("");
    const [overlayout, setOverlay] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            window.history.replaceState(
                null,
                "",
                `${window.location.protocol}//${window.location.host}/#`
            );
            const checkTenantLength = JSON.parse(sessionStorage.getItem("tenants"));
            if (["null", null].includes(checkTenantLength)) {
                // gotoFirstTenant();
            } else {
                dispatch({ type: "STORE_TENANTS", payload: checkTenantLength });
                // fetchUserDetails();
                // fetchOrgs();
            }
            setShowRoutes(true);
        }
    }, [keycloak, keycloak.authenticated]);

    // const fetchUserDetails = async () => {
    //     //const userDetails = sessionStorage.getItem("userDetails");

    //     // if (userDetails === null) {
    //     //     const userDetails = await getUserDetails(keycloak, uuidv4());
    //     //     console.log("userDetails.data: ", userDetails.data);
    //     //     try {
    //     //         if (Boolean(userDetails.error)) throw new Error(userDetails.message);

    //     //         const errCheck = findApiError(userDetails);
    //     //         if (errCheck.error) {
    //     //             throw new Error(errCheck.message);
    //     //         }
    //     //         if (userDetails.data.length === 0) {
    //     //             throw new Error(
    //     //                 "User list is empty. Login in another window and try different Tenant."
    //     //             );
    //     //         } else {
    //     //             sessionStorage.setItem("userDetails", JSON.stringify(userDetails.data));
    //     //         }
    //     //     } catch (error) {
    //     //         setErrorMessage(
    //     //             "Failed to fetch users. Try logout and switching to different Tenant"
    //     //         );
    //     //     }
    //     // }
    // };

    // const fetchOrgs = async () => {

    //     const organizationId = sessionStorage.getItem("organization");
    //     const organizationName = sessionStorage.getItem("organizationName");

    //     if (organizationId === null && organizationName === null) {

    //         const organizationDetails = await getOrgDetails(keycloak, uuidv4());
    //         // console.log("organizationDetails", organizationDetails)
    //         try {
    //             // if (Boolean(organizationDetails.error)) throw new Error(organizationDetails.message);

    //             // const errCheck = findApiError(organizationDetails);
    //             // if (errCheck.error) {
    //             //     throw new Error(errCheck.message);
    //             // }
    //             if (organizationDetails.data.length === 0) {
    //                 throw new Error(
    //                     "Organization list is empty. Login in another window and try different Tenant."
    //                 );
    //             } else {
    //                 sessionStorage.setItem("orgs", JSON.stringify(organizationDetails.data));
    //                 sessionStorage.setItem("organization", organizationDetails.data[0].id);
    //                 sessionStorage.setItem("organizationName", organizationDetails.data[0].name);
    //                 sessionStorage.setItem("currentOrg", JSON.stringify(organizationDetails.data[0]));

    //                 // dispatch(storeOrgs(organizationDetails.data));;
    //                 setShowRoutes(true);
    //             }
    //         } catch (error) {
    //             setErrorMessage(
    //                 "Failed to fetch organizations. Try logout and switching to different Tenant"
    //             );
    //         }
    //     } else {
    //         setShowRoutes(true);
    //     }
    // };


    // const gotoFirstTenant = async () => {

    //     const tenantDetails = await getTenantDetails(keycloak, uuidv4());
    //     // console.log("tenantDetails", tenantDetails)
    //     if (tenantDetails.err) {
    //         // if bff fails
    //         setErrorMessage(tenantDetails.message);
    //     } else if (tenantDetails.length > 0) {
    //         let tenantData = tenantDetails;
    //         let tenants = [];
    //         let tenantObj = {};
    //         tenantData.forEach((tenant) => {
    //             if (tenant.name !== "dso" && tenant.name !== "itvsdp") {
    //                 tenantObj = {};
    //                 tenantObj.id = tenant.id;
    //                 tenantObj.name = tenant.name;
    //                 tenantObj.displayName = tenant.displayName;
    //                 tenants.push(tenantObj);
    //             }
    //         });
    //         let realm = sessionStorage.getItem("defaultRealm");
    //         // const currentTenant = tenants.filter(
    //         //     (tenant) => tenant.name === realm
    //         // )[0];
    //         const currentTenant = tenants.filter(
    //             (tenant) => tenant.name === "ibmconsulting"
    //         )[0];
    //         dispatch({ type: "STORE_TENANTS", payload: tenants });
    //         sessionStorage.setItem("tenants", JSON.stringify(tenants));
    //         sessionStorage.setItem(
    //             "tenant",
    //             sessionStorage.getItem("tenant") || currentTenant.name
    //         );
    //         sessionStorage.setItem("tenantId", currentTenant.id);
    //         await fetchUserDetails();
    //         await fetchOrgs();
    //     } else {
    //         setErrorMessage("No data found");
    //     }
    // };

    const routesLoader = () => {
        return errMessage ? (
            <ErrorComponent title={errMessage} />
        ) : (
            <InlineLoading
                description={`Fetching user details for ${keycloak.realm}`}
                className="event-loader"
            />
        );
    };

    const getRoutes = () => {
        return showRoutes ? (
            <Router>
                <Routes />
            </Router>
        ) : (
            routesLoader()
        );
    };

    // const getTenantName = () => {
    //     return window.location.pathname.split("/")[3] === undefined
    //         ? "IBM Consulting AIOps"
    //         : window.location.pathname.split("/")[3];
    // };

    // const authenticationLoader = () => {
    //     return errMessage ? (
    //         <ErrorComponent title={errMessage} />
    //     ) : (
    //         <InlineLoading
    //             description={`Authenticating user for ${sessionStorage.getItem("tenant")}`}
    //             className="event-loader"
    //         />
    //     );
    // };

    const overlayDone = (bool) => {
        // console.log("bool -------------------", bool)
        setOverlay(bool)
        sessionStorage.setItem("overlay", "false")
        //    context.updateOverlay(bool)
    }

    // console.log("overlayout -----------", context)

    return (
        <>
            {getRoutes()}
            {/* {overlayout ? <Overlay overLaydone={overlayDone} /> : getRoutes()} */}
        </>
    );
};

export default Navigation;
