import React, { useState, useEffect } from "react";
import PncOverview from "./pncOverview";
import {
  Column,
  Row,
  Tag,
  ClickableTile,
  SkeletonPlaceholder,
  Toggle,
} from "@carbon/react";
import { Subtract, Add, ArrowUp, Renew } from "@carbon/react/icons/index";
import { useQuery } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";
import { getInstanaSynthtic } from "Services/ServerApi";
import IncidentTabs from "./incidentTabs";
import { v4 as uuidv4 } from "uuid";

const PncMnemonic = ({ PNCJson }) => {
  const { keycloak } = useKeycloak();
  const [customer, setCustomerJou] = useState([]);
  const [service, setServices] = useState([]);
  const [title, setTitle] = useState("mnemonic");
  const [clickedTiles, setClickedTiles] = useState("");
  const [activeBox, setActiveBox] = useState("");
  const [appAlerts, setAppAlerts] = useState([]);
  const [refreshIcon, setRefreshicon] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState("static");
  const [workFlowJson, setWorkFlowJson] = useState(PNCJson);
  const [selectedService, setSelectedService] = useState("");
  const [simulationMode, setSimulationMode] = useState(false);
  const [boxData, setBoxData] = useState([
    { name: "Incident", count: 0 },
    { name: "Changes", count: 0 },
    { name: "Platform Alerts", count: 0 },
    { name: "Application Alerts", count: 0 },
  ]);

  const { data: instanaSynthetic, refetch: synthetic } = useQuery(
    ["Synthetic"],
    async () => await getInstanaSynthtic(keycloak, uuidv4()),
    {
      retry: 1,
      enabled: false,
    }
  );

  useEffect(() => {
    if (instanaSynthetic !== undefined && instanaSynthetic.data !== undefined) {
      let sync = 10,
        error = 0;
      instanaSynthetic.data.sync.testResult[0]["metrics"].forEach((test) => {
        sync = test["synthetic.metricsStatus"];
      });
      instanaSynthetic.data.metric.items[0]["metrics"][
        "erroneousCalls.sum"
      ].forEach((err, index) => {
        error = err[1];
      });
      const synCalc = sync * 10;
      const fixVal = synCalc > 9 ? 0 : 1;
      const filternm = PNCJson.data.map((pnc) => {
        return pnc["name"] === "Web Banking Application"
          ? {
              ...pnc,
              value: `${((2400 + synCalc) / 25).toFixed(fixVal).toString()}%`,
              customerJou: pnc.customerJou.map((jou) => {
                return jou["name"] === "Wires/Transfers"
                  ? {
                      ...jou,
                      value: `${((1100 + synCalc) / 12)
                        .toFixed(fixVal)
                        .toString()}%`,
                      service: jou.service.map((serv) => {
                        return serv["name"] ===
                          "Add an external account to make an external transfer"
                          ? {
                              ...serv,
                              availability: synCalc.toFixed(fixVal).toString(),
                              errors: synCalc > 99 ? error : 1,
                              errorMessage:
                                serv.errorMessage !== undefined &&
                                serv.errorMessage.length > 0
                                  ? serv.errorMessage.map((err) => {
                                      return {
                                        ...err,
                                        "Failure Rate": `${
                                          ((10 - error) / 10) * 100
                                        }%`,
                                        Count: 10,
                                      };
                                    })
                                  : [],
                            }
                          : serv;
                      }),
                    }
                  : jou;
              }),
            }
          : pnc;
      });
      setRefreshicon(false);
      // setSimulationMode(false);
      setWorkFlowJson({ data: filternm });
    }
  }, [instanaSynthetic]);

  useEffect(() => {
    simulate();
  }, []);

  // useEffect(() => {
  //     if (similarInc !== undefined && similarInc.data !== undefined) {
  //         setMLSimilarInc(similarInc.data)
  //     }
  // }, [similarInc])

  // useEffect(() => {
  //     if (mlQuery !== "") {
  //         refetch()
  //     }
  // }, [mlQuery])

  const simulate = () => {
    if (simulationMode === true) {
      const filternm = PNCJson.data.map((pnc) => {
        return pnc["name"] === "Web Banking Application"
          ? {
              ...pnc,
              value: "",
              customerJou: pnc.customerJou.map((jou) => {
                return jou["name"] === "Card Management"
                  ? {
                      ...jou,
                      value: "100%",
                    }
                  : jou;
              }),
            }
          : pnc;
      });
      setWorkFlowJson({ data: filternm });
    } else {
      const filternm = PNCJson.data.map((pnc) => {
        return pnc["name"] === "Web Banking Application"
          ? {
              ...pnc,
              value: "95%",
              customerJou: pnc.customerJou.map((jou) => {
                return jou["name"] === "Card Management"
                  ? {
                      ...jou,
                      value: "95%",
                    }
                  : jou;
              }),
            }
          : pnc;
      });
      setWorkFlowJson({ data: filternm });
    }
  };

  const handleOnClick = (data) => {
    if (data.customerJou !== undefined && data.customerJou.length > 0) {
      setTitle("customer");
      setCustomerJou(data);
      setServices([]);
      if (data.name !== "Web Banking Application") {
        setSelectedFlow("live");
      } else {
        setSelectedFlow("static");
        setBoxData((boxData) =>
          boxData.map((box) => {
            return box.name === "Incident"
              ? {
                  ...box,
                  count: 2,
                }
              : box;
          })
        );
      }
    } else if (data.service !== undefined && data.service.length > 0) {
      setTitle("services");
      setServices(data);
      let count = 0;
      data.service.forEach((ct) => {
        count += ct.errors;
      });
      setBoxData((boxData) =>
        boxData.map((box) => {
          return box.name === "Application Alerts"
            ? {
                ...box,
                count: count,
                services_data: data.service,
              }
            : box;
        })
      );
    } else {
      setTitle("");
      if (data.errorMessage !== undefined) {
        // const em = data.errorMessage.map((err, index) => {
        //   return {
        //     ...err,
        //     id: (index + 1).toString(),
        //   };
        // });
        // setErrorsRow(em)
      }

      const ele = document.getElementById(`row8`);
      ele.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onclickTitle = (text) => {
    setTitle(text);
  };

  const showServices = () => {
    return (
      <ul
        className="service-Tile"
        style={{
          height: `${service.service.length <= 10 ? "195px" : "350px"}`,
        }}
      >
        {service.service.map((serv) => {
          return (
            <li
              key={serv.name}
              onClick={() => onclickService(serv)}
              className={
                parseInt(serv.errors) > 2 ||
                parseInt(serv.availability) < parseInt("100")
                  ? "red"
                  : "green"
              }
            >
              <h6>{serv.name}</h6>
              <div className="errors">
                <span>{serv.errors}</span>
                <h6>{simulationMode ? "Anamoly" : "Errors"}</h6>
              </div>
              <div className="availablity">
                <span>
                  {serv.availability !== undefined
                    ? `${serv.availability}%`
                    : "100%"}
                </span>
                <h6>Availability</h6>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const onclickService = (service) => {
    if (service !== undefined && service.errorMessage !== undefined) {
      const em = service.errorMessage.map((err, index) => {
        return {
          ...err,
          id: (index + 1).toString(),
        };
      });
      setAppAlerts(em);
    }

    setSelectedService(service.name);
    const ele = document.getElementById(`row8`);
    if (simulationMode) {
      setClickedTiles("");
    }
    ele.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const tileClick = (data) => {
    if (data.name === "Incident") {
      setClickedTiles("Incidents");
      setActiveBox("Incident");
      const ele = document.getElementById(`row8`);
      ele.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    if (data.name === "Application Alerts") {
      setClickedTiles("Application Alerts");
      setActiveBox("Application Alerts");
    }

    if (
      data.services_data !== undefined &&
      data.services_data.errorMessage !== undefined
    ) {
      const em = data.services_data.errorMessage.map((err, index) => {
        return {
          ...err,
          id: (index + 1).toString(),
        };
      });
      setAppAlerts(em);
    } else if (data.services_data !== undefined) {
      let filterError = [];
      data.services_data.forEach((er) => {
        if (er.errorMessage !== undefined) {
          filterError = er.errorMessage;
        }
      });
      const em = filterError.map((err, index) => {
        return {
          ...err,
          id: (index + 1).toString(),
        };
      });
      setAppAlerts(em);
    }
    const ele = document.getElementById(`row8`);
    ele.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const snowDataFromChild = (data) => {
    setBoxData((boxData) =>
      boxData.map((box) => {
        return box.name === "Incident"
          ? {
              ...box,
              count: data.length,
            }
          : box;
      })
    );
  };

  function checkIncidentBoxLength() {
    let leng = 0;

    boxData.forEach((box) => {
      if (box.name === "Incident") {
        leng = box.count;
      }
    });

    return leng === 0 ? false : true;
  }

  const refreshData = () => {
    const update = workFlowJson.data.map((flow) => {
      return flow["name"] === "Web Banking Application"
        ? {
            ...flow,
            value: "",
          }
        : flow;
    });
    setWorkFlowJson({ data: update });
    setRefreshicon(true);
    synthetic();
  };

  // const ontoggle = (e) => {
  //   if (e === true) {
  //     const filternm = PNCJson.data.map((pnc) => {
  //       return pnc["name"] === "Web Banking Application"
  //         ? {
  //             ...pnc,
  //             value: "",
  //             customerJou: pnc.customerJou.map((jou) => {
  //               return jou["name"] === "Card Management"
  //                 ? {
  //                     ...jou,
  //                     value: "100%",
  //                   }
  //                 : jou;
  //             }),
  //           }
  //         : pnc;
  //     });
  //     setClickedTiles("");
  //     setRefreshicon(true);
  //     setSimulationMode(true);
  //     setTitle("mnemonic");
  //     setCustomerJou([]);
  //     setServices([]);
  //     setWorkFlowJson({ data: filternm });
  //     synthetic();
  //   } else {
  //     const filternm = PNCJson.data.map((pnc) => {
  //       return pnc["name"] === "Web Banking Application"
  //         ? {
  //             ...pnc,
  //             value: "95%",
  //             customerJou: pnc.customerJou.map((jou) => {
  //               return jou["name"] === "Card Management"
  //                 ? {
  //                     ...jou,
  //                     value: "95%",
  //                   }
  //                 : jou;
  //             }),
  //           }
  //         : pnc;
  //     });
  //     setSimulationMode(false);
  //     setTitle("mnemonic");
  //     setCustomerJou([]);
  //     setServices([]);
  //     setWorkFlowJson({ data: filternm });
  //   }
  // };

  console.log(
    "workFlowJson",
    service,
    workFlowJson,
    simulationMode,
    refreshIcon,
    checkIncidentBoxLength()
  );

  return (
    <>
      <Row id="row1">
        <Column lg={16} md={8} sm={4} className="paddingRgt paddingLft">
          <div className="f-col">
            <h4 className="row-title">
              {title === "mnemonic" ? (
                <Subtract onClick={() => onclickTitle()} size={20} />
              ) : (
                <Add size={20} onClick={() => onclickTitle("mnemonic")} />
              )}{" "}
              <h6 style={{ marginTop: "6px", fontWeight: "600" }}>
                Mnemonic{customer.name ? ": " : null}
              </h6>{" "}
              {customer.name ? (
                <Tag className="some-class" type="teal" title="Clear Filter">
                  {customer.name}
                </Tag>
              ) : null}
            </h4>
            {/* <Toggle
              labelText=""
              size="sm"
              labelA="Simulation Mode"
              labelB="Simulation Mode"
              onToggle={(e) => ontoggle(e)}
              id="toggle-1"
            /> */}
            <span className="refresh" onClick={() => refreshData()}>
              <Renew className={refreshIcon ? "rotateIcons" : ""} /> Refresh
            </span>
          </div>
        </Column>
        {title === "mnemonic" && workFlowJson.data.length > 0 ? (
          <PncOverview
            PNCJson={workFlowJson.data}
            handleOnClick={handleOnClick}
            colmn={4}
            flow="mnemonic"
          />
        ) : null}
      </Row>
      {customer.customerJou !== undefined &&
      customer.customerJou.length > 0 &&
      refreshIcon === false ? (
        <Row id="row2">
          <Column lg={16} md={8} sm={4} className="paddingRgt paddingLft">
            <h4 className="row-title">
              {title === "customer" ? (
                <Subtract onClick={() => onclickTitle()} size={20} />
              ) : (
                <Add size={20} onClick={() => onclickTitle("customer")} />
              )}{" "}
              <h6 style={{ marginTop: "6px", fontWeight: "600" }}>
                Customer Journey{service.name ? ": " : null}
              </h6>{" "}
              {service.name ? (
                <Tag className="some-class" type="teal" title="Clear Filter">
                  {service.name}
                </Tag>
              ) : null}
            </h4>
          </Column>
          {title === "customer" ? (
            <PncOverview
              PNCJson={customer.customerJou}
              handleOnClick={handleOnClick}
              colmn={4}
              flow="customer"
            />
          ) : null}
        </Row>
      ) : null}
      {service.service !== undefined && service.service.length > 0 ? (
        <Row id="row3">
          {!simulationMode ? (
            <Column
              lg={service.service.length <= 10 ? 4 : 2}
              md={2}
              sm={2}
              className="paddingRgt paddingLft"
            >
              {!simulationMode && checkIncidentBoxLength() ? (
                boxData.map((box, index) => (
                  <ClickableTile
                    id={index}
                    onClick={() => tileClick(box)}
                    aria-label={`clickableTile-${index}`}
                    key={uuidv4()}
                    className={
                      activeBox === box.name
                        ? `box-tile active ${
                            service.service.length <= 10 ? "" : "serv-box"
                          }`
                        : `box-tile ${
                            service.service.length <= 10 ? "" : "serv-box"
                          }`
                    }
                  >
                    <h6>{box.name}</h6>
                    <span
                      style={{
                        fontSize: "2rem",
                        color: `${box.count > 0 ? "#da1e28" : "#198038"}`,
                      }}
                    >
                      {box.count}{" "}
                      {box.count > 0 ? (
                        <ArrowUp size={12} stroke="#da1e28" strokeWidth="3px" />
                      ) : null}
                    </span>
                  </ClickableTile>
                ))
              ) : (
                <SkeletonPlaceholder />
              )}
            </Column>
          ) : null}
          {refreshIcon === false ? (
            <Column
              lg={service.service.length <= 10 ? 12 : 14}
              md={8}
              sm={4}
              className="paddingRgt paddingLft"
            >
              {checkIncidentBoxLength() ? (
                <>
                  <h5 style={{ fontWeight: "600" }}>Services</h5>
                  {showServices()}
                </>
              ) : (
                <SkeletonPlaceholder />
              )}
            </Column>
          ) : null}
        </Row>
      ) : null}
      <div id="row8" style={{ height: "300px" }}>
        {service.service !== undefined && service.service.length > 0 ? (
          <IncidentTabs
            simulationMode={simulationMode}
            boxData={boxData}
            appAlerts={appAlerts}
            clickedTiles={clickedTiles}
            snowDataTo={snowDataFromChild}
            selectedService={selectedService}
            selectedFlow={selectedFlow}
          />
        ) : null}
      </div>
    </>
  );
};

export default PncMnemonic;
