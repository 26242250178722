import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import InlineLoader from "Carbon-Components/InlineLoader";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import ErrorWhileFetching from "Components/ICPSA/utilities/ErrorWhileFetching";
import React, { useEffect, useState } from "react";
import "./JobObserv.scss";
import DashboardDetails from "./JobWorkflowView";

const DashboardDetailsIndex = (props) => {
    // const [attFlow, setAttFlow] = useState("");
    // const { t } = useTranslation();

    const [workflowDetails, setWorflowDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [err, setErr] = useState(false);

    const resolveFunc = () => {
        if (props?.location && props?.location?.state) {
            setWorflowDetails(props.location.state.data)
        }
        setIsLoading(false)
        setShow(true)
      };
    
    const rejectFunc = (err) => {
        setErr(true);
    };

    const getDataAsync = () => {
        setShow(false)
        return new Promise((resolve, reject) => {
            try {
              setTimeout(() => {
                resolve()
              }, 0);
            } catch (error) {
              reject(error);
            }
        });
    }

    useEffect(() => {
        if(err) setShow(true)
    }, [err])
      
    useEffect(() => {

            // if (props?.location && props?.location?.state) {
            //     setWorflowDetails(props.location.state.data)
            // }
            // setIsLoading(false)
            getDataAsync().then(resolveFunc).catch(rejectFunc)
    }, [props.location])


    //const workflowDetails = useSelector((state) => state.ATTReducer);
    const [details, setDetails] = useState([]);

    // useEffect(() => {
    //     if ( workflowDetails !== undefined && Object.entries(workflowDetails).length > 0) {
    //         setDetails(workflowDetails.Details)
    //     }
    // }, [workflowDetails])

    const isDataExist = Object.entries(workflowDetails).length > 0

    const getData = () => {
        if (!show) {
            return (
              <InlineLoader
                status="active"
                description="Please wait, it may take a while..."
              />
            );
          }
          if (err) {
            return <ErrorWhileFetching />;
          }
          if(isDataExist){
            return <DashboardDetails location={props.location} />
          }
          return <DataUnAvailable />
    }


    return (
            <FlexGrid className="att-dash">
                <Row>
                    <Column lg={12} md={8} sm={4}>
                            <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                                <BreadcrumbItem href="#/itOperations">
                                    Integrated Operations
                                </BreadcrumbItem>
                                {isDataExist ?
                                <BreadcrumbItem isCurrentPage>
                                    {workflowDetails.dataProduct}
                                </BreadcrumbItem> : null}
                            </Breadcrumb>
                    </Column>
                </Row>
                {getData()}
            </FlexGrid>
    )
}

export default DashboardDetailsIndex