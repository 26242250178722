import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ELK from "elkjs/lib/elk.bundled";
import { path as d3Path } from "d3-path";
import { CardNodeTitle, CardNodeColumn } from '@carbon/charts-react';
import { CardNode, Edge, TeeMarker } from "@carbon/charts-react";

const Link = ({ link }) => {
  const sections = link.sections[0];
  const path = d3Path();

  path.moveTo(sections.startPoint.x, sections.startPoint.y);

  if (sections.bendPoints) {
    sections.bendPoints.forEach((b) => {
      path.lineTo(b.x, b.y);
    });
  }

  path.lineTo(sections.endPoint.x, sections.endPoint.y);

  return (
    <Edge
      path={path.toString()}
      markerStart="circle"
      markerEnd="tee"
      variant="dash-sm"
      className="draw-edge"
    />
  );
};

const Node = ({ x, y, height, width, title, data, onClick }) => {
  const heig = height - 30;
  return (
    <foreignObject
      transform={`translate(${x},${y - 30})`}
      height={height - 30}
      width={width}
      style={{ overflow: "visible" }}
    >
      <div style={{ height: heig, width }}>
        {/* <ShapeNode renderIcon={<User16 />} size={"100%"} /> */}
        <CardNode
          color={data.color}
          className="card-node"
          onClick={() => onClick(data)}
        >
          <CardNodeColumn>
            <CardNodeTitle>
              <div className="elk-title-tooltip">{title}</div>
            </CardNodeTitle>
          </CardNodeColumn>
        </CardNode>
      </div>
    </foreignObject>
  );
};

const Elk = ({ nodes, links, layout, nodeClick }) => {
  const elk = new ELK();

  const [positions, setPositions] = useState(null);

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": layout,
      "elk.padding": "[left=50, top=50, right=50, bottom=50]",
      separateConnectedComponents: false,
      "spacing.nodeNode": 100,
      "spacing.nodeNodeBetweenLayers": 60,
      // "spacing.nodeNode": 50,
      interactiveLayout: true,
      "elk.direction": "RIGHT",
      // "elk.interactive": "Layer & Order Preserving",
      "elk.alignment": "CENTER",

      "elk.interactive": "Layer Preserving",
      "elk.contentAlignment": "V_CENTER",
      "crossingMinimization.semiInteractive": true,
      "nodePlacement.strategy": "NETWORK_SIMPLEX",
    },
    children: nodes,
    edges: links,
  };

  useEffect(() => {
    elk
      .layout(graph)
      .then((g) => setPositions(g))
      .catch(console.error);
  }, []);

  if (!positions) return null;

  const buildNodes = () => {
    const { children } = positions;

    return children.map((node) => {
      return (
        <Node
          key={`node_${uuidv4()}`}
          x={node.x}
          y={node.y}
          height={node.Height}
          width={node.width}
          title={node.title}
          data={node}
          onClick={nodeClick}
        />
      );
    });
  };

  const buildLinks = () => {
    const { edges } = positions;

    return edges.map((edge) => {
      return <Link key={`link_${uuidv4()}`} link={edge} />;
    });
  };

  const nodeElements = buildNodes();
  const linkElements = buildLinks();

  return (
    <div
      className={`pnc-topo`}
      style={{
        position: `relative`,
        height: "550px",
        width: "100%",
        padding: 20,
      }}
    >
      <svg fill="white" style={{ height: "100%", width: "100%" }}>
        <defs>
          <TeeMarker id="tee" />
          {/* <CircleMarker id="circle" position="start" /> */}
        </defs>
        {linkElements}
        {nodeElements}
      </svg>
    </div>
  );
};

export default Elk;
