export const convertUTCtoFormat = (utcDate) => {
    // Parse the UTC date
    const date = new Date(utcDate);
    
    // Extract individual components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1); // Months are zero-based
    const day = String(date.getUTCDate());
    const hours = String(date.getUTCHours());
    const minutes = String(date.getUTCMinutes());
    const seconds = String(date.getUTCSeconds());
    
    // Format the date
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const FAILED_JOBS_HEADER_DATA = [
      {
        key: "jobname",
        header: "Job Name",
      },
      {
        key: "jobid",
        header: "Job Id",
      },
      {
        key: "runid",
        header: "Run Id",
      },
      {
        key: "observedtime",
        header: "Observed Time",
      },
      {
        key: "job_status",
        header: "Job Status",
      },
];