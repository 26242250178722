import {
    Breadcrumb,
    BreadcrumbItem,
    Column,
    ErrorBoundary,
    FlexGrid,
    Row,
    SkeletonText,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    StructuredListWrapper,
    Tile
} from "@carbon/react";
import { useQuery } from "@tanstack/react-query";
import ChartContainer from "Carbon-Charts/ChartContainer";
import CommonMLModal from "Carbon-Components/Modals/CommonModal";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./vs.scss";
// import DynatraceIndex from "Components/Real-time Dashboard/Dynatrace";

const Go2Application = (props) => {
    const { workflow } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [recentIncidents] = useState({});
    const [valueStreamData, setValueStream] = useState();

    const { data: valueStream } = useQuery(["valueStream"]);

    useEffect(() => {
        if (valueStream !== undefined && valueStream.data !== undefined) {
            const response = valueStream.data.valueStream;
            const filterWF = response[0].data.filter((fil) => fil["title"] === workflow)
            setValueStream(filterWF)
        }
    }, [valueStream])


    const getRowData = (key) => {
        let keyData = [];
        valueStreamData.forEach((vs) => {
            vs.workflowDetails.forEach((workflow) => {
                workflow["Row2"] !== undefined && workflow["Row2"].forEach((row2) => {
                    row2.steps !== undefined && row2.steps.forEach((step) => {
                        if (step[key]) {
                            keyData = step[key]
                        }
                    })
                })
            })
        })
        return keyData
    }

    const getEvents = () => {
        return valueStreamData !== undefined && valueStreamData.length > 0 ? getRowData("recentIncidents").map((similar) => {
            const newSim = Object.entries(similar).map(([key, value]) => ({ key, value }))
            return <ul className="err-log">{newSim.map((erlog) => {
                return <li>
                    <h5>{erlog.key}</h5><span>{erlog.key === "eventId" ? <a href="https://aiopsri-ibmdso.instana.io/" target="_blank" rel="noopener noreferrer">{erlog.value}</a> : erlog.value}</span>
                </li>
            })}</ul>
        }) : <ul className="err-log">{[1, 2, 3, 4].map(() => {
            return <li>
                <h5><SkeletonText /></h5><span><SkeletonText /></span>
            </li>
        })}</ul>
    }


    const getLoadTime = () => {
        if (valueStreamData !== undefined && valueStreamData.length > 0) {
            const infraMetrics = getRowData("Load Time");
            return {
                label: "",
                dataType: "area",
                data: {
                  chartData: infraMetrics,
                  chartOptions: {
                    title: "Load Time",
                    axes: {
                      left: {
                        title:"Count",
                        mapsTo: "value",
                        scaleType: "linear",
                      },
                      bottom: {
                        title: "Time",
                        mapsTo: "date",
                        scaleType: "labels",
                      },
                    },
                    height: "250px",
                    grid: {
                      x: {
                        enabled: false,
                      },
                      y: {
                        enabled: false,
                      },
                    },
                    color: {
                      gradient: {
                        enabled: true,
                      },
                    },
                  },
                },
              };
        };
    }

    const getErrorMetrics = () => {
        if (valueStreamData !== undefined && valueStreamData.length > 0) {
            const infraMetrics = getRowData("Erros");
            return {
                label: "",
                dataType: "bar",
                data: {
                  chartData: infraMetrics,
                  chartOptions: {
                    title: "Errors",
                    color: {
                      scale: {
                        errors: "#da1e28",
                      },
                    },
                    axes: {
                      left: {
                        mapsTo: "value",
                        "title":"Count"
                      },
                      bottom: {
                        "title":"Time",
                        mapsTo: "date",
                        scaleType: "labels",
                        ticks: {
                          rotation: "always",
                        },
                      },
                    },
                    bars: {
                      width: 5,
                    },
                    height: "250px",
                  },
                },
              };
        }
    }

    const getRecentChanges = () => {
        return valueStreamData !== undefined && valueStreamData.length > 0 ? getRowData("recentChanges").map((details) => {
            return (
                <>
                    <StructuredListBody>
                        <StructuredListRow className="table-row">
                            <StructuredListCell noWrap>
                                {details.Ticket}
                            </StructuredListCell>
                            <StructuredListCell>
                                {details.Description}
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListBody>
                </>
            );
        }) : <StructuredListBody>
            <StructuredListRow className="table-row">
                <StructuredListCell noWrap>
                    <SkeletonText />
                </StructuredListCell>
                <StructuredListCell>
                    <SkeletonText />
                </StructuredListCell>
            </StructuredListRow>
        </StructuredListBody>
    }

    const getRecentIncidents = () => {
        return valueStreamData !== undefined && valueStreamData.length > 0 ? getRowData("recentIncidents").map((details) => {
            return (
                <>
                    <StructuredListBody>
                        <StructuredListRow className="table-row">
                            <StructuredListCell noWrap>
                                {details.Ticket}
                            </StructuredListCell>
                            <StructuredListCell></StructuredListCell>
                            <StructuredListCell>
                                {details.Description}
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListBody>
                </>
            );
        }) : <StructuredListBody>
            <StructuredListRow className="table-row">
                <StructuredListCell noWrap>
                    <SkeletonText />
                </StructuredListCell>
                <StructuredListCell>
                    <SkeletonText />
                </StructuredListCell>
            </StructuredListRow>
        </StructuredListBody>
    }

    const getErrorMessage = () => {
        return valueStreamData !== undefined && valueStreamData.length > 0 ? getRowData("errorMessage").map((details) => {
            return (
                <>
                    <StructuredListBody>
                        <StructuredListRow className="table-row">
                            <StructuredListCell noWrap>
                                {details.error}
                            </StructuredListCell>
                            <StructuredListCell>
                                {details["Occurrences"]}
                            </StructuredListCell>
                            <StructuredListCell>
                                {details.AffectedUsers}
                            </StructuredListCell>
                        </StructuredListRow>
                    </StructuredListBody>
                </>
            );
        }) : <StructuredListBody>
            <StructuredListRow className="table-row">
                <StructuredListCell noWrap>
                    <SkeletonText />
                </StructuredListCell>
                <StructuredListCell>
                    <SkeletonText />
                </StructuredListCell>
                <StructuredListCell>
                    <SkeletonText />
                </StructuredListCell>
                <StructuredListCell>
                    <SkeletonText />
                </StructuredListCell>
            </StructuredListRow>
        </StructuredListBody>
    }

    return (
        <FlexGrid fullWidth>
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">IT Value Stream Observability</BreadcrumbItem>
                        <BreadcrumbItem href={`#/workflowView/${workflow}`}>{workflow}</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Workflow View Details
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row className="go2">
                <Column lg={8} md={8} className="tickets pr-0">
                    <Tile className="full-height">
                        <h5 className="last-row-title" style={{ fontWeight: "800" }}>
                            Recent Changes
                        </h5>
                        <StructuredListWrapper>
                            <StructuredListHead>
                                <StructuredListRow head>
                                    <StructuredListCell head>Change Ticket</StructuredListCell>
                                    <StructuredListCell head>Description</StructuredListCell>
                                </StructuredListRow>
                            </StructuredListHead>
                            {getRecentChanges()}
                        </StructuredListWrapper>
                    </Tile>
                </Column>
                <Column lg={8} md={4} sm={4} className="recent-head pl-0 pr-0">
                    <Tile className="full-height">
                        <h5 className="box-heading">Recent Incidents</h5>
                        <StructuredListWrapper className="linked-cust" isCondensed={true}>
                            <StructuredListHead>
                                <StructuredListRow head>
                                    <StructuredListCell head>Ticket</StructuredListCell>
                                    <StructuredListCell head>Sev</StructuredListCell>
                                    <StructuredListCell head>Description</StructuredListCell>
                                </StructuredListRow>
                            </StructuredListHead>
                            {getRecentIncidents()}
                        </StructuredListWrapper>
                    </Tile>
                </Column>
            </Row>
            <Row className="row-sec go2">
                <Column lg={6} md={4} sm={4} className="recent-head pl-0 pr-0">
                    <Tile className="full-height">
                        <h5 className="box-heading">Recent Events</h5>
                        {getEvents()}
                    </Tile>
                </Column>
                <Column lg={5} md={6} sm={4} className="pl-0 pr-0">
                    <ErrorBoundary fallback={"Data is unavailable"}>
                        {valueStreamData !== undefined ? <ChartContainer data={getLoadTime()} /> : null }
                    </ErrorBoundary>
                </Column>
                <Column lg={5} md={6} sm={4} className="pr-0">
                    <ErrorBoundary fallback={"Data is unavailable"}>
                    {valueStreamData !== undefined ? <ChartContainer data={getErrorMetrics()} /> : null }
                    </ErrorBoundary>
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4} className="pl-0 pr-0">
                    <Tile className="full-height">
                        <StructuredListWrapper>
                            <StructuredListHead>
                                <StructuredListRow head>
                                    <StructuredListCell head>Errors</StructuredListCell>
                                    <StructuredListCell head>Occurrences</StructuredListCell>
                                    <StructuredListCell head>Affected Users</StructuredListCell>
                                </StructuredListRow>
                            </StructuredListHead>
                            {getErrorMessage()}
                        </StructuredListWrapper>
                    </Tile>
                </Column>
            </Row>
            {showModal && (
                <CommonMLModal
                    setShowModal={setShowModal}
                    ticketDetails={recentIncidents}
                />
            )}
        </FlexGrid>
    );
};

export default Go2Application;