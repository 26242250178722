import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  FlexGrid,
  Modal,
  Row,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tile,
} from "@carbon/react";
import React, { useContext, useState } from "react";
import TreeDiagram from "./TreeDiagram";
import "./fins.scss";
// import recentIncidentsData from "./recentIncidents.json";
// import inferenceData from "./inferenceData.json";
import ChartContainer from "Carbon-Charts/ChartContainer";
import CommonMLModal from "Carbon-Components/Modals/CommonModal";
// import CPULoad from "./infra_5mins.json";
// import Error from "./errorMetrics.json";
// import Latency from "./application_metrics.json";
import { Catalog } from "@carbon/react/icons";
// import AlertsLogs from "./logs.json";
import Context from "Context/Context";
import { v4 as uuidv4 } from "uuid";
// import DynatraceIndex from "Components/Real-time Dashboard/Dynatrace";

const Go2Application = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [recentIncidents, setRecentIncidents] = useState({});
  const [dateIndexChange, onDateIndexChange] = useState(2);
  const [dateChange, onDateChange] = useState("1 Day");
  const [alertLogs, setShowAlert] = useState(false);
  const context = useContext(Context);
  const title = sessionStorage.getItem("portfolioTitle");
  const selectedFlow = context.valueStreamData.data.filter(
    (data) => data.title === title
  );
  const CJstepName = sessionStorage.getItem("CJstepName");
  const selectedCustomerJourney = selectedFlow[0].journeySteps.filter(
    (fil) => fil.name === CJstepName
  );

  // console.log("selectedCustomerJourney", selectedCustomerJourney);

  // const TestComponent = (title, value) => {
  //   return (
  //     <div className="example-layer-test-component">
  //       {title} <span>{value}</span>
  //     </div>
  //   );
  // };

  // const onTicketIdClick = (details) => {
  //   setRecentIncidents(details);
  //   setShowModal(true);
  // };

  // const ReponseTime = () => {
  //   const data = [
  //     {
  //       group: "Load Time",
  //       date: "07:17:54",
  //       value: 20,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:23:54",
  //       value: 23,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:28:54",
  //       value: 27,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:33:54",
  //       value: 22,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:38:54",
  //       value: 24,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:43:54",
  //       value: 26,
  //       surplus: 1176513114.52198,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:48:54",
  //       value: 28,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:53:54",
  //       value: 24,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "07:58:00",
  //       value: 80,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "08:03:54",
  //       value: 32,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "08:08:54",
  //       value: 30,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "08:13:54",
  //       value: 30,
  //       surplus: 633229392.4724686,
  //     },
  //     {
  //       group: "Load Time",
  //       date: "08:18:54",
  //       value: 32,
  //       surplus: 12493.574396571927,
  //     },
  //   ];
  //   return {
  //     label: "",
  //     dataType: "area",
  //     data: {
  //       chartData: data,
  //       chartOptions: {
  //         title: "Load Time",
  //         axes: {
  //           left: {
  //             title: "Count",
  //             mapsTo: "value",
  //             scaleType: "linear",
  //           },
  //           bottom: {
  //             title: "Time",
  //             mapsTo: "date",
  //             scaleType: "labels",
  //           },
  //         },
  //         // style: {
  //         //   /**
  //         //    * optional prefixing string for css classes (defaults to 'cc')
  //         //    */
  //         //   prefix: "myClass"
  //         // },
  //         height: "250px",
  //         grid: {
  //           x: {
  //             enabled: false,
  //           },
  //           y: {
  //             enabled: false,
  //           },
  //         },
  //         color: {
  //           gradient: {
  //             enabled: true,
  //           },
  //         },
  //       },
  //     },
  //   };
  // };

  // const getErrorResponse = () => {
  //   const data = [
  //     {
  //       group: "errors",
  //       date: "07:17:54",
  //       value: 10,
  //     },
  //     {
  //       group: "errors",
  //       date: "07:27:54",
  //       value: 12,
  //     },
  //     {
  //       group: "errors",
  //       date: "07:37:54",
  //       value: 14,
  //     },
  //     {
  //       group: "errors",
  //       date: "07:47:54",
  //       value: 14,
  //     },
  //     {
  //       group: "errors",
  //       date: "07:57:54",
  //       value: 35,
  //     },
  //   ];

  //   return {
  //     label: "",
  //     dataType: "bar",
  //     data: {
  //       chartData: data,
  //       chartOptions: {
  //         title: "Errors",
  //         color: {
  //           scale: {
  //             errors: "#da1e28",
  //           },
  //         },
  //         axes: {
  //           left: {
  //             mapsTo: "value",
  //             title: "Count",
  //           },
  //           bottom: {
  //             title: "Time",
  //             mapsTo: "date",
  //             scaleType: "labels",
  //             ticks: {
  //               rotation: "always",
  //             },
  //           },
  //         },
  //         bars: {
  //           width: 5,
  //         },
  //         height: "250px",
  //       },
  //     },
  //   };
  // };

  const getEvents = () => {
    return selectedCustomerJourney[0].recentIncidents.map((similar) => {
      const newSim = Object.entries(similar).map(([key, value]) => ({
        key,
        value,
      }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newSim.map((erlog) => {
            return (
              <li key={uuidv4()}>
                <h5>{erlog.key}</h5>
                <span>
                  {erlog.key === "eventId" ? (
                    <a
                      href="https://aiopsri-ibmdso.instana.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {erlog.value}
                    </a>
                  ) : (
                    erlog.value
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      );
    });
  };

  const getInstanaInfraMemoryMetrics = () => {
    // const infraMetrics = CPULoad;

    // let pastMinForavail = getPastMinutes(5, "cpu");

    // let metricsData = [];
    // infraMetrics?.items?.length > 0 &&
    //   metricsData.push(infraMetrics.items[0].metrics);

    // let newArray = [];

    // if (metricsData.length > 0) {
    //   metricsData.forEach((finalMetrics) => {
    //     Object.entries(finalMetrics).forEach((key) => {
    //       key[1].forEach((res) => {
    //         let newObj = {};
    //         let timeStamp = res[0];
    //         if (timeStamp.length > 11) {
    //           let num = res[0];
    //           let str = num.toString();
    //           let removedLasttwo = str.substring(0, str.length - 2);
    //           timeStamp = parseInt(removedLasttwo, 10);
    //         }

    //         let timest = new Date(timeStamp);
    //         newObj = {
    //           group: key[0],
    //           date: getCurrentDateTime(timest),
    //           value: res[1],
    //         };
    //         newArray.push(newObj);
    //       });
    //     });
    //   });
    // }

    // let pastMin = [...pastMinForavail];
    // console.log("pastMinForavail ==============>>>>>", newArray);
    // newArray.forEach((res) => {
    //   pastMin.forEach((mint) => {
    //     let dateToCompare = new Date(mint.date);
    //     dateToCompare.setMinutes(new Date(mint.date).getMinutes() - 5);
    //     if (res.date <= mint.date && res.date > dateToCompare.toISOString()) {
    //       if (res.group === "cpu.used" && mint.group === "cpu") {
    //         pastMinForavail.push({
    //           group: "Cpu Used",
    //           date: res.date,
    //           value: res.value,
    //         });
    //       }
    //     }
    //   });
    // });

    // let memory = [
    //   { group: "cpu.used", date: "2024-03-14T01:00:00.000Z", value: 0.0710309 },
    //   { group: "cpu.used", date: "2024-03-14T01:05:00.000Z", value: 0.072632 },
    //   { group: "cpu.used", date: "2024-03-14T01:10:00.000Z", value: 0.0733333 },
    //   { group: "cpu.used", date: "2024-03-14T01:15:00.000Z", value: 0.0773333 },
    //   { group: "cpu.used", date: "2024-03-14T01:20:00.000Z", value: 0.0754 },
    //   { group: "cpu.used", date: "2024-03-14T01:25:00.000Z", value: 0.0774667 },
    //   { group: "cpu.used", date: "2024-03-14T01:30:00.000Z", value: 0.0748667 },
    //   { group: "cpu.used", date: "2024-03-14T01:35:00.000Z", value: 0.0788 },F
    //   { group: "cpu.used", date: "2024-03-14T01:40:00.000Z", value: 0.0790333 },
    //   { group: "cpu.used", date: "2024-03-14T01:45:00.000Z", value: 0.0749667 },
    //   { group: "cpu.used", date: "2024-03-14T01:50:00.000Z", value: 0.0768333 },
    //   { group: "cpu.used", date: "2024-03-14T01:55:00.000Z", value: 0.0768333 },
    //   { group: "cpu.used", date: "2024-03-14T02:00:00.000Z", value: 0.0768 },
    //   { group: "cpu.used", date: "2024-03-14T02:05:00.000Z", value: 0.0788333 },
    //   { group: "cpu.used", date: "2024-03-14T02:10:00.000Z", value: 0.0783333 },
    //   { group: "cpu.used", date: "2024-03-14T02:15:00.000Z", value: 0.0786333 },
    //   { group: "cpu.used", date: "2024-03-14T02:20:00.000Z", value: 0.0779333 },
    //   { group: "cpu.used", date: "2024-03-14T02:25:00.000Z", value: 0.0769333 },
    //   { group: "cpu.used", date: "2024-03-14T02:30:00.000Z", value: 0.0795 },
    // ];

    return {
      label: "",
      dataType: "area",
      data: {
        chartData: selectedCustomerJourney[0].cpuUsed,
        chartOptions: {
          title: "CPU Used",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          // style: {
          //   /**
          //    * optional prefixing string for css classes (defaults to 'cc')
          //    */
          //   prefix: "myClass"
          // },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            scale: {
              "cpu.used": "#004144",
            },
          },
        },
      },
    };
  };

  const getErrorMetrics = () => {
    // const infraMetrics = Error;

    // let pastMinForavail = getPastMinutes(5, "errors.mean.300");

    // let metricsData = [];
    // infraMetrics?.items?.length > 0 &&
    //   metricsData.push(infraMetrics.items[0].metrics);

    // let newArray = [];

    // if (metricsData.length > 0) {
    //   metricsData.forEach((finalMetrics) => {
    //     Object.entries(finalMetrics).forEach((key) => {
    //       key[1].forEach((res) => {
    //         let newObj = {};
    //         let timeStamp = res[0];
    //         if (timeStamp.length > 11) {
    //           let num = res[0];
    //           let str = num.toString();
    //           let removedLasttwo = str.substring(0, str.length - 2);
    //           timeStamp = parseInt(removedLasttwo, 10);
    //         }

    //         let timest = new Date(timeStamp);
    //         newObj = {
    //           group: key[0],
    //           date: getCurrentDateTime(timest),
    //           value: res[1],
    //         };
    //         newArray.push(newObj);
    //       });
    //     });
    //   });
    // }

    // let pastMin = [...pastMinForavail];
    // console.log("pastMinForavail ==============>>>>>", newArray);
    // newArray.forEach((res) => {
    //   pastMin.forEach((mint) => {
    //     let dateToCompare = new Date(mint.date);
    //     dateToCompare.setMinutes(new Date(mint.date).getMinutes() - 5);
    //     if (res.date <= mint.date && res.date > dateToCompare.toISOString()) {
    //       if (
    //         res.group === "errors.mean.300" &&
    //         mint.group === "errors.mean.300"
    //       ) {
    //         pastMinForavail.push({
    //           group: "errors.mean.300",
    //           date: res.date,
    //           value: res.value,
    //         });
    //       }
    //     }
    //   });
    // });

    // let memory = [
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:00:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:05:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:10:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:15:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:20:00.000Z",
    //     value: 0.72,
    //   },
    //   { group: "errors.mean.300", date: "2024-03-14T01:25:00.000Z", value: 1 },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:30:00.000Z",
    //     value: 0.7,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:35:00.000Z",
    //     value: 0.6,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:40:00.000Z",
    //     value: 0.5294,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:45:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:50:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T01:55:00.000Z",
    //     value: 0.5,
    //   },
    //   {
    //     group: "errors.mean.300",
    //     date: "2024-03-14T02:00:00.000Z",
    //     value: 0.5,
    //   },
    // ];

    return {
      label: "",
      dataType: "bar",
      data: {
        chartData: selectedCustomerJourney[0].errors,
        chartOptions: {
          title: "Error",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          // style: {
          //   /**
          //    * optional prefixing string for css classes (defaults to 'cc')
          //    */
          //   prefix: "myClass"
          // },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            scale: {
              "errors.mean.300": "#da1e28",
            },
          },
        },
      },
    };
  };

  const getInstanaInfraLatency = () => {
    // const infraMetrics = Latency;

    // let pastMinForavail = getPastMinutes(5, "latency.mean.300");

    // let metricsData = [];
    // infraMetrics?.items?.length > 0 &&
    //   metricsData.push(infraMetrics.items[0].metrics);

    // let newArray = [];

    // if (metricsData.length > 0) {
    //   metricsData.forEach((finalMetrics) => {
    //     Object.entries(finalMetrics).forEach((key) => {
    //       key[1].forEach((res) => {
    //         let newObj = {};
    //         let timeStamp = res[0];
    //         if (timeStamp.length > 11) {
    //           let num = res[0];
    //           let str = num.toString();
    //           let removedLasttwo = str.substring(0, str.length - 2);
    //           timeStamp = parseInt(removedLasttwo, 10);
    //         }

    //         let timest = new Date(timeStamp);
    //         newObj = {
    //           group: key[0],
    //           date: getCurrentDateTime(timest),
    //           value: res[1],
    //         };
    //         newArray.push(newObj);
    //       });
    //     });
    //   });
    // }

    // let pastMin = [...pastMinForavail];
    // console.log("pastMinForavail", pastMinForavail);
    // newArray.forEach((res) => {
    //   pastMin.forEach((mint) => {
    //     let dateToCompare = new Date(mint.date);
    //     dateToCompare.setMinutes(new Date(mint.date).getMinutes() - 5);
    //     if (res.date <= mint.date && res.date > dateToCompare.toISOString()) {
    //       if (
    //         res.group === "latency.mean.300" &&
    //         mint.group === "latency.mean.300"
    //       ) {
    //         pastMinForavail.push({
    //           group: "latency.mean",
    //           date: res.date,
    //           value: res.value,
    //         });
    //       }
    //     }
    //   });
    // });

    // let memory = [
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:10:00.000Z",
    //     value: 1.0952380952380953,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:15:00.000Z",
    //     value: 1.33,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:20:00.000Z",
    //     value: 1.5106382978723405,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:25:00.000Z",
    //     value: 24.710526315789473,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:30:00.000Z",
    //     value: 1.3376623376623376,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:35:00.000Z",
    //     value: 1.4148936170212767,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:40:00.000Z",
    //     value: 1.1777777777777778,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:45:00.000Z",
    //     value: 1.0344827586206897,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:50:00.000Z",
    //     value: 1.0789473684210527,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T01:55:00.000Z",
    //     value: 1.2173913043478262,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:00:00.000Z",
    //     value: 1.3766233766233766,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:05:00.000Z",
    //     value: 1.247191011235955,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:10:00.000Z",
    //     value: 1.5394736842105263,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:15:00.000Z",
    //     value: 1.0379746835443038,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:20:00.000Z",
    //     value: 1.5280898876404494,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:25:00.000Z",
    //     value: 1.8421052631578947,
    //   },
    //   {
    //     group: "latency.mean.300",
    //     date: "2024-03-14T02:30:00.000Z",
    //     value: 1.146341463414634,
    //   },
    // ];

    return {
      label: "Latency",
      dataType: "line",
      data: {
        chartData: selectedCustomerJourney[0].latencyMean,
        chartOptions: {
          title: "Latency",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          // style: {
          //   /**
          //    * optional prefixing string for css classes (defaults to 'cc')
          //    */
          //   prefix: "myClass"
          // },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            gradient: {
              enabled: true,
            },
          },
        },
      },
    };
  };

  // const getPastMinutes = (minutes, group, startDate = new Date()) => {
  //   return [...Array(minutes)].map((i, idx) => {
  //     let newDate = subtractMinutes(startDate, idx);
  //     let updatedDate = getCurrentDateTime(newDate);
  //     return { group: `${group}`, date: updatedDate, value: 0 };
  //   });
  // };

  // const getCurrentDateTime = (newDate) => {
  //   let date =
  //     newDate.getDate() <= 9 ? `0${newDate.getDate()}` : newDate.getDate();
  //   let month =
  //     newDate.getMonth() + 1 <= 9
  //       ? `0${newDate.getMonth() + 1}`
  //       : newDate.getMonth() === 12
  //       ? newDate.getMonth()
  //       : newDate.getMonth() + 1;
  //   let year = newDate.getFullYear();
  //   let hour =
  //     newDate.getHours() <= 9 ? `0${newDate.getHours()}` : newDate.getHours();
  //   let minute =
  //     newDate.getMinutes() <= 9
  //       ? `0${newDate.getMinutes()}`
  //       : newDate.getMinutes();
  //   let sec =
  //     newDate.getSeconds() <= 9
  //       ? `0${newDate.getSeconds()}`
  //       : newDate.getSeconds();
  //   return `${year}-${month}-${date}T${hour}:${minute}:${sec}.000Z`;
  // };

  //const subtractMinutes = (date, minutes) => new Date(date - minutes * 300000);

  // const dateOnChange = (e, index) => {
  //   // console.log("e", e);
  //   // console.log("index", index);
  //   onDateIndexChange(index);
  //   onDateChange(e);
  // };

  // const dateSelection = () => {
  //   const dateArr = ["1 Hr", "12 Hr", "1 Day"];
  //   return dateArr.map((date, index) => (
  //     <li
  //       onClick={() => dateOnChange(date, index)}
  //       className={index === dateIndexChange && "active"}
  //       key={date}
  //     >
  //       {date}
  //     </li>
  //   ));
  // };

  const getAlertLogs = () => {
    return selectedCustomerJourney[0].InfraLogs.map((err) => {
      const newEr = Object.entries(err).map(([key, value]) => ({ key, value }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newEr.map((erlog) => {
            return (
              <li key={`${erlog.key}-${erlog.value}`}>
                <h5>{erlog.key}</h5>
                <span>{erlog.value}</span>
              </li>
            );
          })}
        </ul>
      );
    });
  };

  return (
    <FlexGrid fullWidth>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              IT Value Stream Observability
            </BreadcrumbItem>
            <BreadcrumbItem href="#/workFlowJourney">Workflow</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              {props.location.state.data}
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
        {/* <Column lg={3} md={2} sm={2}>
          <ul className="portfolio-fss wid-change">{dateSelection()}</ul>
        </Column> */}
      </Row>
      <Row className="go2">
        {/* <Column lg={6} md={4} className="show-Inference">
          <h5>Inference</h5>
          <OrderedList>
            <ListItem>
              The issue has been detected after change CHG010056751
            </ListItem>
            <ListItem>Error rate has increased during last 15 mins</ListItem>
            <ListItem>Pods have restarted in last 30 mins</ListItem>
            <ListItem>
              30% Apply for Card applications have been impacted
            </ListItem>
          </OrderedList>
        </Column> */}

        {/* <Column lg={5} md={4} sm={4} className="pr-0">
          <Tile className="full-height">
            <h5 className="box-heading">Recent Changes</h5>
            <div className="layer">
              <Layer>{TestComponent("Hardware", 1)}</Layer>
              <Layer>{TestComponent("Network", 1)}</Layer>
              <Layer>{TestComponent("Software", 2)}</Layer>
              <Layer>{TestComponent("Database", 1)}</Layer>
            </div>
          </Tile>
        </Column> */}
        <Column lg={16} md={8} className="tickets pr-0">
          <Tile className="full-height">
            <h5 className="last-row-title" style={{ fontWeight: "800" }}>
              Recent Changes
            </h5>
            {/* <p>No Recent Changes Found</p> */}
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Change Ticket</StructuredListCell>
                  <StructuredListCell head>Description</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].recentChanges.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          {details.Ticket}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details.Description}
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
        {/* <Column lg={3} md={4} sm={4} className="pr-0">
          <Tile className="full-height">
            <h5 className="box-heading">Bridges (Last 1 week)</h5>
            <div className="layer">
              <Layer>{TestComponent("Ongoing", 1)}</Layer>
              <Layer>{TestComponent("Closed", 10)}</Layer>
              <Layer>{TestComponent("Paused", 2)}</Layer>
            </div>
          </Tile>
        </Column> */}
      </Row>
      <Row className="row-sec go2">
        {/* <Column lg={4} md={4} sm={4} className="bl1 bt1 br1 pl-0 pr-0">
            <Tile className="full-height">
              <h5 className="box-heading">Linked Customer Journey</h5>
              <StructuredListWrapper className="linked-cust" isCondensed={true}>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>CJ Name</StructuredListCell>
                    <StructuredListCell head>Open Bridge</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow className="error">
                    <StructuredListCell>Apply for Product</StructuredListCell>
                    <StructuredListCell>1</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>Authentication</StructuredListCell>
                    <StructuredListCell>0</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>Pay a bill</StructuredListCell>
                    <StructuredListCell>0</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </Tile>
          </Column>
          <Column lg={3} md={4} sm={4} className="bt1 br1 pl-0 pr-0">
            <Tile className="full-height">
              <h5 className="box-heading">Recent Changes</h5>
              <div className="layer">
                <Layer>{TestComponent("Hardware", 1)}</Layer>
                <Layer>{TestComponent("Network", 1)}</Layer>
                <Layer>{TestComponent("Software", 2)}</Layer>
                <Layer>{TestComponent("Database", 1)}</Layer>
              </div>
            </Tile>
          </Column> */}
        {/* <Column lg={3} md={4} sm={4} className="pr-0">
            <Tile className="full-height">
              <h5 className="box-heading">Linked Customer Journey</h5>
              <StructuredListWrapper className="linked-cust" isCondensed={true}>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>CJ Name</StructuredListCell>
                    <StructuredListCell head>Open Bridge</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow className="error">
                    <StructuredListCell>Apply for Product</StructuredListCell>
                    <StructuredListCell>1</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>Authentication</StructuredListCell>
                    <StructuredListCell>0</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>Pay a bill</StructuredListCell>
                    <StructuredListCell>0</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </Tile>
          </Column> */}
        <Column lg={6} md={4} sm={4} className="recent-head pl-0 pr-0">
          <Tile className="full-height">
            <h5 className="box-heading">Recent Events</h5>
            {getEvents()}
          </Tile>
        </Column>
        <Column
          lg={10}
          md={6}
          sm={4}
          className="pr-0"
          style={{ position: "relative" }}
        >
          <TreeDiagram />
          {/* <NetworkDiag /> */}
        </Column>
      </Row>
      <Row className="row-sec go2">
        <Column lg={6} md={8} sm={4} className="pl-0 pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraMemoryMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={6} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getErrorMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={4} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraLatency()} />
          </ErrorBoundary>
        </Column>
        {/* <Column
            lg={4}
            md={4}
            sm={4}
            className="bl1 bb1 pl-0 pr-0 padding-for-border"
          >
            <Tile className="full-height">
              <h5 className="box-heading">Health</h5>
              <div className="sec-row">
                <div className="tile-box-wrapper">
                  <div className="tile-box">
                    <h5>Application</h5>
                    <div className="layer">
                      <Layer>{TestComponent("Latency", "95%")}</Layer>
                      <Layer>{TestComponent("Traffic", "900)}</Layer>
                      <Layer>{TestComponent("Saturation", "10%")}</Layer>
                      <Layer>{TestComponent("Error", 1)}</Layer>
                    </div>
                  </div>
                  <div className="tile-box">
                    <h5>Databse</h5>
                    <p>No issues detected</p>
                  </div>
                </div>
                <div className="tile-box-wrapper">
                  <div className="tile-box">
                    <h5>Platform</h5>
                    <p>No issues detected</p>
                  </div>
                  <div className="tile-box">
                    <h5>Logs</h5>
                    <p>No anomaly detected</p>
                  </div>
                </div>
              </div>
            </Tile>
          </Column> */}
        {/* <Column
            lg={4}
            md={4}
            sm={4}
            className="br1 bl1 bb1 pr-0 pl-0 padding-for-border"
          >
            <Tile className="full-height">
              <h5 className="box-heading">Contextual</h5>
              <div className="tile-box-wrapper">
                <h5 style={{ fontWeight: "380" }}>Upstream Applications</h5>
                <StructuredListWrapper
                  className="linked-cust"
                  isCondensed={true}
                >
                  <StructuredListHead>
                    <StructuredListRow head>
                      <StructuredListCell head>Application</StructuredListCell>
                      <StructuredListCell head>Open Bridge</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    <StructuredListRow className="error">
                      <StructuredListCell>eACQ</StructuredListCell>
                      <StructuredListCell>1</StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell>One CMS</StructuredListCell>
                      <StructuredListCell>0</StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell>PACE</StructuredListCell>
                      <StructuredListCell>0</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
              <div className="tile-box-wrapper">
                <h5 style={{ fontWeight: "380" }}>Downstream Applications</h5>
                <StructuredListWrapper
                  className="linked-cust"
                  isCondensed={true}
                >
                  <StructuredListHead>
                    <StructuredListRow head>
                      <StructuredListCell head>Application</StructuredListCell>
                      <StructuredListCell head>Open Bridge</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    <StructuredListRow className="error">
                      <StructuredListCell>eACQ</StructuredListCell>
                      <StructuredListCell>1</StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell>CEM</StructuredListCell>
                      <StructuredListCell>0</StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell>Card Service</StructuredListCell>
                      <StructuredListCell>0</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
            </Tile>
          </Column> */}
        {/* <Column
            lg={8}
            md={4}
            sm={4}
            className="br1 bb1 recent-head pl-0 pr-0 padding-for-border"
          >
            <Tile className="full-height">
              <h5 className="box-heading">Recent Incidents</h5>
              <StructuredListWrapper className="linked-cust" isCondensed={true}>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Ticket</StructuredListCell>
                    <StructuredListCell head>Sev</StructuredListCell>
                    <StructuredListCell head>Description</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow className="error">
                    <StructuredListCell>INC32581</StructuredListCell>
                    <StructuredListCell>1</StructuredListCell>
                    <StructuredListCell>Hardware Failure</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>INC33671</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>
                      Payment for APAC region failure
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>INCC3360</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>Login Failure</StructuredListCell>
                  </StructuredListRow>

                  <StructuredListRow>
                    <StructuredListCell>INCC3356</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>
                      Pay bill intermittent failure
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>INCC3387</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>
                      Merchant discount not applied
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>INCC3398</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>Network latency</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell>INCC3332</StructuredListCell>
                    <StructuredListCell>2</StructuredListCell>
                    <StructuredListCell>Batch job failed</StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </Tile>
          </Column> */}
      </Row>
      <Row>
        <Column lg={10} md={8} sm={4} className="pl-0">
          <Tile className="full-height">
            <h5 style={{ fontWeight: "600" }}>Application Alerts</h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>API</StructuredListCell>
                  <StructuredListCell head>Failure Rate</StructuredListCell>
                  <StructuredListCell head>Count</StructuredListCell>
                  <StructuredListCell head>Error</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].applicationAlerts.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          /{details.apis}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details["Failure Rate"]}
                        </StructuredListCell>
                        <StructuredListCell>{details.Count}</StructuredListCell>
                        <StructuredListCell>{details.error}</StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
        <Column lg={6} md={4} sm={4} className="pl-0 pr-0">
          <Tile className="full-height infra-alert">
            <h5>Infrastructure Alerts</h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Event Name</StructuredListCell>
                  <StructuredListCell head>Logs</StructuredListCell>
                  {/* <StructuredListCell head>Failure Rate</StructuredListCell>
                  <StructuredListCell head>Count</StructuredListCell>
                  <StructuredListCell head>Error</StructuredListCell> */}
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].InfraAlerts.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          {details.apis}
                        </StructuredListCell>
                        <StructuredListCell onClick={() => setShowAlert(true)}>
                          <Catalog />
                        </StructuredListCell>
                        {/* <StructuredListCell>
                          {details["Failure Rate"]}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details.Count}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details.error}
                        </StructuredListCell> */}
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
      </Row>
      {showModal && (
        <CommonMLModal
          setShowModal={setShowModal}
          ticketDetails={recentIncidents}
        />
      )}
      {alertLogs ? (
        <Modal
          open
          passiveModal
          secondaryButtonText={null}
          heading="Logs"
          size="lg"
          onRequestClose={() => setShowAlert(false)}
          // canCloseDetailsPopup={() => setShowAlert(false)}
        >
          <div>{getAlertLogs()}</div>
        </Modal>
      ) : null}
    </FlexGrid>
  );
};

export default Go2Application;
