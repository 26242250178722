export const getQueryKeyValue = (e, eventHandler, dataKey, chartKey, fields) => {
  const { detail } = e;
  let value;
  switch (eventHandler) {
    case "scatter-click":
    case "bar-click":
      if (chartKey === "sharedStackKey") {
        // This was written for bar-click, need to check for scatter-click
        const {xKey, stackedKey} = fields
        const value1 = detail.datum["data"]["sharedStackKey"];
        const value2 = detail.datum["group"];
        return `${xKey},${value1},${stackedKey},${value2}`
      }
      else if(Array.isArray(chartKey)){
        // This was written for scatter-click, need to check for bar-click
        value = ""
        const length = chartKey.length
        chartKey.forEach((chartkey, index) => {
          value = value + `${dataKey[index]},${detail.datum[chartkey]}`
          if(index !== length -1){
            value = value + ","
          }
        })
        return value
      }
      else {
        value = detail.datum[chartKey];
      }
      break;
    case "pie-slice-click":
    case "leaf-click":
    case "circle-leaf-click":
      value = detail.datum.data[chartKey];
      break;
    default:
      value = "";
  }
  return `${dataKey},${value}`;
};
