import { Breadcrumb, BreadcrumbItem, Column, Grid } from "@carbon/react";
import React from "react";
import GitHubReposReport from "./GitHubReposReport";

function GitHubRepos() {
    return (
        <>
            <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem>
                        {/* <BreadcrumbItem href="#/iALM">Product Engineering</BreadcrumbItem> */}
                        <BreadcrumbItem href="#/iALM">Version Control</BreadcrumbItem>
                        {/* <BreadcrumbItem href="#/gitHubOrganizations">GitHub Organizations</BreadcrumbItem> */}
                        <BreadcrumbItem isCurrentPage>GitHub Repos</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid>
            <div className="content-div">
                <GitHubReposReport />
            </div>
        </>
    );
}

export default GitHubRepos;