import { ClickableTile, Column, Row, Tag } from "@carbon/react";
import { ArrowDown, ArrowUp } from "@carbon/react/icons/index";
import ChartContainer from "Carbon-Charts/ChartContainer";
import React, { useEffect, useState } from "react";
import CpgKpi from "./test.json";

const KpiModel = () => {
    const [dashboardData, setDashboard] = useState([]);

    useEffect(() => {
        setDashboard(CpgKpi.CpgKPI)
    }, [])

    const getCharts = (chart) => {
        if(chart.charts.length > 0) {
            return <ChartContainer data={chart.charts[0]} />
        }
    }

    return (
        <Row>
            <Column lg={16} md={8} className="nerve-tiles">
                                {dashboardData !== undefined && dashboardData.map((info, index) => 
                                    <ClickableTile
                                        id={index}
                                        // onClick={() => handleOnClick(info)}
                                        aria-label={`clickableTile-${index}`}
                                    >
                                        <div>
                                            <h5>{info.displayName}</h5>
                                            <Tag className="some-class" type={"teal"}>
                                                {"Business"}
                                            </Tag>
                                        </div>
                                        <ul>
                                            <li>{info.delta === "up" ? <ArrowUp stroke={info.arrowStatus === "red" ? "#fa4d56" : "#24a148"} strokeWidth="1.5px" /> : <ArrowDown stroke={info.arrowStatus === "red" ? "#fa4d56" : "#24a148"} strokeWidth="1.5px" />} {info.value}<span>{info.postFix}</span></li>
                                            <li className="comments">{getCharts(info)} <span>{info.comments}</span></li>
                                        </ul>
                                    </ClickableTile>
            )}
            </Column>
        </Row>
    )
}

export default KpiModel;