import React from 'react'
import { Link } from 'react-router-dom'

const ErrorFallback = () => {
  return (
    <div className='center-screen'>
        <h3>Something went wrong! Please try again after sometime.</h3>
        <p>Click to Go <Link to="/">Home</Link></p>
    </div>
  )
}

export default ErrorFallback