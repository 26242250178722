import { ErrorBoundary } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
import React, { useEffect } from "react";
import { getTwistlockIssues } from "Services/ServerApi";
import ApplicationRecommendations from "./ApplicationRecommendations";
import IssuesContainer from "./IssuesContainer";
import { v4 as uuidv4 } from "uuid";

const ClusterIssues = ({ hirarchy }) => {
  const { keycloak } = useKeycloak();
  const queryCache = new QueryCache();

  const {
    isLoading,
    data: res,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    [`twistlog_issues_top_level_${hirarchy}`],
    () =>
      getTwistlockIssues(
        keycloak,
        uuidv4(),
        "twistlog",
        hirarchy,
      ),
    {
      cache: queryCache,
      retry: 1,
      enabled: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getData = () => {
    if (isLoading || (isFetching && isError)) {
      return <InlineLoader description="Please wait..." status="active" />;
    }

    try {
      if (isError) {
        throw new Error();
      }
      if (hirarchy === "application") {
        return <ApplicationRecommendations data={res.data} refresh={refetch} />;
      }
      return <IssuesContainer data={res.data} refresh={refetch} />;
    } catch (error) {
      return <ErrorRefresh refresh={refetch} />;
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorRefresh refresh={refetch} />}>
      {getData()}
    </ErrorBoundary>
  );
};

export default ClusterIssues;
