import React from "react";
import {
  Column,
  ErrorBoundary,
  Row,
} from "@carbon/react";
import ErrorFallback from "Common-Modules/ErrorFallback";
import MendOverview from "./mend";
import { useTranslation } from 'react-i18next';

const SASTDashboards = () => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <MendOverview queryKey={["mend_dashboards"]} />
          </Column>
        </Row>
    </ErrorBoundary>
  );
};

export default SASTDashboards;
