
import ReactSpeedometer from "react-d3-speedometer";

export const Speedometer = (props) => (
    <div>
        {/* <ReactSpeedometer
            needleHeightRatio={0.7}
            maxSegmentLabels={5}
            segments={3}
            customSegmentStops={[0, 500, 750, 900, 1000]}
            segmentColors={['firebrick', 'tomato', 'gold', 'limegreen']}
            value={333}
            textColor={333}
        />
        <ReactSpeedometer
            forceRender={true}
            maxSegmentLabels={1}
            customSegmentStops={[500, 777, 1000]}
            segmentColors={['#5959ac', '#AAA']}
            needleColor={'#5959ac'}
            currentValueText={'Current Value: ${value}'}
            minValue={500}
            maxValue={1000}
            value={777}
            textColor={333}
        /> */}
        <ReactSpeedometer
            height={180}
            forceRender={true}
            maxSegmentLabels={1}
            // customSegmentStops={[500, 777, 1000]}
            segmentColors={props.colors}
            needleColor={'#161616'}
            currentValueText={'Total: ${value}'}
            minValue={0}
            maxValue={100}
            value={props.value}
            customSegmentStops={props.services}
            textColor={161}
            labelFontSize={'12px'}
            valueTextFontSize={'12px'}
            valueTextFontWeight={'600'}
        />
    </div>
)