import React from "react";
import { Tooltip } from "@carbon/react";

const TooltipCarbon = (props) => {
  return (
    <Tooltip align={props.align} description={props.description}>
      <button type="button" className="tooltip-button">
        {props.children}
      </button>
    </Tooltip>
  );
};

TooltipCarbon.defaultProps = {
  align: "bottom",
};

export default TooltipCarbon;
