let largestHeight = 0;
let depth = 0;
let nodesRearranged = [];
let nodesDepthMap;

const isDuplicateNode = (obj) => {
  let i;
  for (i = 0; i < nodesRearranged.length; i++) {
    if (nodesRearranged[i].id === obj.id) {
      return true;
    }
  }
  return false;
};

const getNode = (services, referenceId) => {
  return services.find((serviceObj) => serviceObj.ReferenceId === referenceId);
};

const addChildNodes = (node, services) => {
  let clients = [];
  if (node.Edges && node.Edges.length > 0) {
    node.Edges.map((edgeObj) => {
      let edgeNode = getNode(services, edgeObj.ReferenceId);
      edgeNode.depth = depth;
      let minimumNode = {
        id: edgeNode.ReferenceId,
        depth: edgeNode.depth,
        nodeData: edgeNode,
      };
      if (!isDuplicateNode(minimumNode)) {
        clients.push(edgeNode);
        nodesRearranged.push(minimumNode);
      }
    });
  }
  return clients;
};

const getClientWithDepth = (clients, services) => {
  ++depth;
  let clients1 = [];
  clients.map((clientObj) => {
    clients1 = clients1.concat(addChildNodes(clientObj, services));
  });
  return clients1;
};

export const getClientNodeData = (services) => {
  nodesRearranged = [];
  depth = 0;
  nodesDepthMap = new Map();

  let clients = [];
  services.map((serviceObj) => {
    if (serviceObj.Type && serviceObj.Type.includes("client")) {
      serviceObj.depth = depth;
      let minimumNode = {
        id: serviceObj.ReferenceId,
        depth: serviceObj.depth,
        nodeData: serviceObj,
      };
      clients.push(serviceObj);
      nodesRearranged.push(minimumNode);
    }
  });

  do {
    largestHeight =
      largestHeight > clients.length ? largestHeight : clients.length;
    nodesDepthMap.set(depth, clients);
    clients = getClientWithDepth(clients, services);
  } while (clients.length > 0);

  let returnObject = {};
  returnObject.nodesRearranged = nodesRearranged;
  returnObject.nodesDepthMap = nodesDepthMap;
  returnObject.largestHeight = largestHeight;

  return returnObject;
};
