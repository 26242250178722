import React, { useState } from 'react'
import { Column, Row, SkeletonText, Tile } from '@carbon/react'
import solutions from "./clusterSolution.json"

const ClusterRecommendations = () => {
    const [action, setActions] = useState(false);

    setTimeout(() => {
        setActions(true)
    }, 2000)

  return (
    <Row>
        <Column lg={8} md={4} sm={4} className="pr-05">
            <Tile>
                <h4 style={{fontWeight: "500"}}>Recommendation</h4>
                <ul className='dotted--list'>
                    <li>Upgrade cluster to latest version 4.14.5</li>
                    <li>This will fix around 1900 issues</li>
                    <li>Time to upgrade 4- 6 hours</li>
                </ul>
            </Tile>
        </Column>
        <Column lg={8} md={4} sm={4} className="pl-05">
            <Tile>
                <h4 style={{fontWeight: "500"}}>Action</h4>
                {action ?
                <div className='action--panel'>
                    {
                        solutions.map(sol => <>
                        <p className='fw-normal'>{sol.step}</p>
                        <ul className='dotted--list mt-0'>
                         <li>{sol.description}</li>
                        </ul>    
                        </>)
                    }
                </div> : <>
                <SkeletonText />
                <SkeletonText />
                <SkeletonText />
                <SkeletonText />
                </> }
            </Tile>
        </Column>
    </Row>
  )
}

export default ClusterRecommendations