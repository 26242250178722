import {
  Button,
  Column,
  ComboBox,
  Dropdown,
  Modal,
  Row,
  SkeletonText,
  StructuredListSkeleton,
  Tile,
  Toggle,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from "@carbon/react";
import {
  Information,
} from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import ChartContainer from "Carbon-Charts/ChartContainer";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import { getApplicationData } from "Services/ServerApi";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AMS from "../assets/susops_1.jpg";
import WorldMap from "../worldMap";
import ApplicationTypes from "./ApplicationTypes";
import Category from "./Category";
import Inferences from "./Inferences";
import MLInferences from "./MLInferences";
import {
  colors,
  graph2,
  itAppColors,
  modifyApiData,
  showPieChart
} from "./Utilities";
import modifyJson from "./emissionred/HyOps";
import getRecommendChartData from "./reductions/piechart";
import { decrement, rows } from "./staticData/recomm";

const Overview = () => {
  const tenant = sessionStorage.getItem("tenant");
  const EA_Tenant = tenant === "Energy_Australia";
  // const showInfs = tenant === process.env.REACT_DEFAULT_TENANT || EA_Tenant;
  const showInfs = true;
  const Ibm_consulting = tenant === process.env.REACT_DEFAULT_TENANT;

  // state
  const [showMap, setShowMap] = useState(false);
  const [popup, showPopup] = useState(false);
  const [application, setApplication] = useState("");
  const [recommend, setRecommend] = useState("");
  const [level, setLevel] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [applicationSpecific, setAppSpecic] = useState([]);
  const [appTotal, setAppTotal] = useState([]);
  const [applications, setApps] = useState([]);
  const [tickets, setTickets] = useState({});
  const [year, setYear] = useState("2050");
  const [appColors, setAppColors] = useState({});
  const chartRef = useRef(null);
  const [clientName, setClientName] = useState("");
  const [clientIndustry, setClientIndustry] = useState("");
  const [clientHq, setClientHq] = useState("");
  const [countryTarget, setCountryTarget] = useState("2070");
  const unit = EA_Tenant || Ibm_consulting ? "kgco2" : "mtco2";
  const [apiData, setApiData] = useState([]);
  const [categoryWise, setCategoryWise] = useState([]);

  const displayUnit = unit === "mtco2" ? "mtCO₂eq" : "kgCO₂eq";

  // libaries
  const history = useHistory();
  const { keycloak } = useKeycloak();

  // utilities
  const app = application || "application";
  const heading = application || "Summary";
  const recOrapp = Boolean(recommend || application);

  // totals
  const ticketsTotal = Object.values(tickets).reduce(
    (acc, total) => Number(acc || 0) + total,
    0,
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chartRef.current !== null) {
      chartRef.current.chart.services.events.addEventListener(
        "pie-slice-click",
        onClickProcessEvent,
      );
    }
  }, [isLoading, recommend]);

  useEffect(() => {
    if (isEmpty || apiError) {
      setIsLoading(false);
    }
  }, [isEmpty, apiError]);

  useEffect(() => {
    if (apiData.length > 0) {
      setIsLoading(true);
      calculateData();
    }
  }, [apiData, unit]);

  const calculateData = () => {
    try {
      const data = modifyApiData(apiData, unit);
      if (data === "Error") throw new Error("");
      const { applicationSpecific, appTotal, applications, tickets, category } =
        data;
      let obj = {};
      if (applications.length > 0) {
        const sortedNames = applications.sort(
          (a, b) => appTotal[b] - appTotal[a],
        );
        const minArrayLength = Math.min(sortedNames.length, colors.length);
        for (let i = 0; i < minArrayLength; i++) {
          const name = sortedNames[i];
          const color = colors[i];
          obj[name] = color;
        }
        setAppColors(obj);
      }
      setTickets(tickets);
      setAppSpecic(applicationSpecific);
      setAppTotal(appTotal);
      setApps(applications);
      setCategoryWise(category);
    } catch (error) {
      setApiError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const rowData = () => {
    if (EA_Tenant) {
      const rowTemp = rows.map((row) => {
        if (
          // application === "C1-CCnB" &&
          row.recommendation === "End-to-End Full Stack Observability"
        ) {
          row.disabled = true;
        } else {
          row.disabled = false;
        }
        return row;
      });
      return rowTemp;
    }
    return rows;
  };

  const inf_total = () => appTotal[app];

  const onClickProcessEvent = (event) => {
    setApplication(event.detail.datum.data.group);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setApiError(false);
    setIsEmpty(false);

    const res = await getApplicationData(keycloak, "", uuidv4());
    if (Array.isArray(res?.data)) {
      if (res.data.length > 0) {
        try {
          const clientDetails = res.data[0].appDetails;
          setClientName(clientDetails.client_name);
          setCountryTarget(clientDetails.country_net_zero);
          setClientIndustry(clientDetails.industry);
          setClientHq(clientDetails.hq);
        } catch (err) {
          console.log(err);
        }
        setApiData(res.data);
      } else {
        setIsEmpty(true);
      }
    } else {
      setApiError(true);
    }
  };

  if (isLoading) {
    return (
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <SkeletonText width="70%" />
            <SkeletonText width="30%" />
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={4} sm={4}>
            <StructuredListSkeleton />
          </Column>
        </Row>
      </>
    );
  }

  if (apiError) {
    return <ErrorRefresh refresh={() => fetchData()} />;
  }

  if (isEmpty) {
    return (
      <div className="center-screen">
        <h4>Onboard Applications to view Dashboards</h4>
        <Button onClick={() => history.push("/susopsInstrumentation")}>
          Onboard Applications
        </Button>
      </div>
    );
  }

  return (
    <>
      {!showMap ? (
        <>
          <Row>
            <Column lg={8} md={4} sm={4} className="pr-05">
              <Tile
                className="tiles title"
                style={{ minHeight: "109px", padding: "0" }}
              >
                <div className="inference-heading">Summary</div>
                <div className="summary">
                  <div className="flex-space-between flex-wrap">
                    <div className="keyValue">
                      <h5>Client Name:</h5>
                      <h4>{clientName || "-"}</h4>
                    </div>
                    <div className="keyValue">
                      <h5>Client Industry:</h5>
                      <h4>{clientIndustry || "-"}</h4>
                    </div>
                  </div>
                  <div className="flex-space-between flex-wrap">
                    <div className="keyValue">
                      <h5>Headquarters:</h5>
                      <h4>{clientHq || "-"}</h4>
                    </div>
                    <div className="keyValue">
                      <h5>Client Net Zero Target:</h5>
                      <Dropdown
                        id="select-target-year"
                        label="Dropdown menu options"
                        onChange={(e) => setYear(e.selectedItem)}
                        selectedItem={year}
                        size="sm"
                        items={["2030", "2040", "2050", "2060", "2070"]}
                        itemToString={(item) => (item ? item : "")}
                      />
                    </div>
                  </div>
                  <div className="flex-space-between flex-wrap">
                    <div className="keyValue">
                      <h5>Country Net Zero Target:</h5>
                      <h4>
                        By <strong>{countryTarget || "-"}</strong>
                      </h4>
                    </div>
                    <div className="keyValue">
                      <h5>Total Emission:</h5>
                      <h4>
                        <strong>
                          {`${appTotal["application"].toFixed(3)} ${displayUnit}` ||
                            "-"}
                        </strong>
                      </h4>
                    </div>
                  </div>
                </div>
              </Tile>
            </Column>
            <Column lg={8} md={4} sm={4} className="pl-05">
              {showInfs ? (
                <MLInferences application={application} />
              ) : (
                <Inferences value={inf_total()} displayUnit={displayUnit} />
              )}
            </Column>
          </Row>
          <Row
            style={{
              alignItems: "end",
              marginTop: `${showInfs ? "0" : "1rem"}`,
            }}
          >
            <Column lg={8} md={8} sm={4} className="susops pr-05">
              {showInfs ? <Inferences value={inf_total()} displayUnit={displayUnit} /> : null}
            </Column>
            <Column lg={8} md={8} sm={4} className="pl-05">
              <div
                className="flex-space-between flex-column"
                style={{ gap: "1.2rem" }}
              >
                {showInfs ? <ApplicationTypes /> : null}
                <div className="flex-1">
                  <ComboBox
                    id="select-application"
                    placeholder="Select Application"
                    items={applications}
                    selectedItem={application}
                    itemToString={(item) => item}
                    onChange={(e) => setApplication(e.selectedItem)}
                    size="sm"
                    className="select-app"
                  />
                  <div style={{ width: "22rem" }}>
                    <ComboBox
                      id="select-reccs"
                      placeholder="Select method to reduce carbon emission"
                      items={rowData()}
                      // disabled={!application}
                      itemToString={(item) => (item ? item.recommendation : "")}
                      onChange={(e) => {
                        if (e.selectedItem)
                          setRecommend(e.selectedItem?.recommendation);
                        else {
                          setRecommend(false);
                          setLevel(true);
                        }
                      }}
                      className="select-app"
                      size="sm"
                    />
                  </div>
                  <Information
                    size={24}
                    className="info-icon"
                    onClick={() => showPopup(true)}
                  />
                </div>
              </div>
            </Column>
          </Row>
          <Row className="graphs-row">
            <Column
              lg={8}
              md={6}
              sm={4}
              className="pr-05"
            >
              <div className={(EA_Tenant || Ibm_consulting) && (!application || (application && !recommend)) ? "nested--tile" : ""}>
                {recommend ? (
                  <ChartContainer
                    data={modifyJson(
                      recommend,
                      heading,
                      appTotal[app],
                      decrement[recommend],
                      displayUnit,
                    )}
                  />
                ) : null}
                {!recommend ? (
                  <ChartContainer
                    data={graph2(application, appTotal[app], year, displayUnit)}
                  />
                ) : null}
              </div>
            </Column>
            {(!application) ||
              (application && !recommend) ? (
              <Column
                // lg={recOrapp ? 5 : 4}
                lg={4}
                md={6}
                sm={4}
                className={`${recOrapp ? "pr-0" : "pr-0"} pl-05 heading-parent`}
                style={{ position: "relative" }}
              >
                <div className="per-incident-heading">
                  <Toggletip className="manual-heading-info" align="left">
                    <ToggletipButton label="Show information">
                      <Information className="per-incident-icon" size={16} />
                    </ToggletipButton>
                    <ToggletipContent>
                      <div>
                        Carbon Emission per incident ={" "}
                        {`${(
                          (appTotal["application"] / ticketsTotal) *
                          (EA_Tenant || Ibm_consulting ? 1 : 1000)
                        ).toFixed(3)} kgCO₂eq`}
                      </div>
                    </ToggletipContent>
                  </Toggletip>
                </div>
                <ChartContainer
                  chartRef={chartRef}
                  data={showPieChart(
                    applicationSpecific["application"],
                    "Summary",
                    appColors,
                    ticketsTotal,
                    (EA_Tenant || Ibm_consulting) ? "350px" : "300px",
                    displayUnit,
                  )}
                />
              </Column>
            ) : null}
            {/* Total Applications Category wise */}
            {!application && !recommend ? (
              <Column lg={4} md={4} sm={4} className="pl-0 invisible-heading">
                <div className={(EA_Tenant || Ibm_consulting) ? "nested--tile" : ""}>
                  <Category data={categoryWise} displayUnit={displayUnit} />
                </div>
              </Column>
            ) : null}
            {application ? (
              <Column
                lg={4}
                md={6}
                sm={4}
                className={
                  recommend
                    ? "pr-0 pl-05 heading-parent"
                    : `pl-0 heading-parent`
                }
              >
                <div className={((EA_Tenant || Ibm_consulting) && !recommend) ? "nested--tile" : ""}>
                  <div className="per-incident-heading">
                    <Toggletip className="manual-heading-info" align="left">
                      <ToggletipButton label="Show information">
                        <Information className="per-incident-icon" size={16} />
                      </ToggletipButton>
                      <ToggletipContent>
                        <div>
                          Carbon Emission per incident ={" "}
                          {`${(
                            (appTotal[application] / tickets[application]) *
                            (EA_Tenant || Ibm_consulting ? 1 : 1000)
                          ).toFixed(3)} kgCO₂eq`}
                        </div>
                      </ToggletipContent>
                    </Toggletip>
                  </div>
                  <ChartContainer
                    chartRef={chartRef}
                    data={showPieChart(
                      applicationSpecific[application],
                      application,
                      itAppColors,
                      tickets[application],
                      "300px",
                      displayUnit,
                    )}
                  />
                </div>
              </Column>
            ) : null}
            {recommend ? (
              <Column lg={4} md={6} sm={4} className="pl-0 heading-parent">
                <Toggle
                  className="manual-heading"
                  size="sm"
                  labelText=""
                  labelB="Max"
                  labelA="Max"
                  defaultToggled={level}
                  id="toggle-5"
                  onToggle={() => setLevel((prev) => !prev)}
                />
                <ChartContainer
                  data={getRecommendChartData(
                    JSON.parse(JSON.stringify(applicationSpecific[app])),
                    ((100 -
                      (level ? decrement[recommend || "application"] : 25)) /
                      100) *
                    appTotal[app],
                    application ? itAppColors : appColors,
                    ((EA_Tenant || Ibm_consulting) && !application) ? "350px" : "300px",
                    displayUnit
                  )}
                />
              </Column>
            ) : null}
          </Row>
        </>
      ) : (
        <WorldMap />
      )}
      {popup ? (
        <Modal
          open
          passiveModal
          size="md"
          onRequestClose={() => showPopup(false)}
        >
          <img
            src={AMS}
            alt="aiops journey"
            referrerpolicy="no-referrer"
            className="img-aiops"
          ></img>
        </Modal>
      ) : null}
    </>
  );
};

export default Overview;
