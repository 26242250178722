import React from 'react'
// import "../../../Common-Modules/Scss/_dropdowns.scss"
import { CloseFilled, Filter } from "@carbon/react/icons";
import { FilterableMultiSelect } from '@carbon/react';

const DropdownFilters = ({filters, showFilters, setShowFilters, dropdownFilters, setDropdownFilters}) => {
  if(showFilters)
  return (
    <div className='full--screen-dropdowns'>
      <div onClick={() => setShowFilters(false)} className='pointer flex-display flex-end'>
          <CloseFilled size={16} fill="#fff" />
      </div>
      <div className='flex-1 flex-column pr-05'>
        {
          filters.map(filter => {
            return <FilterableMultiSelect
            id={filter.key}
            size="sm"
            key={`${filter.key}-${filter.displayName}`}
            items={filter.values || []}
            titleText={filter.displayName}
            placeholder="Select items"
            itemToString={(item) => (item ? item : "")}
            initialSelectedItems={dropdownFilters[filter.key] || []}
            onChange={(e) => setDropdownFilters(prev => {
              if(e.selectedItems.length > 0){
                return {...prev, [filter.key]: e.selectedItems}
              }else{
                const newObj = {...prev}
                delete newObj[filter.key]
                return newObj
              }
            })}
          />
          })
        }
      </div>
    </div>
  )
  return (<div className='filter-funnel pointer' onClick={() => setShowFilters(true)}> 
    <Filter size={16} fill="#fff" />
  </div>)
}

export default DropdownFilters