import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const BFF_URL =
  //"http://localhost:8000/bff";
  sessionStorage.getItem("bff");

const SVC_URL = sessionStorage.getItem("svc") || "localhost";
const AUTH_URL = sessionStorage.getItem("auth") || "localhost";

const projectVertex = sessionStorage.getItem("projectVertex") || "aiopsdemo";

const org = sessionStorage.getItem("organizationName")
const tenant = sessionStorage.getItem("tenant")

export const getErrorResponse = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return { err: true, message: error.response.status };
  }
  // else if (error.request) {
  //   The request was made but no response was received
  //   `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   http.ClientRequest in node.js
  //   console.log(error.request);
  // }
  else {
    // Something happened in setting up the request that triggered an Error
    return { err: true, message: error.message };
  }
  // console.log(error.config);
};

export const getDefaultRealm = () => {
  // return "itvsdp";
  const tenant = sessionStorage.getItem("tenant");
  if (tenant) {
    return tenant;
  }

  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    return sessionStorage.getItem("defaultRealm") !== null ? sessionStorage.getItem("defaultRealm") : "aiops";
  }
  return sessionStorage.getItem("defaultRealm") !== null ? sessionStorage.getItem("defaultRealm") : "ibmaiops";
};

const getOrgName = () => {
  // const currentOrg = JSON.parse(sessionStorage.getItem("currentOrg"));
  // // return currentOrg.id;
  return "62f4ea8ed804a7125ae45e9e";
};


export const getPermissions = async (keycloak) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  };

  // console.log("config", config)

  try {
    const response = await axios.get(`${BFF_URL}/keycloak/getPermission/tenant/${sessionStorage.getItem("tenant")}`, config);
    return response
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
}

/* User Info */
export const getUserInfo = async (keycloak) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  };

  try {
    const response = await axios.get(`${BFF_URL}/keycloak/userInfo?tenant=${sessionStorage.getItem("tenant")}&org=${sessionStorage.getItem("organizationName")}`, config);
    return response
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
}

export const getAlertCounts = async (keycloak) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant")
    }
  };

  try {
    const response = await axios.get(`${BFF_URL}/keycloak/alertCount?tenant=${sessionStorage.getItem("tenant")}&org=${sessionStorage.getItem("organizationName")}`, config);
    return response.data
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
}

//Org Details
export const getOrgDetails = async (keycloak, uuid) => {
  //let realm = getDefaultRealm();
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${keycloak.token}`,
  //     "X-Tenant": keycloak.realm,
  //     "logged-in": keycloak.tokenParsed.email,
  //     uuid: uuid,
  //   },
  // };
  try {
    // const response = await axios.get(
    //   `${AUTH_URL}/rbac/listUserOrgs/tenant/${realm}`,
    //   config
    // );
    const response = {
      data: [{ "name": "HCM", "org_property": { "default_roles": ["orgAdmin", "developer", "Devops Architect"] }, "status": "Active", "tenant_id": { "timestamp": 1707980406, "date": "2024-02-15T07:00:06.000+00:00" }, "description": "Default org for tenant ibm consulting", "displayName": "HCM", "organization_name": "HCM", "id": "65cdb677c87548563b79f67a" }]
    }
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

//Tenant Details
export const getTenantDetails = async (keycloak, uuid) => {
  //let realm = getDefaultRealm();
  return [{ "id": "65cdb676c87548563b79f677", "name": "ibmconsulting", "displayName": "IBM Consulting", "engagement_Id": "10000", "portfolio": [{ "name": "AIOPS", "description": "Value stream", "visualizationEnabled": "Yes", "automationEnabled": "Yes", "qeEnabled": "No", "startDate": "", "endDate": "", "applications": [{ "applicationName": "DevOps Commander", "administrator": "ramyvaid@in.ibm.com", "applicationInstance": [{ "appInstanceIdref": "62a314881ad94c392d847130" }] }] }] }]
  // return axios
  //   .get(`${BFF_URL}/keycloak/tenants?tenant=${realm}`, {
  //     headers: {
  //       Authorization: `Bearer ${keycloak.token}`,
  //       "X-Tenant": keycloak.realm,
  //       "logged-in": keycloak.tokenParsed.email,
  //       uuid: uuid,
  //     },
  //   })
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     return getErrorResponse(err);
  //   });
};

export const deActivateTenant = async (keycloak, realm_dele, uuid) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": realm_dele,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.delete(
      `${AUTH_URL}/rbac/tenant/${realm}?tenantName=${realm_dele}`,
      config
    );
    return response;
  } catch (err) {
    console.log(err);
    return {
      error: true,
      message: err.message,
    };
  }
};

/* User Details Start */
export const getUserDetails = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.get(
      `${SVC_URL}/org/user-info/tenant/${realm}`,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};
/* User Details End */


export const getPowerBiAccessToken = async (keycloak, uuid) => {
  //let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  return axios
    .get(`${BFF_URL}/allcollection/powerbi?tenant=${keycloak.realm}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return "Error";
    });
};

/* getCollection Apis Start */
export const getAnyCollection = async (keycloak, collectionName, flow) => {
  let realm = getDefaultRealm();
  // const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuidv4(),
    },
  };
  return axios
    .get(
      `${BFF_URL}/collection/get?tenant=${realm}&collectionName=${collectionName}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getElasticAnyCollection = async (keycloak, collectionName, key, value) => {
  //let realm = getDefaultRealm();
  //const orgId = getOrgName();
  let org = sessionStorage.getItem("organizationName");
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuidv4(),
    },
  };

  // https://aiops-insights-svc-aiops-core-services.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud/svc/aiops2.0/allcollection/ibmaiops-dev/ibmconsulting?collectionName=domain&&orgName=Telco

  return axios
    .get(
      `${BFF_URL}/collection/getElactic?tenant=${keycloak.realm}&collectionName=${collectionName}&key=${key}&value=${value}&accTypeName=${tenant}&partyName=${org}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getDateWiseFiltredData= async (keycloak, uuid, collectionName,  startDate, endDate, fields, dateField ) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/collection/dateFilter?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&fields=${fields}&dateField=${dateField}&sdate=${startDate}&edate=${endDate}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

/* getCollection Apis End */

export const checkTenantDupilicates = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${AUTH_URL}/rbac/checkduplicate/tenant/${realm}?check=${data}&param=tenant`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const checkOrgDupilicates = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${AUTH_URL}/rbac/org/checkduplicate/tenant/${realm}?orgName=${data}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const checkAppDupilicates = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${AUTH_URL}/rbac/org/app/checkduplicate/tenant/${realm}?appName=${data}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const postTenantDetails = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${AUTH_URL}/rbac/create/tenant/${realm}`, data, config)
    .then((response) => {
      //
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const updateTenantDetails = async (keycloak, id, data, uuid) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .put(
      `${AUTH_URL}/rbac/tenants/tenant/${realm}?tenantIDRef=${id}`,
      data,
      config
    )
    .then((response) => response)
    .catch((err) => ({
      error: true,
      message: err.message,
    }));
};

export const updateOrgDetails = async (keycloak, orgId, data, uuid) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .put(`${AUTH_URL}/rbac/org/tenant/${realm}?orgIdref=${orgId}`, data, config)
    .then((response) => response)
    .catch((err) => ({
      error: true,
      message: err.message,
    }));
};

export const getUsersFromKeycloak = async (keycloak) => {
  let realm = getDefaultRealm();
  let url = sessionStorage.getItem("keycloak");
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  return axios
    .get(`${url}/auth/admin/realms/${realm}/users`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const getUserRolesForRealm = async (keycloak, userId) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  const base_url = sessionStorage.getItem("keycloak");
  const url = `${base_url}/auth/admin/realms/${realm}/users/${userId}/role-mappings/realm`;
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

export const getUserRolesforOrg = (keycloak, userId, orgId, name) => {
  const realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  const base_url = sessionStorage.getItem("keycloak");
  const url = `${base_url}/auth/admin/realms/${realm}/users/${userId}/role-mappings/clients/${orgId}`;
  return new Promise((resolve, reject) => {
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        resolve({ name, data });
      })
      .catch((err) => reject(err));
  });
};

export const getUserList = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(`${AUTH_URL}/rbac/users/tenant/${realm}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return "Error";
    });
};

export const fetchUserRoles = async (keycloak, type, uuid) => {
  let realm = getDefaultRealm();
  let url;
  if (type === "tenant") url = `${SVC_URL}/rbac/roles/tenant/${realm}`;
  else url = `${AUTH_URL}/rbac/roles/tenant/${realm}?isOrgUser=true`;
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        error: true,
      };
    });
};

// export const postUserDetails = async (keycloak, uuid, data) => {
//   let realm = getDefaultRealm();
//   data.tenant = realm;
//   const config = {
//     headers: {
//       Authorization: `Bearer ${keycloak.token}`,
//       "X-Tenant": keycloak.realm,
//       "logged-in": keycloak.tokenParsed.email,
//       uuid: uuid,
//     },
//   };
//   return axios
//     .post(`${AUTH_URL}/rbac/user/tenant/${realm}`, [data], config)
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       return {
//         error: true,
//         message: err.message,
//       };
//     });
// };

// http://localhost:8080/svc/org/user/tenant/tenant0802_new_12
export const postOrgUserDetails = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  data.tenant = realm;
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${AUTH_URL}/rbac/org/user/tenant/${realm}`, [data], config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      };
    });
};

/* HomePage Start */
export const getWizard = (keycloak, collection) => {
  return {
    NewHomeDashboard: [
      {
        "dashboard": [
          {
            "title": "Insights",
            "route": "insights",
            "data": [
              {
                "title": "Development",
                "route": "iALM",
                "icon": "Development",
                "tag": "Healthy",
                "id": "iALM",
                "metrics": {
                  "chartData": [
                    {
                      "title": "Build Status",
                      "values": [
                        {
                          "group": "Success",
                          "value": 120
                        },
                        {
                          "group": "Failure",
                          "value": 26
                        }
                      ]
                    },
                    {
                      "title": "Risk Assessment",
                      "values": [
                        {
                          "group": "High",
                          "value": 2
                        },
                        {
                          "group": "Medium",
                          "value": 1
                        },
                        {
                          "group": "Low",
                          "value": 0
                        }
                      ]
                    },
                    {
                      "title": "Velocity",
                      "values": [
                        {
                          "key": "Flame Blue S2 2024",
                          "group": "Committed",
                          "value": 106
                        },
                        {
                          "key": "Flame Blue S2 2024",
                          "group": "Completed",
                          "value": 76
                        },
                        {
                          "key": "Agile Alchemists Q2 S1",
                          "group": "Committed",
                          "value": 68
                        },
                        {
                          "key": "Agile Alchemists Q2 S1",
                          "group": "Completed",
                          "value": 68
                        },
                        {
                          "key": "Flame Blue S1 2024",
                          "group": "Committed",
                          "value": 109
                        },
                        {
                          "key": "Flame Blue S1 2024",
                          "group": "Completed",
                          "value": 61
                        },
                        {
                          "key": "Jade Green S1 2024 -",
                          "group": "Committed",
                          "value": 121
                        },
                        {
                          "key": "Jade Green S1 2024 -",
                          "group": "Completed",
                          "value": 80
                        }
                      ]
                    }
                  ],
                  "tileData": [
                    {
                      "title": "Avg Build Duration",
                      "value": "18 mins"
                    },
                    {
                      "title": "Vulnerabilities",
                      "value": 10
                    },
                    {
                      "title": "Avg Build per Day",
                      "value": 4
                    }
                  ]
                },
                "description": "GenAI-powered insights illuminate the efficiency of the development lifecycle.",
                "data": []
              },
              {
                "title": "Security",
                "route": "securityOps",
                "icon": "FirewallClassic",
                "tag": "Healthy",
                "id": "securityOps",
                "metrics": {
                  "chartData": [
                    {
                      "title": "Issues by Severity",
                      "values": [
                        {
                          "group": "Medium",
                          "value": 1319
                        },
                        {
                          "group": "High",
                          "value": 933
                        },
                        {
                          "group": "Low",
                          "value": 208
                        },
                        {
                          "group": "Informational",
                          "value": 15
                        }
                      ]
                    },
                    {
                      "title": "Top 5 Issues",
                      "values": [
                        {
                          "group": "PrivilegeEscalation",
                          "value": 174
                        },
                        {
                          "group": "PathTraversal",
                          "value": 177
                        },
                        {
                          "group": "Authentication.Credentials.Unprotected",
                          "value": 426
                        },
                        {
                          "group": "Second Order Injection",
                          "value": 464
                        },
                        {
                          "group": "Validation Required",
                          "value": 799
                        }
                      ]
                    }
                  ],
                  "tileData": [
                    {
                      "title": "Applications",
                      "value": "3"
                    },
                    {
                      "title": "Worker Nodes",
                      "value": 15
                    }
                  ]
                },
                "description": "Uncover vulnerabilities within your IT workload through intelligent security insights.",
                "data": []
              },
              {
                "title": "Operations",
                "route": "itOperations",
                "icon": "CallsAll",
                "tag": "Healthy",
                "id": "itOperations",
                "metrics": {
                  "chartData": [
                    {
                      "title": "Sev 1 Incidents",
                      "values": [
                        {
                          "group": "Sev 1",
                          "value": 2
                        },
                        {
                          "group": "Others",
                          "value": 3
                        }
                      ]
                    },
                    {
                      "title": "Alerts by Categories",
                      "values": [
                        {
                          "group": "Network",
                          "value": 5
                        },
                        {
                          "group": "Application",
                          "value": 2
                        },
                        {
                          "group": "Infrastructure",
                          "value": 3
                        }
                      ]
                    },
                    {
                      "title": "Job Status",
                      "values": [
                        {
                          "name": "Success",
                          "value": 289
                        },
                        {
                          "name": "Failed",
                          "value": 3
                        },
                        {
                          "name": "Running",
                          "value": 50
                        },
                        {
                          "name": "Yet to Start",
                          "value": 90
                        }
                      ]
                    }
                  ],
                  "tileData": [
                    {
                      "title": "Service Integrations Failed",
                      "value": "4"
                    },
                    {
                      "title": "Errors",
                      "value": "5"
                    }
                  ]
                },
                "description": "Unlocks Day 2 operational efficiencies through GenAI infused insights.",
                "data": []
              },
              {
                "title": "Value Stream",
                "route": "valueStream",
                "icon": "Process",
                "tag": "Error",
                "metrics": {
                  "chartData": [
                    {
                      "title": "Journey Status",
                      "values": [
                        {
                          "group": "Success",
                          "value": 11
                        },
                        {
                          "group": "Failure",
                          "value": 1
                        }
                      ]
                    },
                    {
                      "title": "Interaction Volume Status",
                      "values": [
                        {
                          "group": "Success",
                          "key": "22:30:00",
                          "value": 100
                        },
                        {
                          "group": "Success",
                          "key": "22:45:00",
                          "value": 65
                        },
                        {
                          "group": "Success",
                          "key": "23:10:00",
                          "value": 80
                        },
                        {
                          "group": "Success",
                          "key": "23:30:00",
                          "value": 120
                        },
                        {
                          "group": "Success",
                          "key": "23:50:00",
                          "value": 150
                        },
                        {
                          "group": "Fail",
                          "key": "22:30:00",
                          "value": 35
                        },
                        {
                          "group": "Fail",
                          "key": "22:45:00",
                          "value": 20
                        },
                        {
                          "group": "Fail",
                          "key": "23:10:00",
                          "value": 15
                        },
                        {
                          "group": "Fail",
                          "key": "23:30:00",
                          "value": 12
                        },
                        {
                          "group": "Fail",
                          "key": "23:50:00",
                          "value": 24
                        }
                      ]
                    }
                  ],
                  "tileData": [
                    {
                      "title": "Customer Journey",
                      "value": "12"
                    }
                  ]
                },
                "description": "Gain insights to Business impact by correlating IT & Business Metrics.",
                "id": "banking",
                "data": [
                  {
                    "title": "Financial Service",
                    "description": "",
                    "id": "banking",
                    "key": "banking",
                    "disabled": false,
                    "icon": "chart--line--data",
                    "data": []
                  }
                ]
              }
            ]
          }
        ],
        "orgIdref": "662f6997d8287b32fd00d4f2"
      }
    ]
  }
  //getCollection(keycloak, collection)
}
/* HomePage End */


/* Gen AI Apis */
export const getVertexResponse = async (data, flow, keycloak) => {
  let realm = getDefaultRealm();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": realm,
      "logged-in": keycloak.tokenParsed.email
    },
  };
  return axios
    .post(`${BFF_URL}/vertexAi/${realm}/api?flow=${flow}`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getGenAIResponse = async (data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  return axios
    .post(`${BFF_URL}/vertexAi/${realm}/genAI`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getWatsonxGenAIlogs = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  //const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/applicationLM/logs/${realm}`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getAzureGenAIlogs = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  //const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(`${BFF_URL}/applicationLM/azure/${realm}`, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};
/* Gen AI Api Ends */

/* Instrumentation Starts */

export const getToolFormFields = async (field, keycloak, uuid) => {
  const realm = getDefaultRealm();
  // const org = getOrgName();
  return axios.get(
    `${BFF_URL}/allcollection/pipelineTool?tenant=${keycloak.realm}&field=${field}`,
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": realm,
      },
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((err) => err);
};
/* Instrumentation Ends */

/* value stream start */
export const watsonXSummarize = async (keycloak, uuid, data) => {
  return axios
    .post(`https://us-central1-aiplatform.googleapis.com/v1/projects/ictewantstoken/locations/us-central1/publishers/google/models/text-bison:predict`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const watsonXStructured = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/amexSummary/${realm}/watsonxStructure`,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const sendMail = async (keycloak, uuid, data) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(
      `${BFF_URL}/amexSummary/${realm}/sendMail`,
      data,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const AmexSummarize = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      `https://us-central1-aiplatform.googleapis.com/v1/projects/${projectVertex}/locations/us-central1/publishers/google/models/text-bison:predict`,
      data,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const googletoken = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(`${BFF_URL}/googleToken/${realm}/getToken`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return getErrorResponse(err);
    });
};

export const getSimilarIncidents = async (payload, keycloak) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email
    },
  };
  return axios
    .post(`${BFF_URL}/similarInc/similarIncidents`, payload, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

/* value stream end */

/* IntelligentALM starts */

export const triggerJenkinsBuildforGenAI = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return await axios
    .post(
      `${BFF_URL}/allcollection/pipelineTrigger?tenant=${realm}&orgIdref=${org}&toolchainIdref=6523c75493e6ae6a4dca2781`,
      {},
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return getErrorResponse(err);
    });
};

export const JenkinsBuildStatusforGenAI = async (keycloak, uuid) => {
  // const realm = getDefaultRealm();
  // const orgId = getOrgName();
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${keycloak.token}`,
  //     "X-Tenant": keycloak.realm,
  //     "logged-in": keycloak.tokenParsed.email,
  //     uuid: uuid,
  //   },
  // };
  // return await axios
  //   .get(`${BFF_URL}/applicationLM/build?tenant=${realm}`, config)
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     return getErrorResponse(err);
  //   });
  return {
    "_class": "com.cloudbees.hudson.plugins.folder.Folder",
    "jobs": [
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 92,
          "result": "FAILURE",
          "timestamp": 1711439753532
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 1,
          "result": "SUCCESS",
          "timestamp": 1699531142266
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 144,
          "result": "FAILURE",
          "timestamp": 1708328544758
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 42,
          "result": "FAILURE",
          "timestamp": 1699879090173
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 538,
          "result": "FAILURE",
          "timestamp": 1702893675022
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 61,
          "result": "SUCCESS",
          "timestamp": 1702894758144
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 2,
          "result": "FAILURE",
          "timestamp": 1699532038503
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 41,
          "result": "FAILURE",
          "timestamp": 1708426385940
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 11,
          "result": "FAILURE",
          "timestamp": 1702308827664
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 257,
          "result": "FAILURE",
          "timestamp": 1708422010104
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 48,
          "result": "FAILURE",
          "timestamp": 1708430582282
        }
      },
      {
        "_class": "hudson.model.FreeStyleProject",
        "lastBuild": null
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 51,
          "result": "FAILURE",
          "timestamp": 1708450293659
        }
      },
      {
        "_class": "org.jenkinsci.plugins.workflow.job.WorkflowJob",
        "lastBuild": {
          "_class": "org.jenkinsci.plugins.workflow.job.WorkflowRun",
          "number": 35,
          "result": "FAILURE",
          "timestamp": 1704353078933
        }
      },
      {
        "_class": "hudson.model.FreeStyleProject",
        "lastBuild": null
      }
    ]
  };
}

/* IntelligentALM ends */

/* Security Operations api Starts */
export const getAppScanData = async (
  keycloak,
  uuid,
  collectionName,
  filterChartData
) => {
  const realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios.get(
    `${BFF_URL}/securityDashboards/appscan/${realm}/${collectionName}?orgId=${orgId}&filterChart=${filterChartData}`,
    config
  );
};

export const getMendLibraries = async (keycloak, uuid, collectionName, filterChartData) => {
  const realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios.get(
    `${BFF_URL}/securityDashboards/mend/${realm}/${collectionName}?orgId=${orgId}&filterChart=${filterChartData}`,
    config
  );
};


export const getTwistlockIssues = async (
  keycloak,
  uuid,
  collectionName,
  hirarchy
) => {
  const realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios.get(
    `${BFF_URL}/securityDashboards/twistlock/issues/${realm}/${collectionName}?orgId=${orgId}&hirarchy=${hirarchy}`,
    config
  );
};


/* Security operations Api ends */


/* Susops Api starts */
export const getApplicationData = async (keycloak, name, uuid) => {
  const realm = getDefaultRealm();
  const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    let response;
    if (name) {
      response = await axios.get(
        `${BFF_URL}/susops/application?tenant=${realm}&orgIdref=${org}&application=${name}&year=2022`,
        config
      );
    } else {
      response = await axios.get(
        `${BFF_URL}/susops/application?tenant=${realm}&orgIdref=${org}&application=${name}&year=2022`,
        config
      );
    }
    return response.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

export const getTicketDetails = async (keycloak, name, uuid) => {
  const realm = getDefaultRealm();
  const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    let response;
    if (name) {
      response = await axios.get(
        `${BFF_URL}/susops/carbonemission?tenant=${realm}&orgIdref=${org}&application=${name}&year=2022`,
        config
      );
    } else {
      response = await axios.get(
        `${BFF_URL}/susops/carbonemission?tenant=${realm}&orgIdref=${org}`,
        config
      );
    }
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};
/* Susops Api ends */

/* JobObservabillity */
export const getDirectvData = async (keycloak, uuid, collectionName, flow) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/jobObservability/get?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&data=${flow}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getDirectvDataDateWise = async (keycloak, uuid, collectionName, sdate, edate, fields, dateField) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/jobObservability/getDateWise?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&fields=${fields}&dateField=${dateField}&sdate=${sdate}&edate=${edate}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getAuditReport = async (keycloak, uuid, collectionName) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName()
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      "uuid": uuid
    },
  };
  return axios
    .get(`${BFF_URL}/jobObservability/audit?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return ({
        err: true,
        message: "Unexpected Error Occured"
      });
    });
};

export const getdirectvDetails = async (keycloak, uuid, collectionName, flow) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName()
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      "uuid": uuid
    },
  };
  return axios
    .get(`${BFF_URL}/jobObservability/directvDetails?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&data=${flow}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return ({
        err: true,
        message: "Unexpected Error Occured"
      });
    });
};

export const createSnowTicketDirectv = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      "uuid": uuid
    },
  };
  return axios.post(`${BFF_URL}/jobObservability/createTicket?`, data, config)
};

/* Job Observabillity Ends */

/* PNC APIS Starts */
export const getMSTeams = (data, keycloak) => {
  let realm = getDefaultRealm();
  const body = {
    ticket: data
  }
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email
    },
  };
  return axios.post(`${BFF_URL}/vertexAi/${realm}/msTeams`, body, config)
}

export const getHCSCMSTeams = (data, keycloak) => {
  const body = {
    ticket: data
  }
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email
    },
  };
  return axios.post(`${BFF_URL}/hcsc/msTeams`, body, config)
}

export const getInstanaSynthtic = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/realTime/${realm}/getInstanaSynthetic?orgId=${orgId}`,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getPNCLogDetails = async (keycloak, uuid, collectionName, flow) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };

  let url = `${BFF_URL}/pnc/pncLogs?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}`;
  if(flow !== undefined) {
    url = `${BFF_URL}/collection/get?tenant=${realm}&collectionName=ato_genai_response`;
  }

  return axios
    .get(
      url,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getMLSimilarIncident = async (keycloak, uuid, data, flow) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      uuid: uuid,
    },
  };
  const query = {
    note: data
  }

  return flow === undefined ? axios
    .post(`${BFF_URL}/vertexAi/MLSimilarIncident?flow=${flow}`, query, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    }) : axios
    .get(
      `${BFF_URL}/collection/get?tenant=${realm}&collectionName=ato_genai_response`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });;
};

export const getServiceNowCRData = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/realTime/${realm}/getLiveServiceNowCR?orgId=${orgId}`,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getServiceNowData = async (keycloak, uuid, flow, props) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };

console.log("props ========", props)
  let url = `${BFF_URL}/realTime/${realm}/getLiveServiceNow?orgId=${orgId}&flow=${flow}`;
  if(props !== undefined) {
    url = `${BFF_URL}/collection/get?tenant=${realm}&collectionName=ato_incident_analysis`;
  }

  return axios
    .get(
      url,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getAssignee = async (keycloak, uuid, data, flow) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      uuid: uuid,
    },
  };
  const query = {
    note: data
  }
  return axios
    .post(`${BFF_URL}/vertexAi/${realm}/getAssignee?flow=${flow}`, query, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};
/* PNC APIS Ends */


/* DO External */
export const getDOExternalApi = async (keycloak, org, roles) => {
  // console.log("keycloak --------", keycloak, org);
  const body =
  {
    "partyName": org,
    "accTypeName": "CAI",
    "userId": keycloak.tokenParsed.email,
    "incident_status": ["Open", "In Progress"],
    "role": roles
  }

  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
    },
  };

  return axios
    .post(`${BFF_URL}/DO/getExternal`, body, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const postDOExternalApi = async (keycloak, payload) => {
  // console.log("keycloak --------", keycloak, org);
  const body = payload

  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
    },
  };

  return axios
    .post(`${BFF_URL}/DO/postDoCreateTicket`, body, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};
/* Talk to Docs start */
export const getPrediction = async (keycloak, uuid, data, flow) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      uuid: uuid,
    },
  };
  const body = {
    query: data
  }
  return axios
    .post(`${BFF_URL}/vertexAi/${realm}/prediction`, body, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const uploadStatus = async (keycloak, uuid, name) => {
  let realm = getDefaultRealm();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.get(
      `${SVC_URL}/excelfile/uploadstatus/tenant/${realm}?givenName=${name}`,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

export const uploadNames = async (keycloak, uuid) => {
  let realm = getDefaultRealm();
  const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.get(
      `${SVC_URL}/excelfile/uploadnames/tenant/${realm}?orgIdref=${org}`,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

export const deleteFile = async (keycloak, name, uuid) => {
  let realm = getDefaultRealm();
  const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  try {
    const response = await axios.delete(
      `${SVC_URL}/excelfile/delete/tenant/${realm}?uploadName=${name}&orgIdref=${org}`,
      config
    );
    return response;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};
/* talk to docs end */


/* Citi Apis Starts */
export const getCitiIncidents = async (keycloak, uuid, fields,
  dateField,
  startTime,
  endTime) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/citiBank/blockedHosts?tenant=${realm}&orgId=${orgId}&field=${fields}&dateField=${dateField}&startTime=${startTime}&endTime=${endTime}`,
      config
    )
};

export const postSendMail = async (keycloak, uuid, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      uuid: uuid,
    },
  };
  const query = {
    message: data
  }
  return axios
    .post(`${BFF_URL}/email/send`, query, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return "Error";
    });
};

export const getCitiSession = async (keycloak, uuid, collectionName, fields,
  dateField,
  startTime,
  endTime) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/citiBank/get?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&fields=${fields}&dateField=${dateField}&startTime=${startTime}&endTime=${endTime}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getCitiSesssionDetails = async (keycloak, sessionId, clusterName, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
    },
  };
  return axios
    .get(
      `${BFF_URL}/citiBank/sessionDetails?sessionId=${sessionId}&clusterName=${clusterName}&params=${params}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getToolchainsQuery = async (keycloak, category, uuid) => {
  //const realm = getDefaultRealm();
  //const org = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/citiBank/managetoolchain?tenant=${keycloak.realm}&orgIdref=661cf5e5574e2b6abfc3233a&category=${category}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });


};

/* Citi Apis Ends */

/* Telco Apis Starts */

export const getIncidentDetails = async (keycloak, uuid, inputValue) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/telco/${realm}/getTicketDetails?orgId=${orgId}&inputValue=${inputValue}`,

      config,
      { timeout: 600000 }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getAnalyzedDataDetails = async (keycloak, uuid, inputValue) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .post(
      `${BFF_URL}/telco/${realm}/getAnalyzedDetails?orgId=${orgId}`,
      inputValue,
      config,
      { timeout: 600000 }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

/* Telco Apis Ends */

/* ibm consulting advantage */
export const getAdvantageUserRole = async (keycloak, token) => {
  const team = sessionStorage.getItem("teamId");
  const pars = JSON.parse(team)
  console.log("team ========= >>>>>>>>", team)
  const config = {
    headers: {
      "x-access-token": token,
      Authorization: `Bearer ${keycloak.token}`,
      "userEmail": keycloak.tokenParsed.email,
      "teamName":sessionStorage.getItem("tenant"),
      "teamId": pars.teamId,
    },
  };

  console.log("config ========= >>>>>>>>", config)

  return axios
    .get(
      `${BFF_URL}/ica/getCatalog`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getAdvantageMetering = async (keycloak, user, token) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  return axios
    .get(
      `${BFF_URL}/ica/getAdvantageMetering?userEmail=${user.userEmail}&teamName=${user.teamName}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getHCSCTicketSummarize= async (keycloak, uuid, data ) => {
  // let realm = getDefaultRealm();
  // const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios.get(`${BFF_URL}/hcsc/getTicketSummarization?work_note=${data}`, config)
};

export const getHCSCSimilarIncidents= async (keycloak, uuid, data ) => {
  // let realm = getDefaultRealm();
  // const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios.get(`${BFF_URL}/hcsc/getSimilarIncidents?work_note=${data}`, config)
};

export const getHCSCChangeRequests= async (keycloak,
   uuid, number, desc) => {
  // let realm = getDefaultRealm();
  // const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  // INC4300431"
  // return axios.get(`${BFF_URL}/hcsc/getChangeRqeuests?work_note=${JSON.stringify(desc)}&number=INC4300824`, config)
  return axios.get(`${BFF_URL}/hcsc/getChangeRqeuests?number=${number}`, config)
};

/* Soc Apis starts */

export const getIncidentAnalyticsSOC = async (
  keycloak,
  uuid,
  collectionName,
  filterChart,
  startDate,
  endDate
) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/socIncidentAnalytics/get?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&filterChart=${filterChart}&startDate=${startDate}&endDate=${endDate}`,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getIncidentAnalyticsTabularDataSOC = async (
  keycloak,
  uuid,
  collectionName,
  filterChart,
  startDate,
  endDate
) => {
  let realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": keycloak.realm,
      "logged-in": keycloak.tokenParsed.email,
      "locale-language": sessionStorage.getItem("localeLanguage"),
      "organization": sessionStorage.getItem("organizationName"),
      uuid: uuid,
    },
  };
  return axios
    .get(
      `${BFF_URL}/socIncidentAnalytics/getTabularData?tenant=${realm}&collectionName=${collectionName}&orgId=${orgId}&filterChart=${filterChart}&startDate=${startDate}&endDate=${endDate}`,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return {
        err: true,
        message: "Unexpected Error Occured",
      };
    });
};

export const getIncidentAnalyticsReports = async (
  keycloak,
  tabName,
  uuid,
  collectionName,
  filterChartData,
  dropDown
) => {
  //const realm = getDefaultRealm();
  const dropdownString = Object.entries(dropDown)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`)
    .join('&');
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant":sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      "locale-language": sessionStorage.getItem("localeLanguage"),
      "organization": sessionStorage.getItem("organizationName"),
      uuid: uuid,
    },
  }
  return axios.get(
    `${BFF_URL}/incidentAnalyticsReports/${keycloak.realm}/${collectionName}?tabName=${tabName}&orgId=${orgId}&filterChart=${filterChartData}&${dropdownString}`,
    config
  )
};

export const getIncidentAnalyticsProbabilityAnalysisReports = async (
  keycloak,
  tabName,
  uuid,
  collectionName,
  filterChartData
) => {
  //const realm = getDefaultRealm();
  const orgId = getOrgName();
  const config = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "X-Tenant": sessionStorage.getItem("tenant"),
      "logged-in": keycloak.tokenParsed.email,
      "locale-language": sessionStorage.getItem("localeLanguage"),
      "organization": sessionStorage.getItem("organizationName"),
      uuid: uuid,
    },
  };
  return axios.get(
    `${BFF_URL}/incidentAnalyticsReports/probabilityAnalysis/${keycloak.realm}/${collectionName}?tabName=${tabName}&orgId=${orgId}&filterChart=${filterChartData}`,
    config
  );
};