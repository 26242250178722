const isEmptyResponse = (res) => {
    return !Boolean(res) || 
           (Array.isArray(res) && res.length === 0) || 
           (typeof res === "object" && Object.keys(res).length === 0);
};

const isEmptyData = (res) => {
    return !Boolean(res.data) || 
           (typeof res.data === "object" && !Array.isArray(res.data) && Object.keys(res.data).length === 0);
};

const hasErrorInResponse = (res) => {
    return res?.data?.itvsdpStatusCode && res?.data?.itvsdpStatusCode !== 200;
};

const hasErrorMessage = (res) => {
    return res?.data?.error;
};

const hasValidStatus = (res) => {
    return res.status === 200;
};

export const findApiError = (res) => {
    try {
        if (isEmptyResponse(res)) {
            throw new Error("Request Failed with empty response")
        }

        if (isEmptyData(res)) {
            throw new Error(`Request Failed with status ${res.status} but empty response`)
        }

        if (hasErrorInResponse(res)) {
            throw new Error(res?.data?.itvsdpResponseMessage || "Something has Went Wrong")
        }

        if (hasErrorMessage(res)) {
            throw new Error(res.data.error);
        }

        if (!hasValidStatus(res)) {
            throw new Error(`Request Failed with status code: ${res.status}`)
        }

        return {
            error: false
        }

    } catch (err) {
        console.log(err);
        return {
            error: true,
            message: err.message,
        };
    }
};
