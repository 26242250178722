import { Breadcrumb, BreadcrumbItem, Column, Grid } from "@carbon/react";
import React from "react";
import GitHubRepoDetailsReport from "./GitHubRepoDetailsReport";

function GitHubRepoDetails(props) {
    return (
        <>
            <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem>
                        {/* <BreadcrumbItem href="#/iALM">Product Engineering</BreadcrumbItem> */}
                        <BreadcrumbItem href="#/iALM">Version Control</BreadcrumbItem>
                        {/* <BreadcrumbItem href="#/gitHubRepos">GitHub Repos</BreadcrumbItem> */}
                        <BreadcrumbItem isCurrentPage>Repo Details</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid>
            <div className="content-div">
                <GitHubRepoDetailsReport props={props}/>
            </div>
        </>
    );
}

export default GitHubRepoDetails;