import { ClickableTile, Column } from "@carbon/react";
import { ArrowRight } from "@carbon/react/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const PortfolioTiles = (props) => {
  const history = useHistory();
  const handleOnClick = (route, title) => {
    // console.log("raisss =============", route)
    if (route) {
      sessionStorage.setItem("portfolioTitle", title);
      history.push({ pathname: route });
    }
  };


  // console.log("props.portfolioData ================", props.portfolioData)

  return (
    <>
      {props.portfolioData.map((data, index) => (
        <Column
          lg={4}
          md={4}
          sm={2}
          className="clickable-tile-portfolio-fss"
          key={data.title}
          style={{ paddingLeft: "0px" }}
        >
          <ClickableTile
            id={index}
            onClick={() => handleOnClick(data.route, data.title)}
            aria-label={`clickableTile-${index}`}
          >
            <div>
              <h5 className="flow-title">{data.title}</h5>
              {/* <h4 className={`flow-value ${!data.isAboveThreshold  && "folw-value-err"}`}>{data.value}</h4> */}
            </div>
            <div className="availability-status">
              {/* <div className="availBox">
                {data.isAboveThreshold ? (
                  <CheckmarkFilled
                    fill="#198038"
                    size="12"
                    className="svg-status"
                  />
                ) : (
                  <Misuse fill="#da1e28" size="12" className="svg-status" />
                )}
                <h6
                  className={`status-title ${
                    data.isAboveThreshold ? "green-txt" : "red-txt"
                  }`}
                >
                  {data.status}
                </h6>
              </div> */}
              <div className="target">
                {data.target.map((res) => {
                  const newSim = Object.entries(res).map(([key, value]) => ({
                    key,
                    value,
                  }));
                  return (
                    <ul key={uuidv4()}>
                      {newSim.map((erlog, index) => {
                        return (
                          <li className={index === 1 ? "targ" : ""} key={uuidv4()}>
                            <span className="">
                              {index === 0 ? (
                                <>
                                  <div
                                    style={{ float: "left" }}
                                    className={
                                      erlog.key === "Actual" && erlog.value > 97
                                        ? "green"
                                        : erlog.key === "Actual"
                                        ? "red"
                                        : ""
                                    }
                                  >
                                    {erlog.value}
                                  </div>
                                  <p>
                                    %{" "}
                                    {index === 0 ? `within ${data.min}` : null}
                                  </p>
                                </>
                              ) : null}
                              {/* {index === 1 ? <Tag size="sm" className="some-class" type="purple">
                        {erlog.key}:{erlog.value}
                      </Tag> : null}  */}
                            </span>
                            {index === 0 ? <h5>{erlog.key}</h5> : null}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
              {/* <h6 className="recent-bridge">
                Open Bridge:{" "}
                <span style={{ fontSize: "14px" }}>{data.recentBridge}</span>
              </h6> */}
            </div>
            <div>
              <ArrowRight size={16} className="arrow-icon"></ArrowRight>
            </div>
          </ClickableTile>
        </Column>
      ))}
    </>
  );
};

export default PortfolioTiles;
