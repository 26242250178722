import {
  SkeletonPlaceholder,
  SkeletonText
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import Elk from "./elkTopology";


const size = 48;

const node = [
  { id: "a", height: size, width: size, title: "Card Shop", types: ["Wikis"], errorRate: 0 },
  { id: "b", height: size, width: size, title: "Data Store", types: ["jdbc"], errorRate: 0 },
  { id: "c", height: size, width: size, title: "My Login", types: ["Wikis"], errorRate: 0 },
  { id: "d", height: size, width: size, title: "Fetch Card Details", types: ["jdbc"], errorRate: 0 },
  { id: "e", height: size, width: size, title: "Capture Personnal Info", types: ["Wikis"], errorRate: 0 },
  { id: "f", height: size, width: size, title: "Capture Business Info", types: ["Wikis"], errorRate: 0 },
  { id: "g", height: size, width: size, title: "Save & Complete Later", types: ["jdbc"], errorRate: 0 },
  { id: "h", height: size, width: size, title: "Submit", types: ["HTTP"], errorRate: 1 },
  { id: "i", height: size, width: size, title: "Auto Approve", types: ["MESSAGING"], errorRate: 0 },
  { id: "j", height: size, width: size, title: "Manual Approve", types: ["MESSAGING"], errorRate: 0 }
];

const link = [
  { id: "1", source: "a", target: "c" },
  { id: "2", source: "b", target: "c" },
  { id: "3", source: "d", target: "c" },
  { id: "4", source: "c", target: "e" },
  { id: "5", source: "c", target: "f" },
  { id: "6", source: "e", target: "g" },
  { id: "7", source: "g", target: "h" },
  { id: "8", source: "h", target: "i" },
  { id: "9", source: "h", target: "j" }
];


const TopologyGraph = (props) => {
  const [linkData, setlinkData] = useState([]);
  const [nodeData, setNodeData] = useState([]);

  // const nodeHeight = 64;
  // const nodeWidth = 180;
  // const size = 48;

  useEffect(() => {
    setlinkData(link)
    setNodeData(node)
  }, [])

  // useEffect(() => {
  //   let keyData = [];
  //   props.valueStreamData.forEach((vs) => {
  //     vs.workflowDetails.forEach((workflow) => {
  //       workflow["Row2"] !== undefined && workflow["Row2"].forEach((row2) => {
  //         row2.steps !== undefined && row2.steps.forEach((step) => {
  //           if (step["topology"]) {
  //             keyData = step["topology"]
  //           }
  //         })
  //       })
  //     })
  //   })
  //   if(Object.entries(keyData).length > 0) {
  //     getTopologyData(keyData)
  //   }
  // }, [props])

  // const getTopologyData = (instanaTopology) => {

  //   // const nodeData = [
  //   //   { id: "a", height: size, width: size, label: "Prepaid", "types": ["jdbc"] },
  //   //   { id: "b", height: 160, width: 60, label: "Integration Svc Layer", "types": ["HTTP"] },
  //   //   { id: "c", height: size, width: size, label: "Billing System", "types": ["MESSAGING"] },
  //   //   { id: "d", height: size, width: size, label: "MDMS", "types": ["unknown"] },
  //   //   { id: "e", height: 160, width: 60, label: "Integration Svc Layer", "types": ["jdbc"] },
  //   //   { id: "f", height: size, width: size, label: "HES", "types": ["unknown"] },
  //   //   { id: "g", height: 160, width: 60, label: "Integration Svc Layer", "types": ["unknown"] },
  //   //   { id: "h", height: size, width: size, label: "Meter", "types": ["unknown"] }
  //   // ];

  //   // const linkData = [

  //   //   { id: "1", source: "a", target: "b" },
  //   //   { id: "2", source: "b", target: "c" },
  //   //   { id: "3", source: "c", target: "d" },
  //   //   { id: "4", source: "d", target: "e" },
  //   //   { id: "5", source: "e", target: "f" },
  //   //   { id: "6", source: "f", target: "g" },
  //   //   { id: "7", source: "g", target: "h" }
  //   // ];

  //   const connected = instanaTopology.connections.map((topo, index) => {
  //     let newObj = {
  //       source: topo.from,
  //       target: topo.to
  //     };
  //     // instanaTopology.connections.forEach((conn) => {
  //     //   if (topo.from === conn.from && topo.to === conn.to) {
  //     //     newObj = {
  //     //       ...newObj,
  //     //       errorRate: conn.errorRate,
  //     //     }
  //     //   }
  //     // })
  //     return {
  //       id: index + 1,
  //       ...newObj
  //     }
  //   })
  //   const node = instanaTopology.services.map((serv, index) => {
  //     let newServ = serv;
  //     connected.forEach((con) => {
  //       if (con.target === serv.id) {
  //         newServ = {
  //           ...newServ,
  //           error: con.errorRate
  //         }
  //       }
  //     })
  //     return {
  //       ...newServ,
  //       width: 60,
  //       height: 60,
  //     }
  //   })
  //   setlinkData(connected)
  //   setNodeData(node)
  // }

  const redirectTo = (node) => {
    props.redirectToIne(true, node);
  };

  return (
    <>
      {nodeData.length > 0 ?
        <Elk nodes={nodeData} links={linkData} layout="layered" viewBox="0 0 800 500" width="1200" height="500" flow="real-time" cardClick={redirectTo} />
        : <div style={{ height: "500px" }}>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <br />
          <SkeletonPlaceholder />
        </div>}
    </>
  );
};

export default TopologyGraph;
