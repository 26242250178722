import {
    Breadcrumb,
    BreadcrumbItem,
    Column,
    ComboBox,
    FlexGrid,
    Row
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import Context from "Context/Context";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import React, { useContext, useState } from "react";
import ATOIndex from "./ATO/Atoindex";
import Bank from "./Bank/vsIndex";
import Cpg from "./Cpg/CpgIndex";
import NewProductIndex from "./NewProductManagement/index-new";
import NorfolkIndex from "./Norfolk/NorfolkIndex";
import PNCIndex from "./Pnc/pncIndex";
import PmIndex from "./ProcessMining/PmIndex";
import Value from "./Value.json";
import PWorkFlowIndex from "./customerJourney/index";
import VSDashboard from "./valueStreamDashboard";

const VSIndex = () => {
    const { keycloak } = useKeycloak();
    const context = useContext(Context);
        const tenant = sessionStorage.getItem("tenant");
    const [valueStreamFlow, setValueStreamFlow] = useState(
        context.valueStreamFlow
    );

    //   const {
    //     isLoading: loading,
    //     data: res,
    //     isFetching,
    //     isError,
    //     refetch,
    //   } = useQuery(
    //     ["value_stream_flows_for_dropdown"],
    //     () => getAnyCollection(keycloak, uuidv4(), "valueStreamFlows", "soc"),
    //     {
    //       retry: 1,
    //       enabled: false,
    //     }
    //   );

    //   useEffect(() => {
    //     refetch();
    //   }, [refetch]);

    const getSelectedFlowDetails = async (e) => {
        if (e.selectedItem !== null) {
            context.valueStreamFlow = e.selectedItem.value_stream_flow_name;
            context.valueStreamTemplate = e.selectedItem.template;
            setValueStreamFlow(e.selectedItem.value_stream_flow_name);
        } else {
            if (tenant === "ibmconsulting") {
                context.valueStreamTemplate = 1;
                    context.valueStreamFlow = "Banking";
                setValueStreamFlow("Banking");
                    context.valueStreamTemplate = 1;
                    setValueStreamFlow("Banking");
                } else {
                    context.valueStreamFlow = "Telco with IBM Process Mining";
                    context.valueStreamTemplate = 6;
                    setValueStreamFlow("Telco with IBM Process Mining");
                }
        }
    };

    const getItems = (list) => {
        let updatedList = [];
        list.map((obj) => {
            if (obj.value_stream_flow_name === "Global Banking" && GetPermissions("global_banking::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "North America Financial Services" && GetPermissions("north_america_financial_services::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Energy & Utilities" && GetPermissions("energy_utilities::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Oil & Gas" && GetPermissions("energy_utilities::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "North America CPG" && GetPermissions("north_america_cpg::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Consumer Packaged Goods" && GetPermissions("consumer_packaged_goods::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Telco Industry" && GetPermissions("telco_industry::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Telco with IBM Process Mining" && GetPermissions("telco_with_ibm_process_mining::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "ATO" && GetPermissions("ato::read")) {
                updatedList.push(obj);
            }
            if (obj.value_stream_flow_name === "Norfolk" && GetPermissions("norfolk::read")) {
                updatedList.push(obj);
            }
        })
        console.log("updatedList: ", updatedList);
        return updatedList;
    }

    const getDropdownContents = () => {
        // if (loading || isFetching) {
        //   return (
        //     <div className="skeletonPlaceholder--it-health">
        //       <SkeletonText />
        //     </div>
        //   );
        // }

        // if (isError) {
        //   return <ErrorRefresh refresh={refetch} />;
        // }

        if (Value.data?.valueStreamFlows.length > 0) {
            return (
                <>
                    <ComboBox
                        ariaLabel="ComboBox"
                        id="ComboBox1"
                        items={getItems(Value.data.valueStreamFlows[0].flows).sort((a, b) => {
                            let fa = a.value_stream_flow_display_name.toLowerCase(),
                                fb = b.value_stream_flow_display_name.toLowerCase();
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        })}
                        itemToString={(item) =>
                            item ? item.value_stream_flow_display_name : ""
                        }
                        placeholder={valueStreamFlow}
                        titleText="Select a Flow"
                        onChange={(e) => getSelectedFlowDetails(e)}
                    />
                </>
            );
        }
    };


    return (
        <FlexGrid className="valueStream">
            <Row>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            IT Value Stream Observability
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
                <Column lg={4} md={4} sm={2}>
                    {getDropdownContents()}
                </Column>
            </Row>
            {context.valueStreamTemplate === 4 && (
                <Row>
                    <Column lg={16} md={8} sm={4}>
                        <VSDashboard valueStreamFlow={valueStreamFlow} />
                    </Column>
                </Row>
            )}
            {context.valueStreamTemplate === 1 && <Bank />}
            {context.valueStreamTemplate === 2 && <PNCIndex />}
            {context.valueStreamTemplate === 3 && <PWorkFlowIndex />}
            {context.valueStreamTemplate === 5 && <Cpg />}
            {context.valueStreamTemplate === 6 && <PmIndex />}
            {context.valueStreamTemplate === 7 && <NewProductIndex />}
            {context.valueStreamTemplate === 8 && <ATOIndex />}
            {context.valueStreamTemplate === 9 && <NorfolkIndex />}
        </FlexGrid>
    );
};

export default VSIndex;
