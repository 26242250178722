import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CarbonDataTable from "Carbon-Components/DataTable";
import TilesLoader from "../TilesLoader";
import ErrorFallback from "Common-Modules/ErrorFallback";
import {
  convertDate,
  convertEpochToDateTime,
  convertUTCDate,
} from "Common-Modules/ConvertUTCDate";

const BlockedHosts = ({ asset, cluster }) => {
  const [isCalculating, setIsCalculating] = useState(true);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const { data: incidentDetails } = useQuery(["citi_incidents"]);

  const headers = [
    {
      key: "TICKET_ID",
      header: "Ticket Id",
    },
    {
      key: "REPORT_DATE",
      header: "Reported Date",
    },
    {
      key: "STATUS",
      header: "Status",
    },
    {
      key: "REPORTED_PRIORITY",
      header: "Priority",
    },
    {
      key: "SHORT_DESCRIPTION",
      header: "Description",
    },
  ];

  useEffect(() => {
    try {
      const detailsCopy = JSON.parse(JSON.stringify(incidentDetails.data));
      const rowData = detailsCopy
        .filter(
          (session) =>
            session["CONFIGURATION_ITEM"] === asset &&
            session["SHORT_DESCRIPTION"].includes("block") &&
            session["SHORT_DESCRIPTION"].includes(cluster),
        )
        .map((session, index) => {
          session.id = `${session["TICKET_ID"]}-${index}`;
          const date = convertEpochToDateTime(
            Date.parse(session["REPORT_DATE"]),
          );
          const formattedDate = convertDate(
            "dd-mm-yy",
            convertUTCDate(date),
            "created",
          );
          session["REPORT_DATE"] = formattedDate;
          return session;
        });
      setData(rowData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, []);

  const getRowCellData = (id, data, row) => {
    return data;
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    <CarbonDataTable
      rowData={data}
      headerData={headers}
      title="Blocked Hosts"
      getRowCellData={getRowCellData}
      getTableHeader={(header) => header}
      actionsNeeded={false}
    />
  );
};

export default BlockedHosts;
