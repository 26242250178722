import { Breadcrumb, BreadcrumbItem, ClickableTile, Column, FlexGrid, Row, SkeletonPlaceholder, SkeletonText } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import TilesComponentNew from "./TilesComponentsNew";

const Wizard = () => {
    const { keycloak } = useKeycloak();
    const { t , i18n} = useTranslation();
    const [wizardData, setWizardData] = useState();

    const { data: WizardRes, isFetching, refetch: wizardRefetch } = useQuery(['insight_hub'], async () => await getAnyCollection(keycloak, "insight_hub"), { retry: 1 });



    useEffect(() => {
        if (WizardRes !== undefined) {
            console.log("WizardRes", WizardRes)
            const response = WizardRes.data.insight_hub.length > 0 ? WizardRes.data.insight_hub[0].dashboard.filter(
                (data) => data.title === "Insights"
            ) : []
            setWizardData(response)
        }
    }, [WizardRes])

    console.log("wizardData", wizardData, isFetching)

    return (
        <FlexGrid fullWidth>
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">{t("common.tour.home")}</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            {t("itGovernanceReporting.itGovernanceReport")}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row className="wizard-hm">
                {wizardData !== undefined && wizardData.length > 0 && wizardData[0].data.length > 0 ? <TilesComponentNew tileData={wizardData[0].data} /> : isFetching === true ? [1, 2, 3, 4, 5, 6, 7, 8].map((skel) => <Column
                    lg={4}
                    md={4}
                    sm={2}
                    className="clickable--tile insights-hb"
                    key={skel}
                >
                    <ClickableTile>
                        <SkeletonText />
                        <br />
                        <SkeletonText />
                        <SkeletonText />
                        <br />
                        <br />
                        <SkeletonPlaceholder />
                        <SkeletonPlaceholder />
                        <SkeletonPlaceholder />
                    </ClickableTile>

                </Column>) : <h5 style={{ width: "100%", textAlign: "center" }} >No Data Available</h5> }
            </Row>
        </FlexGrid>
    )
}

export default Wizard;