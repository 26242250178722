/** @format */

import {
  Accordion,
  AccordionItem,
  Column,
  Grid,
  TextArea,
  Tile
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { getPlatformAlertsFeedback } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateErrorMessage } from "../utilities/CommonReportUtility";

const GenAISummaryFeedback = (props) => {
  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  const [isFeedbackAvailable, setIsFeedbackAvailable] = useState(false);

  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [errMessageFeedback, setErrorMessageFeedback] = useState("");
  const [score, setScore] = useState(0);
  const [reason, setReason] = useState("");
  const [remediation, setRemediation] = useState("");

  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    if (props.type !== "apa") {
      getFeedbackAPI()
    }
  }, []);

  const getFeedbackAPI = async () => {
    const response = await getPlatformAlertsFeedback(
      keycloak.token,
      props.platformAlert.id
    );

    if (response.data === "") {
      setIsFeedbackAvailable(false)
    } else {
      setIsFeedbackAvailable(true)
    }

    AddUserActivity(keycloak, context, "FEATURE", "Platform Alerts Feedback", response);

    setIsFeedbackLoading(false);
    setErrorMessageFeedback(updateErrorMessage(response));

    if (response.data.thumbs_up_count + response.data.thumbs_down_count > 0) {
      let score = (response.data.thumbs_up_count / (response.data.thumbs_up_count + response.data.thumbs_down_count)) * 100;
      setUserCount(response.data.thumbs_up_count + response.data.thumbs_down_count);
      setScore(score.toFixed(2));

      setReason(response.data.rcaSummarization);
      setRemediation(response.data.remediationRecommendation);
    }

    return response.data;
  };

  return (
    <Accordion className="genai--accordian">
      <AccordionItem title={<h5 className="text-tile">Augmented Assistance - Feedback</h5>}>
        <Grid>
          {
            isFeedbackAvailable ?
              <>
                <Column lg={8}>
                  <h6>RCA Summarization</h6>
                  <p>
                    <TextArea
                      cols={150}
                      rows={12}
                      id="text-area-1"
                      defaultValue={reason}
                      readOnly={true}
                    />
                  </p>
                </Column>
                <Column lg={8}>
                  <h6>Remediation recommendation</h6>
                  <p>
                    <TextArea
                      cols={150}
                      rows={12}
                      id="text-area-1"
                      defaultValue={remediation}
                      readOnly={true}
                    />
                  </p>
                </Column>
                <Column lg={12}>
                  <Tile className="chart-container">
                    <h5 className="text-tile">User Feedback</h5>
                    <strong>Score: {score}</strong>
                    <p>No. of feedbacks: {userCount}</p>
                  </Tile>
                </Column>
              </>
              :
              <Column lg={12}>
                <Tile className="chart-container">
                  <p>No feedback available</p>
                </Tile>
              </Column>
          }

        </Grid>
      </AccordionItem>
    </Accordion >
  );
};

export default GenAISummaryFeedback;
