import { Column, SkeletonPlaceholder } from "@carbon/react";
import React from "react";

const TilesLoader = () => {
  return [1, 2, 3, 4].map((ske) => (
    <Column
      lg={8}
      md={4}
      sm={2}
      className="clickable-tile-portfolio-att margin-lft"
      key={ske}
    >
      <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
    </Column>
  ));
};

export default TilesLoader;
