import { AreaChart, SimpleBarChart } from "@carbon/charts-react";
import {
    DataTable,
    Layer,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectRow,
    TabsSkeleton,
    Toggle
} from "@carbon/react";
import { DotMark } from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ForceDirected from "./KenvueForceDirected";

const rowDt = [{ id: "1", "Job": "Total", Count: 0 }, { id: "2", "Job": "Failed", Count: 0 },
{ id: "3", "Job": "Yet to Start", Count: 0 }, { id: "4", "Job": "Success", Count: 0 }, { id: "5", "Job": "Running", Count: 0 }, { id: "6", "Job": "Independent", Count: 0 }, { id: "7", "Job": "Dependent", Count: 0 }]

const TopologyGraph = ({ workFlowDetails, details, directvData, date, location, resUniq }) => {
    //const history = useHistory();
    const [nodeData, setNodeData] = useState([]);
    const [linkData, setlinkData] = useState([]);
    const [toggle, setToggle] = useState(null);
    const [rows, rowData] = useState([]);
    const [timeRangeVal, setTimeRangeVal] = useState("");
    const [timeRange, setTimeRange] = useState([]);
    const [mergeData, setMergeData] = useState([]);
    const [adhoc, setAdhoc] = useState(null);
    const [duplicateDirectv] = useState(directvData)
    const [radioSelect, setRadioSelect] = useState([]);

    useEffect(() => {
        if (toggle) {
            const modifiedArrayOfObject = mergeData.map((node) => {
                return {
                    ...node,
                    CriticalJob: node.jobname === "DHL_AU_DLV_TO_BIL1" ? true : false
                }
            });
            const cric = modifiedArrayOfObject.filter((cric) => cric.CriticalJob === true)
            setMergeData(cric)
            getTopologyData(cric)
        } else {
           mergeCD(directvData, date)
        }
    }, [toggle, date])


    useEffect(() => {
        if (linkData.length > 0) {
            tableData()
        }
    }, [nodeData, linkData, details])

    useEffect(() => {
        if (rows.length > 0) {
            rowData([])
            setTimeout(() => {
                tableData()
            }, 300)
        }
    }, [date, adhoc])

    useEffect(() => {

    }, [])

    const tableData = () => {
        const res = rowDt.map((row) => {
            let dat = row;
            if (row.Job === "Total") {
                dat.Count = mergeData.length
            } else if (row.Job === "Failed") {
                dat.Count = getFailed()
            } else if (row.Job === "Yet to Start") {
                dat.Count = getStart()
            } else if (row.Job === "Success") {
                dat.Count = getSuccess()
            } else if (row.Job === "Running") {
                dat.Count = getRunning()
            } else if (row.Job === "Independent") {
                dat.Count = getInDependent()
            } else if (row.Job === "Dependent") {
                dat.Count = getDependent()
            }
            return dat
        })
        rowData(res)
    }

    const mergeCD = (directvData, date, range) => {
        const mergeCode = directvData;

        let day = []
        if (date === "Daily") {
            day = ["ddly", "dly", "dy"]
        } else if (date === "Hourly") {
            day = ["hhly", "hrly"]
        } else if (date === "All") {
            day = "All"
        } else if (date === "Monthly") {
            day = ["mmly"]
        }

        let res = mergeCode;
        if (day !== "All") {
            res = mergeCode.filter((res) => day.some(tv => res["jobname"].includes(tv)));
        }

        let subLast = 0;
        if (range === undefined) {
            let time = []
            res.forEach((observ) => {
                if (observ.observedtime !== undefined) {
                    time.push(observ.observedtime)
                }
            })
            if (time.length > 0) {
                const sort = [...time].sort((date1, date2) => new Date(date1) - new Date(date2))
                const [first, ...rest] = sort;
                const last = rest.pop();
                const subFirst = 0 + first.substr(11, 2);
                subLast = last.substr(11, 2);
                setTimeRange([parseFloat(subFirst), parseFloat(subLast)])
            }
        }
        const timeRange = range !== undefined ? parseFloat(range) : parseFloat(subLast)
        setTimeRangeVal(timeRange)
        setMergeData(res);
        setRadioSelect([])
        getTopologyData(res);
    }

    const getTopologyData = (mergeCode, option) => {
        let linkData = [], NodeData = [], dependJobs = [];
        mergeCode.forEach((json, index) => {
            let newObj = {};
            if(json["jobname"] === undefined) {
                newObj = {
                    ...json,
                    id: json["Job Name"], width: 48, height: 48, jobName: json["Job Name"],
                    color: getColor(json.job_status),
                    lastChild: false
                }
            } else {
                newObj = {
                    ...json,
                    id: json["jobname"], width: 48, height: 48, jobName: json["jobname"],
                    color: getColor(json.job_status),
                    lastChild: false
                }
            }
            
            if (!["Failed", "Yet to Start", "Running", "Success"].includes(option)) {
                const fin = mergeCode.find((fd) => fd["jobname"] === json["Child Job Name"])
                if (fin === undefined && json["Child Job Name"] !== undefined) {
                    dependJobs.push({ id: json["Child Job Name"], width: 48, height: 48, lastChild: true, jobName: json["Child Job Name"], color: "#c6c6c6" })
                } else if (fin !== undefined) {
                    dependJobs.push({ ...fin, id: fin["jobname"], jobname: fin["jobname"], width: 150, height: 50, color: getColor(fin["job_status"]), lastChild: true })
                }
                
                if(json["jobname"] === undefined) {
                    NodeData.push(newObj)
                    linkData.push({ id: (index + 1).toString(), source: json["Job Name"], target: json["Child Job Name"] !== undefined ? json["Child Job Name"] : json["Job Name"] })
                } else {
                    NodeData.push(newObj)
                    linkData.push({ id: (index + 1).toString(), source: json["jobname"], target: json["Child Job Name"] !== undefined ? json["Child Job Name"] : json["jobname"] })
                }
            } else {
                NodeData.push(newObj)
                linkData.push({ id: (index + 1).toString(), source: json["jobname"], target: json["jobname"] })
            }
        })

        const mergedArray = [...NodeData, ...dependJobs];



        const uniqueNode = mergedArray.filter((obj, index) => {
            return obj.id !== undefined && index === mergedArray.findIndex(o => obj.id === o.id);
        })

        setlinkData(linkData);
        setNodeData(uniqueNode);
    }

    const getColor = (status) => {
        if (status.toLowerCase() === "running") {
            return "#f1c21b"
        } else if (status.toLowerCase()  === "start") {
            return "#c6c6c6"
        } else if (status.toLowerCase()  === "done") {
            return "#24a148"
        } else if (status.toLowerCase() === "error") {
            return "#da1e28"
        } else {
            return "#c6c6c6"
        }
    }

    const ontoggle = (bool) => {
        setToggle(bool)
        rowData([])
        setTimeout(() => {
            tableData()
        }, 300)
    }

    const getCurrentDate = () => {
        const mn = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const dt = ["", "st", "nd", "td"]
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() === 12 ? 12 : date.getMonth() + 1;
        let year = date.getFullYear();

        return `(${day}${day < 4 ? dt[1] : "th"} ${mn[month]} ${year})`
    }

    // const getTime = () => {
    //     const date = new Date();
    //     let hr = date.getHours();
    //     let min = date.getMinutes();
    //     let sec = date.getSeconds();
    //     return `${hr}:${min}:${sec}`
    // }

    const getTimeMinus = () => {
        let original = new Date();
        let subtract5min = new Date();
        subtract5min.setTime(original.getTime() - 10 * 60 * 1000);
        let hr = subtract5min.getHours();
        let min = subtract5min.getMinutes();
        let sec = subtract5min.getSeconds();
        return `${hr}:${min}:${sec}`
    }

    // const onAuditReport = () => {
    //     history.push({ pathname: "/ATT", state: { data: "fromTopo" } })
    // }

    const getDependent = () => {
        const filt = linkData.filter((da) => da.source !== da.target)
        return filt.length
    }

    const getInDependent = () => {
        const filt = linkData.filter((da) => da.source === da.target)
        return filt.length
    }

    const getFailed = () => {
        const fail = mergeData.filter((st) => st.job_status.toLowerCase() === "error")
        return fail.length
    }

    const getStart = () => {
        const fail = mergeData.filter((st) => st.job_status.toLowerCase() === "run")
        return fail.length
    }

    const getRunning = () => {
        const fail = mergeData.filter((st) => st.job_status.toLowerCase() === "running")
        return fail.length
    }

    const getSuccess = () => {
        const done = mergeData.filter((st) => st.job_status.toLowerCase() === "done")
        return done.length
    }

    const onClickRadio = (e, row) => {
        let event = "";
        row.cells.forEach((cell) => {
            if (cell.id.includes("Job")) {
                event = cell.value
            }
        })
        let merge = mergeData;
        let response = [];
        if (event === "Independent") {
            response = merge.filter((da) => da["Child Job Name"] === undefined)
        } else if (event === "Dependent") {
            response = merge.filter((da) => da["Child Job Name"] !== undefined)
        } else if (event === "Failed") {
            response = merge.filter((da) => da.job_status.toLowerCase() === "error")
        } else if (event === "Yet to Start") {
            response = merge.filter((da) => da.job_status.toLowerCase() === "run")
        } else if (event === "Running") {
            response = merge.filter((da) => da.job_status.toLowerCase() === "running")
        } else if (event === "Success") {
            response = merge.filter((da) => da.job_status.toLowerCase() === "done")
        } else if (event === "Total") {
            response = merge
        }
        setRadioSelect(response)
        getTopologyData(response, event)
    }

    const onAdHoc = (bool) => {
        if (bool) {
            const updatedTime = directvData.map((tv) => {
                const parse = parseInt(tv.starttime)
                return {
                    ...tv,
                    starttime: new Date(parse).toLocaleString()
                }
            });

            const sort = updatedTime.sort((a, b) => {
                return new Date(a.starttime) - new Date(b.starttime);
            })

            let e = {};
            sort.forEach(v => e[v.jobname] = v.starttime);
            const ress = Object.keys(e).map(v => sort.find(c => c.starttime === e[v]));

            let mergeData = []
            location.state.starCric.forEach((dat) => {
                const fil = ress.filter((cric) => (cric.jobname === dat.jobname || cric.jobname === dat["Child Job Name"]))
                if (fil.length > 0) {
                    mergeData.push({ ...dat, child_job_status: "run", ...fil[0] })
                } else {
                    mergeData.push({ ...dat, job_status: "run" })
                }
            })
            const adHoc = mergeData.filter((ad) => ad["SCHEDULE TIME"] === undefined)
            setMergeData(adHoc);
            getTopologyData(adHoc);
            setTimeout(() => {
                setAdhoc(true)
            }, 300)
        } else {
           // mergeCD(directvData, date);
            setTimeout(() => {
                setAdhoc(false)
            }, 300)
        }
    }

    // const onChangeSlider = (e) => {
    //     if (e.value !== timeRange[1]) {
    //         const removeUn = duplicateDirectv.filter((emp) => emp.observedtime !== undefined);
    //         const unDef = duplicateDirectv.filter((emp) => emp.observedtime === undefined);
    //         const getDiff = removeUn.filter((fil) => timeRange[0].toString() >= fil["observedtime"].substr(11, 2) && fil["observedtime"].substr(11, 2) <= e.value.toString())
    //         let response = [];
    //         getDiff.forEach((diff) => {
    //             const dirtv = removeUn.filter((data) => diff["Child Job Name"] === data["jobname"] && (timeRange[0].toString() >= data["observedtime"].substr(11, 2) && data["observedtime"].substr(11, 2) <= e.value.toString()))
    //             if (dirtv.length > 0) {
    //                 response.push({ ...diff, ...dirtv })
    //             } else {
    //                 response.push({ ...diff })
    //             }
    //         })
    //         // let mergeRes = [...response, ...unDef]
    //      //   mergeCD(mergeRes, date, e.value)
    //     } else {
    //       //  mergeCD(directvData, date)
    //     }
    //     rowData([])
    //     setTimeout(() => {
    //         tableData()
    //     }, 300)
    // }

    const volumeChart = () => {
        let removeEmpty = []
        if (radioSelect.length > 0) {
            removeEmpty = radioSelect.filter((emp) => emp.cluster_details !== undefined && Object.entries(emp.cluster_details).length > 0)
        } else if (nodeData.length > 0) {
            removeEmpty = mergeData.filter((emp) => emp.cluster_details !== undefined && Object.entries(emp.cluster_details).length > 0)
        }
        const nodeType = Object.values(removeEmpty.reduce((obj, item) => {
            obj[item.cluster_details.node_type_id] = obj[item.cluster_details.node_type_id] || { node_type_id: item.cluster_details.node_type_id, count: 0 };
            obj[item.cluster_details.node_type_id].count++;
            return obj;
        }, {}));
        const data1 = nodeType.map((type) => {
            return {
                "group": type.node_type_id,
                "value": type.count
            }
        })

        const sorted = data1.sort((a, b) => b.value - a.value);

        const options = {
            title: "Cluster Node Type",
            axes: {
                bottom: {
                    mapsTo: 'group',
                    scaleType: "labels",
                    title: "Node Type"
                },
                left: {
                    mapsTo: 'value',
                    title: "Total Jobs"
                }
            },
            legend: {
                enabled: false
            },
            grid: {
                x: {
                    enabled: false,
                },
                y: {
                    enabled: false,
                },
            },
            height: "250px"
        };
        return sorted.length > 0 ? <SimpleBarChart data={sorted} options={options}></SimpleBarChart> : getChartLoader();
    };

    const getChartLoader = (message) => {
        return (
            <>
                <Layer className="chart-loader__layer">
                    {message !== undefined ? message : "Data is unavailable"}
                </Layer>
                <AreaChart
                    data={[]}
                    options={{
                        title: "Cluster Node Type",
                        axes: {
                            left: {},
                            bottom: {
                                scaleType: "labels",
                            },
                        },
                        toolbar: {
                            enabled: false,
                        },
                        height: "250px",
                    }}
                ></AreaChart>
            </>
        );
    };

    const headers = [{ key: "Job", header: "Jobs" }, { key: "Count", header: "Count" }]

    return (
        <>
            <div className="topology-graph--div">
                <h4>{details.dataProduct}: Health <span >{getCurrentDate()}</span></h4>
                {rows.length > 0 ? <DataTable rows={rows} headers={headers} radio>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getSelectionProps,
                        getTableProps,
                        getTableContainerProps
                    }) => <TableContainer className="details" {...getTableContainerProps()}>
                            <Table {...getTableProps()} aria-label="sample table">
                                <TableHead>
                                    <TableRow>
                                        <th scope="col" />
                                        {headers.map((header) => <TableHeader key={uuidv4()} {...getHeaderProps({
                                            header
                                        })}>
                                            {header.header}
                                        </TableHeader>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => <TableRow key={uuidv4()} {...getRowProps({
                                        row
                                    })}>
                                        <TableSelectRow onChange={(e) => onClickRadio(e, row)} {...getSelectionProps({
                                            row
                                        })} />
                                        {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </DataTable> : <TabsSkeleton rowCount={5} columnCount={2} />}
            </div>
            {
                nodeData.length > 0 && linkData.length > 0
                    ? <div className="topology-graph--div-time">
                        {/* <Slider labelText="Time range (CST)" minLabel=":00" maxLabel=":00" value={timeRangeVal} min={timeRange[0]} max={timeRange[1]} step={1} onChange={(e) => onChangeSlider(e)} noValidate invalidText="Invalid message goes here" /> */}
                        <ForceDirected nodeData={nodeData} linkData={linkData} workFlowDetails={workFlowDetails} location={location} resUniq={resUniq} />
                        <ul className="jobs-legend">
                            <li><DotMark size={17} fill="#f1c21b" /> Running</li>
                            <li><DotMark size={17} fill="#24a148" /> Success</li>
                            <li><DotMark size={17} fill="#da1e28" /> Failed</li>
                            <li><DotMark size={17} fill="#c6c6c6" /> Yet to Start</li>
                            <li><span /> Terminating Job</li>
                        </ul>
                    </div>
                    : <div className="topology-graph--div-time no-jobs">No jobs are available</div>
            }
            <div className="jobs-len">
                <div className="toggle">
                    <Toggle size="sm" onToggle={(e) => ontoggle(e)} disabled={adhoc} labelText="Critical Jobs" labelA="Critical Jobs" labelB="Critical Jobs" hideLabel={true} id="toggle-1" />
                    <Toggle size="sm" onToggle={(e) => onAdHoc(e)} disabled={toggle} labelText="Ad hoc Jobs" labelA="Ad hoc Jobs" labelB="Ad hoc Jobs" hideLabel={true} id="toggle-2" />
                </div>
                {volumeChart()}
                <p>Report generated on {getTimeMinus()}</p>
            </div>
        </>
    )
}

export default TopologyGraph;