import {
  Button,
  Column,
  DatePicker,
  DatePickerInput,
  ErrorBoundary,
  Grid,
  InlineLoading,
  Modal,
  Row,
  SkeletonPlaceholder,
  Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";

import React, { useContext, useEffect, useState } from "react";

import { GroupedBarChart } from "@carbon/charts-react";
import { useLocation } from "react-router-dom";

import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import { getPlatformAlertsDetails } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { formatDateYear, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";



const PlatformAlertsReport = (props) => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [errMessage, setErrorMessage] = useState("");
  const [apiData, setAPIData] = useState("");
  const [showPopUP, setShowPopUP] = useState(false);
  const [startDate, setStartDate] = useState("2022-09-13 00:00");
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 86400000).toISOString().split("T")[0] + " 23:50"
  );
  const [apiLoading, setApiLoading] = useState(false);

  const context = useContext(Context);

  useEffect(() => {
    getAPIData(sessionStorage.getItem("poStartDate"), sessionStorage.getItem("poEndDate"));
  }, []);

  // const updateAPI = async () => {
  //   setApiLoading(true);
  //   await getAPIData(sessionStorage.getItem("poStartDate"), sessionStorage.getItem("poEndDate"));
  // };

  const getAPIData = async (sDate, eDate) => {
    const response = await getPlatformAlertsDetails(
      keycloak.token,
      sDate,
      eDate,
      JSON.parse(sessionStorage.getItem("hostIds"))
    );

    AddUserActivity(keycloak, context, "FEATURE", "Platform Alerts", response);

    setApiLoading(false);
    setIsAPIDataLoading(false);
    setErrorMessage(updateErrorMessage(response));
    setAPIData(response.data);
  };

  const getTableHeader = (header) => {
    return header;
  };

  const getDetailsRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getDetailsRow(apiData.data[1].data.rowData, id);
    sessionStorage.setItem("platformAlert", JSON.stringify(row));
    window.location.href = window.location.href.includes("Portfolio") ? "#/icpsa/platformMetricsPortfolioApp" :
      location.pathname.includes("/operations") ?
        "#/operations-platformMetrics"
        : "#/icpsa/platformMetrics";
  };

  const getAlarmStatus = (row) => {
    let status = "";
    row.cells.forEach((cellObj) => {
      if (cellObj.id.includes("overall_state_modified")) {
      } else {
        if (cellObj.id.includes("overall_state")) {
          status = cellObj.value;
        }
      }
    })
    return status;
  }

  const isFedbackAvailable = (row) => {
    let status = false;
    const rowData = apiData.data[1].data.rowData
    for (const tableRow of rowData) {
      if (tableRow.id === row.id) {
        status = tableRow["feedback_given"] || false
      }
    }
    return status;
  }

  const getRowCellData = (id, data, row) => {
    //Petsite - ALARM
    //Bee-Travels - Alert
    //Roboshop - open

    if (id.includes("name")) {
      // if (getAlarmStatus(row) === "ALARM" || getAlarmStatus(row) === "Alert" || getAlarmStatus(row) === "open") {
      if (getAlarmStatus(row) === "open" || isFedbackAvailable(row)) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return (
          <a
            className="event-status"
            onClick={() => {
              onClickNode(row.id);
            }}
          >
            {data}
          </a>
        );
      } else {
        return data;
      }

    }
    if (id.includes("overall_state_modified") || id.includes("created")) {
      return String(new Date(data).toLocaleString());
    }

    return data;
  };

  // const onStartDateChange = (e) => {
  //   setStartDate(formatDateYear(new Date(e[0]).toDateString()));
  //   if (e.length > 1) {
  //     setEndDate(formatDateYear(new Date(e[1]).toDateString()));
  //   }
  // };

  // const getHeadingAndDateFilter = () => {
  //   return (
  //     <>
  //       <Column lg={10} >
  //         <div>
  //           <h3>Platform Alerts</h3>
  //         </div>
  //       </Column>
  //       <Column lg={4}>
  //         <ErrorBoundary fallback={"Data is unavailable"}>
  //           <DatePicker
  //             dateFormat="Y-m-d"
  //             datePickerType="range"
  //             onChange={(e) => onStartDateChange(e)}
  //             className="date-picker--margin"
  //           >
  //             <DatePickerInput
  //               id="date-picker-range-start"
  //               placeholder="yyyy-mm-dd"
  //               labelText="Start Date"
  //               defaultValue={startDate}
  //               type="text"
  //             />
  //             <DatePickerInput
  //               id="date-picker-range-end"
  //               placeholder="yyyy-mm-dd"
  //               labelText="End Date"
  //               defaultValue={endDate}
  //               type="text"
  //             />
  //           </DatePicker>
  //         </ErrorBoundary>
  //       </Column>
  //       <Column lg={2}>
  //         <span>
  //           {apiLoading ? (
  //             <InlineLoading
  //               status="active"
  //               iconDescription="Loading"
  //               description="Loading data..."
  //               className="report-update__button"
  //             />
  //           ) : (
  //             <Button
  //               kind="primary"
  //               onClick={updateAPI}
  //               size="md"
  //               iconDescription="Icon Description"
  //               className="report-update__button"
  //             >
  //               Update
  //             </Button>
  //           )}
  //         </span>
  //       </Column>
  //     </>
  //   );
  // };

  const createChartExplicit = () => {
    return (
      <>
        <Column lg={16} className="common-top-margin">
          {
            apiData.data[0].data.chartData.length > 0 ? (
              <ErrorBoundary fallback={<ErrorWhileFetching />}>
                <Tile className="chart-container">
                  <GroupedBarChart
                    data={apiData.data[0].data.chartData}
                    options={apiData.data[0].data.chartOptions}
                  ></GroupedBarChart>
                </Tile>
              </ErrorBoundary>
            ) : ""
          }
        </Column>
        <Column lg={16} className="common-top-margin">
          {apiData.data[1].data.rowData.length > 0 ? (
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              <CarbonDataTable
                rowData={apiData.data[1].data.rowData}
                headerData={apiData.data[1].data.headerData}
                title={props.title}
                getRowCellData={getRowCellData}
                getTableHeader={getTableHeader}
                description={`Host : ${sessionStorage.getItem("host-title")}`}
                table_particular_actions={false}
                actionsNeeded={false}
              />
            </ErrorBoundary>
          ) : (
            <Column lg={16} md={8} sm={4}>
              <DataUnAvailable
                description="Error due to data unavailablity for the selected Host"
                title="Alerts are unavailable"
              />
            </Column>
          )}
        </Column>
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {apiData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
      {showPopUP && (
        <Modal
          open
          passiveModal
          size="lg"
          onRequestClose={() => setShowPopUP(false)}
        >
          <Row>
            <Column lg={8} md={8} sm={4}>
              <Tile>Metrics:</Tile>
              <Tile>Logs:</Tile>
            </Column>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default PlatformAlertsReport;
