import React, { useState, useEffect } from "react";
import ELK from "elkjs/lib/elk.bundled";
import { path as d3Path } from "d3-path";
import TooltipCarbon from "Carbon-Components/Tooltip";
import { DataBase, Wikis, Send, Queued } from "@carbon/react/icons/index";

import { Edge, CardNodeTitle, CardNodeColumn, CardNode } from "@carbon/charts-react";
import { ArrowRightMarker, TeeMarker } from "@carbon/charts-react";
import { ShapeNode } from "@carbon/charts-react";
import {
    buildBezierPathString,
} from '@carbon/charts';


const Link = ({ link }) => {
    const sections = link.sections[0];
    const path = d3Path();

    path.moveTo(sections.startPoint.x, sections.startPoint.y);

    if (sections.bendPoints) {
        sections.bendPoints.forEach((b) => {
            path.lineTo(b.x, b.y);
        });
    }

    path.lineTo(sections.endPoint.x, sections.endPoint.y);

    return (
        <Edge
            path={path.toString()}
            markerStart="tee"
            markerEnd="marker"
            variant="dash-sm"
        // className="draw-edge"
        />
    );
};

const getIcons = (data) => {
    switch (data.types[0]) {
        case "jdbc":
            return <DataBase size={20} />
        case "HTTP":
            return <Wikis size={20} />
        case "MESSAGING":
            return <Send size={20} />
        default:
            return <Queued size={20} />
    }
}

const Node = ({ x, y, height, width, data, onClickCard }) => {
    return (
        <foreignObject
            transform={`translate(${x},${y})`}
            height={height}
            width={width}
            style={{ overflow: "visible", position: "relative" }}
        >
            <div style={{ height, width }}>
                {/* <ShapeNode renderIcon={<Send />} size={"100%"} /> */}
                {/* <div  onClick={() => onClickCard(data)}>
                    <ShapeNode title={data.label} renderIcon={getIcons(data)} size={60} />
                    {data.errorRate > 0 ? <div className="html-spinners"></div> : null}
                </div> */}
                {/* <h5 style={{
                    position: "absolute",
                    bottom: "-30px",
                    fontSize: "0.9rem",
                    width: "235px",
                    fontWeight: "600"
                }}>{data.title}</h5> */}
                            <CardNode onClick={() => onClickCard(data)} color={data.errorRate !== undefined && data.errorRate > 0 ? "#fa4d56" : "#24a148" }>
                {/* <CardNodeColumn>{getIcons(data)}</CardNodeColumn> */}
                <CardNodeColumn>
                    <CardNodeTitle>{data.label}</CardNodeTitle>
                </CardNodeColumn>
            </CardNode>
            </div>
        </foreignObject>
    );
};

const Elk = (props) => {
    const elk = new ELK();

    const [positions, setPositions] = useState(null);

    const graph = {
        id: "root",
        layoutOptions: {
            "elk.algorithm": props.layout,
            "elk.padding": "[left=20, top=20, right=20, bottom=20]",
            separateConnectedComponents: false,
            "spacing.nodeNode": 100,
            "spacing.nodeNodeBetweenLayers": 100,
            interactiveLayout: true,
            "elk.direction": "RIGHT",
            // "elk.interactive": "Layer & Order Preserving",
            // "elk.alignment": "CENTER",
            "elk.interactive": "Layer Preserving",
            "elk.contentAlignment": "V_CENTER",
            "crossingMinimization.semiInteractive": true,
            "nodePlacement.strategy": "NETWORK_SIMPLEX",
        },
        children: props.nodes,
        edges: props.links
    };

    useEffect(() => {
        elk
            .layout(graph)
            .then((g) => setPositions(g))
            .catch(console.error);
    }, []);

    if (!positions) return null;

    const buildNodes = () => {
        const { children } = positions;

        return children.map((node, i) => {
            return (
                <Node
                    key={`node_${i}`}
                    x={node.x}
                    y={node.y}
                    height={node.height}
                    width={node.width}
                    data={node}
                    onClickCard={props.cardClick}
                />
            );
        });
    };

    const buildLinks = () => {
        const { edges } = positions;

        return edges.map((edge, i) => {
            return <Link key={`link_${i}`} link={edge} />;
        });
    };

    const nodeElements = buildNodes();
    const linkElements = buildLinks();

    return (
        <div
            className={`topology-graph`}
            style={{
                position: `relative`,
                height: props.height,
                width: "1200px",
                margin: 10
            }}
        >
            <svg viewBox="500 0 550 800" width="1200px" height={props.height}>
                <defs>
                    <TeeMarker id="tee" position="start" />
                    <ArrowRightMarker id="marker" position="end" />
                </defs>
                {linkElements}
                {nodeElements}
            </svg>
        </div>
    );
};

export default Elk;