/** @format */

import {
    ErrorBoundary
} from "@carbon/react";
import ErrorFallback from "Common-Modules/ErrorFallback";
import { default as React, useEffect } from "react";

// import "./../css/_chart.scss";
const GitHubPullRequestDetails = (props) => {
    useEffect(() => {
    }, []);

    const getReadableDate = (date) => {
        return String(new Date(date).toDateString());
    }

    const getData = () => {
        return (
            <>
                <strong style={{ position: "absolute", top: "15px", right: "15px" }}>{props.details.pr_number}</strong>
                {/* <strong style={{ position: "absolute", top: "15px", right: "15px" }}>{props.details.committer_name}</strong> */}
                <div style={{ paddingTop: "1rem", fontSize: "12px" }}>
                    <span>Title: {props.details.title}</span><br />
                    <span>Base: {props.details.base_branch}</span><br />
                    <span>Head: {props.details.head_branch}</span><br />

                    <span>Created Date: {getReadableDate(props.details.created_date)}</span><br />
                    <span>Updated Date: {getReadableDate(props.details.updated_date)}</span><br />

                    <span>Repo Id: {props.details.repo_id}</span>
                </div>
            </>
        );
    };

    return (
        <div className="container-multichart__toolbar">
            <ErrorBoundary fallback={<ErrorFallback />}>
                {getData()}
            </ErrorBoundary>
        </div>
    );
};

export default GitHubPullRequestDetails;
