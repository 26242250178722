import { ClickableTile, Column, ComboBox, ErrorBoundary, Row, Search, SkeletonPlaceholder, Tag, Tile } from "@carbon/react";
import { ArrowDown, ArrowUp } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import ChartContainer from "Carbon-Charts/ChartContainer";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Speedometer } from "./Speedometer";

const TilesComponent = () => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [selectedBox, setSelectedBox] = useState("kpi");
    const [selectedFlow, setSelectedFlow] = useState("");
    const [dashboardData, setDashboardData] = useState([]);
    const [comboBox, setComboBox] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [dashboardDuplicateData, setDuplicateDashboardData] = useState([]);

    const { data: nerveCenterKPI, refetch: Refetch } = useQuery(['nerveCenterKPI'], async () => await getAnyCollection(keycloak, "nerveCenterKPI"), { retry: 1 });

    const { data: nerveCenterService, refetch: ServiceRefetch } = useQuery(['nerveCenterService'], async () => await getAnyCollection(keycloak, "nerveCenterSvc"), { retry: 1 });

    useEffect(() => {
        sessionStorage.setItem("nervecenter-selected-tab", "KPI");

        if (nerveCenterKPI !== undefined && nerveCenterKPI.data !== undefined) {
            setDashboardData(nerveCenterKPI.data.nerveCenterKPI)
            setDuplicateDashboardData(nerveCenterKPI.data.nerveCenterKPI)

            let newArr = [];
            nerveCenterKPI.data.nerveCenterKPI.map((kpi) => {
                newArr.push({
                    name: kpi.type
                })
            })

            const unique = newArr.filter((obj, index) => {
                return index === newArr.findIndex(o => obj.name === o.name);
            });

            setComboBox(unique)
        }
    }, [nerveCenterKPI])

    useEffect(() => {
        if (nerveCenterService !== undefined && nerveCenterService.data !== undefined) {
            const service = nerveCenterService.data.nerveCenterSvc;
            setServiceData(service)
        }
    }, [nerveCenterService])

    const getSelectedFlow = (e) => {
        if (e.selectedItem !== null) {
            const filterData = dashboardDuplicateData.filter((fil) => fil.type === e.selectedItem.name.toLowerCase());
            setDashboardData(filterData)
        } else {
            setDashboardData(dashboardDuplicateData);
        }
    }


    const getComboBox = () => {
        return <ComboBox
            id="ComboBox1"
            items={comboBox.sort((a, b) => {
                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })}
            itemToString={(item) =>
                item ? item.name : ""
            }
            placeholder="Select Category"
            titleText=""
            size="sm"
            disabled={selectedBox === "service"}
            onChange={(e) => getSelectedFlow(e)}
        />
    }

    const handleOnClick = (data) => {
        sessionStorage.setItem("nerveTileInfo", JSON.stringify(data));
        history.push({ pathname: `nerveTileInfo`, state: { tileData: data } })
    }

    const getCharts = (chart) => {
        if (chart.charts.length > 0) {
            return <ChartContainer data={chart.charts[0]} />
        }
    }

    const onChangeSearch = (e) => {
        if (e.target.value !== "") {
            const filterDat = dashboardData.filter((data) => {
                return data["type"].toLowerCase().includes(e.target.value.toLowerCase()) || data["displayName"].toLowerCase().includes(e.target.value.toLowerCase())
            })
            if (filterDat.length > 0) {
                setDashboardData(filterDat)
            }
        } else {
            setDashboardData(dashboardDuplicateData)
        }
    }

    const onClickRgt = (e) => {
        let tabName = e === "kpi" ? "KPI" : "Service";
        sessionStorage.setItem("nervecenter-selected-tab", tabName);
        setSelectedBox(e)
    }

    const getServiceCharts = () => {
        let serviceDataList = [];

        if (serviceData.length > 0) {
            serviceDataList.push(
                <Column lg={16} md={8} sm={4} >
                    <ChartContainer data={serviceData[3].chart[0]} />
                </Column>
            );
            serviceDataList.push(
                <Column lg={5} md={2} sm={2} className="service-chart" >
                    {/* <ChartContainer data={serviceData[0].chart[0]} /> */}
                    <Tile className={`chart-container`}>
                        <h6>{serviceData[0].chart[1].data.title}</h6>
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            <div style={{ float: "left", marginRight: "20px" }}>
                                <Speedometer services={serviceData[0].chart[1].data.segments} colors={serviceData[0].chart[1].data.colors} value={serviceData[0].chart[1].data.value} />
                            </div>
                        </ErrorBoundary>
                    </Tile>
                    <ul style={{ width: "auto" }}>{serviceData[0].services.map((serv) => <li onClick={() => handleOnClick({ kpi: serv.name })} style={{ backgroundColor: serv.color, color:serv.fontColor }}><h6>{serv.name}</h6><span>{serv.value}</span></li>)}</ul>
                </Column>
            );
            serviceDataList.push(
                <Column lg={5} md={2} sm={2} className="service-chart" >
                    {/* <ChartContainer data={serviceData[0].chart[0]} /> */}
                    <Tile className={`chart-container`}>
                        <h6>{serviceData[1].chart[0].data.chartOptions.title}</h6>
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            <div style={{ float: "left", marginRight: "20px" }}>
                                <Speedometer services={serviceData[1].chart[1].data.segments} colors={serviceData[1].chart[1].data.colors} value={serviceData[1].chart[1].data.value} />
                            </div>
                        </ErrorBoundary>
                    </Tile>
                    <ul style={{ width: "auto" }}>{serviceData[1].services.map((serv) => <li onClick={() => handleOnClick({ kpi: serv.name })} style={{ backgroundColor: serv.color, color:serv.fontColor }}><h6>{serv.name}</h6><span>{serv.value}</span></li>)}</ul>
                </Column>
            );
            serviceDataList.push(
                <Column lg={5} md={2} sm={2} className="service-chart" >
                    {/* <ChartContainer data={serviceData[0].chart[0]} /> */}
                    <Tile className={`chart-container`}>
                        <h6>{serviceData[2].chart[0].data.chartOptions.title}</h6>
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            <div style={{ float: "left", marginRight: "20px" }}>
                                <Speedometer services={serviceData[2].chart[1].data.segments} colors={serviceData[2].chart[1].data.colors} value={serviceData[2].chart[1].data.value} />
                            </div>
                        </ErrorBoundary>
                    </Tile>
                    <ul style={{ width: "auto" }}>{serviceData[2].services.map((serv) => <li onClick={() => handleOnClick({ kpi: serv.name })} style={{ backgroundColor: serv.color, color:serv.fontColor }}><h6>{serv.name}</h6><span>{serv.value}</span></li>)}</ul>
                </Column>
            );
        }

        // if (serviceData.length > 0) {
        //     serviceDataList = serviceData.map((data) =>
        //         <Column lg={4} md={4} sm={4} className="service-chart" >
        //             <ChartContainer data={data.chart[0]} />
        //             <ul>{data.services.map((serv) => <li onClick={() => handleOnClick({ kpi: serv.name })} style={{ backgroundColor: serv.color }}><h6>{serv.name}</h6><span>{serv.value}</span></li>)}</ul>
        //         </Column>
        //     )
        // }
        // serviceDataList.push(
        //     <Column lg={16} >
        //         <Tile className="chart-container">
        //             <ErrorBoundary fallback={"Data is unavailable"}>
        //                 <div>
        //                     <LineChart
        //                         data={lineChartJSON.chartData}
        //                         options={lineChartJSON.chartOptions}
        //                     ></LineChart>
        //                 </div>
        //             </ErrorBoundary>
        //         </Tile>
        //     </Column>);
        // serviceDataList.push(
        //     <Column lg={8} >
        //         <Tile className="chart-container">
        //             <ErrorBoundary fallback={"Data is unavailable"}>
        //                 <div>
        //                     <StackedAreaChart
        //                         data={nerveAreaChartJSON.chartData}
        //                         options={nerveAreaChartJSON.chartOptions}
        //                     ></ StackedAreaChart>
        //                 </div>
        //             </ErrorBoundary>
        //         </Tile>
        //     </Column>);
        // serviceDataList.push(
        //     <Column lg={8} >
        //         <Tile className="chart-container">
        //             <ErrorBoundary fallback={"Data is unavailable"}>
        //                 <div>
        //                     <StackedAreaChart
        //                         data={areaChartJSON.chartData}
        //                         options={areaChartJSON.chartOptions}
        //                     ></ StackedAreaChart>
        //                 </div>
        //             </ErrorBoundary>
        //         </Tile>
        //     </Column>);
        // serviceDataList.push(
        //     <Column lg={16} >
        //         <Tile className="chart-container">
        //             <ErrorBoundary fallback={"Data is unavailable"}>
        //                 <div>
        //                     <StackedBarChart
        //                         data={barChartJSON.chartData}
        //                         options={barChartJSON.chartOptions}
        //                     ></ StackedBarChart>
        //                 </div>
        //             </ErrorBoundary>
        //         </Tile>
        //     </Column>);


        return serviceDataList;
    }

    return (
        dashboardData.length > 0 ? <>
            <Row>
                <Column lg={2} md={2} sm={2} className="pd-lft ">
                    <ul className="rgt-corner">
                        <li className={selectedBox === "service" ? "active" : ""} onClick={() => onClickRgt("service")}>Services</li>
                        <li className={selectedBox === "kpi" ? "active" : ""} onClick={() => onClickRgt("kpi")} >KPIs</li>
                    </ul>
                </Column>
                <Column className="tile-combo pd-lft" lg={4} md={4} sm={2}>
                    {getComboBox()}
                </Column>
                <Column className="search pd-lft pd-rgt" lg={6} md={4} sm={2}>
                    <Search size="sm" placeholder="Find your items" disabled={selectedBox === "service"} labelText="Search" closeButtonLabelText="Clear search input" id="search-1" onChange={(e) => onChangeSearch(e)} onKeyDown={() => { }} />
                </Column>
                {selectedBox === "kpi" && <Column className="date-sec" lg={4} md={4} sm={2}>
                    <Tag className="some-class" type="high-contrast">
                        {'1H'}
                    </Tag>
                    <Tag className="some-class" type="outline">
                        {'8H'}
                    </Tag>
                    <Tag className="some-class" type="outline">
                        {'16H'}
                    </Tag>
                    <Tag className="some-class" type="outline">
                        {'24H'}
                    </Tag>
                </Column>}
            </Row>
            <Row>
                {selectedBox === "kpi" ? <Column lg={16} md={8} sm={4} className="nerve-tiles pd-lft pd-rgt">
                    {dashboardData.map((info, index) =>
                        <ClickableTile
                            id={index}
                            onClick={() => handleOnClick(info)}
                            aria-label={`clickableTile-${index}`}
                        >
                            <div>
                                <h5>{info.displayName}</h5>
                                <Tag className="some-class" type={info.type === "security" ? "warm-gray" : info.type === "platform" ? "magenta" : info.type === "application" ? "blue" : info.type === "database" ? "teal" : "high-contrast"}>
                                    {info.type}
                                </Tag>
                            </div>
                            <ul>
                                <li>{info.delta === "up" ? <ArrowUp stroke={info.arrowStatus === "red" ? "#fa4d56" : "#24a148"} strokeWidth="1.5px" /> : <ArrowDown stroke={info.arrowStatus === "red" ? "#fa4d56" : "#24a148"} strokeWidth="1.5px" />} {info.value}<span>{info.postFix}</span></li>
                                <li className="comments">{getCharts(info)} <span>{info.comments}</span></li>
                            </ul>
                        </ClickableTile>
                    )}
                </Column> : getServiceCharts()}
            </Row>
        </> : [1, 2, 3, 4].map((ske) =>
            <Column
                lg={4}
                md={4}
                sm={2}
                className="clickable-tile-portfolio-att margin-lft"
                key={ske}
            >
                <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
            </Column>
        )
    )
}

export default TilesComponent;