
import {
    buildBezierPathString,
} from '@carbon/charts';
import { ArrowRightMarker, Edge, ShapeNode } from "@carbon/charts-react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { getColorClass, getEdgeEnd, getEdgeStart } from "../../ICPSA/AppPerformanceAvailabililty/TopologyUtility";
import "../../ICPSA/AppPerformanceAvailabililty/appPerformance.scss";
import { BuildRun, SoftwareResourceCluster, DeployRules, SettingsAdjust, CallsIncoming, Api, Types, LocationCurrent, Wikis } from "@carbon/react/icons/index"
import { getClientNodeData } from "./ServiceMap";

const ShapeNodeSize = 64;

var clientNodes = [];
let highestX = -1;
let highestY = -1;

const TopologyGraphAligned = (props) => {
    const [nodeData, setNodeData] = useState();
    const [edgeData, setEdgeData] = useState();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        clientNodes = [];
        highestX = -1;
        highestY = -1;

        console.log("props.data ===========>>>>>>>>", props.data)
        let serviceMapFunctionInfo = getClientNodeData(props.data);

        updateClientNodeData(serviceMapFunctionInfo);
    }, []);

    const gotoTraceSummary = useCallback(() =>
    (history.push(
        window.location.href.includes("Portfolio") ? "/icpsa/traceSummaryApp" :
            location.pathname.includes("/operations") ?
                "/operations-traceSummary"
                :
                "/traceSummary"
    )), [history]);

    const getNodesWithDepth = (nodes, depth) => {
        let nodesWithDepth = [];
        nodes.map((nodeObj) => {
            if (nodeObj.depth === depth) {
                nodesWithDepth.push(nodeObj.nodeData);
            }
        })
        return nodesWithDepth;
    }

    const getClientUserNode = (clientNodeData, largestHeight) => {
        let edge = {}

        let node = {};

        node = { icon: "user" };
        node.id = (1000).toString();
        node.x = 0;
        node.y = ((largestHeight * 100) - (80)) / 2;
        node.name = "Client";
        node.title = "Client";
        node.Edges = [];

        clientNodeData.map((clientNodeObj, index) => {

            edge = {};
            edge.ReferenceId = clientNodeObj.ReferenceId;
            node.Edges.push(edge);
        })

        return node;
    }

    const updateNode = (x, y, serviceObj) => {
        let node = {};

        node = { icon: serviceObj.Type };
        node.depth = serviceObj.depth;

        node.id = serviceObj.ReferenceId.toString();
        node.x = x;
        node.y = y;
        node.name = serviceObj.Name;
        node.type = serviceObj.Type;
        node.title = getTruncatedName(serviceObj.Name);
        node.ARN = serviceObj.ARN ? serviceObj.ARN : "";
        node.Edges = serviceObj.Edges ? serviceObj.Edges : [];

        node.fault = serviceObj.hasIssue === "fault" ? true : false;
        node.error = serviceObj.error;
        node.hasAnomaly = serviceObj.hasIssue === "anomaly" ? true : false;

        node.prca = serviceObj?.PRCA?.["Probable root cause"];

        return node;
    }

    const updateHighestX = (x) => {
        highestX = x > highestX ? x : highestX;
    }

    const updateHighestY = (y) => {
        highestY = y > highestY ? y : highestY;
    }


    const updateClientNodeData = (clientNodeData) => {
        let nodes = [];

        nodes.push(getClientUserNode(getNodesWithDepth(clientNodeData.nodesRearranged, 0), clientNodeData.largestHeight));

        let node = {};
        let x = 0;
        let y = 0;
        let currentDepth = -1;
        clientNodeData.nodesRearranged.map((clientNodeObj, index) => {
            let height = clientNodeData.nodesDepthMap.get(clientNodeObj.depth).length;

            if (currentDepth === clientNodeObj.depth) {
                y += 120;
                updateHighestY(y);
            } else {
                y = ((clientNodeData.largestHeight * 100) - (height * 100)) / 2;
            }

            currentDepth = clientNodeObj.depth;
            x = 200 + (200 * clientNodeObj.depth);
            updateHighestX(x);
            node = updateNode(x, y, clientNodeObj.nodeData);
            clientNodes.push(node);
        })
        nodes = nodes.concat(clientNodes);

        setNodeData(nodes);
        getEdgeData(nodes);
    }

    const getTruncatedName = (title) => {
        let length = title.length;
        let newTitle = "";
        let front = "";
        let back = "";
        if (length > 13) {
            front = title.substring(0, 5);
            back = title.substring(length - 5, length);
            newTitle = front + "..." + back;
        } else {
            newTitle = title;
        }
        return newTitle;
    }

    // const getTimeFormat = (time) => {
    //     const newFormat = time.split(":")[0] + ":" + time.split(":")[1];
    //     return newFormat.replace('T', ' ');
    // }

    const nodeExist = (nodes, referenceId) => {
        let found = false;
        nodes.forEach((nodeObj) => {
            if (nodeObj.id === referenceId) {
                found = true;
            }
        })
        return found;
    }

    const getEdgeData = (nodes) => {
        let edges = [];
        let edge = {};
        let source = "";
        let target = "";

        nodes.map((nodeObj) => {
            if (nodeObj.Edges && nodeObj.Edges.length > 0) {
                nodeObj.Edges.map((edgeNodeObj) => {
                    edge = {};
                    source = nodeObj.id.toString();
                    target = edgeNodeObj.ReferenceId.toString();
                    edge.source = source;
                    edge.target = target;
                    if (nodeExist(nodes, source) && nodeExist(nodes, target)) {
                        edges.push(edge);
                    }
                })
            }
        });

        setEdgeData(edges);
    }

    const getTitleElement = (node, i) => {
        return (
            <span className={'node-title' + i} title={node.name}>
                {node.title}
            </span >
        );
    }

    const getSubTitleElement = (node, i) => {
        return (
            <span className={'font-italic node-subtitle' + i} title={node.type}>
                {node.type}
            </span >
        );
    }

    const onClickNode = (node) => {
        sessionStorage.setItem("currentNode", JSON.stringify(node));
        if (node.ARN && (node.error || node.fault || node.hasAnomaly)) {

            let issueType = node.hasAnomaly ? "Anomaly" : (node.error ? "Error" : "Fault");
            sessionStorage.setItem("issueType", issueType);

            sessionStorage.setItem("componentARN", node.ARN);
            sessionStorage.setItem("startTime", props.data.StartTime);
            sessionStorage.setItem("endTime", props.data.EndTime);

            gotoTraceSummary();
        }
    }

    const getNode = (node, i) => {
        let spinnerClassName = "";
        console.log("node.error", node.error)
        if ((node.error || node.fault || node.hasAnomaly)) {
            spinnerClassName = "html-spinner";
        } else if (node.error || node.fault || node.hasAnomaly) {
            spinnerClassName = "html-spinner_no-pointer";
        }

        console.log("node ======== >>>>>>>>", node)

        return <span className="node__nocursor">
            <ShapeNode
                xmlns="http://www.w3.org/1999/xhtml"
                title={getTitleElement(node, i)}
                subtitle={getSubTitleElement(node, i)}
                size={ShapeNodeSize}
                onClick={() => { onClickNode(node); }}
                aria-label={getTitleElement(node, i)}
                renderIcon={getIconSrc(node.icon)}
            />
            <div title={'PRCA: ' + node.prca} onClick={() => { onClickNode(node); }} className={`${spinnerClassName} ${getColorClass(node)} ${i}`}></div>
        </span >
    }

    const getIconSrc = (type) => {
        console.log("type", type)
        if (type) {
            if (type.includes("kubernetesEndpoints")) return <LocationCurrent size={20} />;
            if (type.includes("kubernetesService")) return <Types size={20} />;
            if (type.includes("kubernetesPod")) return <CallsIncoming size={20} />;
            if (type.includes("kubernetesReplicaSet")) return <Api size={20} />;
            if (type.includes("kubernetesStatefulSet")) return <SettingsAdjust size={20} />;
            if (type.includes("kubernetesDeployment")) return <DeployRules size={20} />;
            if (type.includes("kubernetesJob")) return <BuildRun size={20} />;
            if (type.includes("kubernetesJob")) return <BuildRun size={20} />;
            if (type.includes("client")) return <SoftwareResourceCluster size={20} />;
            if (type.includes("user")) return <Wikis size={20} />;
        } else {
            return <Wikis size={20} />;
        }
    }

    const nodeElements = () => {
        return nodeData.map((node, i) => {
            return (
                <foreignObject
                    style={{
                        "-webkit-transform": "translate(${node.x}, ${node.y})",
                        "-moz-transform": "translate(${node.x}, ${node.y})",
                        "-ms-transform": "translate(${node.x}, ${node.y})",
                        "-o-transform": "translate(${node.x}, ${node.y})"
                    }}
                    className="overflow-visible"
                    transform={`translate(${node.x}, ${node.y})`}
                    width="135"
                    height="110"
                >
                    {getNode(node, i)}
                </foreignObject>
            )
        });
    };

    const getEdge = (edge) => {

        console.log("edge: ", edge);
        if ((getEdgeStart(nodeData, edge.source).x - 32) == getEdgeEnd(nodeData, edge.target).x) {
            return <Edge
                path={buildBezierPathString(
                    getEdgeStart(nodeData, edge.source),
                    getEdgeEnd(nodeData, edge.target),
                    getEdgeStart(nodeData, edge.source).x,
                    getEdgeStart(nodeData, edge.source).y,
                    getEdgeEnd(nodeData, edge.target).x - (getEdgeEnd(nodeData, edge.target).y - getEdgeStart(nodeData, edge.source).y) / 2,
                    getEdgeEnd(nodeData, edge.target).y - (getEdgeEnd(nodeData, edge.target).y - getEdgeStart(nodeData, edge.source).y) / 2
                )}
            />
        } else {
            return <Edge
                source={getEdgeStart(nodeData, edge.source)}
                target={getEdgeEnd(nodeData, edge.target)}
                markerEnd={'marker'}
            />
        }
    }

    const edgeElements = () => {
        return edgeData.map((edge, i) => {
            return (
                getEdge(edge)
            );
        });
    };

    const DrawGraph = () => (
        <div className="do-graph">
            <svg viewBox={props.data.length > 50 ? `0 0 3800 300` : `0 0 1450 300`} width={props.data.length > 50 ? "2000" :"1450"} height="200" xmlns="http://www.w3.org/2000/svg" >
                <defs>
                    <ArrowRightMarker id={'marker'} />
                </defs>
                {edgeElements()}
                {nodeElements()}
            </svg>
        </div>
    );

    return (
        <div>
            {
                nodeData && edgeData ? <DrawGraph /> : ""
            }
        </div>
    );
};

export default TopologyGraphAligned;
