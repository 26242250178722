/** @format */

import {
  ClickableTile,
  Column,
  ErrorBoundary,
  Grid,
  Modal,
  ProgressBar,
  SkeletonPlaceholder,
  Tile
} from "@carbon/react";
import { Launch } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import GenAISummary from "Components/ICPSA/AppPerformanceAvailabililty/GenAiSummary";
import RunBookAutomationCommon from "Components/ICPSA/AppPerformanceAvailabililty/TraceComponent/RunbookAutomationCommon";
import { getProbableRCA } from "Components/ICPSA/Services/ServerApi";
import AddUserActivity from "Components/ICPSA/utilities/AddUserActivity";
import ErrorWhileFetching from "Components/ICPSA/utilities/ErrorWhileFetching";
import ErrorWhileFetchingShort from "Components/ICPSA/utilities/ErrorWhileFetchingShort";
import Context from "Context/Context";
import { default as React, useContext, useEffect, useState } from "react";

const TraceDetailss = (props) => {
  const { keycloak } = useKeycloak();
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const [errMessageRCA, setErrorMessageRCA] = useState("");
  const [errMessageGenAI, setErrorMessageGenAI] = useState("");
  const [isAnomalyData, setIsAnomalyData] = useState(true);
  const [anomaly, setAnomaly] = useState("");
  const [namespace, setNamespace] = useState("");
  const [probableRootCause, setProbableRootCause] = useState("");
  const [runBookAutomation, setRunBookAutomation] = useState("");
  const [genAISummary, setGenAISummary] = useState("");
  const [showGenAITile, setShowGenAITile] = useState(false);
  const [showPopUPRunbook, setShowPopUPRunbook] = useState(false);
  const genAISteps = ["RCA Summarization", "Remediation Recommendation", "Code Remediation", "Finalizing"]
  const size = 4;
  const [progress, setProgress] = useState(0);
  const context = useContext(Context)

  useEffect(() => {
    updateTraceObj();
  }, []);

  useEffect(() => {
    if (showGenAITile) {
      setProgress(0);
      if (genAISummary || errMessageGenAI) {
      } else {
        setTimeout(() => {
          const interval = setInterval(() => {
            setProgress(currentProgress => {
              if (currentProgress > size || genAISummary || errMessageGenAI) {
                clearInterval(interval);
              }
              return currentProgress + 0.05;
            });
          }, 50);
        }, 3000);
      }
    }
  }, []);

  const getUpdatedAnomaly = () => {
    let anomaly = props.data.anomaly;
    let anomalyReason = props.data.anomaly_reason ? props.data.anomaly_reason : "";
    return (anomaly + " " + anomalyReason);
  }

  const getProbableRCAData = async (anomaly, metrics) => {
    const response = await getProbableRCA(keycloak.token, anomaly, metrics);
    AddUserActivity(keycloak, context, "FEATURE", "Probable Root Cause", response);
    setIsAPIDataLoading(false);

    if (!response.data) {
      setErrorMessageRCA("Error while fetching data");
    }
    if (response.status >= 400 || response === "Error") {
      setErrorMessageRCA("Error while fetching data");
    } else {
      setProbableRootCause(getTextBoxData("Probable Root Cause", response.data.data["Probable root cause"]));
      setRunBookAutomation(getTextBoxData("RunBook Automation", ""));
    }
  }

  const getGenAISummary = async (anomaly, metrics, log) => {
    setShowGenAITile(true);
    // const response = await getGenAISummaryAPI(keycloak.token, getUpdatedAnomaly(), metrics, log, "apa");
    const response = props.data.getGenAISummaryAPI;
    AddUserActivity(keycloak, context, "FEATURE", "GenAI Summary", response);
    if (!response.data) {
      setErrorMessageGenAI("Error while fetching data");
    }
    if (response.status >= 400 || response === "Error") {
      setErrorMessageGenAI("Error while fetching data");
    } else {
      setGenAISummary(response.data.data);
      if (response?.data.data?.reason && response?.data?.data?.remediation && response?.data?.data?.code) {
        setShowGenAITile(true);
      } else {
        setShowGenAITile(false);
      }
    }
  }

  const updateTraceObj = () => {
    // if (props.data.traceObj) {
    //   setTraceSummary(props.data.traceObj);
    // }
    // else {
    //   setErrorMessage("Data is unavailable");
    // }
    if (props.data) {
      setAnomaly(getTextBoxData(sessionStorage.getItem("issueType"), props.data.anomaly));
      if (props.data.anomaly && props.data.MetricName) {
        setNamespace(props.data.Namespace);
        getProbableRCAData(getUpdatedAnomaly(), props.data.MetricName);
        getGenAISummary(props.data.anomaly, props.data.MetricName);
      } else {
        setIsAnomalyData(false);
      }
    } else {
      setIsAnomalyData(false);
    }
  }

  const getTextBoxData = (titleText, value) => {
    let data = { title: "", value: "" };
    data.title = titleText;
    data.value = value;
    return data;
  }

  const getTextTile = (tile) => {
    return (
      < Tile className="chart-container" >
        <h5 className="text-tile">{tile.title}</h5><p>{getUpdatedAnomaly()}</p>
      </Tile>
    );
  }

  const getRCATextTile = (tile) => {
    return (
      < Tile className="prca">
        <h5 className="text-tile">{tile.title}</h5>
        <p>{tile.value}</p>
      </Tile>
    );
  }

  const getRunBookAutomationTile = (tile) => {
    return (
      < ClickableTile className="chart-container" onClick={() => setShowPopUPRunbook(true)}      >
        <h5 className="text-tile">{tile.title}</h5><p><Launch /></p>
      </ClickableTile>
    );
  }

  const getLoading = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : <ErrorWhileFetching errMessage={errMessage} />
  }

  const getLoadingRCA = () => {
    return isAnomalyData ?
      <Column lg={16}>
        {
          errMessageRCA ?
            <ErrorWhileFetchingShort errMessage={errMessageRCA} />
            :
            <div className="skeletonPlaceholder--it-health-short">
              <SkeletonPlaceholder />
            </div>
        }
      </Column>
      : "";
  }

  const getProgressLoader = () => {
    const running = progress > 0;
    let helperText = running ? `Processing data for ${genAISteps[Math.floor(progress)]}...` : 'Fetching data...';

    if (progress >= size) {
      helperText = 'Done';
    }
    return <ProgressBar value={running ? progress : null} max={size} status={progress === size ? 'finished' : 'active'} label={<h6>Augmented Assistance</h6>} helperText={helperText} />;
  }

  const getLoadingGenAI = () => {
    return isAnomalyData ?
      <Column lg={16} className="common-top-margin">
        {
          errMessageGenAI ?
            <ErrorWhileFetchingShort errMessage={errMessageGenAI} />
            :
            <div className="skeletonPlaceholder--it-health-short">
              {getProgressLoader()}
            </div>
        }
      </Column>
      : "";
  }

  const getPopupDataRunBook = () => {
    return (
      <ErrorBoundary fallback={<ErrorWhileFetching />}>
        <RunBookAutomationCommon
          servicetype={namespace}
          title={getUpdatedAnomaly()}
          rootcause={getUpdatedAnomaly()}
        />
      </ErrorBoundary>
    );
  };

  return (
    <>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div>
            <h4>Troubleshoot View
            </h4>
          </div>
        </Column>
      </Grid>
      {showPopUPRunbook ? (
        <Modal
          open
          size="lg"
          passiveModal
          onRequestClose={() => setShowPopUPRunbook(false)}
          modalHeading="Prescribed Runbook Automations"
        >
          {getPopupDataRunBook()}
        </Modal>
      ) : (
        ""
      )}
      {
        isAPIDataLoading || errMessage ? getLoading() :
          <ErrorBoundary fallback={"Data is unavailable"}>
            {/* {traceSummary ? */}
            <Grid>
              {
                anomaly ?
                  <Column lg={16}>
                    {getTextTile(anomaly)}
                  </Column>
                  : ""
              }
              {
                probableRootCause ?
                  <>
                    <Column lg={11}>
                      {getRCATextTile(probableRootCause)}
                    </Column>
                    <Column lg={5}>
                      {getRunBookAutomationTile(runBookAutomation)}
                    </Column>
                  </>
                  :
                  getLoadingRCA()
              }
            </Grid>
            {/* : ""} */}
          </ErrorBoundary>
      }
      <Grid>
        {
          showGenAITile ?
            genAISummary ?
              <Column lg={16} md={8} sm={4}>
                <GenAISummary
                  data={genAISummary}
                  type="apa"
                />
              </Column>
              :
              getLoadingGenAI()
            : ""
        }
      </Grid>
    </>
  );
};

export default TraceDetailss;