// import "react-chat-elements/dist/main.css";
import React, { useEffect, useRef, useState } from "react";
// import { MessageBox, MessageList, Input } from "react-chat-elements";
import { Button, Row, TextInput, Tooltip } from "@carbon/react";
import { Download } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { getIncidentDetails } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";
import ChatHistory from "./ChatHistory";
import Dashboard from "./Dashboard";
import "./telco.scss";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const Chatbot = (props) => {
  //const messageListReferance = React.createRef();
  //const inputReferance = React.createRef();
  const setWrapperRef = useRef(null);
  const [chatBox, showChatBox] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValueCopy, setInputValueCopy] = useState("");
  const [inputAns, setInputAns] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [responseRec, setResponseRec] = useState(false);
  const [helpText, removeHelpTxt] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [responseData2, setResponseData2] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [showChatHis, setShowChatHis] = useState(true);
  const [showQues, setShowQues] = useState(false);
  const { keycloak } = useKeycloak();
  // useEffect(() => {
  //     if (inputAns) {
  //         getAnswer()
  //     }
  // }, [inputAns])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  useEffect(() => {
    if (responseRec) {
      getAnswer();
    }
  }, [responseRec]);

  useEffect(() => {
    if (callApi) {
      setResponseData({});
      setResponseData2({});
      sendQuestion();
    }
  }, [callApi]);

  const handleClickOutside = (event) => {
    if (
        setWrapperRef.current !== null &&
        !setWrapperRef.current.contains(event.target)
    ) {
        setShowQues(false);
    }
};

  const sendQuestion = async () => {
    setIsLoading(true);
    ResponseApi(false);
    const response = await getIncidentDetails(keycloak, uuidv4(), inputValue);
    try {
      ResponseApi(true);
      setResponseData(response.data.details);
      setResponseData2(response.data);
      setChatHistory([
        ...chatHistory,
        {
          question: inputValue,
          answer: response.data.details,
          hasSenderKey: response.data?.hasSenderKey,
        },
      ]);
      setErrorMsg("");
      setIsLoading(false);
    } catch (error) {
      ResponseApi(false);
      setErrorMsg("Apologies, we couldn't process your request at this moment");
      setResponseData({});
      setResponseData2({});
      setChatHistory([...chatHistory]);
      setIsLoading(false);
    }
  };

  const getAnswer = () => {
    // setTimeout(() => {
    //   setDataSource((prevState) => [
    //     ...prevState,
    //     {
    //       position: "left",
    //       type: "text",
    //       text: `See the Insights dashabord on the left.`,
    //       date: new Date(),
    //     },
    //   ]);
    // }, 500);
    setInputAns(false);
  };

  // const onclickChatIcon = () => {
  //   if (chatBox) {
  //     showChatBox(false);
  //   } else {
  //     showChatBox(true);
  //   }
  // };

  const handleKeyPress = (e, ques) => {
    setResponseData2({});
    setShowQues(false)
    // props.getApiResponse(false)
    setCallApi(false);
    if (e.key === "Enter") {
      setDataSource((prevState) => [
        ...prevState,
        {
          position: "right",
          type: "text",
          text: ques !== undefined ? ques : e.target.value,
          date: new Date(),
        },
      ]);
      setInputAns(true);
      setCallApi(true);
      removeHelpTxt(true);
      setInputValueCopy("");
      // props.getApiResponse(true)
    }
  };

  const onChangeInput = (e) => {
    setInputValue(e.target.value);
    setInputValueCopy(e.target.value);
  };

  // const onClickdataSource = (e) => {
  //   if (e.text === `See the Insights dashabord on the left.`) {
  //     setCallApi(false);
  //   } else {
  //     setResponseData2({});
  //     setInputValue(e.text);
  //     setInputValueCopy(e.text);
  //     setCallApi(true);
  //   }
  // };

  const ResponseApi = (bool) => {
    setResponseRec(bool);
    setCallApi(false);
  };

  // const getApiResponse = (bool) => {
  //   setCallApi(bool);
  // };

  const handleDownload = () => {
    if (!chatHistory) return;

    const json = JSON.stringify(chatHistory, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    a.click();

    URL.revokeObjectURL(url);
  };

  // const generatePDF = () => {
  //   const input = document.getElementById('pdf-content');

  //   html2canvas(input).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     const imgProps= pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('download.pdf');
  //   });
  // };

  const onClickInput = () => {
    setShowQues(true)
  }

  const onClickQuestion = (ques) => {
    setInputValue(ques);
    setInputValueCopy(ques);
    setShowQues(false)
    handleKeyPress({key: "Enter"}, ques)
  }

  const ques = ["How do I resolve PTP unlocked issue?", "What are the active alarms on node DU-2251100?", "Fetch the latest performance KPIs of node DU-2251100", "List all defects related to 'PTP unlocked'", "Find knowledge base article related to 'PTP unlock' issue troubleshooting"]

  return (
    <div className="chat-db">
      {chatBox ? (
        <div className="chat-wdw">
          <div className="chat-title-download">
            <h5 style={{ fontSize: "20px" }}>NOC AI Assistant</h5>
            <div>
              <Tooltip
                align="bottom"
                label="Download Data"
                style={{ paddingLeft: "4px" }}
              >
                <Button
                  kind="tertiary"
                  // disabled={!Object.keys(responseData).length > 0}
                  disabled={!chatHistory.length > 0}
                  onClick={handleDownload}
                  className="download-btn"
                >
                  <Download size={14} />
                </Button>
              </Tooltip>
            </div>
          </div>
          {chatHistory.length > 0 ? (
            <div className="chart-history" id="pdf-content">
              {chatHistory.map((entry, index) => (
                <Row style={{ width: "100%" }}>
                  <>
                    <strong>Q:</strong>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "column",
                        width: "100%",
                        paddingBottom: "10px",
                      }}
                    >
                      <h5 className="chat-ques">{entry.question}</h5>
                    </div>
                    <ChatHistory
                      responseData={entry.answer}
                      analyzedData={entry.analyzedData}
                      hasSenderKey={entry.hasSenderKey}
                      responseData2={responseData2}
                      setChatHistory={setChatHistory}
                      chatHistory={chatHistory}
                    />
                    <hr />
                  </>
                </Row>
              ))}
            </div>
          ) : null}
          <div
            className="chart-dashboard"
            style={{ height: chatHistory.length === 0 ? "590px" : "" }}
          >
            {helpText ? (
              <Dashboard
                errorMsg={errorMsg}
                isLoading={isLoading}
                responseData={responseData}
                responseData2={responseData2}
                setInputValue={setInputValue}
                showChatHis={showChatHis}
                inputValue={inputValue}
              />
            ) : (
              <h2 className="help-txt">How can I help you today?</h2>
            )}
            {/* {helpText ? (
              showChatHis ? (
                <div className={`chat-bx ${responseRec}`}>
                  <MessageList
                    referance={messageListReferance}
                    className="message-list"
                    lockable={true}
                    toBottomHeight={"100%"}
                    dataSource={dataSource}
                    onClick={(e) => onClickdataSource(e)}
                  />
                  <div
                    className="chat-icn"
                    onClick={() => setShowChatHis(false)}
                  >
                    <ChatBot size={24} />
                  </div>
                </div>
              ) : (
                <div className="chat-icn" onClick={() => setShowChatHis(true)}>
                  <ChatBot size={24} />
                </div>
              )
            ) : null} */}
          </div>
          {/* <Input
                    referance={inputReferance}
                    placeholder='Type your message here..'
                    multiline={true}
                    value={inputValue}
                    clear={clear => (inputClear = clear)}
                    rightButtons={<Button color='white' kind="secondary" backgroundColor='black' text='Send' />}
                /> */}
          <TextInput
            id="msg-input"
            type="text"
            onChange={(e) => onChangeInput(e)}
            value={inputValueCopy}
            labelText=""
            helperText=""
            onClick={() => onClickInput()}
            placeholder="Type your message here.."
            onKeyPress={(e) => handleKeyPress(e)}
          />
                    {showQues && <div ref={setWrapperRef} className="noc-ques">
            <ul>{ques.map((qu) => <li onClick={() => onClickQuestion(qu)}>{qu}</li>)}</ul>
          </div>}
        </div>
      ) : null}
      {/* <div className='chat-icn' onClick={() => onclickChatIcon()}>
                <ChatBot size={24} />
            </div> */}
    </div>
  );
};

export default Chatbot;
