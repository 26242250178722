/** @format */

const initialState = { isLandingPageHiddern: false };

const LandingPageReducer = (state = initialState, action) => {
  if (action && action.type === "LANDING_PAGE") {
    return {
      ...state,
      isLandingPageHiddern: action.payload,
    }
  } else {
    return state
  }
};
export default LandingPageReducer;
