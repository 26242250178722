function decreaseValuesByRandomAmount(array, totalDecrease) {
  let remainingDecrease = totalDecrease;
  let temp = JSON.parse(JSON.stringify(array));
  const maxIterations = 1000;

  for (
    let iteration = 0;
    iteration < maxIterations && remainingDecrease > 0.05 * totalDecrease;
    iteration++
  ) {
    let count = 0;
    const decreaseAmount = remainingDecrease * 0.05;

    for (let index = 0; index < array.length; index++) {
      if (array[index].value - decreaseAmount > 0.05 * temp[index].value) {
        array[index].value -= decreaseAmount;
        count++;
      }
    }

    if (count === 0) {
      break;
    }

    remainingDecrease -= count * decreaseAmount;
  }

  return array;
}

const subtractData = (data, value) => {
  return decreaseValuesByRandomAmount(data, value);
};

export default function getRecommendChartData(data, value, colors, height, displayUnit) {
  return {
    label: "",
    dataType: "donut",
    data: {
      chartData: subtractData(data, value),
      chartOptions: {
        title: "Potential Reduction in CO₂",
        resizable: true,
        donut: {
          center: {
            label: displayUnit,
            numberFormatter: (value) => (displayUnit === "mtCO₂eq" ? value.toFixed(3) : value.toFixed(2)),
          },
          alignment: "center",
        },
        pie: {
          labels: {
            enabled: false,
          },
        },
        color: {
          scale: {
            ...colors,
          },
        },
        height,
      },
    },
  };
}
