import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import KenDashboard from "./JobDashboard";
import WorkflowViewMainframe from "Components/ICTE/JobObservabilityMainFrame/JobObservability";
import "./JobObserv.scss";

const JobIndex = (props) => {
  const [selectedDropdown, setSelectedItem] = useState(sessionStorage.getItem("ObservabilityTitle") !== null ? sessionStorage.getItem("ObservabilityTitle") : "Job Observability");

  const selectedItem = (e) => {
    setSelectedItem(e)
    sessionStorage.setItem("ObservabilityTitle", e)
  }

  return (
    <div className="att-dash">
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            {selectedDropdown === "Job Observability" && <KenDashboard SelectedItem={selectedItem} title="Job Dashboard" />}
            {selectedDropdown === "Mainframe Management" && <WorkflowViewMainframe SelectedItem={selectedDropdown} title="Job Dashboard" />}
          </Column>
        </Row>
      </>
    </div>
  );
};

export default JobIndex;
