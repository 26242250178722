import { Column, DatePicker, DatePickerInput, Grid, NumberInput, Stack } from "@carbon/react";
// import DefaultModal from "Carbon-Components/Modal/Modal";
import React, { useEffect, useState } from "react";
// import { updateTenantDetails } from "services/serverapi";
import { useKeycloak } from "@react-keycloak/web";
// import InlineLoader from "Components/Common/loaderModule/InlineLoader";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import { createUTCDateFromInput } from "Common-Modules/ConvertUTCDate";
// import { isValidDate } from "Common-Modules/Utilities";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
// import { formatDateYear } from "Components/ICPSA/utilities/CommonReportUtility";
// import { updateTenantDetails } from "Services/ServerApi";
import DefaultModal from "Carbon-Components/Modals/CommonModal";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import { isValidDate } from "Common-Modules/Utilities";
import { formatDateYear } from "Components/ICPSA/utilities/CommonReportUtility";
import { updateTenantDetails } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";
// import { formatDateYear } from "Components/PlatformServices/Charts/Reports/CommonReportUtility";
// import { isValidDate } from "Common-Modules/utilities/utilities";

const EditTenant = ({ cancel, tenant }) => {
  const [newDate, setNewDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [threshold, setThreshold] = useState(tenant.thresholdValue || 183)
  const [resMsg, setResMsg] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { keycloak } = useKeycloak();

  const isExistingDateValid = isValidDate(tenant.endDate)

  useEffect(() => {
    if (isExistingDateValid)
      handleDateChange(new Date(tenant.endDate))
  }, [isExistingDateValid, tenant.endDate])

  const getMinDate = () => {
    const date = new Date();
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const res = await updateTenantDetails(
      keycloak,
      tenant.id,
      { endDate: newDate, startDate: tenant.startDate, thresholdValue: threshold },
      uuidv4(),
    );
    try {
      if (res?.data?.updated_by) setResMsg("Tenant updated Successfully.");
      else throw new Error("");
      setRefresh(true);
    } catch (error) {
      setRefresh(false);
      setResMsg("Failed to Update. Please, try again after sometime.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (inp) => {
    const date = createUTCDateFromInput(inp, "end");
    setNewDate(date);
  };

  if (resMsg) {
    return (
      <PassiveModal canCloseDetailsPopup={() => cancel(refresh)}>
        <h4>{resMsg}</h4>
      </PassiveModal>
    );
  }

  const invalid = (threshold < 0 || threshold > 365 || !Number.isInteger(threshold)) ? true : false

  const defaultDate = isExistingDateValid ? formatDateYear(new Date(tenant.endDate).toDateString()).substring(0, 10) : null

  return (
    <DefaultModal
      heading="Edit"
      primaryText="Submit"
      primaryButtonDisabled={invalid || !Boolean(newDate) || isLoading}
      onModalSubmit={() => onSubmit()}
      onCloseModal={() => cancel(false)}
    >
      <Grid>
        <Column lg={8} md={6} sm={4}>
          {isLoading ? (
            <InlineLoader status="active" description="updating tenant..." />
          ) : (
            <Stack gap={5}>
              <p>
                <strong>Tenant Name: </strong> {tenant.name}
              </p>

              <DatePicker
                allowInput={false}
                htmlFor="end-date"
                dateFormat="Y-m-d"
                id="End-Date"
                minDate={getMinDate()}
                onChange={(event) => handleDateChange(event[0])}
                datePickerType="single"
              >
                <DatePickerInput
                  labelText="End Date"
                  defaultValue={defaultDate}
                  htmlFor="end-date"
                  id="End-Date"
                  placeholder="yyyy-mm-dd"
                  disabled={false}
                  invalidText=""
                />
              </DatePicker>

              <NumberInput
                id="threshold"
                allowEmpty={false}
                label="Data Retention Period (days)"
                value={threshold}
                min={0}
                max={365}
                invalid={invalid}
                invalidText="value should be positive integer between 0 and 365"
                onChange={(e) => setThreshold(Number(e.target.value))}
                hideSteppers={true}
              />
            </Stack>
          )}
        </Column>
      </Grid>
    </DefaultModal>
  );
};

export default EditTenant;
