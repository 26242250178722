import {
    Button,
    FluidForm,
    TextArea,
    TextInput
} from "@carbon/react";
import { Rotate, SendAltFilled } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import ErrorNotify from "Carbon-Components/ErrorNotify";
import { postSendMail } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const MailContent = ({ asset, cluster, rowData, title }) => {
    const { keycloak } = useKeycloak()
    const [mailTo, setMailTo] = useState(["gkumar17@in.ibm.com", "aparna.venkatraman@in.ibm.com"]);
    const [mailFrom, setMailFrom] = useState("citi.psops@ibm.com");
    const [inputCc, setInputCc] = useState(["sowrabh.b.s@ibm.com", "primadhu@in.ibm.com"]);
    const [inputSubject, setInputSubject] = useState("");
    const [emailContent, setEmailContent] = useState("");
    const [hostDa, setCluster] = useState([]);
    const [application, setApplication] = useState([]);
    const [mailSent, setMailSent] = useState(false);
    const [reasonData, setReason] = useState([]);
    const [mailMessage, setMailMessage] = useState("");
    const [notify, showNotification] = useState(false);
    const [enterInputTo, setEnterInputTo] = useState("");
    const [receiverId, storeReciverId] = useState([]);

    const {
        data: MailResponse,
        refetch,
    } = useQuery(
        ["mailsent"],
        () =>
            postSendMail(keycloak, uuidv4(), mailMessage),
        {
            retry: 1,
            cacheTime: 0,
            enabled: false,
        }
    );

    useEffect(() => {
        let host = [], app = [], asset = "", reason = [];

        rowData.forEach((data) => {
            const hostData = `${data["cluster"][0]}-${data["cluster"][1]}-${data["cluster"][2]}`
            asset = data.asset_class;
            host.push(hostData);
            reason.push(data.reason)
            app.push(data.application)
        })
        const uniqueHost = [...new Set(host)];
        const uniqueApp = [...new Set(app)];
        const uniqueReason = [...new Set(reason)];
        const setSubject = `${asset} ${uniqueHost} ${new Date()} ${title}`;
        setCluster(uniqueHost)
        setApplication(uniqueApp)
        setReason(uniqueReason)
        setInputSubject(setSubject)
    }, [])

    useEffect(() => {
        if (hostDa.length > 0) {
            getMailContent()
        }
    }, [hostDa])

    useEffect(() => {
        if (mailMessage !== "") {
            refetch()
        }
    }, [mailMessage])

    useEffect(() => {
        if (MailResponse !== undefined) {
            showNotification(true)
            setMailMessage("")
            setTimeout(() => {
                showNotification(false)
            }, 3000)
        }
    }, [MailResponse])

    useEffect(() => {
        if (enterInputTo !== "") {
            let newAr = [];
            if (enterInputTo.indexOf(',') !== -1) {
                newAr = enterInputTo.split(',');
            } else {
                newAr.push(enterInputTo)
            }
            setMailTo(enterInputTo)
            storeReciverId(newAr)
        } else if(mailTo.length === 1) {
            setMailTo([])
        }
    }, [enterInputTo])

    const getMailContent = () => {
        // <ul>
        // ${rowData.map(row => <li>{row.session_id} - {row.host}</li>)}
        // </ul>
        const flow = title === "Failed Tasks" ? "task_id" : "session_id";
        const bodyData = rowData.reduce((a, c) => a + `${c[flow] + " , " + c.host + " , " + c.application}\n`, "")
        const flowTitle = title === "Failed Tasks" ? "Task" : "Session"
        const space = `Host                                        `
        const text = `Hi ${asset} Citi team,\nWe have observed ${flowTitle} error at ${new Date()} in ${hostDa}\n\n`
        const heading = `Please Find the below details:\n`;
        const subHeading = `Zone: ${hostDa}\nApplication name: ${application}\nReason: ${reasonData}\n\n`
        const titles = `${flowTitle} Id   ${space}   Application\n`;
        const footer = "\nPlease let us know if there is any impact to the Citi workloads because of this issue.\n\nRegards,\nIBM Citi Platform Service operations\nWebex : citi.psops@ibm.com"
        const endText = text + heading + subHeading + titles + bodyData + footer;
        setEmailContent(endText)
    }

    // const getDateTime = () => {
    //     const date = new Date();
    //     return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    // }


    // const postMailContent = async () => {

    //     // const data = {
    //     //     to: "gkumar17@in.ibm.com",
    //     //     cc: "aparna.venkatraman@in.ibm.com",
    //     //     subject: inputSubject,
    //     //     content: emailContent
    //     // }
    //     // const Response = await sendMail(keycloak, uuidv4(), data);
    //     // setMailSent(false)
    // }

    const onclickSend = () => {
        const addBr = emailContent.replace(/\r?\n/g, '<br />')
        const msg = {
            to: receiverId.length > 0 ? receiverId : mailTo,
            cc: inputCc,
            from: mailFrom, // Use the email address or domain you verified above
            subject: inputSubject,
            text: inputSubject,
            html: addBr
        };
        setMailMessage(msg)
    }

    const onInputTo = (e) => {
        setEnterInputTo(e.target.value);
    }

    const onInputCc = (e) => {
        setInputCc(e.target.value)
    }

    const onInputSubject = (e) => {
        setInputSubject(e.target.value)
    }
    const onTextArea = (e) => {
        setEmailContent(e.target.value)
    }


    return (
        <>
            {notify ? <div className="notify-mail"> <ErrorNotify title="Mail Sent Successfully" subTitle="" kind="success" hideCloseButton={true} /> </div> : null}
            <div style={{ clear: "both", display: "inline-block", width: "100%" }}>
                <Button
                    kind="tertiary"
                    className="add-button"
                    onClick={() => onclickSend()}
                    size="sm"
                    style={{ float: "right" }}
                    disabled={mailTo.length === 0 || notify}
                >
                    {mailSent ? <Rotate className="rotateIcons" size={16} /> : <SendAltFilled size={16} />} Send
                </Button>
            </div>
            <div>
                <div className="mail-content">
                    <TextInput className="mb-1" onChange={(e) => onInputTo(e)} value={mailTo} id="text-input-1" placeholder="Enter Receiver mail" type="text" labelText="To" helperText="" />
                    <TextInput onChange={(e) => onInputCc(e)} value={inputCc} id="text-input-2" type="text" placeholder="Enter CC" labelText="Cc" helperText="" />
                    <FluidForm>
                        <TextInput onChange={(e) => onInputSubject(e)} value={inputSubject} type="text" labelText="Add a Subject" id="text-input-3" />
                    </FluidForm>
                    <div className="txt-area">
                        <TextArea onChange={(e) => onTextArea(e)} labelText="" helperText="" value={emailContent} rows={12} id="text-area-1" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailContent;
