/** @format */

import React, { Component } from "react";

// carbon
import {
  DatePicker,
  DatePickerInput,
  Dropdown,
  FilterableMultiSelect,
  Form,
  FormLabel,
  Layer,
  SelectableTile,
  Stack,
  TextInput
} from "@carbon/react";
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ formData: [], errors: {} });
    this.getformData();
  }

  componentWillUnmount() {
    this.setState({ formData: [], errors: {} });
  }

  getformData() {
    this.setState({ formData: this.props.formData });
  }

  handleInputChange(event, chkIndex) {
    let updatedFormData = [...this.state.formData];
    updatedFormData = updatedFormData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.id === event.target.id) {
        formFieldTemp.inputValue = event.target.value;
        formFieldTemp.visited = Boolean(event.target.value);
        const re = new RegExp(formField.inputPattern.slice(1, -1));
        formFieldTemp.isValid =
          event.target.value === "" ? false : re.test(event.target.value);
        if (formFieldTemp.minLength && formFieldTemp.isValid)
          if (Number(formFieldTemp.minLength) > event.target.value.length)
            formFieldTemp.isValid = false;
      }
      return formFieldTemp;
    });
    this.setState({
      formData: updatedFormData,
    });
    this.isFormValid(event.target.id, event.target.value, "text");
  }

  getEmptyFieldValidation(formfield) {
    let fieldErrors = {};
    fieldErrors = this.state.errors;
    if (formfield.inputType === "date") {
      fieldErrors[formfield.label] = "Incorrect date format";
    } else {
      fieldErrors[formfield.label] = "Cannot be empty";
    }
    this.setState({ errors: fieldErrors });
    return false;
  }

  getNonEmptyFieldValidation(formfield) {
    // Field validation for non Empty
    if (typeof formfield.inputValue !== "undefined") {
      // Select fields validation
      if (
        formfield.inputType === "multiselect" ||
        formfield.inputType === "dropdown" ||
        formfield.inputType === "dropdown-carbon"
      ) {
        if (
          formfield.inputValue === undefined ||
          formfield.inputValue === "" ||
          formfield.inputValue.length === 0
        ) {
          return false;
        }
        return true;
      }
      // RegEx validation

      const newReg = new RegExp(formfield.regex);
      if (
        !Array.isArray(formfield.inputValue) &&
        !formfield.inputValue.match(newReg)
      ) {
        return this.getRegExValidation(formfield);
      }
    }
    return true;
  }

  getRegExValidation(formfield) {
    const fieldErrors = this.state.errors;
    if (formfield.regexMessage) {
      fieldErrors[formfield.label] = formfield.regexMessage;
    } else {
      fieldErrors[formfield.label] = "Invalid characters";
    }
    this.setState({ errors: fieldErrors });
    return false;
  }

  isFormValid(label, value, id) {
    let formIsValid = true;
    this.state.formData.forEach((formfield) => {
      if (formfield.isValid === false || formfield.isValid === true) {
        if (formfield.isRequired || formfield.inputValue) {
          formIsValid = formIsValid && formfield.isValid;
        }
      }
    });
    if (this.props.getFormValidity) {
      this.props.getFormValidity(formIsValid);
    }

    if (this.props.handleFormChange) {
      this.props.handleFormChange(label, value, id);
    }
  }

  getTextInput(data) {
    return (
      <div>
        <TextInput
          id={data.id}
          labelText={`${data.label} ${!Boolean(data.isRequired) ? "(optional)" : ""
            }`}
          placeholder={data.placeholder}
          required={data.isRequired === true}
          disabled={data.disabled}
          invalid={data.visited && !data.isValid}
          invalidText={data.invalidText}
          defaultValue={data.inputValue}
          onChange={(event) => {
            this.handleInputChange(event, data);
          }}
          maxLength={30}
          readOnly={data.readOnly}
        />
        {this.props.dupilicate.id === data.id ? (
          this.props.dupilicate.exists ? (
            <div className="error-message">{this.props.duplicateMessage}</div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }

  onDateChange(id, event) {
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.id === id) {
        formFieldTemp.inputValue = event[0];
        formFieldTemp.isValid = true;
        formFieldTemp.visited = true;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    this.isFormValid(id, event[0], "date");
    return 1;
  }

  getMinDate() {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  getInputFieldCalender(data) {
    return (
      <div>
        <DatePicker
          allowInput={false}
          htmlFor={data.label}
          id={data.label}
          minDate={this.getMinDate()}
          onChange={(event) => this.onDateChange(data.id, event)}
          datePickerType="single"
          maxDate={data.maxDate}
          readOnly={data.readOnly}
        >
          <DatePickerInput
            labelText={`${data.label} ${!Boolean(data.isRequired) ? "(optional)" : ""
              }`}
            htmlFor={data.label}
            id={data.label}
            pattern="\\d{1,2}/\\d{1,2}/\\d{4}"
            placeholder="mm/dd/yyyy"
            disabled={data.disabled}
            invalid={
              this.state.errors[data.label] !== undefined && data.visited
            }
            invalidText=""
            defaultValue={data.inputValue}
          />
        </DatePicker>
      </div>
    );
  }

  onDropdownChange(event, data) {
    const id = data.id;
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.id === id) {
        formFieldTemp.isValid = true;
        formFieldTemp.visited = true;
        formFieldTemp.inputValue = event.selectedItem.text;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    this.isFormValid(id, event.selectedItem.text, data.inputType);
  }

  getInputFieldDropdownCarbon(data) {
    return (
      <Dropdown
        id={data.id}
        size="md"
        label="Select"
        ariaLabel="Select"
        itemToString={(item) => (item ? item.id : "")}
        items={this.getDropdownList(data.options)}
        onChange={(event) => {
          this.onDropdownChange(event, data);
        }}
        titleText={`${data.label} ${!Boolean(data.isRequired) ? "(optional)" : ""
          }`}
        direction={data.direction}
      />
    );
  }

  getDropdownList(data) {
    return data.map((dataObject) => ({
      id: dataObject.name,
      text: dataObject.value,
    }));
  }

  onMultiSelectChange(data, selections) {
    const id = data.id;
    const emails = selections.map((user) => user.text);
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.id === id) {
        formFieldTemp.isValid = Boolean(emails.length);
        formFieldTemp.visited = true;
        formFieldTemp.inputValue = emails;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    this.isFormValid(id, emails, data.inputType);
  }

  getInputMultiSelect(data) {
    return (
      <FilterableMultiSelect
        id={data.id}
        placeholder={data.placeHolder}
        direction={data.direction || "top"}
        items={this.getDropdownList(data.options)}
        titleText={data.label}
        itemToString={(item) => (item ? item.text : "")}
        selectionFeedback="top-after-reopen"
        initialSelectedItems={data.initialSelectedItems}
        onChange={(e) => this.onMultiSelectChange(data, e.selectedItems)}
      />
    );
  }

  onMultiSelectTilesChange(data, selected) {
    const id = data.id;
    // const emails = selected.map((selection) => selection.text);
    let updatedFormData = [];
    updatedFormData = this.state.formData.map((formField) => {
      const formFieldTemp = formField;
      if (formFieldTemp.id === id) {
        formFieldTemp.isValid = true;//Boolean(emails.length);
        formFieldTemp.visited = true;
        formFieldTemp.inputValue = data;//emails;
      }
      return formFieldTemp;
    });
    this.setState({ formData: updatedFormData });
    // this.isFormValid(id, emails, data.inputType);
    this.isFormValid(id, data, data.inputType);
  }

  getInputMultiSelectTiles(data) {
    return (
      <div role="group" aria-label="selectable tiles">
        <FormLabel>{data.label}</FormLabel>
        <Layer style={{ "marginLeft": "1rem" }}>
          {
            data.options.map((option) => {
              return <SelectableTile
                onChange={(e) => this.onMultiSelectTilesChange(data, e)}
                id={option.id} style={{ "background": "#f4f4f4" }}>
                {option.name}
              </SelectableTile>
            })
          }
        </Layer>
      </div >
    );
  }

  render() {
    const formFields = this.state.formData.map((data, index) => (
      <div key={data.id}>
        {data.inputType === "text" ? this.getTextInput(data) : ""}
        {data.inputType === "date" ? this.getInputFieldCalender(data) : ""}
        {data.inputType === "dropdown-carbon"
          ? this.getInputFieldDropdownCarbon(data)
          : ""}
        {data.inputType === "multiselect" ? this.getInputMultiSelect(data) : ""}
        {data.inputType === "multiselecttile" ? this.getInputMultiSelectTiles(data) : ""}
      </div>
    ));
    return (
      <Form
        onSubmit={(event) => this.submitForm(event)}
        className="dynamic-form-max-width"
      >
        <Stack gap={5}>
          <>{formFields}</>
        </Stack>
      </Form>
    );
  }
}

export default DynamicForm;
