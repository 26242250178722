/** @format */

import {
  Button,
  Column,
  DatePicker,
  DatePickerInput,
  ErrorBoundary,
  Grid,
  InlineLoading,
  Modal,
  SkeletonPlaceholder
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { getEventBridgeReportData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
// import "./../css/_chart.scss";
import EventBridgeDetails from "./EventBridgeDetails";


const eventBridgeHeaderData = [
  {
    key: "eventID",
    header: "Event ID",
  },
  {
    key: "eventName",
    header: "Event Name",
  },
  {
    key: "eventExecutionTime",
    header: "Event Execution Time",
  }
];

const EventBridgeReport = () => {
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);

  const [apiLoading, setApiLoading] = useState(false);
  const [showPopUP, setShowPopUP] = useState(false);
  const [eventDetailsRow, setEventDetailsRow] = useState();
  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  useEffect(() => {
    let startDateDefault = "2022-09-13";
    let endDateDefault = new Date().toISOString().split("T")[0];

    setDefaultStartDate(startDateDefault);
    setDefaultEndDate(new Date().toISOString().split("T")[0]);

    startDateDefault += " 00:00";
    endDateDefault += " 00:00";

    setStartDate(startDateDefault);
    setEndDate(endDateDefault);

    getAPIData(startDateDefault, endDateDefault);
  }, []);

  const getDetailsRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getDetailsRow(APIData.data.data[0].data.rowData, id);
    setEventDetailsRow(row);
    setShowPopUP(true);
  };

  const getPopupData = () => {
    return <EventBridgeDetails details={eventDetailsRow} />;
  };

  const getRowCellData = (id, data, row) => {
    if (id.includes("eventID")) {
      return (
        <a
          className="event-status"
          onClick={() => {
            onClickNode(row.id);
          }}
        >
          {data}
        </a>
      );
    }
    if (id.includes("eventExecutionTime")) {
      return String(new Date(data).toLocaleString());
    }
    return data;
  };
  const getTableHeader = (header) => {
    return header;
  };

  const getAPIData = async (sDate, eDate) => {
    const response = await getEventBridgeReportData(
      keycloak.token,
      sDate,
      eDate
    );
    AddUserActivity(keycloak, context, "FEATURE", "Event bridge Insights", response);

    setIsAPIDataLoading(false);
    setApiLoading(false);
    setErrorMessage(updateErrorMessage(response));
    setAPIData(updateAPIData(response));
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-") + " 00:00";
  };

  const onStartDateChange = (e) => {
    setStartDate(formatDate(new Date(e[0]).toDateString()));
    if (e.length > 1) {
      setEndDate(formatDate(new Date(e[1]).toDateString()));
    }
  };

  const updateAPI = async () => {
    setApiLoading(true);
    await getAPIData(startDate, endDate);
  };

  const getHeadingAndDateFilter = () => {
    return (
      <>
        <Column lg={8}>
          <h3>{APIData.data.label}</h3>
        </Column>
        <Column lg={8} className="flex-display flex-end override-form-flex">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <DatePicker
              dateFormat="Y-m-d"
              datePickerType="range"
              onChange={(e) => onStartDateChange(e)}
              className="date-picker--margin"
            >
              <DatePickerInput
                id="date-picker-range-start"
                placeholder="yyyy-mm-dd"
                labelText="Start Date"
                defaultValue={defaultStartDate}
                type="text"
              />
              <DatePickerInput
                id="date-picker-range-end"
                placeholder="yyyy-mm-dd"
                labelText="End Date"
                defaultValue={defaultEndDate}
                type="text"
              />
            </DatePicker>
          </ErrorBoundary>
          <span>
            {apiLoading ? (
              <InlineLoading
                status="active"
                iconDescription="Loading"
                description="Loading data..."
                className="report-update__button"
              />
            ) : (
              <Button
                kind="primary"
                onClick={updateAPI}
                size="md"
                iconDescription="Icon Description"
                className="report-update__button"
              >
                Update
              </Button>
            )}
          </span>
        </Column>
      </>
    );
  };

  const createChartExplicit = () => {
    return (
      <>
        {getHeadingAndDateFilter()}
        {APIData.data.data[0].data.rowData &&
          APIData.data.data[0].data.rowData.length > 0 ? (
          <>
            <Column lg={16} className="common-top-margin">
              {APIData.data.data[0].data.rowData &&
                APIData.data.data[0].data.rowData.length > 0 ? (
                <ErrorBoundary fallback={<ErrorWhileFetching />}>
                  <CarbonDataTable
                    rowData={APIData.data.data[0].data.rowData}
                    headerData={eventBridgeHeaderData}
                    title={APIData.data.data[0].label}
                    getRowCellData={getRowCellData}
                    getTableHeader={getTableHeader}
                    actionsNeeded={false}
                  />
                </ErrorBoundary>
              ) : (
                "Data is unavailable!"
              )}
            </Column>
          </>
        ) : (
          <Column lg={16} md={8} sm={4}>
            <DataUnAvailable />
          </Column>
        )}
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {APIData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
      {showPopUP ? (
        <Modal
          open
          size="lg"
          passiveModal
          onRequestClose={() => setShowPopUP(false)}
          modalHeading="Event Details"
        >
          {getPopupData()}
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default EventBridgeReport;
