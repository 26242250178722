import { InlineLoading } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import Navigation from "Components/Navigation/Navigation";
import Context from "Context/Context";
import { getAdvantageMetering, getAdvantageUserRole, getPermissions, getUserInfo } from "Services/ServerApi";
import React, { useContext, useEffect, useState } from "react";

const Permissions = () => {
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const context = useContext(Context)
    const [advantageUser, setAdvantageUser] = useState({});
    const [userInfo, fetchUserInfo] = useState(false);

    const ICAToken = sessionStorage.getItem("ICAToken");

    const {
        data: permissionInfo,
        isLoading: loading1,
        isError: err1,
        refetch: permissionFetch,
        isFetching: isFetching,
    } = useQuery(
        [`permissionInfo`],
        async () => await getPermissions(keycloak),
        {
            cache: queryCache,
            cacheTime: 60 * 60 * 1000,
            enabled: false
        },
    );

    const {
        data: userInfoData,
        isLoading: loading2,
        isError: err2,
        refetch: userFetch,
        isFetching: isFetching1,
    } = useQuery(
        [`userInfo`],
        async () => await getUserInfo(keycloak),
        {
            cache: queryCache,
            cacheTime: 60 * 60 * 1000,
            enabled: false
        },
    );

    const {
        data: userInfoAdvantage,
        isLoading: loading3,
        isError: err3,
        refetch: userAdvantage,
        isFetching: isFetching2,
    } = useQuery(
        [`advantage`],
        async () => await getAdvantageUserRole(keycloak, ICAToken),
        {
            cache: queryCache,
            cacheTime: 60 * 60 * 1000,
            enabled: false
        },
    );

    const {
        data: advantageMeter,
        isLoading: loading4,
        isError: err4,
        refetch: advantageMeterRe,
        isFetching: isFetching3,
    } = useQuery(
        [`metering`],
        async () => await getAdvantageMetering(keycloak, advantageUser, ICAToken),
        {
            cache: queryCache,
            cacheTime: 60 * 60 * 1000,
            enabled: false
        },
    );

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            permissionFetch()
            userFetch()
        }
    }, [keycloak && keycloak.authenticated])

    useEffect(() => {
        if (userInfoAdvantage !== undefined) {
            setAdvantageUser(userInfoAdvantage)
        }
    }, [userInfoAdvantage])

    useEffect(() => {
        if (permissionInfo !== undefined && permissionInfo.data !== undefined) {
            // console.log("permissionInfo.data", permissionInfo)
            context.UserPermissions = permissionInfo.data.success;
            // fetchUserInfo(true)
        }
    }, [permissionInfo])

    useEffect(() => {
        const team = sessionStorage.getItem("teamId");
        if (team !== null && team !== undefined) {
            userAdvantage()
        }
    }, [keycloak && keycloak.authenticated])

    useEffect(() => {
        if (Object.entries(advantageUser).length > 0) {
            advantageMeterRe()
        }
    }, [advantageUser])

    useEffect(() => {
        if (userInfoData !== undefined && userInfoData.data !== undefined) {
            const usin = userInfoData.data.data;

            context.UserInfo = usin
            sessionStorage.setItem("tenants", JSON.stringify(usin.tenants));
            // reLoadApp(context);
            setTenant(usin);
            // setDefaultTenant(usin)
        }
    }, [userInfoData])

    const setTenant = (userInfoData) => {
        let tenantName = sessionStorage.getItem("tenant");
        if (tenantName) {
            //do nothing
        } else {
            tenantName = userInfoData.groups[0].name;
            sessionStorage.setItem("tenant", tenantName);
        }
        setOrganization(userInfoData, tenantName);
    }

    const setOrganization = (userInfoData, tenantName) => {
        let tenantObj = {};
        let organizationName = sessionStorage.getItem("organizationName");
        if (organizationName) {
            //do nothing
        } else {
            userInfoData.groups.forEach((group) => {
                if (group.name === tenantName) {
                    tenantObj = group;
                }
            })
            
            // organizationName = tenantObj.orgs !== undefined ? tenantObj.orgs[0] : ""; 
            organizationName = tenantObj?.orgs?.length > 0 ? tenantObj.orgs[0]?.name || "" : "";
        }
        sessionStorage.setItem("organizationName", organizationName);
        fetchUserInfo(true);
        setApplication(userInfoData, tenantName, organizationName);
    }

    const setApplication = (userInfoData, tenantName, orgName) => {
        let tenantObj = {};
        let orgObj = {};
        let applicationName = sessionStorage.getItem("applicationName");
        if (applicationName) {
            //do nothing
        } else {
            userInfoData.groups.forEach((group) => {
                if (group.name === tenantName) {
                    tenantObj = group;
                }
            })
            tenantObj.orgs.forEach((org) => {
                if (org.name === orgName) {
                    orgObj = org
                }
            })
            applicationName = orgObj.apps.length > 0 ?  orgObj.apps[0] : ""; 
        }
        sessionStorage.setItem("applicationName", applicationName);
        fetchUserInfo(true);
    }

    // const setDefaultTenant = (data) => {
    //     let newArr = null;
    //     Object.entries(data).forEach(([key, value]) => {
    //         if (key === "tenants") {
    //             newArr = value
    //         }
    //     })

    //     let tenantName = "";
    //     newArr !== null && newArr.forEach((arr) => {
    //         if (arr.name === "ibmconsulting") {
    //             tenantName = arr.name
    //         }
    //     })

    //     sessionStorage.setItem(
    //         "tenant",
    //         sessionStorage.getItem("tenant") || tenantName
    //     );

    //     setDefaultOrg(data, tenantName)
    // }

    // const setDefaultOrg = (data, tenantName) => {
    //     let newOrgs = null;
    //     Object.entries(data).forEach(([key, value]) => {
    //         if (key === "groups") {
    //             newOrgs = value
    //         }
    //     })

    //     let getOrg = [];
    //     newOrgs !== null && newOrgs.forEach((organization) => {
    //         return (sessionStorage.getItem("tenant") || tenantName) === organization.name ? organization.orgs.forEach((org) => {
    //             getOrg.push(org)
    //         }) : ""
    //     })
    //     sessionStorage.setItem("organizationName", sessionStorage.getItem("organizationName") || getOrg[0]);
    // }

    const getRealms = () => {
        return keycloak.clientId === undefined
            ? "IBM Consulting AIOps"
            : sessionStorage.getItem("tenant");
    }

    const getRealmDetails = () => {
        // return errMessage ? (
        //     <ErrorComponent title={errMessage} />
        // ) : (
        //     <InlineLoading
        //         description={`Fetching user details for ${keycloak.realm}`}
        //         className="event-loader"
        //     />
        // );
        return <InlineLoading
            description={`Fetching user details for ${keycloak.realm}`}
            className="event-loader"
        />
    }

    const getUsersInfo = () => {
        return userInfo ? (
            <Navigation />
        ) : (
            getRealmDetails()
        );
    };

    const authenticationLoader = () => {
        return <InlineLoading
            description={`Authenticating user for ${getRealms()}`}
            className="event-loader"
        />
    };

    return (
        <>
            {keycloak && keycloak.authenticated
                ? getUsersInfo()
                : authenticationLoader()}
        </>
    );
}

export default Permissions;