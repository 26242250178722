import {
  ClickableTile,
  Column,
  Row,
  SkeletonPlaceholder
} from "@carbon/react";
import { ArrowRight, Renew } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import ErrorFallback from "Common-Modules/ErrorFallback";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useData from "./Hooks/useData";
import TilesLoader from "./TilesLoader";
import "./citi.scss";
import EpochTime from "./epochTime";
import { getCluserTilesData } from "./utils";
import NumberDisplay from "Common-Modules/NumberDisplay";

const AssetDetails = (props) => {
  const { keycloak } = useKeycloak();
  const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
  const [dateChange, onDateChange] = useState(() => selecteDates ? selecteDates.time : {
    currentTime: new Date(new Date().getTime() - 15 * 60 * 1000).getTime(),
    prevTime: new Date().getTime(),
  });
  const asset = JSON.parse(sessionStorage.getItem("asset_class"));
  const history = useHistory();
  // const assetDetails = JSON.parse(sessionStorage.getItem("asset_details"));

  const [isCalculating, setIsCalculating] = useState(true);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [refresh, onRefresh] = useState(false);
  const [onLoading, setonLoading] = useState(0);

  const { isLoading, isErrorDate, assets, sessions, tasks, ooms, incidents, sessionCount, taskCount, apiRefresh } = useData(
    keycloak,
    dateChange,
    refresh
  );

  useEffect(() => {
    if (!apiRefresh) {
      setonLoading(loading => loading + 1)
    }
  }, [apiRefresh])

  useEffect(() => {
    const sess = sessions;
    const oom = ooms;
    const task = tasks;
    const incident = incidents;
    try {
      const calculatedData = getCluserTilesData(
        asset,
        sess,
        oom,
        task,
        incident,
        sessionCount,
        taskCount
      );
      setData(calculatedData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, [assets, sessions, tasks, ooms, incidents, sessionCount, taskCount]);


  const dateOnChange = (e) => {
    onDateChange(e);
    setonLoading(1)
  };

  useEffect(() => {
    if (onLoading > 1) {
      onRefresh(false);
    }
  }, [onLoading])

  const getClusterCount = (clusters) => {
    let count = 0;
    Object.values(clusters).forEach((clusterArr) => {
      count = count + clusterArr.length;
    });
    return count;
  };

  const onClickRefresh = () => {
    onRefresh(true)
    setonLoading(1)
  }

  const handleOnClick = (cluster) => {
    sessionStorage.setItem("cluster_name", JSON.stringify(cluster));
    sessionStorage.setItem("cluster_data", JSON.stringify(data));
    history.push({ pathname: `/citiCluster/${cluster}`, state: { details: props.location.state.details, directvData: props.location.state.directvData, starCric: props.location.state.starCric, mergeData: props.location.state.mergeData, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange, flow: props.location.state.flow } });
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    // <FlexGrid className="att-dash circle-view">
    <>
      <Row>
        <Column lg={8} md={4} sm={4}>
          {/* <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/operations">Operations</BreadcrumbItem>
            <BreadcrumbItem href="#/citiIndex">Overview</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>{asset.displayName}</BreadcrumbItem>
          </Breadcrumb> */}
        </Column>
        {/* <Column lg={4} md={4} sm={4}>
          <ul className="portfolio-fss">{dateSelection()}</ul>
        </Column> */}
        {/* <Column lg={8} md={4} sm={4}>
          <div className="refresh-icon">
            <Renew
              aria-label="refresh"
              className={onLoading <= 1 ? "rotateIcons" : ""}
              onClick={() => onClickRefresh()}
            />
            Refresh
          </div>
          <EpochTime
            dateOnChange={dateOnChange}
          />
        </Column> */}
      </Row>
      <Row className="citi--specific--styles">
        <Column lg={16} md={8} sm={4}>
          <div className="flex-1 flex-space-between flex-wrap">
            <h4 className="fw-normal" style={{ margin: "0.5rem 0" }}>
              {/* Job:{" "}
              <span style={{ color: "#0062ff" }}>{asset.displayName}</span> */}
            </h4>
            <div className="flex-space-between flex-wrap" style={{gap:"0.5rem"}}>
              <div className="refresh-icon" onClick={() => onClickRefresh()}>
                <Renew
                  aria-label="refresh"
                  className={onLoading <= 1 ? "rotateIcons" : ""}
                  // onClick={() => onClickRefresh()}
                />
                Refresh
              </div>
              <EpochTime
                dateOnChange={dateOnChange}
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row>
        {getClusterCount(asset.clusters) > 0 ? (
          onLoading > 1 ? Object.values(asset.clusters).map((clusters) => {
            return clusters.map((cluster) => {
              return (
                <Column
                  lg={8}
                  md={4}
                  sm={4}
                  className="clickable-tile-portfolio-att margin-lft"
                  key={cluster}
                >
                  <ClickableTile
                    id={`clickableTile-${cluster}`}
                    onClick={() => handleOnClick(cluster)}
                    aria-label={`clickableTile-${cluster}`}
                  >
                    <div className="title">
                      <h4 className="fw-normal">
                        Cluster:{" "}
                        <span style={{ fontWeight: 600 }}>{cluster}</span>
                      </h4>
                    </div>
                    {/* <ul className="clus-app">
                      <li>
                        <h4>No.of Applications:</h4> <span>0</span>
                      </li>
                    </ul> */}
                    <div className="sec-row counts--wrapper">
                      <ul className="host">
                        <li>
                          <h6 style={{ fontWeight: 600 }}>Cluster</h6>
                        </li>
                        <li>
                          Blocked Host{" "}
                          <span
                            className={
                              data.blockedHostsCount[cluster] > 0 ? "red" : ""
                            }
                            title={data.blockedHostsCount[cluster] || 0}
                          >
                            <NumberDisplay number={data.blockedHostsCount[cluster] || 0} />
                          </span>
                        </li>
                        <li>
                          OOM{" "}
                          <span
                            className={data.oomCount[cluster] > 0 ? "red" : ""}
                            title={data.oomCount[cluster] || 0}
                          >
                            <NumberDisplay number={data.oomCount[cluster] || 0} />
                          </span>
                        </li>
                        <li>
                          Node Issues{" "}
                          <span
                            className={
                              data.nodeIssuesCount[cluster] > 0 ? "red" : ""
                            }
                            title={data.nodeIssuesCount[cluster] || 0}
                          >
                            <NumberDisplay number={data.nodeIssuesCount[cluster] || 0} />
                          </span>
                        </li>
                      </ul>
                      <ul className="session">
                        <li>
                          <h6>Sessions</h6>
                        </li>
                        <li>
                          Open<span title={data["openSessionCount"][cluster] || 0} ><NumberDisplay number={data["openSessionCount"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Closed<span title={data["closedSessionCount"][cluster] || 0}><NumberDisplay number={data["closedSessionCount"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Failed
                          <span
                            className={
                              data.sessionsCount[cluster] > 0 ? "red" : ""
                            }
                            title={data.sessionsCount[cluster] || 0}
                          >
                            <NumberDisplay number={data.sessionsCount[cluster] || 0} />
                          </span>
                        </li>
                      </ul>
                      <ul className="status">
                        {/* <li><div>Successful:</div><span>{data.success}</span></li> */}
                        <li>
                          <h6>Tasks</h6>
                        </li>
                        <li>
                          Done<span title={data["doneTasks"][cluster] || 0} ><NumberDisplay number={data["doneTasks"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Running<span title={data["runningTasks"][cluster] || 0}><NumberDisplay number={data["runningTasks"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Error
                          <span
                            className={
                              data.taskCounts[cluster] > 0 ? "red" : ""
                            }
                            title={data.taskCounts[cluster] || 0}
                          >
                            <NumberDisplay number={data.taskCounts[cluster] || 0} />
                          </span>
                        </li>
                        <li>
                          Pending<span title={data["pendingTasks"][cluster] || 0} ><NumberDisplay number={data["pendingTasks"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Cancelled<span title={data["cancelledTasks"][cluster] || 0} ><NumberDisplay number={data["cancelledTasks"][cluster] || 0} /></span>
                        </li>
                        <li>
                          Long Running<span>{0}</span>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="sec-row counts--wrapper">
                      <p>
                        Session Count:{" "}
                        <strong>{data.sessionsCount[cluster] || 0}</strong>
                      </p>
                      <p>
                        Task Count:{" "}
                        <strong>{data.taskCounts[cluster] || 0}</strong>
                      </p>
                      <p>
                        OOM Count:{" "}
                        <strong>{data.oomCount[cluster] || 0}</strong>
                      </p>
                    </div> */}
                    <div>
                      <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                    </div>
                  </ClickableTile>
                </Column>
              );
            });
          }) : [1, 2, 3, 4].map((tile) => <Column
            lg={8}
            md={4}
            sm={4}
            className="clickable-tile-portfolio-att margin-lft"
            key={`skeleton-${tile}`}
          >
            <ClickableTile
              id={`seleton-clickableTile-${tile}`}
              aria-label={`seleton-clickableTile-${tile}`}
            >
              <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
            </ClickableTile>
          </Column>)
        ) : (
          <h4>No clusters To Show</h4>
        )}
      </Row>
    </>
    // </FlexGrid>
  );
};

export default AssetDetails;
