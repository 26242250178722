import { Column, InlineLoading, FlexGrid, Row } from "@carbon/react";
import React, { useState } from "react";
const sign = require('jwt-encode');
const secret = 'secret';

const DoIndex = () => {
    const [loading, setLoading] = useState(true);

    // const onLoadIframe = () => {
    //     setLoading(false);
    // }

    // let loadingText = <InlineLoading
    //     description={`Loading..`}
    //     className="event-loader"
    // />


    const jwttoken = () => {
        const json = {
            filters: {
                tenant: ['CAI'],
                org: [""],
                time: { start_datetime: "", end_datetime: "" }
            },
            Tab: "Services Management"
        }

        const jwt = sign(json, secret);

        const doUrl = sessionStorage.getItem("doUrl");
        window.open(`${doUrl}?userinfo=${jwt}`, "_self")

        //return `https://aiops-do-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud?userinfo=${jwt}`
    }

    return (
        <FlexGrid className="integratedOps">
            <Row>
                <Column lg={16} md={8} sm={4}>
                {jwttoken()}
                    {/* <div>
                        {loading && loadingText}
                        <iframe
                            id="digital-operations"
                            title={' '}
                            src={jwttoken()}
                            width="100%"
                            height="900"  //adjust this accordingly
                            sandbox='allow-scripts allow-same-origin allow-popups allow-top-navigation allow-forms allow-top-navigation-by-user-activation
                allow-modals allow-popups-to-escape-sandbox allow-pointer-lock'
                            style={{ display: loading ? 'none' : 'block' }}
                            allow="fullscreen"
                            onLoad={onLoadIframe}
                        >
                        </iframe>
                    </div> */}
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default DoIndex;