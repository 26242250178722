import {
  Button,
  Column,
  ErrorBoundary,
  ProgressIndicator,
  ProgressIndicatorSkeleton,
  ProgressStep,
  RadioButton,
  RadioButtonGroup,
  Row,
  Tile,
} from "@carbon/react";
import {
  ChartArea,
  ChartAverage,
  ChartClusterBar,
  ChartColumn,
  ChartCombo,
  ChartStacked,
  WarningFilled,
} from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import ChartContainer from "Carbon-Charts/ChartContainer";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const leftTiles = [
  {
    icon: <ChartClusterBar size={16} fill="#4589ff" />,
    title: "Stories",
    value: 10,
    type: ["All", "Product"],
  },
  {
    icon: <ChartAverage size={16} fill="#4589ff" />,
    title: "Builds",
    value: 20,
    type: ["All", "Payment"],
  },
  {
    icon: <ChartCombo size={16} fill="#4589ff" />,
    title: "Test Coverage",
    value: 31,
    type: ["All", "Cart"],
  },
  {
    icon: <ChartColumn size={16} fill="#4589ff" />,
    title: "Vulnerabilities",
    value: 15,
    type: ["All", "Cart"],
  },
  {
    icon: <ChartStacked size={16} fill="#4589ff" />,
    title: "Deployments",
    value: 24,
    type: ["All", "Payment", "Cart"],
  },
  {
    icon: <ChartArea size={16} fill="#4589ff" />,
    title: "Tickets",
    value: 43,
    type: ["All", "Payment", "Order"],
  },
  {
    title: "People",
    value: 14,
    type: ["All", "Payment", "Order"],
  },
];

const progress = [
  {
    progress: "complete",
    label: "Planning",
    description: "",
    type: ["Product", "Cart"],
  },
  {
    progress: "complete",
    label: "Development",
    description: "",
    type: ["Cart"],
  },
  {
    progress: "invalid",
    label: "Testing",
    description: "",
    type: ["Product", "Order"],
  },
  {
    progress: "invalid",
    label: "Build & Deployment",
    description: "",
    type: ["Payment", "Cart"],
  },
  {
    progress: "invalid",
    label: "Operation",
    description: "",
    type: ["Payment", "Product", "Order"],
  },
];

const AlMDevInsights = (props) => {
  const { keycloak } = useKeycloak();
  const [devInsightsData, setDevInsights] = useState([]);
  const [showleftTiles] = useState(leftTiles);
  const [ProgressActive, setProgressActive] = useState("");
  const [showInference, setInference] = useState("");
  const [viewType, setViewType] = useState("");
  const [dupliViewType, duplicateViewType] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  const [progressData] = useState(progress);
  const [chartTitle, setChartTitle] = useState("Sprints");
  const [devInsightsDuplicate, setDevInsightsDuplicate] = useState([]);

  useEffect(() => {
    if (props.radioSelected === "Release") {
      setDevInsights([]);
      ReleaseDetails();
    } else {
      setDevInsights([]);
      getDevInsightsData();
    }
    duplicateViewType(props.type);
    setViewType(props.type);
  }, [props]);

  const getDevInsightsData = async () => {
    const response = await getAnyCollection(keycloak, "ALMdevInsights");
    response.err === true
      ? setErrorMessage(response.message)
      : checkStatusCode(response.data);
  };

  const checkStatusCode = (res) => {
    getInference(res.ALMdevInsights);
    setDevInsights(res.ALMdevInsights);
    setDevInsightsDuplicate(res.ALMdevInsights);
  };

  const getInference = (devData) => {
    let infere = "";
    props.title &&
      devData.length > 0 &&
      devData.forEach((data) => {
        if (data.projectName === props.title.ProjectName) {
          infere = data.Inference;
        }
      });
    setInference(infere);
  };

  const ReleaseDetails = (e) => {
    e === "Release" && setChartTitle("Releases");
    e === "Sprint" && setChartTitle("Sprints");
    let dev = [],
      final = [];
    devInsightsDuplicate.length > 0 &&
      devInsightsDuplicate.forEach((data) => {
        if (data.projectName === props.title.ProjectName) {
          data.devInsights.forEach((insights) => {
            let response = [];
            let high = insights.Highlights;
            insights.data.map((projectData) => {
              response.push({
                ...projectData,
                key:
                  e === "Release"
                    ? projectData.release
                    : projectData.key !== undefined &&
                      projectData.key.replace("Release", "Sprint"),
              });
              if (
                projectData.key ===
                (insights.Highlights !== undefined && insights.Highlights.from)
              ) {
                high = {
                  ...high,
                  from: e === "Release" ? projectData.release : projectData.key,
                };
              } else if (
                projectData.key ===
                (insights.Highlights !== undefined && insights.Highlights.to)
              ) {
                high = {
                  ...high,
                  to: e === "Release" ? projectData.release : projectData.key,
                };
              }
            });
            dev.push({ ...insights, Highlights: high, data: response });
          });
        }
        final.push({ ...data, devInsights: dev });
      });
    setDevInsights(final);
  };

  const getDevData = (title) => {
    let devs = [];
    devInsightsData.length > 0 &&
      devInsightsData.forEach((data) => {
        if (data.projectName === props.title.ProjectName) {
          data.devInsights.forEach((insights) => {
            if (insights.title === title) {
              devs = insights;
            }
          });
        }
      });
    return devs;
  };

  const getOpsData = (title) => {
    let ops = [];
    devInsightsData.length > 0 &&
      devInsightsData.forEach((data) => {
        if (data.projectName === props.title.ProjectName) {
          data.opsInsights.forEach((insights) => {
            if (insights.title === title) {
              ops = insights;
            }
          });
        }
      });
    return ops;
  };

  const velocityChart = () => {
    if (Object.entries(getDevData("Velocity")).length > 0) {
      const res = getDevData("Velocity");
      const response = {
        label: "",
        dataType: "bar",
        type: res.type !== undefined ? res.type : ["Product", "Planning"],
        translateValue: "19px",
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Velocity",
            color: {
              scale: {
                Committed: "#6fdc8c",
                Completed: "#007d79",
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            axes: {
              left: {
                mapsTo: "value",
                title: "Tasks Count",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Team Velocity",
                      fillColor: "#002d9c",
                    },
                  ],
                }),
              },
              bottom: {
                title: chartTitle,
                scaleType: "labels",
                mapsTo: "key",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            height: "300px",
            toolbar: {
              enabled: false,
            },
          },
        },
      };
      return response;
    } else {
      return chartSkeleton("area", "Velocity", ["Product"]);
    }
  };

  const chatbot = (title) => {
    if (title === "dev") {
      window.open("https://dso-chatops.slack.com/archives/C04NA0FB79T");
    } else if (title === "ops") {
      window.open("https://dso-chatops.slack.com/archives/C04LETJ3Q5C");
    }
  };

  const toolAvailability = () => {
    if (Object.entries(getDevData("toolAvailability")).length > 0) {
      const res = getDevData("toolAvailability");
      const response = {
        label: "",
        dataType: "area",
        translateValue: "19px",
        type:
          res.type !== undefined
            ? res.type
            : ["Cart", "Payment", "Build & Deployment"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Devops Tool Availability",
            height: "300px",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            axes: {
              bottom: {
                mapsTo: "date",
                scaleType: "time",
                ticks: {
                  number: 2,
                },
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              left: {
                title: "%",
                mapsTo: "value",
                scaleType: "linear",
                includeZero: false,
                ticks: {
                  values: [80, 90, 100],
                },
              },
            },
            color: {
              scale: {
                "Dataset 1": "#1192e8",
              },
              gradient: {
                enabled: true,
              },
            },
            points: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            toolbar: {
              controls: [
                {
                  type: "Custom",
                  text: "Custom button",
                  shouldBeDisabled: () => false,
                  clickFunction: () => {
                    chatbot("dev");
                  },
                  iconSVG: {
                    content: `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:none;}</style></defs><title>collaborate</title><path d="M6,21V20H4v1a7,7,0,0,0,7,7h3V26H11A5,5,0,0,1,6,21Z" transform="translate(0)"/><path d="M24,11v1h2V11a7,7,0,0,0-7-7H16V6h3A5,5,0,0,1,24,11Z" transform="translate(0)"/><path d="M11,11H5a3,3,0,0,0-3,3v2H4V14a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V14A3,3,0,0,0,11,11Z" transform="translate(0)"/><path d="M8,10A4,4,0,1,0,4,6,4,4,0,0,0,8,10ZM8,4A2,2,0,1,1,6,6,2,2,0,0,1,8,4Z" transform="translate(0)"/><path d="M27,25H21a3,3,0,0,0-3,3v2h2V28a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V28A3,3,0,0,0,27,25Z" transform="translate(0)"/><path d="M20,20a4,4,0,1,0,4-4A4,4,0,0,0,20,20Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,26,20Z" transform="translate(0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`,
                  },
                },
              ],
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Devops Tool Availability", [
        "Cart",
        "Payment",
        "Build & Deployment",
      ]);
    }
  };

  const avgBuildTime = () => {
    if (Object.entries(getDevData("Build Time")).length > 0) {
      const res = getDevData("Build Time");
      const response = {
        label: "",
        dataType: "area",
        translateValue: "19px",
        type:
          res.type !== undefined
            ? res.type
            : ["Cart", "Payment", "Build & Deployment"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Build Time",
            axes: {
              bottom: {
                title: chartTitle,
                mapsTo: "key",
                scaleType: "labels",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              left: {
                mapsTo: "value",
                scaleType: "linear",
                title: "Time (min)",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Idle Build Time",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
            },
            color: {
              scale: {
                UI: "#1de4bd",
                API: "#19aade",
                BFF: "#1ac9e6",
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            curve: "curveMonotoneX",
            resizable: true,
            height: "300px",
            toolbar: {
              enabled: false,
            },
          },
        },
      };
      return response;
    } else {
      return chartSkeleton("bar", "Build Time", [
        "Cart",
        "Payment",
        "Build & Deployment",
      ]);
    }
  };

  const autoManualTest = () => {
    if (Object.entries(getDevData("ManualAndAutomate")).length > 0) {
      const res = getDevData("ManualAndAutomate");
      const response = {
        label: "",
        dataType: "combo",
        translateValue: "19px",
        type: res.type !== undefined ? res.type : ["Order", "Testing"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Manual and Automation Test",
            axes: {
              left: {
                title: "Count",
                stacked: true,
                mapsTo: "value",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Team Velocity",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: chartTitle,
                scaleType: "labels",
                mapsTo: "key",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              right: {
                title: "Time (min)",
                scaleType: "linear",
                mapsTo: "temp",
                correspondingDatasets: ["Manual Testing", "Automated Tests"],
              },
            },
            curve: "curveMonotoneX",
            comboChartTypes: [
              {
                type: "grouped-bar",
                options: {
                  points: {
                    enabled: false,
                  },
                },
                correspondingDatasets: ["Manual TC", "Automated TC"],
              },
              {
                type: "line",
                correspondingDatasets: ["Manual Testing", "Automated Tests"],
              },
            ],
            height: "300px",
            color: {
              scale: {
                "Manual TC": "#6929c4",
                "Manual Testing": "#a56eff",
                "Automated TC": "#012749",
                "Automated Tests": "#0072c3",
              },
            },
            toolbar: {
              enabled: false,
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Manual and Automation Test", ["Order"]);
    }
  };

  const developmentEfficiency = () => {
    if (Object.entries(getDevData("DevelopmentEfficiency")).length > 0) {
      const res = getDevData("DevelopmentEfficiency");
      const response = {
        label: "",
        dataType: "horizontal-bar",
        translateValue: "19px",
        type:
          res.type !== undefined
            ? res.type
            : ["Cart", "Payment", "Development", "Build & Deployment"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Build Success and Failure",
            axes: {
              left: {
                title: "No.of Builds",
                mapsTo: "value",
                stacked: true,
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Build Success",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: chartTitle,
                mapsTo: "key",
                scaleType: "labels",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            resizable: true,
            height: "300px",
            color: {
              scale: {
                "Code Issue": "#ff8389",
                "Quality Gate": "#da1e28",
                Tool: "#ffb3b8",
                "Unit Testing": "#fa4d56",
                "Build Success": "#005d5d",
              },
              gradient: {
                enabled: true,
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            toolbar: {
              controls: [
                {
                  type: "Custom",
                  text: "Custom button",
                  shouldBeDisabled: () => false,
                  clickFunction: () => {
                    chatbot("dev");
                  },
                  iconSVG: {
                    content: `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:none;}</style></defs><title>collaborate</title><path d="M6,21V20H4v1a7,7,0,0,0,7,7h3V26H11A5,5,0,0,1,6,21Z" transform="translate(0)"/><path d="M24,11v1h2V11a7,7,0,0,0-7-7H16V6h3A5,5,0,0,1,24,11Z" transform="translate(0)"/><path d="M11,11H5a3,3,0,0,0-3,3v2H4V14a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V14A3,3,0,0,0,11,11Z" transform="translate(0)"/><path d="M8,10A4,4,0,1,0,4,6,4,4,0,0,0,8,10ZM8,4A2,2,0,1,1,6,6,2,2,0,0,1,8,4Z" transform="translate(0)"/><path d="M27,25H21a3,3,0,0,0-3,3v2h2V28a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V28A3,3,0,0,0,27,25Z" transform="translate(0)"/><path d="M20,20a4,4,0,1,0,4-4A4,4,0,0,0,20,20Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,26,20Z" transform="translate(0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`,
                  },
                },
              ],
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Development Efficiency", [
        "Cart",
        "Payment",
      ]);
    }
  };

  const TestCycleTime = () => {
    if (Object.entries(getDevData("Test Cycle")).length > 0) {
      const res = getDevData("Test Cycle");
      const response = {
        label: "",
        dataType: "combo",
        translateValue: "19px",
        type: res.type !== undefined ? res.type : ["Product", "Testing"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Test Cycle Time",
            color: {
              scale: {
                Defects: "#fa4d56",
                "Test Cycle Time": "#005d5d",
              },
            },
            axes: {
              left: {
                mapsTo: "count",
                scaleType: "linear",
                title: "Test Cycle Time",
              },
              right: {
                mapsTo: "value",
                scaleType: "linear",
                title: "Defects",
                correspondingDatasets: ["Defects"],
              },
              bottom: {
                title: chartTitle,
                mapsTo: "key",
                scaleType: "labels",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            comboChartTypes: [
              {
                type: "simple-bar",
                correspondingDatasets: ["Test Cycle Time"],
              },
              {
                type: "line",
                options: {
                  points: {
                    radius: 5,
                  },
                },
                correspondingDatasets: ["Defects"],
              },
            ],
            height: "300px",
            toolbar: {
              enabled: false,
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Test Cycle Time", ["Product"]);
    }
  };

  const planningEffors = () => {
    if (Object.entries(getDevData("PlanningEfforts")).length > 0) {
      const res = getDevData("PlanningEfforts");
      const response = {
        label: "",
        dataType: "bar",
        translateValue: "19px",
        type: res.type !== undefined ? res.type : ["Cart", "Planning"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Estimated Effort vs Actual Effort",
            axes: {
              left: {
                mapsTo: "value",
                title: "Days",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Effort Time",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: chartTitle,
                scaleType: "labels",
                mapsTo: "key",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            height: "300px",
            grid: {
              x: {
                enabled: false,
              },
            },
            toolbar: {
              enabled: false,
            },
            color: {
              scale: {
                "Estimated Effort": "#6929c4",
                "Actual Effort": "#491d8b",
              },
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("line", "Estimated Effort vs Actual Effort", [
        "Cart",
      ]);
    }
  };

  const loadResponseTime = () => {
    if (Object.entries(getDevData("Load vs Response Time")).length > 0) {
      const res = getDevData("Load vs Response Time");
      const response = {
        label: "",
        dataType: "line",
        translateValue: "19px",
        type: res.type !== undefined ? res.type : ["Order", "Testing"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "User Load vs Response Time",
            axes: {
              bottom: {
                title: chartTitle,
                mapsTo: "key",
                scaleType: "labels",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              left: {
                mapsTo: "value",
                title: "Time (in sec)",
                scaleType: "linear",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Idle Time",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
            },
            height: "300px",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            toolbar: {
              enabled: false,
            },
            color: {
              scale: {
                "50 user": "#002d9c",
                "100 user": "#8a3800",
                "200 user": "#ee538b",
              },
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Load Time vs Response Time", ["Order"]);
    }
  };

  const codeCoverage = () => {
    if (Object.entries(getDevData("codeCoverage")).length > 0) {
      const res = getDevData("codeCoverage");
      const response = {
        label: "",
        dataType: "horizontal-bar",
        translateValue: "19px",
        type: res.type !== undefined ? res.type : ["Cart", "Development"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Code Coverage",
            axes: {
              left: {
                title: chartTitle,
                scaleType: "labels",
                mapsTo: "key",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              bottom: {
                mapsTo: "value",
                scaleType: "linear",
                title: "No.of Lines",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Code Coverage",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
            },
            color: {
              scale: {
                Covered: "#ff7eb6",
                "Not Covered": "#fff1f1",
              },
            },
            height: "300px",
            toolbar: {
              controls: [
                {
                  type: "Custom",
                  text: "Custom button",
                  shouldBeDisabled: () => false,
                  clickFunction: () => {
                    chatbot("dev");
                  },
                  iconSVG: {
                    content: `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:none;}</style></defs><title>collaborate</title><path d="M6,21V20H4v1a7,7,0,0,0,7,7h3V26H11A5,5,0,0,1,6,21Z" transform="translate(0)"/><path d="M24,11v1h2V11a7,7,0,0,0-7-7H16V6h3A5,5,0,0,1,24,11Z" transform="translate(0)"/><path d="M11,11H5a3,3,0,0,0-3,3v2H4V14a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V14A3,3,0,0,0,11,11Z" transform="translate(0)"/><path d="M8,10A4,4,0,1,0,4,6,4,4,0,0,0,8,10ZM8,4A2,2,0,1,1,6,6,2,2,0,0,1,8,4Z" transform="translate(0)"/><path d="M27,25H21a3,3,0,0,0-3,3v2h2V28a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V28A3,3,0,0,0,27,25Z" transform="translate(0)"/><path d="M20,20a4,4,0,1,0,4-4A4,4,0,0,0,20,20Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,26,20Z" transform="translate(0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`,
                  },
                },
              ],
            },
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("bar", "Code Coverage", ["Cart"]);
    }
  };

  const chartSkeleton = (datatype, title, typeData) => {
    let response = {
      label: "",
      dataType: datatype,
      type: typeData,
      data: {
        chartData: [],
        chartOptions: {
          title: "",
          ...(datatype !== "pie" && {
            axes: {
              left: {
                mapsTo: "value",
              },
              bottom: {
                scaleType: "labels",
                mapsTo: "key",
              },
            },
          }),
          data: {
            loading: true,
          },
          height: "300px",
        },
      },
    };
    return response;
  };

  const incidentResolution = () => {
    if (Object.entries(getOpsData("incidentResolution")).length > 0) {
      let incidentResolutionData = getOpsData("incidentResolution");
      return {
        label: "",
        dataType: "stacked-area",
        translateValue: "19px",
        type:
          incidentResolutionData.type !== undefined
            ? incidentResolutionData.type
            : ["Payment", "Order", "Operation"],
        data: {
          chartData: incidentResolutionData.data,
          chartOptions: {
            title: "Incident Resolution by Team",
            axes: {
              left: {
                title: "No. of Tickets Resolved",
                stacked: true,
                scaleType: "linear",
                mapsTo: "value",
                ticks: {
                  number: 5,
                },
                ...(incidentResolutionData.average !== undefined && {
                  thresholds: [
                    {
                      value: incidentResolutionData.average,
                      label: "Avg Tickets Resolve Time",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: "Releases",
                scaleType: "labels",
                mapsTo: "key",
                ...(incidentResolutionData.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: incidentResolutionData.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: incidentResolutionData.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            curve: "curveMonotoneX",
            height: "300px",
            color: {
              scale: {
                "L1 Team": "#bae6ff",
                "L2 Team": "#6fdc8c",
                "L3 Team": "#012749",
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            toolbar: {
              enabled: false,
            },
          },
        },
      };
    } else {
      return chartSkeleton("area", "Incident Resolution by Team", [
        "Payment",
        "Order",
      ]);
    }
  };

  const recurringIncident = () => {
    if (Object.entries(getOpsData("recurringIncident")).length > 0) {
      let recurringIncidentData = getOpsData("recurringIncident");
      return {
        label: "",
        dataType: "combo",
        translateValue: "19px",
        type:
          recurringIncidentData.type !== undefined
            ? recurringIncidentData.type
            : ["Order", "Operation"],
        data: {
          chartData: recurringIncidentData.data,
          chartOptions: {
            title: "Recurring Incident by Month",
            axes: {
              left: {
                title: "No. of File Changed",
                mapsTo: "value",
                stacked: true,
                ...(recurringIncidentData.average !== undefined && {
                  thresholds: [
                    {
                      value: recurringIncidentData.average,
                      label: "Avg File Changes",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: "Releases",
                mapsTo: "key",
                scaleType: "labels",
                ...(recurringIncidentData.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: recurringIncidentData.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: recurringIncidentData.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              right: {
                title: "Recurring Defect",
                mapsTo: "count",
                correspondingDatasets: ["Recurring Defect"],
                titleOrientation: "right",
              },
            },
            comboChartTypes: [
              {
                type: "stacked-bar",
                options: {},
                correspondingDatasets: ["UI", "BFF", "API"],
              },
              {
                type: "line",
                options: {},
                correspondingDatasets: ["Recurring Defect"],
              },
            ],
            height: "300px",
            color: {
              scale: {
                UI: "#fa4d56",
                API: "#570408",
                BFF: "#9f1853",
                "Recurring Defect": "#005d5d",
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            toolbar: {
              enabled: false,
            },
          },
        },
      };
    } else {
      return chartSkeleton("line", "Recurring Incident by Month", ["Order"]);
    }
  };

  const sev1Resolution = () => {
    if (Object.entries(getOpsData("sev1Resolution")).length > 0) {
      let sev1ResolutionData = getOpsData("sev1Resolution");
      return {
        label: "",
        dataType: "combo",
        translateValue: "19px",
        type:
          sev1ResolutionData.type !== undefined
            ? sev1ResolutionData.type
            : ["Product", "Payment", "Operation"],
        data: {
          chartData: sev1ResolutionData.data,
          chartOptions: {
            title: "High Severity Incidents Vs Time taken to fix",
            axes: {
              left: {
                title: "Count of Incidents",
                stacked: true,
                mapsTo: "value",
                titleOrientation: "left",
                ...(sev1ResolutionData.average !== undefined && {
                  thresholds: [
                    {
                      value: sev1ResolutionData.average,
                      label: "Avg Incidents",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: "Releases",
                scaleType: "labels",
                mapsTo: "key",
                ...(sev1ResolutionData.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: sev1ResolutionData.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: sev1ResolutionData.Highlights.to,
                      },
                    ],
                  },
                }),
              },
              right: {
                title: "Hrs to fix",
                scaleType: "linear",
                mapsTo: "count",
                correspondingDatasets: ["Code fix Duration"],
                titleOrientation: "right",
              },
            },
            curve: "curveMonotoneX",
            comboChartTypes: [
              {
                type: "stacked-area",
                options: {
                  points: {
                    enabled: false,
                  },
                },
                correspondingDatasets: [
                  "Code change",
                  "Data Issue",
                  "Infrastructure",
                ],
              },
              {
                type: "line",
                correspondingDatasets: ["Code fix Duration"],
              },
            ],
            height: "300px",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            color: {
              scale: {
                "Code change": "#012749",
                "Data Issue": "#6fdc8c",
                Infrastructure: "#8a3ffc",
                "Code fix Duration": "#ee538b",
              },
            },
            toolbar: {
              controls: [
                {
                  type: "Custom",
                  text: "Custom button",
                  shouldBeDisabled: () => false,
                  clickFunction: () => {
                    chatbot("ops");
                  },
                  iconSVG: {
                    content: `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:none;}</style></defs><title>collaborate</title><path d="M6,21V20H4v1a7,7,0,0,0,7,7h3V26H11A5,5,0,0,1,6,21Z" transform="translate(0)"/><path d="M24,11v1h2V11a7,7,0,0,0-7-7H16V6h3A5,5,0,0,1,24,11Z" transform="translate(0)"/><path d="M11,11H5a3,3,0,0,0-3,3v2H4V14a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V14A3,3,0,0,0,11,11Z" transform="translate(0)"/><path d="M8,10A4,4,0,1,0,4,6,4,4,0,0,0,8,10ZM8,4A2,2,0,1,1,6,6,2,2,0,0,1,8,4Z" transform="translate(0)"/><path d="M27,25H21a3,3,0,0,0-3,3v2h2V28a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v2h2V28A3,3,0,0,0,27,25Z" transform="translate(0)"/><path d="M20,20a4,4,0,1,0,4-4A4,4,0,0,0,20,20Zm6,0a2,2,0,1,1-2-2A2,2,0,0,1,26,20Z" transform="translate(0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`,
                  },
                },
              ],
            },
          },
        },
      };
    } else {
      return chartSkeleton("line", "Sev1 Resolution Reason by Fix time", [
        "Product",
        "Payment",
      ]);
    }
  };

  const showDynamicCharts = (data) => {
    return viewType !== "All"
      ? [
          velocityChart(),
          showStorySize(),
          planningEffors(),
          toolAvailability(),
          avgBuildTime(),
          developmentEfficiency(),
          codeCoverage(),
          autoManualTest(),
          TestCycleTime(),
          loadResponseTime(),
          incidentResolution(),
          recurringIncident(),
          sev1Resolution(),
        ].map((flow, i) => {
          return (
            flow.type &&
            flow.type.some((fruit) => viewType.includes(fruit)) &&
            getSelectedCharts(flow, i)
          );
        })
      : [
          velocityChart(),
          showStorySize(),
          planningEffors(),
          toolAvailability(),
          avgBuildTime(),
          developmentEfficiency(),
          codeCoverage(),
          autoManualTest(),
          TestCycleTime(),
          loadResponseTime(),
          incidentResolution(),
          recurringIncident(),
          sev1Resolution(),
        ].map((flow, i) => {
          return getSelectedCharts(flow, i);
        });
  };

  const getSelectedCharts = (flow, index) => {
    return (
      <Column lg={8} md={8} sm={4}>
        <ErrorBoundary fallback={"Data is unavailable"}>
          <ChartContainer data={flow} />
        </ErrorBoundary>
      </Column>
    );
  };

  const onRadioChange = (e) => {
    setDevInsights([]);
    ReleaseDetails(e);
  };

  const onChangeProgress = (e) => {
    const select = progress.filter((pro, index) => index === e);
    setViewType(select[0].label);
    setProgressActive(select[0].label);
  };

  const showProgressIndicator = () => {
    return progressData.map((data) => {
      return (
        <ProgressStep
          key={uuidv4()}
          complete={
            data.progress === "complete" ||
            data.type.some((typ) => !dupliViewType.includes(typ))
          }
          invalid={
            data.type.some((typ) => dupliViewType.includes(typ)) ? true : false
          }
          current={data.progress === "current"}
          label={data.label}
          description={data.description}
          className={ProgressActive === data.label ? "active-pro" : ""}
        />
      );
    });
  };

  const showLeftTiles = () => {
    return showleftTiles.map((tiles) => {
      return (
        tiles.type.some((typ) => viewType.includes(typ)) && (
          <Tile>
            <h3>
              {tiles.value} {tiles.icon}
            </h3>
            <h5>{tiles.title}</h5>
          </Tile>
        )
      );
    });
  };

  const showStorySize = () => {
    if (Object.entries(getDevData("Story Size by Sprint")).length > 0) {
      const res = getDevData("Story Size by Sprint");
      const response = {
        label: "",
        dataType: "horizontal-bar",
        translateValue: "0px",
        type: res.type !== undefined ? res.type : ["Product", "Planning"],
        data: {
          chartData: res.data,
          chartOptions: {
            title: "Story Size by Sprint",
            axes: {
              left: {
                stacked: true,
                mapsTo: "value",
                title: "No.of Stories",
                ...(res.average !== undefined && {
                  thresholds: [
                    {
                      value: res.average,
                      label: "Avg Stories",
                      fillColor: "#6fdc8c",
                    },
                  ],
                }),
              },
              bottom: {
                title: chartTitle,
                mapsTo: "key",
                scaleType: "labels",
                ...(res.Highlights !== undefined && {
                  highlights: {
                    highlightStartMapsTo: "startHighlight",
                    highlightEndMapsTo: "endHighlight",
                    labelMapsTo: "label",
                    data: [
                      {
                        startHighlight: res.Highlights.from,
                        label: "Custom formatter",
                        endHighlight: res.Highlights.to,
                      },
                    ],
                  },
                }),
              },
            },
            color: {
              scale: {
                Small: "#009d9a",
                Medium: "#007d79",
                Large: "#005d5d",
                XL: "#004144",
              },
            },
            toolbar: {
              enabled: false,
            },
            height: "300px",
          },
        },
      };

      return response;
    } else {
      return chartSkeleton("line", "Story Size by Sprint", [
        "Development",
        "Payment",
      ]);
    }
  };

  const clickViewAll = () => {
    if (viewType === "All") {
      setViewType(dupliViewType);
    } else {
      setViewType("All");
    }
    setProgressActive("");
  };

  return (
    <>
      <Row>
        <Column lg={6} md={8} sm={4}>
          <h4 className="h4-title">
            {props.title !== undefined ? props.title.ProjectName : null}
          </h4>
          <div className="inference">
            <WarningFilled fill="#DA1E28" /> Inference:{" "}
            <span>{showInference !== "" ? showInference : null}</span>
          </div>
        </Column>
        <Column lg={2} md={2} sm={1}>
          <RadioButtonGroup
            className="radio-grp"
            defaultSelected="Sprint"
            legendText=""
            name="radio-button-group"
            valueSelected="Sprint"
            labelPosition="right"
            orientation="horizontal"
            onChange={(e) => onRadioChange(e)}
          >
            <RadioButton id="radio-1" labelText="Sprint" value="Sprint" />
            <RadioButton id="radio-2" labelText="Release" value="Release" />
          </RadioButtonGroup>
        </Column>
      </Row>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Button
            className="viewAll-btn"
            kind="tertiary"
            onClick={() => clickViewAll()}
          >
            {viewType === "All" ? "View Anamoly" : "View All"}
          </Button>
          {progressData.length > 0 ? (
            <ProgressIndicator
              onChange={(e) => onChangeProgress(e)}
              spaceEqually={true}
              className="progress-indic"
            >
              {showProgressIndicator()}
            </ProgressIndicator>
          ) : (
            <ProgressIndicatorSkeleton />
          )}
        </Column>
      </Row>
      <Row className="sec-row">
        <Column lg={2} md={2} sm={2}>
          <div className="left-tiles">{showLeftTiles()}</div>
        </Column>
        <Column lg={14} md={8} sm={4}>
          <Row>{showDynamicCharts(devInsightsData)}</Row>
        </Column>
      </Row>
    </>
  );
};

export default AlMDevInsights;
