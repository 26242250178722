import { ProgressIndicator, ProgressStep } from "@carbon/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const BizopsProgress = (props) => {
  const { t } = useTranslation();
  const [progressLine, ProgressData] = useState([
    {
      day: [],
      progress: "complete",
      title: "Start",
      label: [],
    },
    {
      day: [],
      progress: "complete",
      title: "User Login",
      label: [
        "Login Success: 90%",
        "Direct Customer: 60%",
        "Click Through: 40%",
      ],
    },
    {
      day: ["06"],
      progress: "complete",
      title: "User Reviews Product",
      label: ["Conversion: 30%", "Customer Satisfaction: 60%"],
    },
    {
      day: [],
      progress: "complete",
      title: "User Adds Product To Cart",
      label: ["Cart Abandonment: 15%"],
    },
    {
      day: ["04"],
      progress: "complete",
      title: "User Checks Out The Product",
      label: ["Conversion: 40%"],
    },
    {
      day: ["01", "02", "03"],
      progress: "complete",
      title: "User Does Payment",
      label: ["Payment Success: 60%", "Payment Reject: 40%"],
    },
    {
      day: ["05"],
      progress: "invalid",
      title: "Confirmation Of Sales",
      label: [
        "Order Conversion: 40%",
        "Order Fulfilment: 80%",
        "Order Abandonment: 15%",
        "Customer Satisfaction: 60%",
      ],
    },
    {
      day: [],
      progress: "complete",
      title: "Initiate Shipment",
      label: ["Conversion: 40%"],
    },
    {
      day: [],
      progress: "complete",
      title: "End",
      label: [],
    },
  ]);

  const DrawProgress = () => {
    return progressLine.map((flow) => {
      return (
        <ProgressStep
          label={`${flow.title}`}
          secondaryLabel={flow.label.map((label) => (
            <span>{label}</span>
          ))}
          complete={!flow.day.includes(props.campaignDay)}
          invalid={flow.day.includes(props.campaignDay)}
          current={flow.progress === "current"}
        />
      );
    });
  };

  return (
    <>
      <div className="some-container" style={{ marginTop: "0" }}>
        <ProgressIndicator
          vertical
          spaceEqually
          onChange={(e) => {
            props.enableTopology(e);
          }}
        >
          {DrawProgress()}
        </ProgressIndicator>
      </div>
    </>
  );
};

export default BizopsProgress;
