import { getEATrend } from "./staticData/EmissionTrend";

export const ten_conv = {
  shell: "Shell",
  Energy_Australia: "Energy Australia",
};

export const userSystemPer = {
  "C1-CCnB": 19,
  "MuleSoft On-Prem": 9,
  Maximo: 1,
  MDMS: 2,
};

export const units = [
  {
    text: "Kg",
    id: "kgco2",
  },
  {
    text: "MT",
    id: "mtco2",
  },
];

export const getUnitDisplay = (unit) => {
  let display = "";
  units.forEach((uni) => {
    if (uni.id === unit) {
      display = uni;
    }
  });
  return display;
};

export const laptops = {
  shell: "HP",
  Energy_Australia: "Lenovo ThinkPad",
};

export const hq = {
  shell: "London, United Kingdom",
  Energy_Australia: "Australia",
};

export const conversion = {
  msTeams: "MS Teams",
  slack: "Slack",
  webex: "Webex",
};

export const inferences1Mt = {
  miles: 2_564,
  galoons: 98.2,
  phones: 121_643,
};

export const tools = [
  "Cisco WebEx",
  "MS Teams",
  "Slack",
  "Zoom",
  "Skype",
  "Google Meet",
];

export const colors = [
  "#6fdc8c",
  "#42be65",
  "#24a148",
  "#198038",
  "#0e6027",
  "#044317",
  "#044317",
  "#08bdba",
  "#009d9a",
  "#007d79",
  "#005d5d",
  "#004144",
  "#0072c3",
  "#0f62fe",
  "#0043ce",
  "#002d9c"
];

export const itAppColors = {
  Emails: "#24a148",
  Communication: "#24a148",
  "Service Now": "#198038",
  ITSM: "#198038",
  Laptop: "#42be65",
  Devices: "#42be65",
  "MS Teams": "#6fdc8c",
  Collaboration: "#6fdc8c",
};

// Application colors
export const appColors = {
  "Blueprint BUSINESS WAREHOUSE": "#001141",
  "Chemicals Customer Portal": "#001d6c",
  "GSAP ERP CH": "#002d9c",
  "ENERGY COMPONENTS": "#0043ce",
  "GSAP ERP DS": "#0f62fe",
};

// For Overview Dashboards
export const modifyApiData = (data, unit) => {
  const applications = [];
  const applicationSpecific = {
    application: [],
  };
  const category = [
    {
      group: "Communication",
      value: 0,
    },
    {
      group: "Devices",
      value: 0,
    },
    {
      group: "Collaboration",
      value: 0,
    },
    {
      group: "ITSM",
      value: 0,
    },
  ];
  const tickets = {};
  const appTotal = {
    application: 0,
  };
  try {
    data.forEach((element) => {
      const appName = element["name"];
      const appEmission = Number(element["totalEmission"][unit]);
      applications.push(appName);
      tickets[appName] = element["totalTicketsInApp"];
      appTotal["application"] += appEmission;
      appTotal[element["name"]] = appEmission;
      applicationSpecific["application"].push({
        group: appName,
        value: appEmission,
      });
      const email = Number(element["emailEmission"][unit]);
      const collab = Number(element["emissionFromAllCommTools"][unit]);
      const snow = Number(element["serviceNowEmission"][unit]);
      const device = Number(element["laptopEmission"][unit]);

      category[0]["value"] = category[0]["value"] + email;
      category[1]["value"] = category[1]["value"] + device;
      category[2]["value"] = category[2]["value"] + collab;
      category[3]["value"] = category[3]["value"] + snow;
      applicationSpecific[appName] = [
        {
          group: "Communication",
          value: email,
        },
        {
          group: "Collaboration",
          value: collab,
        },
        {
          group: "ITSM",
          value: snow,
        },
        {
          group: "Devices",
          value: device,
        },
      ];
    });
    return { applicationSpecific, appTotal, applications, tickets, category };
  } catch (error) {
    return "Error";
  }
};

export const getAppsFromItops = (data) => {
  const applications = [];
  data.forEach((obj) => {
    applications.push(obj.name);
  });
  return { applications };
};

// For ItOps all applications
export const itOpsData = (data, unit) => {
  const multipler = unit === "mtco2" ? 1 : 1000
  let emails_app = [],
    service_now = [],
    webex_app = [],
    laptop_app = [],
    applications = [];
  data.forEach((obj) => {
    applications.push(obj.name);
    emails_app.push({
      group: obj["name"],
      key: "2022",
      value: Number(obj["emailEmission"][unit]),
    });
    service_now.push({
      group: obj["name"],
      key: "2022",
      value: Number(obj["serviceNowEmission"][unit]),
    });
    laptop_app.push({
      group: obj["name"],
      key: "2022",
      value: Number(obj["laptopEmission"][unit]),
    });
    Object.entries(obj["commToolsSplitUp"]).forEach(([tool, tooldetails]) => {
      webex_app.push({
        group: conversion[tool] || tool,
        key: obj["name"],
        value: tooldetails * multipler,
      });
    });
  });
  emails_app = emails_app.sort((a, b) => (a.group > b.group ? 1 : -1));
  service_now = service_now.sort((a, b) => (a.group > b.group ? 1 : -1));
  webex_app = webex_app.sort((a, b) => (a.key > b.key ? 1 : -1));
  laptop_app = laptop_app.sort((a, b) => (a.group > b.group ? 1 : -1));
  return { emails_app, service_now, webex_app, laptop_app, applications };
};

// For ITops single app
export const appBasedIt = (data) => {
  const emails_app = [],
    service_now = [],
    webex_app = [],
    laptop_app = [];
  if (Object.keys(data?.incidents).length > 0) {
    Object.entries(data.incidents).forEach(([key, value]) => {
      emails_app.push({
        group: key,
        key: "2022",
        value: Number(value["emailEmissionData"]["success"]) * 1000,
      });
      service_now.push({
        group: key,
        key: "2022",
        value: Number(value["servicenowEmissionData"]["success"]) * 1000,
      });
      laptop_app.push({
        group: key,
        key: "2022",
        value: Number(value["laptopEmissionData"]["success"]) * 1000,
      });
      Object.entries(value["commTool"]).forEach(([tool, tooldetails]) => {
        webex_app.push({
          group: conversion[tool] || tool,
          key: key,
          value: Number(tooldetails["toolEmission"]) * 1000,
        });
      });
    });
    return { emails_app, service_now, webex_app, laptop_app };
  } else {
    return "Error";
  }
};

// Line graph -emission trend overview
export const graph2 = (application, appTotal, year, displayUnit) => {
  //const tenant = sessionStorage.getItem("tenant");
  let growthThredHold, optThreshold;
  let data;
  data = getEATrend(appTotal, year).filter((data) => {
    if (data.key === year) {
      if (data.group === "Platinum") growthThredHold = data.value;
      if (data.group === "Optimize") optThreshold = data.value;
    }
    return data.key <= year;
  });

  return {
    label: "",
    dataType: "line",
    data: {
      chartData: data,
      chartOptions: {
        title: `Carbon Emission Trend: ${application || "Summary"}`,
        axes: {
          bottom: {
            title: "Year",
            mapsTo: "key",
            scaleType: "labels",
          },
          left: {
            mapsTo: "value",
            title: `CO2 emission(${displayUnit})`,
            scaleType: "linear",
            thresholds: [
              {
                value: growthThredHold,
                label: "Platinum",
                fillColor: "#C77074",
              },
              {
                value: optThreshold,
                label: "Optimize",
                fillColor: "#59D0CC",
              },
            ],
          },
        },
        color: {
          scale: {
            Platinum: "#4a4c4e",
            Gold: "#fddc69",
            "Silver/Bronze": "#deab78",
            Optimize: "#007d79",
          },
        },
        height: "300px",
        legend: {
          enabled: true,
        },
        tooltip:{
          showTotal: false
        },
        grid: {
          x: {
            enabled: false,
          },
          y: {
            enabled: false,
          },
        },
      },
    },
  };
};

// Actual Pie chart in overview
export const showPieChart = (
  chartData,
  application,
  colors,
  tickets,
  height,
  displayUnit,
  alignment = "center",
) => {
  return {
    label: "",
    dataType: "donut",
    data: {
      chartData,
      chartOptions: {
        title: `${application || "Summary"}: ${tickets} Tickets`,
        resizable: true,
        donut: {
          center: {
            label: displayUnit,
            numberFormatter:
              displayUnit === "mtCO₂eq"
                ? (value) => value.toFixed(3)
                : (value) => value.toFixed(2),
          },
          alignment,
        },
        pie: {
          labels: {
            enabled: false,
          },
        },
        color: {
          scale: {
            ...colors,
          },
        },
        height,
      },
    },
  };
};

// Actual Pie chart in overview
export const showLineChart = (
  chartData,
  application,
  colors,
  tickets,
  height,
  displayUnit,
  alignment = "center",
) => {
  const newCol = {}
  Object.keys(colors).forEach(app => {
    newCol[app] = "#24a148" 
  })
  const sortedData = chartData.sort((a, b) => (a.group > b.group ? 1 : -1));
  return {
    label: "",
    dataType: "simpleBar",
    data: {
      chartData: sortedData,
      chartOptions: {
        title: `${application || "Summary"}: ${tickets} Tickets`,
        axes: {
          left: {
            mapsTo: "value",
            title: `CO2 Emission (${displayUnit})`,
          },
          bottom: {
            title: "Application",
            mapsTo: "group",
            scaleType: "labels",
          },
        },
        legend:{
          enabled: false
        },
        color: {
          scale: {
            ...newCol,
          },
        },
        height,
      },
    },
  };
};
