import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Modal, Row, Tile } from "@carbon/react";
import { WatsonHealthAiStatusComplete } from "@carbon/react/icons/index";
import { useQuery } from "@tanstack/react-query";
import ChartContainer from "Carbon-Charts/ChartContainer";
import DataTable from "Carbon-Components/DataTable";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import TopologyGraph from "./topologyGraph";
import TooltipCarbon from "Carbon-Components/Tooltip";
import IncidentModal from "./IncidentModal";
import OpsProgress from "./progressIndicator";
import TopologyGraph from "./topologyGraph";
import Summarize from "./vertexSummary";
import "./vs.scss";

const VSWorkflowView = () => {
    const { workflow } = useParams();
    const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
    const dateToday = new Date();
    const timestamp = Date.parse(dateToday);
    const history = useHistory();
    const [valueStreamData, setValueStream] = useState();
    const [showTopology, setShowTopology] = useState(false);
    const [showSummaryModal, setShowSummaryMd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showApps, setShowApps] = useState(false);
    const [bridgeId, sendBridgeId] = useState(null);
    const [showResolution, setShowResolution] = useState(false);
    const [dateChange, onDateChange] = useState(() => selecteDates ? selecteDates.time : {
        to: timestamp,
        windowSize: 5 * 60 * 1000
    });
    const { data: valueStream } = useQuery(["valueStream"]);


    useEffect(() => {
        if (valueStream !== undefined && valueStream.data !== undefined) {
            const response = valueStream.data.valueStream;
            // const filterWF = response.data.filter((fil) => fil["title"] === workflow)
            const filterWF = response[0].data.filter((fil) => fil["title"] === workflow)
            setValueStream(filterWF)
        }
    }, [valueStream])

    const getRows = (row) => {
        return valueStreamData.map((vs) => {
            return vs.workflowDetails.map((workflow) => {
                const row1 = workflow[row] !== undefined && workflow[row].map((row1, index) => {
                    return row === "Row2" ? <>
                        {!["chart", "table"].includes(row1.type) ? <Column lg={4} md={4} sm={4} className="journey-sg">
                            <Tile className="structred-list-table-view">
                                <h6 className="cust-journey">Customer Journey Steps</h6>
                                <OpsProgress enableTopology={onClickShowTopology} />
                            </Tile>
                        </Column> : null}
                    </> : <Column lg={2} md={2} sm={2} className="no--right__padding"> {getElement(row1, index)} </Column>
                })
                return <>
                    {row1}
                </>
            })
        })
    }

    const getColumnRow = (row) => {
        return valueStreamData.map((vs) => {
            return vs.workflowDetails.map((workflow) => {
                const row1 = workflow[row] !== undefined && workflow[row].map((row1, index) => {
                    return row === "Row2" ? <>
                        {"chart" === row1.type ?
                            getElement(row1, index)
                            : null}
                    </> : null
                })
                return <>
                    {row1}
                </>
            })
        })
    }

    const getColumnRow1 = (row) => {
        return valueStreamData.map((vs) => {
            return vs.workflowDetails.map((workflow) => {
                const row1 = workflow[row] !== undefined && workflow[row].map((row1) => {
                    return row === "Row2" ? <>
                        {"table" === row1.type ?
                            <DataTable
                                rowData={getThirdRows("rows")}
                                headerData={getThirdRows("header")}
                                title=""
                                getRowCellData={getRowCellData}
                                getTableHeader={(header) => header}
                                table_particular_actions={true}
                                actionsNeeded={false}
                                getActions={getTableActions}
                            ></DataTable>
                            : null}
                    </> : null
                })
                return <>
                    {row1}
                </>
            })
        })
    }

    const showApplication = () => {
        setShowApps(true)
    }

    const onClickShowTopology = () => {
        setShowTopology(true);
    };

    const getElement = (ele, index) => {
        if (ele.type === "tile") {
            return <Tile className="first-row-tile" onClick={ele.name === 'No. of Applications' ? () => showApplication() : null}>
                <h6>{ele.name}</h6>
                {Array.isArray(ele.value) ? ele.value.map((el) => {
                    return <Column lg={2} md={2} sm={2}> <div className="interact">
                        <p>
                            Total <span>{el.Total}</span>
                        </p>
                        <div className="sec-4">
                            <h5>
                                Success <span>{el.Success}</span>
                            </h5>
                        </div>
                        <div className="sec-5">
                            <h5>
                                Fail <span>{el.Fail}</span>
                            </h5>
                        </div>
                    </div>
                    </Column>
                }) : <span className={`tile-value${index}`}>{ele.value}</span>}
            </Tile>
        } else if (ele.type === "chart") {
            return <Column lg={ele.col} md={8} sm={4} className="chart-col">{getCharts(ele.value, ele)}</Column>
        }
    }

    const getCharts = (data, ele) => {
        if (ele.name === "Interaction Volume Over Time") {
            return <ChartContainer data={VolumeChart(data, ele)} />
        } else if (ele.name === "API Interactions") {
            return <ChartContainer data={interactionChart(data, ele)} />

        } else if (ele.name === "Error") {
            return <ChartContainer data={getErrorResponse(data, ele)} />
        }
    };

    const getErrorResponse = (data, ele) => {
        return {
            label: "",
            dataType: "bar",
            data: {
                chartData: data,
                chartOptions: {
                    title: ele.name,
                    color: {
                        scale: {
                            errors: "#da1e28",
                        },
                    },
                    axes: {
                        left: {
                            mapsTo: "value",
                            "title": "Count"
                        },
                        bottom: {
                            "title": "Time",
                            mapsTo: "date",
                            scaleType: "labels",
                            ticks: {
                                rotation: "always",
                            },
                        },
                    },
                    bars: {
                        width: 5,
                    },
                    height: "250px",
                },
            },
        };
    };

    const VolumeChart = (data, ele) => {
        return {
            label: "",
            dataType: "area",
            data: {
                chartData: data,
                chartOptions: {
                    title: ele.name,
                    axes: {
                        bottom: {
                            mapsTo: "key",
                            scaleType: "labels",
                            title: "Time",
                        },
                        left: {
                            mapsTo: "value",
                            scaleType: "linear",
                        },
                    },
                    curve: "curveNatural",
                    height: "250px",
                    color: {
                        scale: {
                            Success: "#198038",
                            Fail: "#fa4d56",
                        },
                    },
                },
            },
        };
    };

    const interactionChart = (data, ele) => {
        return {
            label: "",
            dataType: "donut",
            data: {
                chartData: data,
                chartOptions: {
                    title: ele.name,
                    resizable: true,
                    donut: {
                        alignment: "center",
                        center: {
                            label: "",
                        },
                    },
                    color: {
                        scale: {
                            Success: "#78a9ff",
                            Failed: "#ff8389",
                        },
                    },
                    toolbar: {
                        enabled: false,
                    },
                    height: "250px"
                },
            },
        };
    }

    const getThirdRows = (id) => {
        let newarr = [];
        valueStreamData.forEach((vs) => {
            vs.workflowDetails.forEach((workflow) => {
                workflow["Row2"] !== undefined && workflow["Row2"].forEach((flow) => {
                    if (flow.type === "table") {
                        flow.data.forEach((data) => {
                            Object.entries(data).forEach(([key, value]) => {
                                if (id === key) {
                                    newarr = value
                                }
                            })
                        })
                    }
                })
            })
        })
        return newarr
    }

    const onSummaryModal = (details, rows) => {
        setShowSummaryMd(true);
        const rowData = getThirdRows("rows");
        const filterDa = rowData.filter((fil) => details === fil["Bridge_Id"])
        sendBridgeId(filterDa[0]);
    };

    const onIncidnetIdClick = (details, flow) => {
        let key = "";
        if (flow === "resolution") {
            details.cells.forEach((cel) => {
                if (cel.id.includes("Incident_Id")) {
                    key = cel.value
                }
            })
        } else {
            key = details;
        }
        const rowData = getThirdRows("rows");
        const filterDa = rowData.filter((fil) => key === fil["Incident_Id"]);
        sendBridgeId({ ...filterDa[0], flow: flow });
        setShowModal(true);
    };

    const getRowCellData = (id, data, rows) => {
        // if (id.includes("label")) {
        //     return `/${data}`
        //   }
        //   if (id.includes("technologies")) {
        //     return data !== undefined ? data.map((tech) => {
        //       return tech
        //     }) : null
        //   }
        if (id.includes("Bridge_Id")) {
            return <div className="bridge-sum"
                onClick={() => onSummaryModal(data, rows)}>
                {data}
            </div>
        }
        if (id.includes("Incident_Id")) {
            return <div className="bridge-sum"
                onClick={() => onIncidnetIdClick(data)}>
                {data}
            </div>
        }
        if (id.includes(":Ticket Title")) {
            try {
                if (data.length <= 50) {
                    return data;
                } else {
                    let temp = data.substring(0, 50) + "...";
                    return (
                        <div className="desc-tooltip">
                            <TooltipCarbon description={data}>{temp}</TooltipCarbon>
                        </div>
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (id.includes(":Description")) {
            try {
                if (data.length <= 50) {
                    return data;
                } else {
                    let temp = data.substring(0, 50) + "...";
                    return (
                        <div className="desc-tooltip">
                            <TooltipCarbon description={data}>{temp}</TooltipCarbon>
                        </div>
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
        return data
    }

    const getTableActions = (row, cell) => {
        return (
            <ul className="action-icon">
                <li>
                    <a
                        // className={
                        //     eventSummaryDetails.status === "Open"
                        //         ? "active"
                        //         : "disable"
                        // }
                        href="https://ibm.webex.com/meet/hpramod"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt="webex"
                            src={
                                require(`../../../../Assets/External/WebEx.png`)
                            }
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="https://ibm-test.enterprise.slack.com/archives/C081XC8KZU4"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt="slack"
                            src={
                                require(`../../../../Assets/External/slack.svg`)
                                    .default
                            }
                        />
                    </a>
                </li>
                <li
                    title="Resolution"
                    onClick={() =>
                        onIncidnetIdClick(row, "resolution")
                    }
                >
                    <WatsonHealthAiStatusComplete size={18} />
                </li>
            </ul>
        );
    };

    // const getApplication = () => {
    //     const title = sessionStorage.getItem("portfolioTitle")
    //     const port = portfolioDataJSON.portfolioData.filter((data) => data.title === title);
    //     return <ul className="app-cnt">{port[0].child.map((app) =>
    //         <li>{app.name}</li>
    //     )}</ul>;
    // }

    const redirectToIne = (page, node) => {
        if (page === true) {
            // showInferencePage(true)
            history.push({
                pathname: `/workflowViewDetails/${workflow}`,
                state: { data: node, datechange: dateChange },
            });
        }
    };

    const getApplication = () => {
        let newarr = [];
        valueStreamData.forEach((vs) => {
            vs.workflowDetails.forEach((workflow) => {
                workflow["Row1"] !== undefined && workflow["Row1"].forEach((row3) => {
                    if (row3.data !== undefined) {
                        newarr = row3.data
                    }
                })
            })
        })
        return <ul className="app-cnt">{newarr.map((app) =>
            <li>{app.name}</li>
        )}</ul>
    }

    const ShowSummaryMd = () => {
        setShowSummaryMd(false);
    };
    return (
        <FlexGrid className="workflow-view">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">
                            IT Value Stream Observability
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            {workflow}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={4} md={4} sm={4} className="no--right__padding">
                    <Tile className="first-row ">
                        <h3 className="workflow-title">
                            {sessionStorage.getItem("portfolioTitle")}
                        </h3>
                        {/* <h5>EKYC Activation User Journey</h5> */}
                    </Tile>
                </Column>
                {valueStreamData !== undefined ? getRows("Row1") : null}
            </Row>
            <Row>
                {valueStreamData !== undefined ?
                    <>{getRows("Row2")}
                        <Column lg={12} md={8} sm={4}>
                            <Row>
                                {getColumnRow("Row2")}
                            </Row>
                            <Row>
                                <Column lg={16} md={10} sm={4} className="no--right__padding">
                                    {getColumnRow1("Row2")}
                                </Column>
                            </Row>
                        </Column>
                    </>
                    : null}
            </Row>
            {showModal && (
                <IncidentModal setShowModal={setShowModal} ticketDetails={bridgeId} />
            )}
            {showSummaryModal && (
                <Summarize ShowSummaryMd={ShowSummaryMd} bridgeDetails={bridgeId} />
            )}

            {showTopology && (
                <Modal
                    open
                    size="lg"
                    passiveModal
                    isFullWidth
                    onRequestClose={() => setShowTopology(false)}
                    modalHeading="Topology View"
                    modalLabel="Application Topology View"
                    className="fss-topology"
                >
                    <TopologyGraph redirectToIne={redirectToIne} dateChange={dateChange} />
                </Modal>
            )}

            {showApps ? <Modal
                open
                size="lg"
                passiveModal
                isFullWidth
                onRequestClose={() => setShowApps(false)}
                modalHeading="Applications"
                modalLabel=""
                className="fss-topology"
            >
                {getApplication()}
            </Modal> : null}
        </FlexGrid>
    )
}

export default VSWorkflowView;