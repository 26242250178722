import ChartContainer from 'Carbon-Charts/ChartContainer';
import { HP_440_G7_CE, accumulatedLegend, yearlyLegend } from 'Constant';
import React from 'react';
import apps from "./newData.json";

const YearWiseChart = () => {

  const transformData1 = (data) => {
    let inputData = [...data]

    // Create an object to store the transformed data
    const savings = {} // to store carbon saving month wise - line on 1 and 2 charts
    const actualAccumulatedHrs = {} 

  for (const item of inputData) {
    const completionDate = new Date(item["Completion (MMM-YY)"]);
    const annualBenefit = item["Monthly OT Effort (Hrs.)"];

    const targetDate = new Date('2023-12-31');

    const remainingMonths = (targetDate.getFullYear() - completionDate.getFullYear()) * 12 + (12 - completionDate.getMonth());

    const currentMonthBenifit = item["Monthly OT Effort (Hrs.)"] / 2

    if(item["Benefit Category"] === "OT-ES" || remainingMonths === 1){
        const key2 = `${completionDate.getFullYear()}`;
        const key3 = `yearly-${completionDate.getFullYear()}`

        if (!savings[key2]) {
            savings[key2] = {
                group: "Carbon Savings(kgCO₂eq)",
                date: key2,
                carbonsaving: 0,
            };
        }
        savings[key2].carbonsaving += annualBenefit * HP_440_G7_CE;

        if (!actualAccumulatedHrs[key3]) {
            actualAccumulatedHrs[key3] = {
                group: yearlyLegend,
                date: key2,
                value: 0,
            };
        }
        actualAccumulatedHrs[key3].value += annualBenefit
    }
        
    else if(remainingMonths > 1){
    const monthlyBenefit = annualBenefit;

    const monthLimit =  12
    for(let j = completionDate.getFullYear(); j <= targetDate.getFullYear() ; j++){
      for (let i = j === completionDate.getFullYear() ? completionDate.getMonth() : 0; i < monthLimit; i++) {

        const isCurrentMonth = i === completionDate.getMonth() && j === completionDate.getFullYear()
        const benifit = isCurrentMonth ? currentMonthBenifit : monthlyBenefit 

        // Calculate and distribute savings for each completed month
        const key2 = `${j}`;
        if (!savings[key2]) {
            savings[key2] = {
                group: "Carbon Savings(kgCO₂eq)",
                date: key2,
                carbonsaving: 0,
            };
        }

        savings[key2].carbonsaving += benifit * HP_440_G7_CE;

        const key3 = isCurrentMonth ? `yearly-${j}` : `accumulated-${j}`
            if (!actualAccumulatedHrs[key3]) {
                actualAccumulatedHrs[key3] = {
                    group: isCurrentMonth ? yearlyLegend : accumulatedLegend,
                    date: key2,
                    value: 0,
                };
            }
        actualAccumulatedHrs[key3].value += benifit
      }
    }
    }
    }

    // calculate emission
    const savingsArray = Object.values(savings)

    // calculate accumulated monthly
    const accMonthlyArray = Object.values(actualAccumulatedHrs) 

    return [...savingsArray, ...accMonthlyArray]
  };

  const data = transformData1(apps)

  const options = {
    title: 'YoY Effort Saving and Reduction in CO₂',
    color:{
      scale: {
          'Carbon Savings(kgCO₂eq)' : "#198038"
      }
    },
    axes: {
      bottom: {
        title: 'Year',
        mapsTo: 'date',
        scaleType: 'labels',
      },
      left: {
        mapsTo: 'value',
        title: 'Annual Benefit (Hrs)',
        scaleType: 'linear',
        stacked: true
      },
      right: {
        mapsTo: 'carbonsaving',
        scaleType: 'linear',
        title: 'Reduction in Carbon Emission (kgCO₂eq)',
        correspondingDatasets: [
          'Carbon Savings(kgCO₂eq)'
        ]
      },
    },
    comboChartTypes: [
        {
          type: 'line',
          correspondingDatasets: ["Carbon Savings(kgCO₂eq)"]
        },
        {
          type: 'stacked-bar',
          correspondingDatasets: [yearlyLegend, accumulatedLegend]
        }
      ],
    height: '350px',
    legend: {
      truncation: {
        type: "none"
      },
    },
    curve: 'curveMonotoneX'
}

  return (
    <ChartContainer
        data={{
            dataType: "combo",
            data:{
                chartData: data,
                chartOptions: options 
            }
        }}
    />
  )
}

export default YearWiseChart