/** @format */

import {
    Column,
    Grid,
    Tile,
} from "@carbon/react";
import React, { useEffect } from "react";
// import "./../css/_chart.scss";

const eventDetailHeaderData = [
    {
        "key": "eventID",
        "header": "Event ID"
    },
    {
        "key": "eventName",
        "header": "Event Name"
    },
    {
        "key": "eventExecutionTime",
        "header": "Event Execution Time"
    },
    {
        "key": "eventStatus",
        "header": "Event Status"
    },
    {
        "key": "eventReason",
        "header": "Event Reason"
    }
];

const automationDetailHeaderData = [
    {
        "key": "automationName",
        "header": "Automation Name"
    },
    {
        "key": "cloudwatchAlarm",
        "header": "Cloudwatch Alarm"
    },
    {
        "key": "automationExSTime",
        "header": "Automation execution Start time"
    },
    {
        "key": "automationExETime",
        "header": "Automation execution End time"
    },
    {
        "key": "automationStatus",
        "header": "Automation Status"
    }
];


const EventBridgeDetails = (props) => {

    useEffect(() => {
    }, []);

    const getTextTile = (tile) => {
        return (
            <div className="common-top-margin">
                <h5 className="text-tile">{tile.title}</h5><p>{tile.value}</p>
            </div>
        );
    }

    const getReadableData = (key, data) => {
        if (key.includes("eventExecutionTime") || key.includes("automationExETime") || key.includes("automationExSTime")) {
            return String(new Date(data).toLocaleString());
        } else { return data; }
    }

    const getEventDetails = () => {
        let details = [];
        eventDetailHeaderData.forEach((headerObj) => {
            let obj = {};
            obj.title = headerObj.header;
            obj.value = getReadableData(headerObj.key, props.details[headerObj.key]);
            details.push(getTextTile(obj));
        })
        return details;
    }

    const getAutomationDetails = () => {
        let details = [];
        automationDetailHeaderData.forEach((headerObj) => {
            let obj = {};
            obj.title = headerObj.header;
            obj.value = getReadableData(headerObj.key, props.details[headerObj.key]);
            details.push(getTextTile(obj));
        })
        return details;
    }

    return (
        <div>
            <Grid>
                <Column lg={8}><Tile>{getEventDetails()}</Tile></Column>
                <Column lg={8}><Tile>{getAutomationDetails()}</Tile></Column>
            </Grid>
        </div>
    );
};

export default EventBridgeDetails;
