/** @format */

import {
  Column,
  ErrorBoundary,
  Grid,
  SkeletonPlaceholder,
  Tooltip
} from "@carbon/react";
import { Information } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import CarbonDataTable from "Carbon-Components/DataTable";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import TraceDetails from "./TraceDetails";

import { getTopologyDetailsData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import TraceSumm from "./TraceSummaryJS.json";

const tableHeaderData = [
  {
    "key": "id",
    "header": "Trace ID"
  },
  {
    "key": "Duration",
    "header": "Duration (s)"
  },
  {
    "key": "StartTime",
    "header": "Start Time"
  },
  {
    "key": "ResponseTime",
    "header": "Response Time"
  },
  {
    "key": "HasFault",
    "header": "Has Fault"
  },
  {
    "key": "HasError",
    "header": "Has Error"
  },
  {
    "key": "HasThrottle",
    "header": "Has Throttle"
  },
  {
    "key": "IsPartial",
    "header": "Is Partial"
  },
  {
    "key": "Revision",
    "header": "Revision"
  }
];

const TraceSummary = (props) => {
  const { keycloak } = useKeycloak();

  const [isAPIDataLoading, setIsAPIDataLoading] = useState(false);
  const [errMessage, setErrorMessage] = useState("");

  const [traceResponse, setTraceResponse] = useState(TraceSumm.data);
  const [traceSummary, setTraceSummary] = useState(TraceSumm.data.trace_summary);
  const [showPopUP, setShowPopUP] = useState(false);
  const [traceRow, setTraceRow] = useState();
  const context = useContext(Context)

  useEffect(() => {
    getTopologyChartJSON();
  }, []);

  const updateRowID = (traceList) => {
    let updatedArray = [];
    traceList.forEach((traceObj) => {
      traceObj.id = traceObj.Id;
      updatedArray.push(traceObj)
    })
    return updatedArray;
  }

  const updateResponse = (response) => {
    if (response.err) {
      setErrorMessage(response.message);
    } else {
      if (response.data.data) {
        setTraceResponse(response.data.data);
        if (response.data.data.trace_summary) {
          setTraceSummary(updateRowID(response.data.data.trace_summary));
        } else {
          setErrorMessage("Data is unavailable");
        }
      }
      else {
        setErrorMessage("Data is unavailable");
      }
    }
  }

  const getTopologyChartJSON = async () => {
    setIsAPIDataLoading(true);

    const response = await getTopologyDetailsData(keycloak.token, sessionStorage.getItem("componentARN"), sessionStorage.getItem("startTime"), sessionStorage.getItem("endTime"));
    AddUserActivity(keycloak, context, "FEATURE", "Trace Summary", response);

    setIsAPIDataLoading(false);
    if (response.status >= 400 || response === "Error") {
      setErrorMessage("Error while fetching data");
    } else {
      updateResponse(response);
    }
  };

  const getDetailsRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  }

  const onClickNode = (id) => {
    let row = getDetailsRow(traceSummary, id);
    setTraceRow(row);
    setShowPopUP(true);
    props.traceClick();
  }

  const getRowCellData = (id, data, row) => {
    if (id.includes("StartTime")) {
      return String(new Date(data).toLocaleString());
    }
    if (id.includes("id")) {
      return <div className="event-status" onClick={() => { onClickNode(row.id) }}>{data}</div>;
    }
    if (id.includes("HasError")) {
      if (!data && sessionStorage.getItem("hasAnomaly") === "Yes") {
        return <>{data ? "Yes" : "No"}<Tooltip align="bottom" label="This is a proactive anamoly, error may occur!">
          <button
            className="tooltip-button"
            type="button"
          >
            <Information />
          </button>
        </Tooltip></>;
      }
    }
    if (typeof data == "boolean") {
      return data ? "Yes" : "No";
    }

    return data;
  };

  const getTableHeader = (header) => {
    return header;
  };

  const getTableDescription = () => {
    return "Traces for " + sessionStorage.getItem("componentARN");
  }

  const createChartExplicit = () => {
    return (<>
      <Column lg={16} className="table-padding">
        {traceSummary && traceSummary.length > 0 ?
          <ErrorBoundary fallback={<ErrorWhileFetching />}>
            <CarbonDataTable
              rowData={traceSummary}
              headerData={tableHeaderData}
              title={props.title}
              getRowCellData={getRowCellData}
              getTableHeader={getTableHeader}
              description={getTableDescription()}
              table_particular_actions={false}
              actionsNeeded={false}
            />
          </ErrorBoundary> : "Data is unavailable!"}
      </Column>
    </>
    );
  };

  const getPopupData = () => {
    return (
      <ErrorBoundary fallback={<ErrorWhileFetching />}>
        <TraceDetails
          traceObj={traceRow}
          traceResponse={traceResponse}
        />
      </ErrorBoundary>
    );
  };

  const getLoading = () => {
    return isAPIDataLoading ? (
      <div className="skeleton__placeholder--table">
        <SkeletonPlaceholder />
      </div>
    ) : <ErrorWhileFetching errMessage={errMessage} />
  }

  return (
    <div className="container-multichart">
      {
        showPopUP && !props.goBack ? (
          getPopupData()
        ) : (
          isAPIDataLoading || errMessage ? getLoading() :
            <Grid>{createChartExplicit()}</Grid>
        )
      }
    </div>
  );
};


export default TraceSummary;
