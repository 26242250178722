import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import React, { useEffect, useState } from "react";
import "./JobObserv.scss";
import DashboardDetails from "./JobWorkflowView";

const DashboardDetailsIndex = (props) => {
    // const [attFlow, setAttFlow] = useState("");
    // const { t } = useTranslation();

    const [workflowDetails, setWorflowDetails] = useState([]);

    useEffect(() => {
        if (props?.location && props?.location?.state) {
            setWorflowDetails(props.location.state.data)
        }
    }, [props.location])


    //const workflowDetails = useSelector((state) => state.ATTReducer);
    const [details, setDetails] = useState([]);

    // useEffect(() => {
    //     if ( workflowDetails !== undefined && Object.entries(workflowDetails).length > 0) {
    //         setDetails(workflowDetails.Details)
    //     }
    // }, [workflowDetails])

    return (
        Object.entries(workflowDetails).length > 0 ?
            <FlexGrid className="att-dash">
                <Row>
                    <Column lg={12} md={8} sm={4}>
                            <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                                <BreadcrumbItem href="#/itOperations">
                                    Integrated Operations
                                </BreadcrumbItem>
                                {/* <BreadcrumbItem href="#/itOperations">
                                    Job Dashboard
                                </BreadcrumbItem> */}
                                <BreadcrumbItem isCurrentPage>
                                    {workflowDetails.dataProduct}
                                </BreadcrumbItem>
                            </Breadcrumb>
                    </Column>
                </Row>
                <DashboardDetails location={props.location} />
            </FlexGrid>
            : null
    )
}

export default DashboardDetailsIndex