import moment from "moment";

export const prepareInfraAlertsData = (response) => {
    let ticketedAlerts = 0, alertArray = [];
    let hits = response?.data?.hits?.hits || [];
    let totalAlerts = response?.data?.hits?.total?.value || 0;
    hits.forEach(function (element, index) {
        let alert = {};
        var incidentId = element._source.incident.id ? element._source.incident.id : "";
        let offset = moment(element?._source?.lastUpdateTime)?.utcOffset();
        alert['id'] = index;
        alert['incidentId'] = incidentId;
        alert['updatedOn'] = moment(element?._source?.incident?.lastUpdtTime).utcOffset(offset).format('DD-MMM-YY HH:mm');
        alert['alertName'] = element?._source?.alert?.name;
        alert['alertCount'] = element?._source?.alert?.count;
        alert['priority'] = element?._source?.incident?.priority ? element?._source?.incident?.priority : '';
        alert['errorInfo'] = element?._source?.source?.status;
        alert['incidentURL'] = element?._source?.incident?.url;
        alert['sysId'] = element?._source?.incident?.sysId;
        alert['client'] = element?._source?.metadata?.clientId;
        alert['sourceProvider'] = element?._source?.source?.provider;
        alert['sourceName'] = element?._source?.source?.name;
        alert['alertTime'] = element?._source?.alert.time;
        alert['sort'] = element?.sort;
        alert['createdAt'] = element?._source?.incident?.createdAt ? moment(element._source.incident.createdAt).utcOffset(offset).format('DD-MMM-YY HH:mm') : '-';
        ticketedAlerts = element?._source?.alert?.state === 'Ticketed' ? ticketedAlerts + 1 : ticketedAlerts;
        alertArray.push(alert);
    });
    let summaryData = { 'ticketedAlerts': ticketedAlerts, 'totalAlerts': totalAlerts };
    return { alertArray, summaryData };
}