/** @format */

import {
    ClickableTile,
    Column,
    TextArea,
    Tile,
  } from "@carbon/react";
  import React, { useEffect, useState } from "react";
  import { Launch } from "@carbon/react/icons/index"
  import DataUnAvailable from "Common-Modules/DataUnavailable";
  
  const Logs = (props) => {
    const [title, setTitle] = useState("");
    const [componentName, setComponentName] = useState();
    const [logGroupName, setLogGroupName] = useState();
    const [logStreamName, setLogStreamName] = useState();
    const [drillDown, setDrillDown] = useState();
    const [region, setRegion] = useState("");
    const [startEpoch, setStartEpoch] = useState("");
    const [endEpoch, setEndEpoch] = useState("");
  
    useEffect(() => {
      setFieldsData();
    }, []);
  
    const getTextBoxData = (titleText, value) => {
      let data = { title: "", value: "" };
      data.title = titleText;
      data.value = value;
      return data;
    }
  
    const setFieldsData = () => {
      setTitle(getTextBoxData("Title", "Logs Details"));
      if (props.data) {
        setComponentName(getTextBoxData("Component", props.data.component_name));
        setLogGroupName(getTextBoxData("Group", props.data.log_group_name));
        setLogStreamName(getTextBoxData("Stream", props.data.log_stream_name));
      }
      setDrillDown(getTextBoxData("Get more Logs", ""));
      setRegion(sessionStorage.getItem("region"));
  
      let startDate = new Date(props.startTime);
      let endTime = new Date(props.endTime);
  
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset() - 4)
      endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset())
  
      setStartEpoch(startDate.getTime());
      setEndEpoch(endTime.getTime());
    }
  
    const getTextTile = (tile) => {
      return (
        < Tile className="chart-container" >
          <h5 className="text-tile">{tile.title}</h5><p>{tile.value}</p>
        </Tile>
      );
    }
  
    const gotoMetricsAWSConsole = () => {
  
      let startDate = new Date(props.startTime);
      let endDate = new Date(props.endTime);
  
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset())
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset())
  
      let graph = {
        "view": "timeSeries",
        "stacked": false,
        "metrics": [
          [
            props.namespace, 
            props.metrics, 
            props.functionNameTitle,
            props.functionNameValue
          ]
        ],
        "stat": props.stat,
        "period": 300,
        "width": 1651,
        "height": 200,
        "start": startDate.toISOString(),
        "end": endDate.toISOString()
      }
  
      window.open(
        `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#metricsV2:graph=${JSON.stringify(graph)}`, "_blank"
      );
    }
  
    const getMetricsTile = () => {
      return (
        logGroupName && logStreamName ?
          < ClickableTile className="chart-container metric" onClick={() => gotoMetricsAWSConsole()}>
            <h5 className="text-tile">Metrics</h5><p><Launch fill="#0f62fe" /></p>
          </ClickableTile>
          : ""
      );
    }
  
    const gotoAWSConsole = () => {
      window.open(
        `https://console.aws.amazon.com/cloudwatch/home?region=${region}#logEventViewer:group=${logGroupName.value};stream=${logStreamName.value};start=${startEpoch};end=${endEpoch}`, "_blank"
      );
    }
  
    const getDrillDownTile = (tile) => {
      return (
        logGroupName && logStreamName ?
          < ClickableTile className="chart-container metric" onClick={() => gotoAWSConsole()}>
            <h5 className="text-tile">{tile.title}</h5><p><Launch fill="#0f62fe" /></p>
          </ClickableTile>
          : ""
      );
    }
  
    const getLogsText = () => {
      let logs = "";
      if (props.data && props.data.log_details) {
        if (props.data.log_details && props.data.log_details.events && props.data.log_details.events.length > 0) {
          props.data.log_details.events.map((eventObj) => {
            let eventLog = eventObj.message;
  
            if (props.traceId != "" && eventObj.message.includes(props.traceId)) {
              eventLog = "----------> Highlight <----------" + "\n" + eventObj.message;
            }
  
            let log = "";
            log += "Time: " + new Date(eventObj.timestamp).toUTCString() + "\n";
            log += eventLog + "\n";
            log += "Ingestion Time: " + new Date(eventObj.ingestionTime).toUTCString() + "\n\n";
            logs = logs.concat(log);
          });
        } else if (props.data.log_details && props.data.log_details.message) {
          logs = props.data.log_details.message;
        }
      }
      return logs;
    }
  
    return (
      <>
        {
          componentName ?
            <Column lg={8}>
              {getTextTile(componentName)}
            </Column>
            : ""
        }
        {
          logGroupName ?
            <Column lg={8}>
              {getTextTile(logGroupName)}
            </Column>
            : ""
        }
        {
          logStreamName ?
            <Column lg={8}>
              {getTextTile(logStreamName)}
            </Column>
            : ""
        }
        {
          drillDown ?
            <Column lg={4}>
              {getDrillDownTile(drillDown)}
            </Column>
            : ""
        }
        {
          props.metrics ?
            <Column lg={4}>
              {getMetricsTile()}
            </Column>
            : ""
        }
  
        <Column lg={16} className="common-top-margin logs">
          {getLogsText() ?
            <p>
              <TextArea
                cols={300}
                rows={10}
                id="text-area-1"
                defaultValue={props.data ? getLogsText() : ""}
                readOnly={true}
              />
            </p> : <DataUnAvailable />
          }
        </Column>
      </>
    );
  };
  
  export default Logs;
  