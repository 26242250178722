import React, { useEffect, useState } from "react";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import {
  ErrorBoundary,
  ComboBox,
  Column,
  Row,
  SkeletonPlaceholder,
} from "@carbon/react";
import ErrorFallback from "Components/Common/ErrorFallback";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from 'react-i18next';
import ErrorRefresh from "Components/Common/ErrorRefresh";
import { useQuery } from "@tanstack/react-query";
import { getIncidentAnalyticsProbabilityAnalysisReports } from "Services/ServerApi";
import ProbabilityAnalysis from "./ProbabilityAnalysis";
import { v4 as uuidv4 } from "uuid";

const ProbabilityAnalysisOverview = ({ queryKey }) => {
  const [environmentName, setEnvironmentName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const onSettled = () => {
    setIsLoading(false);
  };

  const {
    isLoading: loading,
    data: res,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    queryKey,
    () =>
      getIncidentAnalyticsProbabilityAnalysisReports(
        keycloak,
        queryKey[1],
        uuidv4(),
        "incident_ER_likelihood"
      ),
    {
      retry: 1,
      enabled: false,
      onSettled,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onEnvironmentSelect = (e) => {
    if (e["selectedItem"] !== null) {
      setEnvironmentName(e.selectedItem);
    } else {
      setEnvironmentName("");
    }
  };

  const getData = () => {
    if (isLoading || loading || (isFetching && isError)) {
      return (
        <div className="skeletonPlaceholder--it-health">
          <SkeletonPlaceholder />
        </div>
      );
    }

    if (isError) {
      return <ErrorRefresh refresh={refetch} />;
    }

    if (Object.keys(res.data).length > 0) {
      let uniqueSources = [];
      let uniqueTargets = [];
      let nodeData = [];
      let linkData = [];
      uniqueSources = [...new Set(res.data?.links?.map((item) => item.source))];
      uniqueTargets = [...new Set(res.data?.links?.map((item) => item.target))];
      linkData = res.data.links;
      nodeData = res.data.nodes;
      if (environmentName !== "") {
        linkData = res.data.links.filter(
          (fil) => fil.source === environmentName
        );
        nodeData = [
          ...res.data.nodes.filter(
            (fil) =>
              uniqueTargets.includes(fil.name) || fil.name === environmentName
          ),
        ];
      }
      return (
        <>
          <Row>
            <Column lg={10} md={8} sm={4}>
              <h5>{t(`IncidentAnalyticsReports.chartScreens.probability_analysis.title`)}</h5>
            </Column>
            <Column lg={6} md={4} sm={2}>
              <ComboBox
                ariaLabel="ComboBox"
                id="ComboBox1"
                items={uniqueSources}
                itemToString={(item) => (item ? item : "")}
                placeholder="All"
                size="sm"
                titleText="Select Severity"
                onChange={(e) => onEnvironmentSelect(e)}
              />
            </Column>
          </Row>
          <ProbabilityAnalysis
            nodeData={nodeData}
            linkData={linkData}
            environmentName={environmentName}
          />
        </>
      );
    } else {
      return <ErrorRefresh refresh={refetch} />;
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback />}> {getData()}</ErrorBoundary>
  );
};

export default ProbabilityAnalysisOverview;
