import { Modal } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import DynamicForm from "Components/Common/DynamicForm";
// import InlineLoading from "Components/Common/loaderModule/InlineLoader";
// import { addApplication } from "ICPSAservices/serverapi";
import React, { useEffect, useState } from "react";
// import { checkAppDupilicates } from "services/serverapi";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import { getApplicationAPI, postAppDetails } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
// import addApp from "./addApp.json";

const CreateApplication = (props) => {
  const { keycloak } = useKeycloak();
  let timer = null;
  const duplicateMessage = "Application Already Exists";
  const [isFormValid, setFormValididty] = useState(false);
  const [formData, setFormData] = useState({});
  const [dupilicate, setDupilicate] = useState(false);
  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedTenant = sessionStorage.getItem("selectedTenant");
  const selectedOrg = sessionStorage.getItem("selectedOrg");

  const handleFormValidity = (isValid) => {
    setFormValididty(isValid);
  };

  useEffect(() => {
  }, [formData]);

  const handleFormChange = (label, value, id) => {
    console.log("label======" + label);
    
    if (label === "name") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(() => {
        if (Boolean(value)) checkDupilicate(value);
      }, 500);
    }
    const targetId = label;
    const targetValue = value;
    const data = formData;
    data[targetId] = targetValue;
    setFormData(data);
  };

  const checkDupilicate = async (value) => {
    setChecking(true);
    const res = await getApplicationAPI(keycloak, uuidv4(), selectedTenant, selectedOrg, value);
    if (res?.status === 204) {
      setDupilicate(false);
    } else {
      setDupilicate(true);
    }
    setChecking(false);
  };

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const onCreateApp = async () => {
    const data = { ...formData };
    // data.okrTemplate = [];
    // data.startDate = createUTCDateFromInput(formData.startDate, "start");
    // data.endDate = createUTCDateFromInput(formData.endDate, "end");
    // data.tenant = sessionStorage.getItem("tenant");
    setIsLoading(true);
    const res = await postAppDetails(keycloak, uuidv4(), selectedTenant, selectedOrg, data);
    try {
      if (!Boolean(res?.data)) {
        throw new Error("msg");
      } else{
      setErrorMessage("Application created successfully");
      setReloadRequired(true);
      }
    } catch (error) {
      setErrorMessage("Failed to create application.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {errorMessage || isLoading ? (
        <PassiveModal
          canCloseDetailsPopup={() => {
            closeErrorPopup();
            props.closeCreateApp(reload_required);
          }}
        >
          <h4>{errorMessage}</h4>
          {isLoading && (
            <InlineLoading
              status="active"
              description="Creating Application"
            />
          )}
        </PassiveModal>
      ) : (
        <Modal
          open
          modalHeading="Create Application"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestClose={() => props.closeCreateApp(reload_required)}
          onRequestSubmit={() => onCreateApp()}
          primaryButtonDisabled={!isFormValid || dupilicate || checking}
        >
          <div>
            <DynamicForm
              formData={JSON.parse(JSON.stringify(props.addApp))}
              dupilicate={{ id: "name", exists: dupilicate }}
              duplicateMessage={duplicateMessage}
              getFormValidity={handleFormValidity}
              handleFormChange={handleFormChange}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateApplication;
