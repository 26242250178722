import { ComboBox } from "@carbon/react";
import PowerBi from "Components/PowerBI/PowerBI";
import React, { useState } from "react";

function OperationInsights() {
  const [powerBiTitle, setPoweBiTitle] = useState("Operations Insights")

  const getComboBox = () => {
    return <ComboBox
    id="ComboBox1"
    items={["Operations Insights","Inference based Incident Analytics","SLM Exception Handling"].sort((a, b) => {
        let fa = a.toLowerCase(),
            fb = b.toLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    })}
    itemToString={(item) =>
        item ? item  : ""
    }
    placeholder={powerBiTitle}
    titleText=""
    size="sm"
    defaultValue="Operations Insights"
    onChange={(e) => getSelectedFlow(e)}
  />
}

const getSelectedFlow = (e) => {

  if(e.selectedItem !== null) {
      setPoweBiTitle(e.selectedItem)
  } else {
      setPoweBiTitle("Operations Insights");
  }
}

  return (
    <>
      <div style={{ float: "right", width: "20%", marginBottom: "15px" }}>{getComboBox()}</div>
      {powerBiTitle === "Operations Insights" && <PowerBi reportId="a6261dfc-197e-4234-822a-71b9170ceee0" />}
      {powerBiTitle === "Inference based Incident Analytics" && <PowerBi reportId="905d9f94-8f5b-4b6b-b660-91915183b0d2" />}
      {powerBiTitle === "SLM Exception Handling" && <PowerBi reportId="83807eab-18c5-4dca-92fe-bd13848e7176" />}
    </>
  );
}

export default OperationInsights;
