import React, { useState } from "react";
import { ClickableTile, Column, InlineLoading } from "@carbon/react";

const PncOverview = ({ PNCJson, handleOnClick, colmn, flow }) => {
    const [active, setActive] = useState("")

    const tileClick = (data) => {
        setActive(data.name)
        handleOnClick(data)
    }

    return (
        <>
            {PNCJson.map((data, index) => (
                <Column
                    lg={colmn}
                    md={colmn}
                    sm={colmn}
                    className="clickable-tile-portfolio-att pnc-tiles"
                    key={data.name}
                >
                    <ClickableTile
                        id={index}
                        onClick={() => tileClick(data)}
                        aria-label={`clickableTile-${index}`}
                        className={active === data.name ? "active" : ""}
                        disabled={data.value === ""}
                    >
                        <div className="title">
                            <h4 className="flow-title">{data.name}</h4>
                        </div>
                        {flow === "service" ?
                            <ul className="serv-info">
                                <li><span className={data.responseTime.includes("min") ? "red" : ""}>{data.errors}</span><h5>Errors</h5></li>
                                <li><span className={data.responseTime.includes("min") ? "red" : ""}>{data.responseTime}</span><h5>Response Time</h5></li>
                            </ul> : <div className="sec-col">
                                {data.value !== "" ? <span className={data.value === "100%" ? "green" : data.value === "99%" ? "orange" : "red"}>{data.value}</span> : <InlineLoading status="active" iconDescription="Loading" description="" />}
                                {["mnemonic", "customer"].includes(flow) ? <h5>Availability</h5> : null}
                            </div>}
                        {/* <div className={`anamoly green`}>
                                <h3> {data.percent}%</h3>
                                ({data.success} / {data.total})
                            </div> */}
                        {/* <div>
                            <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                        </div> */}
                    </ClickableTile>
                </Column>
            ))}
        </>
    )
}

export default PncOverview;