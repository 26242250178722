import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  Grid,
  Tabs,
  TabPanel,
  TabList,
  Tab,
  TabPanels,
} from "@carbon/react";
import ErrorWhileFetching from "Components/ICPSA/utilities/ErrorWhileFetching";
import React from "react";
import TraceDetailss from "./TraceDetailss";
import PncIncidents from "../ValueStream/Pnc/pncIncident";

const TraceDetailsDashboard = (props) => {
  return (
    <>
      <Grid>
        <Column lg={13} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/itOperations">
              Integrated Operations
            </BreadcrumbItem>
            <BreadcrumbItem href="#/nerveTileInfo">
              Nerve Center View
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Troubleshooting View</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Grid>
      <Tabs>
        <TabList aria-label="List of tabs" style={{paddingLeft:"50px"}}>
          <Tab>Troubleshooting Details</Tab>
          <Tab>Incident Details</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid>
                <Column lg={16}>
            <div className="content-div trace">
              <div className="container-multichart">
                <ErrorBoundary fallback={<ErrorWhileFetching />}>
                  <TraceDetailss data={props.location.state.chartData} />
                </ErrorBoundary>
              </div>
            </div>
            </Column>
            </Grid>
          </TabPanel>
          <TabPanel>
          <Grid>
          <Column lg={16}>
            <PncIncidents />
            </Column>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default TraceDetailsDashboard;
