import { Row, Column, CodeSnippet } from "@carbon/react";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import React, { useState } from "react";
import Issue from "./Issue";

const FixSuggestion = ({ close, selectedRow }) => {
  const [showFix, setShowFix] = useState(false);

  const selectedFix = () => {
    setShowFix(true)
  }

  return (
    <PassiveModal className="code-fix" heading="" canCloseDetailsPopup={() => close()} size="lg">
      <Row className="mt-1">
        <Column lg={6} md={6} sm={4} className="pr-0 lft-col">
          {selectedRow.issues.map((issue, index) => {
            return <Issue issue={issue} selectedRow={selectedRow} key={index} selectedFix={selectedFix} />;
          })}
        </Column>
        {showFix ?
          <Column lg={10} md={6} sm={4} className="pr-0">
            <CodeSnippet type="multi" feedback="Copied to clipboard">
              {`<dependencies> 
        <!-- Other dependencies -->    
        <!-- Update the version to 42.4.1 or any fixed version --> 
    <dependency>
       <groupId>org.postgresql</groupId> 
      <artifactId>postgresql</artifactId> 
      <version>42.4.1</version> 
      </dependency> 
    </dependencies> 
    )`}
            </CodeSnippet>

          </Column> : null
        }
      </Row>
    </PassiveModal>
  );
};

export default FixSuggestion;
