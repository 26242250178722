import {
    Breadcrumb,
    BreadcrumbItem,
    Column,
    ComboBox,
    DataTable,
    FlexGrid,
    Modal,
    Row,
    SkeletonPlaceholder,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectRow,
    TabsSkeleton
} from "@carbon/react";
import { CheckmarkFilled, ErrorFilled, InProgress, UndefinedFilled } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import ErrorWhileFetching from "Components/ICPSA/utilities/ErrorWhileFetching";
import Context from "Context/Context";
import { getAnyCollection } from "Services/ServerApi";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Servers from "./Servers";
import TopologyGraph from "./TopologyGraph";
import "./nerve.scss";

const NerveDashboard = (props) => {

    const context = useContext(Context);
    const history = useHistory();
    const { keycloak } = useKeycloak();
    // const [showApps, setShowApps] = useState({});
    const [appDetails, setAppDetails] = useState([]);
    const [showTopology, setShowTopology] = useState(false);
    const [nerveCenterData, setNerveCenter] = useState([]);
    const [nerveCenterDuplicateData, setDuplicateNerveCenter] = useState([]);
    const [selectedApp, setSelectedApp] = useState("");
    const [serviceData, setServiceData] = useState([]);
    const [propsState, setPropsState] = useState(props.location.state);
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [errMessage, setErrorMessage] = useState("");
    const [selectedService, setSelectedService] = useState("all services");

    const { data: nerveCenter, refetch: Refetch } = useQuery(['nerveCenter'], async () => await getAnyCollection(keycloak, "nerveCenter"), { retry: 1 });

    const updateProps = () => {
        if (propsState === undefined) {
            let state = {
                "tileData": JSON.parse(sessionStorage.getItem("nerveTileInfo")),
                "workflow": "",
                "flow": ""
            };
            setPropsState(state);
        }
        setIsAPIDataLoading(false);
    }

    useEffect(() => {
        updateProps();
    }, [props, propsState])

    useEffect(() => {
        if (nerveCenterData.length > 0) {
            onClickApp(selectedApp)
        }
    }, [nerveCenterData])

    useEffect(() => {
        if (propsState !== undefined && nerveCenter !== undefined && nerveCenter.data !== undefined) {
            let filterKpi = [], serviceArr = [];
            nerveCenter.data.nerveCenter.map((nerve) => {
                if (nerve.kpi.includes(propsState.tileData.kpi) || nerve.services.includes(propsState.tileData.kpi)) {
                    filterKpi.push(nerve)
                }
            })
            if (filterKpi.length > 0) {
                filterKpi !== undefined && filterKpi.forEach((serv) => {
                    serv.services.forEach((sev) => {
                        serviceArr.push(sev)
                    })
                })
                setNerveCenter(filterKpi)
                setDuplicateNerveCenter(filterKpi)
                setSelectedApp(filterKpi[0]["Display Name"])
            }
            const servDuplicate = [...new Set(serviceArr)]
            setServiceData(servDuplicate)
        }
    }, [propsState, nerveCenter])


    const getInference = () => {
        return <ul>
            {appDetails.length > 0 && appDetails[0].Inference !== undefined && appDetails[0].Inference.map((infer) => {
                return <li>{infer}</li>
            })}
        </ul>
    }

    const getRowData = (cell, row) => {
        if (cell.id.includes("Platform")) {
            return <div style={{ "cursor": "pointer" }} onClick={() => onClickStatus(cell)}>{getStatus(cell.value.Error)}</div>
        }

        if (cell.id.includes("Network")) {
            return <div style={{ "cursor": "pointer" }} onClick={() => onClickStatus(cell)}>{getStatus(cell.value.Error)}</div>
        }

        if (cell.id.includes("Security")) {
            return <div style={{ "cursor": "pointer" }} onClick={() => onClickStatus(cell)}>{getStatus(cell.value.Error)}</div>
        }

        if (cell.id.includes("Alert")) {
            return <div style={{ "cursor": "pointer" }} onClick={() => onClickStatus(cell)}>{getStatus(cell.value.Error)}</div>
        }

        if (cell.id.includes("Db")) {
            return <div style={{ "cursor": "pointer" }} onClick={() => onClickStatus(cell)}>{getStatus(cell.value.Error)}</div>
        }

        if (cell.id.includes("Display Name")) {
        }
        if (cell.id.includes("Service")) {
            return selectedService;
        }

        return cell.value
    }

    const onClickApp = (e) => {
        let data = [];
        nerveCenterData.length > 0 && nerveCenterData.forEach((app) => {
            if (app["Display Name"] === e) {
                data.push(app)
            }
        })
        if (data.length > 0) {
            setAppDetails(data)
            setSelectedApp(e)
        }
    }

    const onClickStatus = (e) => {

        let data = [];
        nerveCenterData.forEach((app) => {
            if (e.id.includes(app.id)) {
                data.push(app)
            }
        })
        context.nerveCenter = data;
        setAppDetails(data)
        setShowTopology(true)
    }

    const getStatus = (stat) => {
        switch (stat) {
            case 0:
                return <CheckmarkFilled fill="#198038" />;
            case 1:
                return <ErrorFilled fill="#da1e28" />;
            case "inProgress":
                return <InProgress fill="#d2a106" />;
            default:
                return <UndefinedFilled />;
        }
    }

    // const getFirstRow = () => {
    //     return appDetails.length > 0 && appDetails[0]["Header Tiles"].map((tile) => {
    //         return <ClickableTile onClick={() => showTileDetails(tile)} className="tile-row">
    //             <h5>{tile.name}</h5>
    //             <span>{tile.value}</span>
    //         </ClickableTile>
    //     })
    // }

    // const showTileDetails = (tile) => {
    //     if (tile.data !== undefined) {
    //         setShowApps(tile)
    //     }
    // }

    // const getTilesDetails = () => {
    //     return showApps.data.map((data) => {
    //         return <li>{data.name}</li>
    //     })
    // }

    // const handleOnClick = (data) => {
    //     history.push({ pathname: `nerveTileInfoDetails`, state: { chartData: data } })
    // }

    // const getCharts = () => {
    //     return appDetails.length > 0 &&
    //         appDetails[0].Charts.map(
    //             (chart, index) =>
    //                 index == 0 ?
    //                     <Column lg={8} md={4} sm={4} >
    //                         <span style={{ "cursor": "pointer", "background": "lightgray" }}
    //                             onClick={() => { handleOnClick(chart) }} >
    //                             <ChartContainer data={chart} />
    //                         </span>
    //                     </Column >
    //                     :
    //                     <Column lg={8} md={4} sm={4} >
    //                         <ChartContainer data={chart} />
    //                     </Column>
    //         )
    // }

    const getComboBox = () => {
        return <ComboBox
            id="ComboBox1"
            items={serviceData.sort((a, b) => {
                let fa = a.toLowerCase(),
                    fb = b.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })}
            itemToString={(item) =>
                item ? item : ""
            }
            placeholder="Select Service"
            titleText="Select Service"
            size="sm"
            onChange={(e) => getSelectedFlow(e)}
        />
    }

    const getSelectedFlow = (e) => {
        setSelectedService(e.selectedItem)
        if (e.selectedItem !== null) {
            const filterData = nerveCenterDuplicateData.filter((fil) => fil.services.includes(e.selectedItem));
            setNerveCenter(filterData)
        } else {
            setNerveCenter(nerveCenterDuplicateData);
        }
    }

    const redirectToIne = (page, node) => {
        history.push({
            pathname: "/nerveDetails",
            state: { data: node, tileData: propsState.tileData, workflow: propsState.workflow, flow: propsState.flow },
        });
    };

    const breadcurmRedirect = () => {
        const name = sessionStorage.getItem("portfolioTitle")
        history.push({
            pathname: `/workflowViewCpg/${name}`,
            state: { tileData: propsState.tileData, workflow: propsState.workflow },
        });
    }

    const headers = [
        { "key": "Display Name", "header": "Name" },
        { "key": "Platform", "header": "Plt" }, 
        { "key": "Network", "header": "Net" }, 
        { "key": "Security", "header": "Sec" }, 
        { "key": "Alerts", "header": "Alrt" }, 
        // { "key": "DB", "header": "Db" }, 
        { "key": "Service", "header": "Svc" }];

    const getLoading = () => {
        return isAPIDataLoading ? (
            <div className="skeletonPlaceholder--it-health">
                <SkeletonPlaceholder />
            </div>
        ) : <ErrorWhileFetching errMessage={errMessage} />
    }

    // const handleSetShowApps = (data) => {
    //     setShowApps(data);
    // }

    // const handleSetDuplicateNerveCenter = (data) => {
    //     setDuplicateNerveCenter(data);
    // }

    // const handleSetServiceData = (data) => {
    //     setServiceData(data);
    // }

    // const handleSetIsAPIDataLoading = (data) => {
    //     setIsAPIDataLoading(data);
    // }

    const getTableTitle = () => {
        let tabName = sessionStorage.getItem("nervecenter-selected-tab");
        return (`${tabName} : ${propsState.tileData.displayName ? propsState.tileData.displayName : propsState.tileData.kpi} ${propsState.tileData.postFix ? propsState.tileData.postFix : ""}`);
    }

    return (
        isAPIDataLoading ? getLoading() :
            <FlexGrid className="adani-nerve">
                <Row>
                    {propsState.flow === "valueStream" ? <Column lg={13} md={8} sm={4}>
                        <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                            <BreadcrumbItem href="#/valueStream">Value Stream</BreadcrumbItem>
                            <BreadcrumbItem onClick={() => breadcurmRedirect()}>Consumer Package Goods Workflow</BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>
                                Consumer Package Goods Details
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Column> : <Column lg={13} md={8} sm={4}>
                        <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                            <BreadcrumbItem href="#/itOperations">Integrated Operations</BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>
                                Nerve Center View
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Column>}
                </Row>
                {nerveCenterData.length > 0 && appDetails.length > 0 ? <Row>
                    <Column lg={6} md={4} sm={2} className="pd-l pd-r">
                        {getComboBox()}
                        <div className="col-table" style={{ marginTop: "1rem" }}>
                            {
                                nerveCenterData.length > 0 ?
                                    <>
                                        <DataTable rows={nerveCenterData} headers={headers} radio>
                                            {({
                                                rows,
                                                headers,
                                                getHeaderProps,
                                                getRowProps,
                                                getSelectionProps,
                                                getTableProps,
                                                getTableContainerProps
                                            }) => <TableContainer title={getTableTitle()} description={`Select the Service Component`} {...getTableContainerProps()}>
                                                    <Table {...getTableProps()} aria-label="sample table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <th scope="col" />
                                                                {headers.map((header, i) => <TableHeader key={i} {...getHeaderProps({
                                                                    header
                                                                })}>
                                                                    {header.header}
                                                                </TableHeader>)}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row, i) => <TableRow key={i} {...getRowProps({
                                                                row
                                                            })}>
                                                                <TableSelectRow {...getSelectionProps({
                                                                    row, onClick: event => {
                                                                        onClickApp(row.cells[0].value, row);
                                                                    }
                                                                })} />
                                                                {row.cells.map(cell => <TableCell key={cell.id}>
                                                                    {getRowData(cell, row)}
                                                                </TableCell>)}
                                                            </TableRow>)}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>}
                                        </DataTable>
                                    </>
                                    :
                                    <TabsSkeleton rowCount={5} columnCount={2} />
                            }
                        </div>
                        <div className="inference">
                            <h5>Inference</h5>
                            {getInference()}
                        </div>
                    </Column>
                    <Column lg={10} md={8} sm={4} className="rgt-col">
                        {/* <Row className="head-sec">
                            <Column lg={6} md={4} sm={2} >
                                <Tag className="lft-tag" type="purple">
                                    Name: {selectedApp}
                                </Tag>
                            </Column>
                            <Column className="date-sec" lg={10} md={6} sm={4} >
                                <Tag className="some-class" type="high-contrast">
                                    {'1H'}
                                </Tag>

                                <Tag className="some-class" type="outline">
                                    {'8H'}
                                </Tag>
                                <Tag className="some-class" type="outline">
                                    {'16H'}
                                </Tag>
                                <Tag className="some-class" type="outline">
                                    {'24H'}
                                </Tag>
                            </Column>
                        </Row>
                        <Row>
                            <Column className="first-row" lg={16} md={8} sm={4} >
                                {getFirstRow()}
                            </Column>
                        </Row>
                        <Row>
                            {getCharts()}
                        </Row> */}

                        {/* <ContainerDetails
                            appDetails={appDetails}
                            selectedApp={selectedApp}
                            setShowApps={handleSetShowApps}
                        /> */}

                        <Servers
                            appDetails={appDetails}
                            selectedApp={selectedApp}
                        // setShowApps={handleSetShowApps}
                        />
                    </Column>

                    {/* {Object.entries(showApps).length > 0 ? <Modal
                        open
                        size="lg"
                        passiveModal
                        isFullWidth
                        onRequestClose={() => setShowApps(false)}
                        modalHeading={showApps.name}
                        modalLabel=""
                        className="fss-topology"
                    >
                        <ul className="tiles-details">{getTilesDetails()}</ul>
                    </Modal> : null} */}

                    {showTopology && (
                        <Modal
                            open
                            size="lg"
                            passiveModal
                            isFullWidth
                            onRequestClose={() => setShowTopology(false)}
                            modalHeading="Topology View"
                            modalLabel="Application Topology View"
                            className="topology-view"
                        >
                            <TopologyGraph redirectToIne={redirectToIne} appDetails={appDetails} />
                        </Modal>
                    )}
                </Row> : <Column
                    lg={16}
                    md={8}
                    sm={2}
                    className="clickable-tile-portfolio-att margin-lft"
                >
                    <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
                </Column>} </FlexGrid>
    )
}

export default NerveDashboard;