import { useKeycloak } from '@react-keycloak/web';
import DangerModal from 'Carbon-Components/Modals/DangerModal';
import PassiveModal from 'Carbon-Components/Modals/PassiveModal';
import InlineLoader from 'Components/Common/loaderModule/InlineLoader';
import { deleteOrganization } from 'Components/ICPSA/Services/ServerApi';
// import DangerModal from 'Carbon-Components/Modal/DangerModal';
// import PassiveModal from 'Carbon-Components/Modal/PassiveModal';
// import InlineLoader from 'Components/Common/loaderModule/InlineLoader';
import React, { useState } from 'react';
// import { deleteOrganization } from 'services/serverapi';
import { v4 as uuidv4 } from "uuid";

const DeleteOrModal = ({cancel, org}) => {
    const [isLoading, setIsLoading] = useState(false);
	const [resMsg, setResMsg] = useState("");
    const [refresh, setRefresh] = useState(false)
	const { keycloak } = useKeycloak();

    const deleteOrg = async () => {
		setIsLoading(true);
		const res = await deleteOrganization(keycloak, org.id, uuidv4());
		if (res?.data?.OrgDeleteStatus === "success") {
			setResMsg("Successfully Removed Organization");
            setRefresh(true)
		}
        else if(res?.data?.toolSetlinked !== 0){
            setResMsg(`Please delete the Toolsets linked to ${org.name} before deleting the Organization.`);
            setRefresh(false)
        }
        else {
			setResMsg("Error while Removing Organization");
            setRefresh(false)
		}
		setIsLoading(false);
	};

    if(resMsg){
        return <PassiveModal canCloseDetailsPopup={() => cancel(refresh)} >
          <h4>{resMsg}</h4>
        </PassiveModal>
    }
    return (
        <DangerModal
            primaryButtonDisabled={isLoading}
            heading="Are you sure you want to Delete?"
            primaryText="Delete"
            onCloseModal={() => cancel(false)}
            onModalSubmit={() => deleteOrg()}
        >
           { isLoading
        ? <InlineLoader status="active" description="Deleting Organization..." />
        : <>
            <p className="mb-1">
            <strong>Organization Name: </strong> {org.name}</p> 
            <p>This action can't be reversed.</p>
        </>}
        </DangerModal>
    )
}

export default DeleteOrModal