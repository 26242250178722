import { useQuery } from "@tanstack/react-query";
import { INCIDENT_ANALYTICS } from "../ReactQueryKeys/keys";
import { getIncidentAnalyticsReports } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";

const getData = (keycloak, tabName, filterChartData, dropDown) =>
  getIncidentAnalyticsReports(
    keycloak,
    tabName,
    uuidv4(),
    INCIDENT_ANALYTICS,
    filterChartData,
    dropDown
  );
  {}

export const useIncidentHook = (
  keycloak,
  qurykey,
  tabName,
  enabled,
  onSettled,
  filterChartData,
  dropDown = {}
) => {
  return useQuery(qurykey, () => getData(keycloak, tabName, filterChartData, dropDown), {
    retry: 1,
    enabled,
    onSettled,
  });
};
