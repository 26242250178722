import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import CarbonTabs from "Carbon-Components/Tabs";
import React, { useState } from "react";
// import DAST from "./DAST/index";
// import SAST from "./SAST";
import PerformanceAvailabilityComponent from "Components/ICPSA/AppPerformanceAvailabililty/PerformanceAvailabilityComponent";
import PlatformInsights from "Components/ICPSA/PlatformInsights/PlatformInsights";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import AlertSummary from "../../DO/AlertsSummary/AlertSummary";
import AdaniIndex from "../AdaniNerve";
import KenvueIndex from "../JobObservability/JobObservability";
import KenvueIndex1 from "../JobObservability1/JobObservability";

const IntegratedITOps = () => {
    const [tabSelect, setTabSelection] = useState(sessionStorage.getItem("selectedOpsTab") !== null ? parseInt(sessionStorage.getItem("selectedOpsTab")) : 0)

    const hubs = sessionStorage.getItem("HubFlow");

    // useEffect(() => {
    //     if(hubs === "insights") {
    //         setTabSelection(0)
    //     } else {
    //         const tab = sessionStorage.getItem("selectedTab")
    //         setTabSelection(0)
    //     }
    // }, [])

    const getTabList = () => {
        if (hubs === "operations") {
            return [
                "AlertSummary",
                "Application Performance & Availability",
                "Job Observability",
                "Nerve Center",
                "Platform Observability"
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
        else {
            return [
                "AlertSummary",
                "Application Performance & Availability",
                "Job Observability",
                "Nerve Center",
                "Platform Observability",
                // "Job Insights",
                // "Ticket Analytics"
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
    }

    const getTabPanelsList = () => {
        if (hubs === "operations") {
            let panelList = [];
            if (GetPermissions("alert_summary::read")) {
                panelList.push(<AlertSummary />);
            }
            if (GetPermissions("application_performance_availability::read")) {
                panelList.push(<PerformanceAvailabilityComponent />);
            }
            if (GetPermissions("job_observability::read")) {
                panelList.push(<KenvueIndex />);
            }
            if (GetPermissions("nerve_center::read")) {
                panelList.push(<AdaniIndex />);
            }
            if (GetPermissions("platform_observability::read")) {
                panelList.push(<PlatformInsights />);
            }
            return panelList;
            // return [
            //     <AlertSummary/>,
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />,
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
        else {
            let panelList = [];

            if (GetPermissions("alert_summary::read")) {
                panelList.push(<AlertSummary />);
            }
            if (GetPermissions("application_performance_availability::read")) {
                panelList.push(<PerformanceAvailabilityComponent />);
            }
            if (GetPermissions("job_observability::read")) {
                panelList.push(<KenvueIndex />);
            }
            if (GetPermissions("nerve_center::read")) {
                panelList.push(<AdaniIndex />);
            }
            if (GetPermissions("platform_observability::read")) {
                panelList.push(<PlatformInsights />);
            }
            if (GetPermissions("job_insights::read")) {
                panelList.push(<KenvueIndex1 />);
            }
            return panelList;
            // return [
            //     <AlertSummary />,
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />,
            //     <KenvueIndex1 />,
            //     // <HCSCIncidents />
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
    }

    const tabSelection = (e) => {
        sessionStorage.setItem("selectedOpsTab", e)
        setTabSelection(e);

    }


    const getDisabledList = () => {

        let disabledList = [];
        if (!GetPermissions("alert_summary::read")) {
            disabledList.push("AlertSummary");
        }
        if (!GetPermissions("application_performance_availability::read")) {
            disabledList.push("Application Performance & Availability");
        }
        if (!GetPermissions("job_observability::read")) {
            disabledList.push("Job Observability");
        }
        if (!GetPermissions("nerve_center::read")) {
            disabledList.push("Nerve Center");
        }
        if (!GetPermissions("platform_observability::read")) {
            disabledList.push("Platform Observability");
        }
        if (!GetPermissions("job_insights::read")) {
            disabledList.push("Job Insights");
        }

        console.log("disabledList: ", disabledList);
        return disabledList;
    }

    return (
        <FlexGrid className="integratedOps">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Integrated Operations
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <CarbonTabs
                        tabIndex={sessionStorage.getItem("selectedOpsTab") !== "NaN" ? parseInt(sessionStorage.getItem("selectedOpsTab")) : 0}
                        selectionMode="automatic"
                        type="default"
                        Tablist={getTabList()}
                        tabSelection={true}
                        tabSelected={tabSelection}
                        disabledList={getDisabledList()}
                        TabPanelsList={getTabPanelsList()}
                    />
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default IntegratedITOps;