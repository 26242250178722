import { Column, ErrorBoundary, FlexGrid, Row } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import ChartContainer from "Carbon-Charts/ChartContainer";
import ErrorRefresh from "Components/Common/ErrorRefresh";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useEffect, useState } from "react";
import { useIncidentHook } from "./Hooks/useIncidentAnalyticsReport";
import { v4 as uuidv4 } from "uuid";

const StoryMap = ({ queryKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { keycloak } = useKeycloak();

  const onSettled = () => {
    setIsLoading(false);
  };  

  const {
    isLoading: loading,
    data: res,
    isFetching,
    isError,
    refetch,
  } = useIncidentHook(
    keycloak,
    queryKey,
    queryKey[1],
    false,
    onSettled,
    ""
  );

  useEffect(() => {
    let timer = setTimeout(() => refetch(), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [refetch]);

  const getData = () => {
    try {
        if (isLoading || isFetching || loading) {
          return <InlineLoader description="Please wait..." status="active" />;
        }
    
        if (isError) {
          throw new Error("Dummy")
        }

        return res.data.charts.map((res, index) => {
            return (
              <Column
                lg={res["col-size"]}
                md={8}
                sm={4}
                key={uuidv4()}
                className="pl-05 pr-05"
              >
                {res.data && (
                  <ErrorBoundary fallback={"Data is unavailable"}>
                    {res.data.chartData.length <= 1 ? (
                      <h4 className="empty--message">Data is not sufficient to create Tree Chart</h4>
                    ) : (
                        <ChartContainer data={res} />
                    )}
                  </ErrorBoundary>
                )}
              </Column>
            );
          })
    } catch (error) {
        return <ErrorRefresh refresh={refetch} />;
    }
  };

  return (
    <FlexGrid>
        <Row className="pl-05 pr-05">
            <ErrorBoundary fallback={<ErrorRefresh refresh={refetch} />}> 
                {getData()}
            </ErrorBoundary>
        </Row>
    </FlexGrid>
  );
};

export default StoryMap;
