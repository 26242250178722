import { CodeSnippet, Column, ComposedModal, ModalBody, ModalFooter, ModalHeader, RadioTile, Row, Select, SelectItem, Tile } from "@carbon/react";
import { Rotate } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getVertexResponse, googletoken } from "Services/ServerApi";

const GenPipeline = (props) => {
    const size = 400;
    const { keycloak } = useKeycloak();
    const [progress, setProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [helperText, setHelperText] = useState("");
    const [selectChange, setSelectChange] = useState("");
    const [googlekey, setGoogleToken] = useState("");
    const [prompt, setPrompt] = useState("");
    const [storeSummary, setStoreSummary] = useState("");
    const [questionArr] = useState(["Pipeline with Maven Build, Docker build, Sonarscan and deploy to Openshift",
        "Pipeline with gradle Build, Docker build, Sonarscan and deploy to Openshift",
        "Pipeline with Maven Build, Docker build, Sonarscan and deploy to EKS "]);

    useEffect(() => {
        if (progress === 0 && prompt !== "") {
            setTimeout(() => {
                const interval = setInterval(() => {
                    setProgress(currentProgress => {
                        const advancement = Math.random() * 8;
                        if (currentProgress + advancement < size) {
                            return currentProgress + advancement;
                        } else {
                            clearInterval(interval);
                            return size;
                        }
                    });
                }, 100);
            }, 100);
        }
    }, [progress, prompt])

    useEffect(() => {
        if (progress > 50) {
            setHelperText('Generating Pipeline Code..')
        }
        if (progress === size) {
            setHelperText('')
        }
    }, [progress])

    useEffect(() => {
        if (googlekey === "") {
            getGoogleToken()
        }
    }, [googlekey])


    const getGoogleToken = async () => {
        const token = await googletoken(keycloak, uuidv4());
        if (token === undefined) {
            setErrorMessage("Token is invalid")
        } else {
            setGoogleToken(token.data.access_token)
        }
    }

    const onselectionchange = (e) => {
        setSelectChange(e.target.value)
    }

    const onUseCaseSelect = (e, index) => {
        setStoreSummary("")
        setPrompt(e)
        getApiResponse(e)
    }

    const getApiResponse = async (question) => {
        const res = await getVertexResponse({
            "query": question
        }, "code", keycloak)
        setStoreSummary(res.data)
    }

    const listUseCase = () => {
        return questionArr.map((que, index) => {
            return <RadioTile id={`radio-tile-${index}`} className={`radio-${index} common-margin__bottom`} value={que} onChange={(e) => onUseCaseSelect(e, index)} >
                {que}
            </RadioTile>
        })
    }

    const getSummaryDetails = () => {
        return storeSummary !== "" ? <p>{storeSummary}</p> : <>
            {prompt !== "" ?
                <ul>
                    <li><Rotate className="rotateIcons" size={16} />{helperText}</li>
                </ul> : null}
        </>
    }

    const closeModal = () => {
        props.onCloseModal(false)
    }


    return (
        <ComposedModal
            open
            size="lg"
            className="progressModal cicdModal"
            onClose={() => closeModal()}
        >
            <ModalHeader label="Create Pipeline" />
            <ModalBody>
                <Row>
                    <Column lg={5} md={4}>
                        <Select onChange={(e) => onselectionchange(e)} id="select-1" labelText="Select Repository" helperText="">
                            <SelectItem value="" text="" />
                            <SelectItem value="service" text="Service" />
                            <SelectItem value="UI" text="UI" />
                        </Select>
                        {selectChange !== "" ?
                            <>
                                <div className="tech">
                                    <h5>Technology</h5>
                                    <p><span className="box"></span><span>{selectChange === "UI" ? "Javascript 99.8%" : "Java 99.8%"}</span></p>
                                </div>
                                <h5>Suggested prompts for pipeline creation</h5>
                                {listUseCase()}
                            </> : null}
                    </Column>
                    <Column lg={11} md={6} className="padding-0">
                        <Tile>
                            <CodeSnippet hideCopyButton={storeSummary === ""} minCollapsedNumberOfRows={20} minExpandedNumberOfRows={20} maxCollapsedNumberOfRows={20} maxExpandedNumberOfRows={20} aria-label="Copy" copyButtonDescription="Copy" className="copy-clip" type="multi" feedback="Copied" copyText="Copy">
                                {getSummaryDetails()}
                            </CodeSnippet>
                        </Tile>
                    </Column>
                </Row>
            </ModalBody>
            <ModalFooter primaryButtonDisabled={storeSummary === ""} onRequestSubmit={() => closeModal()} closeModal={() => closeModal()} primaryButtonText="Submit" />
        </ComposedModal>
    )
}

export default GenPipeline;