export const setChartOptions = (data) => {
  if (data.aggregationType === "Percentage") {
    const axes = data.data.chartOptions.axes;

    Object.entries(axes).forEach(([key, value]) => {
      if (value.add_percenatage) {
        axes[key].ticks = axes[key].ticks || {};
        axes[key]["ticks"]["formatter"] = (n) => `${n}%`;
      }
    });
  }
  return data;
};

export const getTotalItems = (data) => {
  return data?.total ? data.total : data?.chartData?.length;
};


export const capitalize = (inputString) => {
  const lowerCase = inputString.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const isValidDate = (dateString) => {
  let date = new Date(dateString);
  return !isNaN(date.getTime());
}