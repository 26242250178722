
export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-') + " 00:00";
}

export const formatDateYear = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + " 00:00";
}

export const updateErrorMessage = (response) => {
    if (response.status >= 400 || response === "Error") {
        return "Error while fetching data";
    } else {
        if (response.err) {
            return response.message;
        } else {
            return "";
        }
    }
}

export const updateAPIData = (response) => {
    if (response.status >= 400 || response === "Error") {
        return "";
    } else {
        if (response.err) {
            return "";
        } else {
            return response.data;
        }
    }
}