export const sessionfields =
  "asset_class,cluster,host,application,service,reason,timestamp,session_id";
export const sessionCountFields = "asset_class,cluster,session,timestamp";
export const taskCountFields = "asset_class,cluster,task,timestamp";
export const oomfields = "asset_class,cluster,host,line,count,timestamp";
export const taskfields =
  "asset_class,cluster,host,application,service,reason,timestamp,session_id,task_id";
export const incidentFields =
  "TICKET_ID,CONFIGURATION_ITEM,SHORT_DESCRIPTION,LONG_DESCRIPTION,REPORT_DATE,REPORTED_PRIORITY,IMPACT,STATUS";

const nameKeyMapper = {
  SMP: "Simpliciti",
  CDT: "Commodities",
  ACE: "ACE",
  EQT: "Equities"
}

export const getCluserTilesData = (asset, sessions, ooms, tasks, incidents, sessionCount, taskCount) => {
  const sessionsCount = {};
  const openSessionCount = {};
  const closedSessionCount = {};
  const taskCounts = {};
  const doneTasks = {};
  const cancelledTasks = {};
  const runningTasks = {};
  const pendingTasks = {};
  const oomCount = {};
  const blockedHostsCount = {};
  const nodeIssuesCount = {};
  const longRunning = {};
  const aborted = {};

  sessions.forEach((element) => {
    if (
      element.cluster.length > 0 &&
      element.asset_class === asset.toolchainName
    ) {
      const name = element.cluster[0];
      if (sessionsCount[name]) {
        sessionsCount[name] += 1;
      } else {
        sessionsCount[name] = 1;
      }
    }
  });

  sessionCount.forEach((element) => {
    if (
      nameKeyMapper[element.asset_class] === asset.toolchainName
    ) {
      const name = element.cluster;
      if(openSessionCount[name]){
        openSessionCount[name] += element.session["Open"]
      } else{
        openSessionCount[name] = element.session["Open"]
      }
      if(closedSessionCount[name]){
        closedSessionCount[name] += element.session["Closed"]
      } else{
        closedSessionCount[name] = element.session["Closed"]
      }
    }
  });

  taskCount.forEach((session) => {
    const assetname = nameKeyMapper[session["asset_class"]]
    if(assetname === asset.toolchainName){
      const name = session.cluster;
      if(doneTasks[name]){
        doneTasks[name] += session.task["Done"]
      } else{
        doneTasks[name] = session.task["Done"]
      }
      if(cancelledTasks[name]){
        cancelledTasks[name] += session.task["Cancelled"]
      } else{
        cancelledTasks[name] = session.task["Cancelled"]
      }
      if(runningTasks[name]){
        runningTasks[name] += session.task["Running"]
      } else{
        runningTasks[name] = session.task["Running"]
      }
      if(pendingTasks[name]){
        pendingTasks[name] += session.task["Pending"]
      } else{
        pendingTasks[name] = session.task["Pending"]
      }
    }
  })

  tasks.forEach((element) => {
    if (
      element.cluster.length > 0 &&
      element.asset_class === asset.toolchainName
    ) {
      const name = element.cluster[0];
      if (taskCounts[name]) {
        taskCounts[name] += 1;
      } else {
        taskCounts[name] = 1;
      }
    }
  });

  ooms.forEach((element) => {
    if (
      element.cluster.length > 0 &&
      element.asset_class === asset.toolchainName
    ) {
      const name = element.cluster[0];
      if (oomCount[name]) {
        oomCount[name] += 1;
      } else {
        oomCount[name] = 1;
      }
    }
  });

  const clusterList = Object.values(asset.clusters).flat();
  incidents.forEach((element) => {
    const name = element["CONFIGURATION_ITEM"];
    if (
      name === asset.displayName &&
      element["SHORT_DESCRIPTION"].toLowerCase().includes("block")
    ) {
      const presentStrings = clusterList.filter((str) =>
        element["SHORT_DESCRIPTION"].includes(str),
      );
      if (presentStrings.length > 0) {
        if (blockedHostsCount[presentStrings[0]]) {
          blockedHostsCount[presentStrings[0]] += 1;
        } else {
          blockedHostsCount[presentStrings[0]] = 1;
        }
      }
    }

    if (
      name === asset.displayName &&
      element["SHORT_DESCRIPTION"].includes("[symphony-resource-list]") &&
      element["STATUS"].toLowerCase() === "open"
    ) {
      const presentStrings = clusterList.filter((str) =>
        element["SHORT_DESCRIPTION"].includes(str),
      );
      if (presentStrings.length > 0) {
        if (nodeIssuesCount[presentStrings[0]]) {
          nodeIssuesCount[presentStrings[0]] += 1;
        } else {
          nodeIssuesCount[presentStrings[0]] = 1;
        }
      }
    }
  });

  return {
    sessionsCount,
    taskCounts,
    oomCount,
    blockedHostsCount,
    nodeIssuesCount,
    longRunning,
    aborted,
    openSessionCount,
    closedSessionCount,
    cancelledTasks,
    pendingTasks,
    runningTasks,
    doneTasks
  };
};

export const getAssetClassesTilesData = (sessions, ooms, tasks, incidents, sessionCount, taskCount) => {
  const sessionsCount = {};
  const openSessionCount = {};
  const closedSessionCount = {};
  const taskCounts = {};
  const doneTasks = {};
  const cancelledTasks = {};
  const runningTasks = {};
  const pendingTasks = {};
  const oomCount = {};
  const blockedHostsCount = {};
  const nodeIssuesCount = {};
  const longRunning = {};
  const aborted = {};

  sessions.forEach((element) => {
    const name = element.asset_class;
    if (sessionsCount[name]) {
      sessionsCount[name] += 1;
    } else {
      sessionsCount[name] = 1;
    }
  });

  tasks.forEach((element) => {
    const name = element.asset_class;
    if (taskCounts[name]) {
      taskCounts[name] += 1;
    } else {
      taskCounts[name] = 1;
    }
  });

  ooms.forEach((element) => {
    const name = element.asset_class;
    if (oomCount[name]) {
      oomCount[name] += 1;
    } else {
      oomCount[name] = 1;
    }
  });

  sessionCount.forEach((session) => {
    const name = nameKeyMapper[session.asset_class]
    if(openSessionCount[name]){
      openSessionCount[name] += session.session["Open"]
    } else{
      openSessionCount[name] = session.session["Open"]
    }
    if(closedSessionCount[name]){
      closedSessionCount[name] += session.session["Closed"]
    } else{
      closedSessionCount[name] = session.session["Closed"]
    }
  })

  taskCount.forEach((session) => {
    const name = nameKeyMapper[session["asset_class"]]
    if(doneTasks[name]){
      doneTasks[name] += session.task["Done"]
    } else{
      doneTasks[name] = session.task["Done"]
    }
    if(cancelledTasks[name]){
      cancelledTasks[name] += session.task["Cancelled"]
    } else{
      cancelledTasks[name] = session.task["Cancelled"]
    }
    if(runningTasks[name]){
      runningTasks[name] += session.task["Running"]
    } else{
      runningTasks[name] = session.task["Running"]
    }
    if(pendingTasks[name]){
      pendingTasks[name] += session.task["Pending"]
    } else{
      pendingTasks[name] = session.task["Pending"]
    }
  })

  incidents.forEach((element) => {
    const name = element["CONFIGURATION_ITEM"];
    if (element["SHORT_DESCRIPTION"].toLowerCase().includes("block")) {
      if (blockedHostsCount[name]) {
        blockedHostsCount[name] += 1;
      } else {
        blockedHostsCount[name] = 1;
      }
    }

    if (
      element["SHORT_DESCRIPTION"].includes("[symphony-resource-list]") &&
      element["STATUS"].toLowerCase() === "open"
    ) {
      if (nodeIssuesCount[name]) {
        nodeIssuesCount[name] += 1;
      } else {
        nodeIssuesCount[name] = 1;
      }
    }
  });

  return {
    sessionsCount,
    taskCounts,
    oomCount,
    blockedHostsCount,
    nodeIssuesCount,
    longRunning,
    aborted,
    openSessionCount,
    closedSessionCount,
    cancelledTasks,
    pendingTasks,
    runningTasks,
    doneTasks
  };
};
