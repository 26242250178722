import { useKeycloak } from "@react-keycloak/web";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { getUserRolesForRealm, getUserRolesforOrg } from "services/serverapi";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import { getUserRolesForRealm, getUserRolesforOrg } from "Services/ServerApi";
import RolesListTable from "./RolesListTable";

const ShowRolesForOrg = ({ selectedUser, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("active");
  const [description, setDescription] = useState("fetching User roles...");
  const [isProductAdmin, setIsProductAdmin] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [isClientRolesEmpty, setIsClientRolesEmpty] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [rolesList, setRolesList] = useState([]);

  const orgs = useSelector((state) => state.GetOrgs);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    fetchUserRoles();
  }, []);

  useEffect(() => {
    if (isProductAdmin || isTenantAdmin || isClientRolesEmpty || showTable) {
      setIsLoading(false);
    }
  }, [isProductAdmin, isTenantAdmin, isClientRolesEmpty, showTable]);

  const fetchUserRoles = async () => {
    const realmRoles = await getUserRolesForRealm(keycloak, selectedUser.id);
    try {
      if (
        realmRoles.error ||
        !Array.isArray(realmRoles.data) ||
        realmRoles.data.length === 0
      ) {
        throw new Error("");
      }
      const roles = realmRoles.data.map((role) => role.name);
      if (roles.includes("productAdmin")) {
        setIsProductAdmin(true);
      } else if (roles.includes("tenantAdmin")) {
        setIsTenantAdmin(true);
      } else {
        getClientLevelRoles();
      }
    } catch (error) {
      setStatus("error");
      setDescription("Failed to fetch realm based roles.");
    }
  };

const getClientLevelRoles = () => {
    const requests = [];
    
    orgs.orgList.forEach((org) => {
    if (Boolean(org.client_id)) {
        requests.push(getUserRolesforOrg(keycloak, selectedUser.id, org.client_id, org.name))
      }
    });
    Promise.all(requests)
            .then(responses => {
              const orgRolesMapper = [];
              responses.forEach((res, index) => {
                if (!Array.isArray(res?.data) || res.data.length === 0) {
                    return;
                  } else {
                    const rolesList = [];
                    res.data.forEach((role) => {
                      rolesList.push(role.name);
                    });
                    orgRolesMapper.push({
                      id: index,
                      orgName: res.name,
                      role: rolesList,
                    });
                  }
              })
              if (orgRolesMapper.length === 0) {
                setIsClientRolesEmpty(true);
              } else {
                setRolesList(orgRolesMapper);
                setShowTable(true);
              }
            })
            .catch(err => console.log(err))
  };

  return (
    <>
      {isLoading || isProductAdmin || isTenantAdmin || isClientRolesEmpty || showTable ? (
        <PassiveModal canCloseDetailsPopup={() => onClose()}>
          {isLoading && (
            <InlineLoader description={description} status={status} />
          )}
          {isProductAdmin && (
            <h4>{selectedUser.username} has Product Admin access.</h4>
          )}
          {isTenantAdmin && (
            <h4>{selectedUser.username} has Tenant Admin access.</h4>
          )}
          {isClientRolesEmpty && (
            <h4>{selectedUser.username} have no roles assigned.</h4>
          )}
          {showTable && (
            <RolesListTable rowData={rolesList} name={selectedUser.username} />
          )}
        </PassiveModal>
      ) : null}
    </>
  );
};

export default ShowRolesForOrg;
