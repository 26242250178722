
import { CloseOutline } from "@carbon/react/icons/index";
import React, { useState } from "react";
import "../css/Home.scss";
import Overlay1 from "./ov";
import Overlay3 from "./ov2";
import Overlay2 from "./ovl";

const Overlay = (props) => {
    const [guidedTourFirstPage, setGuidedTourFirstPage] = useState(true);
    const [guidedTourSecondPage, setGuidedTourSecondPage] = useState(false);
    const [guidedTourThirdPage, setGuidedTourThirdPage] = useState(false);

    const onPopUpButtonClick = (booleanValue, booleanValue1, booleanValue2) => {
        setGuidedTourFirstPage(booleanValue);
        setGuidedTourSecondPage(booleanValue1);
        setGuidedTourThirdPage(booleanValue2)
    };



    const nextBtn = () => {
        onPopUpButtonClick(false, true, false)
    }

    const nextBtn1 = () => {
        onPopUpButtonClick(false, false, true)
    }

    const prevBtn = () => {
        onPopUpButtonClick(true, false, false)
    }

    // const home = () => {
    //     props.overLaydone(false)
    // }

    const prevBtn1 = () => {
        onPopUpButtonClick(false, true, false)
    }

    return (
        <div className="overlay">
            <div className="sec-layer">
                {/* <Button
                kind="secondary"
                className="skip-btn"
                onClick={() => props.overLaydone(false)}
            >
                Skip
            </Button> */}
                <div className="skip-btn" onClick={() => props.overLaydone(false)}><CloseOutline /></div>
                {guidedTourFirstPage ? <Overlay1 nextBtn={nextBtn} /> : null}
                {guidedTourSecondPage ? <Overlay2 nextBtn={nextBtn1} prevBtn={prevBtn} /> : null}
                {guidedTourThirdPage ? <Overlay3 homeBtn={() => props.overLaydone(false)} prevBtn={prevBtn1} /> : null}
                {/* <div className="guid-footer">

                    <div className="carousal">
                    <span
                        id="1"
                        onClick={() => onPopUpButtonClick(true, false, false)}
                        className={`${guidedTourFirstPage ? "dot active" : "dot"}`}
                    ></span>
                    <span
                        id="2"
                        className={`${guidedTourSecondPage ? "dot active" : "dot"}`}
                        onClick={() => onPopUpButtonClick(false, true, false)}
                    ></span>
                    <span
                        id="3"
                        className={`${guidedTourThirdPage ? "dot active" : "dot"}`}
                        onClick={() => onPopUpButtonClick(false, false, true)}
                    ></span>
                </div>
                    <div className="prev">
                        {guidedTourSecondPage || guidedTourThirdPage ? (
                            <Button renderIcon={ChevronLeft} iconDescription="Icon Description" hasIconOnly onClick={() => guidedTourSecondPage ? onPopUpButtonClick(true, false, false) : onPopUpButtonClick(false, true, false)} />
                        ) : (
                            <div className="empty">&nbsp;</div>
                        )}
                    </div>
                    {(guidedTourFirstPage || guidedTourSecondPage) && (
                        <Button renderIcon={ChevronRight} className="tour-btn" iconDescription="Icon Description" hasIconOnly onClick={() => guidedTourFirstPage ? onPopUpButtonClick(false, true, false) : onPopUpButtonClick(false, false, true)} />
                    )}
                    {guidedTourThirdPage && (
                        <Button
                            size="sm"
                            className="done-btn"
                            onClick={() => props.overLaydone(false)}
                        >
                            Done
                        </Button>
                    )}
                </div> */}
            </div>
        </div>
    )
}

export default Overlay