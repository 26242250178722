import React from "react";
import AutoPatcherReport from "./AutoPatcherReport";

function AutoPatcher() {
    return (
        <>
            {/* <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>Patch Event Insights</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid> */}
            <div className="content-div">
                <AutoPatcherReport />
            </div>
        </>
    );
}

export default AutoPatcher;
