/** @format */

import { Breadcrumb, BreadcrumbItem, Column, Row } from "@carbon/react";
import DynamicForm from "Components/Common/DynamicForm";
import { default as React, useEffect, useState } from "react";
import UserList from "./tenantuser/UserList";

function TenantDetails(props) {
  const [tenantDetails, setTenantDetails] = useState({});

  useEffect(() => {
    setTenantDetails(props.tenantRow);
  }, []);

  return (
    <>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/adminPanel" onClick={() => {
              props.closeTenantDetails();
            }}>Tenant List</BreadcrumbItem>
            <BreadcrumbItem href="#" isCurrentPage={true}>
              Tenant Details
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={6} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            <DynamicForm
              formData={JSON.parse(JSON.stringify(props.addTenant))}
              dupilicate={{ id: "name", exists: false }}
            // duplicateMessage={duplicateMessage}
            // getFormValidity={handleFormValidity}
            // handleFormChange={handleFormChange}
            />
          </div>
        </Column>
        <Column lg={10} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            <UserList tenant={props.tenantRow.name} />
          </div>
        </Column>
      </Row>
    </>
  );
}

export default TenantDetails;
