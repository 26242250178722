class FormValidation {
	static isEmailValid(value) {
		const pattern = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
		return pattern.test(value);
	}

	static hasSpecialCharacter(value) {
		const pattern = /^[!@#$%^&*)(+=._-]+$/g;
		return pattern.test(value);
	}

	static isUserNameValid(value) {
		const pattern = /^[a-zA-Z ]*$/;
		return pattern.test(value);
	}

	static isNameValid(value) {
		const pattern = /^[a-zA-Z\d\- _]+$/;
		return pattern.test(value);
	}

	static isPathValid(value) {
		const pattern = /^[a-zA-Z\d\-_./]+$/;
		return pattern.test(value);
	}

	static isGitCloneUrlValid(value) {
		const pattern = /^(https?):\/\/\S*.git$/;
		return pattern.test(value);
	}

	static isURLValid(value) {
		const pattern = /^(https?):\/\/\S*$/;
		return pattern.test(value);
	}

	static isAppsodyUrlValid(value) {
		const pattern = /^(https?):\/\/\S*.json$/;
		return pattern.test(value);
	}
	
	static isDateValid(value){
		const pattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/
		return pattern.test(value);
	}
}

export default FormValidation;
