import { ClickableTile, Column, SkeletonPlaceholder } from "@carbon/react";
import { ArrowRight } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, QueryClient, useQuery } from "@tanstack/react-query";
import InlineLoader from "Carbon-Components/InlineLoader";
import { getDirectvData, getdirectvDetails } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { flowDetails } from './store/action';

const DashboardTiles = (props) => {
    
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const queryClient = new QueryClient();
    const [directvJobExec, setDirectvJobExec] = useState([]);
    const [starCric, setStarCricData] = useState([]);
    const [categoryNames, setCategoryNames] = useState([])
    const [mergeDt, setMergeData] = useState([]);
    const dispatch = useDispatch();

    const { isLoading, isError, data: jobExecRes, error } = useQuery(['directvJobsMainFrame'], async () => await getDirectvData(keycloak, uuidv4(), "job_detail", "elastic"), { cache: queryCache, retry: ({ message: status }) => (status !== '404' && status !== '401') })
    const { data: starCricJobs } = useQuery(['directvstarCricJobsMainFrame'], async () => await getdirectvDetails(keycloak, uuidv4(), "job_group", "elastic"), { cache: queryCache })

    useEffect(() => {
        if (jobExecRes && starCricJobs) {
            setDirectvJobExec(jobExecRes.data.job_detail);
            setStarCricData(starCricJobs.data.job_group)

        }
    }, [jobExecRes, starCricJobs])

    // useEffect(() => {
    //     if (props.dashboardData.length > 0) {
    //         setDashboardData(props.dashboardData)
    //     }
    // }, [props.dashboardData])

    // useEffect(() => {
    //     if(mergeDt.length > 0 && props.flow === "value stream") {
    //         redirectTo()
    //     }
    // }, [mergeDt])

    useEffect(() => {
        if (directvJobExec.length > 0 && starCric.length > 0) {
            let day = []
            if (props.date === "Daily") {
                day = ["ddly", "dly", "dy"]
            } else if (props.date === "Hourly") {
                day = ["hhly", "hrly"]
            } else if (props.date === "All") {
                day = "All"
            } else if (props.date === "Monthly") {
                day = ["mmly"]
            }
            mergeData(day)
        }
    }, [directvJobExec, starCric, props.date])


    const handleOnClick = (data) => {
        console.log("data",data);
        console.log("mergeDt",mergeDt);
        console.log("directvJobExec",directvJobExec);
        console.log("starCric",starCric);
        
        
        
        dispatch(flowDetails(data))
        history.push({ pathname: "/WorkflowIndex2", state: { data, mergeData: mergeDt, directvJobExec, starCric, date: props.date, dateIndexChange: props.dateIndexChange } })
    };

    const mergeData = (day) => { 
        
        const formatTime = directvJobExec.map((tv) => {
            return {
                ...tv, job_details: tv.job_details.map((details) => {
                    const parse = parseInt(details.starttime)
                    return {
                        ...details,
                        starttime: new Date(parse).toLocaleString()
                    }
                })
            }
        });

        console.log("formatTime",formatTime);
        
        let filterLatest = formatTime.map((time) => {
            let e = {};
            time.job_details.forEach(v => e[v.jobname] = v.observedtime);
            const job = Object.keys(e).map(v => time.job_details.find(c => c.observedtime === e[v]))
            if (job[0].job_status === "start") {
                let child_task = false;
                time.child_task_details !== undefined && time.child_task_details.forEach((child) => {
                    const stt = child.child_task_status.toLowerCase();
                    if (stt.includes("running")) {
                        child_task = true
                    }
                })
                return { ...time, job_details: child_task === true ? job : [] }
            } else {
                return { ...time, job_details: job }
            }
        })

        const filterEmptyJob = filterLatest.filter((fil) => fil.job_details.length > 0)


        const unique = filterEmptyJob.filter((obj, index) => {
            return index === filterEmptyJob.findIndex(o => obj.job_details[0].jobname === o.job_details[0].jobname);
        });

        const uniqueStar = starCric;

        console.log("unique", unique);
        console.log("uniqueStar", uniqueStar);
        
        
        let mergeData = []
        uniqueStar.forEach((dat) => {
            const fil = unique.filter((cric) => (cric.job_details[0].jobname === dat["jobname"]))

            if (fil.length > 0) {
                mergeData.push({ ...dat, child_job_status: "run", ...fil[0].job_details[0], ...fil[0] })
            } else {
                mergeData.push({ ...dat, job_status: "running" })
            }
        })


        const uniqueData = mergeData.filter((obj, index) => {
            return index === mergeData.findIndex(o => obj["jobname"] === o["jobname"] && (obj["Child Job Name"] === o["Child Job Name"] || obj["Child Job Name"] === undefined));
        })

        //const removeAdHoc = uniqueData.filter((ad) => ad["Schedule"] !== undefined);

        setMergeData(uniqueData);
        let finalOp = uniqueData;
        if (day !== "All") {
            finalOp = uniqueData.filter((res) => day.some(tv => res["jobname"].includes(tv)));
        }
        getProductCount(finalOp, props.dashboardData);
        setCategoryNames([
            ...new Map(starCricJobs.data.job_group.map(item => [item.category_name, { category_name: item.category_name, group_category	: item.group_category }])).values()
          ])
    }

    const getProductCount = (response, dashboardData) => {
        const stat = Object.values(response.reduce((obj, item) => {
            obj[item.job_status] = obj[item.job_status] || { status: item.job_status, count: 0 };
            obj[item.job_status].count++;
            return obj;
        }, {}));

        let stCount = stat.filter((tt) => tt["status"] === "done")
        if (stCount !== undefined && stCount.length > 0) {
            stCount = stCount[0].count
        } else {
            stCount = 0;
        }

        // const upStatus = dashboardData.map((res) => {
        //     let dp = res;
        //     if (res.dataProduct === "Data Platform 1") {
        //         // console.log("res.status =================",dashboardData, res.status)
        //         dp = {
        //             ...dp,
        //             total: response.length,
        //             status: res.status.map(k => {
        //                 let ispresent = stat.find(p => p.status === k.status);
        //                 if (ispresent) {
        //                     return ispresent
        //                 } else {
        //                     return k
        //                 }
        //             }),
        //             percent: Math.round((stCount / response.length) * 100),
        //             success: stCount
        //         }
        //     }
        //     return dp;
        // })
        // setDashboardData(upStatus)
    }

    // const getStatus = (status) => {
    //     let waiting = 0, running = 0, failure = 0;
    //     status.forEach((stat) => {
    //         if (stat.status.toLowerCase() === "start") {
    //             waiting = stat.count
    //         }
    //         if (stat.status.toLowerCase() === "running") {
    //             running = stat.count
    //         }
    //         if (stat.status.toLowerCase() === "error") {
    //             failure = stat.count
    //         }
    //     })

    //     return <>
    //         <li><div>Running:</div><span>{running}</span></li>
    //         <li><div>Failed:</div><span className={failure > 0 ? "red" : null}>{failure}</span></li>
    //         <li><div>Yet to Start:</div><span>{waiting}</span></li>
    //     </>
    // }

    // const redirectTo = () => {
    //     let newObj = {}
    //     dashboardData.forEach((data) => {
    //         if(data.dataProduct === "OTC") {
    //             newObj = data
    //         }
    //     })
    //     dispatch(flowDetails(newObj))
    //     history.push({ pathname: "/WorkflowIndex2", state: { mergeData: mergeDt, directvJobExec, starCric, date: props.date, dateIndexChange: props.dateIndexChange, flow: props.flow } })
    // }

    console.log("categoryNames", categoryNames)

    return (
        props.flow === "value stream" ? <InlineLoader description="Loading Kenvue Job Observability.." /> :
            <>
                {categoryNames.length > 0 ? categoryNames.map((data, index) => (
                    <Column
                        lg={4}
                        md={4}
                        sm={2}
                        className="clickable-tile-portfolio-att margin-lft"
                        key={index}
                    >
                            <ClickableTile
                                id={index}
                                onClick={() => handleOnClick(data)}
                                aria-label={`clickableTile-${index}`}
                            >
                                <div className="title">
                                    <h4 className="flow-title">{data.group_category.toUpperCase() +" "+ data.category_name}</h4>
                                </div>
                                {/* <div className={`anamoly green`}>
                                    <h3> {Math.round((data.success/data.total*100))}%</h3>
                                    ({data.success} / {data.total})
                                </div> */}
                                {/* <ul className="status">
                                    <li><div>Successful:</div><span>{data.success}</span></li>
                                    {getStatus(data.status)}
                                </ul> */}
                                <div>
                                    <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                                </div>
                            </ClickableTile> 
                    </Column>
                )) : <SkeletonPlaceholder />}
            </>
    );
};

export default DashboardTiles;