import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { postDOExternalApi } from "Services/ServerApi";
import {
    Modal
  } from "@carbon/react";
  import { useHistory } from "react-router-dom";

const CreateTicket = (props) => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [ticketStatus, setTicketStatus] = useState("")

    const { data: ticketCreation, refetch } = useQuery(['createTicket'], async () => await postDOExternalApi(keycloak, props.data[0]._source),{
        retry: 1,
        enabled: true,
      })

    console.log("propsssss", props)
    // useEffect(() => {
    //     if(props !== undefined && ticketCreation === undefined) {
    //         refetch()
    //     }
    // }, [props, ticketCreation])
    useEffect(() => {
        console.log("ticketCreation", ticketCreation)
        if(ticketCreation !== undefined) {
            setTicketStatus(ticketCreation.data.message);
            sessionStorage.setItem("ticketStatus", ticketCreation.data.message)
        }
    }, [ticketCreation])

    const reDirect = () => {
        history.push("/itOperations")
    }

    return <>
        <Modal
            open
            size="md"
            passiveModal
            onRequestClose={() => reDirect()}
            modalHeading="Create Ticket"
          >
            {ticketStatus === "Success" ? <h4>Ticket Created Successfully</h4> : <h4>{ticketStatus}</h4>}
          </Modal>
    </>
}

export default CreateTicket