import React from "react";
import tiles from "./tiles.json";
import { ClickableTile, Column, FlexGrid, Row } from "@carbon/react";
// import "../../ALMDashboards/CodeQuality/codeQuality.scss";
import { useHistory } from "react-router-dom";
import "../securityOps.scss";
import { useTranslation } from 'react-i18next';

const ContainerSecurity = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <FlexGrid className="code--quality--container">
      <Row className="code--quality--row">
        {tiles.map((tile) => {
          const clusterName = tile.cluster;
          return (
            <Column lg={5} md={4} sm={4} className="pl-0 full-length">
              <ClickableTile
                id={clusterName}
                key={clusterName}
                onClick={() => history.push("/clusterIssues")}
                aria-label={`${clusterName}`}
              >
                <div className="flex-space-between">
                  <h4>{clusterName}</h4>
                  <div className="flex-display flex-column">
                    <h3>{tile.workerNodes}</h3>
                    <p>{t(`SecurityHub.WorkerNodes`)}</p>
                  </div>
                </div>
                <ul className="worker-details">
                  <li><p>{t(`SecurityHub.CurrentVersion`)}</p><span>{tile.currentVersion}</span></li>
                  <li><p>{t(`SecurityHub.LatestVersion`)}</p><span>{tile.latestVersion}</span></li>
                  <li><p>{t(`SecurityHub.ClusterType`)}</p><span>{tile.clusterType}</span></li>
                  <li><p>{t(`SecurityHub.CloudProvider`)}</p><span>{tile.cloudProvider}</span></li>
                </ul>
              </ClickableTile>
            </Column>
          );
        })}
      </Row>
    </FlexGrid>
  );
};

export default ContainerSecurity;
