import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import AlMDevInsights from "./AlmdevInsights";
import "./alm.scss";

const DevandOpsInsights = (props) => {
  const [verifyFlow, setVerifyFlow] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [toplogyTitle, setTopologyTitle] = useState("");

  useEffect(() => {
    if (props.location.state && props.location.state.data !== undefined) {
      setTopologyTitle(props.location.state.data.ProjectName);
      setSelectedProject(props.location.state.data);
    } else {
      setVerifyFlow(true);
      setSelectedProject(props.location.state);
      setTopologyTitle(props.location.state.ProjectName);
    }
  }, [props.location.state]);

  return (
    <FlexGrid className="ALM-Dashboard">
      <Row>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>

            {verifyFlow ? (
              <>
                <BreadcrumbItem href="#/valueStream">
                  IT Value Stream Observability
                </BreadcrumbItem>
                {/* <BreadcrumbItem href="#/newProductLaunch">
                                New Product Launch Campaign
                            </BreadcrumbItem> */}
                <BreadcrumbItem isCurrentPage>
                  {selectedProject !== undefined
                    ? selectedProject.ProjectName
                    : ""}
                </BreadcrumbItem>{" "}
              </>
            ) : (
              <>
                <BreadcrumbItem href="#/itControlTower">
                  IT Control Tower
                </BreadcrumbItem>
                <BreadcrumbItem href="#/overviewALM">
                  ALM Dashboard
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  {selectedProject !== undefined
                    ? selectedProject.ProjectName
                    : ""}
                </BreadcrumbItem>
              </>
            )}
          </Breadcrumb>
        </Column>
      </Row>

      <AlMDevInsights type={toplogyTitle} title={selectedProject} />
    </FlexGrid>
  );
};

export default DevandOpsInsights;
