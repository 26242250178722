import React from "react";
import PropTypes from "prop-types";

const NumberDisplay = ({ number }) => {
  const formatNumber = (num) => {
    if (num >= 1000000000) {
      // For billions
      return `${Math.round(num / 1000000000)}B`;
    } else if (num >= 1000000) {
      // For millions
      return `${Math.round(num / 1000000)}M`;
    } else if (num >= 10000) {
      // For thousands
      return `${Math.round(num / 1000)}K`;
    } else {
      return num.toString(); // For numbers less than 10000
    }
  };

  return <>{formatNumber(number)}</>;
};

NumberDisplay.defaultProps = {
  number: 0,
};

NumberDisplay.propTypes = {
  number: PropTypes.number,
};

export default NumberDisplay;
