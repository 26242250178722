import React, { useRef, useEffect, useState, useContext } from "react";
import { TreeChart } from "@carbon/charts-react";
import { Close } from "@carbon/react/icons";
import Elk from "./elkTopology";
import { Button, Dropdown } from "@carbon/react";
import Context from "Context/Context";

const TreeDiagram = () => {
  const svgRef = useRef(null);
  const [popup, showPopup] = useState(false);
  const [popup1, showPopup1] = useState(false);
  const [type, setType] = useState("Health");
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [popupPosition1, setPopupPosition1] = useState({ x: 0, y: 0 });
  const context = useContext(Context);
  const title = sessionStorage.getItem("portfolioTitle");
  const selectedFlow = context.valueStreamData.data.filter(
    (data) => data.title === title
  );
  const CJstepName = sessionStorage.getItem("CJstepName");
  const selectedCustomerJourney = selectedFlow[0].journeySteps.filter(
    (fil) => fil.name === CJstepName
  );

  useEffect(() => {
    const refTemp = svgRef.current;
    const onClickProcessEvent = (event) => {
      if (type === "Recent Changes") {
        setPopupPosition({
          x: event.detail.datum.y0,
          y: event.detail.datum.x0 - 15,
        });
        showPopup(true);
      }
    };

    const onClickNode = (event) => {
      if (type === "Health") {
        setPopupPosition1({
          x: event.detail.datum.y0,
          y: event.detail.datum.x0 - 15,
        });
        showPopup1(true);
      }
    };

    if (type === "Recent Changes") {
      if (refTemp !== null) {
        refTemp.chart.services.events.addEventListener(
          "tree-node-click",
          onClickProcessEvent
        );
      }
    }
    if (type === "Health") {
      if (refTemp !== null) {
        refTemp.chart.services.events.addEventListener(
          "tree-node-click",
          onClickNode
        );
      }
    }
    return () => {
      if (refTemp !== null) {
        refTemp.chart.services.events.removeEventListener(
          "tree-node-click",
          onClickProcessEvent
        );
      }
      if (refTemp !== null) {
        refTemp.chart.services.events.removeEventListener(
          "tree-node-click",
          onClickNode
        );
      }
    };
  }, [type]);

  // const redirect = () => {
  //   const url = `https://www.servicenow.com/`;
  //   window.open(url, "_blank", "noreferrer");
  // };

//   const linkData = [{
//     id: 1,
//     source: "Pre Paid",
//     target: "MDM"
//   }, {
//     id: 2,
//     source: "MDM",
//     target: "HES"
//   }, {
//     id: 3,
//     source: "HES",
//     target: "Network"
//   },
//  {
//     id: 3,
//     source: "Network",
//     target: "Meter"
//   }]

  const linkData = [
    { id: "1", source: "a", target: "b" },
    { id: "2", source: "b", target: "c" },
    { id: "3", source: "c", target: "d" },
    { id: "4", source: "d", target: "e" },
    { id: "5", source: "e", target: "f" },
    { id: "6", source: "f", target: "g" },
    { id: "7", source: "g", target: "h" },
  ];

  // const nodeData = [{
  //   id: "Pre Paid",
  //   label: "Pre Paid",
  //   width: 60,
  //   height: 60,
  //   types: ['jdbc']
  // }, {
  //   id: "MDM",
  //   label: "MDM",
  //   width: 60,
  //   height: 60,
  //   types: ['unknown']
  // },
  // {
  //   id: "HES",
  //   label: "HES",
  //   width: 60,
  //   height: 60,
  //   types: ['HTTP']
  // },
  // {
  //   id: "Network",
  //   label: "Network",
  //   width: 60,
  //   height: 60,
  //   types: ['unknown']
  // },
  // {
  //   id: "Meter",
  //   label: "Meter",
  //   width: 60,
  //   height: 60,
  //   types: ['HTTP']
  // }]

  // const state = {
  //   data: [
  //     {
  //       name: "SpringBootSmaple",
  //       children: [
  //       ]
  //     },
  //     {
  //       name: "COMMSIT",
  //       children: [
  //       ]
  //     },
  //   ],
  //   options: {
  //     // title: "Applications",
  //     height: "225px",
  //     tree: {
  //       type: "dendrogram",
  //       rootTitle: "ADMWriteSrv01",
  //     },
  //   },
  // };

  return (
    <div className={`tree-diag ${type === "Health" ? "circle-colors" : ""}`}>
      <Dropdown
        id="select-health-changes"
        items={["Health", "Recent Changes"]}
        // disabled={true}
        itemToString={(item) => (item ? item : "")}
        selectedItem={type}
        onChange={(e) => setType(e.selectedItem)}
      />

     {selectedCustomerJourney.length > 0 ? <Elk nodes={selectedCustomerJourney[0].nodeData} links={linkData} layout="layered" viewBox="0 0 1050 400" width="800" height="250" flow="real-time" /> : null}

    </div>
  );
};

export default TreeDiagram;
