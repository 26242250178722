function generateIrregularDecreasingValues(firstValue, lastValue) {
  let values = [];
  let decreaseFactor = Math.pow(lastValue / firstValue, 1 / 7);

  for (let i = 0; i < 8; i++) {
    let newValue = firstValue * Math.pow(decreaseFactor, i);
    values.push(newValue);
  }
  return values;
}

const modifyJson = (group, application, flat, percentage, unit) => {
  const [v1, v2, v3, v4, v5, v6, v7, v8] = generateIrregularDecreasingValues(
    flat,
    (1 - percentage / 100) * flat,
  );
  const data = {
    label: "",
    dataType: "line",
    data: {
      chartData: [
        {
          group,
          key: "2023-07-01T00:00:00.000Z",
          value: flat,
        },
        {
          group,
          key: "2023-08-01T00:00:00.000Z",
          value: v2,
        },
        {
          group,
          key: "2023-09-01T00:00:00.000Z",
          value: v3,
        },
        {
          group,
          key: "2023-10-01T00:00:00.000Z",
          value: v4,
        },
        {
          group,
          key: "2023-11-01T00:00:00.000Z",
          value: v5,
        },
        {
          group,
          key: "2023-12-01T00:00:00.000Z",
          value: v6,
        },
        {
          group,
          key: "2024-01-01T00:00:00.000Z",
          value: v7,
        },
        {
          group,
          key: "2024-02-01T00:00:00.000Z",
          value: (1 - percentage / 100) * flat,
        },
        {
          group: "Flat",
          key: "2023-07-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2023-08-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2023-09-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2023-10-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2023-11-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2023-12-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2024-01-01T00:00:00.000Z",
          value: flat,
        },
        {
          group: "Flat",
          key: "2024-02-01T00:00:00.000Z",
          value: flat,
        },
      ],
      chartOptions: {
        title: `By Using ${group}: ${application}`,
        axes: {
          bottom: {
            title: "Time to Implement (Months)",
            mapsTo: "key",
            scaleType: "time",
          },
          left: {
            mapsTo: "value",
            title: `CO2 emission (${unit})`,
            scaleType: "linear",
            thresholds: [
              {
                value: flat,
                label: "Flat",
                fillColor: "#be95ff",
              },
              {
                value: (1 - percentage / 100) * flat,
                label: "Optimize",
                fillColor: "#59D0CC",
              },
            ],
          },
        },
        height: "300px",
        legend: {
          enabled: false,
        },
        tooltip:{
          showTotal: false
        },
        color: {
          scale: {
            [`${group}`]: "#198038",
            Flat: "#be95ff",
          },
        },
        grid: {
          x: {
            enabled: false,
          },
          y: {
            enabled: false,
          },
        },
      },
    },
  };
  return data;
};

export default modifyJson;
