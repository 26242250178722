import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  Modal,
  Row,
  Tile,
  Tooltip
} from "@carbon/react";
import { Information } from "@carbon/react/icons";
import React, { useState } from "react";
import "./vs.scss";

const CommonMLModal = (props) => {
    const [showML, setShowML] = useState("root_cause");
    // const handleClick = (mlType) => {
    //     setShowML(mlType);
    // };

    // const getTableHeader = (header) => {
    //     return header;
    // };

    // const getRowCellData = (id, data, row) => {
    //     if (id.includes(":action")) {
    //         return (
    //             <p>
    //                 <Button kind="primary" size="sm">
    //                     Execute
    //                 </Button>
    //             </p>
    //         );
    //     }
    //     return data;
    // };

    return (
        <Modal
            open
            size="lg"
            passiveModal
            onRequestClose={() => props.setShowModal(false)}
            className="ml-model-popup"
            modalHeading="Ticket Details"
        >
            <>
                <Tile
                    style={{
                        background: "#c1c1c129",
                        padding: "1rem",
                        minHeight: "1rem",
                    }}
                >
                    <Row>
                        <Column lg={6} md={4} sm={2}>
                            <strong>Incident ID</strong> :{" "}
                            <>
                                {props.ticketDetails.IncidentId}{" "}
                                <Tooltip
                                    description={props.ticketDetails.description}
                                    align="bottom"
                                >
                                    <button type="button" className="tooltip-button">
                                        <Information size={12} />
                                    </button>
                                </Tooltip>
                            </>
                        </Column>
                        <Column lg={4} md={4} sm={2}>
                            <strong>Status</strong> : {props.ticketDetails.status}
                        </Column>
                        {props.ticketDetails.priority !== undefined ? <Column lg={4} md={4} sm={2}>
                            <strong>Priority</strong> : {props.ticketDetails.priority}
                        </Column> : null}
                        {props.ticketDetails.severity  !== undefined ? <Column lg={4} md={4} sm={2}>
                            <strong>Severity</strong> : {props.ticketDetails.severity }
                        </Column> : null}
                        {/* <Column lg={4} md={4} sm={2}>
              <strong>Type</strong> : {props.ticketDetails.type}
            </Column> */}
                    </Row>
                </Tile>
                {/* <Row style={{ marginRight: "0" }}>
          <Column lg={4} md={4} sm={2}>
            <RadioTile
              style={{ background: "#f9eefe", marginTop: "10px" }}
              onClick={() => handleClick("root_cause")}
            >
              <h5>
                Root Cause Analysis{" "}
                {showML === "root_cause" && (
                  <CheckmarkFilled
                    fill="#525252"
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                )}
              </h5>
            </RadioTile>
            <RadioTile
              style={{ background: "#f9eefe", marginTop: "10px" }}
              onClick={() => handleClick("recommendation")}
            >
              <h5>
                Runbook Recommendation{" "}
                {showML === "recommendation" && (
                  <CheckmarkFilled
                    fill="#525252"
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                )}
              </h5>
            </RadioTile>
            <RadioTile
              style={{ background: "#f9eefe", marginTop: "10px" }}
              onClick={() => handleClick("similar_incidents")}
            >
              <h5>
                Similar Incidents{" "}
                {showML === "similar_incidents" && (
                  <CheckmarkFilled
                    fill="#525252"
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                )}
              </h5>
            </RadioTile>
          </Column>
          {showML === "root_cause" && (
            <Column
              lg={12}
              md={8}
              sm={4}
              style={{
                background: "#e8c7f329",
                marginTop: "10px",
                padding: "5px",
              }}
            >
              <Tile
                style={{ height: "100%", marginTop: "0rem", padding: "10px" }}
              >
                <h5>
                  Performance Degradation caused due to Insufficient hardware
                  resources(CPU,Memory,Disk I/O) or network Issues.
                </h5>
              </Tile>
            </Column>
          )}
          {showML === "recommendation" && (
            <Column lg={12} md={8} sm={4}>
              <CarbonDataTable
                rowData={getRowData()}
                headerData={getHeaderData()}
                getRowCellData={getRowCellData}
                getTableHeader={getTableHeader}
              />
            </Column>
          )}
          {showML === "similar_incidents" && (
            <Column
              lg={12}
              md={8}
              sm={4}
              style={{ height: "250px", overflowY: "auto" }}
            >
              {similarIncidents.map((similarIncident) => {
                return (
                  <Tile style={{ background: "#c1c1c129" }}>
                    <h5>
                      <Tag type="purple">
                        Similarity Score: {similarIncident.Score}
                      </Tag>{" "}
                      <br />
                      {similarIncident.DESCRIPTION}
                      <br />
                      <Tag type="magenta">{similarIncident.Priority}</Tag>
                      <br />
                      <strong>Resolver</strong> {similarIncident.RESOLVER}
                      <br />
                      <strong>Resolution</strong> <br />
                      {similarIncident.Resolution}
                    </h5>
                  </Tile>
                );
              })}
            </Column>
          )}
        </Row> */}
                <Row style={{ marginTop: "1rem" }}>
                    <Column lg={16} md={8} sm={4}>
                        <Accordion>
                            <AccordionItem
                                title="Root Cause Analysis"
                                className="accordion-ml-model"
                            >
                                <Tile
                                    style={{
                                        height: "100%",
                                        marginTop: "0rem",
                                        padding: "10px",
                                        background: "#c1c1c129",
                                    }}
                                >
                                    <h5>
                                        {props.ticketDetails.rootCause !== undefined ? props.ticketDetails.rootCause : props.ticketDetails.description}
                                    </h5>
                                </Tile>
                            </AccordionItem>
                        </Accordion>
                    </Column>
                </Row>
                <Row>
                    <Column lg={16} md={8} sm={4}>
                        {props.ticketDetails.resolution !== undefined ? <Accordion>
                            <AccordionItem
                                title="Recommended Resolution"
                                className="accordion-ml-model"
                            >
                                <Tile
                                    style={{
                                        height: "100%",
                                        marginTop: "0rem",
                                        padding: "10px",
                                        background: "#c1c1c129",
                                    }}
                                >
                                    <h5>
                                        {props.ticketDetails.resolution}
                                    </h5>
                                </Tile>
                                {/* <CarbonDataTable
                  rowData={getRowData()}
                  headerData={getHeaderData()}
                  getRowCellData={getRowCellData}
                  getTableHeader={getTableHeader}
                /> */}
                            </AccordionItem>
                        </Accordion> : null}
                    </Column>
                </Row>
                {/* <Row>
          <Column lg={16} md={8} sm={4}>
            <Accordion>
              <AccordionItem
                title="Similar Incidents"
                className="accordion-ml-model"
              >
                {similarIncidents.map((similarIncident) => {
                  return (
                    <Tile style={{ background: "#c1c1c129" }}>
                      <h5>
                        <Tag type="purple">
                          Similarity Score: {similarIncident.Score}
                        </Tag>{" "}
                        <br />
                        {similarIncident.DESCRIPTION}
                        <br />
                        <Tag type="magenta">{similarIncident.Priority}</Tag>
                        <br />
                        <strong>Resolver</strong> {similarIncident.RESOLVER}
                        <br />
                        <strong>Resolution</strong> <br />
                        {similarIncident.Resolution}
                      </h5>
                    </Tile>
                  );
                })}
              </AccordionItem>
            </Accordion>
          </Column>
        </Row> */}
            </>
        </Modal>
    );
};

export default CommonMLModal;
