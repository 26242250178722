/** @format */
import { ClickableTile, Column, Tag } from "@carbon/react";
import {
    BatchJob,
    CallsAll,
    Development,
    FirewallClassic,
    Process,
    Tuning,
    Undefined,
    WorkflowAutomation
} from "@carbon/react/icons";
import PowerBi from "Assets/External/powerBi.svg";
import Context from "Context/Context";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import React, { useCallback, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { getBasicInfo, getChartData, getOpsChartData, getValChartData } from "./development";
import { getSecChartData } from "./security";

import "../../HomePage/css/Home.scss";

function TilesComponentNew(props) {
    const history = useHistory();
    //const context = useContext(Context);
    const { t , i18n} = useTranslation();
    const SVC_URL = sessionStorage.getItem("svc") || "localhost";

    sessionStorage.setItem("HubFlow", "insights")

    const handleOnClick = useCallback(
        (route, data) =>
            route === "warRoom"
                ? window.open(
                    "https://ibm-appops-devops.slack.com/archives/C02DQHU26H1",
                    "_blank"
                )
                : route === "resPerformance"
                    ? window.open(
                        "https://aiops.turbonomic.io/vmturbo/oauth2/authorization/login.ibm.com",
                        "_blank"
                    )
                    : route === "appPerformance"
                        ? window.open("https://aiopsri-ibmdso.instana.io/", "_blank")
                        : route === "devOpsCommander"
                            ? window.open("https://dc-stg.edst.ibm.com/")
                            : route === "icpsa"
                                ? window.open("https://ibmaiops4aws.edst.ibm.com/ui/")
                                : route === "swagger"
                                    ? window.open(`${SVC_URL}/swagger-ui/index.html`, "_blank", "noreferrer")
                                    : route === "cloudFabrix"
                                        ? window.open(data.url)
                                        : route === "cfxPopUp"
                                            ? props.showPopUp("true")
                                            : route === "watson-aiops"
                                                ? window.open(data.url)
                                                : route === "doServiceDesk"
                                                    ? window.open(data.url)
                                                    : route === "doCommandCenter"
                                                        ? window.open(data.url) 
                                                        : history.push(`/${route}`),
        [history]
    );
    
    const getTitleIcons = (title) => {
        switch (title) {
            case "Development":
                return <Development />;
            case "Security":
                return <FirewallClassic />;
            case "Operations":
                return <CallsAll />;
            case "Value Stream":
                return <Process />;
            case "Automation":
                return <WorkflowAutomation />;
            case "Job Observability":
                return <BatchJob />;
            case "genai":
                return <Tuning />;
            default:
                return <Undefined />;
        }
    }
    
    return (
        <>
            {props.tileData.map((data, index) => (
                <Column
                    lg={props.lg ? props.lg : 4}
                    md={8}
                    sm={4}
                    className="clickable--tile insights-hb"
                    key={index}
                >
                    <ClickableTile
                        id={index}
                        onClick={() =>
                            data.id !== undefined && handleOnClick(data.id, data)
                        }
                        disabled={!GetPermissions(data.access)}
                        className={!data.disabled ? "" : "disable-tile"}
                        aria-label={`clickableTile-${index}`}
                    >
                        <div>
                            {data.isPowerBIReport !== undefined && data.isPowerBIReport ? (
                                <img className="powerbi" src={PowerBi} alt="power BI" />
                            ) : null}
                        </div>
                        <h5 className="report-title">
                            {getTitleIcons(data.title)} {t(data.transTitle)}
                            {/* {data.description && data.description !== "" && (
                                <Tooltip
                                    align="bottom"
                                    label={data.description}
                                    style={{ paddingLeft: "4px" }}
                                >
                                    <button type="button">
                                        <Information size={14} />
                                    </button>
                                </Tooltip>
                            )} */}
                            {data.tag !== undefined ?
                                <Tag className="notify-tag" type={data.tag === "Healthy" ? "green" : "red"}>
                                    {t(data.transTag)}
                                </Tag> : null}
                        </h5>
                        <p className="desc">{t(data.translateDesc)}</p>
                        {data["sub-title"] && (
                            <span className="subtitle">{data["sub-title"]}</span>
                        )}
                        {data.title === "Development" ? getBasicInfo(data.metrics) : null}
                        {data.title === "Development" ? <div className="chart-box">{getChartData(data.metrics)}</div> : null}
                        {data.title === "Security" ? getBasicInfo(data.metrics) : null}
                        {data.title === "Security" ? <div className="chart-box">{getSecChartData(data.metrics)}</div> : null}
                        {data.title === "Operations" ? getBasicInfo(data.metrics) : null}
                        {data.title === "Operations" ? <div className="chart-box">{getOpsChartData(data.metrics)}</div> : null}
                        {data.title === "Value Stream" ? getBasicInfo(data.metrics) : null}
                        {data.title === "Value Stream" ? <div className="chart-box">{getValChartData(data.metrics)}</div> : null}
                        {/* <div className="rgt-aw">
                            <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                        </div> */}
                    </ClickableTile>
                </Column>
            ))}
        </>
    );
}

export default TilesComponentNew;
