import { DatePicker, DatePickerInput } from "@carbon/react";
import {
  Calendar,
  EventSchedule,
  Time
} from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";

const EpochTime = (props) => {
  const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
  const [dateDropdown, showDateDropdown] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Select Day");
  const [defaultDropDate] = useState(() => selecteDates ? { start: selecteDates.startDate, end: selecteDates.endDate } : { start: "", end: "" })
  const [dateIndexChange, onDateIndexChange] = useState(() => selecteDates ? selecteDates.indx : 0);

  // const items = [
  //   "Select Day",
  //   "Last Two days",
  //   "Last Three days",
  //   "Last Four days",
  //   "Last Five days",
  //   "Last Seven days",
  // ];

  useEffect(() => {
    if (dateIndexChange === 0) {
      sessionStorage.setItem("selecteDates", JSON.stringify({ time: getCurrandPrevTime(15), indx: 0 }))
    }
  }, [dateIndexChange]);

  const onClickDrop = (e, index, eve) => {
    const date = new Date();
    const Tdyepoch = Date.parse(date);
    if (e === "15 Mins") {
      sessionStorage.setItem("selecteDates", JSON.stringify({ time: getCurrandPrevTime(15), indx: index }))
      props.dateOnChange(getCurrandPrevTime(15), index);
      setSelectedDate("Select Day")
    } else if (e === "1 Hour") {
      sessionStorage.setItem("selecteDates", JSON.stringify({ time: getCurrandPrevTime(60), indx: index }))
      props.dateOnChange(getCurrandPrevTime(60), index);
      setSelectedDate("Select Day")
    } else if (e === "1 Day") {
      sessionStorage.setItem("selecteDates", JSON.stringify({ time: getCurrandPrevTime(60 * 24), indx: index }))
      props.dateOnChange(getCurrandPrevTime(60 * 24), index);
      setSelectedDate("Select Day")
    } else {
      const day = dateSwitch(e.selectedItem, date);
      props.dateOnChange(
        {
          startTime: day,
          endTime: Tdyepoch,
        },
        index,
      );
      setSelectedDate(e.selectedItem)
    }
    onDateIndexChange(index)
    // showDateDropdown(false);
  };

  const dateSwitch = (e, date) => {
    switch (true) {
      case e === "Last Two days":
        return date.setDate(date.getDate() - 2);
      case e === "Last Three days":
        return date.setDate(date.getDate() - 3);
      case e === "Last Four days":
        return date.setDate(date.getDate() - 4);
      case e === "Last Five days":
        return date.setDate(date.getDate() - 5);
      case e === "Last Seven days":
        return date.setDate(date.getDate() - 7);
      default:
        return date.setDate(date.getDate() - 1);
    }
  };

  const onChangeDate = (e) => {

    let startDate = Date.parse(convertDateTime(e[0]));
    if (e.length > 1) {
      let endDate = Date.parse(convertDateTime(e[1]));

      if (startDate === endDate) {
        startDate = Date.parse(convertStartDateTime(e[0]));
        endDate = Date.parse(convertEndDateTime(e[1]));
      }
      props.dateOnChange(
        {
          startTime: startDate,
          endTime: endDate,
        },
        dateIndexChange,
      );
      sessionStorage.setItem("selecteDates", JSON.stringify({
        time: {
          startTime: startDate,
          endTime: endDate,
        }, indx: 4, startDate: `${e[0].getFullYear()}-${monthOrde(e[0])}-${dateM(e[0])}`, endDate: `${e[1].getFullYear()}-${monthOrde(e[1])}-${dateM(e[1])}`
      }))
      onDateIndexChange(4)
    }
  }

  const convertDateTime = (date) => {
    const newDate = new Date();
    const format = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()))
    return format
  }

  const convertEndDateTime = (date) => {
    const today = new Date(date);
    const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
    return endOfToday
  }

  const convertStartDateTime = (date1) => {
    const date = new Date(date1);
    const beginningOfYesterday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return beginningOfYesterday;
  }

  const onClickDateRange = (eve) => {
    if (dateDropdown) {
      showDateDropdown(false)
    } else {
      showDateDropdown(true)
    }
  }

  const dateSelection = () => {
    const dateArr = ["15 Mins", "1 Hour", "1 Day"];

    return dateArr.map((date, index) => (
      <li
        className={`${index === dateIndexChange && "active"} ${date === "1 day" ? date : ""
          } `}
        key={date}
        onClick={(eve) => {
          if (index === 3) {
            onClickDateRange(eve)
          } else {
            onClickDrop(date, index, eve);
          }
        }}
      >
        {date}
        {date === "15 mins" ? <Time /> : null}
        {date === "1 Hour" ? <EventSchedule /> : null}
        {date === "1 day" ? <Calendar /> : null}
        {/* {date === "Custom" ? (
          <div className="calcul">
            <Calculator />
            {dateDropdown ? (
              <Dropdown
                onChange={(e) => {
                  //   e.stopPropagation();
                  onClickDrop(e, 3);
                }}
                id="default"
                titleText=""
                helperText=""
                initialSelectedItem={selectedDate}
                label=""
                items={items}
                itemToString={(item) => (item ? item : "")}
              />
            ) : null}
          </div>
        ) : null} */}
        {index === dateIndexChange && selectedDate !== "Select Day" ? <div className="custom-date">Custom Date: <span>{selectedDate}</span> </div> : null}
        {/* {date === "Select Range" ? dateDropdown ? showCustomDate() : null : null} */}
      </li>
    ));
  };

  const minmDate = () => {
    const newDate = new Date();
    const last10days = new Date().setDate(newDate.getDate() - 5);
    const dateOr = new Date(last10days);
    const minuDate = `${dateOr.getFullYear()}-${monthOrde(dateOr)}-${dateM(dateOr)}`;
    return minuDate.toString()
  }

  const maxmDate = () => {
    const newDate = new Date();
    const maxmDate = `${newDate.getFullYear()}-${monthOrde(newDate)}-${dateM(newDate)}`
    return maxmDate.toString()
  }

  function monthOrde(date) {
    if (date.getMonth() === 0) {
      return "01"
    } else {
      return `0${1 + date.getMonth()}`
    }
  }

  function dateM(date) {
    if (date < 10) {
      return `0${date.getDate()}`
    } else {
      return date.getDate()
    }
  }

  const showCustomDate = () => {
    return <div className="calcul">
      <DatePicker dateFormat="Y-m-d" datePickerType="range" minDate={minmDate()} maxDate={maxmDate()} onChange={(e) => onChangeDate(e)}>
        <DatePickerInput id="date-picker-input-id-start" defaultValue={defaultDropDate.start} placeholder="Start date" labelText="" size="sm" />
        <DatePickerInput id="date-picker-input-id-finish" defaultValue={defaultDropDate.end} placeholder="End date" labelText="" size="sm" />
      </DatePicker>
    </div>
  }

  return (
    <>
      <ul className="portfolio-fss">{dateSelection()}</ul>
      {showCustomDate()}
    </>
  );
};

export default EpochTime;

export const getCurrandPrevTime = (time) => {
  const dateToday = new Date();
  let totalMilliSeconds = dateToday.getTime();
  let millisecondsToSubtract = time * 60 * 1000;
  let newDate = new Date(totalMilliSeconds - millisecondsToSubtract);
  const endTime = Date.parse(dateToday);
  const startTime = Date.parse(newDate);
  return {
    startTime: startTime,
    endTime: endTime,
  };
};

export const malformedJSON2Array = (tar) => {
  let arr = [];
  tar = tar.replace(/^\{|\}$/g, "").split("}");

  for (let i = 0, cur, pair; i < tar.length; i++) {
    cur = tar[i];  // Assign cur before checking in the loop
    if (!cur) continue;  // Skip empty values if necessary
    arr[i] = {};
    pair = cur.split("}");
    arr[i] = [pair[0]];
  }
  return arr;
};
