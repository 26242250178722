import { HeatmapChart } from "@carbon/charts-react";
import { Dropdown } from "@carbon/react";
import React, { useState } from "react";

const HeatMapWithDropDown = ({ data, options }) => {
  const years = Array.from(new Set(data.map((ticket) => ticket.year)));
  const [year, setYear] = useState(years[0]);

  const data1 = data.filter((a) => a.year === year);
  const sortedData1 = data1.sort((a, b) => a.group - b.group);
 
  return (
    <div className="chart-dropdown-wrapper">
      {years.length > 1 ? (
        <div className="dropdown-wrapper">
          <Dropdown
            id="select-target-year"
            label="Dropdown menu options"
            onChange={(e) => setYear(e.selectedItem)}
            selectedItem={year}
            size="sm"
            items={years}
            itemToString={(item) => (item ? item : "")}
          />
        </div>
      ) : (
        <p>year: {years[0]}</p>
      )}
      <HeatmapChart data={sortedData1} options={options}></HeatmapChart>
    </div>
  );
};

export default HeatMapWithDropDown;
