/** @format */

import {
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    ErrorBoundary,
    Grid,
    InlineLoading,
    Modal,
    SkeletonPlaceholder,
    Tile
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import ChartContainer from "Carbon-Charts/ChartContainer";
import TextBox from "Carbon-Charts/TextBox";
import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import ErrorFallback from "Common-Modules/ErrorFallback";
import Context from "Context/Context";
import { default as React, useContext, useEffect, useState } from "react";
import { getGitHubRepoDetailsReportData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import GitHubPullRequestDetails from "./GitHubPullRequestDetails";

// import "./../css/_chart.scss";
const GitHubRepoDetailsReport = (props) => {

    const [APIData, setAPIData] = useState("");
    const [apiLoading, setApiLoading] = useState(false);
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [errMessage, setErrorMessage] = useState("");

    // const [commitsAPIData, setCommitsAPIData] = useState("");
    // const [commitsApiLoading, setCommitsApiLoading] = useState(false);
    // const [isCommitsAPIDataLoading, setIsCommitsAPIDataLoading] = useState(true);
    // const [commitsErrMessage, setCommitsErrorMessage] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const [showPopUP, setShowPopUP] = useState(false);
    // const [showCommitsPopUP, setShowCommitsPopUP] = useState(false);
    const [pullRequestDetailsRow, setPullRequestDetailsRow] = useState();
    // const [commitDetailsRow, setCommitDetailsRow] = useState();
    const [repoName, setRepoName] = useState("");

    const { keycloak } = useKeycloak();
    const context = useContext(Context);

    useEffect(() => {
        setRepoName(JSON.parse(sessionStorage.getItem("gitHubRepo")).repository_name);
        let repoId = JSON.parse(sessionStorage.getItem("gitHubRepo")).repository_id;
        let startDateDefault = "2022-09-13";
        let endDateDefault = new Date().toISOString().split("T")[0];

        setDefaultStartDate(startDateDefault);
        setDefaultEndDate(new Date().toISOString().split("T")[0]);

        startDateDefault += " 00:00";
        endDateDefault += " 00:00";

        setStartDate(startDateDefault);
        setEndDate(endDateDefault);

        getAPIData(startDateDefault, endDateDefault, repoId);
    }, []);

    const getDetailsRow = (details, detailsId) => {
        let detailRow = {};
        details.forEach((row) => {
            if (row.id === detailsId) {
                detailRow = row;
                return;
            }
        });
        return detailRow;
    };

    const onClickNode = (id) => {
        let row = getDetailsRow(APIData.data.charts[3].data.rowData, id);
        setPullRequestDetailsRow(row);
        setShowPopUP(true);
    };

    // const onClickCommitsNode = (id) => {
    //     let row = getDetailsRow(commitsAPIData.data.data[0].data.rowData, id);
    //     setCommitDetailsRow(row);
    //     setShowCommitsPopUP(true);
    // };

    const getPopupData = () => {
        return <GitHubPullRequestDetails details={pullRequestDetailsRow} />;
    };

    // const getCommitsPopupData = () => {
    //     return <GitHubCommitDetails details={commitDetailsRow} />;
    // };

    const getRowCellData = (id, data, row) => {
        if (id.includes("id")) {
            return (
                <a
                    className="event-status"
                    onClick={() => {
                        onClickNode(row.id);
                    }}
                >
                    {data}
                </a>
            );
        }
        if (id.includes("created_date")) {
            return String(new Date(data).toLocaleString());
        }
        return data;
    };

    const getTableHeader = (header) => {
        return header;
    };

    // const getCommitsRowCellData = (id, data, row) => {
    //     if (id.includes("id")) {
    //         return (
    //             <a
    //                 className="event-status"
    //                 onClick={() => {
    //                     onClickCommitsNode(row.id);
    //                 }}
    //             >
    //                 {data}
    //             </a>
    //         );
    //     }
    //     if (id.includes("commit_date")) {
    //         return String(new Date(data).toLocaleString());
    //     }
    //     return data;
    // };

    // const getCommitsTableHeader = (header) => {
    //     return header;
    // };

    const getAPIData = async (sDate, eDate, repoId) => {
        const repoDetailsResponse = await getGitHubRepoDetailsReportData(
            keycloak.token,
            sDate,
            eDate,
            repoId
        );

        // const commitsResponse = await getGitHubCommitsReportData(
        //     keycloak.token,
        //     sDate,
        //     eDate,
        //     repoId
        // );
        AddUserActivity(keycloak, context, "FEATURE", "Event bridge Insights", repoDetailsResponse);
        // AddUserActivity(keycloak, context, "FEATURE", "Event bridge Insights", commitsResponse);

        setIsAPIDataLoading(false);
        setApiLoading(false);
        setErrorMessage(updateErrorMessage(repoDetailsResponse));
        setAPIData(updateAPIData(repoDetailsResponse));

        // setIsCommitsAPIDataLoading(false);
        // setCommitsApiLoading(false);
        // setCommitsErrorMessage(updateErrorMessage(commitsResponse));
        // setCommitsAPIData(updateAPIData(commitsResponse));
    };

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-") + " 00:00";
    };

    const onStartDateChange = (e) => {
        setStartDate(formatDate(new Date(e[0]).toDateString()));
        if (e.length > 1) {
            setEndDate(formatDate(new Date(e[1]).toDateString()));
        }
    };

    const updateAPI = async () => {
        let repoId = JSON.parse(sessionStorage.getItem("gitHubRepo")).repository_id;

        setApiLoading(true);
        // setCommitsApiLoading(true);
        await getAPIData(startDate, endDate, repoId);
    };

    const getHeadingAndDateFilter = () => {
        return (
            <>
                <Column lg={8}>
                    <h3>Repo Details</h3>
                    <p>{repoName}</p>
                </Column>
                <Column lg={8} className="flex-display flex-end override-form-flex">
                    <ErrorBoundary fallback={"Data is unavailable"}>
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="range"
                            onChange={(e) => onStartDateChange(e)}
                            className="date-picker--margin"
                        >
                            <DatePickerInput
                                id="date-picker-range-start"
                                placeholder="yyyy-mm-dd"
                                labelText="Start Date"
                                defaultValue={defaultStartDate}
                                type="text"
                            />
                            <DatePickerInput
                                id="date-picker-range-end"
                                placeholder="yyyy-mm-dd"
                                labelText="End Date"
                                defaultValue={defaultEndDate}
                                type="text"
                            />
                        </DatePicker>
                    </ErrorBoundary>
                    <span>
                        {apiLoading
                            // || commitsApiLoading 
                            ? (
                                <InlineLoading
                                    status="active"
                                    iconDescription="Loading"
                                    description="Loading data..."
                                    className="report-inline--github"
                                />
                            ) : (
                                <Button
                                    kind="primary"
                                    onClick={updateAPI}
                                    size="md"
                                    iconDescription="Icon Description"
                                    className="report-update__button--github"
                                >
                                    Update
                                </Button>
                            )}
                    </span>
                </Column>
            </>
        );
    };


    const createChartExplicit = () => {
        return (
            <>
                {APIData.data.charts[3].data.rowData &&
                    APIData.data.charts[3].data.rowData.length > 0 ? (
                    <>
                        <Column lg={16} className="common-top-margin">
                            {APIData.data.charts[3].data.rowData &&
                                APIData.data.charts[3].data.rowData.length > 0 ? (
                                <ErrorBoundary fallback={<ErrorWhileFetching />}>
                                    <CarbonDataTable
                                        rowData={APIData.data.charts[3].data.rowData}
                                        headerData={APIData.data.charts[3].data.headerData}
                                        title={APIData.data.charts[3].label}
                                        // getRowCellData={getCommitsRowCellData}
                                        // getTableHeader={getCommitsTableHeader}
                                        getRowCellData={getRowCellData}
                                        getTableHeader={getTableHeader}
                                        actionsNeeded={false}
                                    />
                                </ErrorBoundary>
                            ) : (
                                "Data is unavailable!"
                            )}
                        </Column>
                    </>
                ) : (
                    <Column lg={16} md={8} sm={4}>
                        <DataUnAvailable />
                    </Column>
                )}
            </>
        );
    };

    const loadSkeleton = () => {
        // return
        // isCommitsAPIDataLoading || 
        return isAPIDataLoading ? (
            <div className="skeletonPlaceholder--it-health">
                <SkeletonPlaceholder />
            </div>
        ) : (
            <>
                <ErrorWhileFetching errMessage={errMessage} />
                {/* <ErrorWhileFetching errMessage={commitsErrMessage} /> */}
            </>
        );
    };


    const setChartOptions = (data) => {
        if (data.aggregationType === "Percentage") {
            const axes = data.data.chartOptions.axes;

            Object.entries(axes).forEach(([key, value]) => {
                if (value.add_percenatage) {
                    axes[key].ticks = axes[key].ticks || {};
                    axes[key]["ticks"]["formatter"] = (n) => `${n}%`;
                }
            });
        }
        return data;
    };

    const getTotalItems = (data) => {
        return data?.total ? data.total : data?.chartData?.length;
    };

    const getData = () => {
        // let chartData = JSON.parse(sessionStorage.getItem("gitHubRepoData"))?.charts;
        // let tileData = JSON.parse(sessionStorage.getItem("gitHubRepoData"))?.tiles;
        let chartData = APIData.data.charts;
        let tileData = APIData.data.tiles;

        return (
            <>
                {getHeadingAndDateFilter()}

                {/* <Grid style={{ "paddingLeft": "0rem" }}> */}
                {Array.isArray(tileData) &&
                    tileData.length > 0 &&
                    tileData.map((element) => {
                        {
                            return (
                                <Column lg={2} md={8} sm={4}>
                                    <Tile className="chart-container">
                                        <ErrorBoundary fallback={"Data is unavailable"}>
                                            <TextBox data={element}></TextBox>
                                        </ErrorBoundary>
                                    </Tile>
                                </Column>
                            )
                        }
                    })
                }
                {
                    chartData.map((res, index) => {
                        return (
                            res &&
                            <Column
                                lg={res["col-size"]}
                                md={4}
                                sm={4}
                            >
                                {res.data && (
                                    <ErrorBoundary fallback={"Data is unavailable"}>
                                        {res.data.chartData ? (
                                            // <ChartContainer data={res} />
                                            <ChartContainer
                                                // getRowCellData={getRowCellData}
                                                // getTableHeader={getTableHeader}
                                                // isFetching={isFetching}
                                                data={setChartOptions(res)}
                                                totalItems={getTotalItems(res.data)}
                                            />
                                        )
                                            // :
                                            //  res.data.rowData ?
                                            //     <CarbonDataTable
                                            //         rowData={res.data.rowData}
                                            //         headerData={res.data.headerData}
                                            //         title="Open Pull Requests"
                                            //         getRowCellData={getRowCellData}
                                            //         getTableHeader={getTableHeader}
                                            //         actionsNeeded={false}
                                            //         toolbarNeeded={false}
                                            //     />
                                            :
                                            ""
                                        }
                                    </ErrorBoundary>
                                )}
                            </Column>
                        );
                    })
                }
                {/* </Grid> */}
                {createChartExplicit()}
            </>
        );
    };

    return (
        <div className="container-multichart">
            <ErrorBoundary fallback={<ErrorFallback />}>
                {APIData ? <Grid>{getData()}</Grid> : <Grid><Column lg={16}>{loadSkeleton()}</Column></Grid>}
                {showPopUP ? (
                    <Modal
                        open
                        size="lg"
                        passiveModal
                        onRequestClose={() => setShowPopUP(false)}
                        modalHeading="Pull Request Details"
                    >
                        {getPopupData()}
                    </Modal>
                ) : (
                    ""
                )}
                {/* {showCommitsPopUP ? (
                    <Modal
                        open
                        size="lg"
                        passiveModal
                        onRequestClose={() => setShowCommitsPopUP(false)}
                        modalHeading="Commit Details"
                    >
                        {getCommitsPopupData()}
                    </Modal>
                ) : (
                    ""
                )} */}
            </ErrorBoundary>
        </div>
    );
};

export default GitHubRepoDetailsReport;
