import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import KenDashboard from "./JobDashboard";
import "./JobObserv.scss";

const JobIndex = (props) => {
  return (
    <div className="att-dash">
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <KenDashboard title="Job Dashboard" />
          </Column>
        </Row>
      </>
    </div>
  );
};

export default JobIndex;
