import {
  Button,
  Column,
  ErrorBoundary,
  FlexGrid,
  Layer,
  RadioButton,
  RadioButtonGroup,
  Row,
  SkeletonPlaceholder,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tile,
} from "@carbon/react";
import { LogoFacebook } from "@carbon/react/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TopologyGraphAligned from "./TopologyGraphAligned";
import "./nbp.scss";

//AllDay
import DayTopologyGraphAdMgmt from "./DayCharts/AllDay/topologygraphAdMgmt.json";
import DayTopologyGraphCartMgmt from "./DayCharts/AllDay/topologygraphCartMgmt.json";
import DayTopologyGraphOrderMgmt from "./DayCharts/AllDay/topologygraphOrderMgmt.json";
import DayTopologyGraphPaymentGateway from "./DayCharts/AllDay/topologygraphPaymentGateway.json";
import DayTopologyGraphProductMgmt from "./DayCharts/AllDay/topologygraphProductMgmt.json";

import daychart1JSON from "./DayCharts/AllDay/chart1.json";
import daychart2JSON from "./DayCharts/AllDay/chart2.json";
import daychart3JSON from "./DayCharts/AllDay/chart3.json";
import daychart5JSON from "./DayCharts/AllDay/chart5.json";
import daychart6JSON from "./DayCharts/AllDay/chart6.json";

//Day1

import Day1TopologyGraphAdMgmt from "./DayCharts/day1/topologygraphAdMgmt.json";
import Day1TopologyGraphCartMgmt from "./DayCharts/day1/topologygraphCartMgmt.json";
import Day1TopologyGraphOrderMgmt from "./DayCharts/day1/topologygraphOrderMgmt.json";
import Day1TopologyGraphPaymentGateway from "./DayCharts/day1/topologygraphPaymentGateway.json";
import Day1TopologyGraphProductMgmt from "./DayCharts/day1/topologygraphProductMgmt.json";

import day1chart1JSON from "./DayCharts/day1/chart1.json";
import day1chart2JSON from "./DayCharts/day1/chart2.json";
import day1chart3JSON from "./DayCharts/day1/chart3.json";
import day1chart5JSON from "./DayCharts/day1/chart5.json";
import day1chart6JSON from "./DayCharts/day1/chart6.json";

//Day2
import Day2TopologyGraphAdMgmt from "./DayCharts/day2/topologygraphAdMgmt.json";
import Day2TopologyGraphCartMgmt from "./DayCharts/day2/topologygraphCartMgmt.json";
import Day2TopologyGraphOrderMgmt from "./DayCharts/day2/topologygraphOrderMgmt.json";
import Day2TopologyGraphPaymentGateway from "./DayCharts/day2/topologygraphPaymentGateway.json";
import Day2TopologyGraphProductMgmt from "./DayCharts/day2/topologygraphProductMgmt.json";

import day2chart1JSON from "./DayCharts/day2/chart1.json";
import day2chart2JSON from "./DayCharts/day2/chart2.json";
import day2chart3JSON from "./DayCharts/day2/chart3.json";
import day2chart5JSON from "./DayCharts/day2/chart5.json";
import day2chart6JSON from "./DayCharts/day2/chart6.json";

//Day3
import Day3TopologyGraphAdMgmt from "./DayCharts/day3/topologygraphAdMgmt.json";
import Day3TopologyGraphCartMgmt from "./DayCharts/day3/topologygraphCartMgmt.json";
import Day3TopologyGraphOrderMgmt from "./DayCharts/day3/topologygraphOrderMgmt.json";
import Day3TopologyGraphPaymentGateway from "./DayCharts/day3/topologygraphPaymentGateway.json";
import Day3TopologyGraphProductMgmt from "./DayCharts/day3/topologygraphProductMgmt.json";

import day3chart1JSON from "./DayCharts/day3/chart1.json";
import day3chart2JSON from "./DayCharts/day3/chart2.json";
import day3chart3JSON from "./DayCharts/day3/chart3.json";
import day3chart5JSON from "./DayCharts/day3/chart5.json";
import day3chart6JSON from "./DayCharts/day3/chart6.json";

//Day 4
import Day4TopologyGraphAdMgmt from "./DayCharts/day4/topologygraphAdMgmt.json";
import Day4TopologyGraphCartMgmt from "./DayCharts/day4/topologygraphCartMgmt.json";
import Day4TopologyGraphOrderMgmt from "./DayCharts/day4/topologygraphOrderMgmt.json";
import Day4TopologyGraphPaymentGateway from "./DayCharts/day4/topologygraphPaymentGateway.json";
import Day4TopologyGraphProductMgmt from "./DayCharts/day4/topologygraphProductMgmt.json";

import day4chart1JSON from "./DayCharts/day4/chart1.json";
import day4chart2JSON from "./DayCharts/day4/chart2.json";
import day4chart3JSON from "./DayCharts/day4/chart3.json";
import day4chart5JSON from "./DayCharts/day4/chart5.json";
import day4chart6JSON from "./DayCharts/day4/chart6.json";

//Day 5
import Day5TopologyGraphAdMgmt from "./DayCharts/day5/topologygraphAdMgmt.json";
import Day5TopologyGraphCartMgmt from "./DayCharts/day5/topologygraphCartMgmt.json";
import Day5TopologyGraphOrderMgmt from "./DayCharts/day5/topologygraphOrderMgmt.json";
import Day5TopologyGraphPaymentGateway from "./DayCharts/day5/topologygraphPaymentGateway.json";
import Day5TopologyGraphProductMgmt from "./DayCharts/day5/topologygraphProductMgmt.json";

import day5chart1JSON from "./DayCharts/day5/chart1.json";
import day5chart2JSON from "./DayCharts/day5/chart2.json";
import day5chart3JSON from "./DayCharts/day5/chart3.json";
import day5chart5JSON from "./DayCharts/day5/chart5.json";
import day5chart6JSON from "./DayCharts/day5/chart6.json";

// Day6
import Day6TopologyGraphAdMgmt from "./DayCharts/day6/topologygraphAdMgmt.json";
import Day6TopologyGraphCartMgmt from "./DayCharts/day6/topologygraphCartMgmt.json";
import Day6TopologyGraphOrderMgmt from "./DayCharts/day6/topologygraphOrderMgmt.json";
import Day6TopologyGraphPaymentGateway from "./DayCharts/day6/topologygraphPaymentGateway.json";
import Day6TopologyGraphProductMgmt from "./DayCharts/day6/topologygraphProductMgmt.json";

import day6chart1JSON from "./DayCharts/day6/chart1.json";
import day6chart2JSON from "./DayCharts/day6/chart2.json";
import day6chart3JSON from "./DayCharts/day6/chart3.json";
import day6chart5JSON from "./DayCharts/day6/chart5.json";
import day6chart6JSON from "./DayCharts/day6/chart6.json";

import {
  BubbleChart,
  ComboChart,
  GaugeChart,
  GroupedBarChart,
} from "@carbon/charts-react";
import { useKeycloak } from "@react-keycloak/web";
import { getAnyCollection } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";

import ProgressBar from "./progressBar";

const CampaignDashboard = (props) => {
  const rootCauseBarChartRef = useRef(null);

  const [topologyPopup, setTopologyPopup] = useState(false);
  const [topologyType, setTopologyType] = useState("");
  const [selectedCardTitle, setSelectedCardTitle] = useState("");
  const [showCharts, setShowCharts] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [paymentType, setPaymentType] = useState("failure");
  const { keycloak } = useKeycloak();

  const [campaignDay, setCampaignDay] = useState("All");

  useEffect(() => {
    getCollectionList();
  }, []);

  useEffect(() => {
    setEventListerners();
  }, [chartData]);

  const getCollectionList = async () => {
    const response = await getAnyCollection(keycloak, "bizOps");
    setChartData(response.data.bizOps[0]);
  };

  const setEventListerners = () => {
    if (rootCauseBarChartRef.current) {
      rootCauseBarChartRef.current.chart.services.events.addEventListener(
        "bar-click",
        rootCauseBarChartOnClick
      );
    }
  };

  const rootCauseBarChartOnClick = ({ detail }) => {
    setCampaignDay(detail.datum.date.toString().substring(8, 10));
    const ele = document.getElementById(`campaignDay`);
    ele.scrollIntoView({ behavior: "smooth" });
  };

  const showTopology = (type) => {
    setTopologyType(type);
    setTopologyPopup(true);
  };

  const getColName = (colId) => {
    switch (colId) {
      case 1:
        return "Affiliate Ad Management";

      case 2:
        return "Product Management";

      case 3:
        return "Cart Management";

      case 4:
        return "Cart Management";

      case 5:
        return "Payment Gateway";

      case 6:
        return "Order Management";

      case 7:
        return "Order Management";
      default:
        return "";
    }
  };

  const onShowTraceList = (show) => {};

  const getTopologyGraph = (colId) => {
    switch (campaignDay) {
      case "All":
        switch (colId) {
          case "ad":
            return DayTopologyGraphAdMgmt;

          case "product":
            return DayTopologyGraphProductMgmt;

          case "cart":
            return DayTopologyGraphCartMgmt;

          case "payment":
            return DayTopologyGraphPaymentGateway;

          case "order":
            return DayTopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "01":
        switch (colId) {
          case "ad":
            return Day1TopologyGraphAdMgmt;

          case "product":
            return Day1TopologyGraphProductMgmt;

          case "cart":
            return Day1TopologyGraphCartMgmt;

          case "payment":
            return Day1TopologyGraphPaymentGateway;

          case "order":
            return Day1TopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "02":
        switch (colId) {
          case "ad":
            return Day2TopologyGraphAdMgmt;

          case "product":
            return Day2TopologyGraphProductMgmt;

          case "cart":
            return Day2TopologyGraphCartMgmt;

          case "payment":
            return Day2TopologyGraphPaymentGateway;

          case "order":
            return Day2TopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "03":
        switch (colId) {
          case "ad":
            return Day3TopologyGraphAdMgmt;

          case "product":
            return Day3TopologyGraphProductMgmt;

          case "cart":
            return Day3TopologyGraphCartMgmt;

          case "payment":
            return Day3TopologyGraphPaymentGateway;

          case "order":
            return Day3TopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "04":
        switch (colId) {
          case "ad":
            return Day4TopologyGraphAdMgmt;

          case "product":
            return Day4TopologyGraphProductMgmt;

          case "cart":
            return Day4TopologyGraphCartMgmt;

          case "payment":
            return Day4TopologyGraphPaymentGateway;

          case "order":
            return Day4TopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "05":
        switch (colId) {
          case "ad":
            return Day5TopologyGraphAdMgmt;

          case "product":
            return Day5TopologyGraphProductMgmt;

          case "cart":
            return Day5TopologyGraphCartMgmt;

          case "payment":
            return Day5TopologyGraphPaymentGateway;

          case "order":
            return Day5TopologyGraphOrderMgmt;
          default:
            return "";
        }

      case "06":
        switch (colId) {
          case "ad":
            return Day6TopologyGraphAdMgmt;

          case "product":
            return Day6TopologyGraphProductMgmt;

          case "cart":
            return Day6TopologyGraphCartMgmt;

          case "payment":
            return Day6TopologyGraphPaymentGateway;

          case "order":
            return Day6TopologyGraphOrderMgmt;
          default:
            return "";
        }
      default:
        return "";
    }
  };

  const topologyGraph = (selectedCardTitle) => {
    switch (topologyType) {
      case "ad":
        return (
          <TopologyGraphAligned
            data={getTopologyGraph("ad")}
            hideDateFilter={onShowTraceList}
            topologyTitle={selectedCardTitle}
          />
        );

      case "product":
        return (
          <TopologyGraphAligned
            data={getTopologyGraph("product")}
            hideDateFilter={onShowTraceList}
            topologyTitle={selectedCardTitle}
          />
        );

      case "cart":
        return (
          <TopologyGraphAligned
            data={getTopologyGraph("cart")}
            hideDateFilter={onShowTraceList}
            topologyTitle={selectedCardTitle}
          />
        );

      case "payment":
        return (
          <TopologyGraphAligned
            data={getTopologyGraph("payment")}
            hideDateFilter={onShowTraceList}
            topologyTitle={selectedCardTitle}
          />
        );

      case "order":
        return (
          <TopologyGraphAligned
            data={getTopologyGraph("order")}
            hideDateFilter={onShowTraceList}
            topologyTitle={selectedCardTitle}
          />
        );
      default:
        return "";
    }
  };

  const getChart = (chart) => {
    switch (campaignDay) {
      case "All":
        switch (chart) {
          case "1":
            return daychart1JSON;

          case "2":
            return daychart2JSON;

          case "3":
            return daychart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return daychart5JSON;

          case "6":
            return daychart6JSON;
          default:
            return "";
        }

      case "01":
        switch (chart) {
          case "1":
            return day1chart1JSON;

          case "2":
            return day1chart2JSON;

          case "3":
            return day1chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day1chart5JSON;

          case "6":
            return day1chart6JSON;
          default:
            return "";
        }

      case "02":
        switch (chart) {
          case "1":
            return day2chart1JSON;

          case "2":
            return day2chart2JSON;

          case "3":
            return day2chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day2chart5JSON;

          case "6":
            return day2chart6JSON;
          default:
            return "";
        }

      case "03":
        switch (chart) {
          case "1":
            return day3chart1JSON;

          case "2":
            return day3chart2JSON;

          case "3":
            return day3chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day3chart5JSON;

          case "6":
            return day3chart6JSON;
          default:
            return "";
        }

      case "04":
        switch (chart) {
          case "1":
            return day4chart1JSON;

          case "2":
            return day4chart2JSON;

          case "3":
            return day4chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day4chart5JSON;

          case "6":
            return day4chart6JSON;
          default:
            return "";
        }

      case "05":
        switch (chart) {
          case "1":
            return day5chart1JSON;

          case "2":
            return day5chart2JSON;

          case "3":
            return day5chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day5chart5JSON;

          case "6":
            return day5chart6JSON;
          default:
            return "";
        }

      case "06":
        switch (chart) {
          case "1":
            return day6chart1JSON;

          case "2":
            return day6chart2JSON;

          case "3":
            return day6chart3JSON;

          case "4":
            return paymentTypeChartOption();

          case "5":
            return day6chart5JSON;

          case "6":
            return day6chart6JSON;
          default:
            return "";
        }
      default:
        return "";
    }
  };

  const checkDataOfCampaignDay = (data, title) => {
    return data.filter((fil) => fil.title === title);
  };

  const paymentTypeChartOption = () => {
    const options = {
      title: "Sales by Payment type",
      grid: {
        x: {
          enabled: false,
        },
        y: {
          enabled: false,
        },
      },
      axes: {
        left: {
          mapsTo: "value",
          title: "Payment type",
        },
        bottom: {
          mapsTo: "date",
          scaleType: "time",
        },
      },
      color: {
        scale: {
          Wallet: "#012749",
          "Net Banking": "#bae6ff",
          "Credit Card": "gray",
          "Success Rate": "darkgreen",
          "Failure Rate": "red",
        },
      },
      height: "250px",
    };
    return options;
  };

  const paymentTypeSuccessOption = () => {
    const options = {
      title: "Sales by Payment Success",
      grid: {
        x: {
          enabled: false,
        },
        y: {
          enabled: false,
        },
      },
      axes: {
        left: {
          mapsTo: "value",
          title: "Payment type",
        },
        bottom: {
          mapsTo: "date",
          scaleType: "time",
        },
      },
      color: {
        scale: {
          Wallet: "#012749",
          "Net Banking": "#bae6ff",
          "Credit Card": "gray",
          "Success Rate": "darkgreen",
          "Failure Rate": "red",
        },
      },
      height: "250px",
    };
    return options;
  };

  const paymentTypeFailureOption = (data) => {
    let colorArr = ["#ff8389", "#fa4d56", "#da1e28"];

    let obj = {};
    data.forEach((val, index) => {
      if (val.value < 10) {
        obj[val.group] = colorArr[0];
      } else if (val.value >= 10 && val.value <= 30) {
        obj[val.group] = colorArr[1];
      } else {
        obj[val.group] = colorArr[2];
      }
    });
    const options = {
      title: "Sales by Payment Failure",
      grid: {
        x: {
          enabled: false,
        },
        y: {
          enabled: false,
        },
      },
      axes: {
        left: {
          mapsTo: "value",
          title: "Payment type",
        },
        bottom: {
          mapsTo: "date",
          scaleType: "time",
        },
      },
      color: {
        scale: {
          Wallet: "#012749",
          "Net Banking": "#d4bbff",
          "Credit Card": "#00539a",
        },
      },
      height: "250px",
    };
    return options;
  };

  const getChartData = (chart, title) => {
    switch (campaignDay) {
      case "All":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "2":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "3":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "4":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "5":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "6":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "7":
            return checkDataOfCampaignDay(chartData.allDay, title);

          case "8":
            return checkDataOfCampaignDay(chartData.allDay, title);
          default:
            return "";
        }

      case "01":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day1, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day1, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day1, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day1, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day1, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day1, title);
          default:
            return "";
        }

      case "02":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day2, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day2, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day2, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day2, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day2, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day2, title);
          default:
            return "";
        }

      case "03":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day3, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day3, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day3, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day3, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day3, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day3, title);
          default:
            return "";
        }

      case "04":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day4, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day4, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day4, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day4, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day4, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day4, title);
          default:
            return "";
        }

      case "05":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day5, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day5, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day5, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day5, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day5, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day5, title);
          default:
            return "";
        }

      case "06":
        switch (chart) {
          case "1":
            return checkDataOfCampaignDay(chartData.day6, title);

          case "2":
            return checkDataOfCampaignDay(chartData.day6, title);

          case "3":
            return checkDataOfCampaignDay(chartData.day6, title);

          case "4":
            return checkDataOfCampaignDay(chartData.day6, title);

          case "5":
            return checkDataOfCampaignDay(chartData.day6, title);

          case "6":
            return checkDataOfCampaignDay(chartData.day6, title);
          default:
            return "";
        }
      default:
        return "";
    }
  };

  const getGraph1 = () => {
    const chartJSON = getChart("1");
    const chartDetails = getChartData("1", "Campaign Progress");
    return (
      <Tile className="chart-container" style={{ height: "236px" }}>
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <GaugeChart
              data={chartDetails[0].chartData}
              options={chartJSON.options}
            ></GaugeChart>
          </div>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getGraph2 = () => {
    const chartJSON = getChart("2");
    const chartDetails = getChartData("2", "Conversion Rate");
    return (
      <Tile className="chart-container">
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <ComboChart
              ref={rootCauseBarChartRef}
              data={chartDetails[0].chartData}
              options={chartJSON.options}
            ></ComboChart>
          </div>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getGraph3 = () => {
    const chartJSON = getChart("3");
    const chartDetails = getChartData("3", "User Login vs Response Time");
    return (
      <Tile className="chart-container">
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <ComboChart
              data={chartDetails[0].chartData}
              options={chartJSON.options}
            ></ComboChart>
          </div>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getGraph4 = () => {
    const chartDetails = getChartData("4", "Sales by Payment type");
    const chartFailure = getChartData("4", "Sales by Payment Failure");
    const chartSuccess = getChartData("4", "Sales by Payment Success");
    const chartJSON =
      paymentType === "success"
        ? paymentTypeSuccessOption()
        : paymentType !== "failure"
        ? getChart("4")
        : paymentTypeFailureOption(chartFailure[0].chartData);
    const data =
      paymentType === "success"
        ? chartSuccess[0].chartData
        : paymentType !== "failure"
        ? chartDetails[0].chartData
        : chartFailure[0].chartData;
    return (
      <Tile className="chart-container">
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <GroupedBarChart data={data} options={chartJSON}></GroupedBarChart>
          </div>
          <RadioButtonGroup
            legendText=""
            name="radio-button-group"
            orientation="horizontal"
            className="chart-check"
            defaultSelected="failure"
          >
            <RadioButton
              labelText="Success"
              value="success"
              id="radio-1"
              onClick={() => setPaymentType("success")}
            />
            <RadioButton
              labelText="Failure"
              value="failure"
              id="radio-2"
              onClick={() => setPaymentType("failure")}
            />
          </RadioButtonGroup>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getGraph5 = () => {
    const chartJSON = getChart("5");
    const chartDetails = getChartData("5", "Device Exchange Program");
    return (
      <Tile className="chart-container">
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <ComboChart
              data={chartDetails[0].chartData}
              options={chartJSON.options}
            ></ComboChart>
          </div>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getGraph6 = () => {
    const chartJSON = getChart("6");
    const chartDetails = getChartData("6", "Sales by plan");
    return (
      <Tile className="chart-container">
        <ErrorBoundary fallback={"Data is unavailable"}>
          <div>
            <BubbleChart
              data={chartDetails[0].chartData}
              options={chartJSON.options}
            ></BubbleChart>
          </div>
        </ErrorBoundary>
      </Tile>
    );
  };

  const getInfer = (campaign) => {
    if (campaign === "All") {
      return chartData.inferences.map((inference) => {
        return (
          <li style={{ paddingTop: "5px", listStyle: "disc" }}>{inference}</li>
        );
      });
    } else {
      const inference = getChartData("1", "Inference");
      return inference[0].value.map((infer) => {
        return (
          <li style={{ paddingTop: "5px", listStyle: "disc" }}>{infer}</li>
        );
      });
    }
  };

  const getTopoColName = (colId) => {
    switch (colId) {
      case 1:
        return "ad";

      case 2:
        return "product";

      case 3:
        return "cart";

      case 4:
        return "cart";

      case 5:
        return "payment";

      case 6:
        return "order";

      case 7:
        return "order";
      default:
        return "";
    }
  };

  const enableTopology = (e) => {
    if (![0, 8].includes(e)) {
      setTabIndex(1);
      showTopology(getTopoColName(e));
      setSelectedCardTitle(getColName(e));
    }
  };

  const viewAll = () => {
    setCampaignDay("All");
  };

  return (
    <>
      <FlexGrid className="bp--screen">
        <Row>
          <Column lg={4} md={4} sm={4}>
            {Object.keys(chartData).length > 0 ? (
              getGraph1()
            ) : (
              <Tile style={{ paddingTop: "2rem" }}>
                <SkeletonPlaceholder />
              </Tile>
            )}
          </Column>
          <Column
            lg={12}
            md={12}
            sm={12}
            className="no--right__padding"
            style={{ marginTop: "1rem", background: "white" }}
          >
            <Row>
              <Column lg={6} md={6} sm={6} className="no--right__padding">
                {Object.keys(chartData).length > 0 ? (
                  <>
                    <Layer>
                      <p style={{ fontSize: "17px" }}>Program KPI's</p>
                    </Layer>
                    <Layer>
                      <div className="title-day">
                        Day <strong>6</strong> of <strong>10 </strong>
                      </div>
                    </Layer>
                  </>
                ) : (
                  <Tile>
                    <SkeletonPlaceholder />
                  </Tile>
                )}
              </Column>
              <Column lg={6} md={6} sm={6} className="no--right__padding">
                {Object.keys(chartData).length > 0 ? (
                  <Layer style={{ marginTop: "3.2rem" }}>
                    <p>
                      <ul>
                        {chartData.campaignDetails.map((campaignData) => {
                          return (
                            <li>
                              <span>
                                {campaignData.title}
                                <span style={{ color: campaignData.color }}>
                                  <strong
                                    style={{
                                      marginLeft: "2px",
                                      marginRight: "2px",
                                    }}
                                  >
                                    {campaignData.value}
                                  </strong>
                                </span>{" "}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      <ul>
                        <li>
                          <span>Ecommerce platform: Telco Online Store</span>
                        </li>
                        <li>
                          <span>
                            Ad partner:
                            <LogoFacebook
                              size={30}
                              style={{ marginBottom: "-15px", fill: "#4267B2" }}
                            />
                          </span>
                        </li>
                      </ul>
                    </p>
                  </Layer>
                ) : (
                  <Tile>
                    <SkeletonPlaceholder />
                  </Tile>
                )}
              </Column>
              <Column lg={4} md={4} sm={4} className="no--right__padding">
                {Object.keys(chartData).length > 0 ? (
                  <img
                    src={
                      require(`Assets/aws/bp/campaign.png`)
                    }
                    alt="Not Available"
                    style={{
                      height: "12rem",
                      marginTop: "1.5rem",
                      marginLeft: "-5rem",
                    }}
                  />
                ) : (
                  <Tile>
                    <SkeletonPlaceholder />
                  </Tile>
                )}
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column lg={12} md={12} sm={12} className="no--right__padding">
            {Object.keys(chartData).length > 0 ? (
              getGraph2()
            ) : (
              <Tile style={{ paddingTop: "4rem" }}>
                <SkeletonPlaceholder />
              </Tile>
            )}
          </Column>
          <Column lg={4} md={4} sm={4} className="no--right__padding">
            <Tile className="chart-container">
              {Object.keys(chartData).length > 0 ? (
                <ErrorBoundary fallback={"Data is unavailable"}>
                  <h5>Inferences - Day {campaignDay}</h5>
                  <div style={{ height: "225px" }}>
                    <ul style={{ paddingLeft: "10px" }}>
                      {getInfer(campaignDay)}
                    </ul>
                  </div>
                </ErrorBoundary>
              ) : (
                <Tile>
                  <SkeletonPlaceholder />
                </Tile>
              )}
            </Tile>
          </Column>
        </Row>
        {showCharts === true && Object.keys(chartData).length > 0 ? (
          <>
            <Row id="campaignDay" className="topo-row">
              <Column lg={4} md={2} sm={2} className="no--right__padding">
                <div className="days">
                  {campaignDay !== "All" && `Day ${campaignDay}`}
                </div>
                <ProgressBar
                  enableTopology={enableTopology}
                  campaignDay={campaignDay}
                />
              </Column>
              <Column
                lg={12}
                md={8}
                sm={4}
                className="no--right__padding tab-view telco"
              >
                <Tabs
                  selectedIndex={tabIndex}
                  onChange={(e) => {
                    setTabIndex(e.selectedIndex);
                  }}
                >
                  <TabList aria-label="List of tabs">
                    <Tab>Charts</Tab>
                    <Tab disabled={!topologyPopup}>Topology View</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {tabIndex === 0 && (
                        <Row className="tab-rows">
                          <Column
                            lg={8}
                            md={8}
                            sm={4}
                            className="no--right__padding"
                          >
                            {getGraph3()}
                          </Column>
                          <Column
                            lg={8}
                            md={8}
                            sm={4}
                            className="no--right__padding"
                          >
                            {getGraph4()}
                          </Column>
                          <Column
                            lg={8}
                            md={8}
                            sm={4}
                            className="no--right__padding"
                          >
                            {getGraph5()}
                          </Column>
                          <Column
                            lg={8}
                            md={8}
                            sm={4}
                            className="no--right__padding"
                          >
                            {getGraph6()}
                          </Column>
                        </Row>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {topologyPopup && tabIndex === 1 && (
                        <div className="topoGraph">
                          {topologyGraph(selectedCardTitle)}
                        </div>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                {campaignDay !== "All" && (
                  <Button
                    className="viewAll"
                    kind="teritary"
                    size="sm"
                    onClick={() => viewAll()}
                  >
                    View All Day
                  </Button>
                )}
              </Column>
            </Row>
          </>
        ) : (
          ""
        )}
      </FlexGrid>
    </>
  );
};

export default CampaignDashboard;
