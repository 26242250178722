/** @format */

const LoginSuccessful = (state = false, action) => {
  if (action && action.type === "LOGIN_PAGE") {
    // console.log("action ------------", action)
    return {
      ...state,
      ...action.payload
    }
  }
  else {
    return state
  }
};
export default LoginSuccessful;
