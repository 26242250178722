import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CarbonDataTable from "Carbon-Components/DataTable";
import TilesLoader from "../TilesLoader";
import ErrorFallback from "Common-Modules/ErrorFallback";
import {
  convertDate,
  convertEpochToDateTime,
  convertUTCDate,
} from "Common-Modules/ConvertUTCDate";

const OOMS = ({ asset, cluster }) => {
  const [isCalculating, setIsCalculating] = useState(true);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const { data: oomDetails } = useQuery(["citi_oom"]);

  const headers = [
    {
      key: "host",
      header: "Host",
    },
    {
      key: "count",
      header: "Count",
    },
    {
      key: "timestamp",
      header: "Reported Date",
    },
    {
      key: "line",
      header: "Description",
    },
  ];

  useEffect(() => {
    try {
      const detailsCopy = JSON.parse(JSON.stringify(oomDetails.data));
      const rowData = detailsCopy
        .filter(
          (session) =>
            session.asset_class === asset && session.cluster[0] === cluster,
        )
        .map((session, index) => {
          session.id = String(index);
          const date = convertEpochToDateTime(session.timestamp);
          const formattedDate = convertDate(
            "dd-mm-yy",
            convertUTCDate(date),
            "created",
          );
          session.timestamp = formattedDate;
          return session;
        });
      setData(rowData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, []);

  const getRowCellData = (id, data, row) => {
    return data;
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    <CarbonDataTable
      rowData={data}
      headerData={headers}
      title="OOM Error Details"
      getRowCellData={getRowCellData}
      getTableHeader={(header) => header}
      actionsNeeded={false}
    />
  );
};

export default OOMS;
