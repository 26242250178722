import { ClickableTile, Column, ErrorBoundary, Row, SkeletonText, Tile, Tooltip } from "@carbon/react";
import { Information } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./kenvue.scss";

const SummaryAnalyze = (props) => {
    const { keycloak } = useKeycloak();
    const [tabTitle, setTabTitle] = useState(0);
    const [analyzeDetails, setAnalyzeDetails] = useState([]);

    const { data: analyzeData, isFetching } = useQuery(['kenvue_Analyze'], async () => await getAnyCollection(keycloak, uuidv4(), "kenvue_summary", "soc"))

    useEffect(() => {

        if (analyzeData !== undefined && analyzeData.data !== undefined) {
            const ana = analyzeData.data.kenvue_summary;
            const filterAna = ana.filter((res) => res.data.some((vu) => vu.incident === props.rowData[0].Number));
            setAnalyzeDetails(filterAna.length > 0 ? filterAna[0].data : [])
            
        }
    }, [analyzeData])

    const getSummary = () => {
        const summary = analyzeDetails.length > 0 ? analyzeDetails[0].data.filter((data) => data.key === "summary") : [];
        return summary.length > 0 ? <>
            {/* <div className="feedback">
                <Button kind="secondary" onClick={() => feedbackBtn("Accept")}>
                    {feedbackPop === "Accept" ? "Thank you" : <>Accept Suggestion <ThumbsUp /></>}
                </Button>
                <Button kind="tertiary" onClick={() => feedbackBtn("Reject")}>
                    Reject suggestion and give feedback <ThumbsDown />
                </Button>
            </div> */}
            {summary[0]["data"].map((summ) => {
                const newSum = Object.entries(summ).map(([key, value]) => ({ key, value }))
                return <p className="summary" key={uuidv4()}>
                    {
                        newSum.map((exe) => <>
                            <h5>{exe.key}</h5>
                            <ul>{exe.value.map((val) => <li key={`${exe.key}-${val}`}>{val}</li>)}</ul>
                        </>)
                    }
                </p>
            })}
        </> : isFetching ? [1,2,3].map(() => <> <SkeletonText /> <SkeletonText /> <br /> </>) : <p>Please enable GENAI capabillity</p>
    }

    const getDetailsAna = () => {
        const root = analyzeDetails.length > 0 ? analyzeDetails[0].data.filter((data) => data.key === "Detailed Analysis") : [];
        return root.length > 0 ? root[0]["data"].map((summ) => {
            const newSum = Object.entries(summ).map(([key, value]) => ({ key, value }))
            return <p className="summary" key={uuidv4()}>
                {
                    newSum.map((exe) => <>
                        <h5>{exe.key}</h5>
                        <ul>{exe.value.map((val) => <li key={`${exe.key}-${val}`}>{val}</li>)}</ul>
                    </>)
                }
            </p>
        }) : null
    }

    const getSimilarIn = () => {
        const simi = analyzeDetails.length > 0 ? analyzeDetails[0].data.filter((data) => data.key === "similar ticket") : [];
        return simi.length > 0 ? simi[0]["data"].map((similar) => {
            const newSim = Object.entries(similar).map(([key, value]) => ({ key, value }))
            return <ul className="err-log" key={uuidv4()}>{newSim.map((erlog) => {
                return <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5><span>{erlog.value}</span>
                </li>
            })}</ul>
        }) : <ul className="err-log"><li>No Similar Incidents Available</li></ul>
    }

    const getRootCause = () => {
        const root = analyzeDetails.length > 0 ? analyzeDetails[0].data.filter((data) => data.key === "rootcause") : [];
        return <p className="summary">
            <h5>Probable Rootcause</h5>
            <ul>
                {root.length > 0 ? root[0]["Probable Rootcause"].map((root) => <li key={root}>{root}</li>) : <li>No RootCause Available</li>}
            </ul>
        </p>
    }

    const getRecommend = () => {
        const root = analyzeDetails.length > 0 ? analyzeDetails[0].data.filter((data) => data.key === "resolution") : [];
        return root.length > 0 && root[0].data !== undefined ? root[0]["data"].map((summ) => {
            const newSum = Object.entries(summ).map(([key, value]) => ({ key, value }))
            return <p className="summary" key={uuidv4()}>
                {
                    newSum.map((exe) => <>
                        <h5>{exe.key}</h5>
                        <ul>{exe.value.map((val) => <li key={`${exe.key}-${val}`}>{val}</li>)}</ul>
                    </>)
                }
            </p>
        }) : <p className="summary">
            <h5>Recommended Resolution</h5>
            <ul>
                {root.length > 0 ? root[0]["Recommended Resolution"].map((root) => <li key={root}>{root}</li>) : <li>No Recommended Resolution</li>}
            </ul>
        </p>
    }

    const clickLeftSwitch = (index) => {
        setTabTitle(index)
    }


    return (
        <>
            <Tile
                style={{
                    background: "#c1c1c129",
                    padding: "1rem",
                    minHeight: "1rem",
                }}
            >
                <Row>
                    <Column lg={6} md={4} sm={2}>
                        <strong>Incident ID</strong> :{" "}
                        <>
                            {props.rowData[0].Number}{" "}
                            <Tooltip
                                description={props.rowData[0]["Short description"]}
                                align="bottom"
                            >
                                <button type="button" className="tooltip-button">
                                    <Information size={12} />
                                </button>
                            </Tooltip>
                        </>
                    </Column>
                    <Column lg={4} md={4} sm={2}>
                        <strong>Status</strong> : {props.rowData[0].State}
                    </Column>
                    <Column lg={4} md={4} sm={2}>
                        <strong>Severity</strong> : {props.rowData[0].Priority}
                    </Column>
                </Row>
            </Tile>
            <div className="verticaltabs">
                <div className="left-switch">
                    <ul>
                        <li><ClickableTile className={tabTitle === 0 ? "active" : ""} onClick={() => clickLeftSwitch(0)}><h6>Summary</h6></ClickableTile></li>
                        <li><ClickableTile className={tabTitle === 1 ? "active" : ""} onClick={() => clickLeftSwitch(1)}><h6>Detailed Analysis</h6></ClickableTile></li>
                        <li><ClickableTile className={tabTitle === 4 ? "active" : ""} onClick={() => clickLeftSwitch(4)}><h6>Similar Incidents</h6></ClickableTile></li>
                        <li><ClickableTile className={tabTitle === 2 ? "active" : ""} onClick={() => clickLeftSwitch(2)}><h6>Probable Root Cause</h6></ClickableTile></li>
                        <li><ClickableTile className={tabTitle === 3 ? "active" : ""} onClick={() => clickLeftSwitch(3)}><h6>Recommended Resolution</h6></ClickableTile></li>
                    </ul>
                </div>
                <Tile className="tile-switch">
                    {tabTitle === 0 ? <ErrorBoundary>
                        <div className="box-cont">
                            {getSummary()}
                        </div>
                        {/* <div className="box-cont1">
                            <h5><ShareKnowledge /> Owner group & Assignee</h5>
                            {getOwnerGrp()}
                        </div> */}
                    </ErrorBoundary> : null}
                    {tabTitle === 1 ? <ErrorBoundary> {getDetailsAna()} </ErrorBoundary> : null}
                    {tabTitle === 2 ? <ErrorBoundary> {getRootCause()} </ErrorBoundary> : null}
                    {tabTitle === 3 ? <ErrorBoundary> {getRecommend()} </ErrorBoundary> : null}
                    {tabTitle === 4 ? <ErrorBoundary> {getSimilarIn()} </ErrorBoundary> : null}
                </Tile>
            </div>
        </>
    )
}

export default SummaryAnalyze;