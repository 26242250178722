export const INCIDENT_ANALYTICS = "incident_analytics_ER";
export const INCIDENT_ANALYTICS_SUMMARY = [
  "incident_analytics_summary",
  "Summary",
];
export const INCIDENT_ANALYTICS_REPORT_TO = [
  "incident_analytics_reports_to",
  "reports_ticket_overview",
];
export const INCIDENT_ANALYTICS_REPORT_ST = [
  "incident_analytics_reports_st",
  "reports_similar_trend",
];

export const INCIDENT_ANALYTICS_REPORT_TREND = [
  "incident_analytics_reports_trend",
  "reports_trend",
];

export const INCIDENT_ANALYTICS_REPORT_OPEN_SUMMARY = [
  "incident_analytics_open_summary",
  "Open_Summary",
];
export const INCIDENT_ANALYTICS_REPORT_TICKET_SUMMARY = [
  "incident_analytics_ticket_summary",
  "Reports_Ticket_Summary",
];

export const INCIDENT_ANALYTICS_REPORT_SOLUTION_ANALYSIS = [
  "incident_analytics_solution_analysis",
  "Issue_to_Solution_Analysis",
];
export const INCIDENT_ANALYTICS_REPORT_DURATION = [
  "incident_analytics_reports_duration",
  "reports_duration",
];
export const INCIDENT_ANALYTICS_REPORT_FORECASTING = [
  "incident_analytics_reports_forecasting",
  "reports_forecasting",
];

export const INCIDENT_ANALYTICS_REPORT_HEATMAPANALYSIS = [
  "incident_analytics_reports_heat_maps",
  "reports_heat_maps",
];

export const INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS = [
  "incident_analytics_reports_probability_analysis",
  "probability_analysis",
];

export const INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS_FORCE_DIRECTED = [
  "incident_analytics_reports_probability_analysis_force_directed",
  "probability_analysis_force_directed",
];

export const INCIDENT_ANALYTICS_REPORT_TREE_CHART = [
  "incident_analytics_reports_tree_chart",
  "reports_tree_chart",
];