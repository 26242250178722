import { InlineLoading } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import * as pbi from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import { getPowerBiAccessToken } from "Services/ServerApi";
import { v4 as uuidv4 } from 'uuid';
import "./css/_powerbi.scss";

const PowerBi = (props) => {
  const [accessToken, setAccessToken] = useState(null);
  const [isPowerBILoading, setIsPowerBITokenLoading] = useState(null);
  const [errMessage, setErrorMessage] = useState("");
  const { keycloak } = useKeycloak();

  const onLoadFunc = async () => {
    const accessToken = await getPowerBiAccessToken(keycloak, uuidv4());
    setIsPowerBITokenLoading(false);
    if (accessToken.status >= 400 || accessToken === "Error") {
      // if bff fails
      setErrorMessage("Error while fetching data");
      setAccessToken("error");
    } else {
      setAccessToken(accessToken.data);
    }
  };

  useEffect(() => {
    onLoadFunc();
  }, []);

  return accessToken=== null || isPowerBILoading ? (
    <InlineLoading
      description="Loading Reports ... "
      className="event-loader"
    />
  ) : errMessage ? (
    { errMessage }
  ) : (
    <PowerBIEmbed
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        id: props.reportId,
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=" + props.reportId,
        accessToken: accessToken,
        tokenType: pbi.models.TokenType.Aad,
        pageName: props.pageName,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: pbi.models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={
        new Map([
          ["loaded"],
          ["rendered"],
          ["error"],
        ])
      }
      cssClassName={"iframeDiv"}
      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );
};

export default PowerBi;
