import ChartContainer from "Carbon-Charts/ChartContainer";
import React from "react";


const ATOChart = (props) => {

    const getChartData = (props) => {
        console.log("props.data ---- >>>>>", props.data)
        if (props.data !== undefined && props.data.chartData !== undefined && props.data.chartData.length > 0) {
            // const incident = props.serv.filter((ser) => ser["Engaged By"] !== "Alerting" && ser["state"] === "1")
            // const alertcnt = props.serv.filter((ser) => ser["Engaged By"] === "Alerting" && ser["state"] === "1")
            // const alert = props.serv.filter((ser) => ser["Engaged By"] === "Alerting")
            return props.data.chartData.map((chartRes) => {
                let data = null;
                let width = "32%", title="", chartTitle = "";
                if (chartRes.title === "Outage Prediction") {
                    title= "outage";
                    chartTitle = "Incidents";
                    data = {
                        dataType: "donut",
                        data: {
                            chartData: chartRes.values,
                            chartOptions: {
                                title: "Incidents",
                                resizable: true,
                                donut: {
                                    alignment: "center",
                                    center: {
                                        label: "",
                                    },
                                    legend: {
                                        enabled: false
                                    }
                                },
                                color: {
                                    scale: {
                                        Success: "#78a9ff",
                                        Failure: "#ff8389",
                                    },
                                },
                                toolbar: {
                                    enabled: false,
                                },
                                pie: {
                                    labels: {
                                        enabled: false
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                height: "100px",
                                "width": "100px"
                            },
                        },
                    };
                } else if (chartRes.title === "Journey Status") {
                    title= "journey";
                    chartTitle = "Alerts";
                    data = {
                        dataType: "donut",
                        data: {
                            chartData: chartRes.values,
                            chartOptions: {
                                title: "Alerts",
                                resizable: true,
                                donut: {
                                    alignment: "center",
                                    center: {
                                        label: "",
                                    },
                                    legend: {
                                        enabled: false
                                    }
                                },
                                color: {
                                    scale: {
                                        Success: "#78a9ff",
                                        Failure: "#ff8389",
                                    },
                                },
                                toolbar: {
                                    enabled: false,
                                },
                                pie: {
                                    labels: {
                                        enabled: false
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                height: "100px",
                                "width": "100px"
                            },
                        },
                    };
                }  else if (chartRes.title === "Component Health") {
                    title= "component";
                    chartTitle = "Component Health";
                    data = {
                        dataType: "donut",
                        data: {
                            chartData: chartRes.values,
                            chartOptions: {
                                title: "Component Health",
                                resizable: true,
                                donut: {
                                    alignment: "center",
                                    center: {
                                        label: "",
                                    },
                                    legend: {
                                        enabled: false
                                    }
                                },
                                color: {
                                    scale: {
                                        Success: "#78a9ff",
                                        Failure: "#ff8389",
                                    },
                                },
                                toolbar: {
                                    enabled: false,
                                },
                                pie: {
                                    labels: {
                                        enabled: false
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                height: "100px",
                                "width": "100px"
                            },
                        },
                    };
                } else if (chartRes.title === "Latency") {
                    title= "latency";
                    width = "100%";
                    data = {
                        dataType: "area",
                        data: {
                            chartData: chartRes.values,
                            chartOptions: {
                                title: chartRes.title,
                                axes: {
                                    bottom: {
                                        mapsTo: "key",
                                        scaleType: "labels"
                                    },
                                    left: {
                                        mapsTo: "value",
                                        scaleType: "linear",
                                    },
                                },
                                curve: "curveNatural",
                                height: "130px",
                                legend: {
                                    enabled: false
                                },
                                toolbar: {
                                    enabled: false,
                                },
                                
                                color: {
                                    scale: {
                                        Success: "#78a9ff",
                                        Fail: "#da1e28",
                                    },
                                },
                            },
                        },
                    };
                }
                return (
                    data !== null ? <div title={chartTitle} onClick={() => props.onClickAtoChart(chartTitle, props.dat.serv, props.dat)} className="ato-chart" style={{ width: width }} > <ChartContainer data={data} /> </div> : null 
                );
            });
        }
    }

    const getBasicInfo = (props) => {
        if (props.data !== undefined && props.data.tileData !== undefined && props.data.tileData.length > 0) {
            return <ul className="basic-info">{props.data.tileData.map((res) => <li><h5>{res.title}</h5><span>{res.value}</span></li>)}</ul>
        }
    }

    return (
        <>
            {getBasicInfo(props)}
            <div className="chart-box">{getChartData(props)}</div>
        </>
    )
}

export default ATOChart;



