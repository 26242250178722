import React from "react";
import CampaignDashboard from "./CampaignDashboard";

function NewProductIndex() {
  return (
    <>
      {/* <Grid>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">{t("common.home")}</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              {t("common.IT Value Stream Observability")}
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              {t("common.New Product Launch Campaign")}
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Grid> */}
      <div className="process-flow">
        <CampaignDashboard />
      </div>
    </>
  );
}

export default NewProductIndex;
