import { Column, Row, Tag } from '@carbon/react';
import { Close } from '@carbon/react/icons';
import React, { useEffect, useState } from 'react'

const Filters = ({filters, close}) => {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(false)
    },[])
    const getSelectedFilter = () => {
        const values = filters.split(",");
        let result = "";
        for (let i = 0; i < values.length; i += 2) {
          if (i !== 0) {
            result += ", ";
          }
          result += `${values[i]} = ${values[i + 1]}`;
        }
        return result;
      };
    if(isLoading){
        return null
    }
  return (
    <Row className="pl-05 pr-05">
        <Column lg={16} md={8} sm={4}>
        <Tag type="cool-gray" size="md">
            Filter: {getSelectedFilter()}{" "}
            <Close
                size={16}
                className="inline-svg"
                onClick={() => close()}
            />
        </Tag>
        </Column>
    </Row>
  )
}

export default Filters