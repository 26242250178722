/** @format */

import {
    Layer, Tile
} from "@carbon/react";
import React from "react";
import { InformationFilled } from "@carbon/react/icons/index"

const InfoWhileFetching = (props) => {
    return (
        < Tile className="chart-container">
            <Layer className="data_unavailable--layer">
                <InformationFilled />
                <h3 style={{ color: "gray" }}>Response Info</h3>
                <p>{props.errMessage ? props.errMessage : "No Data Available."}</p>
            </Layer>
        </Tile>
    );
}

export default InfoWhileFetching;