import {
  Button,
  Column,
  Modal,
  Row,
  SkeletonText,
  StructuredListSkeleton,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import CarbonTabs from "Carbon-Components/Tabs";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getApplicationData } from "Services/ServerApi";
import DataTable from "Carbon-Components/DataTable";
import Journey from "../assets/susops_2.jpg";
import "../css/dashboard.scss";
import Recommendations from "./Recommendations";
import SusopsRec from "./SusopsRec";
import { headers } from "./staticData/Applications";
import { v4 as uuidv4 } from "uuid";

const RecommTable = () => {
  const [showRec, setShowRec] = useState(false);
  const [popup, showPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [rowData, setRowData] = useState([]);
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    fetchAppdata();
  }, []);

  const fetchAppdata = async () => {
    setIsLoading(true);
    setApiError(false);
    setIsEmpty(false);
    const res = await getApplicationData(keycloak, "", uuidv4());
    if (Array.isArray(res?.data)) {
      if (res.data.length > 0) {
        try {
          const table_format = [];
          res.data.forEach((app) => {
            table_format.push({
              id: app.id,
              name: app.name,
              apptype: app?.appDetails?.apptype || "-",
              region: app?.appDetails?.region || "-",
              technology: app?.appDetails?.tech || "-",
              details: app?.appDetails?.details || "-",
            });
          });
          setRowData(table_format);
        } catch (error) {
          setApiError(true);
        }
      } else {
        setIsEmpty(true);
      }
    } else {
      setApiError(true);
    }
    setIsLoading(false);
  };

  const getRowCellData = (id, data, row) => {
    if (id.includes(":rec")) {
      return (
        <Button
          kind="ghost"
          className="susops-btn"
          size="sm"
          onClick={() => setShowRec(true)}
        >
          View
        </Button>
      );
    }
    return data;
  };

  if (isLoading) {
    return (
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <SkeletonText width="70%" />
            <SkeletonText width="30%" />
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={4} sm={4}>
            <StructuredListSkeleton />
          </Column>
        </Row>
      </>
    );
  }

  if (apiError) {
    return <ErrorRefresh refresh={() => fetchAppdata()} />;
  }

  if (isEmpty) {
    return (
      <div className="center-screen">
        <h4>Onboard Applications to view Dashboards</h4>
        <Button onClick={() => history.push("/susopsInstrumentation")}>
          Onboard Applications
        </Button>
      </div>
    );
  }

  return (
    <div className="recommendations-table">
      <div className="flex-display flex-end-hor">
        <Button
          kind="ghost"
          size="sm"
          className="susops-btn"
          onClick={() => showPopup(true)}
        >
          Journey to No Ops through AI
        </Button>
      </div>
      <DataTable
        rowData={rowData}
        headerData={headers}
        getRowCellData={getRowCellData}
        title="Applications"
        placeholder="Search for Applications"
        actionsNeeded={false}
        getTableHeader={(header) => header}
        toolbarNeeded={false}
      />
      {popup ? (
        <Modal
          open
          passiveModal
          size="md"
          onRequestClose={() => showPopup(false)}
        >
          <img
            src={Journey}
            alt="aiops journey"
            referrerpolicy="no-referrer"
            className="img-aiops"
          ></img>
        </Modal>
      ) : null}
      {showRec ? (
        <Modal
          open
          passiveModal
          size="lg"
          onRequestClose={() => setShowRec(false)}
        >
          <CarbonTabs
            tabIndex={0}
            selectionMode="automatic"
            type="default"
            Tablist={["SusOps", "Potential AIOps Solutions"]}
            TabPanelsList={[
              <SusopsRec key="susops" />,
              <Recommendations key="aiops" />,
            ]}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default RecommTable;
