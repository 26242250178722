import { Layer } from "@carbon/react";
import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
import React from "react";

const ChartLoader = () => {
  const styles = {
    padding: "0 2%",
    position: "absolute",
    top: "10%",
    right:"40%",
  };
  return (
    <Layer style={styles}>
      <InlineLoader status="active" description="Loading data..." />
    </Layer>
  );
};

export default ChartLoader;
