import React from "react";
import PowerBi from "Components/PowerBI/PowerBI";

function Insights() {
  return (
    <>
      <PowerBi reportId="a513a9c2-aafb-406a-96d3-bdb123177a0f" />
    </>
  );
}

export default Insights;
