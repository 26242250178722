import { ErrorBoundary, NumberInput, Stack, TextArea, TextInput } from '@carbon/react'
import { useKeycloak } from '@react-keycloak/web'
import { useMutation } from '@tanstack/react-query'
import InlineLoader from 'Carbon-Components/InlineLoader'
import DefaultModal from 'Carbon-Components/Modals/CommonModal'
import PassiveModal from 'Carbon-Components/Modals/PassiveModal'
import ErrorWhileFetching from 'Components/ICPSA/utilities/ErrorWhileFetching'
import { createSnowTicketDirectv } from 'Services/ServerApi'
import React, { useState } from 'react'
import { v4 as uuidv4 } from "uuid"

const CreateSnowTicket = ({ close, selected }) => {
  const [description, setDescriprion] = useState("")
  const [short_description, setShortDescriprion] = useState("")
  const [priority, setPriority] = useState(3)
  const [errMsg, setErrMsg] = useState("")
  const { keycloak } = useKeycloak()

  const {data: response, mutate, isSuccess, isLoading} = useMutation({
    mutationFn: (data) => createSnowTicketDirectv(keycloak, uuidv4(), data),
    onError: (error) => {
      try {
        setErrMsg(error.response.data.message)
      } catch (error) {
        setErrMsg("An Unexpected Error Occured")
      }
    }
  })

  const disable = priority < 1 || priority > 5 || isLoading || !short_description

  if(isLoading){
    return <PassiveModal>
      <InlineLoader description="Ticket is being Created..." />
    </PassiveModal>
  }

  if(errMsg){
    return <PassiveModal canCloseDetailsPopup={() => close()}>{errMsg}</PassiveModal>
  }

  if(isSuccess){
    return <PassiveModal heading={!response.data.error ? "Ticket Created Successfully": "Ticket Creation Failed"} canCloseDetailsPopup={() => close()}>
      {
        response.data.error
        ? response.data.msg
        : <>
          <p><strong>Status: </strong>{response.data.data.status}</p>
          <p><strong>Ticket Number: </strong>{response.data.data.Number}</p>
          <p><strong>System Id: </strong>{response.data.data.Sys_id}</p>
        </>
      }
    </PassiveModal>
  }

  return (
    <ErrorBoundary fallback={<PassiveModal canCloseDetailsPopup={() => close()}>
      <ErrorWhileFetching />
    </PassiveModal>}>
    <DefaultModal
        heading="Create Incident in Service Now"
        primaryText="Create"
        onCloseModal={() => close()}
        onModalSubmit={() => {
          let ticketNumber = uuidv4()
          ticketNumber = ticketNumber.replace(/-/g, "");
          mutate({
          "company": "DirecTV",
          "assignment_group": "DevSecOps-SOC",
          "additional_comments": "Created from IBM Consulting Aiops",
          "business_service": "AppOps-DirecTV",
          "caller": "Directv_Integration_User",
          "category": "general",
          "contact_type": "External Service Desk",
          "customer_sys_id": "abcde2fgded332devfepqfetxy1z",
          "customer_ticket_number": String(ticketNumber),
          // "customer_ticket_number": String("INC1155767"),
          "description": String(description),
          "priority": String(priority),
          "short_description": String(short_description),
          "work_notes": "test work notes",
          "ticket_type": "Incident"
        })}}
        primaryButtonDisabled={disable}
    >
        <Stack gap={5} className="form-field-max-width">
            <p><strong>Job Id: </strong> {selected.jobid}</p>
            <p><strong>Job Name: </strong> {selected.jobname}</p>
            <p><strong>Company: </strong> DirecTV</p>
            <p><strong>Assignment Group: </strong> DevSecOps-SOC</p>
            <p><strong>Business Service: </strong> AppOps-DirecTV</p>
            <NumberInput
                id="priority"
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(Number(e.target.value))}
                hideSteppers={true}
                min={1}
                max={5}
                invalid={priority < 1 || priority > 5}
                invalidText="Priority should between 1 and 5"
                helperText="Note: Priority should between 1 and 5."
            />
            <TextInput className="mb-1" onChange={(e) => setShortDescriprion(e.target.value)} value={short_description} id="shortDescription" placeholder="Enter Short Description Here ..." type="text" labelText="Short Description" />
            <TextArea
              id="description"
              labelText="Description (Optional)"
              placeholder="Enter Description Here..."
              value={description}
              onChange={(event) => setDescriprion(event.target.value)}
              maxLength={30}
            />
        </Stack>
    </DefaultModal>
    </ErrorBoundary>
  )
}

export default CreateSnowTicket