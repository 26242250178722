import React from 'react'
import ChartContainer from 'Carbon-Charts/ChartContainer';
import { monthNames } from './RowsHeaders';
import { accumulatedLegend, monthlyLegend, oneTimeLegend } from 'Constant';

const ActualAccumulated = ({data, year}) => {

  const order = [monthlyLegend, oneTimeLegend, accumulatedLegend, "Carbon Savings(kgCO₂eq)"]

  const sortedData = data.sort((a,b) => order.indexOf(b.group) - order.indexOf(a.group))

  const tickCount = data.filter(data => data.group === "Carbon Savings(kgCO₂eq)").length

  const options = {
    title: 'MoM Effort Saving and Reduction in CO₂',
    color:{
      scale: {
          'Carbon Savings(kgCO₂eq)' : "#198038",
          [accumulatedLegend]: "#012749",
          [oneTimeLegend]: "#ee5396",
          [monthlyLegend]: "#009d9a",
      }
    },
    timeScale: {
      addSpaceOnEdges: year ? 1 : 0
    },
    axes: {
      bottom: {
        title: 'Month',
        mapsTo: 'date',
        scaleType: 'time',
        ticks: year ? {
            formatter: ((tick) => `${monthNames[tick.getMonth()]} ${tick.getFullYear()}`),
            number: tickCount,
          } : {},
      },
      left: {
        mapsTo: 'value',
        title: 'Monthly Benefit (Hrs)',
        scaleType: 'linear',
        stacked: true
      },
      right: {
        mapsTo: 'carbonsaving',
        scaleType: 'linear',
        title: 'Reduction in Carbon Emission (kgCO₂eq)',
        correspondingDatasets: [
          'Carbon Savings(kgCO₂eq)'
        ]
      },
    },
    comboChartTypes: [
        {
          type: 'line',
          correspondingDatasets: ["Carbon Savings(kgCO₂eq)"]
        },
        {
          type: 'stacked-bar',
          correspondingDatasets: [monthlyLegend, oneTimeLegend, accumulatedLegend]
        }
      ],
    height: '350px',
    legend: {
      truncation: {
        type: "none"
      },
    },
    curve: 'curveMonotoneX'
}

  return (
    <ChartContainer
        data={{
            dataType: "combo",
            data:{
                chartData: sortedData,
                chartOptions: options 
            }
        }}
    />
  )
}

export default ActualAccumulated