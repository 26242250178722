const initialState = {
    tenantsList: []
  }
  
  export default function getTenants(state = initialState, action) {
    switch (action.type) {
      case "STORE_TENANTS":
        return ({
          ...state,
          tenantsList: action.payload
        });
      case "NEW_TENANT":
        return ({
          ...state,
          tenantsList: [...state.tenantsList, action.payload]
        })
      default:
        return state;
    }
  }
  
  