import { Button, ClickableTile, Column, Row, Tile } from "@carbon/react";
import CarbonDataTable from "Carbon-Components/DataTable";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import React, { useState } from "react";
import { CheckmarkFilled } from "@carbon/react/icons";
import FixSuggestion from "./FixSuggestion";

const ApplicationRecommendations = ({ data, refresh }) => {
  const [content, setContent] = useState("");
  const [suggestion, showSuggestion] = useState(false);
  const { tiles, tables } = data;

  const getTiles = () => {
    try {
      return (
        <Row>
          {Object.entries(tiles).map(([key, value]) => (
            <Column lg={2} md={2} sm={2}>
              <ClickableTile
                id={key}
                key={key}
                onClick={() => setContent(key)}
                aria-label={key}
                className={`app--issues--tiles relative ${
                  content === key ? "active--tile" : ""
                }`}
              >
                {content === key ? (
                    <CheckmarkFilled fill="#0062ff" className="top-right" />
                  ) : null}  
                <div>
                  <h3>{value}</h3>
                  <h5>{key}</h5>
                </div>
              </ClickableTile>
            </Column>
          ))}
        </Row>
      );
    } catch (error) {
      return <ErrorRefresh refresh={refresh} />;
    }
  };

  const getRowCellData = (id, data, row) => {
    if (id.includes(":id")) {
      return null;
    }
    if (id.includes(":action")) {
      const rowData = tables[content]["rowData"];
      let selectedRow;
      rowData.forEach((obj) => {
        if (obj.id === row.id) {
          selectedRow = { ...obj };
        }
      });
      return (
        <Button
          kind="ghost"
          size="sm"
          className="remove-left-padding"
          onClick={() => showSuggestion(selectedRow)}
        >
          Fix
        </Button>
      );
    }

    return data;
  };

  const getTables = () => {
    return (
      <>
        <Row>
          <Column lg={10} md={4} sm={4} className={"pr-05"}>
            <CarbonDataTable
              rowData={tables[content]["rowData"]}
              headerData={tables[content]["headerData"]}
              title=""
              getTableHeader={(header) => header}
              actionsNeeded={false}
              getRowCellData={getRowCellData}
            />
          </Column>
          <Column lg={6} md={4} sm={4} className="mt-0 pl-05">
            <Tile>
              <h4 className="fw-normal">Recommendation</h4>
              <ul className="dotted--list">
                <li>
                  Rebuild the base image with all available fixes - Reduces 177
                  vulnerabilities
                </li>
                <li>Upgrade application packages to latest version</li>
              </ul>
            </Tile>
          </Column>
        </Row>
      </>
    );
  };

  return (
    <div>
      {getTiles()}
      {content ? getTables() : null}
      {suggestion ? (
        <FixSuggestion
          close={() => showSuggestion(false)}
          selectedRow={suggestion}
        />
      ) : null}
    </div>
  );
};

export default ApplicationRecommendations;
