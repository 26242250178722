import * as d3 from "d3";
import React, { useEffect } from "react";

const ProbabilityAnalysis = ({ nodeData, linkData, environmentName }) => {
  useEffect(() => {
    createForceDirectedGraph();
  }, [nodeData, linkData]);

  const createForceDirectedGraph = () => {
    d3.select("#forceSvg").remove();
    // dimensions of the chart.
    const width = 1469;
    const height = 678;

    const links = linkData.map((d) => ({ ...d }));
    const nodes = nodeData.map((d) => ({ ...d }));

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.name)
          .distance(220)
      )
      .force("charge", d3.forceManyBody())
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "collide",
        d3.forceCollide(function (d) {
          return 25;
        })
      )
      .on("tick", ticked);

    const svg = d3
      .select("#force-directed")
      .append("svg")
      .attr("id", "forceSvg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr(
        "style",
        "max-width: 100%; height: auto; background:white; margin-top:5px"
      );

    const link = svg
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.8)
      .selectAll()
      .data(links)
      .join("line")
      .attr("stroke", (d) => getStrokeChild(d))
      .attr("stroke-width", (d) =>
        d.value === 0 ? 1 : Math.sqrt(d.value * Math.PI)
      );

    let linkLabel = svg
      .append("g")
      .style("fill", "#525252")
      .attr("font-size", "0.7em")
      .selectAll("text")
      .data(links)
      .enter()
      .append("text")
      .text((d) => d.value.toFixed(2));

    link.append("title").text((d) => d.value >= 0 && "Probability " + d.value);

    function getStrokeChild(d) {
      switch (d.target.name) {
        case "integration":
          return "#78a9ff";
        case "data problem":
          return "#be95ff";
        case "configuration":
          return "#33b1ff";
        case "technical changes":
          return "#08bdba";
        case "authorization issues":
          return "#ff8389";
        default:
          return "#999";
      }
    }

    const node = svg
      .append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll()
      .data(nodes)
      .enter()
      .append("g");
    node
      .append("circle")
      .attr("r", (d) => d.size)
      .attr("fill", (d) => d.color);

    node
      .append("text")
      .style("fill", "#8d8d8d")
      .attr("stroke", "none")
      .attr("width", "10")
      .attr("height", "10")
      .attr("font-size", "1em")
      .text(function (d) {
        if (d.name.length > 20) {
          return d.name.slice(0, 20) + "...";
        }
        return d.name;
      });

    node.append("title").text((d) => d.name);

    node.call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    );

    function ticked() {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("transform", (d) => `translate(${d.x},${d.y})`);

      if (environmentName !== "") {
        linkLabel
          .attr("x", (d) => (d.source.x + d.target.x) / 2)
          .attr("y", (d) => (d.source.y + d.target.y) / 2);
      }
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return svg.node();
  };
  return <div id="force-directed"></div>;
};

export default ProbabilityAnalysis;
