/** @format */

import {
  AreaChart,
  BubbleChart,
  BulletChart,
  CirclePackChart,
  ComboChart,
  DonutChart,
  GaugeChart,
  GroupedBarChart,
  HeatmapChart,
  HistogramChart,
  LineChart,
  LollipopChart,
  MeterChart,
  PieChart,
  SimpleBarChart,
  StackedAreaChart,
  StackedBarChart,
  TreeChart,
  TreemapChart,
  WordCloudChart,
} from "@carbon/charts-react";
import { Dropdown, ErrorBoundary, Layer, Tile } from "@carbon/react";
import CodeQualityMultiChartContainer from "./CodeQualityMultiChartContainer";
import DefectsMultiChartContainer from "./DefectsMultiChartContainer";
import TextBox from "./TextBox";
import HeatMapAnalyticsList from "./HeatMapAnalyticsList";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ComboWithdropdown from "./ChartsWithFilters/ComboWithdropdown";
import HeatMapWithDropDown from "./ChartsWithFilters/HeatMapWithDropDown";
import LineChartWithDropdowns from "./ChartsWithFilters/LineChartWithDropdowns";
import LineChartWithGroupDropdown from "./ChartsWithFilters/LineChartWithGroupDropdown";
import SparkLinewithFilter from "./ChartsWithFilters/SparkLineWithDrodown";
import DataTableForCharts from "./DataTableChart";
import StructuredListChart from "./StructuredListChart";
import ChartLoader from "./Utilities/ChartLoader";
import "./css/_chart.scss";

const ChartContainer = (props) => {
  const [dropdownList, setDrodownList] = useState(["sample", "test"]);
  const { t } = useTranslation();

  useEffect(() => {
    const highlight = document.getElementsByClassName("highlight-bar");
    for (let i = 0; i < highlight.length; i++) {
      highlight[i].setAttribute("id", `lights-${i}`);
      let ids = document.getElementById(`lights-${i}`);
      ids.style.transform = `translateX(${props.data.translateValue})`;
    }
  }, [props.data.data.chartData]);
  const getChart = (type) => {
    const chartOptions = props.data.data.chartOptions;
    switch (type) {
      case "bar":
        return (
          <GroupedBarChart
            data={props.data.data.chartData}
            options={chartOptions}
            ref={props.chartRef}
          ></GroupedBarChart>
        );
      case "lollipop":
        return (
          <LollipopChart
            data={props.data.data.chartData}
            options={chartOptions}
            ref={props.chartRef}
          ></LollipopChart>
        );
      case "histogram":
        return (
          <HistogramChart
            data={props.data.data.chartData}
            options={props.data.data.chartOptions}
            ref={props.chartRef}
          ></HistogramChart>
        );
      case "stackedArea":
        return (
          <StackedAreaChart
            data={props.data.data.chartData}
            options={props.data.data.chartOptions}
            ref={props.chartRef}
          ></StackedAreaChart>
        );
      case "simpleBar":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <SimpleBarChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></SimpleBarChart>
          </>
        );
      case "pie":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <PieChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></PieChart>
          </>
        );
      case "horizontal-bar":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <StackedBarChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></StackedBarChart>
          </>
        );
      case "combo":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <ComboChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></ComboChart>
          </>
        );
      case "comboWithDropdown":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <ComboWithdropdown
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></ComboWithdropdown>
          </>
        );
      case "meter":
        return (
          <MeterChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></MeterChart>
        );
      case "donut":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <DonutChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></DonutChart>
          </>
        );
      case "line":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <LineChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></LineChart>
          </>
        );
      case "sparkline":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <SparkLinewithFilter
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></SparkLinewithFilter>
          </>
        );
      case "lineWithDropdown":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <LineChartWithDropdowns
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></LineChartWithDropdowns>
          </>
        );
      case "lineWithGroupDropdown":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <LineChartWithGroupDropdown
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></LineChartWithGroupDropdown>
          </>
        );
      case "tree":
        return (
          <TreeChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></TreeChart>
        );
      case "treeMap":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <TreemapChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></TreemapChart>
          </>
        );
      case "heatMap":
        return (
          <HeatmapChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></HeatmapChart>
        );
      case "heatMapWithDropdown":
        return (
          <HeatMapWithDropDown
            data={props.data.data.chartData}
            options={chartOptions}
          ></HeatMapWithDropDown>
        );
      case "area":
        return (
          <AreaChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></AreaChart>
        );
      case "stacked-area":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <StackedAreaChart
              data={props.data.data.chartData}
              options={chartOptions}
            ></StackedAreaChart>
          </>
        );
      case "bubble":
        return (
          <>
            {props.isFetching && <ChartLoader />}
            <CirclePackChart
              data={props.data.data.chartData}
              options={chartOptions}
              ref={props.chartRef}
            ></CirclePackChart>
          </>
        );
      case "bullet":
        return (
          <BulletChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></BulletChart>
        );
      case "gauge":
        return (
          <GaugeChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></GaugeChart>
        );
      case "bubble-chart":
        return (
          <BubbleChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></BubbleChart>
        );
      case "wordCloud":
        return (
          <WordCloudChart
            data={props.data.data.chartData}
            options={chartOptions}
          ></WordCloudChart>
        );
      case "dataTableInsideChart":
        return <DataTableForCharts data={props.data.data} />;
      case "dataTableHeatMap":
        return <HeatMapAnalyticsList data={props.data.data.chartData[0]} />;
      case "structuredList":
        return <StructuredListChart data={props.data.data.chartData[0]} />;
      case "text":
        return <TextBox data={props.data.data}></TextBox>;
      case "report":
        return <TextBox data={props.data.data}></TextBox>;
      case "multi":
        return getMultiCharts(props.data.label);
      default:
        return;
    }
  };

  const getMultiCharts = (type) => {
    switch (type) {
      case "Defects":
        return <DefectsMultiChartContainer data={props.data} />;
      case "Code Quality":
        return <CodeQualityMultiChartContainer data={props.data} />;
      default:
        return;
    }
  };

  const getChartLoader = (message) => {
    return (
      <Layer className="chart-loader__layer">
        {message !== undefined ? message : "Data is unavailable"}
      </Layer>
    );
  };

  // console.log("belowTitle ===", props)

  return (
    <>
      {
        props.data.dataType === "structuredList" ?
          <StructuredListChart data={props.data.data.chartData[0]} />
          :
          <Tile
            className={`chart-container ${props.data.scrollNeeded ? "chart-scroll" : ""
              } ${props.data.minText ? "minText" : ""}`}
          >
            {props.data.dataType === "multi" ? <h5>{props.data.label}</h5> : ""}
            <ErrorBoundary fallback={"Data is unavailable"}>
              {props.data.dropdown && (
                <Dropdown
                  ariaLabel="Dropdown"
                  id="carbon-dropdown-example"
                  items={dropdownList}
                  itemToString={(item) => (item ? item : "")}
                  label={t(`common.selectSprint`)}
                  titleText={t(`common.selectSprint`)}
                  size="sm"
                  onChange={(e) => props.onDropdownChange(e)}
                />
              )}
              <div className={props.leftSection ? "show-chart" : ""}>
                {props.AddAdditional && (
                  <AreaChart
                    data={props.AddAdditional.data.chartData}
                    options={props.AddAdditional.data.chartOptions}
                  ></AreaChart>
                )}
                {props.data.data?.chartData?.length === 0 &&
                  props.data.data.chartOptions?.data?.loading ? (
                  getChart(props.data.dataType)
                ) : props.data.data?.chartData?.length > 0 ? (
                  getChart(props.data.dataType)
                ) : (
                  <>
                    {getChartLoader(props.message)}
                    <AreaChart
                      data={[]}
                      options={{
                        title: props.data.data.chartOptions.title,
                        axes: {
                          left: {},
                          bottom: {
                            scaleType: "labels",
                          },
                        },
                        toolbar: {
                          enabled: false,
                        },
                        height: props.data.data.chartOptions.height,
                      }}
                    ></AreaChart>
                  </>
                )}
              </div>
            </ErrorBoundary>
            {props.belowTitle !== undefined || props.data.belowTitle !== undefined && (
              <>
                <h6 className="belowTitle">{props.belowTitle}</h6>
                <h6 className="belowTit" style={{ "left": props.data.left }}>{props.data.belowTitle}</h6>
              </>
            )}
          </Tile>
      }
    </>
  );
};

export default ChartContainer;
