import { StackedAreaChart } from "@carbon/charts-react";
import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Link, Modal, Row, SkeletonPlaceholder, Tile } from "@carbon/react";
import { Report } from "@carbon/react/icons/index";
import ChartContainer from "Carbon-Charts/ChartContainer";
import DataTable from "Carbon-Components/DataTable";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Summarize from "./Bank/vertexSummary";
import KenvueElk from "./KenvueElk";
import valueStream from "./ValueStream.json";
import CommonMLModal from "./mlModal";
import TopologyGraph from "./valueStreamTopology";
import "./vs.scss";

const VSWorkflowView = (props) => {
    const { workflow } = useParams();
    const context = useContext(Context)
    const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
    const dateToday = new Date();
    const timestamp = Date.parse(dateToday);
    const history = useHistory();
    const [valueStreamData, setValueStream] = useState();
    const [showTopology, setShowTopology] = useState(false);
    const [showSummaryModal, setShowSummaryMd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showApps, setShowApps] = useState(false);
    const [bridgeId, sendBridgeId] = useState(null);
    const [showMsTeams, setShowMsTeams] = useState([]);
    const [dateChange, onDateChange] = useState(() => selecteDates ? selecteDates.time : {
        to: timestamp,
        windowSize: 5 * 60 * 1000
    });

    // const { data: valueStream } = useQuery(["valueStream"]);


    useEffect(() => {
        if (valueStream !== undefined && valueStream.data !== undefined) {
            const response = valueStream.data.valueStreamDetails.filter(
                (fil) => fil.title === context.valueStreamFlow
            );
            const filterWF = response[0].data.filter((fil) => fil["name"] === workflow)
            setValueStream(filterWF)
        }
    }, [valueStream, context])

    const getRows = (row) => {
        return valueStreamData.map((vs) => {
            return vs.workflowDetails.map((workflow) => {
                const row1 = workflow[row] !== undefined && workflow[row].map((row1, index) => {
                    return row === "Row2" ? getElement(row1, index, row) : <Column lg={2} md={2} sm={2} className="no--right__padding"> {getElement(row1, index, row)} </Column>
                })
                return <>
                    {row1}
                </>
            })
        })
    }

    const showApplication = () => {
        setShowApps(true)
    }

    const onClickShowTopology = (name) => {
        sessionStorage.setItem("CusJouStep", name)
        setShowTopology(true);
    };

    const getElement = (ele, index, row) => {
        if (ele.type === "tile") {
            return <Tile className="first-row-tile" style={row === "Row2" ? { height: "12rem" } : {}} onClick={ele.name === 'No. of Applications' ? () => showApplication() : null}>
                <h6>{ele.name}</h6>
                {Array.isArray(ele.value) ? ele.value.map((el) => {
                    return <Column lg={2} md={2} sm={2} key={uuidv4()}> <div className="interact">
                        <p>
                            Total <span>{el.Total}</span>
                        </p>
                        <div className="sec-4">
                            <h5>
                                Success <span>{el.Success}</span>
                            </h5>
                        </div>
                        <div className="sec-5">
                            <h5>
                                Fail <span>{el.Fail}</span>
                            </h5>
                        </div>
                    </div>
                    </Column>
                }) : <span className={`tile-value${index}`}>{ele.value}</span>}
            </Tile>
        } else if (ele.type === "chart") {
            return <Column lg={4} md={8} sm={4}><Tile className="add-border">{VolumeChart(ele.value, ele)}</Tile></Column>
        } else {
            return <Column lg={10} md={8} sm={4}>
                <Tile className="structred-list-table-view add-border">
                    <h6 className="cust-journey">Customer Journey Steps</h6>
                    <Row className="customer-journ">
                        {
                            ele.steps.map((workFlowDetails) => {
                                return (
                                    <Column
                                        lg={5}
                                        md={5}
                                        sm={2}
                                        className="no--right__padding"
                                        key={uuidv4()}
                                    >
                                        {workFlowDetails.value !== "" ? <Tile className="structred-list-table-view second-row-table-fss">
                                            <h5 style={{ paddingLeft: "3px", fontWeight: "600", fontSize: "0.75rem" }}>
                                                {workFlowDetails.name}
                                            </h5>
                                            <div
                                                className={`sec-row-detail ${workFlowDetails.value < 98 && "sec-row-detail-err"
                                                    }`}
                                                // {...(workFlowDetails.value < 98
                                                //   ? {
                                                //     onClick: () => {
                                                //       onClickShowTopology();
                                                //     },
                                                //   }
                                                //   : {})}
                                                onClick={workFlowDetails.value && workFlowDetails.value < 98  ? () => onClickShowTopology(workFlowDetails.name) : undefined}
                                            >
                                                <h5 className="value">{workFlowDetails.value}%</h5>
                                                <h6
                                                    className={`status ${workFlowDetails.value < 98 && "status-err"
                                                        }`}
                                                >
                                                    {workFlowDetails.status}
                                                </h6>
                                            </div>
                                        </Tile> : <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />}
                                    </Column>
                                );
                            })}
                    </Row>
                </Tile>
            </Column>
        }
    }

    const VolumeChart = (data, ele) => {
        if (ele.data) {
            return <ChartContainer data={ele.data} />
        }
        const options = {
            title: ele.name,
            color: {
                scale: {
                    "calls": "#78a9ff"
                },
            },
            axes: {
                left: {
                    mapsTo: "value",
                },
                bottom: {
                    mapsTo: "date",
                    scaleType: "time",
                    ticks: {
                        rotation: "always",
                    },
                },
            },
            timeScale: {
                localeObject: {
                    code: "tr",
                    formatLong: {},
                    localize: {},
                    match: {},
                    options: {
                        weekStartsOn: 1,
                        firstWeekContainsDate: 1,
                    },
                },
            },
            bars: {
                width: 5,
            },
            "toolbar": {
                "enabled": false
            },
            "legend": {
                "enabled": false
            },
            height: "150px",
            // theme: "g100",
        };

        return <StackedAreaChart data={data} options={options}></StackedAreaChart>;
    };

    const getThirdRows = (id) => {
        let newarr = [];
        valueStreamData.forEach((vs) => {
            vs.workflowDetails.forEach((workflow) => {
                workflow["Row3"] !== undefined && workflow["Row3"].forEach((row3) => {
                    row3.data.forEach((data) => {
                        Object.entries(data).forEach(([key, value]) => {
                            if (id === key) {
                                newarr = value
                            }
                        })
                    })
                })
            })
        })

        return newarr
    }

    const onSummaryModal = (details, rows) => {
        setShowSummaryMd(true);
        const rowData = getThirdRows("rows");
        const filterDa = rowData.filter((fil) => details === fil["Bridge_Id"])
        sendBridgeId(filterDa[0]);
    };

    const onIncidnetIdClick = (details, rows) => {
        const rowData = getThirdRows("rows");
        const filterDa = rowData.filter((fil) => details === fil["IncidentId"]);
        sendBridgeId(filterDa[0]);
        setShowModal(true);
    };

    const getRowCellData = (id, data, rows) => {
        // if (id.includes("label")) {
        //     return `/${data}`
        //   }
        //   if (id.includes("technologies")) {
        //     return data !== undefined ? data.map((tech) => {
        //       return tech
        //     }) : null
        //   }
        if (id.includes("Bridge_Id")) {
            return <div className="bridge-sum"
                onClick={() => onSummaryModal(data, rows)}>
                {data}
            </div>
        }
        if (id.includes("IncidentId")) {
            return <div className="bridge-sum"
                onClick={() => onIncidnetIdClick(data, rows)}>
                {data}
            </div>
        }
        return data
    }

    const onClickTeams = (details) => {
        let key = "";
        details.cells.forEach((cel) => {
            if (cel.id.includes("IncidentId")) {
                key = cel.value
            }
        })
        const rowData = getThirdRows("rows");
        const filterDa = rowData.filter((fil) => key === fil["IncidentId"]);
        setShowMsTeams(filterDa);
    };

    const getTableActions = (row, cell) => {
        return (
            <ul className="action-icon">
                <li>
                    {/* <a
                        // className={
                        //     eventSummaryDetails.status === "Open"
                        //         ? "active"
                        //         : "disable"
                        // }
                        href="https://ibm.webex.com/meet/hpramod"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt="webex"
                            src={
                                require(`../../assets/icons/tools/WebEx.png`)
                                    .default
                            }
                        />
                    </a> */}
                </li>
                <li
                    className="enable"
                    title="MS-Teams"
                    onClick={() => onClickTeams(row)}
                >
                    {/* <img
                        src={require(`../../assets/carbonIcons/ms-teams.svg`).default}
                        alt="ms-teams"
                        className="ms-teams"
                        width={20}
                        height={20}
                        style={{ cursor: "pointer" }}
                    /> */}
                </li>
                <li
                    title="Summary Details"
                // onClick={() =>
                //     onSummaryModal(eventSummaryDetails)
                // }
                >
                    <Report size={18} />
                </li>
            </ul>
        );
    };

    // const getApplication = () => {
    //     const title = sessionStorage.getItem("portfolioTitle")
    //     const port = portfolioDataJSON.portfolioData.filter((data) => data.title === title);
    //     return <ul className="app-cnt">{port[0].child.map((app) =>
    //         <li>{app.name}</li>
    //     )}</ul>;
    // }

    const redirectToIne = (page, node) => {
        let path = `/VsworkflowViewDetails/${workflow}`
        if(sessionStorage.getItem("CusJouStep") === "SAP Invoice creation") {
            path = `/Kenvue`
        }
        if (page === true) {
            // showInferencePage(true)
            history.push({
                pathname: path,
                state: { data: node, datechange: dateChange, flow: "value stream" },
            });
        }
    };

    const getApplication = () => {
        let newarr = [];
        valueStreamData.forEach((vs) => {
            vs.workflowDetails.forEach((workflow) => {
                workflow["Row1"] !== undefined && workflow["Row1"].forEach((row3) => {
                    if (row3.data !== undefined) {
                        newarr = row3.data
                    }
                })
            })
        })
        return <ul className="app-cnt">{newarr.map((app) =>
            <li key={app.name}>{app.name}</li>
        )}</ul>
    }

    const ShowSummaryMd = () => {
        setShowSummaryMd(false);
    };

    const getMsTeamsLink = (row) => {
        const msTeamChannel = [{
            "Number": "INC000029042604",
            "channel": "https://teams.microsoft.com/l/channel/19%3A41fe92514d6f45deb48ebe5ae5042d2f%40thread.tacv2/ken_inc0465403?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
        },
        {
            "Number": "INC000029072388",
            "channel": "https://teams.microsoft.com/l/channel/19%3A55a59fe41878494ab0727d4995ded081%40thread.tacv2/ken_inc0465415?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
        },
        {
            "Number": "INC000029072399",
            "channel": "https://teams.microsoft.com/l/channel/19%3Afda9d0197faf49e182a44f205c5f9c3d%40thread.tacv2/ken_inc0465427?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
        },
        {
            "Number": "INC000029064752",
            "channel": "https://teams.microsoft.com/l/channel/19%3A89ecea3b89e54a9b85780cf72a5f7436%40thread.tacv2/ken_inc0465435?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
        },
        {
            "Number": "INC000029064655",
            "channel": "https://teams.microsoft.com/l/channel/19%3Ae4d0555d292d43188deaee49d3c27e55%40thread.tacv2/ken_inc0465443?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
        }]

        const filterChanel = msTeamChannel.filter((chanel) => chanel.Number === row[0].IncidentId)
        return filterChanel.length > 0 ? <h5>Channel got created with link as <Link href={filterChanel[0].channel} target="_blank" rel="noopener noreferrer">Microsoft Teams</Link></h5> : "No Microsoft Teams Channel Created for this Incident"
    }

    return (
        <FlexGrid className="workflow-view">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        {context.valueStreamFlow !== "ViSecurity" ? <BreadcrumbItem href="#/valueStream">
                            IT Value Stream Observability
                        </BreadcrumbItem> : <BreadcrumbItem href="#/securityOps">
                            Application Secutiry
                        </BreadcrumbItem>}
                        {context.valueStreamFlow !== "ViSecurity" ? <BreadcrumbItem isCurrentPage>
                            {workflow}
                        </BreadcrumbItem> : null}
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={4} md={4} sm={4} className="no--right__padding">
                    <Tile className="first-row ">
                        <h3 className="workflow-title">
                            {sessionStorage.getItem("portfolioTitle")}
                        </h3>
                        <h5 className="title-desc">{sessionStorage.getItem("portfolioDescription") !== "undefined" ? sessionStorage.getItem("portfolioDescription") : null}</h5>
                    </Tile>
                </Column>
                {valueStreamData !== undefined ? getRows("Row1") : null}
            </Row>
            <Row>
                {valueStreamData !== undefined ? getRows("Row2") : null}
            </Row>
            <Row>
                <Column lg={16} md={10} sm={4} className="no--right__padding">
                    {valueStreamData !== undefined ? <DataTable
                        rowData={getThirdRows("rows")}
                        headerData={getThirdRows("header")}
                        title=""
                        getRowCellData={getRowCellData}
                        getTableHeader={(header) => header}
                        table_particular_actions={true}
                        actionsNeeded={false}
                        getActions={getTableActions}
                    ></DataTable> : null}
                </Column>
            </Row>
            {showModal && (
                <CommonMLModal setShowModal={setShowModal} ticketDetails={bridgeId} />
            )}
            {showSummaryModal && (
                <Summarize ShowSummaryMd={ShowSummaryMd} bridgeDetails={bridgeId} />
            )}
            {showTopology && (
                <Modal
                    open
                    size="lg"
                    passiveModal
                    isFullWidth
                    onRequestClose={() => setShowTopology(false)}
                    modalHeading="Topology View"
                    modalLabel="Application Topology View"
                    className="fss-topology"
                >
                    {context.valueStreamFlow !== "Kenvue" ?
                    <TopologyGraph flow={context.valueStreamFlow} redirectToIne={redirectToIne} valueStreamData={valueStreamData} dateChange={dateChange} />
                    : <KenvueElk cardClick={redirectToIne} /> }
                </Modal>
            )}

            {showApps ? <Modal
                open
                size="lg"
                passiveModal
                isFullWidth
                onRequestClose={() => setShowApps(false)}
                modalHeading="Applications"
                modalLabel=""
                className="fss-topology"
            >
                {getApplication()}
            </Modal> : null}

            {showMsTeams.length > 0 ? <PassiveModal heading={showMsTeams[0]["IncidentId"]} size="sm" canCloseDetailsPopup={() => setShowMsTeams([])}>
                        <div>
                            {getMsTeamsLink(showMsTeams)}
                        </div>
                    </PassiveModal> : null}
        </FlexGrid>
    )
}

export default VSWorkflowView;