import React from 'react'
import { Link } from 'react-router-dom'

const NoPermission = () => {
  return (
    <div className='center-screen'>
        <h3 className='error'>Unauthorized access</h3>
        <p className='bold'>We apologize for the inconvenience, but your are not authorized to visit this page. if you believe this is an error, please contact your Administrator</p>
        <p className='bold'>Click to Go <Link to="/">Home</Link></p>
    </div>
  )
}

export default NoPermission