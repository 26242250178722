import { useKeycloak } from '@react-keycloak/web';
import DangerModal from 'Carbon-Components/Modals/DangerModal';
import PassiveModal from 'Carbon-Components/Modals/PassiveModal';
// import DangerModal from 'Carbon-Components/Modal/DangerModal';
// import PassiveModal from 'Carbon-Components/Modal/PassiveModal';
import InlineLoader from 'Components/Common/loaderModule/InlineLoader';
// import InlineLoader from 'Components/Common/loaderModule/InlineLoader';
import React, { useState } from 'react';
// import { deleteApplication } from 'services/serverapi';

const DeleteUserModal = ({ cancel, app }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [resMsg, setResMsg] = useState("");
    const [refresh, setRefresh] = useState(false)
    const { keycloak } = useKeycloak();

    const deleteApp = async () => {
        // setIsLoading(true);
        // const res = await deleteApplication(keycloak, app.id, uuidv4());
        // if (res?.data?.AppDeleteStatus === "success") {
        // 	setResMsg("Successfully Removed Application");
        //     setRefresh(true)
        // }
        // else if(res?.data?.toolSetlinked !== 0){
        //     setResMsg(`Please delete the Toolsets linked to ${app.application_name} before deleting the Application.`);
        //     setRefresh(false)
        // }
        // else {
        // 	setResMsg("Error while Removing Application");
        //     setRefresh(false)
        // }
        // setIsLoading(false);
    };

    if (resMsg) {
        return <PassiveModal canCloseDetailsPopup={() => cancel(refresh)} >
            <h4>{resMsg}</h4>
        </PassiveModal>
    }
    return (
        <DangerModal
            primaryButtonDisabled={isLoading}
            heading="Are you sure you want to Delete?"
            primaryText="Delete"
            onCloseModal={() => cancel(false)}
            onModalSubmit={() => deleteApp()}
        >
            {isLoading
                ? <InlineLoader status="active" description="Deleting Application..." />
                : <>
                    <p className="mb-1">
                        <strong>Application Name: </strong> {app.name}</p>
                    <p>This action can't be reversed.</p>
                </>}
        </DangerModal>
    )
}

export default DeleteUserModal