/** @format */

import { Button, DataTable, DataTableSkeleton } from "@carbon/react";
import { Add, Renew } from "@carbon/react/icons";
import React from "react";
import { v4 as uuidv4 } from "uuid";
const {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;

const CarbonDataTableRBAC = (props) => {
  return (
    <>
      {props.rowData ? (
        <DataTable rows={props.rowData} headers={props.headerData}>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getBatchActionProps,
            onInputChange,
          }) => (
            <TableContainer title={props.title}>
              <TableToolbar>
                <TableToolbarContent>
                  <TableToolbarSearch
                    tabIndex={
                      getBatchActionProps().shouldShowBatchActions ? -1 : 0
                    }
                    onChange={onInputChange}
                    placeholder={
                      props.placeholder ? props.placeholder : "Filter Table"
                    }
                  />
                  {props.onRefresh && (
                    <div
                      onClick={() => props.onRefresh()}
                      className="refresh-nopadding"
                      role="button"
                    >
                      {props.showRefreshIcon && (
                        <Renew size={20} className="pointer" />
                      )}
                    </div>
                  )}
                  {props.createTitle && !props.disableCreate && (
                    <Button
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? -1 : 0
                      }
                      onClick={() => props.createFunction(true)}
                      kind="primary"
                      className="create--button"
                    >
                      {props.createTitle}
                      <Add />
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {props.getTableHeader(header.header)}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={uuidv4()}>
                          {props.getRowCellData(cell.id, cell.value, row)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      ) : (
        <DataTableSkeleton
          columnCount={5}
          rowCount={5}
          headers={props.headerData}
          showToolbar={false}
        />
      )}
    </>
  );
};

export default CarbonDataTableRBAC;
