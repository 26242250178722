import React from "react";
import { useTranslation } from 'react-i18next';
import TabsWithBottomNav from "Carbon-Components/TabsWithBottomNav";
import {
    INCIDENT_ANALYTICS_REPORT_ST,
    INCIDENT_ANALYTICS_REPORT_TO,
    INCIDENT_ANALYTICS_SUMMARY,
    INCIDENT_ANALYTICS_REPORT_OPEN_SUMMARY,
    INCIDENT_ANALYTICS_REPORT_SOLUTION_ANALYSIS,
    INCIDENT_ANALYTICS_REPORT_TREND,
    INCIDENT_ANALYTICS_REPORT_FORECASTING,
    INCIDENT_ANALYTICS_REPORT_HEATMAPANALYSIS,
    INCIDENT_ANALYTICS_REPORT_DURATION,
    INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS,
    INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS_FORCE_DIRECTED,
    INCIDENT_ANALYTICS_REPORT_TREE_CHART
} from "./ReactQueryKeys/keys";

import Summary from "./Summary";
import TicketSummary from "./TicketSummary";
import ProbabilityAnalysisOverview from "./Likelihood/ProbabilityAnalysisOverview";
import StoryMap from "./StoryMap";

const Overview = () => {
    const { t } = useTranslation();
    const tabsList = [
        t(`IncidentAnalyticsReports.navTabs.summary`),
        t(`IncidentAnalyticsReports.navTabs.openSummary`),
        t(`IncidentAnalyticsReports.navTabs.ticketClassification`),
        t(`IncidentAnalyticsReports.navTabs.forecasting`),
        t(`IncidentAnalyticsReports.navTabs.trends`),
        t(`IncidentAnalyticsReports.navTabs.similarTrends`),
        t(`IncidentAnalyticsReports.navTabs.issueToSol`),
        t(`IncidentAnalyticsReports.navTabs.probabilityAnalysis`),
        t(`IncidentAnalyticsReports.navTabs.duration`),
        t(`IncidentAnalyticsReports.navTabs.heatMapAnalysis`),
    ];

    const contentList = [
        <Summary key="Summary" queryKey={INCIDENT_ANALYTICS_SUMMARY} />,
        <Summary
            key="Open_Summary"
            queryKey={INCIDENT_ANALYTICS_REPORT_OPEN_SUMMARY}
        />,
        <StoryMap key="story" queryKey={INCIDENT_ANALYTICS_REPORT_TREE_CHART} />,
        <Summary
            key="Forecasting"
            queryKey={INCIDENT_ANALYTICS_REPORT_FORECASTING}
        />,
        <Summary key="Trends" queryKey={INCIDENT_ANALYTICS_REPORT_TREND} />,
        <Summary key="Similar trend" queryKey={INCIDENT_ANALYTICS_REPORT_ST} />,
        <Summary
            key="Solution_Analysis"
            queryKey={INCIDENT_ANALYTICS_REPORT_SOLUTION_ANALYSIS}
        />,
        <Summary
            key="probability_analysis"
            queryKey={INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS}
            tabName="Probability Analysis"
            forceDirectedQueryKey={
                INCIDENT_ANALYTICS_REPORT_PROBABILITY_ANALYSIS_FORCE_DIRECTED
            }
        />,
        <Summary
            key="Duration"
            queryKey={INCIDENT_ANALYTICS_REPORT_DURATION}
        />,
        <Summary
            key="Heat_Map_Analysis"
            queryKey={INCIDENT_ANALYTICS_REPORT_HEATMAPANALYSIS}
        />,
    ];

    return <TabsWithBottomNav tabsList={tabsList} contentList={contentList} />;
};

export default Overview;