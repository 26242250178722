import { Tag, Tile } from "@carbon/react";
import { CheckmarkFilled } from "@carbon/react/icons/index";
import { useTranslation } from 'react-i18next';
import React from "react";

const MLModal = ({details, title}) => {
    // console.log("props", title)
    const { t } = useTranslation();

    const splitExpect = (data) => {
        const outpt =  data.Expected_output.split(",");
        return outpt.map((out) => <Tag className="ex-out" type="green">
        {out}
      </Tag>)
    }

    return (
        <div className="ml-modal">
            <div className="mldesc">
                <span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.description`)}</span>
                <p>{t(details.transDesc)}</p>
            </div>
            <div className="expected">
                <span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.expectedOutput`)}</span><p>{splitExpect(details)}</p>
            </div>
            <div className="models">
                <span>{title === "GenAI" ? t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.models`) : t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.algorithm`)}</span>
                <Tile className="mod">
                    <span className="nm">{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.modelTile.name`)}</span>
                    <p>{t(details.Models[0].transName)}</p>
                    {details.Models[0]["hyperscaller"] !== undefined && <div className="hype">
                        <p><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.modelTile.hyperscaller`)} </span><Tag className="some-class" type="magenta">
                        {details.Models[0]["hyperscaller"]}
                    </Tag></p>
                    </div>}
                </Tile>
                {/* {details.Models.map((mod) => mod.name !== undefined && mod.name !== "" && <Tile className="mod">
                    <span className="nm">Name</span>
                    <p>{mod.name}</p>
                    {mod["hyperscaller"] !== undefined && <div className="hype">
                        <p><span>Hyperscaller : </span><Tag className="some-class" type="magenta">
                        {mod["hyperscaller"]}
                    </Tag></p>
                    </div>}
                </Tile>)} */}
            </div>
            <div className="mldesc">
                <span>{title === "GenAI" ? t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.modelInformations`) : t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.algoInformations`)}</span>
                <p>{t(details["transMI"])}</p>
            </div>
            <ul>
                {/* <li><span>Expected Output</span><p className="expected">{details.Expected_output}</p></li> */}
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.governanceFocalReview`)}</span>{t(details["transGovReview"])}  <CheckmarkFilled fill="#24a148" /></li>
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.iplegalReview`)}</span>{t(details["transIPReview"])}  <CheckmarkFilled fill="#24a148" /></li>
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.usageRiskLevel`)}</span><Tag className="some-class" type={details["Usage Risk Level"] === "Medium" ? "teal" : "cyan"}>
        {t(details["transRisk"])}
      </Tag></li>
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.typeOfContent`)}</span>{t(details["transContent"])}</li>
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.region`)}</span>{t(details["transRegion"])}</li>

      <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.status`)}</span>{t(details["transStatus"])} <CheckmarkFilled fill="#24a148" /></li>
                <li><span>{t(`InstrumentationHub.aiServicesCatalogue.catalogueCommons.dataPI`)}</span>{t(details["transDataPI"])}</li>
            </ul>
        </div>
    )
}

export default MLModal;