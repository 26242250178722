import React from 'react';
import { InlineLoading } from '@carbon/react';
import PropTypes from 'prop-types';

class InlineLoader extends React.PureComponent {
  render() {
    const description = this.props.description;
    const status = this.props.status;
    return (
      <div
        className={
          this.props.type && this.props.type === 'left'
            ? 'inlineloader-left'
            : 'inlineloader'
        }
      >
        <InlineLoading status={status} description={description} />
      </div>
    );
  }
}

InlineLoader.propTypes = {
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
};

InlineLoader.defaultProps = {
  type: undefined,
};

export default InlineLoader;
