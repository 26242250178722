import { Button } from '@carbon/react'
import { useKeycloak } from '@react-keycloak/web'
import Context from 'Context/Context'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
    setLoginPage,
    setLandingPage,
  } from "Components/landingPage/store/action/action";
import DefaultModal from 'Carbon-Components/Modals/CommonModal'

const Logout = ({isModal, cancelModal}) => {
    const { keycloak } = useKeycloak()
    const context = useContext(Context)
    const dispatch = useDispatch()

    const logoutFunc = () => {
        keycloak.logout();
        localStorage.setItem("realmSelected", false);
        window.location.href = "/";
        context.updateLogin(false);
        sessionStorage.removeItem("setShowLogin");
        sessionStorage.removeItem("setLogin");
        sessionStorage.removeItem("tenant");
        sessionStorage.removeItem("application");
        dispatch(setLoginPage(false));
        dispatch(setLandingPage(false));
      }
  return ( !isModal ?
    <Button onClick={() => logoutFunc()}>Logout</Button>
    : <DefaultModal
          heading="Confirm"
          primaryText="Logout"
          onCloseModal={() => cancelModal()}
          onModalSubmit={() => logoutFunc()}
      >
          Do you want to log out?
      </DefaultModal>
  )
}

export default Logout