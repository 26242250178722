/** @format */

import React from "react";
import { useSelector } from "react-redux";
import NewLandingScreen from "./LandingPage";
import newBackground from "../../Assets/landingPage/newBackground.png";
import "./css/newLand.scss";

function LandingHome() {
  const reduxData = useSelector((state) => state);

  const canShowLanding = () => {
    if (
      (reduxData.LandingPageReducer.isLandingPageHiddern === false &&
        sessionStorage.getItem("setShowLogin") === null) ||
      (reduxData.LandingPageReducer.isLandingPageHiddern === false &&
        sessionStorage.getItem("setShowLogin") !== "false")
    ) {
      return true;
    }
    return false;
  };

  return canShowLanding() ? (
    <>
      <div
        className="landing-pg"
        // style={{ backgroundImage: `url(${newBackground})` }}
      >
        <NewLandingScreen />
      </div>
    </>
  ) : (
    ""
  );
}

export default LandingHome;