import * as d3 from "d3";
import React, { useEffect } from "react";
import * as topojson from "topojson-client";

import "./css/map.scss";

const ChoroplethMap = () => {
  useEffect(() => {
    getProcessInsights();
  }, []);

  const getProcessInsights = () => {
    createSvgMap();
  };

  const createSvgMap = (data) => {
    // svg.selectAll("*").remove();
    let elem = document.getElementById("tooltip");
    if (elem !== null && elem !== undefined) {
      elem.remove();
    }

    d3.select("#the_SVG_ID").remove();
    let width = 1000;
    let height = 500;

    let graticule = d3.geoGraticule();

    let svg = d3
      .select("#my_dataviz")
      .append("svg")
      .attr("id", "the_SVG_ID")
      .attr("height", height)
      .attr("width", width);

    let g = svg.append("g");

    let projection = d3
      .geoMercator()
      .scale((width - 600) / Math.PI)
      .translate([width / 2, (height + 230) / 2])
      .rotate([-10, 0]);

    let path = d3.geoPath().projection(projection);

    g.append("path")
      .datum(graticule)
      .attr("class", "graticule")
      .attr("d", path);

    let markers = [
      { long: 97.98236392939918, lat: 40.830529300542423, group: "APAC" },
    ];

    let regData = [
      {
        name: "APAC",
        count: 5,
        long: markers[0].long - 20,
        lat: markers[0].lat - 25,
      },
    ];

    Promise.all([
      d3.json("https://piwodlaiwo.github.io/topojson//world-continents.json"),
    ])
      .then(([topology]) => {
        let continents = topojson.feature(
          topology,
          topology.objects.continent
        ).features;
        // let centroids = continents.map(function (feature) {
        //   return path.centroid(feature);
        // });

        //                 const color = d3
        //   .scaleThreshold()
        //   .domain([
        //     10000,
        //     100000,
        //     500000,
        //     1000000,
        //     5000000,
        //     10000000,
        //     50000000,
        //     100000000,
        //     500000000,
        //     1500000000
        //   ])
        //   .range([
        //     'rgb(247,251,255)',
        //     'rgb(222,235,247)',
        //     'rgb(198,219,239)',
        //     'rgb(158,202,225)',
        //     'rgb(107,174,214)',
        //     'rgb(66,146,198)',
        //     'rgb(33,113,181)',
        //     'rgb(8,81,156)',
        //     'rgb(8,48,107)',
        //     'rgb(3,19,43)'
        //   ]);

        // Add a scale for bubble size
        let size = d3
          .scaleLinear()
          .domain([1, 100]) // What's in the data
          .range([4, 50]); // Size in pixel

        g.on("click", (d) => console.log("-------------", d));
        // Draw the map
        svg
          .selectAll(".continent")
          .data(continents)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("title", function (d, i) {
            return d.properties.continent;
          })
          .style("fill", "#77d696");

        let table = d3
          .select("#my_dataviz")
          .append("ul")
          .attr("id", "tooltip")
          .style("padding", "10px")
          .style("background-color", "white")
          .style("border-top", "solid")
          .style("border-left", "solid")
          .style("border-right", "solid")
          .style("border-width", "1px")
          .style("border-radius", "5px")
          .style("width", "400px")
          .style("display", "none")
          .style("position", "absolute");

        let thead = table.append("li");
        //let tbody = table.append("li");

        let viewPopup = function (d) {
          table
            .style("display", "block")
            .style("left", d.offsetX - 200 + "px")
            .style("top", d.offsetY - 180 + "px");
        };

        let columns = [
          "IBM Client",
          "Roboshop",
          "Client Industry",
          "E-Commerce",
          "Client Country",
          "India",
          "Description",
          "IT Workload",
          "Client Target Year",
          "2070",
          "Total Electricity Consumption",
          "259.489 MWh",
          "Renewable Electricity Consumption",
          "142.94 MWh (55.08 %)",
          "GHG Emissions",
          "270.14 MT",
        ];
        // append the header row
        thead
          .append("li")
          .selectAll("div")
          .data(columns)
          .enter()
          .append("div")
          .attr("class", function (d, i) {
            return "key" + i;
          })
          .text(function (column) {
            return column;
          });

        // let data = [
        //   { coulmn1: "20", coulmn2: 45, coulmn3: "val" },
        //   { coulmn1: "20", coulmn2: 50, coulmn3: "val" },
        //   { coulmn1: "20", coulmn2: 55, coulmn3: "val" },
        // ];
        // create a row for each object in the data
        //let rows = tbody.selectAll("li").data(data).enter().append("li");

        // rows
        //   .selectAll("div")
        //   .data(function (row) {
        //     return columns.map(function (column) {
        //       return { column: column, value: row[column] };
        //     });
        //   })
        //   .enter()
        //   .append("div")
        //   .text(function (d) {
        //     return d.value;
        //   });
        // d.name + "<br>" + "long: " + d.long + "<br>" + "lat: " + d.lat
        svg
          .selectAll(".centroid")
          .data(regData)
          .enter()
          .append("circle")
          .attr("class", function (d) {
            return d.name;
          })
          .attr("fill", "#5a60ab")
          .attr("stroke-width", 1)
          .attr("fill-opacity", 1)
          .attr("z-index", 99)
          .style("cursor", "pointer")
          .on("click", viewPopup)
          .attr("r", function (d) {
            return size(d.count);
          })
          .attr("cx", function (d) {
            return projection([d.long, d.lat])[0];
          })
          .attr("cy", function (d) {
            return projection([d.long, d.lat])[1];
          });
      })
      .catch((err) => console.log("Error loading or parsing data."));
  };

  return (
    <>
      <h4>Application is hosted in India</h4>
      <div id="my_dataviz"></div>
    </>
  );
};

export default React.memo(ChoroplethMap);
