import { ClickableTile, Column, Row, Tag } from "@carbon/react";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import React, { useState } from "react";
import ClusterRecommendations from "./ClusterRecommendations";
import ClusterIssues from "./IssuesAPICall";

const IssuesContainer = ({ data, refresh }) => {
  const [content, setContent] = useState("");

  const getData = () => {
    try {
      const {
        issues: { cluster, application },
      } = data;

      return (
        <>
          <Row>
            <Column lg={8} md={4} sm={4} className="pr-05">
              <ClickableTile
                id="cluster-tile"
                key="cluster"
                onClick={() => setContent("cluster")}
                aria-label="cluster"
                className={`top-level-issues-tile ${content === "cluster" ? "active--tile" : ""
                  }`}
              >
                <div>
                  <h3 className="red">{cluster.issues}</h3>
                  <h5>Cluster Issues</h5>
                </div>
                <div>
                  <p>Fix Available: <Tag className="overdue-tag" type="blue" title="Clear Filter">
                    {cluster.fix}
                  </Tag></p>
                  <p>Overdue:<Tag className="overdue-tag" type="red" title="Clear Filter">
                    {cluster.overdue}
                  </Tag></p>
                </div>
              </ClickableTile>
            </Column>
            <Column lg={8} md={4} sm={4} className="pl-05">
              <ClickableTile
                id="application-tile"
                key="application"
                onClick={() => setContent("application")}
                aria-label="application"
                className={`top-level-issues-tile ${content === "application" ? "active--tile" : ""
                  }`}
              >
                <div>
                  <h3>{application.issues}</h3>
                  <h5>Application Issues</h5>
                </div>
                <div>
                  <p>Fix Available:  <Tag className="overdue-tag" type="blue" title="Clear Filter">
                    {application.fix}
                  </Tag></p>
                  <p>Overdue: <Tag className="overdue-tag" type="red" title="Clear Filter">
                    {application.overdue}
                  </Tag></p>
                </div>
              </ClickableTile>
            </Column>
          </Row>
        </>
      );
    } catch (error) {
      <ErrorRefresh refresh={refresh} />;
    }
  };

  return (
    <>
      {getData()}
      {content === "cluster" ? (
        <>
          <hr className="section-divider"></hr>
          <ClusterRecommendations />
        </>
      ) : content === "application" ? (
        <>
          <hr className="section-divider"></hr>
          <ClusterIssues hirarchy="application" />
        </>
      ) : null}
    </>
  );
};

export default IssuesContainer;
