export const headers = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "apptype",
    header: "Type",
  },
  {
    key: "region",
    header: "Region",
  },
  {
    key: "details",
    header: "Details",
  },
  {
    key: "technology",
    header: "Technology Used",
  },
  {
    key: "rec",
    header: "Recommendation",
  },
];

export const rows = [
  {
    id: "2",
    name: "Blueprint BUSINESS WAREHOUSE",
    region: "Bangalore",
    technology: "SAP",
    details: "Hyperscaler",
  },
  {
    id: "3",
    name: "Chemicals Customer Portal",
    region: "Bangalore",
    technology: "SAP",
    details: "Hyperscaler",
  },
  {
    id: "4",
    name: "ENERGY COMPONENTS",
    region: "Bangalore",
    technology: "JAVA",
    details: "Hyperscaler",
  },
  {
    id: "5",
    name: "GSAP ERP CH",
    region: "Bangalore",
    technology: "SAP",
    details: "Hyperscaler",
  },
  {
    id: "6",
    name: "GSAP ERP DS",
    region: "Bangalore",
    technology: "SAP",
    details: "Hyperscaler",
  },
];

export const rows1 = [
  {
    id: "2",
    name: "C1-CCnB",
    region: "Australia",
    technology:
      "Oracle Customer Care & Billing, IBM Websphere ESB, Oracle Service Bus",
    details: "On-Prem",
  },
  {
    id: "3",
    name: "MDMS",
    region: "Australia",
    technology: "Oracle Meter Data Management System",
    details: "On-Prem",
  },
  {
    id: "4",
    name: "MuleSoft On-Prem",
    region: "Australia",
    technology: "MuleSoft AnyPoint Platform",
    details: "On-Prem",
  },
  {
    id: "5",
    name: "Maximo",
    region: "Australia",
    technology: "IBM Maximo",
    details: "On-Prem",
  },
];
