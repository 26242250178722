import { Column, Row } from "@carbon/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "Common-Modules/Paragraph";
import DataTable from "./DataTable";

const DataTableForCharts = ({ data }) => {
  const getHeaderData = () => {
    try {
      if (data.headerData.length > 0) {
        return data.headerData;
      }
    } catch (err) {
      console.log(err);
    }
    return data.fields.map((data) => ({
      key: data,
      header: data,
    }));
  };

  const HEADER_DATA = getHeaderData();

  const getTableHeader = (header) => {
    return header;
  };

  const getRowCellData = (id, data, row) => {
    try {
      if (data.length <= 30) {
        return data;
      } else if (typeof data === "string" && data.length > 30) {
        return (
          <Paragraph text={data} size={30} />
          //   <div className="font-change">
          //     <TooltipCarbon description={data}>{temp}</TooltipCarbon>
          //   </div>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return data;
  };

  if (data.chartData.length > 0) {
    return (
      <Row>
        <Column lg={16} md={8} sm={4}>
          <DataTable
            title={data.title}
            rowData={data.chartData}
            headerData={HEADER_DATA}
            getRowCellData={getRowCellData}
            getTableHeader={getTableHeader}
            actionsNeeded={false}
            className="chart-header"
          ></DataTable>
        </Column>
      </Row>
    );
  } else {
    return (
      <h4 className="empty--message">
        No Data Available For the Selected Filter
      </h4>
    );
  }
};

export default DataTableForCharts;
