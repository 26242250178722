import React from "react";
import { Column, FlexGrid, Row } from "@carbon/react"
import PassiveModal from "Carbon-Components/Modals/PassiveModal";


const Privacy = (props) => {

    const closePopup = () => {
        props.closeModal()
    }

    return (
        <PassiveModal size="lg" canCloseDetailsPopup={closePopup} className="privacy-pop" >
                                <h4>Terms of Use and Privacy Statement</h4>
                    <ul>
                        <li>This instance of IBM Consulting AIOps is hosted and managed by the HCS practice and is offered as AIOps Solution for IBM Clients and IBME Internal Usage. For IBM Clients (Non-IBMers), the IBM Consulting AIOps would be available only after necessary procurement processes are followed. IBM Consulting AIOps is also available as a dedicated set up for specific client requirements.</li>
                        <li>
                        IBM Consulting AIOps is not designed to handle sensitive information, please do not store any PI/SPI/BSI data on the IBM Consulting AIOps.
                        </li>
                        <li>
                        The IBM Consulting AIOps is setup on Container Orchestration Platform (like Kubernetes, ROKS) with enough security controls in place. You are responsible to ensure that using IBM Consulting AIOps is not violating any Contractual or Statutory terms.
                        </li>
                        <li>
                        IBM Consulting AIOps usage cost will be charged to the Projects through a DoU setup between the Project team and HCS practice. Further details will be provided during the Project Onboarding.
                        </li>
                        <li>
                            <p>Data Storage and Security:</p>
                        IBM Consulting AIOps will implement necessary security controls to protect the data stored from unauthorized access, disclosure, modification, or destruction. The data will be stored for the duration of the Project and will be deleted once the Project is completed.
                        </li>
                        <li>
                        <p>User Rights:</p>
                            Users have the right to access, correct, or delete their data stored on the IBM Consulting AIOps platform. Users can exercise these rights by contacting the HCS practice.In case of any queries or concerns, please contact the HCS practice
                        </li>
                    </ul>
        </PassiveModal>
    )
}

export default Privacy;