import React from "react";

const GetRecentTime = () => {

    function roundToNearest15(date) {
        let minutes = date.getMinutes();
        let roundedMinutes = Math.floor(minutes / 15) * 15;
        date.setMinutes(roundedMinutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }
    
    // Example usage:
    let date = new Date();  // Current date and time


    return (
        <div className="recentTime">
            Last Updated Time: {roundToNearest15(date).toTimeString().slice(0, 5)}
        </div>
    )
}

export default GetRecentTime