import {
    Breadcrumb,
    BreadcrumbItem,
    ClickableTile, Column,
    ComboBox,
    FlexGrid,
    Modal,
    Row
} from "@carbon/react";
import { CheckmarkFilled, CheckmarkFilledWarning, InProgressError } from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TopologyGraph from "./TopologyGraph";
import "./cpg.scss";


const CpgWorkflowFlow = (props) => {
    const history = useHistory();
    const [worklflowData, setWorkflowData] = useState({});
    const [comboBoxData, setComboBox] = useState([]);
    const [showToplogy, setShowTopology] = useState(false);
    const [topologyData, setTopologyData] = useState([]);

    useEffect(() => {
        if(props.location.state !== undefined) {
            setWorkflowData(props.location.state.data);
            let workFlw = [];
            props.location.state.data.workflow.forEach((wrk) => {
                workFlw.push(wrk.title)
            })
            setComboBox(workFlw)
        }
    }, [])

    // const getComboBox = () => {
    //     return <ComboBox
    //     id="ComboBox1"
    //     items={comboBoxData.sort((a, b) => {
    //         let fa = a.toLowerCase(),
    //             fb = b.toLowerCase();
    //         if (fa < fb) {
    //             return -1;
    //         }
    //         if (fa > fb) {
    //             return 1;
    //         }
    //         return 0;
    //     })}
    //     itemToString={(item) =>
    //         item ? item  : ""
    //     }
    //     placeholder="Select Service"
    //     titleText=""
    //     size="sm"
    //     onChange={(e) => getSelectedFlow(e)}
    // />
    // }

    // const getSelectedFlow = (e) => {
    //     // if(e.selectedItem !== null) {
    //     //     const filterData = dashboardDuplicateData.filter((fil) => fil.type === e.selectedItem.name.toLowerCase());
    //     //     setDashboardData(filterData)
    //     // } else {
    //     //     setDashboardData(dashboardDuplicateData);
    //     // }
    // }

    // const onChangeSearch = (e) => {
    //     // if(e.target.value !== "") {
    //     //     const filterDat =  dashboardData.filter((data) => {
    //     //         return data["type"].toLowerCase().includes(e.target.value.toLowerCase()) || data["displayName"].toLowerCase().includes(e.target.value.toLowerCase())
    //     //     })
    //     //     if(filterDat.length > 0) {
    //     //         setDashboardData(filterDat)
    //     //     }
    //     // } else {
    //     //         setDashboardData(dashboardDuplicateData)
    //     // }
    // }

    const handleOnClick = (data) => {
        // setShowTopology(true);
        // setTopologyData(data)
        if(data.name === "In-Bound Logistics") {
        sessionStorage.setItem("portfolioTitle", data.name)
        //history.push({ pathname: `nerveTileInfo`, state : {tileData: data, flow: "valueStream", workflow: props.location.state.data } })
        history.push({ pathname: `workflowViewCpg/${data.name}`, state : {tileData: data, flow: "valueStream", workflow: props.location.state.data } })
        }
    }

    const redirectToIne = (page, node) => {
        history.push({
            pathname: "/cpgDetails",
            state: { data: node, tileData: topologyData, workflow: props.location.state.data },
        });
    };

    return (
        <FlexGrid className="Cpg">
         <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">Value Stream</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Consumer Package Goods Workflow
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
         </Row>
        {/* <Row>
            <Column className="tile-combo pd-lft" lg={4} md={4} sm={2}>
                {comboBoxData.length > 0 ? getComboBox() : null}
            </Column>
            <Column className="search pd-lft pd-rgt" lg={6} md={4} sm={2}>
                <Search size="sm" placeholder="Find your items" labelText="Search" closeButtonLabelText="Clear search input" id="search-1" onChange={(e) => onChangeSearch(e)} onKeyDown={() => { }} />
            </Column>
        </Row> */}
        <Row>
        <Column lg={16} md={8} sm={4} className="cpg-tiles-workflow pd-lft pd-rgt">
        {Object.entries(worklflowData).length > 0 && worklflowData.workflow.map((info, index) => 
        <>
            <div className="box-title">
                <h5>{info.title}</h5>

            </div>
            <div className="wrkflw-box">{info.data.map((res) => <ClickableTile
            id={index}
            onClick={(e) => handleOnClick(res)}
            aria-label={`clickableTile-${index}`}
            // className={res.color}
        >
            {/* <p> <span className="infoTit">Title</span></p> */}
            <div>
                <h5>{res.name}</h5>
                {res.color === "red" ? <InProgressError fill="#da1e28"  /> : res.color === "green" ? <CheckmarkFilled fill="#42be65" />: <CheckmarkFilledWarning fill="#ff832b" />}
            </div>
            <p className="descrip">{res.desc !== undefined ? res.desc : null}</p>
        </ClickableTile> ) }
        </div>
        </>                       
        )}
        </Column>
    </Row>
    {showToplogy && (
                <Modal
                    open
                    size="lg"
                    passiveModal
                    isFullWidth
                    onRequestClose={() => setShowTopology(false)}
                    modalHeading="Topology View"
                    modalLabel="Application Topology View"
                    className="topology-view"
                >
                    <TopologyGraph redirectToIne={redirectToIne} appDetails={topologyData} />
                </Modal>
            )}
    </FlexGrid>
    )
}

export default CpgWorkflowFlow;