/** @format */

import {
  Accordion,
  AccordionItem,
  ClickableTile,
  Column,
  ErrorBoundary,
  Grid,
  Modal,
  ProgressBar,
  SkeletonPlaceholder,
  Tile,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Toggletip,
  ToggletipButton,
  ToggletipContent
} from "@carbon/react";
import { Collaborate, Launch, Information } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
//   import CPUUtilization from "Components/PlatformServices/Charts/Reports/CPUUtilization";
  import CarbonDataTable from "Carbon-Components/DataTable";
//   import MemoryUtilization from "Components/PlatformServices/Charts/Reports/MemoryUtilization";
//   import FaultErrorMessages from "Components/PlatformServices/Charts/TopologyComponent/FaultErrorMessages";
  import Context from "Context/Context";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getGenAISummaryAPI, getProbableRCA } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import ErrorWhileFetchingShort from "../utilities/ErrorWhileFetchingShort";
import FloatingBarChart from "./Chart/FloatingBar";
import GenAISummary from "./GenAiSummary";
import Logs from "./Logs";
import RunBookAutomationCommon from "./TraceComponent/RunbookAutomationCommon";
import SimilarRCATicketsGenAI from "./TraceComponent/SimilarRCATicketsGenAI";
import { getElasticAnyCollection } from "Services/ServerApi";
import { QueryCache, useQuery } from "@tanstack/react-query";
import CreateTicket from "Components/DO/TroubleShootView/CreateTicket";
import DoTopologyView from "Components/DO/TroubleShootView/TroubleshootTopology";
import SimilarRCATicketsDOGenAI from "Components/DO/TroubleShootView/SimilarRCATicketsDO";
  
  const tableHeaderData = [
    {
      "key": "Id",
      "header": "API ID"
    },
    {
      "key": "isPartial",
      "header": "Is Partial"
    },
    {
      "key": "responseTime",
      "header": "ResponseTime"
    },
    {
      "key": "hasError",
      "header": "Has Error"
    },
    {
      "key": "apiStage",
      "header": "API Stage"
    }
  ];
  
  const TraceDetails = (props) => {
    const { keycloak } = useKeycloak();
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(false);
    const [errMessage, setErrorMessage] = useState("");
    const [errMessageRCA, setErrorMessageRCA] = useState("");
    const [errMessageGenAI, setErrorMessageGenAI] = useState("");
    const [errMessageLogs, setErrorMessageLogs] = useState("");
  
    const [traceSummary, setTraceSummary] = useState();
    const [cPUUtilizationChartData, setCPUUtilizationChartData] = useState();
    const [memoryUtilizationChartData, setMemoryUtilizationChartData] = useState();
    const [tableRowData, setTableRowData] = useState();
  
    const [isAnomalyData, setIsAnomalyData] = useState(true);
    const [anomaly, setAnomaly] = useState("");
    const [metrics, setMetrics] = useState("");
    const [namespace, setNamespace] = useState("");
    const [period, setPeriod] = useState("");
    const [stat, setStat] = useState("");
    const [functionNameTitle, setFunctionNameTitle] = useState("");
    const [functionNameValue, setFunctionNameValue] = useState("");
  
    const [probableRootCause, setProbableRootCause] = useState("");
    const [similarRCA, setSimilarRCA] = useState("");
    const [runBookAutomation, setRunBookAutomation] = useState("");
  
    const [isLogData, setIsLogData] = useState(true);
    const [logDetails, setLogDetails] = useState("");
    const [genAISummary, setGenAISummary] = useState("");
    const [showGenAITile, setShowGenAITile] = useState(false);
  
    const [traceDiscoveryStart, setTraceDiscoveryStart] = useState("");
    const [traceDiscoveryEnd, setTraceDiscoveryEnd] = useState("");
    const [region, setRegion] = useState("");
  
    const [showPopUP, setShowPopUP] = useState(false);
    const [showCreateTicket, setCreateTicket] = useState(false);
    const [showPopUPRunbook, setShowPopUPRunbook] = useState(false);
  
    const [componentARN, setComponentARN] = useState();
    const genAISteps = ["RCA Summarization", "Remediation Recommendation", "Code Remediation", "Finalizing"]
    const size = 4;
    const [progress, setProgress] = useState(0);
  
    //const history = useHistory();
    const context = useContext(Context)

    useEffect(() => {
      if(props.flow === "DO") {
        fetchDOAnomaly()
      } else {
        updateTraceObj();
      }
      setRegion(sessionStorage.getItem("region"));
      setComponentARN(sessionStorage.getItem("componentARN"));
    }, []);
  
    useEffect(() => {
      if (showGenAITile) {
        setProgress(0);
        if (genAISummary || errMessageGenAI) {
  
        } else {
          setTimeout(() => {
            const interval = setInterval(() => {
              setProgress(currentProgress => {
                if (currentProgress > size || genAISummary || errMessageGenAI) {
                  clearInterval(interval);
                }
                return currentProgress + 0.05;
              });
            }, 50);
          }, 3000);
        }
      }
    }, []);

    const fetchDOAnomaly = () => {
      const Incident = props.tableData !== undefined ? props.tableData[0] : {};
      const anomaly = Incident._source.alert.details[0].value, metrics = Incident._source.alert.type, logs = Incident._source.incident.u_aocc_aiops_uid;
      getGenAISummary(anomaly, metrics, logs);
      setTraceSummary(Incident);
      setAnomaly(anomaly)
      getProbableRCAData(anomaly, metrics);
    }
  
    const getUpdatedAnomaly = () => {
      let anomaly = props.traceObj["Anomaly Summary"].anomaly;
      let anomalyReason = props.traceObj["Anomaly Summary"].anomaly_reason ? props.traceObj["Anomaly Summary"].anomaly_reason : "";
      return (anomaly + " " + anomalyReason);
    }
  
    // const handleOnClick = useCallback(() =>
    //   (window.open("https://appops-devops.slack.com/archives/C03EQQ6LFC7", "_blank"))
    //   , [history]);
  
    const getProbableRCAData = async (anomaly, metrics) => {
      const response = await getProbableRCA(keycloak.token, anomaly, metrics);
      AddUserActivity(keycloak, context, "FEATURE", "Probable Root Cause", response);
      setIsAPIDataLoading(false);
  
      if (!response.data) {
        setErrorMessageRCA("Error while fetching data");
      }
      if (response.status >= 400 || response === "Error") {
        setErrorMessageRCA("Error while fetching data");
      } else {
        setProbableRootCause(getTextBoxData("Probable Root Cause", response.data.data["Probable root cause"]));
        setSimilarRCA(getTextBoxData("Similar Incidents", ""));
        setRunBookAutomation(getTextBoxData("RunBook Automation", ""));
      }
    }
  
    const getGenAISummary = async (anomaly, metrics, log) => {
      setShowGenAITile(true);
      const type = props.flow === "DO" ? "" : "apa";
      const anam = props.flow === "DO" ? anomaly : getUpdatedAnomaly();
      const response = await getGenAISummaryAPI(keycloak.token, anam, metrics, log, type);
      AddUserActivity(keycloak, context, "FEATURE", "GenAI Summary", response);
      if (!response.data) {
        setErrorMessageGenAI("Error while fetching data");
      }
      if (response.status >= 400 || response === "Error") {
        setErrorMessageGenAI("Error while fetching data");
      } else {
        setGenAISummary(response.data.data);
        if (response?.data.data?.reason && response?.data?.data?.remediation && response?.data?.data?.code) {
          setShowGenAITile(true);
        } if (response?.data.data?.reason && response?.data?.data?.remediation) {
          setShowGenAITile(true);
        } else {
          setShowGenAITile(false);
        }
      }
    }
  
    const getCombinedLogs = (logArray) => {
      let combinedLog = "";
      logArray.forEach((logObj) => {
        combinedLog = combinedLog + " " + logObj.message;
      });
      return combinedLog;
    }
  
    const updateTraceObj = () => {
      console.log("setTraceSummary", props)
      if (props.traceObj) {
        setTraceSummary(props.traceObj);
        if (props.traceObj.Annotations && Object.keys(props.traceObj.Annotations).length > 0) {
          if (props.traceObj.Annotations && props.traceObj.Annotations.length > 0) {
            updateModalText(props.traceObj);
          }
        }
      }
      else {
        setErrorMessage("Data is unavailable");
      }
      if (props.traceResponse.total_memory) {
        setCPUUtilizationChartData(props.traceResponse.total_memory.metrics_response);
      }
      if (props.traceResponse.memory_utilization) {
        setMemoryUtilizationChartData(props.traceResponse.memory_utilization.metrics_response);
      }
      if (props.traceObj["Anomaly Summary"]) {
        setAnomaly(getTextBoxData(sessionStorage.getItem("issueType"), props.traceObj["Anomaly Summary"].anomaly));
        if (props.traceObj["Anomaly Summary"].anomaly && props.traceObj["Anomaly Summary"].anomaly_details && props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].MetricName) {
          setMetrics(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].MetricName);
          setNamespace(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].Namespace);
          setPeriod(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].Period);
          setStat(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].Stat);
          setFunctionNameTitle(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].Dimensions[0].Name);
          setFunctionNameValue(props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].Dimensions[0].Value);
  
          getProbableRCAData(getUpdatedAnomaly(), props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].MetricName);
          if (props.traceObj["Logs Details"]) {
            let logs = "";
            if (props.traceObj["Logs Details"].log_details) {
              if (props.traceObj["Logs Details"].log_details.events && props.traceObj["Logs Details"].log_details.events.length > 0) {
                logs = getCombinedLogs(props.traceObj["Logs Details"].log_details.events)?.slice(0, 1000);
              } else if (props.traceObj["Logs Details"].log_details.message) {
                logs = props.traceObj["Logs Details"].log_details.message;
              }
            }
            getGenAISummary(props.traceObj["Anomaly Summary"].anomaly, props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].MetricName, logs);
          } else {
            getGenAISummary(props.traceObj["Anomaly Summary"].anomaly, props.traceObj["Anomaly Summary"].anomaly_details[0].metrics.CloudWatchMetrics[0].MetricName);
          }
        } else {
          setIsAnomalyData(false);
        }
      } else {
        setIsAnomalyData(false);
      }
      if (props.traceObj["Logs Details"]) {
        if (props.traceObj["Logs Details"]) {
          setErrorMessageLogs("Error while fetching data");
        }
        setLogDetails(props.traceObj["Logs Details"]);
        setTraceDiscoveryStart(props.traceResponse["trace_discovery_start_date_time"]);
        setTraceDiscoveryEnd(props.traceResponse["trace_discovery_end_date_time"]);
      } else {
        setIsLogData(false);
      }
    }
  
    const getTextBoxData = (titleText, value) => {
      let data = { title: "", value: "" };
      data.title = titleText;
      data.value = value;
      return data;
    }
  
    const getYesNo = (data) => {
      return data ? "Yes" : "No";
    }
  
    const getRowCellData = (id, data, row) => {
      return data;
    };
  
    const getTableHeader = (header) => {
      return header;
    };
  
    const updateModalText = (traceSummaryObj) => {
      let rowData = {
        id: traceSummaryObj.Annotations["aws:api_id"][0].AnnotationValue.StringValue,
        isPartial: getYesNo(traceSummaryObj.IsPartial),
        responseTime: traceSummaryObj.ResponseTime,
        hasError: getYesNo(traceSummaryObj.HasError),
        apiStage: traceSummaryObj.Annotations["aws:api_stage"][0].AnnotationValue.StringValue
      };
      setTableRowData([rowData]);
    }
  
    const getTextTile = (tile) => {
      const state = props.tableData !== undefined && props.tableData.length > 0 ? props.tableData[0]._source.alert.details[0].value : "";
      return (
        < Tile className="chart-container" >
          <h5 className="text-tile">{tile.title}</h5>
          {state !== "" ? <p>{state}</p> : <p>{getUpdatedAnomaly()}</p>}
        </Tile>
      );
    }
  
    const openSlack = () => {
      window.open(sessionStorage.getItem("msTeams"), '_blank')
    }
  
    const getRCATextTile = (tile) => {
      return (
        < ClickableTile className="prca" onClick={() => openSlack()} >
          <h5 className="text-tile">{tile.title} <Collaborate /></h5>
          <p>{tile.value}</p>
        </ClickableTile>
      );
    }
  
    const getSimilarRCATextTile = (tile) => {
      return (
        < ClickableTile className="chart-container box-tit" onClick={() => setShowPopUP(true)}      >
          <h5 className="text-tile">{tile.title} <Launch /></h5>
        </ClickableTile>
      );
    }
  
    const getRunBookAutomationTile = (tile) => {
      return (
        < ClickableTile className="chart-container box-tit" onClick={() => setShowPopUPRunbook(true)}      >
          <h5 className="text-tile">{tile.title} <Launch /></h5>
        </ClickableTile>
      );
    }
  
    // const getFaultErrorMessages = () => {
    //   return traceSummary ? <FaultErrorMessages traceSummary={traceSummary} node={JSON.parse(sessionStorage.getItem("currentNode"))} componentARN={componentARN} /> : ""
    // }
  
    const getCPUUtilizationChartData = () => {
      return cPUUtilizationChartData ?
        <Column lg={8} md={8} sm={4} >
          {/* <CPUUtilization data={cPUUtilizationChartData} /> */}
        </Column> : ""
    }
  
    const getMemoryUtilizationChartData = () => {
      return memoryUtilizationChartData ?
        <Column lg={8} md={8} sm={4} >
          {/* <MemoryUtilization data={memoryUtilizationChartData} /> */}
        </Column> : ""
    }
  
    const getLoading = () => {
      return isAPIDataLoading ? (
        <div className="skeletonPlaceholder--it-health">
          <SkeletonPlaceholder />
        </div>
      ) : <ErrorWhileFetching errMessage={errMessage} />
    }
  
    const getLoadingRCA = () => {
      return isAnomalyData ?
        <Column lg={16}>
          {
            errMessageRCA ?
              <ErrorWhileFetchingShort errMessage={errMessageRCA} />
              :
              <div className="skeletonPlaceholder--it-health-short">
                <SkeletonPlaceholder />
              </div>
          }
        </Column>
        : "";
    }
  
    const getProgressLoader = () => {
      const running = progress > 0;
      let helperText = running ? `Processing data for ${genAISteps[Math.floor(progress)]}...` : 'Fetching data...';
  
      if (progress >= size) {
        helperText = 'Done';
      }
      return <ProgressBar value={running ? progress : null} max={size} status={progress === size ? 'finished' : 'active'} label={<h6>Augmented Assistance</h6>} helperText={helperText} />;
    }
  
    const getLoadingGenAI = () => {
      return isAnomalyData ?
        <Column lg={16} className="common-top-margin">
          {
            errMessageGenAI ?
              <ErrorWhileFetchingShort errMessage={errMessageGenAI} />
              :
              <div className="skeletonPlaceholder--it-health-short">
                {getProgressLoader()}
              </div>
          }
        </Column>
        : "";
    }
  
    const getTitleName = () => {
      if (tableRowData) {
        return " for " + sessionStorage.getItem("componentARN");
      } else if(props.tableData !== undefined) {
        const incName = props.tableData[0]._source.alert.name
        return " for " +  incName;
      } return "";
    }
  
    const getTable = () => {
      return <div className="common-top-margin">
        <ErrorBoundary fallback={<ErrorWhileFetching />}>
          <CarbonDataTable
            rowData={tableRowData}
            headerData={tableHeaderData}
            getRowCellData={getRowCellData}
            getTableHeader={getTableHeader}
          />
        </ErrorBoundary>
      </div>
    }
  
    const getPopupData = () => {
      return (
        <ErrorBoundary fallback={<ErrorWhileFetching />}>
         {props.flow === "DO" ? <SimilarRCATicketsDOGenAI
            namespace={namespace}
            title={getUpdatedAnomaly()}
            probableRCA={getUpdatedAnomaly()}
          /> : <SimilarRCATicketsGenAI
            namespace={namespace}
            title={getUpdatedAnomaly()}
            probableRCA={getUpdatedAnomaly()}
          />}
        </ErrorBoundary>
      );
    };
  
    const getPopupDataRunBook = () => {
      return (
        <ErrorBoundary fallback={<ErrorWhileFetching />}>
          <RunBookAutomationCommon
            servicetype={namespace}
            title={getUpdatedAnomaly()}
            rootcause={getUpdatedAnomaly()}
          />
        </ErrorBoundary>
      );
    };
  
    const getTitle = (title) => {
      return <h5 className="text-tile">{title}</h5>
    }
  
    const getLoadingLogs = () => {
      return isLogData ? <Column lg={16}>
        {
          errMessageLogs ?
            <ErrorWhileFetchingShort errMessage={errMessageLogs} />
            :
            <div className="skeletonPlaceholder--it-health-short">
              <SkeletonPlaceholder />
            </div>
        }
      </Column> : "";
    }

    const checkCreateTicket = () => {
      const state = props.tableData[0]._source.alert.state;
      const status = props.tableData[0]._source.alert.closureStatus;

      return state === "User_Action_Pending" && status !== "closed"
    }

    console.log("propsssss", props)
  
    return (
      <>
        <Grid>
          {props.flow === "DO" &&<Column lg={16} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/itOperations">Integrated Operations</BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>Troubleshoot View</BreadcrumbItem>
            </Breadcrumb>
          </Column>}

          <Column lg={13} md={8} sm={4}>
  
            <div>
              <h4 style={{ marginTop: "10px" }}>Troubleshoot View{getTitleName()}
                {/* <img
                  src={require(`../../../../../ICPSAassets/${sessionStorage.getItem("monitoringTool")}.png`).default}
                  alt="monitoring-tool-icon"
                  className="monitoring-tile-icon"
                  title={sessionStorage.getItem("monitoringTool")}
                /> */}
              </h4>
            </div>
          </Column>
          {props.flow === "DO" &&<Column lg={3} md={2} sm={2}>
              <Button className="crteTick" disabled={!checkCreateTicket()} onClick={() => setCreateTicket(true)}>Create Ticket</Button>
              <Toggletip align="bottom-right" className="note">
                <ToggletipButton label="Additional information">
                  <Information />
                </ToggletipButton>
                <ToggletipContent>
                <p><span>Note: </span>The "Create Ticket" button will be enabled only if the state is "User_Action_Pending" and the closure status is "Open"</p>
                </ToggletipContent>
              </Toggletip>
          </Column>}
        </Grid>
        {showPopUP ? (
          <Modal
            open
            size="lg"
            passiveModal
            onRequestClose={() => setShowPopUP(false)}
            modalHeading="Similar Incidents"
  
          >
            {getPopupData()}
          </Modal>
        ) : (
          ""
        )}
        {showPopUPRunbook ? (
          <Modal
            open
            size="lg"
            passiveModal
            onRequestClose={() => setShowPopUPRunbook(false)}
  
            modalHeading="Prescribed Runbook Automations"
  
          >
            {getPopupDataRunBook()}
          </Modal>
        ) : (
          ""
        )}
        {
          isAPIDataLoading || errMessage ? getLoading() :
            <ErrorBoundary fallback={"Data is unavailable"}>
              {traceSummary ?
                <Grid>
                  {
                    tableRowData ?
                      <Column lg={16} md={8} sm={4}>
                        {getTable()}
                      </Column>
                      : ""
                  }
                  {
                    anomaly ?
                      <Column lg={16}>
                        {getTextTile(anomaly)}
                      </Column>
                      : ""
                  }
               {props.flow === "DO" &&  <Column lg={16} md={8} sm={4}>
                  <DoTopologyView />
                 </Column> }
                  {
                    probableRootCause ?
                      <>
                        <Column lg={6} className="summary-tile">
                          {getRCATextTile(probableRootCause)}
                        </Column>
  
                        <Column lg={5} className="summary-tile">
                          {getSimilarRCATextTile(similarRCA)}
                        </Column>
  
                        <Column lg={5} className="summary-tile">
                          {getRunBookAutomationTile(runBookAutomation)}
                        </Column>
                      </>
                      :
                      getLoadingRCA()
                  }
                </Grid>
                : ""}
              <Grid>
                {getCPUUtilizationChartData()}
                {getMemoryUtilizationChartData()}
              </Grid>
            </ErrorBoundary>
        }
        <Grid>
          {
            showGenAITile ?
              genAISummary ?
                <Column lg={16} md={8} sm={4}>
                  <GenAISummary
                    data={genAISummary}
                    type="apa"
                    flow={props.flow}
                  />
                </Column>
                :
                getLoadingGenAI()
              : ""
          }
        </Grid>
        {
          props.flow !== "DO" && traceSummary && traceSummary.trace_graph ?
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <Accordion>
                  <AccordionItem title={getTitle('Timeline')}>
                    {<FloatingBarChart trace_graph={traceSummary.trace_graph} />}
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
            : ""
        }
        {
          props.flow !== "DO" && logDetails ?
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <Accordion>
                  <AccordionItem title={getTitle('Logs')}>
                    <Grid>
                      <Logs
                        traceId={props.traceObj.Id}
                        data={logDetails}
                        startTime={traceDiscoveryStart}
                        endTime={traceDiscoveryEnd}
                        namespace={namespace}
                        metrics={metrics}
                        functionNameTitle={functionNameTitle}
                        functionNameValue={functionNameValue}
                        stat={stat}
                        period={period}
                      />
                    </Grid>
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
            : props.flow !== "DO" ? getLoadingLogs() : null
        }

        {
          showCreateTicket ? <CreateTicket setShowPopUP={(e) =>setCreateTicket(e)} data={props.tableData} /> : null
        }
      </>
    );
  };
  
  
  export default TraceDetails;
  
