import React, { useState } from "react";
import { ArrowRight, ArrowUp, ArrowDown } from "@carbon/react/icons";
import { ClickableTile, Column, Tag, SkeletonPlaceholder } from "@carbon/react";

const VSClickableTile = ({ Json, handleOnClick }) => {

    const [active, setActive] = useState("")

    const tileClick = (data) => {
        if (data.title === "Apply for a Product") {
            setActive(data.title)
            handleOnClick(data)
        }
    }

    return (
        <>
            {Json !== undefined ? Json.map((data, index) => (
                <Column
                    lg={4}
                    md={4}
                    sm={2}
                    className="clickable-tile-portfolio-fss"
                    key={index}
                >
                    <ClickableTile
                        id={index}
                        onClick={() => tileClick(data)}
                        className={active === data.title ? "active" : ""}
                        aria-label={`clickableTile-${index}`}
                    >
                        <div>
                            <h5 className="flow-title">{data.title}</h5>
                        </div>
                        <div className="availability-status">
                            <div className="availBox">
                                {data.isAboveThreshold ? (
                                    <ArrowUp stroke="#00b050" strokeWidth="px" />
                                ) : (
                                    <ArrowDown stroke="#da1e28" strokeWidth="1px" />
                                )}
                                <h3 className={`flow-value ${!data.isAboveThreshold && "folw-value-err"}`}>{data.value}<span>%</span></h3>
                                {/* <h6
                  className={`status-title ${data.isAboveThreshold ? "green-txt" : "red-txt"
                    }`}
                >
                  {data.status}
                </h6> */}
                            </div>
                            {/* <h6 className="recent-bridge">
                                Open Bridge:{" "}
                                <span style={{ fontSize: "14px" }}>{data.recentBridge}</span>
                            </h6> */}
                        </div>
                        <div>
                            <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                        </div>
                    </ClickableTile>
                </Column>
            )) : [1, 2, 3, 4].map((ske) =>
                <Column
                    lg={4}
                    md={4}
                    sm={2}
                    className="clickable-tile-portfolio-att margin-lft"
                    key={ske}
                >
                    <SkeletonPlaceholder style={{ width: "100%", marginTop: "5px" }} />
                </Column>
            )}
        </>
    )
}

export default VSClickableTile;