import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row, Tag, Tile } from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./cpg.scss";

const CpgDetails = (props) => {
    const history = useHistory();
    const [workFlowDetails, setWorkflowDetails] = useState({})

    useEffect(() => {
        if(props.location.state !== undefined) {
            setWorkflowDetails(props.location.state.tileData);
            // let workFlw = [];
            // props.location.state.data.workflow.forEach((wrk) => {
            //     workFlw.push(wrk.title)
            // })
            // setComboBox(workFlw)
        }
    }, [])

    const getCharts = (chart) => {
        if(chart.details.charts.length > 0) {
            return <ChartContainer data={chart.details.charts[0]} />
        }
    }

    const getProcess = (process) => {
        return <div>
            <h5>Process:  <Tag className="some-class" type="purple">
        {'Plan and manage inbound product flow'}
      </Tag></h5>
        <div className="impact">
            <h5>Business KPIs Impacted</h5>
            <ul className="impact-val">{process.details.potentialMetrics.map((met) => <li className={met.color} >{met.desc} <span color={met.color} >: {met.value}</span></li>)}</ul>
        </div>
        </div>
    }

    const breadcurmRedirect = () => {
        history.push({
            pathname: "/cpgWorkflow",
            state: { data: props.location.state.workflow },
        });
    }

    return (
        Object.entries(workFlowDetails).length > 0 ? <FlexGrid className="Cpg">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">Value Stream</BreadcrumbItem>
                        <BreadcrumbItem onClick={() => breadcurmRedirect()}>Consumer Package Goods Workflow</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Consumer Package Goods Details
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
         </Row>
            <Row className="header-details">
                <Column lg={4} md={2}>
                    <Tile>
                        <h5><span>Title</span>{workFlowDetails.name}</h5>
                    </Tile>
                </Column>
                <Column lg={4} md={2}>
                {getCharts(workFlowDetails)}
                </Column>
            </Row>
            <Row className="process-details">
                <Column lg={16} md={8} sm={4}>
                    <Tile>
                    {getProcess(workFlowDetails)}
                    </Tile>
                </Column>
            </Row>
        </FlexGrid> : null
    )
}

export default CpgDetails