import { Column, FlexGrid, Row } from "@carbon/react";
import React from "react";
import TilesComponent from "./TilesComponent";
import "./nerve.scss";

const AdaniIndex = () => {

    return (
            window.location.hash === "#/nerveCenter" ?
            <FlexGrid className="adani-nerve">
                <Row>
                    <Column />
                </Row>
                <Row className="adani-nerve">
                    <Column lg={16} md={8} sm={4}>
                        {/* <NerveDashboard /> */}
                        <TilesComponent />
                    </Column>
                </Row>
            </FlexGrid> 
        :
                // <FlexGrid className="adani-nerve">
        //     <Row>
        //         <Column />
        //     </Row>
        <Row className="adani-nerve">
        <Column lg={16} md={8} sm={4}>
            {/* <NerveDashboard /> */}
            <TilesComponent />
        </Column>
    </Row>
// </FlexGrid>
    )

}

export default AdaniIndex;