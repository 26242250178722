import React, { useState } from 'react';
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@carbon/react";
import './TabsWithBottomNav.scss';

const TabsWithBottomNav = ({ tabsList, contentList }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  };

  const contentStyle = {
    flex: '1 1 auto',
    overflow: 'auto',
    paddingBottom: '48px'
  };

  const tabListContainerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'white',
    borderTop: '1px solid #e0e0e0',
    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Tabs
          selectedIndex={selectedIndex}
          onChange={(e) => setSelectedIndex(e.selectedIndex)}
        >
          <TabPanels>
            {contentList.map((content, index) => (
              <TabPanel key={index}>
                {selectedIndex === index && content}
              </TabPanel>
            ))}
          </TabPanels>

          <div style={tabListContainerStyle} className="custom-tab-container">
            <div className="custom-tab-list">
              {tabsList.map((tab, index) => (
                <button
                  key={index}
                  className={`custom-tab ${selectedIndex === index ? 'custom-tab-selected' : ''}`}
                  onClick={() => setSelectedIndex(index)}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default TabsWithBottomNav;