import { Button, CodeSnippet, Column, Row, Tile } from "@carbon/react";
import React, { useState } from "react";

const Issue = ({ selectedRow, issue, selectedFix }) => {
  return (
    <>
      <ul className="img-regis">
        <li><p>Registry:</p><span>{selectedRow.registry}</span></li>
        <li><p>Repository:</p><span>{selectedRow.imageName}</span></li>
        <li><p>Type:</p><span>{issue.Type || "-"}</span></li>
        <li><p>Package:</p><span>{issue.Package || "-"}</span></li>
        <li><p>Current version:</p><span>{issue["Current Version"] || "-"}</span></li>
        <li><p>Fix Status:</p><span>{issue["Fix Status"] || "-"}</span></li>
        <li>      <Button
          onClick={() => selectedFix(true)}
          size="sm"
          className="add-button"
          kind="primary"
        >
          Generate Fix
        </Button></li>
      </ul>
    </>
  );
};

export default Issue;
