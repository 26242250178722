import React from "react";
import { Modal } from "@carbon/react";

const PassiveModal = (props) => {
  return (
    <Modal
      open
      passiveModal
      modalHeading={props.heading}
      onRequestClose={props.canCloseDetailsPopup}
      className={props.className}
      size={props.size}
      aria-label="Close"
    >
      <div className="passive-modal center-alignment">{props.children}</div>
    </Modal>
  );
};

PassiveModal.defaultProps = {
  className: "passiveModal",
  heading: "",
};

export default PassiveModal;
