import React from "react";
import { Modal } from "@carbon/react";

const DangerModal = (props) => {
	return (
		<Modal
			open
            danger
			modalHeading={props.heading}
			primaryButtonText={props.primaryText}
			secondaryButtonText="Cancel"
			onRequestClose={() => props.onCloseModal(true)}
			onRequestSubmit={() => props.onModalSubmit()}
			primaryButtonDisabled={props.primaryButtonDisabled}
		>
			{props.children}
		</Modal>
	);
};

export default DangerModal;
