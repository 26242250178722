import {
  Button,
  Column,
  ComboBox,
  ContentSwitcher,
  Dropdown,
  Row,
  Switch,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import BarChartSkeleton from "Carbon-Charts/Skeletons/BarChartSkeleton";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import { getApplicationData, getTicketDetails } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  appBasedIt,
  colors,
  itOpsData
} from "./Utilities";
import Collab from "./operations/Collab";
import Emails from "./operations/Emails";
import Laptops from "./operations/Laptops";
import ServiceNow from "./operations/ServiceNow";

const ITOps = () => {
  const tenant = sessionStorage.getItem("tenant");
  const [year, setYear] = useState(tenant === "shell" ? "2022" : "2023");
  const [env, setEnv] = useState("Production");
  const [application, setApplication] = useState("");
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [emails_app, setEmaisl] = useState([]);
  const [service_now, setService] = useState([]);
  const [webex_app, setWebex] = useState([]);
  const [appColors, setAppColors] = useState({});
  const [laptop_app, setLaptop] = useState([]);
  const [assumptions, setAssumptions] = useState({});
  const [content, setContent] = useState("laptops");
  const [apiData, setApiData] = useState([]);


  const EA_Tenant = tenant === "Energy_Australia";
  const Ibm_consulting = tenant === process.env.REACT_DEFAULT_TENANT;
  const unit = (application || EA_Tenant || Ibm_consulting) ? "kgco2" : "mtco2";
  const displayUnit = unit === "mtco2"
    ? "mtCO₂eq"
    : "kgCO₂eq";
  const history = useHistory();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (application) {
      fetchData1();
    } else {
      fetchData();
    }
  }, [application]);

  useEffect(() => {
    if (isEmpty || apiError) {
      setIsLoading(false);
    }
  }, [isEmpty, apiError]);

  useEffect(() => {
    if (apiData.length > 0) {
      setIsLoading(true);
      if (application) {
        calculateData1();
      } else {
        calculateData();
      }
    }
  }, [apiData]);

  const reset = () => {
    setContent("laptops");
    setIsLoading(true);
    setApiError(false);
    setIsEmpty(false);
    setApiData([]);
  };

  const calculateData1 = async () => {
    try {
      const data = appBasedIt(apiData[0]);
      if (data === "Error") throw new Error("");
      const { emails_app, service_now, webex_app, laptop_app } = data;
      setEmaisl(emails_app);
      setService(service_now);
      setWebex(webex_app);
      setLaptop(laptop_app);
    } catch (error) {
      setApiError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData1 = async () => {
    reset();
    const res = await getTicketDetails(
      keycloak,
      application,
      uuidv4()
    );
    if (
      Array.isArray(res?.data.data) &&
      res.data.data.length > 0 &&
      res.data.data[0]?.application === application
    ) {
      try {
        if (
          res.data.data[0].assumptions &&
          typeof res.data.data[0].assumptions === "object"
        ) {
          setAssumptions(res.data.data[0].assumptions);
        }
        setApiData(res.data.data);
      } catch (error) {
        setApiError(true);
      }
    } else {
      setApiError(true);
    }
  };

  const calculateData = () => {
    try {
      const data = itOpsData(apiData, unit);
      if (data === "Error") throw new Error("");
      const { emails_app, service_now, webex_app, laptop_app, applications } =
        data;
      const obj = {};
      if (applications.length > 0) {
        const minArrayLength = Math.min(applications.length, colors.length);
        for (let i = 0; i < minArrayLength; i++) {
          const name = applications[i];
          const color = "#24a148";
          obj[name] = color;
        }
        setAppColors(obj);
      }
      setEmaisl(emails_app);
      setService(service_now);
      setWebex(webex_app);
      setLaptop(laptop_app);
      setApplications(applications);
    } catch (error) {
      setApiError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    reset();
    const res = await getApplicationData(
      keycloak,
      application || "",
      uuidv4(),
    );
    if (Array.isArray(res?.data)) {
      if (res.data.length > 0) {
        try {
          if (
            res.data[0].assumptions &&
            typeof res.data[0].assumptions === "object"
          ) {
            setAssumptions(res.data[0].assumptions);
          }
          setApiData(res.data);
        } catch (error) {
          setApiError(true);
        }
      } else {
        setIsEmpty(true);
      }
    } else {
      setApiError(true);
    }
  };

  const modifyData = (data) => {
    let temp = JSON.parse(JSON.stringify(data));
    return temp;
  };

  const getData = {
    Emails: emails_app,
    "Collaboration Tools": webex_app,
    "Service Now": service_now,
    Laptops: laptop_app,
  };

  const headings = {
    Emails: "Emails",
    "Collaboration Tools": "",
    "Service Now": "Service Now",
    Laptops: "Laptop",
  };

  const app_specific = (type) => ({
    label: "",
    dataType: "bar",
    data: {
      chartData: getData[type],
      chartOptions: {
        title: `${headings[type]}`,
        axes: {
          left: {
            title: `CO2 Emission (${displayUnit})`,
            mapsTo: "value",
          },
          bottom: {
            title: "Severity",
            mapsTo: "group",
            scaleType: "labels",
          },
        },
        height: "300px",
        color: {
          scale: {
            S5: "#6fdc8c",
            S4: "#42be65",
            S3: "#198038",
            S2: "#0e6027",
            S1: "#044317",
          },
        },
      },
    },
  });

  const app_specific_grouped = (type) => {
    return {
      label: "",
      dataType: "bar",
      data: {
        chartData: getData[type],
        chartOptions: {
          title: `Carbon Emission`,
          axes: {
            left: {
              title: `CO2 Emission (${displayUnit})`,
              mapsTo: "value",
              stacked: true,
            },
            bottom: {
              title: "Severity",
              mapsTo: "key",
              scaleType: "labels",
            },
          },
          height: "300px",
          color: {
            scale: {
              Slack: "#6fdc8c",
              Webex: "#42be65",
              "MS Teams": "#198038",
            },
          },
        },
      },
    };
  };

  const app_specific_stacked = (type) => {
    return {
      label: "",
      dataType: "horizontal-bar",
      data: {
        chartData: getData[type],
        chartOptions: {
          title: `Carbon Emission`,
          axes: {
            left: {
              title: `CO2 Emission (${displayUnit})`,
              mapsTo: "value",
              stacked: true,
            },
            bottom: {
              title: "Application",
              mapsTo: "key",
              scaleType: "labels",
            },
          },
          height: "400px",
          color: {
            scale: {
              Slack: "#6fdc8c",
              Webex: "#42be65",
              "MS Teams": "#198038",
            },
          },
        },
      },
    };
  };

  const year_specific = (type) => ({
    label: "",
    dataType: "simpleBar",
    data: {
      chartData: modifyData(getData[type]),
      chartOptions: {
        title: `${headings[type]}`,
        axes: {
          left: {
            title: `CO2 Emission (${displayUnit})`,
            mapsTo: "value",
          },
          bottom: {
            title: year ? "Application" : "Year",
            mapsTo: year ? "group" : "key",
            scaleType: "labels",
          },
        },
        height: "400px",
        legend: {
          enabled: false
        },
        color: {
          scale: appColors,
        },
      },
    },
  });

  const render = {
    laptops: (
      <Laptops
        application={application}
        app_specific={app_specific}
        year_specific={year_specific}
        assumptions={assumptions.laptop || []}
      />
    ),
    emails: (
      <Emails
        application={application}
        app_specific={app_specific}
        year_specific={year_specific}
        assumptions={assumptions.email || []}
      />
    ),
    service_now: (
      <ServiceNow
        application={application}
        app_specific={app_specific}
        year_specific={year_specific}
        assumptions={assumptions.serviceNow || []}
      />
    ),
    coll_tools: (
      <Collab
        application={application}
        app_specific_stacked={app_specific_grouped}
        year_specific={app_specific_stacked}
        assumptions={assumptions.commTool || []}
      />
    ),
  };

  if (isLoading) {
    return (
      <Row>
        <Column lg={8} md={4} sm={4}>
          <BarChartSkeleton />
        </Column>
        <Column lg={8} md={4} sm={4}>
          <BarChartSkeleton />
        </Column>
      </Row>
    );
  }

  if (apiError && !application) {
    return <ErrorRefresh refresh={() => fetchData()} />;
  }

  if (apiError && application) {
    return <ErrorRefresh refresh={() => fetchData1()} />;
  }

  if (isEmpty) {
    return (
      <div className="center-screen">
        <h4>Onboard Applications to view Dashboards</h4>
        <Button onClick={() => history.push("/susopsInstrumentation")}>
          Onboard Applications
        </Button>
      </div>
    );
  }

  return (
    <div className="it-ops">
      <Row className="flex-space-between">
        <Column lg={4} md={4} sm={0}>
          <Dropdown
            onChange={() => { }}
            id="carbon-combobox"
            items={[]}
            disabled={true}
            itemToString={(item) => (item ? item : "")}
            label="Select Month"
          />
        </Column>
        <Column lg={4} md={4} sm={4}>
          <Dropdown
            onChange={(e) => setYear(e.selectedItem)}
            id="carbon-combobox"
            selectedItem={year}
            items={tenant === "shell" ? ["2022"] : ["2022-2023"]}
            itemToString={(item) => (item ? item : "")}
            placeholder="Select Year"
          />
        </Column>
        <Column lg={4} md={4} sm={4}>
          <ComboBox
            onChange={(e) => setEnv(e.selectedItem)}
            id="carbon-combobox"
            selectedItem={env}
            items={["Development", "Testing", "Staging", "Production"]}
            itemToString={(item) => (item ? item : "")}
            placeholder="Select Environment"
          />
        </Column>
        <Column lg={4} md={4} sm={4}>
          <ComboBox
            onChange={(e) => setApplication(e.selectedItem)}
            id="carbon-combobox"
            selectedItem={application}
            items={applications}
            itemToString={(item) => (item ? item : "")}
            placeholder="Select Application"
          />
        </Column>
      </Row>
      <Row className="mt-1">
        <Column lg={4} md={3} sm={4} className="mt-1">
          <ContentSwitcher
            selectionMode="automatic"
            onChange={(e) => setContent(e.name)}
          >
            <Switch name="laptops" text="Devices" />
            <Switch name="emails" text="Communication"></Switch>
            <Switch name="service_now" text="ITSM" />
            <Switch name="coll_tools" text="Collaboration Tools" />
          </ContentSwitcher>
        </Column>
        <Column lg={12} md={5} sm={4}>
          {render[content]}
        </Column>
      </Row>
    </div>
  );
};

export default ITOps;
