import {
    Column,
    ContentSwitcher,
    ErrorBoundary,
    Link,
    Row,
    Switch,
    Tag,
    Tile,
    TreeNode,
    TreeView
} from "@carbon/react";
import { IbmWatsonDiscovery } from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import DataTable from "Carbon-Components/DataTable";
import TooltipCarbon from "Carbon-Components/Tooltip";
import { convertDate, convertUTCDate } from "Common-Modules/ConvertUTCDate";
import { getdirectvDetails } from "Services/ServerApi";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Elk = lazy(() => import('Common-Modules/elk'));

const WorkflowDetails = (props) => {
    //const history = useHistory();
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const [selectedRadio, setSelectedRadio] = useState("Topology");
    const [incidentDetails, setIncidentDetail] = useState([])
    const [details] = useState(props.location.state.details);
    const [nodeData, setNodeData] = useState([]);
    const [linkData, setlinkData] = useState([]);
    const [msTeamsPop, setMsTeamsPop] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [showAnalyze, setShowAnalyze] = useState([]);
    const [jobStatusDetails, setJobStatusDetails] = useState([]);

    const { data: incidentData } = useQuery(['job_incident'], async () => await getdirectvDetails(keycloak, uuidv4(), "job_incident"), { cache: queryCache })

    useEffect(() => {
        if (Object.entries(jobStatusDetails).length > 0) {
            nodeDetails()
        }
    }, [jobStatusDetails])

    useEffect(() => {
        drawGraph()
    }, [])

    useEffect(() => {
        if (incidentData !== undefined && incidentData.data !== undefined) {
            const incident = incidentData.data.job_incident[0].Job;
            setIncidentDetail(incident.map((row, index) => {
                return {
                    ...row,
                    id: index.toString()
                }
            }))
        }
    }, [incidentData])

    const drawGraph = () => {
        const state = props.location.state;
        let NodeData = [], linkData = [];
        if (state.data["DEPENDENT JOBNAME"] !== undefined) {
            state.mergeData.forEach((res) => {
                if (res.jobname === state.data.jobname) {
                    NodeData.push({ ...state.data, id: state.data["jobname"], width: 150, height: 50, color: state.data["job_status"] !== undefined ? state.data["job_status"].toLowerCase() : state.data["job_status"] })
                    linkData.push({ id: state.data["jobname"], source: state.data["jobname"], target: state.data["DEPENDENT JOBNAME"] })
                }
                if (state.data["DEPENDENT JOBNAME"] !== undefined && res.jobname === state.data["DEPENDENT JOBNAME"]) {
                    NodeData.push({ ...res, id: res["jobname"], width: 150, height: 50, color: res["job_status"] !== undefined ? res["job_status"].toLowerCase() : res["job_status"] })
                }
            })
        } else {
            const data = props.location.state.data;
            if (data["jobname"] !== undefined) {
                NodeData.push({ ...data, id: data["jobname"], width: 150, height: 50, color: data.job_status });
                linkData.push({ id: data["jobname"], source: data["jobname"], target: data["jobname"] });
            } else {
                NodeData.push({ ...data, id: data["jobname"], width: 150, height: 50, color: data.job_status });
            }
        }

        setlinkData(NodeData.length > 1 ? linkData : []);
        setNodeData(NodeData);
    }

    const onContentSwitch = (eve) => {
        setSelectedRadio(eve.text)
    }

    const cardClick = (data) => {
        setJobStatusDetails(data)
    }

    // const redirectHRef = () => {
    //     history.push({ pathname: window.location.href.includes("icpsa") ? "/icpsa/ATTWorkflowIndex" : "/ATTWorkflowIndex", state: { directvData: props.location.state.directvData, mergeData: props.location.state.mergeData, starCric: props.location.state.starCric, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange } })
    // }

    const nodeDetails = () => {
        let getTask = [];
        jobStatusDetails.task_details !== undefined && jobStatusDetails.task_details.length > 0 && jobStatusDetails.task_details.forEach((task) => {
            let getChild = [];
            jobStatusDetails.child_task_details.forEach((child) => {
                if (task.task_name === child.task_name && task.task_id === child.task_id && task.observedtime.substring(0, 19) === child.observedtime.substring(0, 19)) {
                    getChild.push(child)
                }
            })
            getTask.push({ ...task, children: getChild })
        })
        const nodes = [{
            id: '1',
            value: 'Job Id',
            label: <><h6>Job Id</h6><span>: {jobStatusDetails.jobid !== undefined ? jobStatusDetails.jobid : "-"}</span></>,
        }, {
            id: '2',
            value: 'Job Name',
            label: <><h6>Job Name</h6><span>: {jobStatusDetails.jobname !== undefined ? jobStatusDetails.jobname : "-"}</span></>,
        }, {
            id: '3',
            value: 'Observed Time',
            label: <><h6>Observed Time</h6><span>: {jobStatusDetails.observedtime !== undefined ? jobStatusDetails.observedtime : "-"}</span></>,
        }, {
            id: '4',
            value: 'Schedule Time',
            label: <><h6>Schedule Time</h6><span>: {jobStatusDetails["SCHEDULE TIME"] !== undefined ? jobStatusDetails["SCHEDULE TIME"] : "-"}</span></>,
        }, {
            id: '5',
            value: 'Job Status',
            label: <><h6>Job Status</h6>
                : <Tag className={`some-class ${jobStatusDetails.job_status === ("start" || "running") ? "yellow" : ""} `} type={jobStatusDetails.job_status.toLowerCase() === "error" ? "red" : jobStatusDetails.job_status === "done" ? "green" : "grey"} title="Clear Filter">
                    {jobStatusDetails.job_status}
                </Tag>
            </>
        }, {
            id: '6',
            value: 'Run Id',
            label: <><h6>Run Id</h6><span>: {jobStatusDetails.runid !== undefined ? jobStatusDetails.runid : "-"}</span></>,
        }, {
            id: '7',
            value: 'Start Time',
            label: <><h6>Start Time</h6><span>: {jobStatusDetails.starttime !== undefined ? jobStatusDetails.starttime : "-"}</span></>,
        },
        {
            id: '8',
            value: 'Message',
            label: <><h6>Job Message</h6><span className="node-details--message">: {jobStatusDetails.job_message !== undefined ? "Internal session terminated with runtime error DBIF_RSQL_SQL_ERROR" : "-"}</span></>,
        },
        {
            id: '10',
            value: 'Job Created by',
            label: <><h6>Job Created by</h6><span className="node-details--message">: {"CPIC-WA-BM"}</span></>,
        },
        {
            id: '11',
            value: 'Program',
            label: <><h6>Program</h6><span className="node-details--message">: {"SDBILLDL"}</span></>,
        },
        {
            id: '12',
            value: 'Variant',
            label: <><h6>Variant</h6><span className="node-details--message">: {"DHL_AU_BIL_CR1"}</span></>,
        },
        {
            id: '13',
            value: 'Frequency',
            label: <><h6>Frequency</h6><span className="node-details--message">: {"15Mins"}</span></>,
        },
        {
            id: '9',
            value: 'Cluster Details',
            label: <h6>Cluster Details</h6>,
            children: [
                {
                    id: '8-1',
                    value: 'Cluster Status',
                    label: <><h6>Cluster Status</h6><span>: {jobStatusDetails?.cluster_details?.cluster_status !== undefined ? jobStatusDetails?.cluster_details?.cluster_status : "-"}</span></>,
                }, {
                    id: '8-2',
                    value: 'Node Type Id',
                    label: <><h6>Node Type Id</h6><span>: {jobStatusDetails?.cluster_details?.node_type_id !== undefined ? jobStatusDetails?.cluster_details?.node_type_id : "-"}</span></>,
                }, {
                    id: '8-3',
                    value: 'Number of Workers',
                    label: <><h6>Number of Workers</h6><span>: {jobStatusDetails?.cluster_details?.num_workers !== undefined ? jobStatusDetails?.cluster_details?.num_workers : "-"}</span></>,
                }
            ]
        }];



        let mergeData = [...nodes, ...getTask]

        if (jobStatusDetails.child_task_details !== undefined && getTask.length === 0 && jobStatusDetails.child_task_details.length > 0) {
            let collectData = []
            if (getTask.length > 0) {
                getTask.forEach((task) => {
                    jobStatusDetails.child_task_details.forEach((child) => {
                        if (task.observedtime !== child.observedtime) {
                            collectData.push(child)
                        }
                    })
                })
            } else {
                collectData = jobStatusDetails.child_task_details
            }
            const removeDp = collectData.filter((obj, index) => {
                return index === collectData.findIndex(o => obj.observedtime === o.observedtime);
            });
            mergeData = [...mergeData, ...removeDp]
        }

        let count = 1;
        const nodeData = mergeData.map((node, index) => {
            let data = node;
            if (node.id === undefined && getTask.length > 0 && node.child_task_status === undefined) {
                data = {
                    id: (index + 1).toString(),
                    value: node.task_name,
                    label: <div className="child_task"><div className="first-row"><h6>Task Name: <span>{node.task_name}</span></h6>
                        {node.task_status !== undefined && node.task_status !== "" ? <Tag className={`some-class ${node.task_status === ("start" || "running") ? "yellow" : ""} `} type={node.task_status === "error" ? "red" : node.task_status === "done" ? "green" : "grey"} title="Clear Filter">
                            {node.task_status}
                        </Tag> : null}
                    </div><div className="second-row"><h6>Observed Time: </h6><span className="observedtime">{node.observedtime}</span></div></div>,
                    ...(node.children !== undefined && {
                        children: node.children.map((child, inx) => {
                            count = inx === 0 ? count + inx : count + 3;
                            return {
                                id: `${(index + 1).toString()}-${(count).toString()}`,
                                value: child.task_name,
                                label: <div className="child_task"><div className="first-row"><h6>Child Task Name: <span>{child.task_name}</span></h6>
                                    {child.child_task_status !== undefined && child.child_task_status !== "" ? <Tag className={`some-class ${child.child_task_status === ("start" || "running") ? "yellow" : ""} `} type={child.child_task_status === "error" ? "red" : child.child_task_status === "done" ? "green" : "grey"} title="Clear Filter">
                                        {child.child_task_status}
                                    </Tag> : null} </div>
                                    <div className="second-row"><span className="observedtime">Observed Time: {child.observedtime}</span></div>
                                </div>,
                                children: [{
                                    id: `${(index + 1).toString()}-${(count + 1).toString()}`,
                                    value: "child_task_message",
                                    label: <><h6>Task Message</h6><span className="node-details--message">{child.child_task_message}</span></>,
                                }]
                            }
                        })
                    }),
                }
            } else if (node.id === undefined && jobStatusDetails.child_task_details !== undefined && jobStatusDetails.child_task_details.length > 0) {
                data = {
                    id: (index + 1).toString(),
                    value: node.task_name,
                    label: <div className="child_task"><div className="first-row"><h6>Task Name: <span>{node.task_name}</span></h6>
                        {node.child_task_status !== undefined && node.child_task_status !== "" ? < Tag className={`some-class ${["start", "running"].includes(node.child_task_status) ? "yellow" : ""} `} type={node.child_task_status === "error" ? "red" : node.child_task_status === "done" ? "green" : "grey"} title="Clear Filter">
                            {node.child_task_status}
                        </Tag> : null
                        }
                    </div> <div className="second-row"><h6>Observed Time: </h6><span className="observedtime">{node.observedtime}</span></div></div>,
                    children: [{
                        id: `${(index + 1).toString()}-1`,
                        value: "child_task_message",
                        label: <><h6>Task Message</h6><span className="node-details--message">{node.child_task_message}</span></>,
                    }]
                }
            }
            return data;
        })

        setTaskDetails(nodeData)

    }

    function renderTree({
        taskDetails,
        expanded,
        withIcons = false
    }) {
        if (!taskDetails) {
            return;
        }
        return taskDetails.map(({
            children,
            renderIcon,
            isExpanded,
            ...nodeProps
        }) => <TreeNode key={nodeProps.id} renderIcon={withIcons ? renderIcon : null} isExpanded={expanded ?? isExpanded} {...nodeProps}>
                {renderTree({
                    taskDetails: children,
                    expanded: false,
                    withIcons
                })}
            </TreeNode>);
    }

    const getTableHeader = (header) => {
        return header;
    };

    // const getState = (num) => {
    //     switch (num) {
    //         case "1":
    //             return "New";
    //         case "2":
    //             return "In Progress";
    //         case "3":
    //             return "On Hold";
    //         case "Closed":
    //             return "Resolved";
    //         default:
    //             return "null";
    //     }
    // };

    const getPriority = (num) => {
        switch (num) {
            case "Priority 1":
                return <div>P1 - Critical</div>;
            case "Priority 2":
                return <div>P2 - High </div>;
            case "3 - Medium":
                return <div>Medium </div>;
            case "Priority 4":
                return <div>P4 - Low </div>;
            case "Priority 5":
                return <div>P5 - Very Low </div>;
            default:
                return "null";
        }
    };


    const getRowCellData = (id, data, row) => {
        if (id.includes("Number")) {
            return data
        }
        if (id.includes("Priority")) {
            return getPriority(data)
        }
        if (id.includes("Opened")) {
            return convertDate(
                "dd-mm-yy",
                convertUTCDate(data),
                "created",
            )
        }
        if (id.includes(":Short description")) {
            try {
                if (data.length <= 50) {
                    return data;
                } else {
                    let temp = data.substring(0, 50) + "...";
                    return (
                        <div className="desc-tooltip">
                            <TooltipCarbon description={data}>{temp}</TooltipCarbon>
                        </div>
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
        return data;
    };

    const onClickAnalyze = (row) => {
        const tableRow = getRowActions(row);
        const res = incidentDetails.filter((inc) => inc.Number === tableRow[0].Number)
        setShowAnalyze(res)
    }

    function getRowActions(row) {
        let tableRow = [];
        row.cells.forEach((cell) => {
            incidentDetails.forEach((rows) => {
                if (cell.id.includes("Number")) {
                    if (cell.value === rows.Number) {
                        tableRow.push(rows)
                    }
                }
            })

        })
        return tableRow;
    }

    // const onClickTeams = (row) => {
    //     const tableRow = getRowActions(row);
    //     const res = incidentDetails.filter((inc) => inc.Number === tableRow[0].Number)
    //     // console.log("res ========", res)
    //     setMsTeamsPop(res)
    // }
    // const onClickTeams = (row) => {
    //     const tableRow = getRowActions(row);
    //     const res = incidentDetails.filter((inc) => inc.Number === tableRow[0].Number)
    //     setMsTeamsPop(res)
    // }

    const getActions = (row, cell) => {
        return (
            <ul className="action-icons">
                <li
                    className="enable"
                    title="Analyze"
                    onClick={() => onClickAnalyze(row)}
                >
                    <IbmWatsonDiscovery />
                </li>
            </ul>
        );
    };

    const headerData = [{
        key: "Number",
        header: "Incident ID"
    }, {
        key: "State",
        header: "Status"
    }, {
        key: "Priority",
        header: "Priority"
    }, {
        key: "Short description",
        header: "Short Description"
    }, {
        key: "Opened",
        header: "Created"
    }]

    // const getMsTeamsLink = (row) => {
    //     const msTeamChannel = [{
    //         "Number": "INC0465403",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3A41fe92514d6f45deb48ebe5ae5042d2f%40thread.tacv2/ken_inc0465403?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
    //     },
    //     {
    //         "Number": "INC0465415",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3A55a59fe41878494ab0727d4995ded081%40thread.tacv2/ken_inc0465415?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
    //     },
    //     {
    //         "Number": "INC0465427",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3Afda9d0197faf49e182a44f205c5f9c3d%40thread.tacv2/ken_inc0465427?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
    //     },
    //     {
    //         "Number": "INC0465435",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3A89ecea3b89e54a9b85780cf72a5f7436%40thread.tacv2/ken_inc0465435?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId="
    //     },
    //     {
    //         "Number": "INC0465443",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3Ae4d0555d292d43188deaee49d3c27e55%40thread.tacv2/ken_inc0465443?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId=c0424eda-a959-4e36-8de7-4211781c10bf"
    //     }, 
    //     {
    //         "Number": "INC0468240",
    //         "channel": "https://teams.microsoft.com/l/channel/19%3A3de6fd4451bd4f04b20817e207ad121c%40thread.tacv2/ken_inc0468240?groupId=45d4979d-5a25-4a34-acc2-a2c97da58b60&tenantId=c0424eda-a959-4e36-8de7-4211781c10bf"
    //     }]

    //     const filterChanel = msTeamChannel.filter((chanel) => chanel.Number === row[0].Number)
    //     // console.log("filterChanel", filterChanel)
    //     return filterChanel.length > 0 ? <h5>Channel got created with link as <Link href={filterChanel[0].channel} target="_blank" rel="noopener noreferrer">Microsoft Teams</Link></h5> : "No Microsoft Teams Channel Created for this Incident"
    // }
    //     const filterChanel = msTeamChannel.filter((chanel) => chanel.Number === row[0].Number)
    //     return filterChanel.length > 0 ? <h5>Channel got created with link as <Link href={filterChanel[0].channel} target="_blank" rel="noopener noreferrer">Microsoft Teams</Link></h5> : "No Microsoft Teams Channel Created for this Incident"
    // }

    return (
        Object.entries(details).length > 0 ?
            <>
                <Row>
                    <Column lg={4} md={2} sm={2}>
                        <ContentSwitcher onChange={(e) => onContentSwitch(e)}>
                            <Switch name="Topology" text="Topology" />
                            <Switch name="Details" text="Incident Details" />
                        </ContentSwitcher>
                    </Column>
                </Row>
                <Row>
                    <Column lg={16} md={10} sm={4} className="workflow-de">
                        {selectedRadio === "Topology" ? <Tile>
                            {nodeData.length > 0 ? <>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Elk nodes={nodeData} links={linkData} layout="layered" width="50%" cardClick={cardClick} />
                                </Suspense>
                                {taskDetails.length > 0 ? <ul className="job_wrk_details">
                                    <TreeView label="Details">
                                        {renderTree({ taskDetails, expanded: false, withIcons: false })}
                                    </TreeView>
                                </ul> : null}
                            </> : <div className="msg">No Job Details Found</div>}
                        </Tile> : null}
                        {selectedRadio === "Incident Details" ? <>
                            <Row>
                                <Column lg={16} md={8} sm={2}>
                                    <ErrorBoundary fallback={"Data is unavailable"}>
                                        <DataTable
                                            title="Incidents"
                                            rowData={incidentDetails}
                                            headerData={headerData}
                                            getRowCellData={getRowCellData}
                                            getTableHeader={getTableHeader}
                                            actionsNeeded={false}
                                            className="snow-table"
                                            table_particular_actions={false}
                                            getActions={getActions}
                                        />
                                    </ErrorBoundary>
                                </Column>
                            </Row> </> : null}
                    </Column>
                </Row>
            </>
            : null
    )
}

export default WorkflowDetails;