import { StackedAreaChart } from "@carbon/charts-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Modal,
  Row,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tile,
} from "@carbon/react";
import { Report } from "@carbon/react/icons/index";
import Context from "Context/Context";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonMLModal from "../mlModal";
import TopologyGraph from "./TopologyGraph";
import "./fins.scss";
import Summarize from "./summarize";

const WorkflowDetails = (props) => {
  const history = useHistory();
  const [showTopology, setShowTopology] = useState(false);
  const [dateIndexChange, onDateIndexChange] = useState(2);
  const [dateChange, onDateChange] = useState("1 Day");
  const [bridgeId, sendBridgeId] = useState(null);
  const [showSummaryModal, setShowSummaryMd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const context = useContext(Context);
  const title = sessionStorage.getItem("portfolioTitle");
  const selectedFlow = context.valueStreamData.data.filter(
    (data) => data.title === title
  );
  // console.log("selectedFlow", selectedFlow);

  const onClickShowTopology = (stepName) => {
    // console.log("clicked", stepName);
    sessionStorage.setItem("CJstepName", stepName);
    setShowTopology(true);
  };
  const VolumeChart = () => {
    const data = [
      {
        group: "Disconnection",
        date: "2024-03-01T10:30:00.000Z",
        value: 114656,
      },
      {
        group: "Disconnection",
        date: "2024-03-02T10:30:00.000Z",
        value: 78110,
      },
      {
        group: "Disconnection",
        date: "2024-03-03T10:30:00.000Z",
        value: 58005,
      },
      {
        group: "Disconnection",
        date: "2024-03-04T10:30:00.000Z",
        value: 106970,
      },
      {
        group: "Disconnection",
        date: "2024-03-05T10:30:00.000Z",
        value: 107207,
      },
      {
        group: "Disconnection",
        date: "2024-03-06T10:30:00.000Z",
        value: 110034,
      },
      {
        group: "Disconnection",
        date: "2024-03-07T10:30:00.000Z",
        value: 129331,
      },
      {
        group: "Disconnection",
        date: "2024-03-08T10:30:00.000Z",
        value: 87678,
      },
      {
        group: "Disconnection",
        date: "2024-03-09T10:30:00.000Z",
        value: 96067,
      },
      {
        group: "Disconnection",
        date: "2024-03-10T10:30:00.000Z",
        value: 78205,
      },
      {
        group: "Disconnection",
        date: "2024-03-11T10:30:00.000Z",
        value: 109218,
      },
      {
        group: "Disconnection",
        date: "2024-03-12T10:30:00.000Z",
        value: 89830,
      },
      {
        group: "Disconnection",
        date: "2024-03-13T10:30:00.000Z",
        value: 120578,
      },
      {
        group: "Activations",
        date: "2024-03-01T10:30:00.000Z",
        value: 155702,
      },
      {
        group: "Activations",
        date: "2024-03-02T10:30:00.000Z",
        value: 172747,
      },
      {
        group: "Activations",
        date: "2024-03-03T10:30:00.000Z",
        value: 126174,
      },
      {
        group: "Activations",
        date: "2024-03-04T10:30:00.000Z",
        value: 152178,
      },
      {
        group: "Activations",
        date: "2024-03-05T10:30:00.000Z",
        value: 171708,
      },
      {
        group: "Activations",
        date: "2024-03-06T10:30:00.000Z",
        value: 157265,
      },
      {
        group: "Activations",
        date: "2024-03-07T10:30:00.000Z",
        value: 192822,
      },
      {
        group: "Activations",
        date: "2024-03-08T10:30:00.000Z",
        value: 149617,
      },
      {
        group: "Activations",
        date: "2024-03-09T10:30:00.000Z",
        value: 187956,
      },
      {
        group: "Activations",
        date: "2024-03-10T10:30:00.000Z",
        value: 138059,
      },
      {
        group: "Activations",
        date: "2024-03-11T10:30:00.000Z",
        value: 146523,
      },
      {
        group: "Activations",
        date: "2024-03-12T10:30:00.000Z",
        value: 161215,
      },
      {
        group: "Activations",
        date: "2024-03-13T10:30:00.000Z",
        value: 157282,
      },
    ];
    const options = {
      title: "No.of Activation/Disconnection over time",
      axes: {
        bottom: {
          mapsTo: "date",
          scaleType: "time",
          title: "Date",
        },
        left: {
          stacked: true,
          mapsTo: "value",
          scaleType: "linear",
        },
      },
      curve: "curveMonotoneX",
      height: "175px",
      color: {
        scale: {
          Activations: "#198038",
          Disconnection: "#fa4d56",
        },
      },
    };

    return <StackedAreaChart data={data} options={options}></StackedAreaChart>;
  };

  // const dateOnChange = (e, index) => {
  //   // console.log("e", e);
  //   // console.log("index", index);
  //   onDateIndexChange(index);
  //   onDateChange(e);
  // };

  // const dateSelection = () => {
  //   const dateArr = ["1 Hr", "12 Hr", "1 Day"];
  //   return dateArr.map((date, index) => (
  //     <li
  //       onClick={() => dateOnChange(date, index)}
  //       className={index === dateIndexChange && "active"}
  //       key={date}
  //     >
  //       {date}
  //     </li>
  //   ));
  // };

  const redirectToIne = (page, node) => {
    // console.log("node ----------", node);
    if (page === true) {
      // showInferencePage(true)
      history.push({
        pathname: "/WorkflowJoruneyInference",
        state: { data: node.label },
      });
    }
  };

  const onSummaryModal = (details) => {
    setShowSummaryMd(true);
    sendBridgeId(details);
  };

  const ShowSummaryMd = () => {
    setShowSummaryMd(false);
  };

  const onIncidnetIdClick = (details) => {
    sendBridgeId(details);
    setShowModal(true);
  };

  const showApplication = () => {
    setShowApps(true);
  };

  const getApplication = () => {
    // const title = sessionStorage.getItem("portfolioTitle");
    // console.log("portfolioDataJSON", context.valueStreamData);
    const port = context.valueStreamData.data.filter(
      (data) => data.title === title
    );
    return (
      <ul className="app-cnt">
        {port[0].child.map((app) => (
          <li key={app.name}>{app.name}</li>
        ))}
      </ul>
    );
  };

  const getAppsCount = () => {
    // const title = sessionStorage.getItem("portfolioTitle")
    // const port = portfolioDataJSON.portfolioData[0].data.filter((data) => data.title === title);
    return 5;
  };

  // const journ = [
  //   {
  //     name: "Wallet balance calculation & Disconnect operation command in prepaid",
  //     target: "2",
  //     actual: "2",
  //   },
  //   {
  //     name: "Receive disconnection command in billing",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "Forward disconnection command to MDM",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "MDM receives disconnection command",
  //     target: "2",
  //     actual: "1",
  //   },
  //   {
  //     name: "MDM forwards disconnection command to HES",
  //     target: "2",
  //     actual: "8",
  //   },
  //   {
  //     name: "HES receives disconnection command from MDM",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "HES forwards disconnection command to meter",
  //     target: "2",
  //     actual: "2",
  //   },
  //   {
  //     name: "Consumer meter disconnected",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "Meter sends disconnection status update to HES",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "HES receives disconnection status from meter",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "HES sends disconnection status to MDM",
  //     target: "1",
  //     actual: "1",
  //   },
  //   {
  //     name: "MDM receives disconnection status from HES",
  //     target: "1",
  //     actual: "1",
  //   },
  // ];

  const journey = () => {
    return selectedFlow[0].journeySteps.map((jo) => {
      return (
        <ul className={jo.error ? "red" : ""} key={jo.name}>
          <li
            className="first-li"
            onClick={
              jo.error
                ? () => onClickShowTopology(jo.name)
                : undefined
            }
          >
            {jo.name}
          </li>
          <li className="sec-li">{jo.target}</li>
          <li className="the-li">{jo.actual}</li>
        </ul>
      );
    });
  };

  return (
    <FlexGrid className="fin_service">
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/valueStream">
                IT Value Stream Observability
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>WorkFlow</BreadcrumbItem>
            </Breadcrumb>
          </Column>
          {/* <Column lg={3} md={2} sm={2}>
            <ul className="portfolio-fss wid-change">{dateSelection()}</ul>
          </Column> */}
        </Row>
        <Row>
          <Column lg={4} md={4} sm={4} className="no--right__padding">
            <Tile className="first-row ">
              <h3 className="workflow-title">
                {sessionStorage.getItem("portfolioTitle")}
              </h3>
              {/* <h5>EKYC Activation User Journey</h5> */}
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row " onClick={() => showApplication()}>
              <div className="sec-1">
                <h6>No. of Applications</h6>
                <span>{getAppsCount()}</span>
              </div>
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-2">
                <h6>Events from Infra</h6>
                {dateChange === "1 Day" && <span>2</span>}
                {dateChange === "12 Hr" && <span>2</span>}
                {dateChange === "1 Hr" && <span>2</span>}
              </div>
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-3">
                <h6>Application Events</h6>
                {dateChange === "1 Day" && <span>3</span>}
                {dateChange === "12 Hr" && <span>3</span>}
                {dateChange === "1 Hr" && <span>3</span>}
              </div>
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-4 br">
                <h6>Changes</h6>
                {dateChange === "1 Day" && <span>1</span>}
                {/* {dateChange === "15min" && <span>29</span>}
              {dateChange === "30min" && <span>32</span>} */}
              </div>
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-4 br">
                <h6>Disconnection Status</h6>
                {dateChange === "1 Day" && (
                  <span style={{ color: "#da1e28" }}>
                    90<p>%</p>
                  </span>
                )}
                <p>Target: 95%</p>
                {/* {dateChange === "15min" && <span>29</span>}
              {dateChange === "30min" && <span>32</span>} */}
              </div>
            </Tile>
          </Column>
          <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-4 br collaborate">
                <h6>Collaboration Channel</h6>
                                <a
                                  href={sessionStorage.getItem("msTeams")}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="slack"
                                    src={
                                      require(`../../../../Assets/External/ms-teams.svg`)
                                        .default
                                    }
                                  />
                                </a>
                {/* {dateChange === "15min" && <span>29</span>}
              {dateChange === "30min" && <span>32</span>} */}
              </div>
            </Tile>
          </Column>
          {/* <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-4 br">
                <h6>Bill data collected </h6>
                {dateChange === "1 Day" && <span>99<p>%</p></span>}
                <p>Target: 98%</p>
                {dateChange === "15min" && <span>29</span>}
              {dateChange === "30min" && <span>32</span>}
              </div>
            </Tile>
          </Column> */}
          {/* <Column lg={2} md={2} sm={2} className="no--right__padding">
            <Tile className="first-row ">
              <div className="sec-5 br">
                <h6>Recovery Bridge</h6>
                {dateChange === "5min" && <span>1</span>}
                {dateChange === "15min" && <span>29</span>}
              {dateChange === "30min" && <span>32</span>}
              </div>
            </Tile>
          </Column> */}
          {/* <Column lg={6} md={6} sm={4} className="no--right__padding">
          <Tile className="structred-list-table-view add-border">
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Recent Bridges</StructuredListCell>
                  <StructuredListCell head>Status</StructuredListCell>
                  <StructuredListCell head>Start Time</StructuredListCell>
                  <StructuredListCell head>RCA</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              <StructuredListBody>
                <StructuredListRow className="table-row">
                  <StructuredListCell noWrap>
                    High Failure Rate
                  </StructuredListCell>
                  <StructuredListCell>Open</StructuredListCell>
                  <StructuredListCell>15 min ago </StructuredListCell>
                  <StructuredListCell></StructuredListCell>
                </StructuredListRow>
                <StructuredListRow className="table-row">
                  <StructuredListCell noWrap></StructuredListCell>
                  <StructuredListCell>Closed</StructuredListCell>
                  <StructuredListCell>2 hours ago </StructuredListCell>
                  <StructuredListCell>False Alert </StructuredListCell>
                </StructuredListRow>
              </StructuredListBody>
            </StructuredListWrapper>
          </Tile>
        </Column> */}
        </Row>
        <Row>
          <Column lg={6} md={4} sm={2} className="no--right__padding">
            <Tile className="add-border">
              <div className="chart">{VolumeChart()}</div>
            </Tile>
          </Column>
          <Column lg={2} md={3} sm={2} className="no--right__padding">
            <Tile className="add-border">
              <div className="interact">
                <h4 className="interaction-title">Disconnection Requests</h4>
                <p>
                  Total <span>1987</span>
                </p>
                <div className="sec-4">
                  <h5>
                    Success <span>1900</span>
                  </h5>
                </div>
                <div className="sec-5">
                  <h5>
                    Fail <span>87</span>
                  </h5>
                </div>
              </div>
            </Tile>
          </Column>
          {/* <Column lg={8} md={8} sm={4}>
            <Tile className="structred-list-table-view add-border">
              <h6 className="cust-journey">Customer Journey Steps</h6>
              <Row>
                {workflowJSON.workFlowStatus
                  .filter((fil) => fil.key === dateChange)[0]
                  .data.map((workFlowDetails) => {
                    return (
                      <Column
                        lg={5}
                        md={5}
                        sm={2}
                        className="no--right__padding"
                      >
                        <Tile className="structred-list-table-view second-row-table-fss">
                          <h5 style={{ paddingLeft: "3px", fontWeight: "600", fontSize: "0.75rem" }}>
                            {workFlowDetails.title}
                          </h5>
                          <div
                            className={`sec-row-detail ${workFlowDetails.isError && "sec-row-detail-err"
                              }`}
                            {...(workFlowDetails.isError
                              ? {
                                onClick: () => {
                                  onClickShowTopology();
                                },
                              }
                              : {})}
                          >
                            <h5 className="value">{workFlowDetails.value}</h5>
                            <h6
                              className={`status ${workFlowDetails.isError && "status-err"
                                }`}
                            >
                              {workFlowDetails.status}
                            </h6>
                          </div>
                        </Tile>
                      </Column>
                    );
                  })}
              </Row>
            </Tile>
          </Column> */}
          <Column lg={8} md={8} sm={4} className="journey-ul">
            <ul>
              <li className="first-li">Journey Steps</li>
              <li className="sec-li">Target (mins)</li>
              <li className="the-li">Actual (mins)</li>
            </ul>
            {journey()}
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={10} sm={4} className="no--right__padding">
            <Tile className="structred-list-table-view add-border">
              {/* <h5 className="last-row-title">Events (Open and Closed) Summary</h5> */}
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Bridge Id</StructuredListCell>
                    <StructuredListCell head>Priority</StructuredListCell>
                    <StructuredListCell head>Incident Id</StructuredListCell>
                    <StructuredListCell head>Description</StructuredListCell>
                    <StructuredListCell head>Application</StructuredListCell>
                    <StructuredListCell head>Status</StructuredListCell>
                    <StructuredListCell head>Action</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {selectedFlow[0].eventsSummary.map((eventSummaryDetails) => {
                  return (
                    <>
                      <StructuredListBody>
                        <StructuredListRow className="table-row">
                          <StructuredListCell noWrap>
                            <div
                              className="bridge-sum"
                              onClick={() =>
                                onSummaryModal(eventSummaryDetails)
                              }
                            >
                              {eventSummaryDetails.bridgeId}
                            </div>
                          </StructuredListCell>
                          <StructuredListCell>
                            {eventSummaryDetails.severity}
                          </StructuredListCell>
                          <StructuredListCell>
                            <div
                              className="bridge-sum"
                              onClick={() =>
                                onIncidnetIdClick(eventSummaryDetails)
                              }
                            >
                              {eventSummaryDetails.IncidentId}
                            </div>
                          </StructuredListCell>
                          <StructuredListCell noWrap className="desc">
                            {eventSummaryDetails.description}
                          </StructuredListCell>
                          <StructuredListCell>
                            {eventSummaryDetails.applicationName}
                          </StructuredListCell>
                          <StructuredListCell noWrap>
                            {eventSummaryDetails.status}
                          </StructuredListCell>
                          <StructuredListCell noWrap>
                            {/* {eventSummaryDetails.status === "Open" ? */}
                            <ul className="action-icon">
                              <li>
                                <a
                                  className={
                                    eventSummaryDetails.status === "Open"
                                      ? "active"
                                      : "disable"
                                  }
                                  href="https://ibm.webex.com/meet/hpramod"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="webex"
                                    src={
                                      require(`../../../../Assets/External/WebEx.png`)
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://ibm-caes.slack.com/archives/C043KJ9DL5S"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="slack"
                                    src={
                                      require(`../../../../Assets/External/slack.svg`)
                                        .default
                                    }
                                  />
                                </a>
                              </li>
                              <li
                                title="Summary Details"
                                onClick={() =>
                                  onSummaryModal(eventSummaryDetails)
                                }
                              >
                                <Report size={18} />
                              </li>
                            </ul>
                            {/* : null} */}
                          </StructuredListCell>
                        </StructuredListRow>
                      </StructuredListBody>
                    </>
                  );
                })}
              </StructuredListWrapper>
            </Tile>
          </Column>
          {/* <Column lg={6} md={6} sm={4} className="no--right__padding">
            <Tile className="structred-list-table-view add-border">
              <h4 className="last-row-title">Application Health</h4>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Application</StructuredListCell>
                    <StructuredListCell head>Availability</StructuredListCell>
                    <StructuredListCell head>Success</StructuredListCell>
                    <StructuredListCell head>Failure</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {applicationHealthJSON.applicationHealth.map(
                  (appHealthDetails) => {
                    return (
                      <>
                        <StructuredListBody>
                          <StructuredListRow className="table-row">
                            <StructuredListCell
                              noWrap
                              className={`${appHealthDetails.isError && "error-status"
                                }`}
                            >
                              {appHealthDetails.application}
                            </StructuredListCell>
                            <StructuredListCell
                              noWrap
                              className={`${appHealthDetails.isError && "error-status"
                                }`}
                            >
                              {appHealthDetails.availability}
                            </StructuredListCell>
                            <StructuredListCell
                              noWrap
                              className={`${appHealthDetails.isError && "error-status"
                                }`}
                            >
                              {appHealthDetails.success}
                            </StructuredListCell>
                            <StructuredListCell
                              noWrap
                              className={`${appHealthDetails.isError && "error-status"
                                }`}
                            >
                              {appHealthDetails.failure}
                            </StructuredListCell>
                          </StructuredListRow>
                        </StructuredListBody>
                      </>
                    );
                  }
                )}
              </StructuredListWrapper>
            </Tile>
          </Column> */}
        </Row>
        {showModal && (
          <CommonMLModal setShowModal={setShowModal} ticketDetails={bridgeId} />
        )}
        {showSummaryModal && (
          <Summarize ShowSummaryMd={ShowSummaryMd} bridgeDetails={bridgeId} />
        )}
        {showTopology && (
          <Modal
            open
            size="lg"
            passiveModal
            isFullWidth
            onRequestClose={() => setShowTopology(false)}
            modalHeading="Topology View"
            modalLabel="Application Topology View"
            className="fss-topology"
          >
            <TopologyGraph redirectToIne={redirectToIne} />
            {/* <TreeDiagram /> */}
          </Modal>
        )}

        {showApps ? (
          <Modal
            open
            size="lg"
            passiveModal
            isFullWidth
            onRequestClose={() => setShowApps(false)}
            modalHeading={`${sessionStorage.getItem(
              "portfolioTitle"
            )} Applications:`}
            modalLabel=""
            className="fss-topology"
          >
            {getApplication()}
          </Modal>
        ) : null}
      </>
    </FlexGrid>
  );
};

export default WorkflowDetails;
