import * as d3 from "d3";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ForceDirected = ({ nodeData, linkData, workFlowDetails, location, resUniq }) => {
    const history = useHistory();
    useEffect(() => {
        generateGraph()
    }, [nodeData, linkData])

    const generateGraph = () => {
        d3.select("#topoSvg").remove();
        const width = 900;
        const height = 600;
        const links = linkData.map(d => ({ ...d }));
        const nodes = nodeData.map(d => ({ ...d }));

        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.id).distance(20))
            .force("charge", d3.forceManyBody().strength(-20))
            .force("x", d3.forceX())
            .force("y", d3.forceY(height / 8));
        function getForce() {
            return d3.forceX(width / 2).strength(0)
        }

        const svg = d3.select("#force-grp")
            .append("svg")
            .attr("id", "topoSvg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-300,-330,600,820])
            .attr("style", "max-width: 100%; height: auto;");

        const link = svg.append("g")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .join("line")
            .attr("class", "link")
            .attr("stroke-width", d => Math.sqrt(2))

        const node = svg.append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 0.5)
            .selectAll("circle")
            .data(nodes)
            .join("circle")
            .attr("r", d => getLastChild(d))
            .attr("stroke", d => getStrokeChild(d))
            .attr("stroke-width", d => getStWdChild(d))
            .attr("cursor", "pointer")
            .attr("fill", d => getColor(d))
            .attr("opacity", d => getOpacity(d));


        function getColor(d) {
            return d.color
        }

        let selectedNodes = node.filter(function (d) {
            return d.job_status === "start";
        });

        function getOpacity(d) {
            if (d.CriticalJob !== undefined) {
                if (d.CriticalJob) {
                    return 1
                } else {
                    return 0.3
                }
            }
            return 1
        }

        function getLastChild(d) {
            if(nodes.length < 200) {
                if (d.lastChild) {
                    return 11;
                }
                if (d.CriticalJob) {
                    return 10
                }
                return 9;
            }
            if(nodes.length < 500) {
                if (d.lastChild) {
                    return 9;
                }
                if (d.CriticalJob) {
                    return 8
                }
                return 7;
            }
            if (d.lastChild) {
                return 8;
            }
            if (d.CriticalJob) {
                return 7
            }
            return 5;
        }

        function getStrokeChild(d) {
            if (d.lastChild) {
                return "#252424";
            }
            return "#fff";
        }

        function getStWdChild(d) {
            if (d.lastChild) {
                return 2;
            }
            return 0.5;
        }

        setInterval(function () { blink(selectedNodes); }, 2000)

        function blink(Node) {
            Node.style("opacity", "0.5")
                .transition()
                .duration(1000)
                .style("opacity", 1)
        }

        node.append("title")
            .text(d => d.jobName);

        node.call(d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended));

        node.on("click", function (event, d) {
            history.push({ pathname: "/WorkflowDetailsIndex1", state: { details: { ...d, ...workFlowDetails }, data: d, directvData: location.state.directvData, resUniq, starCric: location.state.starCric, mergeData: location.state.mergeData, date: location.state.date, dateIndexChange: location.state.dateIndexChange, flow: location.state.flow } })
        })

        simulation.on("tick", () => {
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node
                .attr("cx", d => d.x)
                .attr("cy", d => d.y);
        });


        function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
        }

        return svg.node();
    }

    return (
        <>
            <div id="force-grp"></div>
        </>
    )
}

export default ForceDirected;