import { ClickableTile, Column, Row, Modal, DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    Tag,
    TableRow,
    Toggletip,
    ToggletipButton,
    ToggletipContent,
    Tooltip, } from "@carbon/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CardNodeTitle, CardNodeColumn } from '@carbon/charts-react';
import { ShapeNode, CardNode, Edge, TeeMarker, ArrowRightMarker } from "@carbon/charts-react";
import { InProgressError } from "@carbon/react/icons/index";
import KpiModel from "./kpiModel";

const topo = {
    "nodes": [
      {
        "id": "1",
        "row": 1,
        "col": 1,
        "title": "New Product Development",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "2",
        "row": 1,
        "col": 2,
        "title": "Procurement",
        "color": "green",
        "borderColor":"green",
        "fontColor": "white",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "3",
        "row": 1,
        "col": 3,
        "title": "Manufacturing",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
        {
        "id": "4",
        "row": 1,
        "col": 4,
        "title": "Supply Chain",
        "color": "red",
        "borderColor": "red",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "5",
        "row": 2,
        "col": 4,
        "title": "Marketing & Sales",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "6",
        "row": 2,
        "col": 3,
        "title": "Customer",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "7",
        "row": 2,
        "col": 2,
        "title": "Finance",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      },
      {
        "id": "8",
        "row": 2,
        "col": 1,
        "title": "HR",
        "color": "green",
        "borderColor":"green",
        "fontColor": "",
        "details": [{"title": "Avg duration", "value": "1"}, {"title": "Max Time", "value": "4"}, {"title": "Min Time", "value": "10"}, {"title": "Count", "value": "10"}]
      }
    ],
    "edges": [
      {
        "source": "1",
        "target": "2",
        "variant": "arrow"
      },
      {
        "source": "2",
        "target": "3",
        "variant": "arrow"
      },
      {
        "source": "3",
        "target": "4",
        "variant": "arrow"
      },
      {
        "source": "4",
        "target": "5",
        "variant": "arrow"
      },
      {
        "source": "5",
        "target": "6",
        "variant": "arrow"
      },
      {
        "source": "6",
        "target": "7",
        "variant": "arrow"
      },
      {
        "source": "7",
        "target": "8",
        "variant": "arrow"
      }
    ]
  }


const CpgTilesComponent = ({dashboardData}) => {
    const history = useHistory();
    const [showKpiModel, setShowKpiModel] = useState([])

    // const nodeHeight = 100;
    // const nodeWidth = 260;

    const onClickKpis = (e, title) => {
        e.stopPropagation();
        let filt = null
        dashboardData.forEach((data) => {
            const res = data.data.filter((das) => das.displayName === title);
            if(res.length > 0) {
                filt = res;
            }
        })
        setShowKpiModel(filt)
    }

    // const getRowData = (data) => {
    //     return data.value
    // }

    const handleOnClick = (e, title) => {
        e.stopPropagation()
        let filt = null
        dashboardData.forEach((data) => {
            const res = data.data.filter((das) => das.displayName === title);
            if(res.length > 0) {
                filt = res;
            }
        })
        if(title === "Supply Chain") {
            history.push({
                pathname: "/cpgWorkflow",
                state: { data: filt[0] },
            })
        }
    }

    // const getEdges = () => {
    //     let nodeData = getNodeData();
    //     let edgeMapped = topo.edges.map((link) => {
    //       const sourceNode = nodeData.find((node) => node.id === link.source);
    //       const targetNode = nodeData.find((node) => node.id === link.target);
    //       return {
    //         ...link,
    //         source: {
    //           x: sourceNode.x + sourceNode.nodeWidth / 2,
    //           y: sourceNode.y + sourceNode.nodeHeight / 2,
    //         },
    //         target: {
    //           x: targetNode.x + targetNode.nodeWidth / 2,
    //           y: targetNode.y + targetNode.nodeHeight / 2,
    //         },
    //       };
    //     });
    
    //     let edges = edgeMapped.map((edge, i) => (
    //       <Edge
    //         key={`link_${i}`}
    //         source={edge.source}
    //         target={edge.target}
    //         letiant={edge.letiant}
    //         markerEnd={'marker'}
    //       >
    //       </Edge>
    //     ));
    
    //     return edges;
    //   }
    
    //   const getNodeData = () => {
    //     let nodeData = [];
    //     let obj = {};
    //     nodeData = topo.nodes.map((node) => {
    //       obj = node;
    //       obj.id = node.id;
    //       obj.x = ((node.col - 1) * 350);
    //       obj.y = ((node.row - 1) * 150);
    //       obj.icon = "";
    //       obj.title = node.title;
    //       obj.nodeWidth = nodeWidth;
    //       obj.nodeHeight = nodeHeight;
    //       obj.color = node.color;
    //       obj.fontColor = node.fontColor;
    //       obj.details = node.details;
    //       return obj;
    //     })
    //     return nodeData;
    // }

    // const getNodes = () => {
    //     let nodeData = getNodeData();
    //     let nodes = nodeData.map((node, i) => (
    //       <>
    //         <foreignObject
    //           style={{ overflow: 'visible' }}
    //           key={`node_${i}`}
    //           transform={`translate(${node.x}, ${node.y})`}
    //           height={node.nodeHeight}
    //           width={node.nodeWidth}>
    
    //           {node ? (
    //             <>
    //               <CardNode onClick={(e) => handleOnClick(e, node.title)} color={node.borderColor} className="card-node">
    //                 <CardNodeColumn className="title-sec">
    //                   <CardNodeTitle>{node.title}                         
    //                     {node.color === "red" ? 
    //                         <div className="red-icon">
    //                         <Tooltip  align="bottom-start" label="Percentage of supplier orders delivered on time : < 95%">
    //                             <button className="sb-tooltip-trigger" type="button">
    //                                 <InProgressError fill="#da1e28"  />
    //                             </button>
    //                             </Tooltip>
    //                         </div>
    //                         : null}
    //                     </CardNodeTitle>
    //                   <span onClick={(e) => onClickKpis(e, node.title)}>      
    //                     <Tag className="some-class" type={node.color}>
    //                         {'KPI'}
    //                     </Tag></span>
    //                 </CardNodeColumn>
    //                 <CardNodeColumn>
    //                   {/* <ul>
    //                     {node.details.map(desc => <li><span>{desc.title}</span><div>{desc.value}</div></li>)}
    //                   </ul> */}
    //                 </CardNodeColumn>
    //               </CardNode>
    //             </>
    //           ) : null}
    //         </foreignObject>
    //       </>
    //     ));
    
    //     return nodes;
    // }

    //const headers = [{key: "name", header: "Name"}, {key: "value", header: "Sample"}]

    return (
        <>
        <Row>
            <Column lg={16} md={8} sm={4} className="cpg-tiles pd-lft pd-rgt">
            {dashboardData.map((info) => 
                <div className="dash-box">
                    <div className="box-tile">{info.data.map((res, index) => 
                                    <ClickableTile
                                    id={index}
                                    onClick={(e) => handleOnClick(e, res.displayName)}
                                    aria-label={`clickableTile-${index}`}
                                    className={res.borderColor}
                                >
                                    <div>
                                        <h5>{res.displayName}</h5>
                                        <div className="tag-bx">
                                        <Tag className="domain" type="grey">
        {res.title}
      </Tag>
                                        </div>
                                    </div>
                                    <div className="kpi" onClick={(e) => onClickKpis(e,res.displayName)}>
                                    <Tag className="some-class" type={res.borderColor}>
        KPI
      </Tag>
                                    </div>
                                </ClickableTile> 
                    )}
                    </div>
                </div>                        
            )}
            </Column>

            {/* <Column lg={16} md={8} sm={4} className="cpgTopo">
            <svg height="500" width="100%" style={{ overflow: 'visible' }}>
              <defs>
                <ArrowRightMarker id={'marker'} />
              </defs>
              {getEdges()}
              {getNodes()}
            </svg>
          </Column> */}
        </Row>
        {showKpiModel.length > 0 && showKpiModel[0].kpis !== undefined ? <Modal
                open
                size="lg"
                passiveModal
                isFullWidth
                onRequestClose={() => setShowKpiModel(false)}
                modalHeading={showKpiModel[0].displayName}
                modalLabel=""
                className="cpg-model"
            >
                {/* <DataTable rows={showKpiModel[0].kpis} headers={headers}>
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getSelectionProps,
            getTableProps,
            getTableContainerProps
        }) => <TableContainer className="details" {...getTableContainerProps()}>
                <Table {...getTableProps()} aria-label="sample table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => <TableHeader key={i} {...getHeaderProps({
                                header
                            })}>
                                {header.header}
                            </TableHeader>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => <TableRow key={i} {...getRowProps({
                            row
                        })}>
                            {row.cells.map(cell => <TableCell key={cell.id}>{getRowData(cell, row)}</TableCell>)}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>}
    </DataTable> */}
                <KpiModel />
            </Modal> : null}
        </>
    )
}

export default CpgTilesComponent;