import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  FlexGrid,
  InlineLoading,
  Row,
  Search,
  SkeletonPlaceholder,
  SkeletonText,
} from "@carbon/react";

import Chatbot from "./Chatbox";

const TelcoDashboard = () => {
  const [responseRec, setResponseRec] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const ResponseApi = (bool) => {
    setResponseRec(bool);
  };

  const getApiResponse = (bool) => {
    setCallApi(bool);
  };

  return (
    <FlexGrid>
      <Row>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            {/* <BreadcrumbItem href="#/operations">Operations</BreadcrumbItem> */}
            <BreadcrumbItem isCurrentPage>NOC AI Assistant</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        {/* <Column lg={12} md={8} sm={4}>
          <Dashboard gotResponse={ResponseApi} callApi={callApi} inputValue={inputValue} setInputValue={setInputValue}/>
        </Column> */}
        <Column lg={16} md={8} sm={4}>
          <Chatbot
            responseRec={responseRec}
            gotResponse={ResponseApi}
            callApi={callApi}
            getApiResponse={getApiResponse}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </Column>
      </Row>
    </FlexGrid>
  );
};

export default TelcoDashboard;
