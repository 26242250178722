import {
  Accordion,
  AccordionItem,
  Column,
  ErrorBoundary,
  Row,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import ChartContainer from "Carbon-Charts/ChartContainer";
import InlineLoader from "Carbon-Components/InlineLoader";
import { getAnalyzedDataDetails } from "Services/ServerApi";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DataTableForCharts from "./DataTableForCharts";
import Section from "./Section";
import "./telco.scss";

const Dashboard = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const { keycloak } = useKeycloak();

  // useEffect(() => {
  //   getAnalyzedData();
  // }, [props.responseData2.hasSenderKey]);

  // const getAnalyzedData = async () => {
  //   setResponseData({});
  //   if (props.responseData2.hasSenderKey === true) {
  //     setIsLoading(true);
  //     const response = await getAnalyzedDataDetails(
  //       keycloak,
  //       uuidv4(),
  //       props.responseData2.requestBody
  //     );
  //     try {
  //       setResponseData(response.data);
  //       setErrorMsg("");
  //       setIsLoading(false);
  //     } catch (error) {
  //       setErrorMsg("Something went wrong, Please try again...");
  //       setResponseData({});
  //       setIsLoading(false);
  //     }
  //   }
  // };

  // const getCharts = () => {
  //   if (props.isLoading) {
  //     return <InlineLoader description="Please wait..." status="active" />;
  //   }

  //   if (props.errorMsg) {
  //     return <h3>{props.errorMsg}</h3>;
  //   }

  //   if (Object.keys(props.responseData).length > 0) {
  //     if (
  //       props.responseData.charts.length === 0 &&
  //       props.responseData.tabularData.length === 0 &&
  //       props.responseData.countData.length === 0 &&
  //       props.responseData.analyzedData.length === 0 &&
  //       props.responseData.llmOutput.length === 0 &&
  //       Object.keys(props.responseData.kbData).length === 0
  //     ) {
  //       return (
  //         // <h3>
  //         //   Apologies, I couldn't find a suitable response. Please try
  //         //   rephrasing your question or ask something else.
  //         // </h3>
  //         <h3>No data available</h3>
  //       );
  //     }

  //     if (props.responseData.charts.length > 0) {
  //       return (
  //         <Accordion>
  //           <AccordionItem id="charts" title={"Dashboards"}>
  //             <Row>
  //               {props.responseData.charts.map((res) => {
  //                 return (
  //                   <Column
  //                     lg={res["col-size"]}
  //                     md={4}
  //                     sm={4}
  //                     key={uuidv4()}
  //                     className="pl-05 pr-05"
  //                   >
  //                     <ErrorBoundary fallback={"Data is unavailable"}>
  //                       {res.data.chartData.length === 0 ? (
  //                         <ChartContainer data={res} />
  //                       ) : (
  //                         <>
  //                           <ChartContainer data={res} />
  //                         </>
  //                       )}
  //                     </ErrorBoundary>
  //                   </Column>
  //                 );
  //               })}
  //             </Row>
  //           </AccordionItem>
  //         </Accordion>
  //       );
  //     } else return null;
  //   }
  // };

  // const getAnalyzedDashboard = () => {
  //   if (
  //     Object.keys(props.responseData2).length > 0 &&
  //     props.responseData2.hasSenderKey === true
  //   ) {
  //     if (isLoading) {
  //       return (
  //         <InlineLoader
  //           description="Loading Analyzed data..."
  //           status="active"
  //         />
  //       );
  //     }

  //     if (errorMsg) {
  //       return <h3>{props.errorMsg}</h3>;
  //     }
  //     if (Object.keys(responseData).length > 0) {
  //       return responseData.finalRes.map((section) => (
  //         <Section
  //           key={section.header}
  //           header={section.header}
  //           data={section.data}
  //           useHeadingTags={true}
  //         />
  //       ));
  //     }

  //     return null;
  //   }
  //   return null;
  // };

  // const getCountDetails = () => {
  //   let words = props.inputValue.split(" ");

  //   const lastWord = words[words.length - 1];
  //   if (lastWord.endsWith("?")) {
  //     words[words.length - 1] = lastWord.slice(0, -1);
  //   }

  //   const filteredWords = words
  //     .slice(2)
  //     .filter(
  //       (word, index) => !word.includes("?") || index === words.length - 2
  //     );

  //   //let question = filteredWords.join(" ");
  //   if (
  //     Object.keys(props.responseData).length > 0 &&
  //     props.responseData?.countData
  //   ) {
  //     if (props.responseData.countData.length > 0) {
  //       return (
  //         <div className="count-details-main">
  //           {props.responseData.countData.map((countData) => {
  //             return (
  //               <>
  //                 <div className="count-details">
  //                   {/* <h5> {countData.title}</h5> */}
  //                   {/* <h5>{question} </h5> */}
  //                   {countData.count !== 0 && <h6>{countData.count}</h6>}
  //                 </div>
  //               </>
  //             );
  //           })}
  //         </div>
  //       );
  //     }
  //     return null;
  //   }
  //   return null;
  // };

  // const getAnalyzeData = () => {
  //   if (
  //     Object.keys(props.responseData).length > 0 &&
  //     props.responseData?.analyzedData
  //   ) {
  //     if (props.responseData.analyzedData.length > 0) {
  //       return (
  //         <div className="analyze-data-main">
  //           {props.responseData.analyzedData.map((countData) => {
  //             if (Object.keys(countData).length > 0) {
  //               return (
  //                 <>
  //                   <div className="analyze-details">
  //                     <h5>{countData.title} </h5>
  //                     <h6>{countData.value}</h6>
  //                   </div>
  //                 </>
  //               );
  //             }
  //             return null;
  //           })}
  //         </div>
  //       );
  //     }
  //   }
  //   return null;
  // };

  // const getAnalyzedDetails = () => {
  //   if (
  //     Object.keys(props.responseData).length > 0 &&
  //     props.responseData?.analyzedData &&
  //     props.responseData.analyzedData.length > 0
  //   ) {
  //     return props.responseData.analyzedData.map((section) => (
  //       <Section
  //         key={section.header}
  //         header={section.header}
  //         data={section.data}
  //         useHeadingTags={true}
  //       />
  //     ));
  //   }
  //   return null;
  // };

  // const getTabularData = () => {
  //   if (Object.keys(props.responseData).length > 0) {
  //     if (props.responseData.tabularData.length > 0) {
  //       return (
  //         <Accordion>
  //           {props.responseData.tabularData.map((tabular) => {
  //             return (
  //               <AccordionItem
  //                 id="tabular_view"
  //                 key={uuidv4()}
  //                 title={tabular.data.title}
  //                 className="telco-accordion"
  //                 open={true}
  //               >
  //                 {tabular.data.title === "RCA" &&
  //                   tabular.data.chartData.map((res) => {
  //                     if (
  //                       Object.keys(res).includes(
  //                         "Incident Description Market"
  //                       ) &&
  //                       Object.keys(res).length === 1
  //                     )
  //                       return (
  //                         <div
  //                           style={{
  //                             backgroundColor: "white",
  //                             paddingBottom: "10px",
  //                           }}
  //                         >
  //                           <h5
  //                             style={{
  //                               display: "list-item",
  //                               listStyle: "disc",
  //                             }}
  //                           >
  //                             {" "}
  //                             {res["Incident Description Market"]}
  //                           </h5>
  //                         </div>
  //                       );
  //                     return (
  //                       <>
  //                         {Object.entries(res).map(([key, value]) => {
  //                           return (
  //                             <div className="rca-details" key={value}>
  //                               <h5> {key}</h5>
  //                               <h6>{value}</h6>
  //                             </div>
  //                           );
  //                         })}
  //                         <hr style={{ border: "none" }} />
  //                       </>
  //                     );
  //                   })}
  //                 {tabular.data.title !== "RCA" && tabular.data && (
  //                   <ErrorBoundary fallback={"Data is unavailable"}>
  //                     {tabular.data.chartData.length === 0 ? (
  //                       <DataTableForCharts data={tabular.data} />
  //                     ) : (
  //                       <>
  //                         <DataTableForCharts data={tabular.data} />
  //                       </>
  //                     )}
  //                   </ErrorBoundary>
  //                 )}
  //               </AccordionItem>
  //             );
  //           })}
  //         </Accordion>
  //       );
  //     }
  //     return null;
  //   }
  // };

  // const getLLMmsg = () => {
  //   if (Object.keys(props.responseData).length > 0) {
  //     if (
  //       props.responseData?.llmOutput &&
  //       props.responseData?.llmOutput.length > 0
  //     ) {
  //       return (
  //         <>
  //           <h3>{props.responseData.llmOutput.split("\n").join(" ").trim()}</h3>
  //         </>
  //       );
  //     }
  //     return null;
  //   }
  //   return null;
  // };

  // const getKBData = () => {
  //   if (
  //     Object.keys(props.responseData).length > 0 &&
  //     props.responseData?.kbData &&
  //     Object.keys(props.responseData.kbData).length > 0
  //   ) {
  //     const byteCharacters = atob(props.responseData.kbDetails);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: "application/pdf" });

  //     const url = URL.createObjectURL(blob);

  //     return (
  //       <>
  //         <iframe
  //           src={url}
  //           title="PDF Viewer"
  //           style={{ width: "100%", padding: "10px", height: "100%" }}
  //           className="pdf-reader"
  //         />
  //         {/* <h5 style={{ padding: "5px 0 5px 10px" }}>KB Data</h5>
  //         <Paragraph
  //           text={props.responseData.kbData.description[0]}
  //           size={500}
  //         /> */}
  //         <hr />
  //       </>
  //     );
  //   }
  //   return null;
  // };

  const style = {
    padding: "20px",
    fontSize: "1rem",
    display: "inline-flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const getLoader = () => {
    if (props.isLoading) {
      return <InlineLoader description="Please wait..." status="active" />;
    }

    if (props.errorMsg) {
      return <h3 style={style}>{props.errorMsg}</h3>;
    }

    return null;
  };

  return (
    <>
      <Row
        // className="chat-dashboard"
        // style={{ width: props.showChatHis ? "80%" : "100%" }}
        style={{ width: "100%" }}
      >
        {getLoader()}
        {/* {getCountDetails()}
        {getCharts()}
        {getTabularData()}
        {getAnalyzedDetails()}
        {getLLMmsg()}
        {getKBData()}
        {getAnalyzedDashboard()} */}
      </Row>
    </>
  );
};

export default Dashboard;
