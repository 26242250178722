import PowerBi from "Components/PowerBI/PowerBI";
import React from "react";

function QueryAnalytics() {
  return (
    <>
      <PowerBi reportId="4d6e2caa-3b94-4f53-af38-d37f29e10414" />
    </>
  );
}

export default QueryAnalytics;
