import React from "react";
import { Tile, CodeSnippet } from "@carbon/react";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";

const ErrorLog = (props) => {

    const canCloseDetailsPopup = () => {
        props.onCloseModal()    
    }

    return (
        <PassiveModal size="lg" className="modal" heading="Logs" canCloseDetailsPopup={canCloseDetailsPopup}>
            <Tile>
                <CodeSnippet hideCopyButton={true} minCollapsedNumberOfRows={20} minExpandedNumberOfRows={20} maxCollapsedNumberOfRows={20} maxExpandedNumberOfRows={20} aria-label="Copy" copyButtonDescription="Copy" className="copy-clip" type="multi" feedback="Copied" copyText="Copy">
                    {props.logs}
                </CodeSnippet>
            </Tile>
        </PassiveModal>
    )
}

export default ErrorLog;