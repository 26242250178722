import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./en.json')
    },
    fr: {
      translations: require('./fr.json')
    },
    jp: {
      translations: require('./jp.json')
    },
    es: {
      translations: require('./es.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18next.languages = ['en', 'fr', 'jp', 'es'];

export default i18next;