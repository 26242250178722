import {
  CardNode,
  CardNodeColumn,
  CardNodeTitle,
  Edge,
  ArrowRightMarker,
} from "@carbon/charts-react";
import { buildBezierPathString, buildElbowPathString } from "@carbon/charts";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getClientNodeData } from "./ServiceMapFunction";
import {
  getColorClass,
  getEdgeEnd,
  getEdgeStart,
  getIconSrc,
} from "./TopologyUtility";
// import "./nbp.scss";

const ShapeNodeSize = 144;

let clientNodes = [];
let highestX = -1;
let highestY = -1;

const TopologyGraphAligned = (props) => {
  const [nodeData, setNodeData] = useState();
  const [edgeData, setEdgeData] = useState();
  const history = useHistory();

  useEffect(() => {
    clientNodes = [];
    highestX = -1;
    highestY = -1;
    const topoId = document.getElementById("topo");
    if (topoId !== null) {
      topoId.remove();
    }
    if (props.data.Services) {
      let serviceMapFunctionInfo = getClientNodeData(props.data.Services);
      updateClientNodeData(serviceMapFunctionInfo);
    }
  }, [props.data.Services]);

  const gotoTraceSummary = useCallback(
    () =>
      history.push({
        pathname: "/almDashboard",
        state: { ProjectName: props.topologyTitle },
      }),
    [history]
  );

  const getNodesWithDepth = (nodes, depth) => {
    let nodesWithDepth = [];
    nodes.forEach((nodeObj) => {
      if (nodeObj.depth === depth) {
        nodesWithDepth.push(nodeObj.nodeData);
      }
    });
    return nodesWithDepth;
  };

  const getClientUserNode = (clientNodeData, largestHeight) => {
    let edge = {};

    let node = {};

    node = { icon: "user" };
    node.id = (1000).toString();
    node.x = 0;
    node.y = (largestHeight * 100 - 80) / 2;
    node.name = "Client";
    node.title = "Client";
    node.Edges = [];

    clientNodeData.forEach((clientNodeObj, index) => {
      edge = {};
      edge.ReferenceId = clientNodeObj.ReferenceId;
      node.Edges.push(edge);
    });

    return node;
  };

  const updateNode = (x, y, serviceObj) => {
    let node = {};

    node = { icon: serviceObj.icon };
    node.depth = serviceObj.depth;

    node.id = serviceObj.ReferenceId.toString();
    node.x = x;
    node.y = y;
    node.name = serviceObj.Name;
    node.type = serviceObj.Type;
    node.title = serviceObj.Name;
    node.ARN = serviceObj.ARN ? serviceObj.ARN : "";
    node.Edges = serviceObj.Edges ? serviceObj.Edges : [];

    node.fault = serviceObj.hasFault;
    node.error = serviceObj.hasError;
    node.hasAnomaly = serviceObj.hasAnomaly;

    return node;
  };

  const updateHighestX = (x) => {
    highestX = x > highestX ? x : highestX;
  };

  const updateHighestY = (y) => {
    highestY = y > highestY ? y : highestY;
  };

  const updateClientNodeData = (clientNodeData) => {
    let nodes = [];

    //Client nodes
    nodes.push(
      getClientUserNode(
        getNodesWithDepth(clientNodeData.nodesRearranged, 0),
        clientNodeData.largestHeight
      )
    );

    let node = {};
    let x = 0;
    let y = 0;
    let currentDepth = -1;
    clientNodeData.nodesRearranged.forEach((clientNodeObj, index) => {
      let height = clientNodeData.nodesDepthMap.get(clientNodeObj.depth).length;

      if (currentDepth === clientNodeObj.depth) {
        y += 120;
        updateHighestY(y);
      } else {
        y = (clientNodeData.largestHeight * 100 - height * 100) / 2;
      }

      currentDepth = clientNodeObj.depth;
      x = 200 + 200 * clientNodeObj.depth;
      updateHighestX(x);
      node = updateNode(x, y, clientNodeObj.nodeData);
      clientNodes.push(node);
    });
    nodes = nodes.concat(clientNodes);

    setNodeData(nodes);
    getEdgeData(nodes);
  };

  const getEdgeData = (nodes) => {
    let edges = [];
    let edge = {};
    let source = "";
    let target = "";

    nodes.forEach((nodeObj) => {
      if (nodeObj.Edges && nodeObj.Edges.length > 0) {
        nodeObj.Edges.forEach((edgeNodeObj) => {
          edge = {};
          source = nodeObj.id.toString();
          target = edgeNodeObj.ReferenceId.toString();
          edge.source = source;
          edge.target = target;
          edge.path = (source, target) => buildElbowPathString(source, target);
          edges.push(edge);
        });
      }
    });
    setEdgeData(edges);
  };

  const getTitleElement = (node, i) => {
    return (
      <span className={"node-title" + i} title={node.name}>
        {node.title}
      </span>
    );
  };

  const onClickNode = (node) => {
    sessionStorage.setItem("currentNode", JSON.stringify(node));
    if (node.error || node.fault || node.hasAnomaly) {
      sessionStorage.setItem("componentARN", node.ARN);
      sessionStorage.setItem("startTime", props.data.StartTime);
      sessionStorage.setItem("endTime", props.data.EndTime);

      gotoTraceSummary();
    }
  };

  const getNodeColor = (icon) => {
    if (icon.includes("client")) return "";
    if (icon.includes("user")) return "";

    if (icon.includes("ApplicationMobile")) return "node--color__lightblue1"; //#78a9ff
    if (icon.includes("ApplicationWeb")) return "node--color__darkblue1"; //#0f62fe

    if (icon.includes("Plan")) return "node--color__darkblue3"; //#0050e6
    if (icon.includes("Catalog")) return "node--color__pink"; //#f9d8d9
    if (icon.includes("Currency")) return "node--color__lightblue2"; //#b0eeef
    if (icon.includes("Concept")) return "node--color__darkblue4"; //#7b33db
    if (icon.includes("MediaLibrary")) return "node--color__lightblue1"; //#78a9ff

    if (icon.includes("Product")) return "node--color__pink"; //#f9d8d9
    if (icon.includes("AddComment")) return "node--color__orange"; //#f1c21b
    if (icon.includes("ChooseItem")) return "node--color__lightblue1"; //#78a9ff

    if (icon.includes("SelectWindow")) return "node--color__brown"; //#24a148
    if (icon.includes("ShoppingCartPlus")) return "node--color__orange"; //#f1c21b
    if (icon.includes("ShoppingCartMinus")) return "node--color__darkgreen"; //#525252
    if (icon.includes("Purchase")) return "node--color__darkbrown"; //#750e13
    if (icon.includes("InventoryManagement")) return "node--color__darkblue5"; //#002d9c

    if (icon.includes("PaymentGateway")) return "node--color__darkblue2"; //#8a3ffc
    if (icon.includes("UPI")) return "node--color__darkblue2"; //#8a3ffc
    if (icon.includes("Merchant")) return "node--color__lightblue2"; //#b0eeef
    if (icon.includes("IbmBluepay")) return "node--color__darkblue1"; //#0f62fe
    if (icon.includes("Finance")) return "node--color__darkblue2"; //#8a3ffc

    if (icon.includes("Upload")) return "node--color__darkblue4"; //#7b33db
    if (icon.includes("InProgress")) return "node--color__orange"; //#f1c21b
    if (icon.includes("Assembly")) return "node--color__lightbrown"; //#e65b5c
    if (icon.includes("Delivery")) return "node--color__darkblue6"; //#0043ce

    if (icon.includes("Db2Database")) return "node--color__lightblue3"; //#b797f7
  };

  const getNode = (node, i) => {
    console.log("node", node);

    return (
      <span className="node__nocursor">
        <CardNode
          size={ShapeNodeSize}
          onClick={() => {
            onClickNode(node);
          }}
          className={`${getNodeColor(node.icon)} ${
            node.error || node.fault
              ? getColorClass(node)
              : node.hasAnomaly
              ? getColorClass(node)
              : ""
          }`}
        >
          <CardNodeColumn>
            <img
              src={getIconSrc(node.icon)}
              alt="Not Available"
              className="choose-task__icon"
              style={{ height: "24px" }}
            />
          </CardNodeColumn>
          <CardNodeColumn>
            <CardNodeTitle>{getTitleElement(node, i)}</CardNodeTitle>
          </CardNodeColumn>
        </CardNode>
      </span>
    );
  };

  const nodeElements = () => {
    return nodeData.map((node, i) => {
      return (
        <foreignObject
          key={`child${i}`}
          className="overflow-visible"
          transform={`translate(${node.x}, ${node.y})`}
          width="135"
          height="65"
          style={{ overflow: "visible !important" }}
        >
          {getNode(node, i)}
        </foreignObject>
      );
    });
  };

  const getEdge = (edge, i) => {
    return (
      <Edge
        key={`link_${i}`}
        path={buildBezierPathString(
          getEdgeStart(nodeData, edge.source),
          getEdgeEnd(nodeData, edge.target),
          getEdgeStart(nodeData, edge.source).x,
          getEdgeStart(nodeData, edge.source).y,
          getEdgeEnd(nodeData, edge.target).x -
            (getEdgeEnd(nodeData, edge.target).y -
              getEdgeStart(nodeData, edge.source).y) /
              2,
          getEdgeEnd(nodeData, edge.target).y -
            (getEdgeEnd(nodeData, edge.target).y -
              getEdgeStart(nodeData, edge.source).y) /
              2
        )}
        markerEnd={"marker"}
      />
    );
  };

  const edgeElements = () => {
    return edgeData.map((edge, i) => {
      return getEdge(edge, i);
    });
  };

  const DrawGraph = () => (
    <div className="topology-graph-new icpsa">
      <svg
        id="topo"
        height={`600px`}
        width={`1400px`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <ArrowRightMarker id={"marker"} />
        </defs>
        {edgeElements()}
        {nodeElements()}
      </svg>
    </div>
  );

  return <div>{nodeData && edgeData ? <DrawGraph /> : null}</div>;
};

export default TopologyGraphAligned;
