import React from "react";
import DataTable from "Carbon-Components/DataTable";
import { headers, rows } from "./staticData/recomm";

const tenant = sessionStorage.getItem("tenant");

const Recommendations = () => {
  const getTableHeader = (header) => header;
  const getRowCellData = (id, data, row) => {
    return data;
  };
  return (
    <div
      className={tenant === "Energy_Australia" ? "recommedations--tabel" : ""}
    >
      <DataTable
        rowData={rows}
        headerData={headers}
        getRowCellData={getRowCellData}
        toolbarNeeded={false}
        actionsNeeded={false}
        getTableHeader={getTableHeader}
      />
    </div>
  );
};

export default Recommendations;
