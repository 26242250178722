function NotFound() {

  const goHome = () => {
    window.history.replaceState(null, "", `${window.location.protocol}//${window.location.host}/#/tenant/${window.location.pathname.split("/")[3]}/`);
  }

  return (
    <>
      <h1>404!</h1>
      <h3>Not Found</h3>
      <h5>Go back to <a href="" onClick={() => { goHome() }}>Home</a>.</h5>
    </>
  );
}

export default NotFound;
