/** @format */

import {
    GaugeChart,
    SimpleBarChart,
} from "@carbon/charts-react";
import {
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    ErrorBoundary,
    Grid,
    InlineLoading,
    Layer,
    SkeletonPlaceholder,
    Tile
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import TextBox from "Carbon-Charts/TextBox";
import CarbonDataTable from "Carbon-Components/DataTable";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getDeploymentInsightsReportData } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { formatDate, updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";

let buildStatusGuageChartFilter = null;
let phaseStatusGuageChartFilter = null;
let buildStatusBarChartFilter = null;

const DeploymentInsightsReport = () => {

    const buildStatusGuageChartRef = useRef(null);
    const phaseStatusGuageChartRef = useRef(null);
    const buildStatusBarChartRef = useRef(null);
    const context = useContext(Context)

    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [APIData, setAPIData] = useState("");
    const [errMessage, setErrorMessage] = useState("");

    const [startDate, setStartDate] = useState("05-05-2022 00:00")
    const [endDate, setEndDate] = useState("10-11-2022 00:00")
    const [apiLoading, setApiLoading] = useState(false);
    const [APIDataCall, setAPIDataCall] = useState(false);

    const [buildStatusGuageChartLoading, setBuildStatusGuageChartLoading] = useState(false);
    const [phaseStatusGuageChartLoading, setPhaseStatusGuageChartLoading] = useState(false);
    const [buildStatusBarChartLoading, setBuildStatusBarChartLoading] = useState(false);

    const { keycloak } = useKeycloak();

    useEffect(() => {
        getAPIData(startDate, endDate, null);
    }, []);

    useEffect(() => {
        setEventListerners();
    }, [buildStatusGuageChartRef, phaseStatusGuageChartRef, buildStatusBarChartRef])

    const setEventListerners = () => {
        if (buildStatusGuageChartRef.current) {
            buildStatusGuageChartRef.current.chart.services.events.addEventListener(
                "gauge-arc-click",
                buildStatusGuageChartOnClick
            )
        }
        if (phaseStatusGuageChartRef.current) {
            phaseStatusGuageChartRef.current.chart.services.events.addEventListener(
                "gauge-arc-click",
                phaseStatusGuageChartOnClick
            )
        }
        if (buildStatusBarChartRef.current) {
            buildStatusBarChartRef.current.chart.services.events.addEventListener(
                "bar-click",
                buildStatusBarChartOnClick
            )
        }
    }

    const buildStatusGuageChartOnClick = ({ detail }) => {
        console.log("buildStatusGuageChartOnClick", detail);
    }

    const phaseStatusGuageChartOnClick = ({ detail }) => {
        console.log("phaseStatusGuageChartOnClick", detail);
    }

    const buildStatusBarChartOnClick = ({ detail }) => {
        if (buildStatusBarChartFilter && buildStatusBarChartFilter === detail.datum.group) {
            setBuildStatusBarChartLoading(true);
            getAPIData(startDate, endDate, null);
        } else {
            buildStatusBarChartFilter = detail.datum.group;
            setBuildStatusBarChartLoading(true);
            getAPIData(startDate, endDate, detail.datum.group, "BuildStatus");
        }
    }

    const getRowCellData = (id, data, row) => {
        if (id.includes("startTime") || id.includes("endTime")) {
            return String(new Date(data).toLocaleString());
        }
        return data;
    };
    const getTableHeader = (header) => {
        return header;
    };

    const getChartLoader = () => {
        return <Layer className="chart-loader__layer">
            <InlineLoading
                status="active"
                iconDescription="Loading"
                description="Loading data..."
            />
        </Layer>
    }

    const getAPIData = async (sDate, eDate, filter, filterName) => {
        if (filter === null) {
            buildStatusGuageChartFilter = null;
            phaseStatusGuageChartFilter = null;
            buildStatusBarChartFilter = null;
        }
        const response = await getDeploymentInsightsReportData(keycloak.token, sDate, eDate, filter, filterName);
        AddUserActivity(keycloak, context, "FEATURE", "Deployment Insights", response);

        setIsAPIDataLoading(false);
        setApiLoading(false);

        setBuildStatusGuageChartLoading(false);
        setPhaseStatusGuageChartLoading(false);
        setBuildStatusBarChartLoading(false);

        setErrorMessage(updateErrorMessage(response));
        setAPIData(updateAPIData(response));
        if (APIDataCall === false) {
            setEventListerners();
            setAPIDataCall(true);
        }
    };

    const onStartDateChange = (e) => {
        setStartDate(formatDate(new Date(e[0]).toDateString()))
        if (e.length > 1) {
            setEndDate(formatDate(new Date(e[1]).toDateString()))
        }
    }

    const updateAPI = async () => {
        setApiLoading(true);
        await getAPIData(startDate, endDate, null);
    }

    const getHeadingAndDateFilter = () => {
        return <>
            <Column lg={10}>
                <h3
                >{APIData.data.label}</h3>
            </Column>
            <Column lg={4}>
                <ErrorBoundary fallback={"Data is unavailable"}>
                    <DatePicker dateFormat="Y-m-d" datePickerType="range" onChange={(e) => onStartDateChange(e)}
                        className="date-picker--margin"
                    >
                        <DatePickerInput
                            id="date-picker-range-start"
                            placeholder="yyyy-mm-dd"
                            labelText="Start Date"
                            defaultValue="2022-05-05"
                            type="text"
                        />
                        <DatePickerInput
                            id="date-picker-range-end"
                            placeholder="yyyy-mm-dd"
                            labelText="End Date"
                            defaultValue="2022-10-11"
                            type="text"
                        />
                    </DatePicker>
                </ErrorBoundary>
            </Column>
            <Column lg={2}>
                <span>
                    {
                        apiLoading ?
                            <InlineLoading
                                status="active"
                                iconDescription="Loading"
                                description="Loading data..."
                                className="report-update__button"
                            /> :
                            <Button
                                kind="primary"
                                onClick={updateAPI}
                                size='md'
                                iconDescription="Icon Description"
                                className="report-update__button"
                            >
                                Update
                            </Button>
                    }
                </span>
            </Column>
        </>
    }

    const createChartExplicit = () => {
        return (<>
            {getHeadingAndDateFilter()}
            {
                (APIData.data.data[0].data.value > 0) && (
                    (APIData.data.data[2].data.chartData && APIData.data.data[2].data.chartData.length > 0) ||
                    (APIData.data.data[3].data.chartData && APIData.data.data[3].data.chartData.length > 0) ||
                    (APIData.data.data[4].data.chartData && APIData.data.data[4].data.chartData.length > 0) ||
                    (APIData.data.data[5].data.chartData && APIData.data.data[5].data.chartData.length > 0) ||
                    (APIData.data.data[6].data.chartData && APIData.data.data[6].data.chartData.length > 0)) ?
                    <>
                        <Column lg={8} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    <TextBox data={APIData.data.data[0].data}></TextBox>
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={8} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    <TextBox data={APIData.data.data[1].data}></TextBox>
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={4} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[2].data.chartData && APIData.data.data[2].data.chartData.length > 0 ?
                                        <>
                                            {buildStatusGuageChartLoading ? getChartLoader() : ""}
                                            <GaugeChart
                                                ref={buildStatusGuageChartRef}
                                                data={APIData.data.data[2].data.chartData}
                                                options={APIData.data.data[2].data.chartOptions}>
                                            </GaugeChart >
                                        </>
                                        : "Data is unavailable"
                                    }
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={4} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[3].data.chartData && APIData.data.data[3].data.chartData.length > 0 ?
                                        <>
                                            {phaseStatusGuageChartLoading ? getChartLoader() : ""}
                                            <GaugeChart
                                                ref={phaseStatusGuageChartRef}
                                                data={APIData.data.data[3].data.chartData}
                                                options={APIData.data.data[3].data.chartOptions}>
                                            </GaugeChart >
                                        </>
                                        : "Data is unavailable"
                                    }
                                </ErrorBoundary>
                            </Tile>
                        </Column>

                        <Column lg={4} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[4].data.chartData && APIData.data.data[4].data.chartData.length > 0 ?
                                        <GaugeChart
                                            data={APIData.data.data[4].data.chartData}
                                            options={APIData.data.data[4].data.chartOptions}>
                                        </GaugeChart >
                                        : "Data is unavailable"
                                    }
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={4} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[5].data.chartData && APIData.data.data[5].data.chartData.length > 0 ?
                                        <GaugeChart
                                            data={APIData.data.data[5].data.chartData}
                                            options={APIData.data.data[5].data.chartOptions}>
                                        </GaugeChart >
                                        : "Data is unavailable"
                                    }
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={16} md={8} sm={4}>
                            < Tile className="chart-container" >
                                <ErrorBoundary fallback={"Data is unavailable"}>
                                    {APIData.data.data[6].data.chartData && APIData.data.data[6].data.chartData.length > 0 ?
                                        <>
                                            {buildStatusBarChartLoading ? getChartLoader() : ""}
                                            <SimpleBarChart
                                                ref={buildStatusBarChartRef}
                                                data={APIData.data.data[6].data.chartData}
                                                options={APIData.data.data[6].data.chartOptions}
                                            ></SimpleBarChart>
                                        </>
                                        : "Data is unavailable"
                                    }
                                </ErrorBoundary>
                            </Tile>
                        </Column>
                        <Column lg={16} md={8} sm={4}>
                            <ErrorBoundary fallback={<ErrorWhileFetching />}>
                                <div className="scrollable-table">
                                    <CarbonDataTable
                                        rowData={APIData.data.data[7].data.rowData}
                                        headerData={APIData.data.data[7].data.headerData}
                                        title={APIData.data.data[7].label}
                                        getRowCellData={getRowCellData}
                                        getTableHeader={getTableHeader}
                                    />
                                </div>
                            </ErrorBoundary>
                        </Column>
                    </> : <Column lg={16} md={8} sm={4}>
                        <DataUnAvailable />
                    </Column>
            }
        </>
        );
    };

    const loadSkeleton = () => {
        return isAPIDataLoading ? (
            <div className="skeletonPlaceholder--it-health">
                <SkeletonPlaceholder />
            </div>
        ) : <ErrorWhileFetching errMessage={errMessage} />
    }

    return (
        <div className="container-multichart">
            {APIData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
        </div>
    );
};

export default DeploymentInsightsReport;
