import { GroupedBarChart, LineChart } from "@carbon/charts-react";
import { Button, Column, DatePicker, DatePickerInput, ErrorBoundary, Grid, InlineLoading, SkeletonPlaceholder, Tile } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./../css/_chart.scss";

import DataUnAvailable from "Common-Modules/DataUnavailable";
import Context from "Context/Context";
import awsToolIcon from "../../../Assets/carbonIcons/awscloudwatch.svg";
import datadogToolIcon from "../../../Assets/carbonIcons/datadog.svg";
import instanaToolIcon from "../../../Assets/carbonIcons/instana.png";
import { getAppPlatformInsightsDetails, getPlatformInsightsDetails } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { formatDateYear, updateAPIData, updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import PlatformInsightsTilesComponent from "./PlatformInsightsTilesComponent";

const PlatformInsightsReport = (props) => {
  const cpuCircleChartRef = useRef(null);
  const iowaitCircleChartRef = useRef(null);
  const systemloadCircleChartRef = useRef(null);

  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [monitoringToolNames, setMonitoringToolNames] = useState([]);

  const [errMessage, setErrorMessage] = useState("");

  const [hover, setHover] = useState(false);
  const [APIDataCall, setAPIDataCall] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    let startDateDefault = null;
    let endDateDefault = null;

    if (sessionStorage.getItem("poStartDate") && sessionStorage.getItem("poEndDate")) {
      startDateDefault = sessionStorage.getItem("poStartDate");
      endDateDefault = sessionStorage.getItem("poEndDate");
    } else {
      // startDateDefault = new Date(new Date().getTime() - 86400000).toISOString();
      // endDateDefault = new Date(new Date().getTime()).toISOString();
      // startDateDefault = new Date(new Date().getTime() - 7889400000).toISOString(); //3 months
      // endDateDefault = new Date(new Date().getTime()).toISOString();

      const today = new Date();
      const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

      // For Yesterday
      // const yesterday = new Date(today);
      // yesterday.setDate(today.getDate() - 1);
      // const beginningOfYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0, 0);

      // For March 1st
      const date = new Date(2024, 2, 1);
      const beginningOfYesterday = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

      startDateDefault = beginningOfYesterday.toISOString();
      endDateDefault = endOfToday.toISOString();
      sessionStorage.setItem("poStartDate", startDateDefault)
      sessionStorage.setItem("poEndDate", endDateDefault)
    }
    setStartDate(formatDateYear(new Date(startDateDefault).toDateString()));
    setEndDate(formatDateYear(new Date(endDateDefault).toDateString()));
    getAPIData(startDateDefault, endDateDefault);
  }, []);

  useEffect(() => {
    setEventListerners();
  }, [
    cpuCircleChartRef,
    iowaitCircleChartRef,
    systemloadCircleChartRef,
  ]);

  const setEventListerners = () => {
    if (cpuCircleChartRef.current) {
      cpuCircleChartRef.current.chart.services.events.addEventListener(
        "circle-leaf-click",
        handleOnClick
      );
    }
    if (iowaitCircleChartRef.current) {
      iowaitCircleChartRef.current.chart.services.events.addEventListener(
        "circle-leaf-click",
        handleOnClick
      );
    }
    if (systemloadCircleChartRef.current) {
      systemloadCircleChartRef.current.chart.services.events.addEventListener(
        "circle-leaf-click",
        handleOnClick
      );
    }
  };

  // const getHostObj = (hostName) => {
  //   APIData?.data.data.platformTiles.forEach((platformTile) => {
  //     if (platformTile.host_name === hostName) {
  //       return platformTile;
  //     }
  //   })
  // }

  const handleOnClick = ({ detail }) => {

    // "platformAlerts",
    // data,
    // data.host_name,
    // data.hostIds

    // if (route) {
    //   sessionStorage.setItem("platformHost", JSON.stringify(data));
    //   sessionStorage.setItem("host-title", title);
    //   sessionStorage.setItem("hostIds", JSON.stringify(hostIds));
    //   history.push({ pathname: route, state: { title: title } });
    // }

    // if (detail.datum.data.name) {
    //   platformObj = getHostObj(detail.datum.data.name)
    // }

  };

  const updateAPI = async () => {
    setApiLoading(true);
    await getAPIData(sessionStorage.getItem("poStartDate"), sessionStorage.getItem("poEndDate"));
  };

  const getAPIData = async (sDate, eDate) => {
    const response = props.applicationId ? await getAppPlatformInsightsDetails(
      keycloak.token,
      props.applicationId,
      sDate,
      eDate
    ) : await getPlatformInsightsDetails(
      keycloak.token,
      sDate,
      eDate
    );

    AddUserActivity(
      keycloak,
      context,
      "FEATURE",
      "Platform Insights",
      response
    );

    setApiLoading(false);
    setIsAPIDataLoading(false);
    setErrorMessage(updateErrorMessage(response));
    // setAPIData(response);

    setAPIData(updateAPIData(response));
    if (response.data.data.platformTiles &&
      response.data.data.platformTiles.length > 0) {
      getMonitoringToolName(response.data.data.platformTiles)

    }
    if (APIDataCall === false) {
      setEventListerners();
      setAPIDataCall(true);
    }
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  const onHover = () => {
    setHover(!hover);
  };

  const getMonitoringToolIcon = (tool) => {
    switch (tool) {
      case "instana": return instanaToolIcon;
      case "AWSCloudWatch": return awsToolIcon;
      case "Datadog": return datadogToolIcon;
    }
  }

  const getMonitoringToolName = (platformTiles) => {
    let newArr = [];
    platformTiles.map((res) => {
      newArr.push(res.monitoring_tool_specific.monitored_by);
    });
    const tools = [...new Set(newArr)];

    let updatedTools = tools.map((tool) => {

      const toolImg = getMonitoringToolIcon(tool);// require(`../../../Assets/carbonIcons/${tool.toLowerCase()}.svg`).default;

      return (
        <>
          <img
            className="monitoring-tile-icon"
            src={toolImg}
            alt="monitoring-tool-icon"
            style={{ height: "2rem", paddingLeft: "10px" }}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          />
          {hover && <p style={{ fontSize: "12px" }}>{tool}</p>}
        </>
      );
    });
    setMonitoringToolNames(updatedTools);
  };

  const createChartExplicit = () => {
    return (
      <>
        {getHeadingAndDateFilter()}

        {/* {
          <Column lg={12} md={8} sm={4}>
            <Tile className="chart-container">
              <ErrorBoundary fallback={"Data is unavailable"}>
                {APIData.data.platformHealth &&
                  APIData.data.platformHealth.data[0] &&
                  APIData.data.platformHealth.data[0]?.data &&
                  APIData.data.platformHealth.data[0]?.data?.chartData &&
                  APIData.data.platformHealth.data[0].data.chartData.length > 0 ? (
                  <>
                    <StackedAreaChart
                      data={APIData.data.platformHealth.data[0].data.chartData}
                      options={APIData.data.platformHealth.data[0].data.chartOptions}
                    ></StackedAreaChart>
                  </>
                ) : (
                  <DataUnAvailable />
                )}
              </ErrorBoundary>
            </Tile>
          </Column>
        } */}
        {
          (APIData.data.platformTiles &&
            APIData.data.platformTiles.length > 0)
            ? (
              <>
                <>
                  <Column lg={8}>
                    <Tile className="chart-container">
                      <GroupedBarChart
                        data={APIData.data.platformHealth.data[0].data.chartData}
                        options={APIData.data.platformHealth.data[0].data.chartOptions}
                      ></GroupedBarChart>
                    </Tile>
                  </Column>
                  <Column lg={8}>
                    <Tile className="chart-container">
                      <LineChart
                        data={APIData.data.platformStackedBar.data[0].data.chartData}
                        options={APIData.data.platformStackedBar.data[0].data.chartOptions}
                      ></LineChart>
                    </Tile>
                  </Column>
                  <Column lg={8}>
                    <Tile className="chart-container">
                      <LineChart
                        data={APIData.data.platformStackedBar.data[1].data.chartData}
                        options={APIData.data.platformStackedBar.data[1].data.chartOptions}
                      ></LineChart>
                    </Tile>
                  </Column>
                  <Column lg={8}>
                    <Tile className="chart-container">
                      <LineChart
                        data={APIData.data.platformStackedBar.data[2].data.chartData}
                        options={APIData.data.platformStackedBar.data[2].data.chartOptions}
                      ></LineChart>
                    </Tile>
                  </Column>
                  {
                    (APIData.data.platformTiles &&
                      APIData.data.platformTiles.length > 0) ?
                      <PlatformInsightsTilesComponent tileData={APIData.data.platformTiles.sort((a, b) => b.open_alert_count - a.open_alert_count)} />
                      :
                      <Column lg={16} md={8} sm={4}>
                        <DataUnAvailable />
                      </Column>
                  }
                </>
              </>
            ) : (
              <Column lg={16} md={8} sm={4}>
                <DataUnAvailable description="Error due to data unavailablity." />
              </Column>
            )}
      </>
    );
  };

  const onStartDateChange = (e) => {
    setStartDate(formatDateYear(new Date(e[0]).toDateString()));
    const startDate = new Date(e[0]);
    const beginOfstartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    sessionStorage.setItem("poStartDate", beginOfstartDate.toISOString());
    if (e.length > 1) {
      const endDate = new Date(e[1]);
      const endOfendDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
      setEndDate(formatDateYear(new Date(e[1]).toDateString()));
      sessionStorage.setItem("poEndDate", endOfendDate.toISOString());
    }
  };

  const getHeadingAndDateFilter = () => {
    return (
      <>
        <Column lg={8} >
          <div style={{ display: "flex" }}>
            <h3>Platform Insights</h3>
            {monitoringToolNames}
          </div>
        </Column>
        <Column lg={8} className="flex-display flex-end override-form-flex">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <DatePicker
              dateFormat="Y-m-d"
              datePickerType="range"
              onChange={(e) => onStartDateChange(e)}
              className="date-picker--margin"
            >
              <DatePickerInput
                id="date-picker-range-start"
                placeholder="yyyy-mm-dd"
                labelText="Start Date"
                defaultValue={startDate.substring(0, 10)}
                // defaultValue="2023-09-13"
                type="text"
              />
              <DatePickerInput
                id="date-picker-range-end"
                placeholder="yyyy-mm-dd"
                labelText="End Date"
                defaultValue={endDate.substring(0, 10)}
                // defaultValue="2023-12-30"
                type="text"
              />
            </DatePicker>
          </ErrorBoundary>
          <span>
            {apiLoading ? (
              <InlineLoading
                status="active"
                iconDescription="Loading"
                description="Loading data..."
                className="report-update__button"
              />
            ) : (
              <Button
                kind="primary"
                onClick={updateAPI}
                size="md"
                iconDescription="Icon Description"
                className="report-update__button"
                style={{ width: "100%", marginTop: "1.5rem" }}
              >
                Update
              </Button>
            )}
          </span>
        </Column>
      </>
    );
  };



  return (
    <div className="container-multichart">
      {APIData ? <Grid>{createChartExplicit()}</Grid> : loadSkeleton()}
    </div>
  );
};

export default PlatformInsightsReport;
