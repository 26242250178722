import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import KenDashboard from "Components/ICTE/JobObservability/JobObservability";
import WorkflowViewMainframe from "./JobDashboard";
import "./JobObserv.scss";

const JobIndex = (props) => {
  const [selectedDropdown, setSelectedItem] = useState(sessionStorage.getItem("ObservabilityTitle"));

  const selectedItem = (e) => {
    setSelectedItem(e)
    sessionStorage.setItem("ObservabilityTitle", e)
  }

  console.log("props.SelectedItem", props.SelectedItem)
  return (
    <div className="att-dash">
      <>
        <Row>
          <Column lg={16} md={8} sm={4}>
            {selectedDropdown === "Job Observability" && <KenDashboard SelectedItem={selectedDropdown} title="Job Dashboard" />}
            {selectedDropdown === "Mainframe Management" && <WorkflowViewMainframe SelectedItem={selectedItem} selectedDropdown={selectedDropdown} title="Job Dashboard" />}
          </Column>
        </Row>
      </>
    </div>
  );
};

export default JobIndex;
