import { Button } from "@carbon/react";
import { Renew } from "@carbon/react/icons";
import React from "react";

const ErrorRefresh = ({ refresh }) => {
  return (
    <div className="error-refresh-page">
      <p
        className="mb-1"
        title="An error occurred while retrieving data from the server."
      >
        An error occurred while retrieving data from the server.
      </p>
      <Button
        kind="tertiary"
        size="md"
        onClick={() => refresh()}
        className="button__refresh"
      >
        Refresh <Renew />
      </Button>
    </div>
  );
};

export default ErrorRefresh;
