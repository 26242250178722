import {
    Accordion,
    AccordionItem,
    Breadcrumb,
    BreadcrumbItem,
    Column,
    Grid,
    Search,
    SkeletonPlaceholder
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import ErrorRefresh from "Common-Modules/ErrorRefresh";
import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getAnyCollection } from "Services/ServerApi";
//   import { v4 as uuidv4 } from "uuid";
import "./Instrument.scss";
import TileComponent from "./TileComponent";

const InstrumentationComponent = () => {
    const { t } = useTranslation();
    //const context = useContext(Context);
    const { keycloak } = useKeycloak();
    const [isLoading, setIsLoading] = useState(true);
    const [apiError, setApiError] = useState(false);
    const [apiData, setApiData] = useState({});
    const [apiDuplicateData, setApiDuplicateData] = useState({});
    const [accordionIndex, setAccordionIndex] = useState(0);

    sessionStorage.setItem("pageFlow", "Automation_Hub");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setApiError(false);
        setIsLoading(true);
        const res = await getAnyCollection(keycloak, "data_core");
        try {
            if (res.data.data_core.length >= 0) {
                setApiData(res.data.data_core[0]);
                setApiDuplicateData(res.data.data_core[0])
                setIsLoading(false);
            } else {
                throw new Error();
            }
        } catch (error) {
            setApiError(true);
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <InlineLoader description={t(`DataAICore.dataAICoreCommon.loaderText`)} />;
    }

    if (apiError) {
        return <ErrorRefresh refresh={getData} />;
    }

    if (apiData.length === 0) {
        return <h4 className="empty--message">{t(`common.NoDataAvailable`)}</h4>;
    }

    // let instrumentationData = context.setHomeScreenData[0].data.filter(
    //   (data) => data.title === "Automation & Integration Hub"
    // );

    const onChangeSearch = (e) => {
        if (e.target.value !== "") {

            let newArr = []
            apiData.data.forEach((api) => {
                const newRes = {
                    ...api,
                    data: api.data.map((sec) => {
                        return {
                            ...sec,
                            data: sec.data.filter((thd) => thd.title.toLowerCase().includes(e.target.value.toLowerCase()))
                        }
                    })
                }

                let filteredArray = newRes.data.filter(obj => obj.data.length > 0);
                if (filteredArray.length > 0) {
                    newArr.push({
                        title: api.title,
                        data: filteredArray
                    })
                }
            })
            if (newArr.length > 0) {
                setApiData({ data: newArr })
            }
        } else {
            setApiData(apiDuplicateData)
        }

    }

    return (
        <>
            {apiData.data !== undefined && apiData.data.length > 0 ? (
                <>
                    <Grid>
                        <Column lg={12} md={8} sm={4}>
                            <Breadcrumb noTrailingSlash>
                                <BreadcrumbItem href="#/home">{t(`breadcrumb.home`)}</BreadcrumbItem>
                                <BreadcrumbItem isCurrentPage>
                                {t(`DataAICore.dataAICoreCommon.breadcrumb`)}
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </Column>
                    </Grid>
                    {/* {(instrumentationData[0].data === undefined ||
              instrumentationData[0].data.length <= 0) && (
              <h3 style={{ paddingTop: "100px", textAlign: "center" }}>
               {t(`ITControlTower.errMsg`)}
              </h3>
            )} */}
                    <Grid style={{ paddingTop: "20px" }}>
                        <Column
                            lg={16}
                            md={8}
                            sm={2}
                            className="tile-"
                        >
                            <Search size="lg" placeholder={t(`DataAICore.dataAICoreCommon.searchPlaceholder`)} labelText="Search" closeButtonLabelText="Clear search input" id="search-1" onChange={(e) => onChangeSearch(e)} onKeyDown={() => { }} />
                        </Column>
                    </Grid>
                    <>
                        {apiData.data !== undefined && apiData.data.map((accordianres) => {
                            return (
                                <Grid style={{ paddingTop: "20px" }}>
                                    <Column
                                        lg={16}
                                        md={8}
                                        sm={2}
                                        className="tile-"
                                    >
                                        {t(accordianres.transTitle)}
                                    </Column>
                                    <Column className="instrument" lg={16} md={4} sm={2} style={{ marginTop: "15px" }}>
                                        <Accordion className="automation-hub-accordion">
                                            {accordianres.data.map((response, index) => {
                                                return (
                                                    <AccordionItem
                                                        id={response.title + index}
                                                        title={t(response.transTitle)}
                                                        open={response.title + index === accordionIndex ? true : false}
                                                        onClick={() => setAccordionIndex(response.title + index++)}
                                                    >
                                                        <Grid>
                                                            {response.data !== undefined ?
                                                                <TileComponent
                                                                    icon="aws-ibm"
                                                                    tileData={response.data}
                                                                    title={accordianres.title}
                                                                    tabTitle={response.title}
                                                                /> : null}
                                                        </Grid>
                                                    </AccordionItem>
                                                );
                                            })}
                                        </Accordion>
                                    </Column>
                                </Grid>
                            );
                        })}
                    </>
                </>
            ) : (
                <SkeletonPlaceholder />
            )}
        </>
    );
};

export default InstrumentationComponent;
