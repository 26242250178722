/** @format */

import {
  Column,
  ErrorBoundary,
  Grid,
  SkeletonPlaceholder,
  Tile,
} from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import Context from "Context/Context";
import { getDOInfraApi } from "../Services/ServerApi";
import React, { useContext, useEffect, useState } from "react";
import ErrorWhileFetching from "../../ICPSA/utilities/ErrorWhileFetching";
import TopologyGraphAligned from "./CarbonDoTopology";

//import AtoTopologyData from "./topo.json";

const TopologyChart = (props) => {
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [APIData, setAPIData] = useState("");
  const [nodes, setNode] = useState([]);
  const [links, setLinks] = useState([]);
  const [errMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [hideDateFilter, setHideDateFilter] = useState(false);
  const [componentName, setComponentName] = useState("");

  const { keycloak } = useKeycloak();
  const context = useContext(Context);

  // const { data: AtoTopologyData, refetch: atoTopoRefetch } = useQuery(['ato_topology'], async () => await getAnyCollection(keycloak, "aiops-insights-bff"), { retry: 1, enabled: false });

  const { data: AtoTopologyData, isLoading,
    isError,
    isFetching, refetch: TopoRefetch} = useQuery(['infra_alert_topology'], async () => await getDOInfraApi(componentName), { enabled: false })


  const size = 48;


  useEffect(() => {

    if(sessionStorage.getItem("selectedComponent") !== null) {
      const component = sessionStorage.getItem("selectedComponent") === "aiops-insights-bff" ? "dw-bluebuddy-apps-bluebuddy" : "aiops-insights-bff";
      setComponentName(component)
      sessionStorage.setItem("selectedComponent", component);
      setAPIData("")
    } else {
      sessionStorage.setItem("selectedComponent", "aiops-insights-bff");
      setComponentName("aiops-insights-bff");
      setAPIData("")
    }

  }, [])

  useEffect(() => {
    if(componentName !== "") {
      TopoRefetch()
      setAPIData("")
    }
  }, [componentName])

  useEffect(() => {
      console.log("AtoTopologyData", AtoTopologyData, isLoading, isFetching)
    if(AtoTopologyData !== undefined){

      // let nodes = [], edges = [];
      // AtoTopologyData.data[0].Services.forEach((ser, index) => {
      //     nodes.push({name: ser.Name, id: ser.ReferenceId, height: size, width: size})
      //     ser.Edges.forEach((edg, ind) => {
      //         edges.push({ id: edg.ReferenceId, source: ser.ReferenceId, target: edg.ReferenceId})
      //     });
      // })

      // setNode(nodes);
      // setLinks(edges);

      const service = AtoTopologyData.Services[0].ReferenceId;

      console.log("AtoTopologyData.Services[0]", AtoTopologyData.Services[0])

      const obj = {
          "ReferenceId": "aaa",
          "Name": "Client",
          "Type": "client",
          "StartTime": "2023-08-24T04:14:15.00",
          "EndTime": "2023-08-24T04:20:15.00",
          "Edges": [
            {
              "ReferenceId": service
            }
          ]
        }
      const serv =  [obj, ...AtoTopologyData.Services];
      
        console.log("serv", serv)

      setAPIData(serv)
    }
  }, [AtoTopologyData])


  const onShowTraceList = (show) => {
    setHideDateFilter(show);
  };



  const createApplicationsChart = () => {

      console.log("nodes ------------ >>>>>>", APIData)

    return (
      <>
        {/* <Column lg={10}>
          <div>
            <h3>Application Topology View</h3>
          </div>
        </Column> */}
        <Column lg={16} md={8} sm={4}>
          {APIData.length > 0 ? (
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              <Tile className="chart-container">
              <h5 style={{ marginTop: "10px"}}>Topology Graph</h5>
                <ErrorBoundary fallback={"Data is unavailable"}>
                  <TopologyGraphAligned
                    data={APIData}
                  />
                </ErrorBoundary>
              </Tile>
            </ErrorBoundary>
          ) : (
            loadSkeleton()
          )}
        </Column>
      </>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div
        className="skeleton__placeholder--default  common-top-margin"
      >
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  return (
    <div className="container-multichart">
      {<Grid>{createApplicationsChart()}</Grid>}
    </div>
  );
};

export default TopologyChart;
