import {
    Checkbox,
    Column,
    ContentSwitcher,
    DatePicker,
    DatePickerInput,
    Row,
    SkeletonText,
    Switch,
    Tile
} from "@carbon/react";
import { Calendar, EventSchedule, Time } from "@carbon/react/icons/index";
// import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Carbon-Components/DataTable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DailyJobs from "./JobDailyJobs";
import TopologyGraph from "./JobTopology";

const DashboardDetails = (props) => {
    const history = useHistory();
    // const { keycloak } = useKeycloak();
    // const queryClient = new QueryClient();
    const [details, setDetails] = useState([]);
    const [jobExeStatData, setJobExeStatData] = useState([])
    const [dateIndexChange, onDateIndexChange] = useState(0);
    const [dateChange, onDateChange] = useState("All");
    const [contentSwitch, setContentSwitch] = useState("Job Health");
    // const [jobStatusDetails, setJobStatusDetails] = useState([]);
    // const [enableModal, onEnableModal] = useState(false);
    const [directvData, setDirectvData] = useState([]);
    const [resUniq, setResUniq] = useState([])

    const { data: directvJobs } = useQuery(["directvJobs"])
    const { data: starCricJobs } = useQuery(['directvstarCricJobs'])


    useEffect(() => {
        if (props.location) {
            onDateChange(props.location.state.date)
            onDateIndexChange(props.location.state.dateIndexChange)
            setDirectvData(props.location.state.mergeData)
            jobExecutionStatus()
        }
    }, [props])

    const jobExecutionStatus = (dat) => {
        let topo = [];

        const removeStarDuplicate = starCricJobs.data.directv_job_details_new.filter((obj, index) => {
            return index === starCricJobs.data.directv_job_details_new.findIndex(o => obj.jobname === o.jobname);
        });

        removeStarDuplicate.forEach((star) => {
            directvJobs.forEach((job) => {
                job.job_details.forEach((details) => {
                    if (star["Child Job Name"] === details["jobname"] || star["jobname"] === details["jobname"]) {
                        topo.push({ ...star, ...job, ...details })
                    }
                })
            })
        })

        const date = dat === undefined ? props.location.state.date : dat;
        let day = []
        if (date === "Daily") {
            day = ["ddly", "dly"]
        } else if (date === "Hourly") {
            day = ["hhly", "hrly"]
        } else if (date === "All") {
            day = "All"
        }
        let res = topo;
        if (day !== "All") {
            res = topo.filter((res) => day.some(tv => res["jobname"].includes(tv)));
        }

        const unique = res.filter((obj, index) => {
            return index === res.findIndex(o => obj.jobname === o.jobname);
        });

        const removeDuplicateDependent = unique.filter((uni) => uni.jobname !== uni["Child Job Name"]);

        //const test = removeDuplicateDependent.filter((obj, index) => obj.jobname === "vwrshp_dp_cur_vwrshp_uverse_event17786_parsed_data_hhly")

        setResUniq(res)

        const lastRes = removeDuplicateDependent.map((qu, index) => {
            return {
                ...qu,
                id: index.toString(),
                notes: "",
                status: getJobStatus(qu, res)
            }
        });

        const exeSatus = [
            {
                "job": "Hourly",
                "data": lastRes
            },
            {
                "job": "Daily",
                "data": lastRes
            }
        ]

        const dtChange = dateChange === "Daily" ? dateChange : "Hourly"
        const filterJobs = exeSatus.filter((stat) => stat.job === dtChange)
        setJobExeStatData(filterJobs[0].data)

    }

    const getJobStatus = (qu, resUnique) => {
        let newArr = [];
        resUnique.forEach((po) => {
            if (qu.jobname === po.jobname) {
                newArr.push(po)
            }
        })
        const filterDup = newArr.filter((obj, index) => {
            return index === newArr.findIndex(o => obj.jobname === o.jobname && obj.observedtime === o.observedtime);
        });
        return filterDup
    }

    const dateOnChange = (e, index) => {
        onDateIndexChange(index);
        onDateChange(e);
        jobExecutionStatus(e)
    };

   // const workflowDetails = useSelector((state) => state.ATTReducer);

    // useEffect(() => {
    //     if (Object.entries(props.lo).length > 0) {
    //         setDetails(workflowDetails.Details)
    //     }
    // }, [workflowDetails])

    useEffect(() => {
        if (props?.location && props?.location?.state) {
            setDetails(props.location.state.data)
        }
    }, [props.location])

    const jobHeaderData = [{
        key: "jobname",
        header: "Job",
    },
    {
        key: "workflow",
        header: "workflow",
    },
    {
        key: "status",
        header: "Status",
    }
    ]


    const getTableHeader = (data) => {
        return data
    }


    const getJobRowCellData = (id, data, md) => {
        if (id.includes("name")) {
            return <div className="desc-tooltip">
                <div>{data}</div>
            </div>
        }
        if (id.includes("status") && data.length > 0) {
            return <ul>
                {data.map((st) => <li onClick={() => onClickWorkflow(st, md)} title={st.observedtime} className={`stat-color ${st.job_status}`}></li>)}
            </ul>
        }
        if (id.includes("notes")) {
            return <div className="notes">{data}</div>
        }
        return data
    }

    const onClickWorkflow = (data, md) => {
        history.push({ pathname: "/WorkflowDetailsIndex", state: { details, directvData: props.location.state.directvData, resUniq, data: data, starCric: props.location.state.starCric, mergeData: props.location.state.mergeData, date: props.location.state.date, dateIndexChange: props.location.state.dateIndexChange, flow: props.location.state.flow } })
    }

    const dateSelection = () => {
        const dateArr = ["All", "Hourly", "Daily", "Monthly"];
        return dateArr.map((date, index) => (
            <li
                onClick={() => dateOnChange(date, index)}
                className={`${index === dateIndexChange && "active"} ${date === "Monthly" ? date : ""} `}
            >
                {date}
                {date === "Hourly" ? <Time /> : date === "Daily" ? <EventSchedule /> : date === "Monthly" ? <Calendar /> : null}
            </li>
        ));
    };

    // const closeModal = () => {
    //     onEnableModal(false);
    // }

    const onContentSwitch = (eve) => {
        setContentSwitch(eve.text)
    }

    const getcheckBox = () => {
        return <Checkbox onChange={(e) => onClickCheck(e)} id="checkbox-3" labelText="Failed Jobs" helperText="" />
    }

    const onClickCheck = (e) => {
        if (e.target.checked) {
            const error = jobExeStatData.filter((fil) => fil.status.some((err) => err.job_status.toLowerCase() === "error"))
            setJobExeStatData(error)
        } else {
            jobExecutionStatus()
        }
    }


    return (
        Object.entries(details).length > 0 ?
            <>
                < Row >
                    <Column lg={6} md={4}>
                        <ContentSwitcher onChange={(e) => onContentSwitch(e)}>
                            <Switch name="Job Health" text="Job Health" />
                            {/* <Switch disabled name="Job Execution Status" text="Job Execution Status" /> */}
                            <Switch name="Audit Report" text="Audit Report" />
                        </ContentSwitcher>
                    </Column>
                    <Column lg={10} md={8} sm={2} className="filter-date">
                        {/* <ul className="portfolio-fss">{dateSelection()}</ul>
                        <DatePicker datePickerType="single" readOnly={true}>
                            <DatePickerInput disabled placeholder="16/10/2023" readOnly={true} labelText="Select Date" id="date-picker-single" size="sm" />
                        </DatePicker> */}
                    </Column>
                    <Column lg={16} md={8}>
                        {contentSwitch === "Job Health" ? <Tile className="lft-topo">
                            {directvData.length > 0 ? <TopologyGraph details={details} resUniq={resUniq} date={dateChange} workFlowDetails={details} directvData={directvData} location={props.location} /> :
                                <>
                                    <SkeletonText />
                                </>
                            }
                        </Tile> : null}
                        {contentSwitch === "Job Execution Status" ?
                            <Tile className="rgt-table">
                                <div className="title">
                                    <ul className="portfolio-fss">{dateSelection()}</ul>
                                </div>
                                <DataTable
                                    rowData={jobExeStatData}
                                    headerData={jobHeaderData}
                                    getRowCellData={getJobRowCellData}
                                    getTableHeader={getTableHeader}
                                    actionsNeeded={false}
                                    getcheckBox={getcheckBox}
                                    checkBox={true}
                                    className="job-exe"
                                    table_particular_actions={false}
                                    size="sm"
                                    diabledCol="workflow"
                                    placeholder="Search Jobs"
                                />
                            </Tile> : null}
                        {contentSwitch === "Audit Report" ? <DailyJobs flow="fromTopo" /> : null}
                    </Column>
                </Row >
                {/* {enableModal ? <JobStatusModal data={jobStatusDetails} closeModal={closeModal} /> : null} */}
            </>
            : null
    )
}

export default DashboardDetails