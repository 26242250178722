/** @format */

import {
  DataTable,
  Pagination,
  TableToolbar,
  TableToolbarAction,
  TableToolbarContent,
  TableToolbarMenu,
  TableToolbarSearch
} from "@carbon/react";
import { CheckmarkFilled } from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";
const {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} = DataTable;

const issueTypes = ["All", "Anomaly", "Error", "Fault", "Normal"];

const CarbonDataTableServiceMap = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowData, setRowData] = useState([]);
  const [rowDataInitial, setRowDataInitial] = useState(props.rowData);
  const [selectedView, setSelectedView] = useState("All");

  useEffect(() => {
    updatePagination(page, pageSize, props.rowData);
  }, []);

  const updatePagination = (pageCount, pageSizeCount, rowData) => {
    let startIndex = 0;
    let endIndex = 0;

    if (rowData.length > 0) {
      startIndex = (pageCount - 1) * pageSizeCount;
      endIndex = pageCount * pageSizeCount;
    }

    let updatedRowData = rowData.slice(startIndex, endIndex);
    setRowData(updatedRowData);
  };

  const getDescription = () => {
    if (props.description && props.description.includes("Traces")) {
      return props.description;
    } else {
      return "";
    }
  };

  const changePaginationState = (pageInfo) => {
    if (page !== pageInfo.page) {
      setPage(pageInfo.page);
    }
    if (pageSize !== pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize);
    }
    updatePagination(pageInfo.page, pageInfo.pageSize, rowDataInitial);
  };

  const filterRows = (detailsId) => {
    setSelectedView(detailsId);
    let detailRows = [];

    switch (detailsId) {
      case "Anomaly":
        props.rowData.forEach((row) => {
          if (row.hasAnomaly === "Yes") {
            detailRows.push(row);
          }
        });
        break;
      case "Error":
        props.rowData.forEach((row) => {
          if (row.hasError === "Yes") {
            detailRows.push(row);
          }
        });
        break;
      case "Fault":
        props.rowData.forEach((row) => {
          if (row.hasFault === "Yes") {
            detailRows.push(row);
          }
        });
        break;
      case "Normal":
        props.rowData.forEach((row) => {
          if (
            row.hasAnomaly !== "Yes" &&
            row.hasError !== "Yes" &&
            row.hasFault !== "Yes"
          ) {
            detailRows.push(row);
          }
        });
        break;
      case "All":
        detailRows = props.rowData;
        break;
      default:
        return "";
    }

    updatePagination(page, pageSize, detailRows);
    setRowDataInitial(detailRows);
  };

  const getToolbarMenuContent = () => {
    return issueTypes.map((issueType) => {
      return getIssueToolBarAction(issueType);
    })
  }

  const getIssueToolBarAction = (issue) => {
    return <TableToolbarAction
      onClick={(e) => filterRows(e.target.innerText)}
    >
      {issue}
      {selectedView === issue && (
        <CheckmarkFilled className="table-tool-bar__menu-icon" />
      )}
    </TableToolbarAction>
  }

  return props.rowData.length > 0 ? (
    <>
      <DataTable rows={rowData} headers={props.headerData} isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getToolbarProps,
          onInputChange,
        }) => (
          <TableContainer title={props.title} description={getDescription()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <TableToolbarSearch onChange={onInputChange} persistent />
                <TableToolbarMenu dark>
                  {getToolbarMenuContent()}
                </TableToolbarMenu>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()} size="md">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {props.getTableHeader(header.header)}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell, index) => (
                      <TableCell key={index}>
                        {props.getRowCellData(cell.id, cell.value, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        onChange={changePaginationState}
        page={page}
        pageSize={pageSize}
        pageSizes={[10, 20, 100]}
        totalItems={rowDataInitial.length}
      ></Pagination>
    </>
  ) : (
    "Data is unavailable"
  );
};

export default CarbonDataTableServiceMap;
