import { Tile } from "@carbon/react";
import { ChevronLeft, ChevronRight, MachineLearningModel } from "@carbon/react/icons";
import React, { useState } from "react";

const MLInferences = ({ application }) => {
  const appType = {
    "MuleSoft On-Prem": "Platinum",
    Maximo: "Platinum",
    MDMS: "Gold",
    "C1-CCnB": "Gold",
    Application3: "Platinum",
    Application4: "Platinum",
    Application1: "Gold",
    Application2: "Gold",
    Application5: "Gold",
  };
  //const tenant = sessionStorage.getItem("tenant");
  const EA_Tenant = false;

  const data =
    " Based on the provided dictionary, the top 3 most relevant recommendations to reduce the carbon emission of each metric of the application are:\n\n1. Reduce the number of tickets being generated. This can be achieved by implementing better monitoring and alerting systems, as well as by providing users with self-service options to resolve their own issues.\n\n2. Reduce the number of individuals involved in resolving the ticket. This can be achieved by implementing a more efficient ticketing system, as well as by providing training to users on how to resolve their own issues.\n\n3. Reduce the overall effort involved in resolving the ticket. This can be achieved by implementing automated workflows, as well as by providing users with access to the resources they need to resolve their issues quickly and easily.";
  const regex = /\n\n\d+\.\s/;
  let inferences;

  if (EA_Tenant) {
    inferences = [
      "Implement 100% De-Duplication Capability",
      "Implement 100% Auto-Ticketing Capability",
    ];
  } else {
    inferences = data.split(regex).slice(1);
  }
  const inferenceLength = inferences.length;
  const [index, setIndex] = useState(0);

  const nextInf = () => {
    setIndex((prev) => {
      if (prev === inferenceLength - 1) return 0;
      return prev + 1;
    });
  };

  const prevInf = () => {
    setIndex((prev) => {
      if (prev === 0) return inferenceLength - 1;
      return prev - 1;
    });
  };

  return (
    <Tile className="inference-tile">
      <div className="inference-heading">
        Inferences
        {application
          ? `: ${application} (${appType[application] || ""})`
          : null}
      </div>
      <div className="inference">
        <MachineLearningModel size={50} />
        <p className={`inference-description ${EA_Tenant ? "" : "f-12"}`}>
          {inferences[index]}
        </p>
        <ChevronLeft
          className="left-arrow"
          size={20}
          onClick={() => prevInf()}
        />
        <ChevronRight
          className="right-arrow"
          size={20}
          onClick={() => nextInf()}
        />
      </div>
    </Tile>
  );
};

export default MLInferences;
