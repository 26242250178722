/** @format */


const AddUserActivity = async (keycloak, context, eventTypeValue, eventValue, response) => {
    context.events = {
        eventType: eventTypeValue,
        event: eventValue,
        bffResponseStatus: response.status,
        apiResponseStatus: response.data?.apiResponseStatus,
        uiResponseStatus: response.status === 200 && response.data?.apiResponseStatus === 200 && response.data?.isDataAvailable,
        isDataAvailable: response.data?.isDataAvailable
    };
    // await addEvents(keycloak.token, context.events);
}

export default AddUserActivity;