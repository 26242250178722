import { Column, FlexGrid } from "@carbon/react";
import React from "react";
import PmDomains from "./PmDomains";
import "./Pm.scss";

const PmIndex = () => {
    return (
        <>
            <PmDomains />
        </>
    )
}

export default PmIndex;