import { Modal } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
// import PassiveModal from "Carbon-Components/Modal/PassiveModal";
// import DynamicForm from "Components/Common/DynamicForm";
// import InlineLoading from "Components/Common/loaderModule/InlineLoader";
// import { addUserlication } from "ICPSAservices/serverapi";
import React, { useEffect, useState } from "react";
// import { checkAppDupilicates } from "services/serverapi";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import { getAppUserByUsername, getTenantRolesByTenantNameAPI, getUserbyUsername, postUserToSelectedAppDetails } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
import addUser from "./addUser.json";

const CreateUser = (props) => {
  const { keycloak } = useKeycloak();
  let timer = null;
  const duplicateMessage = "User Already Exists";
  const [isFormValid, setFormValididty] = useState(false);
  const [formData, setFormData] = useState({});
  const [dupilicate, setDupilicate] = useState(false);
  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formTemplate, setFormTemplate] = useState(JSON.parse(JSON.stringify(addUser)));
  const [updateCounter, setUpdateCounter] = useState(0);
  const selectedTenant = sessionStorage.getItem("selectedTenant");
  const selectedOrg = sessionStorage.getItem("selectedOrg");
  const selectedApp = sessionStorage.getItem("selectedApp");
  const [roles, setRoles] = useState([]);

  const handleFormValidity = (isValid) => {
    setFormValididty(isValid);
  };

  // Fetch roles when component mounts
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData = await getTenantRolesByTenantNameAPI(keycloak, selectedTenant, uuidv4());
        if (Array.isArray(rolesData)) {
          // Transform the roles data into the format expected by the dropdown
          const formattedRoles = rolesData.map(role => ({
            value: role.id || role,  // Adjust based on actual API response structure
            name: role.name || role  // Adjust based on actual API response structure
          }));

          setRoles(formattedRoles);

          // Update the form template with the new roles
          const updatedTemplate = formTemplate.map(field => {
            if (field.id === 'role') {
              return {
                ...field,
                options: formattedRoles
              };
            }
            return field;
          });

          setFormTemplate(updatedTemplate);
          setUpdateCounter(prev => prev + 1); // Force re-render of form
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        setTimeout(fetchRoles, 2000);
      }
    };

    fetchRoles();
  }, [selectedTenant, keycloak]);


  // useEffect(() => {
  //   getTenantRolesAPI(keycloak, uuidv4());
  // }, []);

  // Updated effect to handle updated formdata for existing users
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      try {
        // Create a new array with updated values
        const updatedTemplate = addUser.map(field => ({
          ...field,
          inputValue: formData[field.id] || ''
        }));
        setFormTemplate(updatedTemplate);
      } catch (error) {
        console.error("Error updating form template:", error);
        setFormTemplate(addUser);
      }
    } else {
      // Reset to original template
      const cleanTemplate = addUser.map(field => ({
        ...field,
        inputValue: ''
      }));
      setFormTemplate(cleanTemplate);
    }
  }, [formData]);

  const handleFormChange = (label, value, id) => {
    console.log("label======" + label);

    if (label === "username") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(() => {
        if (Boolean(value)) checkDupilicate(value);
      }, 500);
    }
    // const targetId = label;
    // const targetValue = value;
    // const data = formData;
    // data[targetId] = targetValue;
    // setFormData(data);
    setFormData(prevData => ({
      ...prevData,
      [label]: value
    }));
  };

  // const checkDupilicate = async (value) => {
  //   setChecking(true);
  //   const res = await getUserId(keycloak, value);
  //   if (res?.status === 204) {
  //     setDupilicate(false);
  //   } else {
  //     setDupilicate(true);
  //   }
  //   setChecking(false);
  // };

  // const checkDupilicate = async (value) => {
  //   setChecking(true);
  //   try {
  //     const res = await getUserbyUsername(keycloak, value);
  //     if (res?.status === 204) {
  //       setDupilicate(false);
  //       setFormData({ username: value });
  //     } else if (res?.data) {
  //       setDupilicate(true);
  //       // Create sanitized data object
  //       const sanitizedData = {};
  //       addUser.forEach(field => {
  //         if (res.data.hasOwnProperty(field.id) && field.id !== 'password') {
  //           sanitizedData[field.id] = res.data[field.id];
  //         }
  //       });
  //       //Don't include password in autofill
  //       delete sanitizedData.password;

  //       // Update the template first
  //       const updatedTemplate = addUser.map(field => ({
  //         ...field,
  //         inputValue: sanitizedData[field.id] || ''
  //       }));

  //       setFormTemplate(updatedTemplate);
  //       setFormData(sanitizedData);
  //       // Increment counter to force re-render with new values
  //       setUpdateCounter(prev => prev + 1);
  //     }
  //   } catch (error) {
  //     console.error("Error checking for duplicate user:", error);
  //     setFormData({ username: value });
  //   } finally {
  //     setChecking(false);
  //     setFormValididty(true);
  //   }
  // };

  const handleUserAutofill = async (userData) => {
    try {
      // First ensure we have the roles data
      // if (roles.length === 0) {
      //   // Fetch roles if not already loaded
      //   const rolesData = await getTenantRolesByTenantNameAPI(keycloak, selectedTenant, uuidv4());
      //   if (Array.isArray(rolesData)) {
      //     const formattedRoles = rolesData.map(role => ({
      //       value: role.id || role,
      //       name: role.name || role
      //     }));
      //     setRoles(formattedRoles);
      //   }
      // }

      // Create sanitized data object
      const sanitizedData = {};
      addUser.forEach(field => {
        if (userData.hasOwnProperty(field.id)) {
          if (field.id === 'role' && userData.role) {
            // Handle role specifically - match it with available roles
            const matchedRole = roles.find(r =>
              r.value === userData.role.id ||
              r.name === userData.role.name ||
              r.value === userData.role
            );
            sanitizedData[field.id] = matchedRole ? matchedRole.value : '';
          } else {
            sanitizedData[field.id] = userData[field.id];
          }
        }
      });

      // Update the template with the sanitized data
      const updatedTemplate = addUser.map(field => {
        if (field.id === 'role') {
          return {
            ...field,
            inputValue: sanitizedData.role || '',
            options: roles 
          };
        }
        return {
          ...field,
          inputValue: sanitizedData[field.id] || ''
        };
      });

      setFormTemplate(updatedTemplate);
      setFormData(sanitizedData);
      setUpdateCounter(prev => prev + 1);
      setFormValididty(true);

    } catch (error) {
      console.error("Error in autofill:", error);
      setFormData({ username: userData.username || '' });
    }
  };

  const checkDupilicate = async (value) => {
    setChecking(true);
    try {
      // First check if user exists in current tenant
      const tenantUserRes = await getAppUserByUsername(keycloak, selectedTenant, selectedOrg, selectedApp, value);

      if (tenantUserRes?.status === 200) {
        // User exists in tenant - don't allow adding
        setDupilicate(true);
        setFormValididty(false);
        setFormData({ username: value });
        return;
      }

      // If not in tenant, check if user exists in product
      const productUserRes = await getUserbyUsername(keycloak, value);

      if (productUserRes?.status === 204) {
        // User doesn't exist anywhere - allow new user creation
        setDupilicate(false);
        setFormData({ username: value });
      } else if (productUserRes?.data) {
        // User exists in product but not in tenant - allow adding to tenant
        setDupilicate(false);
        await handleUserAutofill(productUserRes.data);
        setFormValididty(true);
      }

    } catch (error) {
      console.error("Error checking for duplicate user:", error);
      setFormData({ username: value });
    } finally {
      setChecking(false);
    }
  };

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const onCreateUser = async () => {
    // const data = { ...formData };
    // let selectedRole = {};
    // roles.forEach((roleObj) => {
    //   if (roleObj.value === formData.role) {
    //     selectedRole = roleObj;
    //   }
    // })

    const data = {
      ...formData,
      // role: selectedRole
    };
    // data.okrTemplate = [];
    // data.startDate = createUTCDateFromInput(formData.startDate, "start");
    // data.endDate = createUTCDateFromInput(formData.endDate, "end");
    // data.tenant = sessionStorage.getItem("tenant");
    setIsLoading(true);
    const res = await postUserToSelectedAppDetails(keycloak, uuidv4(), data, selectedTenant, selectedOrg, selectedApp);
    try {
      if (!Boolean(res?.data)) {
        throw new Error("msg");
      } else {
        setErrorMessage("User created successfully");
        setReloadRequired(true);
      }
    } catch (error) {
      setErrorMessage("Failed to create user");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {errorMessage || isLoading ? (
        <PassiveModal
          canCloseDetailsPopup={() => {
            closeErrorPopup();
            props.closeCreateUser(reload_required);
          }}
        >
          <h4>{errorMessage}</h4>
          {isLoading && (
            <InlineLoading
              status="active"
              description={dupilicate ? "Adding User to Application" : "Creating Application User"}
            />
          )}
        </PassiveModal>
      ) : (
        <Modal
          open
          modalHeading="Create/Edit user in Application"
          primaryButtonText="Create/Edit"
          secondaryButtonText="Cancel"
          onRequestClose={() => props.closeCreateUser(reload_required)}
          onRequestSubmit={() => onCreateUser()}
          primaryButtonDisabled={!isFormValid || checking}
        >
          <div>
            <DynamicForm
              // formData={JSON.parse(JSON.stringify(addUser))}
              key={`form-${updateCounter}`}
              formData={formTemplate}
              dupilicate={{ id: "username", exists: dupilicate }}
              duplicateMessage={duplicateMessage}
              getFormValidity={handleFormValidity}
              handleFormChange={handleFormChange}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateUser;
