import React, { useState, useEffect, useContext } from "react";
import Elk from "./elkTopology";
import { SkeletonText, SkeletonPlaceholder } from "@carbon/react";
import Context from "Context/Context";

const TopologyGraph = (props) => {
  const [linkData, setlinkData] = useState([]);
  const [nodeData, setNodeData] = useState([]);
  const context = useContext(Context);
  const title = sessionStorage.getItem("portfolioTitle");
  const selectedFlow = context.valueStreamData.data.filter(
    (data) => data.title === title
  );
  const CJstepName = sessionStorage.getItem("CJstepName");
  const selectedCustomerJourney = selectedFlow[0].journeySteps.filter(
    (fil) => fil.name === CJstepName
  );

  // const nodeHeight = 64;
  // const nodeWidth = 180;
  // const size = 48;

  useEffect(() => {
    getTopologyData();
  }, []);

  const getTopologyData = (instanaTopology) => {
    const nodeData = [
      { id: "a", height: 48, width: 48, label: "Prepaid", types: ["jdbc"] },
      {
        id: "b",
        height: 160,
        width: 60,
        label: "Integration Svc Layer",
        types: ["HTTP"],
      },
      {
        id: "c",
        height: 48,
        width: 48,
        label: "Billing System",
        types: ["MESSAGING"],
      },
      { id: "d", height: 48, width: 48, label: "MDMS", types: ["unknown"] },
      {
        id: "e",
        height: 160,
        width: 60,
        label: "Integration Svc Layer",
        types: ["jdbc"],
      },
      { id: "f", height: 48, width: 48, label: "HES", types: ["unknown"] },
      {
        id: "g",
        height: 160,
        width: 60,
        label: "Integration Svc Layer",
        types: ["unknown"],
      },
      {
        id: "h",
        height: 48,
        width: 48,
        label: "Meter",
        types: ["unknown"],
      },
    ];

    const linkData = [
      { id: "1", source: "a", target: "b" },
      { id: "2", source: "b", target: "c" },
      { id: "3", source: "c", target: "d" },
      { id: "4", source: "d", target: "e" },
      { id: "5", source: "e", target: "f" },
      { id: "6", source: "f", target: "g" },
      { id: "7", source: "g", target: "h" },
    ];

    // const connected = TopologyJson.connections.map((topo, index) => {
    //   let newObj = {
    //     source: topo.from,
    //     target: topo.to
    //   };
    //   // instanaTopology.connections.forEach((conn) => {
    //   //   if (topo.from === conn.from && topo.to === conn.to) {
    //   //     newObj = {
    //   //       ...newObj,
    //   //       errorRate: conn.errorRate,
    //   //     }
    //   //   }
    //   // })
    //   return {
    //     id: index + 1,
    //     ...newObj
    //   }
    // })
    // const node = TopologyJson.services.map((serv, index) => {
    //   let newServ = serv;
    //   connected.forEach((con) => {
    //     if (con.target === serv.id) {
    //       newServ = {
    //         ...newServ,
    //         error: con.errorRate
    //       }
    //     }
    //   })
    //   return {
    //     ...newServ,
    //     width: 60,
    //     height: 60,
    //   }
    // })
    // console.log("connected", node, connected)
    setlinkData(linkData);
    let nodeDataNew =
      selectedCustomerJourney.length > 0
        ? selectedCustomerJourney[0].nodeData
        : nodeData;
    setNodeData(nodeDataNew);
  };

  const redirectTo = (node) => {
    props.redirectToIne(true, node);
  };

  return (
    <>
      {nodeData.length > 0 ? (
        <Elk
          nodes={nodeData}
          links={linkData}
          layout="layered"
          viewBox="200 -200 800 800"
          width="1000"
          height="500"
          flow="real-time"
          cardClick={redirectTo}
        />
      ) : (
        <div style={{ height: "500px" }}>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <br />
          <SkeletonPlaceholder />
        </div>
      )}
    </>
  );
};

export default TopologyGraph;
