import { Breadcrumb, BreadcrumbItem, Column, Grid } from "@carbon/react";
import React from "react";
import PlatformAlertsReport from "./PlatformAlertsReport";

const PlatformAlerts = (props) => {
  return (
    <>
      <Grid>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            {/* <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem> */}
            {/* <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem> */}
            {/* <BreadcrumbItem href="#/itOperations">Integrated Operations</BreadcrumbItem> */}
            <BreadcrumbItem href="#/itOperations">
              Platform Observability
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Platform Alerts</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Grid>
      <div className="content-div">
        <PlatformAlertsReport
          title="Platform Alerts"
        />
      </div>
    </>
  );
};

export default PlatformAlerts;
