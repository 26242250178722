import { Column, FlexGrid, Row } from "@carbon/react";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import valueStream from "../ValueStream.json";
import "./pnc.scss";
import PncMnemonic from "./pncMnemonic";

const PNCIndex = () => {
  // const { data: valueStream } = useQuery(["valueStream"]);
  const context = useContext(Context);
  const [PNCData, setPNCData] = useState({});

  useEffect(() => {
    if (valueStream !== undefined && valueStream.data !== undefined) {
      const response = valueStream.data.valueStreamDetails.filter(
        (fil) => fil.title === context.valueStreamFlow
      );
      setPNCData(response[0]);
    }
  }, [context, valueStream]);

  // console.log("valueStreamTemplate ============== pnc", PNCData)

  return (
    <FlexGrid className="pnc-value-stream">
      {context.valueStreamFlow === "North America Financial Services" && Object.keys(PNCData).length > 0 && (
        <Row>
          <Column lg={16} md={8} sm={4}>
            <PncMnemonic PNCJson={PNCData} />
          </Column>
        </Row>
      )}
    </FlexGrid>
  );
};

export default PNCIndex;
