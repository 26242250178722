import { Column } from "@carbon/react";
import {
  Catalog,
  Collaborate,
  DotMark,
  IbmWatsonDiscovery,
  PlayFilledAlt
} from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import DataTable from "Carbon-Components/DataTable";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  JenkinsBuildStatusforGenAI,
  triggerJenkinsBuildforGenAI,
} from "Services/ServerApi";
import GenPipeline from "../genPipeline";
import Analyze from "./analyze";
import ErrorLog from "./errorLog";
import tableDataJSON from "./tableData.json";
import TreeMap from "./treemap";
import "../scss/ci_cd.scss";

const error = `[ERROR] COMPILATION ERROR : 
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[6,53] package com.ulisesbocchio.jasyptspringboot.annotation does not exist
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[9,2] cannot find symbol
[ERROR] Failed to execute goal org.apache.maven.plugins:maven-compiler-plugin:3.8.0:compile (default-compile) on project LCT_NEW: Compilation failure: Compilation failure: 
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[6,53] package com.ulisesbocchio.jasyptspringboot.annotation does not exist
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[9,2] cannot find symbol
[ERROR]   symbol: class EnableEncryptableProperties
[ERROR] -> [Help 1]
[ERROR] 
[ERROR] To see the full stack trace of the errors, re-run Maven with the -e switch.
[ERROR] Re-run Maven using the -X switch to enable full debug logging.
[ERROR] 
[ERROR] For more information about the errors and possible solutions, please read the following articles:
[ERROR] [Help 1] http://cwiki.apache.org/confluence/display/MAVEN/MojoFailureException`;


const deploy = `docker login -u iamapikey -p **************** us.icr.io
WARNING! Using --password via the CLI is insecure. Use --password-stdin.
Error response from daemon: Get "https://us.icr.io/v2/": unauthorized: Authorization required. See https://cloud.ibm.com/docs/Registry?topic=Registry-troubleshoot-auth-req`;

const getCurrentDateTime = (newDate) => {
  let date =
    newDate.getDate() <= 9 ? `0${newDate.getDate()}` : newDate.getDate();
  let month =
    newDate.getMonth() + 1 <= 9
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour =
    newDate.getHours() <= 9 ? `0${newDate.getHours()}` : newDate.getHours();
  let minute =
    newDate.getMinutes() <= 9
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes();
  let sec =
    newDate.getSeconds() <= 9
      ? `0${newDate.getSeconds()}`
      : newDate.getSeconds();
  return `${year}-${month}-${date} ${hour}:${minute}:${sec}`;
};

const PipelineActivity = () => {
  const { keycloak } = useKeycloak();
  const [showModal, showModals] = useState(false);
  const [errorLog, setErrorLog] = useState(false);
  const [logData, setLogData] = useState(error);
  const [analyze, setAnalyze] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [buildStatus, setBuildStatus] = useState({});

  useEffect(() => {
    getBuildStatus();
  }, []);

  useEffect(() => {
    if (Object.keys(buildStatus).length > 0) {
      const stat = buildStatus.jobs[0]?.lastBuild?.result;
      tableDataJSON[0].status = stat === null ? "In Progress" : stat;
      tableDataJSON[0].pipeline.map((pipe) => {
        if(pipe.name === "Build" && stat === null) {
          pipe.value = "In Progress"
        } else if(pipe.name === "Build") {
          pipe.value = stat
        }
        return pipe;
      })
      let triggeredTime = new Date(buildStatus.jobs[0]?.lastBuild?.timestamp);
      tableDataJSON[0].triggered = getCurrentDateTime(triggeredTime);

      setTableData(tableDataJSON);
    }
  }, [buildStatus]);

  const headerData = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "pipeline",
      header: "Pipeline",
    },
    {
      key: "triggered",
      header: "Triggered",
    },
    {
      key: "status",
      header: "Status",
    },
  ];

  const getTableHeader = (header) => {
    return header;
  };

  const getRowCellData = (id, data, row) => {
    if (id.includes(":pipeline")) {
      //const status = row.cells.filter((fil) => fil["id"].includes("status"));
      return (
        <div className="pipeline-name">
          <ul>
            {data.map((ta) => <li>
              <div className="svg">              
              <DotMark
                size={20}
                className={
                  ta.value === "FAILURE"
                    ? "red"
                    : ta.value === "In Progress"
                    ? "orange"
                    : ta.value === "hold"
                    ? "grey" : "green"
                }
              /></div>
              <span>{ta.name}</span>
            </li>)}
          </ul>
        </div>
      );
    }
    return data;
  };

  const createFunction = () => {
    showModals(true);
  };

  const closeModal = () => {
    showModals(false);
    setErrorLog(false);
    setAnalyze(false);
  };

  const getBuildStatus = async () => {
    const status = await JenkinsBuildStatusforGenAI(keycloak, uuidv4());
    setTableData([]);
    setBuildStatus(status);
    if (status.jobs[0]?.lastBuild?.result === null) {
      setTimeout(() => {
        getBuildStatus();
      }, 15000);
    }
  };

  // const triggerBuild = async () => {
  //   // const triggerBuildStatus = await triggerJenkinsBuildforGenAI(
  //   //   keycloak,
  //   //   uuidv4()
  //   // );
  //   window.location.href = "https://gbsjenkins.edst.ibm.com/job/Liberty-GenAI/job/Gen2-Portal";
  //   setTableData([]);
  //   const triggerBuildStatus = "true";
  //   if (triggerBuildStatus !== "") {
  //     tableDataJSON[0].pipeline.map((pipe) => {
  //       if(pipe.name === "Build") {
  //         pipe.value = "In Progress"
  //       }
  //       return pipe;
  //     })
  //     tableDataJSON[0].status = "In Progress";
  //     setTableData(tableDataJSON);
  //   }
  //   setTimeout(() => {
  //     getBuildStatus();
  //   }, 20000);
  // };

  const getActions = (row, cell) => {
    let status = "", name = "";
    row.cells.forEach((cells) => {
      if (cells.id.includes("status")) {
        status = cells.value;
      }
      if (cells.id.includes("name")) {
        name = cells.value;
      }
    });
    return (
      <ul className="action-icons">
        <li
          className={`${name === "Cart" ? status === "FAILURE" ? "disable" : "enable" : "enable"}`}
          title="Trigger Build"
          // onClick={() => triggerBuild()}
        >
          <a href="https://gbsjenkins.edst.ibm.com/job/Liberty-GenAI/job/Gen2-Portal" target="_blank" rel="noopener"><PlayFilledAlt /></a>
          
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Logs"
          onClick={() => onClickActions("logs", row)}
        >
          <Catalog />
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Analyze"
          onClick={() => onClickActions("analyze", row)}
        >
          <IbmWatsonDiscovery />
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Collaborate"
        >
          <a
            href="https://ibm-test.enterprise.slack.com/archives/C081XC8KZU4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Collaborate />
          </a>
        </li>
      </ul>
    );
  };

  const onClickActions = (name, row) => {
    let pipeName = "";
    row.cells.forEach((cell) => {
      if(cell.id.includes("name")) {
        pipeName = cell.value
      }
    })
    if(pipeName === "Dispatch") {
      setLogData(deploy)
    } else {
      setLogData(error)
    }
    if (name === "logs") {
      setErrorLog(true);
    }
    if (name === "analyze") {
      setAnalyze(true);
    }
    if (name === "pipeline") {
      showModals(true);
    }
  };

  return (
    <>
      <Column lg={8} md={4} sm={2} className="padding-rgt">
        <DataTable
          rowData={tableData}
          headerData={headerData}
          getRowCellData={getRowCellData}
          getTableHeader={getTableHeader}
          actionsNeeded={false}
          table_particular_actions={true}
          createTitle="Generate Pipeline"
          createFunction={createFunction}
          getActions={getActions}
        />
      </Column>
      <Column lg={8} md={4} sm={2}>
        <TreeMap />
      </Column>
      {showModal ? <GenPipeline onCloseModal={closeModal} /> : null}
      {errorLog ? <ErrorLog logs={logData} onCloseModal={closeModal} /> : null}
      {analyze ? <Analyze logs={logData} onCloseModal={closeModal} /> : null}
    </>
  );
};

export default PipelineActivity;
