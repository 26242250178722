import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  FlexGrid,
  Row,
} from "@carbon/react";
import React from "react";
import KenDashboard from "./KenDashboard";

const KenvueIndex = (props) => {
  return (
    <FlexGrid className="att-dash">
      {props.location.state?.flow === "value stream" ? <KenDashboard title="Job Dashboard" flow={props.location.state?.flow} /> : <>
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/operations">Operations</BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>Kenvue Dashabord</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <KenDashboard title="Job Dashboard" />
          </Column>
        </Row>
      </>}
    </FlexGrid>
  );
};

export default KenvueIndex;
