import React from "react";
import { FlexGrid, Row, Column, Breadcrumb, BreadcrumbItem } from "@carbon/react";
import VSDashboard from "./vsDashboard";

const VSIndex = () => {

    // const hubs = sessionStorage.getItem("HubFlow");

    return (
        <FlexGrid className="valueStream">
            {/* <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
                        <BreadcrumbItem isCurrentPage>
                            Customer Journey Value Stream
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row> */}
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <VSDashboard />
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default VSIndex