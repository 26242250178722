import {
  Button,
  Modal
} from "@carbon/react";
import { HP_440_G7_CE } from "Constant";
import React, { useEffect, useState } from "react";
import DataTable from "Carbon-Components/DataTable";
import "../../css/dashboard.scss";
import ContinuousView from "./ContinuousView";
import { headers, monthNames } from "./RowsHeaders";
import apps from "./newData.json";

const Applicaions = () => {
  const [rowData, setRowData] = useState([]);
  const [monthly, setMonthly] = useState(false)

  useEffect(() => {
    fetchAppdata();
  }, []);

  const fetchAppdata = () => {
        const table_format = [];
        apps.forEach((app, index) => {
            const targetDate = new Date('2023-12-31');
            const hrs = app["Monthly OT Effort (Hrs.)"];
            const completionDate = new Date(app["Completion (MMM-YY)"]);
            const remainingMonths = (targetDate.getFullYear() - completionDate.getFullYear()) * 12 + (12 - completionDate.getMonth() -1);
            let carbonSavings;
            let accuHours;
            if(app["Benefit Category"] === "OT-ES"){
              accuHours = hrs
              carbonSavings = hrs * HP_440_G7_CE
            }
            else{
              accuHours = (hrs/2) + (remainingMonths * hrs)
              carbonSavings = accuHours * HP_440_G7_CE
            }
            table_format.push({
                ...app,
                id: index,
                "Total Carbon Savings(kgCO₂eq)": carbonSavings.toFixed(3),
                "Accumulated Hours(hrs)": accuHours.toFixed(1) 
            });
        });
        setRowData(table_format);
  };

  const getRowCellData = (id, data, row) => {
    

    if(id.includes(":Completion (MMM-YY)")){
        const date = new Date(data);
        const month = date.getMonth();
        const year = date.getFullYear();
        const shortMonthName = monthNames[month];
        return `${shortMonthName}-${year}`;
    }

    if (id.includes(":monthlyBreakup")) {
      return (
        <Button
          kind="ghost"
          className="susops-btn"
          size="sm"
          onClick={() => setMonthly(true)}
        >
          View
        </Button>
      );
    }

    return data;
  };

  return (
    <div className="recommendations-table">
      <DataTable
        rowData={rowData}
        headerData={headers}
        getRowCellData={getRowCellData}
        title="Use Cases"
        placeholder="Search for Applications"
        actionsNeeded={false}
        getTableHeader={(header) => header}
        toolbarNeeded={false}
      />
      {monthly ? (
        <Modal
          open
          passiveModal
          size="lg"
          onRequestClose={() => setMonthly(false)}
        >
          <ContinuousView key="cv" />,
        </Modal>
      ) : null}
    </div>
  );
};

export default Applicaions;
