import ChartContainer from "Carbon-Charts/ChartContainer";
import React from "react";
import { itAppColors } from "./Utilities";

const Category = ({ data: chartData, displayUnit }) => {
  const data = {
    data: {
      chartData,
      chartOptions: {
        title: `dummy-hide`,
        resizable: true,
        donut: {
          center: {
            label: displayUnit,
            numberFormatter: (value) => value.toFixed(2),
          },
        },
        pie: {
          labels: {
            enabled: false,
          },
        },
        color: {
          scale: itAppColors,
        },
        height: "300px",
        toolbar: {
          enabled: false,
        },
      },
    },
    dataType: "donut",
  };

  return <ChartContainer data={data} />;
};

export default Category;
