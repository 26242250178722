/** @format */

import {
    ErrorBoundary,
    SkeletonPlaceholder,
    Tag
  } from "@carbon/react";
  import { useKeycloak } from "@react-keycloak/web";
  import CarbonDataTable from "Carbon-Components/DataTable";
  import { getGenAIRunBookRecommendations } from "../../Services/ServerApi";
  import AddUserActivity from "../../utilities/AddUserActivity";
  import ErrorWhileFetching from "../../utilities/ErrorWhileFetching";
  import { InProgress, ErrorFilled, CheckmarkFilled, UndefinedFilled } from "@carbon/react/icons/index";
  import Context from "Context/Context";
  import React, { useContext, useEffect, useState } from "react";
  
  const tableHeaderData = [
    {
      key: "id",
      header: "Runbook Name",
    },
    {
      key: "ticketID",
      header: "Ticket ID",
    },
    {
      key: "category",
      header: "Issue Category",
    },
    {
      key: "ticket",
      header: "Ticket",
    },
  ];
  
  
  const RunBookAutomationGenAI = (props) => {
    const context = useContext(Context);
    useEffect(() => {
      getAutomationRecommendations(props.title, props.rootcause);
    }, []);
  
    const { keycloak } = useKeycloak();
    const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
    const [errMessage, setErrorMessage] = useState("");
    const [tableRowData, setTableRowData] = useState();
  
    const getAutomationRecommendations = async (title, description) => {
  
      const response = await getGenAIRunBookRecommendations(
        keycloak.token,
        title,
        description
      );
  
      AddUserActivity(keycloak, context, "FEATURE", "RunBook Recommendation", response);
      setIsAPIDataLoading(false);
  
      if (!response.data) {
        setErrorMessage("Error while fetching data");
      }
      if (response.status >= 400 || response === "Error") {
        setErrorMessage("Error while fetching data");
      } else {
        updateTableRowData(response.data.data);
      }
    };
  
    const isUnique = (obj, array) => {
      if (array.length > 0) {
        if (array.find(item => item.ticket === obj.ticket) === undefined) {
          return true;
        } else {
          return false;
        }
      } else { return true; }
    }
  
    const updateTableRowData = (data) => {
      let tableRowDataArray = [];
  
      data.forEach((obj, index) => {
        let tableRow = {};
  
        tableRow.id = obj["RUNBOOK"];
        tableRow.ticketID = obj["TICKET_ID"];
        tableRow.category = obj["ISSUE_CATEGORY"];
        tableRow.ticket = obj["TICKET_TITLE"];
  
        if (isUnique(tableRow, tableRowDataArray)) {
          tableRowDataArray.push(tableRow);
        }
      });
      setTableRowData(tableRowDataArray);
    };
  
    const getLoading = () => {
      return isAPIDataLoading ? (
        <div className="skeletonPlaceholder--it-health">
          <SkeletonPlaceholder />
        </div>
      ) : (
        <ErrorWhileFetching errMessage={errMessage} />
      );
    };
  
    //const handleOnClick = (link) => window.open(link, "_blank");
  
    // const getStatusIcon = (status) => {
    //   switch (status) {
    //     case "NOT_EXECUTED":
    //       return <UndefinedFilled />;
    //     case "IN_PROGRESS":
    //       return <ErrorFilled />;
    //     case "SUCCESS":
    //       return <InProgress />;
    //     case "FAILURE":
    //       return <CheckmarkFilled />;
    //     default:
    //       return <UndefinedFilled />;
    //   }
    // };
  
    const getRowCellData = (id, data, row) => {
      return data;
    };
  
    const getTableHeader = (header) => {
      return header;
    };
  
    const getTable = () => {
      return isAPIDataLoading || errMessage ? (
        getLoading()
      ) : (
        <div className="common-top-margin">
          <ErrorBoundary fallback={<ErrorWhileFetching />}>
            <CarbonDataTable
              rowData={tableRowData}
              headerData={tableHeaderData}
              getRowCellData={getRowCellData}
              getTableHeader={getTableHeader}
            />
          </ErrorBoundary>
        </div>
      );
    };
  
    return (
      <>
        <p>
          <strong>Root Cause:</strong> {props.rootcause}
        </p>
        <Tag
          type="green"
          size="sm"
          title={props.servicetype}
          className="text-on-tile__secondary_tag"
        >
          Service Type: {props.servicetype}
        </Tag>
        {getTable()}
      </>
    );
  };
  
  export default RunBookAutomationGenAI;
  