import React from "react";
import VersionControlReport from "./VersionControlReport";

function VersionControl() {
    return (
        <>
            {/* <Grid>
                <Column lg={12} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa">My Dashboards</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/insights">Insights</BreadcrumbItem>
                        <BreadcrumbItem href="#/icpsa/intelligentALM">Intelligent ALM</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>Version Control</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid> */}
            <div className="content-div">
                <VersionControlReport />
            </div>
        </>
    );
}

export default VersionControl;
