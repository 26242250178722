import React, { useEffect, useState } from "react";
import Elk from "./elkTopology";
// import KenvueElk from "./kenvue/kenvueElk";
import {
  SkeletonPlaceholder,
  SkeletonText
} from "@carbon/react";

const TopologyGraph = (props) => {
  const [linkData, setlinkData] = useState([]);
  const [nodeData, setNodeData] = useState([]);

  // const nodeHeight = 64;
  // const nodeWidth = 180;
  // const size = 48;

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    let keyData = {};
    props.appDetails.forEach((vs) => {
        keyData = vs.Platform.topology
    })
    if(Object.entries(keyData).length > 0) {
      getTopologyData(keyData)
    }
  }, [props])

  const getTopologyData = (instanaTopology) => {
    // const nodeData = [
    //   { id: "a", height: size, width: size, label: "Prepaid", "types": ["jdbc"] },
    //   { id: "b", height: 160, width: 60, label: "Integration Svc Layer", "types": ["HTTP"] },
    //   { id: "c", height: size, width: size, label: "Billing System", "types": ["MESSAGING"] },
    //   { id: "d", height: size, width: size, label: "MDMS", "types": ["unknown"] },
    //   { id: "e", height: 160, width: 60, label: "Integration Svc Layer", "types": ["jdbc"] },
    //   { id: "f", height: size, width: size, label: "HES", "types": ["unknown"] },
    //   { id: "g", height: 160, width: 60, label: "Integration Svc Layer", "types": ["unknown"] },
    //   { id: "h", height: size, width: size, label: "Meter", "types": ["unknown"] }
    // ];

    // const linkData = [

    //   { id: "1", source: "a", target: "b" },
    //   { id: "2", source: "b", target: "c" },
    //   { id: "3", source: "c", target: "d" },
    //   { id: "4", source: "d", target: "e" },
    //   { id: "5", source: "e", target: "f" },
    //   { id: "6", source: "f", target: "g" },
    //   { id: "7", source: "g", target: "h" }
    // ];

    const connected = instanaTopology.connections.map((topo, index) => {
      let newObj = {
        source: topo.from,
        target: topo.to
      };
      // instanaTopology.connections.forEach((conn) => {
      //   if (topo.from === conn.from && topo.to === conn.to) {
      //     newObj = {
      //       ...newObj,
      //       errorRate: conn.errorRate,
      //     }
      //   }
      // })
      return {
        id: (index + 1).toString(),
        ...newObj
      }
    })
    const node = instanaTopology.services.map((serv, index) => {
      let newServ = serv;
      connected.forEach((con) => {
        if (con.target === serv.id) {
          newServ = {
            ...newServ,
            error: con.errorRate
          }
        }
      })
      return {
        ...newServ,
        width: props.flow === "polaris" ? 60 : props.flow === "ViSecurity" ? 120 : 180,
        height: 80,
      }
    })
    setlinkData(connected)
    setNodeData(node)
  }

  const redirectTo = (node) => {
    props.redirectToIne(true, node);
  };

  return (
    <>
      {nodeData.length > 0 ?
         <Elk nodes={nodeData} flow={props.flow} links={linkData} layout="layered" viewBox="800 0 550 1000" width="98%" height="550" cardClick={redirectTo} />
        : <div style={{ height: "500px" }}>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <br />
          <SkeletonPlaceholder />
        </div>}
    </>
  );
};

export default TopologyGraph;
