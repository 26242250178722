import { QueryCache, useQuery } from "@tanstack/react-query";
import {
  getCitiIncidents,
  getCitiSession,
  getToolchainsQuery,
} from "Services/ServerApi";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { incidentFields, oomfields, sessionCountFields, sessionfields, taskCountFields, taskfields } from "../utils";

const useData = (keycloak, dateChange, refresh) => {
  const queryCache = new QueryCache();
  const {
    data: assetDetails,
    isLoading: loading1,
    isError: err1,
    isFetching: fetching1,
  } = useQuery(
    [`citi_asset`],
    async () => await getToolchainsQuery(keycloak, "ops", uuidv4()),
    {
      cache: queryCache,
      cacheTime: 60 * 60 * 1000,
    },
  );

  const {
    data: sessionDetails,
    refetch: sessionrefresh,
    isError: err2,
    isLoading: loading2,
    isFetching: fetching2,
  } = useQuery(
    [`citi_session`],
    async () =>
      await getCitiSession(
        keycloak,
        uuidv4(),
        "session_error",
        sessionfields,
        "timestamp",
        1724328902000,
        1724329802000,
        // dateChange.startTime,
        // dateChange.endTime,
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      cacheTime: 0
    },
  );

  const {
    data: oomDetails,
    refetch: oomRefetch,
    isError: err3,
    isLoading: loading3,
    isFetching: fetching3,
  } = useQuery(
    [`citi_oom`],
    async () =>
      await getCitiSession(
        keycloak,
        uuidv4(),
        "oom_error",
        oomfields,
        "timestamp",
        1724328902000,
        1724329802000,
        // dateChange.startTime,
        // dateChange.endTime,
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      // cacheTime: 60 * 60 * 1000
    },
  );

  const {
    data: taskDetails,
    refetch: taskRefetch,
    isError: err4,
    isLoading: loading4,
    isFetching: fetching4,
  } = useQuery(
    [`citi_task`],
    async () =>
      await getCitiSession(
        keycloak,
        uuidv4(),
        "task_error",
        taskfields,
        "timestamp",
        1724328902000,
        1724329802000,
        // dateChange.startTime,
        // dateChange.endTime,
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      cacheTime: 0,
    },
  );

  const {
    data: incidentData,
    refetch: incidentRefetch,
    isError: err5,
    isLoading: loading5,
    isFetching: fetching5,
  } = useQuery(
    [`citi_incidents`],
    async () =>
      await getCitiIncidents(
        keycloak,
        uuidv4(),
        incidentFields,
        "REPORT_DATE",
        "2024-08-22 12:15:02",
        "2024-08-22 12:30:02"
        // convertEpochToDateTime(dateChange.startTime),
        // convertEpochToDateTime(dateChange.endTime),
        // "2024-01-01 00:00:00.000Z",
        // "2024-02-09 00:00:00.000Z",
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      // cacheTime: 60 * 60 * 1000,
    },
  );

  const {
    data: sessionCountDetails,
    refetch: sessionCountRefresh,
    isError: err6,
    isLoading: loading6,
    isFetching: fetching6,
  } = useQuery(
    [`citi_session_count`],
    async () =>
      await getCitiSession(
        keycloak,
        uuidv4(),
        "session_count",
        sessionCountFields,
        "timestamp",
        1724328902,
        1724329802,
        // Math.floor(dateChange.startTime/1000),
        // Math.floor(dateChange.endTime/1000),
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      cacheTime: 0
    },
  );



  const {
    data: taskCountDetails,
    refetch: taskCountRefresh,
    isError: err7,
    isLoading: loading7,
    isFetching: fetching7,
  } = useQuery(
    [`citi_task_count`],
    async () =>
      await getCitiSession(
        keycloak,
        uuidv4(),
        "task_count",
        taskCountFields,
        "timestamp",
        1724328902,
        1724329802,
        // Math.floor(dateChange.startTime/1000),
        // Math.floor(dateChange.endTime/1000),
      ),
    {
      // cache: queryCache,
      retry: ({ message: status }) => status !== "404" && status !== "401",
      enabled: false,
      cacheTime: 0
    },
  );

  const { startTime, endTime } = dateChange;

  useEffect(() => {
    try {
      if (
        !loading1 &&
        // !fetching1 &&
        Array.isArray(assetDetails.data) &&
        assetDetails.data.length > 0
      ) {
        incidentRefetch();
        sessionrefresh();
        oomRefetch();
        taskRefetch();
        sessionCountRefresh();
        taskCountRefresh();
        // const timer = setInterval(() => {
        //   incidentRefetch();
        //   sessionrefresh();
        //   oomRefetch();
        //   taskRefetch();
        // }, 150000)
        // return () => {
        //   clearInterval(timer)
        // }
      }
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetDetails,
    startTime,
    oomRefetch,
    endTime,
    sessionrefresh,
    sessionCountRefresh,
    incidentRefetch,
    taskCountRefresh,
    taskRefetch
  ]);

  useEffect(() => {
    if (refresh) {
      incidentRefetch();
      sessionrefresh();
      oomRefetch();
      taskRefetch();
      sessionCountRefresh();
      taskCountRefresh();
    }
  }, [refresh])

  const isError =
    loading1 || fetching1 ? false : err1 || err2 || err3 || err4 || err5 || err6 || err7;
  const isLoading =
    loading1 ||
    loading2 ||
    loading3 ||
    loading4 ||
    loading5 ||
    loading6 ||
    loading7 ||
    fetching1 ||
    fetching2 ||
    fetching3 ||
    fetching4 ||
    fetching5 ||
    fetching6 ||
    fetching7;

  const assets = assetDetails ? assetDetails.data : [];
  const sessions = sessionDetails ? sessionDetails.data : [];
  const ooms = oomDetails ? oomDetails.data : [];
  const tasks = taskDetails ? taskDetails.data : [];
  const incidents = incidentData ? incidentData.data : [];
  const sessionCount = sessionCountDetails ? sessionCountDetails.data : [];
  const taskCount = taskCountDetails ? taskCountDetails.data : [];

  return { isError, isLoading, assets, sessions, ooms, tasks, incidents, sessionCount, taskCount, apiRefresh: fetching2 };
};

export default useData;
