/** @format */

import React from "react";
import {
    Layer, Tile
} from "@carbon/react";
import { WarningFilled } from "@carbon/react/icons/index"

const ErrorWhileFetching = (props) => {
    return (
        < Tile className="chart-container" >
            <Layer className="data_unavailable--layer">
                <WarningFilled fill="red" />
                <h3 style={{ color: "gray" }}>Error while fetching data</h3>
                <p>{props.errMessage ? props.errMessage : "Something went wrong with the request. Please try again or contact the administrator."}</p>
            </Layer>
        </Tile>
    );
}

export default ErrorWhileFetching;