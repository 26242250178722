import { Column, ErrorBoundary, Grid, Modal, SkeletonPlaceholder } from "@carbon/react";
import { formatDate } from "Common-Modules/ConvertUTCDate";
import { capitalize } from "Common-Modules/Utilities";
import React, { useEffect, useState } from "react";
import { getAlertStatusfromInstana } from "../Services/ServerApi";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";

const AlertStatusPopup = ({close, event_id}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [apiData, setApiData] = useState(undefined)
  
    useEffect(() => {
      setIsLoading(true)
      fetchData()
    }, [])
  
    const fetchData = async () => {
      try {
          const res = await getAlertStatusfromInstana(event_id)
          setApiData(res.data)
      } catch (error) {
          setIsError(true)
      } finally{
          setIsLoading(false)
      }
    }
  
    const getData = () => {
      if (isLoading ) {
        return (
          <div className="skeleton__placeholder--table">
          <SkeletonPlaceholder />
        </div>
        );
      }
  
      if (isError) {
        return <ErrorWhileFetching />;
      }
  
      return (
        <Grid style={{paddingLeft: 0, paddingRight: 0}}>
            <Column lg={12} md={5} className="ml-0">
              <p><strong>Problem: </strong>{capitalize(apiData.problem)}</p>
              <p><strong>Suggested Fix: </strong>{capitalize(apiData.fixSuggestion)}</p>
              <p><strong>Start Date: </strong>{formatDate(apiData.start)}</p>
              <p><strong>End Date: </strong>{formatDate(apiData.end)}</p>
            </Column>
            <Column lg={4} md={3}>
              <p><strong>Type: </strong>{capitalize(apiData.type)}</p>
              <p><strong>State: <span style={{color: "#0062fe"}}>{capitalize(apiData.state)}</span></strong></p>
              <p><strong>Severity: </strong>{apiData.severity}</p>
            </Column>
            {/* <Column lg={16} md={8} className="mt-1 ml-0">
              <h5>Metrics</h5>
            </Column>
            {
              apiData.metrics.map((metric, index) => {
                return <Column lg={8} md={4} className={index%2===0 ? "ml-0" : ""}>
                  <p><strong>Host: </strong>{metric.entityId.host}</p>
                  <p><strong>Metric Name: </strong>{metric.metricName}</p>
                </Column>
              })
            } */}
        </Grid>
      );
    }
  
    return (
      <Modal
        open
        size="md"
        passiveModal
        onRequestClose={() => close()}
        modalHeading="Alert Status"
      >
        <ErrorBoundary fallback={<ErrorWhileFetching />}>
          {getData()}
        </ErrorBoundary>
      </Modal>
    );
  }

  export default AlertStatusPopup